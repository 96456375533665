import { injectable } from '@knuddels-app/DependencyInjection';
import { Position, ViewProvider, ViewRegistry } from '@knuddels-app/layout';
import * as React from 'react';
import { getRouterStateFromPersistedViewState, RouterViewState } from '@knuddels-app/layout/k3Router';
import { LoadService } from '@knuddels-app/ModuleSystem';
import * as providesServices from './providedServices';
import { SmileyTradeSystemApp } from './bundle/components/SmileyTrade/SmileyTradeSystemApp';
import { smileyTradeAppViewId } from './bundle/components/SmileyTrade/viewId';
@injectable()
export class SmileyTradeAppViewProvider implements ViewProvider {
  registerViews(registry: ViewRegistry): void {
    registry.registerView({
      viewId: smileyTradeAppViewId,
      getOverrideStyles: () => ({
        flex: 1,
        maxWidth: 1100
      }),
      position: Position.Side,
      persistIndex: 3,
      isSystemApp: true,
      disableMobileBottomAds: () => true,
      autostartInBackground: globalEnv.product === 'app',
      loadState: SmileyTradeAppViewState.fromPersistedViewState,
      render: () => {
        return {
          mainView: <LoadService service={providesServices.$SystemAppService}>
							{() => <SmileyTradeSystemApp />}
						</LoadService>
        };
      }
    });
  }
}
export type Filter = {
  id: string;
  filter?: Filter[];
};
export class SmileyTradeAppViewState extends RouterViewState<{
  home: {
    params: {
      activeFilter: Filter[];
    };
  };
}> {
  public static fromPersistedViewState = getRouterStateFromPersistedViewState(SmileyTradeAppViewState);
}