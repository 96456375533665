import React from 'react';
import { ActiveChannelFragment, CannotJoinChannelReason, Channel, ChannelAd, ChannelGroup, ChannelJoinMutationResponseError, GetChannelName, InitialJoin, JoinChannelByGroupId, JoinChannelByGroupIdMutationVariables, JoinChannelById, JoinChannelByIdMutationVariables, JoinChannelByName, JoinChannelByNameMutationVariables } from '@generated/graphql';
import { mapChannelJoinErrorToMessage } from '../../msgs/cannotJoinChannelErrorMapping';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $ActiveChannelService, $ChannelSubscriptionService } from '../../../providedServiceIds';
import { $AuthenticatedClientService, $AuthService, GraphQlOperationError } from '@knuddels-app/Connection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { chatroomErrorEvent } from '@knuddelsModules/Channel/analytics';
import { Disposable, ResultPromise } from '@knuddels/std';
import { $ViewService } from '@knuddels-app/layout';
import { channelViewId } from '../../../ChannelViewProvider';
import { $OverlayService } from '@knuddels-app/overlays';
import { channelListViewId } from '@knuddelsModules/ChannelList';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { JoinProtectedChannelDialog } from '../../components/JoinProtectedChannelDialog';
import { action, observable, runInAction } from '@knuddels-app/mobx';
import { $DeepLinkingService, DeepLinkType } from '@knuddelsModules/DeepLinking';
import { AdultChannelContent } from '../../components/Lightboxes/AdultChannelContent';
import { FormattedText, FormattedTextText } from '@shared/components';
import { ChannelJoinErrorModal } from '@knuddelsModules/Channel/bundle/components/ChannelJoinErrorModal';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
export type JoinMutation = typeof JoinChannelByGroupId | typeof JoinChannelById | typeof JoinChannelByName;
export type JoinMutationVariables = JoinChannelByGroupIdMutationVariables | JoinChannelByIdMutationVariables | JoinChannelByNameMutationVariables;
@injectable()
export class JoinChannelService {
  public readonly dispose = Disposable.fn();
  @observable
  public overrideInitialChannelName: string | undefined = undefined;
  @observable
  private openSmileyBox = false;
  private retryLastMutationWithArgs: (args: Partial<JoinMutationVariables>) => ResultPromise<any, any>;
  private lastReportedChannelId: string | null = null;
  constructor(@inject($ActiveChannelService)
  private readonly activeChannelService: typeof $ActiveChannelService.T, @inject($AuthenticatedClientService)
  private readonly authenticatedClientService: typeof $AuthenticatedClientService.T, @inject($I18n)
  private i18n: typeof $I18n.T, @inject($ViewService)
  private readonly viewService: typeof $ViewService.T, @inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T, @inject($DeepLinkingService)
  private readonly deepLinkingService: typeof $DeepLinkingService.T, @inject($AuthService)
  private readonly authService: typeof $AuthService.T, @inject($ChannelSubscriptionService)
  private readonly channelSubscriptionService: typeof $ChannelSubscriptionService.T, @inject($GenericUserEventService)
  private readonly genericUserEventService: typeof $GenericUserEventService.T) {
    this.dispose.track(this.authenticatedClientService.onReconnected.sub(() => {
      if (this.activeChannelService.activeChannel) {
        this.joinChannelById(this.activeChannelService.activeChannel.id, 'ClientReconnected', {
          redirectToChannelListOnFail: false,
          redirectToChannelOnSuccess: false,
          mayJoinSubChannel: true
        });
      }
    }));
    this.dispose.track(this.deepLinkingService.registerDeeplinkHandler((type, target) => {
      const isChannelType = type === DeepLinkType.Channel || type === DeepLinkType.ChannelWithSmileyBox;
      if (isChannelType && target) {
        runInAction('Open smileyBox', () => {
          this.openSmileyBox = type === DeepLinkType.ChannelWithSmileyBox;
        });
        if (this.activeChannelService.activeChannel) {
          this.joinChannelByName(target, 'DeepLink', {
            disableErrorHandling: true
          });
        } else {
          runInAction('Set override initial channel', () => {
            this.overrideInitialChannelName = target;
          });
        }
      }
    }));
    this.dispose.track(this.channelSubscriptionService.onChannelEvent.sub(event => {
      if (event.__typename === 'ChannelPasswordRequired') {
        this.overlayService.showOverlay({
          view: <JoinProtectedChannelDialog retryWithPassword={(password: string) => this.authenticatedClientService.currentK3Client.mutateWithResultPromise(JoinChannelById, {
            channelId: event.channel.id,
            pixelDensity: getPixelRatio(),
            password
          })} onCancel={() => {}} onSuccess={(data: any) => this.handleJoin(data.channel, 'JoinProtectedChannel')} />
        });
      }
    }).dispose);
  }
  @action.bound
  public getAndResetOpenSmileyBoxOnce(): boolean {
    const result = this.openSmileyBox;
    this.openSmileyBox = false;
    return result;
  }
  public async joinChannelById(id: Channel['id'], openContext: string, options = {
    redirectToChannelListOnFail: false,
    redirectToChannelOnSuccess: false,
    mayJoinSubChannel: false
  }): Promise<boolean> {
    return this.joinMutation(JoinChannelById, {
      channelId: id,
      pixelDensity: getPixelRatio(),
      mayJoinSubChannelIfFull: options.mayJoinSubChannel
    }).match({
      ok: data => this.handleJoin(data.channel, openContext, options.redirectToChannelOnSuccess),
      error: err => this.handleJoinError(err, {
        id
      }, options.redirectToChannelListOnFail)
    });
  }
  public async joinChannelByGroupId(id: ChannelGroup['id'], groupName: string, openContext: string, adCampaignId?: ChannelAd['adCampaignId']): Promise<boolean> {
    return this.joinMutation(JoinChannelByGroupId, {
      groupId: id,
      adCampaignId,
      pixelDensity: getPixelRatio(),
      confirmed: this.activeChannelService.hasChannelBeenVisited(groupName)
    }).match({
      ok: data => this.handleJoin(data.channel, openContext),
      error: err => this.handleJoinError(err, {
        name: groupName
      })
    });
  }
  public async joinChannelByName(name: string, openContext: string, options = {
    disableErrorHandling: false
  }): Promise<boolean> {
    return this.joinMutation(JoinChannelByName, {
      name,
      pixelDensity: getPixelRatio(),
      confirmed: this.activeChannelService.hasChannelBeenVisited(name)
    }).match({
      ok: data => this.handleJoin(data.channel, openContext),
      error: err => {
        if (!options.disableErrorHandling) {
          return this.handleJoinError(err, {
            name
          });
        } else {
          return false;
        }
      }
    });
  }
  public async initialJoinChannel(options: {
    redirectToChannelOnSuccess: boolean;
    force: boolean;
  } = {
    redirectToChannelOnSuccess: true,
    force: true
  }): Promise<boolean> {
    return this.authenticatedClientService.currentK3Client.mutateWithResultPromise(InitialJoin, {
      force: options.force,
      pixelDensity: getPixelRatio()
    }).match({
      ok: async data => {
        if (data.channels.length > 0) {
          // TECHDEBT only use first channel for now, because we can only join a single channel in this client
          return this.handleJoin(data.channels[0], 'InitialJoin', options.redirectToChannelOnSuccess);
        } else {
          await this.activeChannelService.clearActiveChannel();
          return Promise.resolve(false);
        }
      },
      error: err => this.handleJoinError(err)
    });
  }
  private joinMutation(mutation: JoinMutation, variables: JoinMutationVariables): ResultPromise<any, any> {
    this.retryLastMutationWithArgs = (args: JoinMutationVariables) => this.authenticatedClientService.currentK3Client.mutateWithResultPromise(mutation, {
      ...variables,
      ...args
    });
    return this.authenticatedClientService.currentK3Client.mutateWithResultPromise(mutation, variables);
  }
  public logJoinChannel(channel: ActiveChannelFragment, openContext: string): void {
    if (this.lastReportedChannelId === channel.id) {
      return;
    }
    this.lastReportedChannelId = channel.id;
    const [id, subId] = channel.id.split(':');
    this.genericUserEventService.reportEvent({
      type: 'Joined_Channel',
      channelId: id,
      source: openContext,
      subId: subId,
      channelName: channel.name,
      numberOfMembers: channel.participants.length
    });
  }
  private async handleJoin(channel: ActiveChannelFragment, openContext: string, redirectToChannelOnSuccess = true): Promise<boolean> {
    await this.activeChannelService.initializeActiveChannel(channel);
    if (redirectToChannelOnSuccess) {
      this.openChannel(channel.id);
    }
    this.logJoinChannel(channel, openContext);
    return Promise.resolve(true);
  }
  private async handleJoinError(resultError: GraphQlOperationError<ChannelJoinMutationResponseError>, channelIdentifier?: {
    id?: string;
    groupId?: string;
    name?: string;
  }, redirectToChannelListOnFail = true): Promise<boolean> {
    if (resultError.kind !== 'DomainError') {
      this.catchError(true);
      return false;
    }
    chatroomErrorEvent.track(`Error_${resultError.error.type}`);
    if (resultError.error.type === CannotJoinChannelReason.SessionInvalid) {
      this.authService.forceRefreshSession();
    }
    const channelName = await this.getChannelName(channelIdentifier);
    if (!channelName) {
      await this.showInitialJoinErrorModal(resultError.error);
      return false;
    }
    await this.showCannotJoinModal(channelName, resultError.error, redirectToChannelListOnFail);
    return false;
  }
  private async getChannelName(channelIdentifier?: {
    id?: string;
    groupId?: string;
    name?: string;
  }): Promise<string | null> {
    if (!channelIdentifier) {
      return null;
    }
    if (channelIdentifier.name) {
      return channelIdentifier.name;
    }
    if (typeof channelIdentifier.id !== 'string') {
      return null;
    }
    return this.authenticatedClientService.currentK3Client.queryWithResultPromise(GetChannelName, {
      channelId: channelIdentifier.id
    }).match({
      ok: data => data.name,
      error: () => null
    });
  }
  private catchError(redirectToChannelListOnFail: boolean): void {
    chatroomErrorEvent.track(`Error_Mutation_Caught`);
    this.showNetworkIssuesModal(redirectToChannelListOnFail);
  }
  private async showInitialJoinErrorModal(error: ChannelJoinMutationResponseError): Promise<void> {
    await this.showErrorModal(this.i18n.format(declareFormat({
      id: 'CANNOT_JOIN_INITIALLY_MESSAGE',
      defaultFormat: 'Please choose another channel.'
    })), this.i18n.format(declareFormat({
      id: 'CANNOT_JOIN_INITIALLY_HEADLINE',
      defaultFormat: 'Cannot join any channel {error, select, OTHER {} other {(code: {error})}}'
    }), {
      error: error.type
    }));
  }
  private async showNetworkIssuesModal(redirectToChannelList = true): Promise<void> {
    await this.showErrorModal(this.i18n.format(declareFormat({
      id: 'NETWORK_ERRORS_MESSAGE',
      defaultFormat: 'Please check your connection and try again.'
    })), this.i18n.format(declareFormat({
      id: 'NETWORK_ERRORS_HEADLINE',
      defaultFormat: 'Network issues'
    })), redirectToChannelList);
  }
  private async showCannotJoinModal(channelIdentifier: string, error: ChannelJoinMutationResponseError, redirectToChannelListOnFail = true): Promise<void> {
    if (error.type === CannotJoinChannelReason.NoConfirmationGiven) {
      return await this.showAdultChannelDialog(channelIdentifier);
    }
    if (error.type === CannotJoinChannelReason.IncorrectPassword) {
      return await this.showJoinProtectedChannelDialog();
    }
    await this.showErrorModal(mapChannelJoinErrorToMessage(channelIdentifier, error), this.i18n.format(declareFormat({
      id: 'CANNOT_JOIN_HEADLINE',
      defaultFormat: 'Cannot join channel "{id}" {error, select, OTHER {} other {(code: {error})}}'
    }), {
      id: channelIdentifier,
      error: error.type
    }), redirectToChannelListOnFail);
  }
  private async showErrorModal(text: string | FormattedText, headline: string, redirectToChannelList = true): Promise<void> {
    const formattedText = typeof text === 'string' ? new FormattedTextText(text) : text;
    const m = this.overlayService.showOverlay({
      view: <ChannelJoinErrorModal headline={headline} redirectToChannelList={redirectToChannelList} text={formattedText} />
    });
    await m.onClosed;
    if (redirectToChannelList) {
      this.viewService.openView(channelListViewId, {
        openContext: 'join-error-modal'
      });
    }
  }
  private async showJoinProtectedChannelDialog(): Promise<void> {
    const dialog = this.overlayService.showOverlay({
      view: <JoinProtectedChannelDialog retryWithPassword={password => this.retryLastMutationWithArgs({
        password
      })} onCancel={() => this.viewService.openView(channelListViewId, {
        openContext: 'join-protected-channel-dialog'
      })} onSuccess={(data: any) => this.handleJoin(data.channel, 'JoinProtectedChannel')} />
    });
    await dialog.onClosed;
  }
  private async showAdultChannelDialog(identifier: string): Promise<void> {
    const retry = () => {
      /**
       * there is a race condition, because there are two events happening after a join
       * - result of join mutation
       * - channel joined event
       *
       * If the join mutation response is delayed, the overlay would be displayed twice.
       * to avoid this we mark the channel as visited, so the channel joined event won't show the dialog again
       */
      this.activeChannelService.markChannelAsVisited(identifier);
      this.retryLastMutationWithArgs({
        confirmed: true
      }).match({
        ok: data => this.handleJoin(data.channel, 'AdultChannelWarningAccept', true),
        error: err => this.handleJoinError(err, {
          name: identifier
        }, true)
      });
    };

    /**
     * if the user has already visited the channel, we don't need to show the dialog again
     * and can auto confirm the dialog
     */
    if (this.activeChannelService.hasChannelBeenVisited(identifier)) {
      retry();
      return;
    }
    const dialog = this.overlayService.showOverlay({
      view: <AdultChannelContent onAccept={retry} onCancel={() => {
        if (!this.activeChannelService.activeChannel) {
          this.viewService.openView(channelListViewId, {
            openContext: 'adult-channel-warning-cancel'
          });
        }
      }} />
    });
    await dialog.onClosed;
  }
  private openChannel(channelId: Channel['id']): void {
    this.viewService.openView(channelViewId.with(s => s.setChannel(channelId)));
    this.overlayService.closeAllOverlays();
  }
  // tslint:disable-next-line: max-file-line-count
}