import { Flex, FlexCol, FallbackBox, resolveThemingValue, useTheme } from '@knuddels/component-library';
import * as React from 'react';
import { ADZONE_CONTENT_HEIGHT, ADZONE_HEIGHT, ADZONE_WIDTH } from '../AdzoneProps';
import { AdsLabel } from './AdsLabel';
import { AdsPlaceholder } from './AdsPlaceholder';
export interface AdzoneWrapperProps {
  adVisible: boolean;
  placeholder: {
    width: number;
    height: number;
  } | 'none';
  verticalAd: boolean;
  fixedSize?: number;
  onLayout?: (size: {
    width: number;
    height: number;
  }) => void;
  hideRemoveAdsBadge?: true;
  adsBadgeLocation?: 'top' | 'bottom';
  bannerId?: string;
}
export const AdzoneWrapper: React.FC<AdzoneWrapperProps> = ({
  fixedSize,
  verticalAd,
  adVisible,
  hideRemoveAdsBadge,
  onLayout,
  placeholder,
  children,
  bannerId,
  adsBadgeLocation = 'top'
}) => {
  return <FallbackBox onLayout={e => {
    onLayout?.(e);
  }} className={_c0 + (verticalAd ? _c1 : _c2)}>
			<div style={{
      width: resolveThemingValue(verticalAd ? fixedSize || ADZONE_WIDTH : undefined, "sizes", useTheme()),
      height: resolveThemingValue(verticalAd ? undefined : fixedSize || ADZONE_HEIGHT, "sizes", useTheme())
    }} className={_c3}>
				<div className={_c4 + (adsBadgeLocation === 'top' ? _c5 : _c6)}>
					{adVisible && <AdsLabel bannerId={bannerId} hideRemoveAdsBadge={hideRemoveAdsBadge} isBottomAd={adsBadgeLocation === 'bottom'} />}
					{verticalAd ? <div style={{
          width: resolveThemingValue(fixedSize || ADZONE_WIDTH, "sizes", useTheme())
        }} className={_c7}>
							{children}
						</div> : <div style={{
          height: resolveThemingValue(fixedSize || ADZONE_CONTENT_HEIGHT, "sizes", useTheme())
        }} className={_c8}>
							{children}
						</div>}
				</div>
			</div>
			{!adVisible && placeholder !== 'none' && <AdsPlaceholder width={placeholder.width} height={placeholder.height} hideRemoveAdsBadge={hideRemoveAdsBadge} />}
		</FallbackBox>;
};
const _c0 = " Knu-Flex position-relative justifyContent-center flexGrow-1 overflow-visible ";
const _c1 = " flexDirection-column ";
const _c2 = " flexDirection-row ";
const _c3 = " Knu-FlexCol position-relative placeItems-center overflow-visible ";
const _c4 = " Knu-Flex position-relative overflow-hidden ";
const _c5 = " flexDirection-column ";
const _c6 = " flexDirection-column-reverse ";
const _c7 = " Knu-Flex flexDirection-column placeItems-center minHeight-220px overflow-hidden ";
const _c8 = " Knu-Flex flexDirection-row placeItems-center minWidth-220px overflow-hidden ";