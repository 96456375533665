import * as React from 'react';
import { useDrag } from '@use-gesture/react';

import styles from './BottomSheet.module.scss';
import { VisibleOverlay } from '@knuddels-app/overlays/OverlayService';

export const useBottomSheet = (overlay: VisibleOverlay) => {
	const domRef = React.useRef<HTMLDivElement | null>(null);
	const bgRef = React.useRef<HTMLDivElement | null>(null);
	const closedRef = React.useRef(false);

	const bind = useDrag(
		({ movement, velocity, dragging, first, direction }) => {
			const domElement = domRef.current;
			if (!domElement) {
				return;
			}

			const translateY = Math.max(0, movement[1] / 1.5);
			if (first) {
				domElement.style.animation = 'none';
				if (bgRef.current) {
					bgRef.current.style.animation = 'none';
				}
			}
			domElement.style.transform = `translate3d(0, ${translateY}px, 0)`;

			if (!dragging) {
				if (velocity[1] > 0.1 && direction[1] === 1) {
					const duration = Math.min(
						(domElement.offsetHeight - translateY) /
							Math.abs(velocity[1]),
						200
					);

					if (bgRef.current) {
						bgRef.current.animate(
							{ opacity: 0 },
							closeAnimation(duration, 'ease-out')
						);
					}

					const animation = domElement.animate(
						{ transform: 'translate3d(0, 100%, 0)' },
						closeAnimation(duration, 'ease-out')
					);
					animation.addEventListener(
						'finish',
						() => {
							closedRef.current = true;
							overlay.dispose();
						},
						{ once: true }
					);
				} else {
					if (bgRef.current) {
						const bgAnimation = bgRef.current.animate(
							{ opacity: 1 },
							closeAnimation(200, 'ease-in')
						);

						bgAnimation.addEventListener(
							'finish',
							() => {
								if (bgRef.current) {
									bgRef.current.style.opacity = '1';
									bgRef.current.style.animation = 'none';
								}
								bgAnimation.cancel();
							},
							{ once: true }
						);
					}

					const animation = domElement.animate(
						{ transform: 'translate3d(0, 0, 0)' },
						closeAnimation(200, 'ease-in')
					);
					animation.addEventListener(
						'finish',
						() => {
							if (domRef.current) {
								domRef.current.style.transform =
									'translate3d(0,0,0)';
								domRef.current.style.animation = 'none';
							}
							animation.cancel();
						},
						{ once: true }
					);
				}
			}
		},
		{ axis: 'y', enabled: true }
	);

	const close = React.useCallback(() => {
		return new Promise<void>(resolve => {
			if (!domRef.current || closedRef.current) {
				resolve();
				return;
			}

			closedRef.current = true;

			if (bgRef.current) {
				bgRef.current.animate({ opacity: '0' }, closeAnimation(200));
			}

			const animation = domRef.current.animate(
				{ transform: 'translate3d(0, 100%, 0)' },
				closeAnimation(200)
			);
			animation.addEventListener(
				'finish',
				() => {
					overlay.dispose();
					resolve();
				},
				{ once: true }
			);
		});
	}, [overlay.dispose]);

	React.useEffect(() => {
		if (bgRef.current) {
			bgRef.current.classList.add(styles['Knu-BottomSheetBg']);
		}

		overlay.setBeforeDispose(close);
	}, [close]);

	return { domRef, bgRef, bind, className: styles['Knu-SlideUpView'] };
};

const closeAnimation = (
	duration: number,
	easing = 'cubic-bezier(0.3, 0, 0.8, 0.15)'
) =>
	({
		duration,
		fill: 'forwards',
		easing,
	}) as KeyframeAnimationOptions;
