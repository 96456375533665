import { newServiceId } from '@knuddels-app/DependencyInjection';
import {
	ActiveChannelService,
	ChannelCommandsService,
	ChannelMessageFilterService,
	ChannelSubscriptionService,
	Commands,
	JoinChannelService,
	MacroBoxService,
	ReconnectedService,
	SystemEventsService,
} from './bundle/services';
import { ChannelComponents } from './bundle/services/ChannelComponents';

export const $ActiveChannelService = newServiceId<ActiveChannelService>(
	'$ActiveChannelService'
);

export const $ReconnectedService = newServiceId<ReconnectedService>(
	'$ReconnectedService'
);

export const $JoinChannelService = newServiceId<JoinChannelService>(
	'$JoinChannelService'
);

export const $SystemEventsService = newServiceId<SystemEventsService>(
	'$SystemEventsService'
);

export const $Commands = newServiceId<Commands>('$Commands');

export const $ChannelComponents =
	newServiceId<ChannelComponents>('$ChannelComponents');

export const $MacroBoxService =
	newServiceId<MacroBoxService>('$MacroBoxService');

export const $ChannelSubscriptionService =
	newServiceId<ChannelSubscriptionService>('$ChannelSubscriptionService');

export const $ChannelMessageFilterService =
	newServiceId<ChannelMessageFilterService>('$ChannelMessageFilterService');

export const $ChannelCommandsService = newServiceId<ChannelCommandsService>(
	'$ChannelCommandsService'
);
