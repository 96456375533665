import { Keyboard } from '@capacitor/keyboard';
import { IModel, inject, injectable, injectedComponent, injectProps, useService } from '@knuddels-app/DependencyInjection';
import { $Environment } from '@knuddels-app/Environment';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { LayoutView } from '@knuddels-app/layout';
import { LoadService } from '@knuddels-app/ModuleSystem';
import { OverlayPresenter } from '@knuddels-app/overlays/OverlayPresenter';
import { isNative } from '@knuddels-app/tools/isNative';
import { $AppService, SystemAppOverlayPresenter } from '@knuddelsModules/Apps';
import { NotificationBarProvider } from '@knuddelsModules/Notifications';
import { dismissKeyboard } from '@shared/helper/dismissKeyboard';
import * as React from 'react';
import { Nav } from './NavBar';
import { useTrackNetworkCondition } from './useTrackNetworkCondition';
import { StatusBar, Style } from '@capacitor/status-bar';
import { useTheme } from '@knuddels/component-library';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
import { $LocalStorage, LocalStorageKey, stringOrUndefinedSerializer } from '@knuddels-app/local-storage';
import { getScopeIfWeb } from '@knuddels-app/local-storage/sessionCookieHelper';
const loginAfterRegistrationKey = new LocalStorageKey({
  name: 'loginAfterRegistration',
  scope: getScopeIfWeb(),
  cookieExpires: {
    inDays: 365
  },
  serializer: stringOrUndefinedSerializer
});
@injectable()
class LoggedInViewModel implements IModel {
  constructor(@injectProps()
  private readonly props: unknown, @inject($Environment)
  private readonly environment: typeof $Environment.T, @inject($GenericUserEventService)
  private readonly genericUserEventService: typeof $GenericUserEventService.T, @inject($LocalStorage)
  private readonly localStorage: typeof $LocalStorage.T) {}
  componentDidMount(): void {
    const stappmessenger = this.environment.messengerSystemAppInterface;
    if (stappmessenger) {
      // this is necessary for the system app to remove the loading screen.
      stappmessenger.client.triggerAppReadiness();
    }
    const storageEntry = this.localStorage.getEntry(loginAfterRegistrationKey);
    const afterRegistration = storageEntry.getAndReset() === 'true';
    this.genericUserEventService.reportEvent({
      type: 'Login',
      afterRegistration
    });
  }
}
export const LoggedInView = injectedComponent({
  name: 'LoggedInView',
  model: LoggedInViewModel
}, () => {
  useTrackNetworkCondition();
  const keyboardService = useService($KeyboardService);
  const theme = useTheme();
  React.useEffect(() => {
    keyboardService.setViewNotResizing();
    /**
     * dismiss any pending keyboard state when the app is opened
     */
    if (isNative()) {
      Keyboard.hide();
    }
    dismissKeyboard();
  }, []);
  React.useEffect(() => {
    // splash screen fades out and while it does, it does not update the status bar
    const retries = [100, 150, 200, 250, 300, 400];
    const setColor = () => {
      StatusBar.setStyle({
        style: theme.id === 'dark' ? Style.Dark : Style.Light
      });
    };
    setColor();
    retries.forEach(timeout => setTimeout(() => setColor(), timeout));
  }, []);
  return <NotificationBarProvider>
				{globalEnv.product === 'stapp-messenger' ? <LayoutView /> : <Nav>
						<LayoutView />
					</Nav>}
				{/*
     	It is important that the `OverlayPresenter` for the logged in view is within the `IPhoneXWrapper`!
     	The logged out view has no `IPhoneXWrapper` (for UI reasons).
     	This is why we cannot put the `OverlayPresenter` in the `App` component.
     */}
				<OverlayPresenter />
				{globalEnv.product !== 'stapp-messenger' ? <LoadService service={$AppService}>
						{() => <SystemAppOverlayPresenter />}
					</LoadService> : null}
			</NotificationBarProvider>;
});