import { jsx as y, jsxs as K, Fragment as M } from "react/jsx-runtime";
import s, { useCallback as H } from "react";
import { TitleBar as j } from "../TitleBar.js";
import { useSplitView as _ } from "./SplitView.js";
import { Portal as Z } from "../Layout/Portal.js";
import { equalUpToDepth as L } from "../helper/equalUpToDepth.js";
import { ErrorWithDocsReference as q } from "../../docs/helper.js";
import { useEvent as T } from "../helper/useEvent.js";
import { clsx as G } from "../../node_modules/clsx/dist/clsx.m.js";
import "./SlideInView.scss.js";
import { useDrag as J } from "../../deps/@use-gesture/react/dist/use-gesture-react.esm.js";
import { isMobileSafari as Q, isSafariWebView as X } from "../helper/browserInfo.js";
import { IconBack as Y } from "../Icons/Back.js";
import { IconCloseCircular as p } from "../Icons/CloseCircular.js";
import { FallbackBox as ee } from "../Layout/Box/Box.js";
import { MemoryHistory as te } from "./plugins/MemoryHistory.js";
const x = s.createContext(null), ne = (e) => {
  const t = {};
  return s.Children.forEach(e, (n) => {
    t[n.props.path] = {
      ...n.props,
      component: n.props.children
    };
  }), t;
};
class re extends s.PureComponent {
  render() {
    return /* @__PURE__ */ y(
      F,
      {
        ...this.props,
        ref: (t) => {
          t && (this.props.innerRef.current[this.props.index] = t), this.props.slideInViewRefs.current.push(t);
        }
      }
    );
  }
}
const F = s.forwardRef((e, t) => {
  const n = s.useRef(
    !e.isRootView && !e.preventAnimation
  ), c = s.useRef(null);
  s.useLayoutEffect(() => {
    const r = e.slideInViewRefs.current[e.index - 1];
    r == null || r.setInactive();
  }, []), s.useImperativeHandle(
    t,
    () => ({
      key: e.item.key,
      get domElement() {
        return c.current;
      },
      setInactive: () => {
        var r, i, o;
        (o = (i = (r = c.current) == null ? void 0 : r.classList) == null ? void 0 : i.add) == null || o.call(i, "inactive");
      },
      setActive: () => {
        var r, i, o;
        (o = (i = (r = c.current) == null ? void 0 : r.classList) == null ? void 0 : i.remove) == null || o.call(i, "inactive");
      },
      animateOut: (r) => {
        var o, m, h, d;
        ((o = c.current) == null ? void 0 : o.style.display) === "none" ? r() : ((d = (h = (m = c.current) == null ? void 0 : m.classList) == null ? void 0 : h.add) == null || d.call(h, "animate-out"), setTimeout(() => {
          r();
        }, 200));
        const i = e.slideInViewRefs.current[e.index - 1];
        i == null || i.setActive();
      }
    }),
    []
  );
  const u = s.useMemo(() => ({
    pop: () => {
      var r;
      (r = e.pop) == null || r.call(e);
    },
    navigate: e.navigate,
    getParamsFor(r) {
      return e.item.params;
    },
    configureNavBar(r) {
      e.updateRoute(e.item.key, r);
    },
    updateNavBar(r) {
      const i = {
        ...e.getNavConfig(e.item.key)
      };
      return e.updateRoute(e.item.key, r), () => {
        e.updateRoute(e.item.key, i);
      };
    }
  }), [e.item, e.navigate, e.pop, e.updateRoute]), g = ae({
    navConfig: e.navConfig,
    index: e.index,
    pop: e.pop,
    close: e.onClose,
    isAnimatingRef: e.isAnimatingRef,
    defaultCloseIconStyle: e.defaultCloseIconStyle,
    inSingleView: e.inSingleView,
    isDeepLinkRoot: e.item.isDeepLinkRoot
  }), f = we({
    domRef: c,
    isAnimatingRef: e.isAnimatingRef,
    slideInViewRefs: e.slideInViewRefs,
    pop: e.pop
  });
  return /* @__PURE__ */ y(x.Provider, { value: u, children: /* @__PURE__ */ K(
    "div",
    {
      ref: c,
      "data-slide-in-view-index": e.index,
      className: G(
        Q ? "Knu-SlideInViewIOS" : "Knu-SlideInView",
        "Knu-FlexCol",
        n.current && "shouldAnimate"
      ),
      style: {
        "--SlideInZindex": e.index
      },
      children: [
        !e.hideTitleBar && /* @__PURE__ */ y(
          j,
          {
            navIcon: g,
            title: e.navConfig.title ?? "",
            subtitle: e.navConfig.subtitle,
            subtitleAdornment: e.navConfig.subtitleAdornment,
            hideShadow: e.navConfig.hideShadow,
            iconList: e.navConfig.iconList,
            fillBackground: e.navConfig.transparentTitleBar === !1
          }
        ),
        /* @__PURE__ */ y("div", { ...f(), className: "Knu-FlexCol flex-1 minHeight-0", children: e.children })
      ]
    }
  ) });
});
F.displayName = "Screen";
const ie = (e, t, n) => {
  const [c] = s.useState(() => {
    if (typeof e == "function") {
      const u = new e();
      return n.current = u.init(t).stack, u;
    } else
      return n.current = e.init(t).stack, e;
  });
  return c;
}, oe = (e, t) => {
  const n = s.useRef(/* @__PURE__ */ new Map()), [c, u] = s.useState({}), g = s.useRef(
    {}
  ), f = s.useCallback(
    (i, o) => {
      n.current.delete(i), g.current[i] = {
        ...g.current[i],
        ...o
      }, u({});
    },
    [u]
  ), r = T((i) => {
    const o = e.find((a) => a.key === i), m = g.current[i] ?? {}, d = { ...t[o == null ? void 0 : o.path] ?? {}, ...m };
    if (n.current.has(i)) {
      const a = n.current.get(i);
      if (L(a, d))
        return a;
    }
    return n.current.set(i, d), d;
  });
  return {
    updateRoute: f,
    getConfigForKey: r
  };
}, se = (e) => {
  switch (e.navIconStyle) {
    case "close":
      return /* @__PURE__ */ y(
        p,
        {
          onPress: e.onPress,
          size: "large",
          ariaLabel: "Ansicht schließen"
        }
      );
    case "back":
      return /* @__PURE__ */ y(
        Y,
        {
          onPress: e.onPress,
          size: "large",
          ariaLabel: "Zurück zur vorherigen Ansicht."
        }
      );
    default:
      return e.navIconStyle;
  }
}, ae = ({
  navConfig: e,
  inSingleView: t,
  index: n,
  pop: c,
  close: u,
  isAnimatingRef: g,
  defaultCloseIconStyle: f,
  isDeepLinkRoot: r
}) => {
  const i = t && r, o = (!t || n !== 0) && !e.finalRoute && !i, m = () => {
    var l;
    if (g.current)
      return;
    let a = !1;
    (l = e.onNavIconPress) == null || l.call(e, {
      preventDefault() {
        a = !0;
      }
    }), !a && (o ? c == null || c() : u == null || u());
  }, h = o ? "back" : u ? f : "none", d = !e.navIconStyle || e.navIconStyle === "auto" ? h : e.navIconStyle;
  return d === "none" ? void 0 : /* @__PURE__ */ y(se, { navIconStyle: d, onPress: m });
}, N = ({
  pop: e,
  innerRef: t,
  routerConfig: n,
  getConfigForKey: c,
  isAnimatingRef: u,
  navigate: g,
  updateRoute: f,
  close: r,
  defaultCloseIconStyle: i,
  inSingleView: o = !0,
  preventAnimation: m,
  containerWidthRef: h,
  hideTitleBar: d,
  stack: a
}) => {
  const l = s.useRef([]);
  return /* @__PURE__ */ y(M, { children: a.map((v, R) => {
    const w = R === 0, V = c(v.key);
    return /* @__PURE__ */ y(
      re,
      {
        innerRef: t,
        item: v,
        getNavConfig: c,
        index: R,
        slideInViewRefs: l,
        onClose: r,
        defaultCloseIconStyle: i,
        inSingleView: o,
        isAnimatingRef: u,
        hideTitleBar: V.hideTitleBar ?? d,
        preventAnimation: m || w,
        isRootView: w,
        pop: w && o ? void 0 : e,
        navigate: g,
        navConfig: V,
        updateRoute: f,
        containerWidthRef: h,
        children: n[v.path].component
      },
      v.key
    );
  }) });
}, ce = (e, t) => {
  const n = [
    e[0],
    ...e.slice(1).filter((u) => t[u.path].preventSplitView)
  ].filter(Boolean), c = e.slice(1).filter((u) => !t[u.path].preventSplitView).filter(Boolean);
  return { rootViewStack: n, splitViewStack: c };
}, ue = (e) => {
  const { rootViewStack: t, splitViewStack: n } = ce(
    e.stack,
    e.routerConfig
  );
  return /* @__PURE__ */ K(M, { children: [
    /* @__PURE__ */ y(
      N,
      {
        ...e,
        innerRef: e.rootViewRef,
        stack: t,
        pop: () => {
          e.history.popRouteWithKey(
            t[t.length - 1].key
          );
        }
      }
    ),
    /* @__PURE__ */ y(Z, { children: n.length > 0 ? /* @__PURE__ */ y(
      N,
      {
        ...e,
        innerRef: e.childViewRef,
        stack: n,
        inSingleView: !1,
        pop: () => {
          e.history.popToRoot();
        }
      }
    ) : null })
  ] });
}, le = (e) => {
  const t = s.useCallback(() => {
    e.history.pop();
  }, [e.history]);
  return /* @__PURE__ */ y(
    N,
    {
      ...e,
      innerRef: e.rootViewRef,
      pop: t,
      stack: e.stack
    }
  );
}, fe = () => _() ? ue : le, me = ({
  rerender: e,
  rootViewRef: t,
  childViewRef: n,
  previousStack: c,
  update: u,
  routerConfig: g,
  isAnimatingRef: f
}) => {
  var h, d;
  const r = new Set(u.stack.map((a) => a.key)), i = c.find(
    (a) => !r.has(a.key)
  ), o = t.current, m = n.current;
  if (!i) {
    e();
    return;
  }
  for (let a = 1; a < o.length; a++)
    if (i.key === ((h = o[o.length - a]) == null ? void 0 : h.key)) {
      f.current = !0;
      for (let l = 1; l < a; l++)
        o[o.length - 1 - l].animateOut(() => {
        });
      o[o.length - 1].animateOut(() => {
        f.current = !1;
        for (let l = 0; l < a; l++)
          o.pop();
        e();
      });
      return;
    }
  if (m.length > 1 && i.key === ((d = m[m.length - 1]) == null ? void 0 : d.key)) {
    f.current = !0, m[m.length - 1].animateOut(() => {
      f.current = !1, m.pop(), e();
    });
    return;
  }
  e();
}, de = ({
  historyPlugin: e = te,
  rootPath: t,
  initialPath: n,
  onNavigate: c,
  onClose: u,
  containerWidthRef: g,
  defaultCloseIconStyle: f = "close",
  children: r,
  hideTitleBar: i = !1,
  routerRef: o
}) => {
  const m = s.useRef([]), h = s.useRef([]), d = s.useRef(!1), a = s.useRef([]), l = s.useRef(!0), v = s.useRef(null), R = ne(r), w = ie(
    e,
    {
      routerConfig: R,
      rootPath: t,
      initialPath: n
    },
    a
  ), [V, U] = s.useReducer((C) => C + 1, 0), B = s.useCallback(() => U(null), []), I = a.current, b = I[I.length - 1], { updateRoute: O, getConfigForKey: W } = oe(
    I,
    R
  );
  s.useEffect(() => {
    l.current = !1;
    const C = w.subscribe((k) => {
      if (a.current.length <= k.stack.length) {
        a.current = k.stack, B();
        return;
      }
      me({
        previousStack: a.current,
        isAnimatingRef: d,
        update: k,
        routerConfig: R,
        rootViewRef: m,
        childViewRef: h,
        rerender: () => {
          a.current = k.stack, B();
        }
      });
    });
    return () => {
      C();
    };
  }, []), s.useEffect(() => {
    v.current && (c == null || c({
      newPath: b.path,
      oldPath: v.current.path
    })), v.current = b;
  }, [b]);
  const P = H(
    (C, { params: k = void 0 } = {}) => {
      const S = a.current[a.current.length - 1];
      if ((S == null ? void 0 : S.path) === C && L(S == null ? void 0 : S.params, k))
        return;
      const A = a.current.find(
        (D) => D.path === C && L(D.params, k)
      );
      A && w.popRouteWithKey(A.key), w.push({ params: k, path: C });
    },
    [w]
  );
  s.useImperativeHandle(o, () => ({
    navigate: P,
    pop: () => {
      w.pop();
    }
  }));
  const $ = T(() => {
    u == null || u();
  }), z = fe();
  return a.current.length === 0 ? null : /* @__PURE__ */ y(
    z,
    {
      containerWidthRef: g,
      hideTitleBar: i,
      preventAnimation: l.current,
      rootViewRef: m,
      childViewRef: h,
      isAnimatingRef: d,
      close: u ? $ : void 0,
      stack: I,
      defaultCloseIconStyle: f,
      navigate: P,
      history: w,
      routerConfig: R,
      getConfigForKey: W,
      updateRoute: O
    }
  );
}, he = (e) => {
  const t = s.useRef(0);
  return /* @__PURE__ */ y(
    ee,
    {
      className: "Knu-FlexCol flex-1 bg-contentBg position-relative size-full overflow-hidden",
      onLayout: (n) => {
        t.current = n.width;
      },
      children: /* @__PURE__ */ y(
        de,
        {
          ...e,
          routerRef: e.routerRef,
          containerWidthRef: t
        }
      )
    }
  );
};
function ye() {
  var e;
  return e = class extends s.Component {
    render() {
      return /* @__PURE__ */ y(he, { ...this.props });
    }
  }, e.Route = () => null, e;
}
function E(e, t) {
  if (!e)
    throw new q(
      `No router context found. Make sure you are using the ${t} hook in a component that is a child of a Router.`,
      "navigation/router"
    );
}
function ge() {
  return function() {
    const t = s.useContext(x);
    return E(t, "useRouter"), t;
  };
}
function ve() {
  return function(t) {
    const n = s.useContext(x);
    return E(n, "useParams"), n.getParamsFor(t);
  };
}
function Re() {
  return function() {
    const t = s.useContext(x);
    return E(t, "useNavigate"), t.navigate;
  };
}
function Te() {
  return {
    Router: ye(),
    /**
     * Hook that lets you access the router instance for navigation.
     */
    useRouter: ge(),
    /**
     * Hook that lets you access the params for a given route.
     */
    useParams: ve(),
    /**
     * Hook that lets you navigate.
     */
    useNavigate: Re(),
    /**
     * Hook that lets you configure the navigation bar.
     * Use this for dynamic configuration only. If the config is static prefer to
     * set the values on the Route component.
     */
    useConfigureNavBar: (e, t = []) => {
      const n = s.useContext(x);
      E(n, "useConfigureNavBar"), s.useLayoutEffect(() => {
        n.configureNavBar(e);
      }, t);
    }
  };
}
const we = ({
  domRef: e,
  slideInViewRefs: t,
  pop: n,
  isAnimatingRef: c
}) => {
  if (!X)
    return () => {
    };
  const u = (f) => {
    e.current.style.transition = "", e.current.style.transform = "", e.current.style.pointerEvents = "", f.domElement.style.transition = "", f.domElement.style.transform = "", f.domElement.style.pointerEvents = "", c.current = !1;
  };
  return J(
    ({
      down: f,
      movement: [r],
      direction: [i],
      velocity: o,
      initial: m,
      tap: h,
      intentional: d
    }) => {
      const a = parseInt(e.current.dataset.slideInViewIndex), l = t.current[a - 1];
      if (!(m[0] > 20 || !l)) {
        if (h) {
          u(l);
          return;
        }
        if (e.current.style.transform = `translate3d(${r}px, 0, 0)`, f)
          c.current = !0, e.current.style.transition = "none", e.current.style.animation = "none", e.current.style.transform = `translate3d(${r}px, 0, 0)`, e.current.classList.remove("shouldAnimate"), e.current.style.pointerEvents = "none", l.domElement.style.transition = "none", l.domElement.style.pointerEvents = "none", l.domElement.style.transform = `translate3d(${Math.min(r - 200, 0)}px, 0, 0)`;
        else if ((o[0] > 0.3 || r > 80) && d) {
          const v = e.current.clientWidth - r, R = Math.min(
            v / o[0],
            200
          );
          e.current.style.transition = `transform ${R}ms ease-out`, e.current.style.transform = "translate3d(100%, 0, 0)", l.domElement.style.transition = `transform ${R}ms ease-out`, l.domElement.style.transform = "translate3d(0%, 0, 0)", e.current.addEventListener(
            "transitionend",
            () => {
              e.current.style.display = "none", l.domElement.style.transition = "", l.domElement.style.transform = "", l.domElement.style.pointerEvents = "", n == null || n(), c.current = !1;
            },
            { once: !0 }
          );
        } else
          e.current.style.transition = "transform 200ms ease-out", e.current.style.transform = "translate3d(0, 0, 0)", l.domElement.style.transition = "transform 200ms ease-out", l.domElement.style.transform = "translate3d(-30%, 0, 0)", e.current.addEventListener(
            "transitionend",
            () => {
              u(l);
            },
            { once: !0 }
          );
      }
    },
    {
      bounds: { left: 20 },
      filterTaps: !0,
      rubberband: !0
    }
  );
};
export {
  Te as createRouter,
  ne as useRouterConfig
};
