import {
	inject,
	injectable,
	newServiceId,
} from '@knuddels-app/DependencyInjection';
import { Deferred, Disposable } from '@knuddels/std';
import { observable } from 'mobx';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { $UserService } from '@knuddelsModules/UserData';
import { $AppService } from '@knuddelsModules/Apps';
import { EvergreenDataChanged } from '@generated/graphql';
import { deepCopy, DeepPartial } from '@knuddels-app/evergreenData/deepCopy';
import { debounce } from '@knuddels-app/tools/debounce';
import { runInAction } from '@knuddels-app/mobx';
import { EvergreenData } from '@knuddels-app/evergreenData/index';

export const $EvergreenDataService = newServiceId<EvergreenDataService>(
	'$EvergreenDataService'
);

@injectable()
export class EvergreenDataService {
	public readonly dispose = Disposable.fn();

	public readonly deferredInitialized = new Deferred();
	@observable
	public initalized = false;

	@observable.ref
	public data: EvergreenData = {
		mixpanel: {
			superProperties: {},
			enabled: false,
		},
		loyalty: {
			currentLevel: 0,
			state: 'OK',
			card: 'NONE',
		},
		user: {
			nick: '',
			knuddelAmount: 0,
		},
		menu: {
			categories: [],
			badges: {},
			badgesValidUntil: {},
			badgesColor: {},
		},
		settings: {
			crashes: {
				ep: '',
				logIntervall: -1,
				reportFastRestartMaxMillis: -1,
			},
			crashesV2: {
				ep: '',
				trackingMode: 'off',
			},
			timing: {
				ep: '',
				commands: [],
			},
		},
		payment: {
			products: {},
		},
		actions: {
			triggers: [],
		},
	};

	private _data: EvergreenData = this.data;

	constructor(
		@inject($AuthenticatedClientService)
		private readonly clientService: typeof $AuthenticatedClientService.T,
		@inject.lazy($UserService)
		private getUserService: typeof $UserService.TLazy,
		@inject.lazy($AppService)
		private getAppService: typeof $AppService.TLazy
	) {}

	async init(): Promise<void> {
		const userService = await this.getUserService();

		this.dispose.track(
			userService.addKnuddelAmountChangedListener(newAmount => {
				this.handleUpdate({
					user: {
						knuddelAmount: newAmount,
					},
				});
			})
		);

		const user = await userService.getCurrentUser();

		this.dispose.track(
			this.clientService.currentK3Client.subscribeToPrimaryData(
				EvergreenDataChanged,
				{
					userId: user.id,
				},
				{
					next: data => {
						this.handleUpdate(
							JSON.parse(data.evergreenData ?? '{}')
						);
					},
				}
			)
		);

		this.handleUpdate({
			...JSON.parse(user.evergreenData ?? '{}'),
			user: {
				nick: user.nick,
				knuddelAmount: user.knuddelAmount ?? 0,
			},
		});
		this.initalized = true;
	}

	public handleUpdate = (update: DeepPartial<EvergreenData>) => {
		this._data = deepCopy(this._data, update);
		this.publishNewData();
	};

	private publishNewData = debounce(() => {
		runInAction('Update evergreen data', () => {
			this.data = this._data;

			if (globalEnv.product === 'app') {
				this.getAppService().then(appService => {
					const apps = appService.appViewers
						.flatMap(viewer => viewer.getAllApps())
						.filter(Boolean);
					apps.forEach(app => {
						app.updateEvergreenData(this.data);
					});
				});
			}

			if (this.deferredInitialized.state === 'none') {
				this.deferredInitialized.resolve();
			}
		});
	});
}
