import React from 'react';
import { createAccessiblePointerEventHandler, Flex, FlexProps, Text, useIsDarkColor } from '@knuddels/component-library';
export const CondensedButton: React.FC<{
  text: string;
  onPress: () => void;
} & FlexProps> = ({
  text,
  onPress,
  ...flexProps
}) => {
  const isDark = useIsDarkColor('contentLightBg');
  return <Flex bg={'contentLightBg'} px={'minor'} py={'tiny'} position={'relative'} borderRadius={'minor'} borderWidth={'large'} borderStyle={'solid'} borderColor={isDark ? 'white-transparent-220' : 'black-solid-110'} darkBorderColor={'white-transparent-220'} hoverBorderColor={isDark ? 'white-transparent-440' : 'black-solid-220'} darkHoverBorderColor={'white-transparent-440'} placeItems={'center'} animated {...createAccessiblePointerEventHandler({
    pointerCallback: onPress
  })} {...flexProps} handleDarkProps>
			<Text type={'tiny'} state={'tertiary'} bold={true} className={_c0}>
				{text.toUpperCase()}
			</Text>
		</Flex>;
};
const _c0 = "  ";