import { createCustomTheme as g } from "./createCustomTheme.js";
import { normal as u } from "../node_modules/color-blend/dist/index.modern.js";
import { createDarkTheme as T } from "./Dark.js";
import { createLightTheme as $ } from "./Light.js";
function L(t, r, e) {
  t = t / 255, r = r / 255, e = e / 255;
  var a = Math.max(t, r, e), s = Math.min(t, r, e), n = (a + s) / 2, o, h;
  if (a == s)
    o = 0, h = 0;
  else {
    var i = a - s;
    switch (h = i / (1 - Math.abs(2 * n - 1)), a) {
      case t:
        o = (r - e) / i, o = (r - e) / i % 6, o = (r - e) / i + (r < e ? 6 : 0);
        break;
      case r:
        o = (e - t) / i + 2;
        break;
      case e:
        o = (t - r) / i + 4;
        break;
    }
  }
  return o = Math.round(o * 60), h = Math.round(h * 100), n = Math.round(n * 100), [o, h, n];
}
const M = (t, r, e) => {
  r /= 100, e /= 100;
  const a = (o) => (o + t / 30) % 12, s = r * Math.min(e, 1 - e), n = (o) => e - s * Math.max(-1, Math.min(a(o) - 3, Math.min(9 - a(o), 1)));
  return [
    Math.round(255 * n(0)),
    Math.round(255 * n(8)),
    Math.round(255 * n(4))
  ];
}, R = /rgba?\((\d+).?,.?(\d+).?,.?(\d+)/, k = (t) => {
  const r = t.match(R);
  return [Number(r[1]), Number(r[2]), Number(r[3])];
}, l = 10, c = (t, r = l, e = 1) => {
  const [a, s, n] = L(t[0], t[1], t[2]), o = M(a, s, n - r);
  return `rgba(${o[0]},${o[1]},${o[2]},${e})`;
}, C = {
  white: 1,
  880: 0.88,
  660: 0.66,
  440: 0.44,
  220: 0.22,
  160: 0.16,
  150: 0.15,
  140: 0.14,
  120: 0.12,
  110: 0.11,
  "090": 0.09,
  "080": 0.08,
  "070": 0.07,
  "050": 0.05
}, _ = {
  black: 1,
  880: 0.88,
  660: 0.66,
  440: 0.44,
  330: 0.33,
  220: 0.22,
  110: 0.11,
  "040": 0.04,
  "020": 0.02
}, A = { r: 18, g: 18, b: 18, a: 1 }, E = { r: 255, g: 255, b: 255, a: 1 }, O = 255, f = 0, p = 0.05, G = 0.03, b = (t, r, e, a, s) => {
  const n = {
    r: t[0],
    g: t[1],
    b: t[2],
    a: r
  }, o = u(e, n);
  return [
    Object.keys(s).reduce(
      (h, i) => {
        const d = s[i], m = u(o, {
          r: a,
          g: a,
          b: a,
          a: d
        });
        return {
          ...h,
          [i]: `rgba(${m.r},${m.g},${m.b},1)`
        };
      },
      {}
    ),
    `rgba(${o.r},${o.g},${o.b},${o.a})`
  ];
}, v = (t, r) => {
  const [e, a] = b(
    t,
    p,
    A,
    O,
    C
  );
  return T(
    r,
    { ...e, white: "rgba(255, 255, 255, 1)" },
    a
  );
}, I = (t, r) => {
  const [e, a] = b(
    t,
    G,
    E,
    f,
    _
  );
  return $(
    r,
    { ...e, black: "rgba(0,0,0,1)" },
    a
  );
}, S = (t, r, e = 1) => {
  const a = k(t), s = c(a), n = c(a, 5), o = c(a, 15), h = c(a, 0, 0.6), i = c(a, l, 0.6), d = r.id === "dark" ? v(a, e) : I(a, e);
  return g(d, {
    colors: {
      basic: { accent: t, accentHover: n },
      texts: {
        light: {
          highlighted: t
        },
        dark: {
          highlighted: t
        }
      },
      icons: {
        light: {
          highlighted: t
        },
        dark: {
          highlighted: t
        }
      }
    },
    buttons: {
      primary: {
        colors: {
          background: `linear-gradient(to bottom,${t},${s})`,
          hover: `linear-gradient(to bottom,${n},${o})`,
          disabled: `linear-gradient(to bottom,${h},${i})`
        }
      }
    }
  });
};
export {
  c as calculateGradient,
  S as createThemeForAccentColor,
  k as parseRgbString
};
