import { ChannelMessageFragment } from '@generated/graphql';
import { ThemeColors, ThemeContext, ThemeOverride, useIsDarkColor, useTheme } from '@knuddels/component-library';
import { FormattedText } from '@shared/components';
import * as React from 'react';
import { useContext } from 'react';
import { ChatRenderConfig } from '../../ChatRenderConfig';
import { ChannelBackgroundColorContext } from '../ChannelBackground';
import { MessageClickableSender } from '../MessageClickableSender';
import { ActionMessageFragment } from '../Messages/ActionMessage';
import { CondensedFormattedText, CondensedMessage } from '../Messages/CondensedMessage';
import { PrivateGroupMessageFragment } from '../Messages/PrivateGroupMessage';
import { PublicMessage } from '../Messages/PublicMessage';
import { SystemMessage } from '../Messages/SystemMessage';
type NormalChannelMessage = Exclude<ChannelMessageFragment, ActionMessageFragment | PrivateGroupMessageFragment>;
type Props = {
  message: NormalChannelMessage;
  firstOfSet: boolean;
  animated: boolean;
  isStackedLayout: boolean;
  renderConfig: ChatRenderConfig;
};
const renderCondensedMessage = (message: NormalChannelMessage, renderConfig: ChatRenderConfig) => {
  switch (message.__typename) {
    case 'ChannelMsgPublic':
      return <CondensedMessage>
					<CondensedFormattedText message={message} renderConfig={renderConfig} />
				</CondensedMessage>;
    case 'ChannelMsgSystem':
      return <CondensedMessage>
					<CondensedFormattedText message={message} renderConfig={renderConfig} isSystemMessage={message.__typename === 'ChannelMsgSystem'} />
				</CondensedMessage>;
    default:
      {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const checkTypeToNotForgetUpdatingThis: never = message;
      }
  }
};
const InnerChannelGroupMessage: React.FC<Props & {
  renderChannelMessage: (message: NormalChannelMessage) => React.ReactNode;
}> = props => {
  const theme = useTheme();
  const isParentDark = useIsDarkColor((useContext(ChannelBackgroundColorContext) as ThemeOverride));
  const {
    isStackedLayout,
    firstOfSet,
    message
  } = props;
  const renderMode = props.renderConfig.renderMode;
  if (renderMode === 'condensed') {
    const condensedMessage = renderCondensedMessage(message, props.renderConfig);
    if (condensedMessage) {
      return condensedMessage;
    }
  }
  const renderedMessage = props.renderChannelMessage(message);
  const bubbleColors: { [key in NormalChannelMessage['__typename']]: ThemeColors } = {
    ChannelMsgPublic: isParentDark ? 'black-transparent-440' : 'white-transparent-440',
    ChannelMsgSystem: 'accent'
  };
  const bubbleColor = bubbleColors[message.__typename];
  return <>
			<MessageClickableSender align={'left'} uid={props.message.sender.id} isStackedLayout={isStackedLayout} bubbleColor={renderMode === 'modernWithoutBubbles' ? isParentDark ? theme.colors.basic.transparentDark : theme.colors.basic.transparentLight : theme.colors.basic[bubbleColor] ?? bubbleColor} noBubblePadding={renderMode === 'modernWithoutBubbles'} showImage={firstOfSet} hasArrow={firstOfSet}>
				{renderedMessage}
			</MessageClickableSender>
		</>;
};
export class ChannelMessageGroup extends React.PureComponent<Props> {
  static contextType = ThemeContext;
  public static getKey(message: NormalChannelMessage): string {
    // adding timestamp because multiple messages may have the same messageId (private channel?)
    return `${message.id}-${(message as any).timestamp || -1 // TECHDEBT timestamp in message
    }`;
  }
  public render(): React.ReactElement {
    return <InnerChannelGroupMessage {...this.props} renderChannelMessage={this.renderChannelMessage} />;
  }
  private readonly renderChannelMessage = (message: NormalChannelMessage): React.ReactNode => {
    const sharedProps = {
      sender: this.props.message.sender
    };
    const isFirst = this.props.firstOfSet;
    switch (message.__typename) {
      case 'ChannelMsgPublic':
        return <PublicMessage key={message.id} {...sharedProps} isFirst={isFirst} text={FormattedText.fromJsonString(message.formattedText)} />;
      case 'ChannelMsgSystem':
        return <SystemMessage key={message.id} {...sharedProps} isFirst={isFirst} text={FormattedText.fromJsonString(message.formattedText)} channelId={message.sentFromChannel.id} renderConfig={this.props.renderConfig} />;
      default:
        {
          // Don't throw here or we will block backend updates if we want to allow other messages.
          // Check with never to get type/compile errors. We don't want to throw warnings
          // with `shouldBeUnreachable` because it is called frequently in render.
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const checkTypeToNotForgetUpdatingThis: never = message;
          return [];
        }
    }
  };
}