import { ActiveChannelFragment, Channel, ChannelEvents, ChannelMessageFragment, GetChannelForCurrentSession, GetUserIsAppBot, LeaveChannel } from '@generated/graphql';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { $FirstLoginStorage } from '@knuddels-app/Connection/serviceIds';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $ScreenService } from '@knuddels-app/Screen';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { $I18n } from '@knuddels-app/i18n';
import { $ViewService } from '@knuddels-app/layout';
import { action, autorun, observable } from '@knuddels-app/mobx';
import { $OverlayService } from '@knuddels-app/overlays';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { declareFormat } from '@knuddels/i18n';
import { Disposable } from '@knuddels/std';
import { $AppService, $GlobalAppsAppViewer } from '@knuddelsModules/Apps';
import { $AutocompleteProviderService } from '@knuddelsModules/AutocompleteInputBar';
import { $ChannelMessageFilterService, $ChannelSubscriptionService, $JoinChannelService } from '@knuddelsModules/Channel';
import { channelViewId } from '@knuddelsModules/Channel/ChannelViewProvider';
import { AdultChannelContent } from '@knuddelsModules/Channel/bundle/components/Lightboxes/AdultChannelContent';
import { channelListViewId } from '@knuddelsModules/ChannelList';
import { $NewMessageService, ClientMessengerMessage, MessengerInterfaces, messengerViewId } from '@knuddelsModules/Messenger';
import { globalAppViewId } from '@knuddelsModules/SystemApps';
import { $UserService } from '@knuddelsModules/UserData';
import React from 'react';
import { ChannelInfo } from './ChannelInfo';
import { ChannelInfoManager } from './ChannelInfoManager';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { isTouchDevice, rgb } from '@knuddels/component-library';
import { computed } from 'mobx';
export type ActiveChannelData = ActiveChannelFragment;
export type ActiveChannelMessage = ChannelMessageFragment | MessengerInterfaces.ClientMessengerMessage;
export type ChannelEvent<T = Record<any, any>> = {
  channel: {
    id: Channel['id'];
  };
} & T;
export type ChannelState = {
  kind: 'active';
} | {
  kind: 'disconnected-by-other-session';
} | {
  kind: 'disconnected-by-server';
} | {
  kind: 'noChannel';
};
@injectable()
export class ActiveChannelService implements Disposable {
  public readonly dispose = Disposable.fn();
  private visitedChannelNames = new Map<string, boolean>();
  private channelViewInitialized = false;
  get isChannelViewInitialized(): boolean {
    return this.channelViewInitialized;
  }
  private channelInfoManager = new ChannelInfoManager(this.authenticatedClientService.currentK3Client, this.i18n, this.firebaseAnalyticsService);
  public get activeChannel(): ChannelInfo | undefined {
    return this.channelInfoManager.activeChannel;
  }
  @computed
  public get backgroundColorAsRgb(): string | null {
    if (!this.activeChannel) {
      return null;
    }
    return rgb(this.activeChannel.groupInfo.backgroundColor.red, this.activeChannel.groupInfo.backgroundColor.green, this.activeChannel.groupInfo.backgroundColor.blue);
  }
  @observable
  private _state: ChannelState = {
    kind: 'active'
  };
  public get state(): ChannelState {
    return this._state;
  }
  constructor(@inject($AuthenticatedClientService)
  private readonly authenticatedClientService: typeof $AuthenticatedClientService.T, @inject($UserService)
  private readonly userService: typeof $UserService.T, @inject($I18n)
  private i18n: typeof $I18n.T, @inject($ViewService)
  private readonly viewService: typeof $ViewService.T, @inject($NewMessageService)
  newMessageService: typeof $NewMessageService.T, @inject($SnackbarService)
  private readonly snackbarService: typeof $SnackbarService.T, @inject($AutocompleteProviderService)
  autocompleteProviderService: typeof $AutocompleteProviderService.T, @inject($FirebaseAnalyticsService)
  private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T, @inject.lazy($AppService)
  private readonly getAppService: typeof $AppService.TLazy, @inject.lazy($GlobalAppsAppViewer)
  private readonly getGlobalAppsAppViewer: typeof $GlobalAppsAppViewer.TLazy, @inject($ScreenService)
  private readonly screenService: typeof $ScreenService.T, @inject($ChannelSubscriptionService)
  private readonly channelSubscriptionService: typeof $ChannelSubscriptionService.T, @inject($ChannelMessageFilterService)
  private readonly channelMessageFilterService: typeof $ChannelMessageFilterService.T, @inject($FirstLoginStorage)
  private readonly firstLoginStorage: typeof $FirstLoginStorage.T, @inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T, @inject($KeyboardService)
  private readonly keyboardService: typeof $KeyboardService.T, @inject.lazy($JoinChannelService)
  private readonly joinChannelService: typeof $JoinChannelService.TLazy) {
    this.dispose.track(autocompleteProviderService.registerUserProvider(() => this.activeChannel ? Array.from(this.activeChannel.participants.values()).map(it => it.user) : []));
    this.dispose.track(newMessageService.onNewMessage.sub(({
      conversationMessage,
      conversation
    }) => {
      const obj: ClientMessengerMessage = {
        __typename: 'CLIENT_MESSENGER_MESSAGE',
        message: conversationMessage,
        id: conversationMessage.id,
        conversation
      };
      if (this.activeChannel && !this.viewService.isViewVisible(messengerViewId)) {
        switch (obj.message.content.__typename) {
          case 'ConversationMentorAchievedMessageContent':
          case 'ConversationPrivateSystemMessageContent':
          case 'ConversationBirthdayMessageContent':
          case 'ConversationNicknameChangeMessageContent':
            // don't show those messages in channel (yet)
            break;
          default:
            this.activeChannel.addMessage(obj);
            this.activeChannel.addBotMessage(obj);
        }
      }
    }));
    this.dispose.track(this.channelSubscriptionService.onChannelEvent.sub(event => this.handleChannelEvents(event)).dispose);
    this.dispose.track(autorun({
      name: 'Close global app on leaving channel'
    }, async () => {
      const globalAppsAppViewer = await this.getGlobalAppsAppViewer();
      const app = globalAppsAppViewer.app;
      const state = this.state.kind;
      if (app && app.appReady && !app.nonChannelApp && (state === 'disconnected-by-other-session' || state === 'disconnected-by-server' || state === 'noChannel')) {
        app.close();
        if (screenService.isStackedLayout && viewService.isViewVisible(globalAppViewId)) {
          snackbarService.showErrorSnackbarWithDefaults({
            type: 'globalAppClosed',
            subtext: i18n.format(declareFormat({
              id: 'channel.globalAppClosedByDisconnect',
              defaultFormat: 'The app was closed because the connection to the channel has been lost.'
            }))
          });
        }
      }
    }));
  }
  public setViewInitialized = (): void => {
    this.channelViewInitialized = true;
  };
  public hasChannelBeenVisited(channelName: string): boolean {
    return this.visitedChannelNames.has(channelName);
  }
  public markChannelAsVisited(channelName: string): void {
    this.visitedChannelNames.set(channelName, true);
  }
  @action
  public async initializeActiveChannel(channel: ActiveChannelData): Promise<void> {
    if (isTouchDevice()) {
      this.keyboardService.dismissKeyboard();
    }

    // will be set to true by the view to avoid race conditions
    this.channelViewInitialized = false;
    this.visitedChannelNames.set(channel.name, true);
    this.channelMessageFilterService.disableAllFilters();
    const appService = await this.getAppService();
    if (!this.activeChannel || this.activeChannel.id !== channel.id) {
      if (this.activeChannel) {
        // Note: we can't be 100% sure if this is called before new appOpen events
        // which is why we don't close apps bound to the new channel.
        appService.closeAllAppsExceptForChannel(channel.name);
        this.firstLoginStorage.resetFirstLoginAfterRegistration();
      }
      this.channelInfoManager.initChannel(channel);
      this.setState({
        kind: 'active'
      });
      this.handleRecentMessages(channel);
    } else {
      this.activeChannel.updateData(channel);
      this.setState({
        kind: 'active'
      });
    }
  }
  private handleRecentMessages = (channel: ActiveChannelData) => {
    if (channel.recentMessages.length <= 0) {
      return;
    }
    channel.recentMessages.forEach(message => {
      this.handleChannelMessage(channel.id, message);
    });
  };
  @action
  public async clearActiveChannel(): Promise<void> {
    this.channelMessageFilterService.disableAllFilters();

    // currently we can't really make apps work without a channel
    if (this.channelInfoManager.activeChannel) {
      const appService = await this.getAppService();
      appService.closeChannelBoundApps(this.activeChannel.name);
    }
    this.channelInfoManager.clearChannel();
    this.setState({
      kind: 'noChannel'
    });
    if (this.viewService.findView(channelViewId)) {
      this.viewService.openView(channelViewId.with(s => s.clearChannel()), {
        locationUpdateMode: 'push'
      });
    }
  }
  @action
  public setState(state: ChannelState): void {
    if (this._state === state) {
      return;
    }
    this._state = state;
  }
  public leaveChannel = (): void => {
    this.authenticatedClientService.currentK3Client.mutateWithResultPromise(LeaveChannel, {}).match({
      ok: async () => {
        await this.clearActiveChannel();
      },
      error: () => {
        this.snackbarService.showGenericError();
      }
    });
  };
  private async handleChannelEvents(event: typeof ChannelEvents.TPrimaryResult): Promise<void> {
    switch (event.__typename) {
      case 'ChannelUserJoined':
        {
          if (this.userService.isCurrentUser(event.participant.user.id)) {
            // We handle /go in the server and the client only notices its success through this event.
            // Then we need to query and initialize the channel or else the user is falsely stuck in the
            // original channel without actually being connected thus not being able to write there.

            if (this.state.kind === 'active') {
              // maybe need to handle error case (query fails) => retry?
              this.authenticatedClientService.currentK3Client.queryWithResultPromise(GetChannelForCurrentSession, {
                pixelDensity: getPixelRatio()
              }, 'network-only').onOk(async data => {
                // Prevents being stuck in invalid channel if ChannelDisconnectEvent arrives during the query
                if (this.state.kind === 'active' && data && data.id === event.channel.id) {
                  if (data.groupInfo.isAdultChannel && !this.hasChannelBeenVisited(data.name)) {
                    this.displayAdultChannelWarning(data);
                  }
                  this.joinChannelService().then(joinChannelService => {
                    joinChannelService.logJoinChannel(data, 'SlashCommand');
                  });
                  await this.initializeActiveChannel(data);
                }
              });
            }
          }
          this.channelInfoManager.handle(event.channel.id, channelInfo => channelInfo.addUser(event.participant));
          break;
        }
      case 'ChannelUserLeft':
        {
          if (this.userService.isCurrentUser(event.user.id)) {
            // Do nothing to prevent flickering
            // (e.g. user is removed from nicklist shortly before he joins another)
          } else {
            this.channelInfoManager.handle(event.channel.id, channelInfo => {
              channelInfo.removeUser(event.user.id);
              channelInfo.nicklistState.clearNicklistState(event.user.id);
            });
          }
          break;
        }
      case 'ChannelMessageReceived':
        {
          await this.handleChannelMessage(event.channel.id, event.msg);
          break;
        }
      case 'NicklistPriorityChanged':
        {
          this.channelInfoManager.handle(event.channel.id, channelInfo => channelInfo.updatePriority(event.user.id, event.nicklistPriority));
          break;
        }
      case 'NicklistIconsAdded':
        {
          this.channelInfoManager.handle(event.channel.id, channelInfo => event.participants.forEach(participant => {
            channelInfo.nicklistState.updateNicklistState(participant.user.id, participant.iconsToAdd, participant.nicklistColor);
          }));
          break;
        }
      case 'NicklistIconRemoved':
        {
          this.channelInfoManager.handle(event.channel.id, channelInfo => channelInfo.nicklistState.removeIcon(event.user.id, event.iconName));
          break;
        }
      default:
      // Don't throw here or we will block backend updates that extend those events.
    }
  }

  private isChannelInBackground = (channelId: string): boolean => {
    if (!this.channelViewInitialized) {
      return false;
    }
    return this.activeChannel?.id !== channelId || this.viewService.isViewInBackground(channelViewId);
  };
  private handleChannelMessage = async (channelId: string, message: ChannelMessageFragment) => {
    if (this.channelMessageFilterService.shouldFilterChannelMessage(message)) {
      return;
    }
    const activeChannel = this.channelInfoManager.activeChannel;
    if (message.__typename === 'ChannelMsgPrivateGroup') {
      // The channel for the message type is the one in which the
      // message was sent, so we always need to add it to the
      // current channel.
      if (activeChannel) {
        activeChannel.addMessage(message);
        activeChannel.addBotMessage(message);
      }
      return;
    } else if (message.__typename === 'ChannelMsgSystem') {
      if (activeChannel) {
        activeChannel.addBotMessage(message);
      }
    }
    const isChannelInBackground = this.isChannelInBackground(channelId);
    if (!activeChannel) {
      this.channelInfoManager.handle(channelId, channelInfo => channelInfo.addMessage(message, isChannelInBackground));
      return;
    }

    // Message sent from same channel
    if (activeChannel.id === channelId) {
      activeChannel.addMessage(message, isChannelInBackground);
      return;
    }

    // AppBots can send messages to other channels
    if (await this.isAppBot(message.sender.id)) {
      activeChannel.addMessage(message, isChannelInBackground);
      return;
    }
    this.channelInfoManager.handle(channelId, channelInfo => channelInfo.addMessage(message, isChannelInBackground));
  };
  public isAppBot = async (userId: string): Promise<boolean> => {
    const result = await this.authenticatedClientService.currentK3Client.queryWithResultPromise(GetUserIsAppBot, {
      userId
    }, 'cache-first');
    return result.match({
      ok: r => r.isAppBot,
      error: () => false
    });
  };
  private displayAdultChannelWarning = (channel: ActiveChannelData): void => {
    const prevChannelId = this.activeChannel?.id;
    this.overlayService.showOverlay({
      view: <AdultChannelContent blurBackdrop onCancel={() => {
        this.visitedChannelNames.delete(channel.name);
        if (prevChannelId) {
          this.joinChannelService().then(s => {
            s.joinChannelById(prevChannelId, 'AdultChannelWarningCancel');
          });
        } else {
          this.viewService.openView(channelListViewId, {
            openContext: 'adult-channel-warning'
          });
        }
      }} />,
      keepOnLocationChange: true
    });
  };
  // tslint:disable-next-line: max-file-line-count
}