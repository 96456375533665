import * as React from 'react';
import { ChildThemeProvider, createSvgIcon, Flex, IconClose, IconSearch, Input, useChildTheme, useTheme, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
import { useService } from '@knuddels-app/DependencyInjection';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { getSafeArea } from '@knuddels-app/SafeArea';
import { $LocationService } from '@knuddels-app/location';
export const SearchBar: React.FC<{
  value: string;
  onChange: (newValue: string) => void;
  onSubmit: () => void;
  onClose: () => void;
}> = ({
  value,
  onChange,
  onSubmit,
  onClose
}) => {
  const theme = useTheme();
  const isDarkTheme = theme.id === 'dark';
  const genericUserEventService = useService($GenericUserEventService);
  const locationService = useService($LocationService);
  const childTheme = useChildTheme({
    sizes: {
      border: {
        base: 0
      }
    },
    colors: {
      basic: {
        inputFieldBg: isDarkTheme ? 'rgba(0,0,0,0)' : 'rgba(255, 255, 255, 1)'
      }
    }
  }, []);
  const track = useLogEvent();
  React.useEffect(() => {
    track('ChannelSelection_Navigation', 'Titlebar_SearchOpened');
    genericUserEventService.reportEvent({
      type: 'ChannelSelection_Opened_Search'
    });
    const index = locationService.pushHash('search', true);
    const unsub = locationService.historyListen(() => {
      if (history.state.idx < index) {
        onClose();
      }
    });
    return () => {
      unsub();
      if (history.state.idx === index) {
        locationService.pop();
      }
    };
  }, []);
  const safeAreaTop = getSafeArea().safeAreaTopHeight;
  return <div style={{
    height: resolveThemingValue(56 + safeAreaTop, "sizes", useTheme()),
    paddingTop: resolveThemingValue((safeAreaTop as any), "spacing", useTheme())
  }} className={_c0 + ("inputFieldBg" ? resolveIsDarkColor("inputFieldBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<IconSearch size={'large'} />
			<ChildThemeProvider theme={childTheme} styles={{
      width: '100%',
      height: 48,
      minHeight: 0
    }}>
				<Input placeholder={formatMessage(declareFormat({
        id: 'channellist.search.input.placeholder'
      }))} value={value} onChange={({
        value: newValue
      }) => onChange(newValue)} {...({
        className: 'title'
      } as any)} autoFocus onKeyPress={e => {
        if (e.key === 'Enter') {
          onSubmit();
        }
      }} />
			</ChildThemeProvider>
			{value.length > 0 && <IconEnter key={'enter'} size={'large'} onPress={onSubmit} color={'accent'} />}
			<IconClose size={'large'} onPress={onClose} />
		</div>;
};
const IconEnter = createSvgIcon(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000">
		<path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 11 L 3 13 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 5 C 21 3.9069372 20.093063 3 19 3 L 5 3 z M 5 5 L 19 5 L 19 19 L 5 19 L 5 13 L 11.585938 13 L 9.2929688 15.292969 L 10.707031 16.707031 L 15.414062 12 L 10.707031 7.2929688 L 9.2929688 8.7070312 L 11.585938 11 L 5 11 L 5 5 z" fill="currentColor" />
	</svg>);
IconEnter.displayName = 'IconEnter';
const _c0 = " Knu-Flex alignItems-center justifyContent-center bg-inputFieldBg pl-base ";