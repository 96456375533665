import { ConversationMessageFragment, ConversationNestedMessageFragment, FullConversationFragment } from '@generated/graphql';
import { IModel, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { $I18n, declareFormat, formatMessage } from '@knuddels-app/i18n';
import { action, reaction } from '@knuddels-app/mobx';
import { FlexCol, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { Disposable } from '@knuddels/std';
import { $SmileyboxService } from '@knuddelsModules/Smileybox';
import { $UserService } from '@knuddelsModules/UserData';
import { Focusable, isUsingTouch, Z_INDEX } from '@shared/components';
import { IconCamera } from '@shared/icons/IconCamera';
import * as React from 'react';
import { $MessengerConversationService, $MessengerImageService, $MessengerMiniChatService, $SendMessageService } from '../../../../providedServices';
import { conversationInputEvent } from '../../../analytics';
import { ClientConversationState } from '../../../services/conversationServices/MessengerConversationService';
import { UploadImageLightboxSource } from '../Lightbox/Lightbox';
import { InputBarImageSenderHelper } from './InputBarImageSenderHelper';
import { InputBarQuoteExtension } from './InputBarQuoteExtension';
import { SnackbarCameraReceiverU16, SnackbarCameraSenderU16 } from './SnackbarCameraU16';
import { useAutoFocus } from './useAutoFocus';
import { MiniChatContext } from '../../MiniChat/MiniChatContext';
import { RichInput } from '@shared/components/RichInput';
import { debounce } from '@knuddels-app/tools/debounce';
// tslint:disable-next-line: no-module-bleeding
import { ChatInputPlugins } from '@knuddelsModules/Channel/bundle/components/Chat/ChatInput/ChatInputPlugins';
import { RichTextEditorRef } from '@shared/components/RichText/RichTextEditor';
import { EditorState } from 'lexical';
import { useRegisterBottomInputContext } from '../../../../../../app/RegisterBottomInputContext';
import { InsideModalContext, useInsideModal } from '@knuddels-app/overlays/InsideModalContext';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
interface Props {
  conversation: FullConversationFragment;
  indent: boolean;
  disabled?: boolean;
  messageSent(): void;
  setLightboxSrc?(src: UploadImageLightboxSource): void;
}
const CONVERSATION_CHARACTER_LIMIT = 5000;
@injectable()
export class ConversationInputModel implements IModel {
  public readonly dispose = Disposable.fn();
  private focusable: Focusable | undefined;
  private readonly clientConversationState: ClientConversationState;
  private readonly imageSender: InputBarImageSenderHelper;
  private lastSendTyping = 0;
  private readonly debouncedSendStopTyping = debounce(() => {
    this.lastSendTyping = 0;
    this.clientConversationState.sendStopTyping();
  }, 2000);
  constructor(@injectProps()
  private readonly props: Props, @inject($MessengerConversationService)
  private readonly messengerConversationService: typeof $MessengerConversationService.T, @inject($MessengerImageService)
  messengerImageService: typeof $MessengerImageService.T, @inject($FirebaseAnalyticsService)
  private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T, @inject($SendMessageService)
  private readonly sendMessageService: typeof $SendMessageService.T, @inject($UserService)
  private readonly userService: typeof $UserService.T, @inject($SnackbarService)
  private readonly snackbarService: typeof $SnackbarService.T, @inject($I18n)
  private readonly i18n: typeof $I18n.T, @inject($GenericUserEventService)
  private readonly genericUserEventService: typeof $GenericUserEventService.T) {
    this.clientConversationState = this.messengerConversationService.getOrCreateClientConversationState(props.conversation.id);
    this.dispose.track(reaction({
      name: 'Focus input bar on message quote'
    }, () => this.clientConversationState.selectedMessageToQuote, selectedMessageToQuote => {
      if (selectedMessageToQuote && this.focusable) {
        this.focusable.focus();
      }
    }));
    this.imageSender = new InputBarImageSenderHelper(messengerImageService, this.clientConversationState, snackbarService, i18n, props.setLightboxSrc, () => props.conversation.otherParticipants, this.genericUserEventService);
  }
  public get conversation(): FullConversationFragment {
    return this.props.conversation;
  }
  public get indent(): boolean {
    return this.props.indent;
  }
  public get disabled(): boolean {
    return this.props.disabled;
  }
  public handleCameraClick = (inMiniChat: boolean) => {
    switch (this.u16CameraState) {
      case 'sender-u16':
        this.snackbarService.showSnackbar(SnackbarCameraSenderU16);
        break;
      case 'receiver-u16':
        this.snackbarService.showSnackbar(SnackbarCameraReceiverU16);
        break;
      default:
        this.selectPicture(inMiniChat ? 'mini-messenger' : 'messenger');
    }
  };
  public get u16CameraState(): 'sender-u16' | 'receiver-u16' | 'normal' {
    if (this.userService.currentUser && this.userService.currentUser.age < 16) {
      return 'sender-u16';
    }
    const participants = this.conversation.otherParticipants;
    if (participants && participants.some(user => user.age < 16)) {
      return 'receiver-u16';
    }
    return 'normal';
  }
  sendText = async (originalText: string): Promise<void> => {
    const text = originalText.trim().replace(/\n/g, '#');
    const backendText = this.convertToBackendMentionLinks(text);
    const quotingMessage = this.clientConversationState.selectedMessageToQuote;
    const participants = this.conversation.otherParticipants;
    if (participants && participants.length > 0) {
      if (quotingMessage) {
        this.sendMessageService.quoteMessage(this.conversation.id, quotingMessage, backendText);
        this.clearQuote();
      } else {
        this.sendMessageService.sendMessage(this.conversation.id, backendText);
      }
      this.props.messageSent();
    }
  };
  onChange = (text: string): void => {
    const now = Date.now();
    const conversation = this.props.conversation;
    // Don't send typing if text changed through submit
    if (text.length > 0 && now - this.lastSendTyping > 30000 && conversation) {
      this.clientConversationState.sendTyping();
      this.lastSendTyping = now;
    }
    this.debouncedSendStopTyping();
  };
  public readonly handleInputBarFocused = (): void => {
    conversationInputEvent.track('Inputbar_Focused');
  };
  public readonly handleInputBarUnfocused = (): void => {
    conversationInputEvent.track('Inputbar_Unfocused');
  };
  public getQuote = (): ConversationMessageFragment | ConversationNestedMessageFragment | undefined => {
    const quotingMessage = this.clientConversationState.selectedMessageToQuote;
    return quotingMessage && quotingMessage.content.__typename === 'ConversationForwardedMessageContent' ? quotingMessage.content.nestedMessage : quotingMessage;
  };
  public clearQuote = (): void => {
    conversationInputEvent.track('Inputbar_QuoteRemoved');
    this.clientConversationState.clearSelectedMessageToQuote();
  };
  public validateInput = (text: string): boolean => {
    return text.trim().length > 0;
  };
  public readonly trackSubmitButtonClicked = (): void => {
    conversationInputEvent.track('InputBar_SendButtonUsed');
  };
  public readonly trackReturnKeySubmit = (): void => {
    conversationInputEvent.track('InputBar_EnterUsed');
  };
  public readonly handleLineBreakAdded = (): void => {
    conversationInputEvent.track('InputBar_LinebreakAdded');
  };
  public readonly selectPicture = (context: 'messenger' | 'mini-messenger'): void => {
    conversationInputEvent.track('InputBar_SendImage');
    this.imageSender.selectPicture(context);
  };
  public handleSmileyboxClosed(): void {
    conversationInputEvent.track('SmileyboxClosed');
  }
  public handleSmileyboxOpened(): void {
    conversationInputEvent.track('SmileyboxOpened');
  }
  @action.bound
  public onInputBarReady(focusable: Focusable): void {
    this.focusable = focusable;
  }
  public trackAutoComplete = (itemId: string): void => {
    this.firebaseAnalyticsService.logEvent('Messenger_Conversation', itemId);
  };
  private convertToBackendMentionLinks = (text: string): string => {
    return text.replace(/\$\$(.+?)\$\$/g, '{$1}');
  };
}
const drafts = new Map<string, EditorState>();
const createSetDraft = (conversationId: string) => debounce((text: string, state: EditorState) => {
  if (text.trim().length === 0) {
    drafts.delete(conversationId);
    return;
  }
  drafts.set(conversationId, state);
});
const useDraft = (conversationId: string) => {
  const ref = React.useRef<RichTextEditorRef>(null);
  const setDraft = React.useMemo(() => createSetDraft(conversationId), [conversationId]);
  React.useEffect(() => {
    if (drafts.has(conversationId)) {
      ref.current?.setState(drafts.get(conversationId)!);
      ref.current?.focus();
    }
  }, [conversationId]);
  return {
    ref,
    setDraft,
    hasDraft: () => drafts.has(conversationId),
    removeDraft: () => drafts.delete(conversationId)
  };
};
export const ConversationInput = injectedComponent({
  name: 'ConversationInput',
  model: ConversationInputModel,
  inject: {
    smileybox: $SmileyboxService,
    miniChatService: $MessengerMiniChatService
  }
}, ({
  model,
  miniChatService
}) => {
  const quote = model.getQuote();
  const autoFocus = useAutoFocus();
  const insideModal = useInsideModal();
  const isMiniChatContext = React.useContext(MiniChatContext);
  const registerBottomInput = useRegisterBottomInputContext();
  const {
    ref,
    setDraft,
    hasDraft,
    removeDraft
  } = useDraft(model.conversation.id);
  const shouldAutoFocus = hasDraft() || !isUsingTouch() || autoFocus && !isMiniChatContext ? true : miniChatService.shouldAutoFocus;
  React.useEffect(() => {
    if (!isMiniChatContext) {
      return registerBottomInput();
    }
  }, []);
  return <>
				{quote && <InputBarQuoteExtension quotedMessage={quote} conversation={model.conversation} indent={model.indent} clearQuote={model.clearQuote} />}
				<div style={{
      zIndex: resolveThemingValue(Z_INDEX.HIGHEST_CONTENT, "theme", useTheme())
    }} className={_c0}>
					<InsideModalContext.Provider value={insideModal && !isMiniChatContext}>
						<RichInput context={isMiniChatContext ? 'miniChat' : 'messenger'} editorRef={ref} adornmentRight={<CameraIcon onClick={() => model.handleCameraClick(isMiniChatContext)} u16State={model.u16CameraState} />} useModal={isMiniChatContext ? true : undefined} onChange={(text: string, state: EditorState) => {
          model.onChange(text);
          setDraft(text, state);
        }} placeholder={formatMessage(declareFormat({
          id: 'CHAT_PLACEHOLDER_WITH_NICK'
        }), {
          nick: model.conversation.otherParticipants[0].nick
        })} autoFocus={shouldAutoFocus} onSubmit={(text: string) => {
          model.sendText(text);
          removeDraft();
        }} plugins={ChatInputPlugins} characterLimit={CONVERSATION_CHARACTER_LIMIT} />
					</InsideModalContext.Provider>
				</div>
			</>;
});
type U16State = 'sender-u16' | 'receiver-u16' | 'normal';
function CameraIcon(props: {
  onClick: () => void;
  u16State: U16State;
}): JSX.Element {
  return <IconCamera size={'large'} onPress={props.onClick} disabled={props.u16State !== 'normal'} />;
}
const _c0 = " Knu-FlexCol shadow-Shadow3 position-relative ";