import * as React from 'react';
import { Flex, FlexCol, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { observer } from '@knuddels-app/mobx';
import defaultPic from '@shared/images/nopic.png';
import { useReleaseImage } from '@knuddels-app/tools/useReleaseImage';
type Props = {
  size: number;
  url?: string;
  blurUrl?: string;
};
export const AlbumPhoto: React.FC<Props> = observer('AlbumPhoto', props => {
  const releaseRef = useReleaseImage();
  return <div style={{
    minWidth: resolveThemingValue(`${props.size}px`, "sizes", useTheme())
  }} className={_c0}>
			<div ref={releaseRef} style={{
      backgroundImage: `url(${props.url ?? defaultPic})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center top',
      height: resolveThemingValue(props.size, "sizes", useTheme()),
      width: resolveThemingValue(props.size, "sizes", useTheme())
    }} className={_c1}></div>
		</div>;
});
const _c0 = " Knu-FlexCol position-relative overflow-hidden ";
const _c1 = " Knu-Flex ";