import * as React from 'react';
import { ChannelCategoryFragment, ChannelGroup, TopChannelCategoryFragment } from '@generated/graphql';
import { Box, FlexCol, Text, useTheme, VirtualListItem, VirtualListRenderItemInfo } from '@knuddels/component-library';
import { ChannelGroupData, ChannelListGroupItems } from './ChannelListGroupItems';
import { CategoryHeader } from '../../Overview/ChannelSelection/ChannelList/CategoryHeader';
import { SmallChannelListGroupItems } from '../../Overview/ChannelSelection/ChannelList/SmallChannelListGroupItems';
import { HighlightChannelListItemRow } from '../../Overview/ChannelSelection/ChannelList/HighlightChannelListItem';
import { ActiveChannelListItem } from '@knuddelsModules/ChannelList/bundle/components/Overview/ChannelSelection/ChannelList/ActiveChannelListItem';
import { ChannelInfo } from '@knuddelsModules/Channel';
export const templateActiveChannel = 'activeChannel';
export const templateChannelGroup = 'channelGroup';
export const templateHighlightChannelGroup = 'highlightChannelGroup';
export const templateSmallChannelGroup = 'smallChannelGroup';
export const templateCategoryHeader = 'categoryHeader';
export const typeNoChannel = 'no-channel';
export type ChannelListItem = ActiveChannelVirtualItems | ChannelListVirtualItems | ChannelListHighlightVirtualItems | ChannelListSmallVirtualItems | CategoryHeaderListItem | NoChannelItemInfo;
export interface ActiveChannelVirtualItems extends VirtualListItem {
  type: typeof templateActiveChannel;
  key: string;
  previewMembers?: ChannelCategoryFragment['channelGroups'][0]['previewMembers'];
  group: ChannelCategoryFragment['channelGroups'][0];
  activeChannel: ChannelInfo;
}
export interface ChannelListVirtualItems extends VirtualListItem {
  channelGroup: ChannelGroup;
  isLast: boolean;
  type: typeof templateChannelGroup;
  key: string;
  userCount: number;
  size: 'condensed' | 'default';
}
export interface ChannelListHighlightVirtualItems extends VirtualListItem {
  maxChannelsPerRow: number;
  channels: TopChannelCategoryFragment[];
  itemWidth: number;
  isLast: boolean;
  type: typeof templateHighlightChannelGroup;
  key: string;
  footerHeight: number;
}
export interface ChannelListSmallVirtualItems extends VirtualListItem {
  channelGroup: ChannelGroup;
  isFirst: boolean;
  isLast: boolean;
  type: typeof templateSmallChannelGroup;
  key: string;
  userCount: number;
}
export interface CategoryHeaderListItem extends VirtualListItem {
  type: typeof templateCategoryHeader;
  title: string;
  key: string;
  button?: React.ReactNode;
}
export interface NoChannelItemInfo extends VirtualListItem {
  type: typeof typeNoChannel;
  key: string;
  error: string;
  testId?: string;
}
export const createChannelGroupVirtualListItem = (key: string, group: ChannelGroupData, userCount: number, isExpanded: boolean, isLast: boolean, size: 'condensed' | 'default') => {
  const itemHeight = size === 'condensed' ? 80 : 92;
  const dividerHeight = 8;
  let height = itemHeight;
  if (isExpanded) {
    height += group.channels.length * (itemHeight + dividerHeight);
  }
  if (!isLast) {
    height += dividerHeight;
  }
  return ({
    key,
    height,
    channelGroup: group,
    userCount,
    isLast,
    type: templateChannelGroup,
    size
  } as ChannelListVirtualItems);
};
export const useCreateHighlightChannelGroupVirtualListItem = () => {
  const theme = useTheme();
  return React.useCallback((key: string, maxChannelsPerRow: number, channels: TopChannelCategoryFragment[], isLast: boolean, availableWidth: number) => {
    const spacing = (maxChannelsPerRow + 1) * theme.spacings.base;
    const itemWidth = (availableWidth - spacing) / maxChannelsPerRow;
    const hasGames = channels.some(it => it.games.length > 0);
    const footerHeight = hasGames ? 128 : 68;
    const imageHeight = itemWidth / 16 * 7;
    const itemHeight = footerHeight + imageHeight;
    const dividerHeight = theme.spacings.minor;
    let height = itemHeight;
    if (!isLast) {
      height += dividerHeight;
    }
    return ({
      key,
      height,
      footerHeight,
      itemWidth,
      isLast,
      maxChannelsPerRow,
      channels: channels,
      type: templateHighlightChannelGroup
    } as ChannelListHighlightVirtualItems);
  }, [theme]);
};
export const createSmallChannelGroupVirtualListItem = (key: string, group: ChannelGroupData, userCount: number, isFirst: boolean, isLast: boolean) => {
  const itemHeight = 40;
  const dividerHeight = 1;
  let height = itemHeight;
  if (!isLast) {
    height += dividerHeight;
  }
  return ({
    key,
    height,
    channelGroup: group,
    userCount,
    isFirst,
    isLast,
    type: templateSmallChannelGroup
  } as ChannelListSmallVirtualItems);
};
export const useRenderChannelListItem = (config: {
  shouldUseShortDescription: boolean;
  trackingContentType: string;
  onChannelSelected?: (channelName: string) => void;
}) => {
  return React.useCallback(({
    item
  }: VirtualListRenderItemInfo<ChannelListItem>): JSX.Element => {
    switch (item.type) {
      case templateActiveChannel:
        return <div className={_c0}>
							<ActiveChannelListItem group={item.group} activeChannel={item.activeChannel} previewMembers={item.previewMembers} />
						</div>;
      case templateChannelGroup:
        return <div className={_c1}>
							<ChannelListGroupItems expandingKey={item.key} channelGroup={item.channelGroup} userCount={item.userCount} size={item.size} isLast={item.isLast} trackingContentType={config.trackingContentType} shouldUseShortDescription={config.shouldUseShortDescription} onChannelSelected={config.onChannelSelected} />
						</div>;
      case templateSmallChannelGroup:
        return <SmallChannelListGroupItems channelGroup={item.channelGroup} userCount={item.userCount} isFirst={item.isFirst} isLast={item.isLast} trackingContentType={config.trackingContentType} />;
      case templateHighlightChannelGroup:
        return <HighlightChannelListItemRow channels={item.channels} itemHeight={item.height} itemWidth={item.itemWidth} maxChannelsPerRow={item.maxChannelsPerRow} isLast={item.isLast} footerHeight={item.footerHeight} trackingContentType={config.trackingContentType} />;
      case templateCategoryHeader:
        return <CategoryHeader title={item.title} button={item.button} />;
      case typeNoChannel:
        return <div className={_c2}>
							<Text className={_c3}>{item.error}</Text>
						</div>;
      default:
        return null;
    }
  }, [config.shouldUseShortDescription]);
};
const _c0 = " Knu-FlexCol mx-base mt-base mb-small ";
const _c1 = " Knu-FlexCol mx-base ";
const _c2 = " Knu-Box mt-xxlarge mx-large ";
const _c3 = " textAlign-center ";