import { MessengerConversation } from '@generated/graphql';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
import { inject, injectable, injectedComponent, injectProps, useService } from '@knuddels-app/DependencyInjection';
import { $Environment } from '@knuddels-app/Environment';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { $ViewService, InsideOverlayViewContext } from '@knuddels-app/layout';
import { useLoadService } from '@knuddels-app/ModuleSystem';
import { $ScreenService } from '@knuddels-app/Screen';
import { $MentorService } from '@knuddelsModules/MentorSystem';
import { messengerViewId, MessengerViewState } from '@knuddelsModules/Messenger/MessengerViewProvider';
import { $SettingsComponents, contactListSettingsFormat, creditsFormat, mentorSystemSettingsFormat, messengerSettingsFormat, privacySettingsFormat, settingsFormat, SettingsRouterConfig, soundSettingsFormat } from '@knuddelsModules/Settings';
import { CONTACT_FILTER_SETTINGS_APP_ID } from '@shared/constants';
import * as React from 'react';
import { useContext } from 'react';
import { appletSideBarOpenConversaionEvent, conversationArchiveTitleBarEvent, conversationOpenedTracker, conversationOverviewTitleBarEvent, markedMessagesTitleBarEvent } from '../analytics';
import { STARRED_MESSAGES } from '../i18n/shared-formats';
import { StAppMessenger } from '../stappmessenger';
import { Conversation } from './Conversation/Conversation';
import { MessengerArchive } from './MessengerList/MessengerArchive';
import { MessengerOverview } from './MessengerList/MessengerOverview';
import { Router, useParams, useRouter } from './MessengerRouter';
import { NewConversation } from './NewConversation/NewConversation';
import { StarredMessages } from './StarredMessages/StarredMessages';
@injectable()
class MessengerComponentBaseModel {
  constructor(@injectProps()
  private readonly props: unknown, @inject($ViewService)
  private readonly viewService: typeof $ViewService.T, @inject($Environment)
  private readonly environment: typeof $Environment.T, @inject($ScreenService)
  private readonly screenService: typeof $ScreenService.T) {}
  gotoMessenger = (): void => {
    this.viewService.openView(messengerViewId.with(s => (s.withPath('overview') as MessengerViewState)));
  };
  close = (): void => {
    this.viewService.closeView(messengerViewId);
  };
  public get isStappSidebarOverview(): boolean {
    return this.environment.isStappSidebarOverview;
  }
  public openStappSidebarConversation = (conversationId: MessengerConversation['id'], index: number): void => {
    conversationOpenedTracker.start();
    appletSideBarOpenConversaionEvent.track('OpenConversation_ClickPosition' + (index + 1));
    this.environment.messengerSystemAppInterface!.executeOpenMessengerSlashCommand(conversationId);
  };
  public get isStackedLayout(): boolean {
    return this.screenService.isStackedLayout;
  }
}
export const MessengerComponent = injectedComponent({
  name: 'MessengerComponent',
  model: MessengerComponentBaseModel,
  inject: {
    settingsComponents: $SettingsComponents,
    i18n: $I18n
  }
}, ({
  model,
  settingsComponents,
  i18n
}) => {
  const overlay = useContext(InsideOverlayViewContext);
  const track = useLogEvent();
  const messagesFormat = declareFormat({
    id: 'MESSENGER_TITLE',
    defaultFormat: 'Messages'
  });
  if (model.isStappSidebarOverview) {
    return <StAppMessenger.StAppOverviewListWrapper title={messagesFormat.format(i18n)} openConversation={model.openStappSidebarConversation} />;
  }
  return <Router rootPath={'overview'} onClose={globalEnv.product === 'stapp-messenger' || model.isStackedLayout ? undefined : model.close}>
				<Router.Route path={'overview'} title={messagesFormat.format(i18n)} preventSplitView navIconStyle={overlay ? 'close' : globalEnv.product === 'stapp-messenger' || model.isStackedLayout ? 'none' : 'auto'} onNavIconPress={() => {
      conversationOverviewTitleBarEvent.track('Titlebar_CloseMessenger');
    }}>
					<MessengerOverview />
				</Router.Route>

				<Router.Route path={'archive'} title={declareFormat({
      id: 'MESSENGER_ARCHIVE_TITLE',
      defaultFormat: 'Archived conversations'
    }).format(i18n)} preventSplitView onNavIconPress={() => {
      conversationArchiveTitleBarEvent.track('Titlebar_BackButton');
    }}>
					<MessengerArchive />
				</Router.Route>

				<Router.Route path={'newConversation'} title={declareFormat({
      id: 'NEW_MESSAGE',
      defaultFormat: 'New message'
    }).format(i18n)} preventSplitView onNavIconPress={() => {
      track('Contacts_Titlebar', 'BackButton_Clicked');
    }}>
					<NewConversation />
				</Router.Route>

				<Router.Route path={'newConversationSettings'} preventSplitView title={contactListSettingsFormat.format(i18n)}>
					<settingsComponents.ContactListSettings />
				</Router.Route>

				<Router.Route path={'starredMessages'} preventSplitView title={STARRED_MESSAGES.format(i18n)} onNavIconPress={() => {
      markedMessagesTitleBarEvent.track('Titlebar_BackButton');
    }}>
					<StarredMessages />
				</Router.Route>

				<Router.Route path={'settings'} title={settingsFormat.format(i18n)} preventSplitView onNavIconPress={() => {
      track('Settings_Overview', 'Titlebar_Close');
    }}>
					<MessengerSettingsOverview />
				</Router.Route>

				<Router.Route path={'contactListSettings'} title={contactListSettingsFormat.format(i18n)} preventSplitView onNavIconPress={() => track('Contacts_Settings', 'Titlebar_BackButton')}>
					<settingsComponents.ContactListSettings />
				</Router.Route>

				<Router.Route path={'soundSettings'} title={soundSettingsFormat.format(i18n)} preventSplitView onNavIconPress={() => track('Settings_Sounds', 'Titlebar_BackButton')}>
					<settingsComponents.SoundSettings />
				</Router.Route>

				<Router.Route path={'messengerSettings'} title={messengerSettingsFormat.format(i18n)} preventSplitView onNavIconPress={() => track('Settings_Messenger', 'Titlebar_BackButton')}>
					<settingsComponents.MessengerSettings />
				</Router.Route>

				<Router.Route path={'credits'} title={creditsFormat.format(i18n)} preventSplitView onNavIconPress={() => track('Settings_Credits', 'Titlebar_Close')}>
					<React.Suspense fallback={null}>
						<settingsComponents.CreditsGetter />
					</React.Suspense>
				</Router.Route>

				<Router.Route path={'privacySettings'} title={privacySettingsFormat.format(i18n)} preventSplitView onNavIconPress={() => track('Settings_Privacy', 'Titlebar_Close')}>
					<settingsComponents.PrivacySettings />
				</Router.Route>

				<Router.Route path={'mentorSystemSettings'} title={mentorSystemSettingsFormat.format(i18n)} preventSplitView onNavIconPress={() => track('Settings_MentorSystem', 'Titlebar_Close')}>
					<settingsComponents.MentorSystemSettings />
				</Router.Route>

				<Router.Route path={'conversation'} onNavIconPress={!overlay ? undefined : e => {
      overlay.close();
      e.preventDefault();
    }} navIconStyle={overlay ? 'close' : globalEnv.product === 'stapp-messenger' && !model.isStackedLayout ? 'none' : 'auto'}>
					<Conversation />
				</Router.Route>
				<Router.Route path={'mentorsystem-rewards'} title={i18n.format(declareFormat({
      id: 'messenger.mentorsystem.rewards.title',
      defaultFormat: 'Mentor system rewards'
    }))}>
					<MentorSystemRewards />
				</Router.Route>
				<Router.Route path={'mentorsystem-about'} title={i18n.format(declareFormat({
      id: 'messenger.mentorsystem.about.title',
      defaultFormat: 'About the mentor system'
    }))}>
					<MentorSystemAbout />
				</Router.Route>
			</Router>;
});
const MentorSystemRewards: React.FC = () => {
  const lazyMentorService = useLoadService($MentorService);
  const params = useParams('mentorsystem-rewards');
  if (!lazyMentorService) {
    return null;
  }
  return <lazyMentorService.MentorSystemRewards initialTab={params?.tab} />;
};
const MentorSystemAbout: React.FC = () => {
  const lazyMentorService = useLoadService($MentorService);
  const params = useParams('mentorsystem-about');
  if (!lazyMentorService) {
    return null;
  }
  return <lazyMentorService.MentorSystemAbout initialTab={params?.tab} />;
};
const MessengerSettingsOverview: React.FC = () => {
  const settingsComponents = useService($SettingsComponents);
  const execute = useSlashCommandWithoutChannel({
    shouldUseStappIfAvailable: true
  });
  const {
    navigate
  } = useRouter();
  const doNavigate = (newPath: keyof SettingsRouterConfig) => {
    switch (newPath) {
      case 'ContactFilterSettings':
        execute('/opensystemapp ' + CONTACT_FILTER_SETTINGS_APP_ID);
        break;
      case 'PrivacySettings':
        navigate('privacySettings');
        break;
      case 'ContactListSettings':
        navigate('contactListSettings');
        break;
      case 'SoundSettings':
        navigate('soundSettings');
        break;
      case 'Credits':
        navigate('credits');
        break;
      case 'MentorSystemSettings':
        navigate('mentorSystemSettings');
        break;
      case 'MessengerSettings':
        navigate('messengerSettings');
        break;
      case 'AccountSettings':
      case 'Overview':
      case 'ChannelSettings':
      case 'ChangePassword':
      case 'ChangeNickname':
      default:
        break;
    }
  };
  return <settingsComponents.Settings navigate={doNavigate} shouldShowChannelSetting={false} shouldShowAccountSettings={false} />;
};