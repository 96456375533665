import * as React from 'react';
import { ChannelBackgroundImageInfo } from '@generated/graphql';
import { ChannelBackground } from './Chat/ChatContent/ChannelBackground';
import { FlexCol } from '@knuddels/component-library';
import { useService } from '@knuddels-app/DependencyInjection';
import { motion } from 'framer-motion';
import { CssAnimatePresence } from '@knuddelsModules/Channel/bundle/components/CssAnimatePresence';
import { KeyboardAvoidanceView } from '@shared/components/KeyboardAvoidanceView';
import { $MessengerMiniChatService } from '@knuddelsModules/Messenger';
import { observer } from '@knuddels-app/mobx';
import { $NativeWebViewService } from '@knuddels-app/NativeWebView';
export const ChannelViews: React.FC<{
  chatView: React.ReactElement;
  nicklist: React.ReactElement;
  backgroundColor: string | undefined;
  backgroundImageInfo: ChannelBackgroundImageInfo | undefined;
  width: number;
  nicklistOpen: boolean;
  chatNextToNicklist: boolean;
}> = observer('ChannelViews', props => {
  const {
    backgroundColor,
    backgroundImageInfo,
    nicklistOpen,
    chatNextToNicklist
  } = props;
  const ref = React.useRef<HTMLDivElement>(null);
  const nativeWebViewService = useService($NativeWebViewService);
  const miniChatOverlay = useService($MessengerMiniChatService);
  React.useLayoutEffect(() => {
    if (!nativeWebViewService.isEnabled) {
      return;
    }
    if (nicklistOpen) {
      nativeWebViewService.updateBackgroundState('background');
    } else {
      nativeWebViewService.updateBackgroundState('active');
    }
  }, [nicklistOpen]);
  return <KeyboardAvoidanceView triggerNativeAnimation enabled={!miniChatOverlay.isOverlayVisible && !nicklistOpen}>
			<ChannelBackground backgroundColor={backgroundColor} backgroundImageInfo={backgroundImageInfo}>
				<motion.div style={{
        ...(chatNextToNicklist ? {
          flex: 1,
          minWidth: '0px'
        } : {
          width: '100%',
          height: '100%'
        })
      }} className={'Knu-FlexCol'} animate={{
        opacity: props.nicklistOpen && !props.chatNextToNicklist ? 0.01 : 1,
        transform: `translate3d(${props.nicklistOpen && !props.chatNextToNicklist ? '-30px' : 0}, 0, 0)`
      }}>
					{props.chatView}
				</motion.div>

				<CssAnimatePresence containerRef={ref} key={chatNextToNicklist ? 'side-by-side' : 'overlay'}>
					{chatNextToNicklist ? <div className={_c0}>
							<ChannelBackground backgroundColor={undefined} backgroundImageInfo={undefined}>
								{props.nicklist}
							</ChannelBackground>
						</div> : props.nicklistOpen ? <div ref={ref} className={'Knu-FlexCol Knu-SlideInView shouldAnimate'}>
							<ChannelBackground backgroundColor={backgroundColor} backgroundImageInfo={backgroundImageInfo}>
								{props.nicklist}
							</ChannelBackground>
						</div> : null}
				</CssAnimatePresence>
			</ChannelBackground>
		</KeyboardAvoidanceView>;
});
const _c0 = " Knu-FlexCol ";