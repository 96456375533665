import * as React from 'react';
import { injectable } from '@knuddels-app/DependencyInjection';
import { Position, ViewId, ViewProvider, ViewRegistry } from '@knuddels-app/layout';
import * as providedServices from './providedServices';
import { LoadService } from '@knuddels-app/ModuleSystem';
import { getRouterStateFromPersistedViewState, RouterViewState } from '@knuddels-app/layout/k3Router';
import type { ContactsRouterConfig } from './ContactsRouterConfig';
import type { AllContactsTabs } from './bundle/components/ContactsView/ContactTabs';
@injectable()
export class ContactsViewProvider implements ViewProvider {
  registerViews(registry: ViewRegistry): void {
    registry.registerView({
      viewId: contactsViewId,
      position: Position.Side,
      loadState: ContactsRouterViewState.fromPersistedViewState,
      render: () => ({
        mainView: <LoadService service={providedServices.$ContactsService}>
						{s => <s.MainView />}
					</LoadService>
      })
    });
  }
}
class ContactsRouterViewState extends RouterViewState<ContactsRouterConfig> {
  public static fromPersistedViewState = getRouterStateFromPersistedViewState(ContactsRouterViewState);
  public withContactsTab(tab: AllContactsTabs): ContactsRouterViewState {
    return this.withStack([{
      path: 'contacts',
      params: {
        initialTab: tab
      },
      key: RouterViewState.generateId()
    }]);
  }
}
export const contactsViewId = new ViewId<ContactsRouterViewState>('contacts');