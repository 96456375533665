import { declareProps, inject, injectable, injectProps } from '@knuddels-app/DependencyInjection';
import { injectedComponent } from '@knuddels-app/DependencyInjection/injectedComponent';
import { action, computed, observable } from '@knuddels-app/mobx';
import { Flex, FlexCol, LineDivider, Text, FallbackBox } from '@knuddels/component-library';
import { $ProfileVisitorsService } from '@knuddelsModules/ProfileVisitors';
import { ProfileVisitor } from '@knuddelsModules/ProfileVisitors/bundle/services';
import { UserImageType } from '@knuddelsModules/UserData';
import { SPACING } from '@shared/components';
import * as React from 'react';
import { useNickSubstituteForGender } from '../useNickSubstituteForGender';
import { OverflowItem } from './OverflowItem';
import { OverviewType } from './ProfileVisitorsOverview';
import { VisitorItem } from './VisitorItem';
interface ModelProps {
  onOverflowClicked(type: OverviewType): void;
  onVisitorClick(userId: ProfileVisitor['id'], nick: string, isUnlocked: boolean): void;
  type: OverviewType;
}
interface ViewProps {
  title: string;
  overflowButtonText: string;
  type: OverviewType;
  showTopSpacer?: boolean;
  layout: 'short' | 'wide';
}
type PanelItemType = {
  type: 'visitor';
  visitor: ProfileVisitor;
  onClick(): void;
} | {
  type: 'overflow';
  onClick(): void;
};
@injectable()
class VisitorsPanelModel {
  @observable
  private maxItemRenderCount = 1;
  @computed
  public get renderedItems(): PanelItemType[] {
    const maxItemRenderCount = this.maxItemRenderCount;
    if (maxItemRenderCount === 0) {
      return [];
    }
    const maxMatchesRenderCount = maxItemRenderCount - 1;
    const items: PanelItemType[] = this.visitors.map(visitor => ({
      type: 'visitor',
      visitor,
      onClick: () => this.handleVisitorClick(visitor)
    }));
    items.length = maxMatchesRenderCount;
    items.push({
      type: 'overflow',
      onClick: () => this.showVisitorsView()
    });
    return items;
  }
  public get visitors(): ReadonlyArray<ProfileVisitor> {
    return this.profileVisitorsService.visitors;
  }
  constructor(@injectProps()
  private readonly props: ModelProps, @inject($ProfileVisitorsService)
  private readonly profileVisitorsService: typeof $ProfileVisitorsService.T) {}
  public readonly showVisitorsView = (): void => {
    this.props.onOverflowClicked(this.props.type);
  };
  public readonly handleVisitorClick = (user: ProfileVisitor): void => {
    this.props.onVisitorClick(user.id, user.nick, user.isUnlocked);
  };
  @action.bound
  public onLayout(width: number): void {
    this.maxItemRenderCount = Math.floor((width + SPACING.MINOR) / (UserImageType.MESSENGER + SPACING.MINOR));
  }
}
export const VisitorsPanel = injectedComponent({
  name: 'VisitorsPanel',
  model: VisitorsPanelModel,
  props: declareProps<ViewProps>()
}, ({
  showTopSpacer = true,
  model,
  type,
  layout,
  ...props
}) => {
  return <div className={_c0 + (showTopSpacer ? _c1 : _c2)}>
				<div className={_c3 + (layout === 'short' ? _c4 : _c5)}>
					<Text type={'body2'} state={'tertiary'} bold={true} className={_c6}>
						{props.title.toUpperCase()}
					</Text>
				</div>
				<div className={_c7 + (layout === 'short' ? _c8 : _c9) + (layout === 'short' ? _c10 : _c11)}>
					<FallbackBox onLayout={e => model.onLayout(e.width)} className={_c12}>
						<VisitorList overflowButtonText={props.overflowButtonText} entries={model.renderedItems} />
					</FallbackBox>
				</div>
				{type === 'messenger' && <div className={_c13}>
						<LineDivider className={_c14} />
					</div>}
			</div>;
});
const VisitorList: React.FC<{
  overflowButtonText: string;
  entries: PanelItemType[];
}> = ({
  overflowButtonText,
  entries
}) => {
  if (entries.length === 0) {
    return null;
  }
  return <div className={_c15}>
			{entries.map(entry => {
      if (entry.type === 'overflow') {
        return <OverflowItem key={':overflow'} onClick={entry.onClick} text={overflowButtonText} />;
      }
      const user = entry.visitor;
      return <VisitorEntry key={user.id} entry={entry} blurred={!entry.visitor.isUnlocked} />;
    })}
		</div>;
};
const VisitorEntry: React.FC<{
  blurred: boolean;
  entry: PanelItemType & {
    type: 'visitor';
  };
}> = ({
  entry,
  blurred
}) => {
  const user = entry.visitor;
  const nickSubstitute = useNickSubstituteForGender(user.gender);
  return <VisitorItem key={user.id} onClick={entry.onClick} id={user.id} nick={blurred ? nickSubstitute : user.nick} blurred={blurred} />;
};
const _c0 = " Knu-FlexCol ";
const _c1 = " pt-minor ";
const _c2 = " pt-tiny ";
const _c3 = " Knu-Flex mb-minor mt-base ";
const _c4 = " ml-base ";
const _c5 = " ml-none ";
const _c6 = "  ";
const _c7 = " Knu-Flex overflow-visible mb-base ";
const _c8 = " pl-base ";
const _c9 = " pl-none ";
const _c10 = " pr-tiny ";
const _c11 = " pr-none ";
const _c12 = " Knu-Flex maxWidth-100-percent flex-1 overflow-visible ";
const _c13 = " Knu-FlexCol px-base ";
const _c14 = "  ";
const _c15 = " Knu-Flex gap-minor ";