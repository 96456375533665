import * as React from 'react';
import { autorun } from '@knuddels-app/mobx';
import { DependencyList } from 'react';

export const useReactiveState = <T>(
	selector: () => T,
	deps: DependencyList,
	delay: number = 0
) => {
	const [value, setValue] = React.useState(selector());

	React.useEffect(() => {
		if (delay > 0) {
			return autorun({ name: 'Update reactive state' }, () => {
				const values = selector();
				setTimeout(() => {
					setValue(values);
				}, delay);
			});
		}

		return autorun({ name: 'Update reactive state' }, () => {
			setValue(selector());
		});
	}, deps);

	return value;
};
