import * as React from 'react';
import { NavBadge } from '@knuddelsModules/LoggedInArea/bundle/services';
import { getTotalBadgeCount } from '@shared/helper/getBadgeCount';

export const useBadge = (badges: NavBadge[]) => {
	const valid = React.useRef<boolean[]>(
		badges.map(
			it =>
				typeof it.validUntil !== 'number' || it.validUntil >= Date.now()
		)
	);

	const createBadge = () => {
		const validBadges = badges.filter(
			(it, index) => it.text && valid.current[index]
		);

		if (validBadges.length === 0) {
			return {};
		}
		if (validBadges.length === 1) {
			return validBadges[0];
		}

		const text = getTotalBadgeCount(validBadges.map(it => it.text));
		return { text, color: validBadges[0].color };
	};

	const [badge, setBadge] = React.useState<{ text?: string; color?: string }>(
		createBadge
	);

	const timeouts: any[] = [];

	React.useEffect(() => {
		if (badges.length < 1) {
			return;
		}

		badges.forEach((badge, index) => {
			if (!badge.text || !badge.validUntil) {
				return;
			}

			if (badge.validUntil < Date.now()) {
				valid.current = valid.current.map((it, i) =>
					i === index ? false : it
				);
				return;
			}

			const remainingTime = badge.validUntil - Date.now();
			const timeout = setTimeout(() => {
				valid.current = valid.current.map((it, i) =>
					i === index ? false : it
				);
				setBadge(createBadge());
			}, remainingTime);

			timeouts.push(timeout);
		});

		setBadge(createBadge());

		return () => {
			timeouts.forEach(clearTimeout);
		};
	}, [badges]);

	return badge;
};
