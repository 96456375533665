import * as React from 'react';
import { ChannelCategoryFragment } from '@generated/graphql';
import { VirtualList } from '@knuddels/component-library';
import { useListData } from './useListData';
import { useRenderChannelListItem } from '../../../shared/ChannelList/ChannelListVirtualItems';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { extractChannelGroupIds } from '../extractChannelGroupIds';
export const ChannelList: React.FC<{
  mayShowActiveChannel?: boolean;
  categories: ChannelCategoryFragment[];
  trackingContentType: string;
  condensedEntries?: boolean;
  onVisibleChannelsChanged?: (visibleChannels: string[]) => void;
}> = ({
  mayShowActiveChannel,
  categories,
  condensedEntries,
  trackingContentType,
  onVisibleChannelsChanged
}) => {
  const listData = useListData(mayShowActiveChannel, categories, trackingContentType, condensedEntries);
  const renderChannelListItem = useRenderChannelListItem({
    shouldUseShortDescription: false,
    trackingContentType
  });
  const track = useLogEvent();
  React.useEffect(() => {
    track(trackingContentType, 'Channellist_Opened');
  }, []);
  return <VirtualList data={listData} renderItem={renderChannelListItem} gapBottom={'xlarge'} onRenderedItemsChanged={range => {
    const visibleChannels = extractChannelGroupIds(listData, range);
    onVisibleChannelsChanged?.(visibleChannels);
  }} className={_c0} />;
};
const _c0 = "  ";