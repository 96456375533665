import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable, expectUnreachable } from '@knuddels/std';
import {
	$LocalStorage,
	createJsonSerializer,
	LocalStorageKey,
} from '@knuddels-app/local-storage';
import { action, computed, observable, runInAction } from '@knuddels-app/mobx';
import { $UserService } from '@knuddelsModules/UserData';
import { $PromotionService } from '@knuddelsModules/Promotions';
import { $PushNotificationsService } from '@knuddelsModules/Notifications';
import {
	engagementAppViewId,
	globalAppViewId,
	smileyTradeAppViewId,
	viewIdForSystemApp,
} from '@knuddelsModules/SystemApps';
import { $CommandWithoutChannelService } from '@knuddels-app/Commands';
import { $ViewService } from '@knuddels-app/layout';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { settingsViewId } from '@knuddelsModules/Settings';
import { $JoinChannelService } from '@knuddelsModules/Channel';
import { $EvergreenDataService } from '@knuddels-app/evergreenData';
import { PROFILE_CUSTOMIZATION_APP_ID } from '@shared/constants';
import { CommunityStatus } from '@generated/graphql';
import {
	DashboardTip,
	DashboardTipId,
	dashboardTips,
} from '@knuddelsModules/Explore/bundle/components/tips';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';

interface TipViewInfo {
	tipId: string;
	plainViews: number;
	uniqueSessions: number;
}

const finishedDashboardTips = new LocalStorageKey({
	name: 'finishedDashboardTips',
	cookieExpires: { inDays: 365 },
	serializer: createJsonSerializer({ defaultValue: [] as string[] }),
});

const dashboardTipViewsKey = new LocalStorageKey({
	name: 'dashboardTipViews',
	cookieExpires: { inDays: 365 },
	serializer: createJsonSerializer<TipViewInfo[]>({ defaultValue: [] }),
});

const TIP_VIEWLIMIT_WHITELIST = [
	'runningHappyHour',
	'lowKnuddel',
	'loyaltySystemLevelLossWarning',
	'loyaltySystemPointLossWarning',
	'loyaltySystemLevelUpHint',
] as DashboardTipId[];

@injectable()
export class DashboardService {
	public readonly dispose = Disposable.fn();

	private readonly finishedDashboardTipsEntry = this.localStorage.getEntry(
		finishedDashboardTips
	);

	private readonly dashboardTipViewsEntry =
		this.localStorage.getEntry(dashboardTipViewsKey);

	@observable
	private finishedTips: string[] = [];
	@observable
	private hiddenRepeatableTips: string[] = [];

	private tipViews: TipViewInfo[] = [];

	private tipsViewedInSession = new Set<string>();

	@observable
	private ready = false;

	constructor(
		@inject($LocalStorage)
		private readonly localStorage: typeof $LocalStorage.T,
		@inject($UserService)
		private readonly userService: typeof $UserService.T,
		@inject($PromotionService)
		private readonly promotionService: typeof $PromotionService.T,
		@inject($PushNotificationsService)
		private readonly pushNotificationsService: typeof $PushNotificationsService.T,
		@inject($CommandWithoutChannelService)
		private readonly commandWithoutChannelService: typeof $CommandWithoutChannelService.T,
		@inject($EvergreenDataService)
		private readonly evergreenDataService: typeof $EvergreenDataService.T,
		@inject($ViewService)
		private readonly viewService: typeof $ViewService.T,
		@inject.lazy($ProfileNavigationService)
		private readonly getProfileNavigationService: typeof $ProfileNavigationService.TLazy,
		@inject($JoinChannelService)
		private readonly joinChannelService: typeof $JoinChannelService.T,
		@inject($GenericUserEventService)
		private readonly genericUserEventService: typeof $GenericUserEventService.T
	) {
		const tips = this.finishedDashboardTipsEntry.get();
		const tipViews = this.dashboardTipViewsEntry.get();
		runInAction('get finished tips', () => {
			this.finishedTips = tips || [];
			this.tipViews = tipViews || [];
			this.ready = true;
		});
	}

	isReady = () => {
		return this.ready && !!this.userService.currentUser;
	};

	private isTipFinished(tipId: string): boolean {
		return (
			this.finishedTips.indexOf(tipId) > -1 ||
			this.hiddenRepeatableTips.indexOf(tipId) > -1
		);
	}

	private isTipViewLimitReached(tipId: string): boolean {
		const tipViewInfo = this.tipViews.find(info => info.tipId === tipId);
		if (!tipViewInfo) {
			return false;
		}

		return (
			(tipViewInfo.uniqueSessions > 1 &&
				!this.tipsViewedInSession.has(tipId)) ||
			tipViewInfo.plainViews > 9
		);
	}

	@computed
	public get showDashboardTips(): DashboardTip[] {
		if (!this.isReady()) {
			return [];
		}

		const nonFinishedTips = dashboardTips.filter(this.mayShowTip);

		return nonFinishedTips.slice(0, 2);
	}

	@action.bound
	markAsFinished(tip: DashboardTip): void {
		if (this.isTipFinished(tip.id)) {
			return;
		}

		if (tip.repeating) {
			this.hiddenRepeatableTips = [...this.hiddenRepeatableTips, tip.id];
		} else {
			this.finishedTips = [...this.finishedTips, tip.id];
			this.finishedDashboardTipsEntry.set(this.finishedTips);
		}
	}

	@action.bound
	markTipViewed(tipId: string) {
		const tipViewInfo = this.tipViews.find(info => info.tipId === tipId);
		const tipViewedInCurrentSession = this.tipsViewedInSession.has(tipId);

		if (tipViewInfo) {
			tipViewInfo.plainViews++;
			if (!tipViewedInCurrentSession) {
				tipViewInfo.uniqueSessions++;
				this.tipsViewedInSession.add(tipId);
			}
		} else {
			this.tipViews = [
				...this.tipViews,
				{
					tipId,
					plainViews: 1,
					uniqueSessions: 1,
				},
			];

			this.tipsViewedInSession.add(tipId);
		}

		this.dashboardTipViewsEntry.set(this.tipViews);
	}

	@action.bound
	finishTip(tip: DashboardTip): void {
		this.genericUserEventService.reportEvent({
			type: 'Clicked_DashboardTip',
			tipId: tip.id,
		});
		this.markAsFinished(tip);

		switch (tip.id) {
			case 'runningHappyHour':
			case 'lowKnuddel':
				this.commandWithoutChannelService.invokeCommand('buyknuddel');
				break;
			case 'uploadProfilePhoto':
				this.commandWithoutChannelService.invokeCommand(
					'/pensystemapp',
					PROFILE_CUSTOMIZATION_APP_ID
				);
				break;
			case 'openQuests':
			case 'pickUpCompletedQuest':
				this.viewService.openViewAsOverlay(engagementAppViewId());
				break;
			case 'completeProfile':
				this.getProfileNavigationService().then(service =>
					service.showEditProfile()
				);
				break;
			case 'openSettings':
				this.viewService.openViewAsOverlay(settingsViewId);
				break;
			case 'photoMeet':
				this.viewService.openViewAsOverlay(
					viewIdForSystemApp('FotomeetApp')
				);
				break;
			case 'enterCrash':
				this.joinChannelService.joinChannelByName(
					'Crash',
					'DashboardTip'
				);
				break;
			case 'playWeltreise':
				this.commandWithoutChannelService.invokeCommand('weltreise');
				break;
			case 'enterDiceOne':
				this.joinChannelService.joinChannelByName(
					'DiceOne',
					'DashboardTip'
				);
				break;
			case 'enterDiceSky':
				this.joinChannelService.joinChannelByName(
					'DiceSky',
					'DashboardTip'
				);
				break;
			case 'allowNotifications':
				this.pushNotificationsService.requestPermission();
				break;
			case 'openAppsAndGames':
				this.viewService.openViewAsOverlay(globalAppViewId);
				break;
			case 'openSmileyExchange':
				this.viewService.openViewAsOverlay(smileyTradeAppViewId);
				break;
			case 'openContactFilter':
				this.viewService.openViewAsOverlayWithContext(
					settingsViewId.with(s =>
						s.withPath('ContactFilterSettings')
					),
					'DiscoverTip'
				);
				break;
			case 'changeMessageDesign':
			case 'changeTextSize':
				this.viewService.openViewAsOverlayWithContext(
					settingsViewId.with(s => s.withPath('ChannelSettings')),
					'DiscoverTip'
				);
			// eslint-disable-next-line no-fallthrough
			case 'changeAccentColor':
			case 'changeDynamicNavSlot':
			case 'changeTheme':
				this.viewService.openViewAsOverlayWithContext(
					settingsViewId.with(s => s.withPath('DisplaySettings')),
					'DiscoverTip'
				);
				break;
			case 'loyaltySystemLevelLossWarning':
			case 'loyaltySystemPointLossWarning':
				this.commandWithoutChannelService.invokeCommand(
					'buyknuddel',
					'sid~LoyaltySystemCard'
				);
				return;
			case 'loyaltySystemLevelUpHint':
			case 'tryLoyaltySystem':
				this.commandWithoutChannelService.invokeCommand(
					'loyaltySystem',
					'open'
				);
				break;
			case 'knuddelsPlus':
				this.commandWithoutChannelService.invokeCommand(
					'openknuddelsplusshop'
				);
				break;
			default:
				expectUnreachable(tip.id);
		}
	}

	private mayShowTip = (tip: DashboardTip) => {
		if (this.isTipFinished(tip.id)) {
			return false;
		}

		if (
			this.isTipViewLimitReached(tip.id) &&
			!TIP_VIEWLIMIT_WHITELIST.includes(tip.id)
		) {
			return false;
		}

		const user = this.userService.currentUser;
		if (!user) {
			return false;
		}

		if (
			user.status !== CommunityStatus.Normal &&
			user.status !== null &&
			!TIP_VIEWLIMIT_WHITELIST.includes(tip.id)
		) {
			return false;
		}

		switch (tip.id) {
			case 'enterCrash':
			case 'enterDiceOne':
			case 'enterDiceSky':
				return (
					user.age &&
					user.age >= 18 &&
					user.dateOfRegistration &&
					Date.now() - +user.dateOfRegistration > 24 * 60 * 60 * 1000
				);
			case 'lowKnuddel':
				return (
					typeof user.knuddelAmount === 'number' &&
					user.knuddelAmount < 10
				);
			case 'uploadProfilePhoto':
				return !user.profilePicture.exists;
			case 'runningHappyHour':
				return (
					this.promotionService.activeHappyHour &&
					+this.promotionService.activeHappyHour.endTimestamp >
						Date.now()
				);
			case 'allowNotifications':
				return this.pushNotificationsService.status === 'undecided';
			case 'pickUpCompletedQuest':
				return (
					Number(
						this.evergreenDataService.data.menu.badges[
							'engagementSystem'
						]
					) > 0
				);
			case 'loyaltySystemLevelUpHint':
				return (
					this.evergreenDataService.data?.loyalty?.card ===
					'COLLECT_LEVEL'
				);
			case 'loyaltySystemLevelLossWarning':
				return (
					this.evergreenDataService.data?.loyalty?.card ===
					'LEVEL_LOSS'
				);
			case 'loyaltySystemPointLossWarning':
				return (
					this.evergreenDataService.data?.loyalty?.card ===
					'POINT_LOSS'
				);
			case 'tryLoyaltySystem':
				return (
					this.evergreenDataService.data?.loyalty?.card ===
					'ONBOARDING'
				);
			case 'knuddelsPlus':
				return !user.hasKnuddelsPlus;
			default:
				return true;
		}
	};
}
