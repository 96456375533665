import * as React from 'react';
import { useCacheAndNetworkQuery } from './Overview/ChannelSelection/useCacheAndNetworkQuery';
import { ChannelGroupFragment, GetDashboardChannelGroups, GetDashboardChannelGroupsQuery } from '@generated/graphql';
import { getPixelRatio } from '@shared/components/helper/getPixelRatio';
import { Flex, FlexCol, Text } from '@knuddels/component-library';
import { ChannelListGroupItems } from './shared/ChannelList/ChannelListGroupItems';
import { ChannelGroupExpandedContextDefaultProvider } from './ChannelGroupExpandedContext';
import { CondensedButton, equalUpToDepth } from '@shared/components';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ViewService } from '@knuddels-app/layout';
import { channelListViewId } from '@knuddelsModules/ChannelList';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { mergePreviewMembers } from './Overview/ChannelSelection/mergePreviewMembers';
import { useChannelGroupUpdater } from './Overview/ChannelSelection/useChannelGroupUpdater';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { useReactiveState } from '@knuddels-app/tools/useReactiveState';
import { $ActiveChannelService, channelViewId } from '@knuddelsModules/Channel';
import { ActiveChannelListItem } from '@knuddelsModules/ChannelList/bundle/components/Overview/ChannelSelection/ChannelList/ActiveChannelListItem';
import { motion } from 'framer-motion';
let cachedData: GetDashboardChannelGroupsQuery['channel']['dashboardChannelGroups'] | undefined;
const Elements = React.memo(({
  elements
}: {
  elements: readonly ChannelGroupFragment[];
}) => {
  return <>
				{elements.map((group, index) => <motion.div key={group.id} layout="position" transition={{
      delay: 0.2,
      bounce: 0.8
    }}>
						<Group key={group.id} group={group} isLast={index === elements.length - 1} />
					</motion.div>)}
			</>;
});
Elements.displayName = 'Elements';
export const DashboardChannelListElement: React.FC<{
  layout: 'short' | 'wide';
  variant: 'mobile' | 'desktop';
}> = ({
  layout,
  variant
}) => {
  const {
    data,
    setData
  } = useCacheAndNetworkQuery(GetDashboardChannelGroups, {
    pixelDensity: getPixelRatio()
  });
  const setVisibleChannels = useUpdater(setData);
  const activeChannelService = useService($ActiveChannelService);
  React.useEffect(() => {
    if (data && data.length > 0) {
      const visibleChannels = data.map(it => it.id);
      setVisibleChannels(visibleChannels);
      cachedData = data;
    }
  }, [data]);
  const viewService = useService($ViewService);
  const i18n = useService($I18n);
  const track = useLogEvent();
  const activeChannel = useReactiveState(() => {
    if (activeChannelService.state.kind === 'active') {
      return activeChannelService.activeChannel;
    } else {
      return null;
    }
  }, [activeChannelService]);
  const useData = data ?? cachedData;
  const activeItem = React.useMemo(() => {
    return activeChannel && useData && useData.flatMap(it => it.channels).find(it => it.name === activeChannel.name);
  }, [activeChannel, useData]);
  const activeGroup = React.useMemo(() => {
    if (!activeChannel) {
      return;
    }
    return useData?.find(it => it.name === activeChannel.name);
  }, [activeItem, activeChannel]);
  if (!useData || useData.length === 0) {
    return null;
  }
  const shownChannels = activeChannel && variant === 'mobile' && useData ? useData.slice(0, -2) : useData;
  const button = <CondensedButton mx={layout === 'short' ? 'base' : 'none'} onPress={() => {
    if (variant === 'desktop') {
      viewService.openView(channelListViewId, {
        openContext: 'discover'
      });
    } else {
      viewService.openView(channelViewId.with(s => s.openChannelList()), {
        openContext: 'discover'
      });
    }
    track('Dashboard_Channel', 'OpenChannelselection_Clicked');
  }} text={declareFormat({
    id: 'dashboard.channellist.button',
    defaultFormat: 'Show all channels'
  }).format(i18n)} />;
  return <div className={_c0}>
			<div className={_c1 + (layout === 'short' ? _c2 : _c3)}>
				<Text type={'body2'} state={'tertiary'} bold={true} className={_c4}>
					{declareFormat({
          id: 'dashboard.channellist.title',
          defaultFormat: 'Channels for you'
        }).format(i18n).toUpperCase()}
				</Text>
				{variant === 'desktop' && button}
			</div>
			<div className={_c5 + (layout === 'wide' ? _c6 : _c7)}>
				<ChannelGroupExpandedContextDefaultProvider>
					{activeChannel && variant === 'mobile' && <div className={_c8}>
							<ActiveChannelListItem group={activeGroup} activeChannel={activeChannel} previewMembers={activeItem?.previewMembers} />
						</div>}
					<Elements elements={shownChannels} />
				</ChannelGroupExpandedContextDefaultProvider>
			</div>
			{variant === 'mobile' && <div className={_c9}>
					{button}
				</div>}
		</div>;
};
const Group: React.FC<{
  group: ChannelGroupFragment;
  isLast: boolean;
}> = ({
  group,
  isLast
}) => {
  const track = useLogEvent();
  const userCount = group.channels.reduce((previousValue, currentValue) => previousValue + currentValue.onlineUserCount, 0);
  return <ChannelListGroupItems expandingKey={group.id} channelGroup={group} userCount={userCount} size={'default'} isLast={isLast} shouldUseShortDescription={false} trackingContentType={'Dashboard_Channel'} onChannelSelected={channelName => {
    track('Dashboard_Channel', channelName + '_Clicked');
  }} />;
};
type QueryResult = typeof GetDashboardChannelGroups.TPrimaryResult;
type DataUpdater = (prevData: QueryResult) => QueryResult;
const useUpdater = (setData: (updater: DataUpdater) => void) => {
  const updateChannelData = React.useCallback((newChannel: ChannelGroupFragment) => {
    const groupId = newChannel.id;
    setData(prevData => {
      const oldChannel = prevData.find(it => it.id === groupId);
      if (!oldChannel) {
        return prevData;
      }
      if (equalUpToDepth(oldChannel, newChannel, undefined, 10)) {
        return prevData;
      }
      const channelToInsert = mergePreviewMembers(oldChannel, newChannel);
      return prevData.map(group => {
        if (group.id === groupId) {
          return channelToInsert;
        }
        return group;
      });
    });
  }, [setData]);
  const overlayOpen = useIsOverlayOpen();
  return useChannelGroupUpdater(!overlayOpen, updateChannelData);
};
const useIsOverlayOpen = () => {
  const viewService = useService($ViewService);
  return useReactiveState(() => {
    return viewService.layout.additionalOverlays.length > 0;
  }, [viewService]);
};
const _c0 = " Knu-FlexCol ";
const _c1 = " Knu-Flex mb-minor mt-base justifyContent-space-between alignItems-center ";
const _c2 = " ml-base ";
const _c3 = " ml-none ";
const _c4 = "  ";
const _c5 = " Knu-FlexCol ";
const _c6 = " px-none ";
const _c7 = " px-base ";
const _c8 = " Knu-FlexCol mb-small ";
const _c9 = " Knu-Flex alignSelf-center mt-base ";