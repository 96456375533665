import React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { useNavigate } from '../../../ChannelListRouter';
import { useSmallChannelGroupExpanded } from '../../../SmallChannelGroupExpandedContext';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { CondensedButton } from '@shared/components';

/**
 * Redirects to the category page with all channels of the category
 * @param categoryName - The name of the category
 * @param categoryId - The id of the category
 * @param grouped - Whether the channels should be grouped by count online users
 */
export const ShowAllChannelsButton: React.FC<{
  categoryName: string;
  categoryId: string;
  trackingContentType: string;
  grouped?: boolean;
}> = ({
  categoryName,
  categoryId,
  trackingContentType,
  grouped = true
}) => {
  const i18n = useService($I18n);
  const navigate = useNavigate();
  const track = useLogEvent();
  const showAllChannels = (): void => {
    track(trackingContentType, `Channellist_All_${categoryName}ChannelsClicked`);
    navigate('category', {
      params: {
        id: categoryId,
        grouped
      }
    });
  };
  return <CondensedButton mx={'base'} text={declareFormat({
    id: 'channellist.header.button.all',
    defaultFormat: 'All'
  }).format(i18n)} onPress={showAllChannels} />;
};
export const ExpandChannelsButton: React.FC = () => {
  const {
    isExpanded,
    setExpanded
  } = useSmallChannelGroupExpanded();
  const i18n = useService($I18n);
  const textFormatId = isExpanded ? declareFormat({
    id: 'channellist.header.category.button.less',
    defaultFormat: 'Less'
  }) : declareFormat({
    id: 'channellist.header.category.button.all',
    defaultFormat: 'All'
  });
  const expandChannels = (): void => {
    setExpanded(!isExpanded);
  };
  return <CondensedButton mx={'base'} text={textFormatId.format(i18n)} onPress={expandChannels} />;
};