import * as React from 'react';
import { ChannelRouteConfig, Router } from '@knuddelsModules/Channel/bundle/components/ChannelRouter';
import { FlexCol, isMobileSafari, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { ChannelRoot } from '@knuddelsModules/Channel/bundle/components/ChannelRoot';
import { RecommendedChannelsProvider } from '@knuddelsModules/ChannelList/bundle/components/Overview/ChannelSelection/RecommendedChannelsContext';
import { observer } from '@knuddels-app/mobx';
import { $ViewService } from '@knuddels-app/layout';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ActiveChannelService, channelViewId } from '@knuddelsModules/Channel';
import { useIsStackedLayout } from '@knuddels-app/Screen';
import { $ClientSettingsService, $SettingsComponents, settingsFormat } from '@knuddelsModules/Settings';
import { $I18n } from '@knuddels-app/i18n';
import { LoadService } from '@knuddels-app/ModuleSystem';
import { $ChannelListService } from '@knuddelsModules/ChannelList';
import styles from './Channel.module.scss';
import { $NativeWebViewService } from '@knuddels-app/NativeWebView';
export const Channel: React.FC<{
  routeChatId: string | undefined;
  isNicklistOpen: boolean;
}> = observer('Channel', ({
  routeChatId,
  isNicklistOpen
}) => {
  const viewService = useService($ViewService);
  const isStackedLayout = useIsStackedLayout();
  const i18n = useService($I18n);
  const settingsComponents = useService($SettingsComponents);
  const activeChannelService = useService($ActiveChannelService);
  const nativeWebViewService = useService($NativeWebViewService);
  const active = !isStackedLayout || viewService.findPersistedView(channelViewId)?.state?.stack?.some(it => it.path === 'chat');
  React.useEffect(() => {
    if (isStackedLayout && !activeChannelService.activeChannel && activeChannelService.isChannelViewInitialized && viewService.isViewVisible(channelViewId)) {
      viewService.openView(channelViewId.with(s => s.openChannelList()));
    }
  }, [isStackedLayout]);
  const {
    onNavigate,
    refetchOverview
  } = useRefetchOnChange();
  return <div className={_c0 + ((isStackedLayout ? "contentBg" : "transparent") ? resolveIsDarkColor(isStackedLayout ? "contentBg" : "transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (isStackedLayout ? _c1 : _c2)}>
			{isStackedLayout && <div className={_c3}>
					<RecommendedChannelsProvider>
						<Router rootPath={'overview'} onNavigate={e => {
          onNavigate(e);
          if (e.newPath === 'overview') {
            nativeWebViewService.updateBackgroundState('background');
          }
        }}>
							<Router.Route path={'overview'} title={'Channels'} hideShadow>
								<LoadService service={$ChannelListService}>
									{s => <s.Overview refetchOverview={refetchOverview} />}
								</LoadService>
							</Router.Route>
							<Router.Route path={'category'}>
								<LoadService service={$ChannelListService}>
									{s => <s.FullCategory />}
								</LoadService>
							</Router.Route>
							<Router.Route path={'channel-list-settings'} title={settingsFormat.format(i18n)}>
								<settingsComponents.ChannelListSettings />
							</Router.Route>
							<Router.Route path={'chat'} hideTitleBar>
								<ChatRoute />
							</Router.Route>
						</Router>
					</RecommendedChannelsProvider>
				</div>}

			<div className={`Knu-FlexCol inset-none position-absolute ${isStackedLayout ? styles[isMobileSafari ? 'ChatViewIOS' : 'ChatView'] : ''} ` + (isStackedLayout && active ? styles.active : '')}>
				<ChannelRoot routeChatId={routeChatId} nicklistOpen={isNicklistOpen} />
			</div>
		</div>;
});
const ChatRoute: React.FC<{}> = () => {
  return null;
};

// TODO: duplicate from channel list (maybe move to shared location/import from channel list)
const useRefetchOnChange = () => {
  const clientSettingsService = useService($ClientSettingsService);
  const settingsChanged = React.useRef(false);
  const isSettingsOpen = React.useRef(false);
  const [refetchOverview, setRefetchOverivew] = React.useState(false);
  React.useEffect(() => {
    return clientSettingsService.addChannelListCategoriesMutationListener(() => {
      if (isSettingsOpen.current) {
        settingsChanged.current = true;
      } else {
        // Toggle state to make it sure it is refetched
        setRefetchOverivew(false);
        setTimeout(() => {
          setRefetchOverivew(true);
        }, 1);
      }
    });
  }, []);
  const onNavigate = React.useCallback((changes: {
    oldPath: keyof ChannelRouteConfig;
    newPath: keyof ChannelRouteConfig;
  }) => {
    isSettingsOpen.current = changes.newPath === 'channel-list-settings';
    if (changes.oldPath === 'channel-list-settings') {
      if (settingsChanged.current === true) {
        settingsChanged.current = false;
        setRefetchOverivew(true);
      }
    } else if (changes.newPath === 'channel-list-settings') {
      setRefetchOverivew(false);
    }
  }, []);
  return {
    onNavigate,
    refetchOverview
  };
};
const _c0 = " Knu-FlexCol flex-1 size-full position-relative ";
const _c1 = " bg-contentBg ";
const _c2 = " bg-transparent ";
const _c3 = " Knu-FlexCol flex-1 size-full position-absolute zIndex-0 ";