import * as React from 'react';
import { Box, ChildThemeProvider, createSvgIcon, Fab, FlexCol, Text, useChildTheme, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
const IconComments = createSvgIcon(<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M23.9903 4.16308C21.2521 1.41254 17.4912 -0.0937126 13.5984 0.00452112C5.84565 0.233733 -0.224555 6.61892 0.00637631 14.3139C0.0393666 15.9184 0.369269 17.4901 0.963093 18.9636C1.45795 20.2079 2.11775 21.354 2.94251 22.4018L1.78785 25.1196C1.32599 26.1674 1.82084 27.379 2.87653 27.8374C3.23942 28.0011 3.66829 28.0339 4.06418 27.9684L9.4086 27.0515C11.322 27.7064 13.3344 27.9356 15.3468 27.7392C22.2418 27.0515 27.6192 21.4522 27.9821 14.5759C28.18 10.6792 26.7285 6.91362 23.9903 4.16308ZM12.9715 19.4548H9.3096" fill="white" />
	</svg>);
IconComments.displayName = 'IconComments';
export const OpenCommentsButton: React.FC<{
  onPress: () => void;
  count: number | undefined;
}> = React.memo(({
  onPress,
  count
}) => {
  const sizeOverrideTheme = useChildTheme({
    sizes: {
      icons: {
        large: 28
      }
    }
  }, []);
  return <div className={_c0}>
			<Fab onPress={onPress}>
				<div className={_c1 + ("transparent" ? resolveIsDarkColor("transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
					<ChildThemeProvider theme={sizeOverrideTheme}>
						<Fab.Icon icon={IconComments} />
					</ChildThemeProvider>
					<div className={_c2}>
						{count !== undefined && <Text type={'body1'} state={'highlighted'} bold={true} className={_c3}>
								{count}
							</Text>}
					</div>
				</div>
			</Fab>
		</div>;
});
OpenCommentsButton.displayName = 'OpenCommentsButton';
const _c0 = " Knu-FlexCol position-absolute right-base bottom-large placeItems-center ";
const _c1 = " Knu-Box bg-transparent ";
const _c2 = " Knu-FlexCol position-absolute left-none top-none right-none bottom-none placeItems-center ";
const _c3 = "  ";