import { injectable } from '@knuddels-app/DependencyInjection';
import { PersistedViewState, Position, ViewId, ViewProvider, ViewRegistry, ViewState } from '@knuddels-app/layout';
import * as React from 'react';
import { ModalAppView } from './bundle/components/ModalAppView';
import { LoadService } from '@knuddels-app/ModuleSystem';
import { $ModalAppViewer } from './providedServices';
import { DISABLE_ADS_APP_IDS } from '@shared/constants';
@injectable()
export class AppModalViewProvider implements ViewProvider {
  registerViews(registry: ViewRegistry): void {
    const disableMobileBottomAds = (state: AppModalViewState) => DISABLE_ADS_APP_IDS.includes(state.appId);
    registry.registerView<AppModalViewState>({
      viewId: appModalViewId,
      position: Position.Overlay,
      loadState: AppModalViewState.fromPersistedViewState,
      allowMultipleOverlays: true,
      isSystemApp: true,
      disableMobileBottomAds,
      render: state => {
        return {
          mainView: <LoadService service={$ModalAppViewer}>
							{() => <ModalAppView key={state.uniqueId} showTitleBar={state.showTitleBar} appId={state.appId} disableAds={disableMobileBottomAds(state)}
            // this is needed in order to rerender ModalAppView if app ids are equal
            uniqueId={state.uniqueId} />}
						</LoadService>
        };
      }
    });
  }
}
export class AppModalViewState implements ViewState {
  constructor(public appId: string, public uniqueId: string, public showTitleBar: boolean) {}
  public static fromPersistedViewState(state: PersistedViewState): AppModalViewState {
    if (!state.state) {
      return new AppModalViewState('', '', false);
    }
    const newState = (state.state as {
      appId: string;
      uniqueId: string;
      showTitleBar: boolean;
    });
    return new AppModalViewState(newState.appId, newState.uniqueId, newState.showTitleBar);
  }
  persist(): PersistedViewState {
    return {
      pathItems: [],
      state: {
        appId: this.appId,
        uniqueId: this.uniqueId,
        showTitleBar: this.showTitleBar
      }
    };
  }
}
export const appModalViewId = new ViewId<any>('appView');