import * as React from 'react';
import { declareProps, inject, injectable, injectedComponent, injectProps, useService } from '@knuddels-app/DependencyInjection';
import { $UserService, KnuddelsPlusStatusBadge, UserImage, UserImageType } from '@knuddelsModules/UserData';
import { CurrentUserKnuddelDisplayLazy } from '@knuddelsModules/KnuddelDisplay';
import { Observer } from 'mobx-react';
import { Flex, FlexCol, Text, Tooltip, toPointerHandler } from '@knuddels/component-library';
import { $PromotionService } from '@knuddelsModules/Promotions';
import { $EvergreenDataService } from '@knuddels-app/evergreenData';
@injectable()
class NavBarProfileModel {
  constructor(@injectProps()
  private readonly props: unknown, @inject($UserService)
  private readonly userService: typeof $UserService.T) {}
  public get currentUserId(): string | undefined {
    return this.userService.currentUser && this.userService.currentUser.id;
  }
  public get currentUserNick(): string | undefined {
    return this.userService.currentUser && this.userService.currentUser.nick;
  }
}
const Label = 'Knuddel kaufen';
export const NavBarProfile = injectedComponent({
  name: 'NavBarProfile',
  model: NavBarProfileModel,
  props: declareProps<{
    collapsed: boolean;
    onProfilePicClick(): void;
    onNicknameClick(): void;
  }>()
}, ({
  model,
  collapsed,
  onProfilePicClick,
  onNicknameClick
}) => {
  const promotionService = useService($PromotionService);
  const evergreenData = useService($EvergreenDataService);
  const loyaltySystemWarning = evergreenData.data.loyalty.state === 'POINT_LOSS' || evergreenData.data.loyalty.state === 'LEVEL_LOSS';
  const renderUserPic = (type: UserImageType) => <Tooltip content={'Profil öffnen'} spacing={8}>
				<div onClick={toPointerHandler(onProfilePicClick)} className={_c0}>
					<Observer>
						{() => <UserImage type={type} userId={model.currentUserId} />}
					</Observer>
				</div>
			</Tooltip>;
  return <div className={_c1 + (collapsed ? _c2 : _c3)}>
				{collapsed ? <div className={_c4}>
						<div className={_c5}>
							{renderUserPic(UserImageType.NAV_SMALL)}
						</div>
						<div className={_c6}>
							<Tooltip content={Label}>
								<CurrentUserKnuddelDisplayLazy type={'vertical'} numberFormat={'full'} showLoyaltySystemLabels firebaseContentType={'Navigation_SidebarNavigation'} />
							</Tooltip>
						</div>
					</div> : <div className={_c7}>
						{renderUserPic(UserImageType.NAV_BIG)}
						<div className={_c8}>
							<div className={_c9}>
								<div onClick={toPointerHandler(onNicknameClick)} className={_c10}>
									<Text type={'body1'} bold={true} numberOfLines={1} className={_c11}>
										{model.currentUserNick || ''}
									</Text>
								</div>
								{!promotionService.activeKnuddelPromotion && !loyaltySystemWarning && <KnuddelsPlusStatusBadge />}
							</div>
							<Tooltip content={Label}>
								<CurrentUserKnuddelDisplayLazy type={'normal'} numberFormat={'none'} showLoyaltySystemLabels showLoyaltySystemIcon={false} promotionBannerLocation={'bottom'} firebaseContentType={'Navigation_SidebarNavigation'} />
							</Tooltip>
						</div>
					</div>}
			</div>;
});
const _c0 = " Knu-FlexCol cursor-pointer justifyContent-center ";
const _c1 = " Knu-FlexCol position-relative overflow-hidden minHeight-100px ";
const _c2 = " justifyContent-flex-start ";
const _c3 = " justifyContent-center ";
const _c4 = " Knu-FlexCol pt-minor zIndex-1 alignItems-center ";
const _c5 = " Knu-Flex mb-minor ";
const _c6 = " Knu-FlexCol overflow-visible ";
const _c7 = " Knu-Flex pl-base zIndex-1 ";
const _c8 = " Knu-FlexCol flex-1 overflow-hidden ml-minor gap-small justifyContent-center ";
const _c9 = " Knu-Flex alignItems-center gap-tiny ";
const _c10 = " Knu-FlexCol cursor-pointer ";
const _c11 = "  ";