import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { observable } from '@knuddels-app/mobx';
import { action } from '@knuddels-app/mobx';
import { $ScreenService } from '@knuddels-app/Screen';
import { ScreenService } from '@knuddels-app/Screen/ScreenService';

const storageKey = 'ReduceMotion';

@injectable()
export class AnimationSettingsService {
	@observable
	private _reduceMotion: boolean;

	public get reduceMotion() {
		return this._reduceMotion;
	}

	public get isAnimationEnabled() {
		return !this._reduceMotion && !this.screenService.isStackedLayout;
	}

	@action
	public setReduceMotion(reduceMotion: boolean) {
		this._reduceMotion = reduceMotion;
		window.localStorage.setItem(storageKey, reduceMotion.toString());
	}

	constructor(
		@inject($ScreenService) private readonly screenService: ScreenService
	) {
		const localStorageValue = window.localStorage.getItem(storageKey);
		this._reduceMotion = localStorageValue
			? localStorageValue === 'true'
			: window.matchMedia('(prefers-reduced-motion: reduce)').matches;
	}
}
