import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $MessageFormatProvider } from '@knuddels-app/i18n';
import { Disposable } from '@knuddels/std';
import { NoChannelDashboard } from '../components/NoChannelDashboard';
@injectable()
export class ExploreComponents {
  public readonly dispose = Disposable.fn();
  public readonly MainView = NoChannelDashboard;
  constructor(@inject($MessageFormatProvider)
  messageFormatProvider: typeof $MessageFormatProvider.T) {
    messageFormatProvider.registerFormatProvider(locale =>
    // Workaround for metro bundler because it can't handle dynamic imports.
    // See https://github.com/facebook/metro/issues/52
    ({
      de: require('../i18n/formats.de.json'),
      en: require('../i18n/formats.en.json')
    } as any)[locale.language]);
  }
}