import { injectable } from '@knuddels-app/DependencyInjection';
import { Position, ViewProvider, ViewRegistry } from '@knuddels-app/layout';
import * as React from 'react';
import { getRouterStateFromPersistedViewState, RouterViewState } from '@knuddels-app/layout/k3Router';
import { LoadService } from '@knuddels-app/ModuleSystem';
import * as providesServices from './providedServices';
import { GenericSystemAppView } from './bundle/components/GenericSystemAppView/GenericSystemAppView';
import { viewIdForSystemApp } from '@knuddelsModules/SystemApps/bundle/components/GenericSystemAppView/viewId';
import { RouterParamsConfig } from '@knuddels/component-library';
import { DISABLE_ADS_APP_IDS } from '@shared/constants';
@injectable()
export class GenericSystemAppViewProvider implements ViewProvider {
  registerViews(registry: ViewRegistry): void {
    registry.registerDynamicView<GenericSystemAppViewState>({
      getViewConfig: viewId => {
        if (!viewId.id.startsWith('app-')) {
          return null;
        }
        const appId = viewId.id.replace('app-', '');
        const disableAds = DISABLE_ADS_APP_IDS.includes(appId);
        return {
          viewId,
          position: Position.Side,
          isSystemApp: true,
          loadState: GenericSystemAppViewState.fromPersistedViewState,
          disableMobileBottomAds: () => disableAds,
          render: () => {
            return {
              mainView: <LoadService service={providesServices.$SystemAppService}>
									{() => <GenericSystemAppView viewId={viewId} appId={appId} />}
								</LoadService>
            };
          }
        };
      },
      getViewId: id => {
        if (!id.startsWith('app-')) {
          return null;
        }
        return viewIdForSystemApp(id.replace('app-', ''));
      }
    });
  }
}
export class GenericSystemAppViewState<T extends RouterParamsConfig<any> = {}> extends RouterViewState<T> {
  public static fromPersistedViewState = getRouterStateFromPersistedViewState(GenericSystemAppViewState);
}