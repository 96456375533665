import { useService } from '@knuddels-app/DependencyInjection';
import { $ScreenService } from '@knuddels-app/Screen';
import React from 'react';
import { isNative } from './isNative';
import { LayoutEvent } from '@knuddels/component-library';
const getNumber = (key: string, storage: typeof window.sessionStorage | typeof window.localStorage) => {
  const value = storage.getItem(key);
  if (!value) {
    return 0;
  }
  return parseInt(value);
};
const setNumber = (key: string, value: number, storage: typeof window.sessionStorage | typeof window.localStorage) => {
  storage.setItem(key, value.toString());
};
const useWidth = (key: string) => {
  const [width, setWidth] = React.useState(() => getNumber(key, window.sessionStorage));
  return {
    width,
    onLayout: (e: LayoutEvent) => {
      if (e.width !== width) {
        setWidth(e.width);
        setNumber(key, e.width, window.sessionStorage);
      }
    }
  };
};
export const useCachedWidth = (key: string) => {
  if (!isNative()) {
    return useWidth(key);
  }
  const screenService = useService($ScreenService);
  const actualKey = key + screenService.orientation;
  const [cachedWidth, setCachedWidth] = React.useState(() => getNumber(actualKey, window.localStorage));
  const setWidth = (width: number) => {
    if (cachedWidth !== width) {
      setCachedWidth(width);
      setNumber(actualKey, width, window.localStorage);
    }
  };
  return {
    width: cachedWidth,
    onLayout: (e: LayoutEvent) => {
      setWidth(e.width);
    }
  };
};