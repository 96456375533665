import * as React from 'react';
import { Z_INDEX } from '@shared/components';
import { Flex, IconBack, IconClose, IconMenu, ThemeOverride, resolveThemingValue, useTheme } from '@knuddels/component-library';
interface NavBarHeaderProps {
  backIcon: 'back' | 'close';
  collapsed?: boolean;
  onToggleCollapsed: (collapsed: boolean) => void;
}
import { KnuddelsLogoSvg } from '../MenuBottomSheet/KnuddelsLogoSvg';
export const NavBarHeader: React.FC<NavBarHeaderProps> = ({
  backIcon,
  collapsed,
  onToggleCollapsed
}) => {
  const handleOnClick = React.useCallback(() => {
    onToggleCollapsed(!collapsed);
  }, [collapsed]);
  return <div style={{
    zIndex: resolveThemingValue(Z_INDEX.OVERCONTENT, "theme", useTheme())
  }} className={_c0}>
			<div className={_c1}>
				{collapsed ? <IconMenu label={'Menü öffnen'} size={'large'} onPress={handleOnClick} /> : backIcon === 'back' ? <IconBack size={'large'} label={'Menü schließen'} onPress={handleOnClick} /> : <IconClose size={'large'} label={'Menü schließen'} onPress={handleOnClick} />}

				{!collapsed && <KnuddelsLogoSvg />}
			</div>
		</div>;
};
const _c0 = " Knu-Flex mt-20px ";
const _c1 = " Knu-Flex alignItems-center pl-minor gap-large ";