import { injectable } from '@knuddels-app/DependencyInjection';
import { action, computed, observable, runInAction } from '@knuddels-app/mobx';
import { Disposable } from '@knuddels/std';
import { dismissKeyboard as doDismissKeyboard } from '@shared/helper/dismissKeyboard';
import { OS, os } from '@shared/components/tools/os';
import { isNative } from '@knuddels-app/tools/isNative';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { WebviewResize } from '@knuddels/webview-resize-capacitor-plugin';
import { debounce } from '@shared/components';
import { isSafariMobile } from '@knuddels-app/tools/isSafari';
import { isTouchDevice } from '@knuddels/component-library';
export type Focusable = {
  focus: () => void;
  blur: () => void;
  setInputMode: (mode: 'none' | 'text') => void;
  getExtraSpace?: () => number;
  parentFocusable?: Focusable;
};
const STORED_KEYBOARD_HEIGHT_KEY = 'keyboardHeight';
const STORED_KEYBOARD_HEIGHT = localStorage.getItem(STORED_KEYBOARD_HEIGHT_KEY);
type VisibleKeyboardType = 'smiley' | 'feature' | 'none';
@injectable()
export abstract class KeyboardServiceBase {
  protected static DEFAULT_KEYBOARD_SIZE = STORED_KEYBOARD_HEIGHT ? parseInt(STORED_KEYBOARD_HEIGHT, 10) : 248;
  protected readonly dispose = Disposable.fn();
  protected _supportsVirtualKeyboard: boolean = false;
  get supportsVirtualKeyboard(): boolean {
    return this._supportsVirtualKeyboard;
  }
  public get focusable(): Focusable | null {
    return this._focusable;
  }
  @computed
  public get anyKeyboardVisible(): boolean {
    return this.currentlyVisibleCustomKeyboard !== 'none' || this.isKeyboardVisible;
  }
  @observable
  public modeInChannel: 'new' | 'default' = 'new';
  @computed
  get currentlyVisibleCustomKeyboard(): VisibleKeyboardType {
    return this._currentlyVisibleCustomKeyboard;
  }
  @observable
  protected _currentlyVisibleCustomKeyboard: VisibleKeyboardType = 'none';
  @computed
  get showSmileyOrFeatureBox(): boolean {
    return this.currentlyVisibleCustomKeyboard === 'smiley' || this.currentlyVisibleCustomKeyboard === 'feature';
  }
  @observable
  protected _keyboardHeight = KeyboardServiceBase.DEFAULT_KEYBOARD_SIZE;
  @observable
  protected _additionalKeyboardHeight = 0;
  @observable
  protected _isKeyboardVisible = false;
  public get isKeyboardVisible(): boolean {
    return this._isKeyboardVisible;
  }

  /**
   * Returns the measured keyboard size or the default keyboard size
   * if the keyboard was not open yet.
   */
  @computed
  public get keyboardHeight(): number {
    return this._keyboardHeight + this._additionalKeyboardHeight;
  }
  public set keyboardHeight(value: number) {
    if (value === this._keyboardHeight) {
      return;
    }
    runInAction('set keyboardHeight', () => {
      this._keyboardHeight = value;
    });
    localStorage.setItem(STORED_KEYBOARD_HEIGHT_KEY, value.toString());
  }
  resetSmileyBoxRecentlyToggle = debounce(() => {
    this.smileyBoxRecentlyToggle = false;
  });
  public smileyBoxRecentlyToggle = false;
  private focusTimeout: any = null;
  @observable.ref
  private _focusable: Focusable | null = null;
  constructor() {
    if (isNative()) {
      Keyboard.addListener('keyboardWillShow', info => {
        this.keyboardHeight = info.keyboardHeight;
        this.handleKeyboardShowEvent(true);
      }).then(h => {
        this.dispose.track(h.remove);
      });
      Keyboard.addListener('keyboardWillHide', () => {
        this.handleKeyboardShowEvent(false);
      }).then(r => {
        this.dispose.track(r.remove);
      });
    }
  }
  @action.bound
  handleKeyboardShowEvent(show: boolean): void {
    if (this.smileyBoxRecentlyToggle) {
      return;
    }
    this.setKeyboardVisible(show);
  }
  @action.bound
  showSmileyBoxToggle(): boolean {
    this._currentlyVisibleCustomKeyboard = this.currentlyVisibleCustomKeyboard !== 'smiley' ? 'smiley' : 'none';
    this.handleKeyboardTypeChange();
    return this.currentlyVisibleCustomKeyboard === 'smiley';
  }
  private handleKeyboardTypeChange(): void {
    this.smileyBoxRecentlyToggle = true;
    if (os === OS.ios || isSafariMobile()) {
      this.handleIOS(this.currentlyVisibleCustomKeyboard !== 'none');
    } else {
      this.handleAndroid(this.currentlyVisibleCustomKeyboard !== 'none');
    }
    this.resetSmileyBoxRecentlyToggle();
  }
  @action.bound
  private openKeyboard(): void {
    this._currentlyVisibleCustomKeyboard = 'none';
    this.smileyBoxRecentlyToggle = true;
    if (os === OS.ios || isSafariMobile()) {
      this.handleIOS(false);
    } else {
      this.handleAndroid(false);
    }
    this.resetSmileyBoxRecentlyToggle();
  }
  @action.bound
  openKeyboardOrClose(focusable: Focusable): void {
    this.setFocusable(focusable);
    if (this.isKeyboardVisible) {
      this.closeAll();
    } else {
      this.openKeyboard();
    }
  }
  @action.bound
  openSmileyBoxOrClose(focusable: Focusable): void {
    this.setFocusable(focusable);
    if (this.currentlyVisibleCustomKeyboard === 'smiley') {
      this.closeAll();
    } else {
      this.showSmileyBoxToggle();
    }
  }
  @action.bound
  openFeatureBoxOrClose(focusable: Focusable): void {
    this.setFocusable(focusable);
    if (this.currentlyVisibleCustomKeyboard === 'feature') {
      this.closeAll();
    } else {
      this._currentlyVisibleCustomKeyboard = 'feature';
      this.handleKeyboardTypeChange();
    }
  }
  private setInputMode(hideNativeKeyboard: boolean): void {
    if (hideNativeKeyboard) {
      this.focusable?.setInputMode('none');
    } else {
      this.focusable?.setInputMode('text');
    }
  }
  private handleAndroid(hideNativeKeyboard: boolean): void {
    this.setInputMode(hideNativeKeyboard);
    if (hideNativeKeyboard) {
      this.hideKeyboard();
    }
    this.focusable?.focus();
    this.setKeyboardVisible(!hideNativeKeyboard);
  }
  private handleIOS(hideNativeKeyboard: boolean): void {
    this.setInputMode(hideNativeKeyboard);
    setTimeout(() => {
      this.focusable?.focus();
    }, 300);
    this.setKeyboardVisible(!hideNativeKeyboard);
  }
  @action.bound
  public setKeyboardVisible(visible: boolean): void {
    this._isKeyboardVisible = visible;
  }
  @action.bound
  public setFocusable(focusable: Focusable | null): void {
    if (this.focusTimeout) {
      clearTimeout(this.focusTimeout);
      this.focusTimeout = null;
    }
    this._focusable = focusable;
    this._additionalKeyboardHeight = focusable?.getExtraSpace?.() ?? 0;
  }
  public removeFocusable(): void {
    this.focusTimeout = setTimeout(() => {
      this.focusable?.setInputMode('text');
      runInAction('remove focusable', () => {
        this._focusable = null;
        this._additionalKeyboardHeight = 0;
      });
      this.focusTimeout = null;
      if (isTouchDevice()) {
        this.closeAll();
      }
    }, 100);
  }
  public hideKeyboard(): void {
    Keyboard.hide();
  }
  public dismissKeyboard = (): void => {
    if (this.supportsVirtualKeyboard) {
      this.hideKeyboard();
    }
    doDismissKeyboard();
  };
  public async setViewNotResizing(): Promise<void> {
    WebviewResize.disableWebviewResize();
    if (isNative()) {
      await Keyboard.setResizeMode({
        mode: KeyboardResize.None
      });
    }
  }
  public closeAll(): void {
    runInAction('close smileybox', () => {
      this._currentlyVisibleCustomKeyboard = 'none';
      this.focusable?.setInputMode('text');
      this.setKeyboardVisible(false);
    });
    this.dismissKeyboard();
  }
}