import * as React from 'react';
import { useMemo } from 'react';
import { observer } from '@knuddels-app/mobx';
import { calculateGradient, createAccessiblePointerEventHandler, Flex, FlexCol, Link, parseRgbString, useSafeAreaTopHeight, ThemeOverride, useTheme, Box, resolveThemingValue, createNativeAccessiblePointerEventHandler, resolveIsDarkColor } from '@knuddels/component-library';
import { LoadService } from '@knuddels-app/ModuleSystem';
import { $KnuddelDisplayComponents } from '@knuddelsModules/KnuddelDisplay';
import { useService } from '@knuddels-app/DependencyInjection';
import { $UserService, KnuddelsPlusStatusBadge, UserImage } from '@knuddelsModules/UserData';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { $OverlayService } from '@knuddels-app/overlays';
import { $AdsService, CAPACITOR_BADGE_HEIGHT, CapacitorBanners } from '@knuddelsModules/Ads';
import { useReactiveState } from '@knuddels-app/tools/useReactiveState';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
import { PROFILE_CUSTOMIZATION_APP_ID } from '@shared/constants';
import { GreetingText } from './GreetingText';
const useIsOverlayOpen = () => {
  const overlayService = useService($OverlayService);
  return useReactiveState(() => {
    return overlayService.isAnyOverlayVisible;
  }, [overlayService]);
};
export const DashboardMobileHeader: React.FC<{
  hasShadow: boolean;
}> = observer('DashboardHeader', ({
  hasShadow
}) => {
  const safeAreaTopHeight = useSafeAreaTopHeight();
  const adsService = useService($AdsService);
  const isOverlayOpen = useIsOverlayOpen();
  const showAds = adsService.areCapacitorAdsVisible;
  const adsHeight = showAds ? CapacitorBanners.K3MoreBanner[1] + CAPACITOR_BADGE_HEIGHT : 0;
  const backgroundColor = useBackgroundColor();
  const isDarkTheme = useTheme().id !== 'light';
  return <div style={{
    height: resolveThemingValue(80 + adsHeight + safeAreaTopHeight, "sizes", useTheme()),
    background: resolveThemingValue((backgroundColor as ThemeOverride), "colors", useTheme())
  }} className={_c0 + ((backgroundColor as ThemeOverride) ? resolveIsDarkColor((backgroundColor as ThemeOverride), useTheme()) ? " content-is-dark" : " content-is-light" : "") + (true ? " animated-all " : "") + (hasShadow ? _c1 : _c2)}>
				<div style={{
      height: resolveThemingValue(safeAreaTopHeight, "sizes", useTheme())
    }} className={_c3} />
				{showAds && <div className={_c4 + ("transparent" ? resolveIsDarkColor("transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
						<adsService.CapacitorAdzone adVisible={!isOverlayOpen} bannerId={'K3DiscoverBanner'} />
					</div>}
				<div className={_c5}>
					<div className={_c6}>
						<HeaderUserImage />
						<HeaderTitle />
					</div>
					<div className={_c7 + ((isDarkTheme ? "transparentDark" : "transparentLight") ? resolveIsDarkColor(isDarkTheme ? "transparentDark" : "transparentLight", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (isDarkTheme ? _c8 : _c9)}>
						<LoadService service={$KnuddelDisplayComponents}>
							{components => <components.CurrentUserKnuddelDisplay type={'normal'} showLoyaltySystemLabels numberFormat={'full'} firebaseContentType={'Dashboard_Header'} />}
						</LoadService>
					</div>
				</div>
			</div>;
});
const HeaderTitle: React.FC = () => {
  const profileNavigationService = useService($ProfileNavigationService);
  const track = useLogEvent();
  const theme = useTheme();
  return <div className={_c10 + ((theme.id === 'light' ? "transparentLight" : "transparentDark") ? resolveIsDarkColor(theme.id === 'light' ? "transparentLight" : "transparentDark", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (theme.id === 'light' ? _c11 : _c12)}>
			<Link state={'secondary'} type={'subtitle'} bold onPress={() => {
      profileNavigationService.showCurrentUserProfile();
      track('Dashboard_Header', 'Nickname_Clicked');
    }}>
				<GreetingText />
			</Link>
			<KnuddelsPlusStatusBadge />
		</div>;
};
const HeaderUserImage: React.FC = () => {
  const userService = useService($UserService);
  const profileNavigationService = useService($ProfileNavigationService);
  const track = useLogEvent();
  const execute = useSlashCommandWithoutChannel();
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: () => {
      if (userService.currentUser.profilePicture.exists) {
        profileNavigationService.showCurrentUserProfile();
      } else {
        execute('/opensystemapp ' + PROFILE_CUSTOMIZATION_APP_ID);
      }
      track('Dashboard_Header', 'ProfileImage_Clicked');
    }
  })} className={_c13}>
			<UserImage type={48} userId={userService.currentUser?.id} />
		</div>;
};
const useBackgroundColor = () => {
  const theme = useTheme();
  return useMemo(() => {
    const rgb = parseRgbString(theme.colors.basic.accent);
    return calculateGradient(rgb, 0, 0.11);
  }, [theme]);
};
const _c0 = " Knu-FlexCol position-relative minHeight-0-px justifyContent-center overflow-hidden zIndex-1 ";
const _c1 = " shadow-Shadow2 ";
const _c2 = " shadow-none ";
const _c3 = " Knu-Flex ";
const _c4 = " Knu-Box display-contents bg-transparent ";
const _c5 = " Knu-Flex position-relative alignItems-center flex-1 ";
const _c6 = " Knu-Flex position-relative mx-base zIndex-1 alignItems-center flex-1 ";
const _c7 = " Knu-Flex position-relative right-base zIndex-2 ";
const _c8 = " bg-transparentDark ";
const _c9 = " bg-transparentLight ";
const _c10 = " Knu-FlexCol ml-small gap-2px ";
const _c11 = " bg-transparentLight ";
const _c12 = " bg-transparentDark ";
const _c13 = " Knu-Flex cursor-pointer ";