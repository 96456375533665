import { ChannelCategoryFragment } from '@generated/graphql';
import React from 'react';
import { ChannelGroupExpandedContext } from '../../../ChannelGroupExpandedContext';
import { getChannelGroupsPerCategory, mayShowMoreButton } from '../../constants';
import { ChannelListItem, createChannelGroupVirtualListItem, templateActiveChannel, templateCategoryHeader } from '../../../shared/ChannelList/ChannelListVirtualItems';
import { ShowAllChannelsButton } from './CategoryHeaderButton';
import { istLastVisitedCategory } from '../../../shared/getCategoryFormatId';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ActiveChannelService } from '@knuddelsModules/Channel';
import { useReactiveState } from '@knuddels-app/tools/useReactiveState';
export const useListData = (mayShowActiveChannel: boolean, categories: readonly ChannelCategoryFragment[], trackingContentType: string, condensedEntries?: boolean): ChannelListItem[] => {
  const activeChannelService = useService($ActiveChannelService);
  const activeChannel = useReactiveState(() => {
    if (activeChannelService.state.kind === 'active') {
      return activeChannelService.activeChannel;
    }
    return null;
  }, [activeChannelService]);
  const {
    isExpanded
  } = React.useContext(ChannelGroupExpandedContext);
  const result: ChannelListItem[] = [];
  const hasActiveChannel = mayShowActiveChannel && activeChannel;
  if (hasActiveChannel) {
    const groupData = categories.flatMap(it => it.channelGroups).find(it => it.channels.some(it1 => it1.name === activeChannel.name));
    result.unshift({
      type: templateActiveChannel,
      group: groupData,
      key: 'activeChannel',
      height: 168 + 8 + 16,
      previewMembers: groupData?.previewMembers,
      activeChannel
    });
  }
  categories.map(category => ({
    category,
    channelGroups: !hasActiveChannel ? category.channelGroups : category.channelGroups.filter(group => group.name !== activeChannel.mainChannelName)
  })).filter(it => it.channelGroups.length > 0).forEach(({
    category,
    channelGroups
  }) => {
    const groupsPerCategory = getChannelGroupsPerCategory(category.id);
    const exceedsLimit = channelGroups.length > groupsPerCategory;
    const headerButton = exceedsLimit && mayShowMoreButton(category.id) ? <ShowAllChannelsButton categoryName={category.name} categoryId={category.id} grouped={!istLastVisitedCategory(category?.id)} trackingContentType={trackingContentType} /> : null;
    result.push({
      type: templateCategoryHeader,
      key: category.id,
      height: 72,
      title: category.name,
      button: headerButton
    });
    const limitedGroups = channelGroups.slice(0, groupsPerCategory);
    limitedGroups.forEach((group, index) => {
      const userCount = group.channels.reduce((previousValue, currentValue) => previousValue + currentValue.onlineUserCount, 0);
      const key = category.id + '-' + group.id;
      result.push(createChannelGroupVirtualListItem(key, group, userCount, isExpanded(key), index === limitedGroups.length - 1, condensedEntries ? 'condensed' : 'default'));
    });
  });
  return result;
};