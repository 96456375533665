import { inject, injectable } from '@knuddels-app/DependencyInjection';
import {
	$NavBarRegistry,
	NavBarItemData,
	NavBarItemProvider,
	NavBarVariant,
} from '@knuddelsModules/LoggedInArea';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { exploreViewId } from '@knuddelsModules/Explore/ExploreViewProvider';
import { $FeatureService } from '@knuddels-app/featureFlags';
import { appNotificationExploreFlag } from '@knuddelsModules/FeatureFlags';
import { fromPromise } from '@knuddels-app/mobx';
import { NavBadge } from '@knuddelsModules/LoggedInArea/bundle/services';

@injectable()
export class ExploreNavBarItemProvider implements NavBarItemProvider {
	private readonly navBarRegistry = fromPromise(this.getNavBarRegistry());

	constructor(
		@inject($FeatureService)
		private readonly featureService: typeof $FeatureService.T,
		@inject.lazy($NavBarRegistry)
		private readonly getNavBarRegistry: typeof $NavBarRegistry.TLazy
	) {}

	getItems(variant: NavBarVariant): NavBarItemData[] {
		if (variant === 'desktop') {
			return [];
		}

		let badges: NavBadge[] = [];
		if (
			this.navBarRegistry.state === 'fulfilled' &&
			this.featureService.hasSomeFlagsEnabled([
				appNotificationExploreFlag,
			])
		) {
			badges = this.navBarRegistry.value.getMenuBadges();
		}

		return [
			{
				id: 'explore',
				kind: 'view',
				title: formatMessage(
					declareFormat({
						id: 'NAVBAR_ITEM_EXPLORE',
						defaultFormat: 'Explore',
					})
				),
				view: exploreViewId,
				trackingId: 'Explore',
				badges,
			},
		];
	}
}
