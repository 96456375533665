import { Position, ViewId, ViewProvider, ViewRegistry } from '@knuddels-app/layout';
import * as React from 'react';
import { injectable } from '@knuddels-app/DependencyInjection';
import { LoadService } from '@knuddels-app/ModuleSystem';
import { $MessengerComponents } from './providedServices';
import { getRouterStateFromPersistedViewState, RouteConfig, RouterViewState, StackRouteConfig } from '@knuddels-app/layout/k3Router';
import { MessengerRouterConfig } from './bundle/components/MessengerRouter';
import { ConversationDashboardContent } from '@knuddelsModules/Messenger/bundle/stappmessenger/ConversationDashboardContent';
const isStappMessenger = globalEnv.product === 'stapp-messenger';
@injectable()
export class MessengerViewProvider implements ViewProvider {
  registerViews(registry: ViewRegistry): void {
    registry.registerView({
      viewId: messengerViewId,
      position: Position.Side,
      loadState: MessengerViewState.fromPersistedViewState,
      // open messenger view automatically if in stapp.
      isDefault: () => isStappMessenger,
      useSplitView: true,
      reuseEqualRoutes: true,
      disableMobileBottomAds: () => true,
      splitViewPlaceholder: isStappMessenger ? <ConversationDashboardContent /> : undefined,
      render: () => {
        return {
          mainView: <LoadService service={$MessengerComponents}>
							{s => <s.Messenger />}
						</LoadService>
        };
      }
    });
  }
}
export class MessengerViewState extends RouterViewState<MessengerRouterConfig> {
  public static fromPersistedViewState = getRouterStateFromPersistedViewState(MessengerViewState);

  // this seems to actually make the url changes (without it most still works but the url doesn't update)
  getPathItems(): string[] {
    const topConversation = this.topConversationRoute();
    return topConversation?.params?.conversationId ? [topConversation?.params?.conversationId] :
    // with this we use the url if no conversation is in stack
    this.pathItems;
  }
  mapPathItemToRoute(pathItem: string): StackRouteConfig<MessengerRouterConfig> {
    return {
      path: 'conversation',
      params: {
        conversationId: pathItem
      },
      key: RouterViewState.generateId()
    };
  }
  withConversation(params: MessengerRouterConfig['conversation']['params']): MessengerViewState {
    const reuseRoute = this.stack.find(r => r.path === 'conversation');
    return this.withStack([...this.stack.filter(r => r.path !== 'conversation'), {
      path: 'conversation',
      params,
      // reuse route key to prevent animations
      key: reuseRoute ? reuseRoute.key : RouterViewState.generateId()
    }]);
  }
  topConversationRoute(): RouteConfig<any> {
    return [...this.stack].reverse().find(route => route.path === 'conversation');
  }
}
export const messengerViewId = new ViewId<MessengerViewState>('messenger');