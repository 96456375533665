import * as React from 'react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FlexCol } from '@knuddels/component-library';
import { AlbumDetailPhotoFragment, NotifyAlbumPhotosViewed, NotifyProfilePicturedViewed } from '@generated/graphql';
import { AlbumPhotoCarouselItemData, DescriptionBar } from './AlbumCarouselItem';
import { useIsStackedLayout } from '@knuddels-app/Screen';
import { OpenAlbumDetailsContextMenu } from '../AlbumDetailsContextMenu/OpenAlbumDetailsContextMenu';
import { ProfileAlbumInfo } from '../../profileQueryHelper';
import { useCloseAlbumDetails } from '../CloseAlbumDetails';
import { IsEditingPhotoDescriptionContext } from '../EditPhotoDescriptionContext';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { PROFILE_PICTURE } from '../../../i18n/shared-formats';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { AlbumDetailsUserContext } from '@knuddelsModules/Profile/bundle/components/AlbumDetails/AlbumDetailsUserContext';
import { $UserService } from '@knuddelsModules/UserData';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
import SvelteAlbumCarousel from './AlbumCarousel.svelte';
import { mount, unmount } from 'svelte';
import { EditDescriptionBar } from './EditDescriptionBar';
import { KeyboardAvoidanceView } from '@shared/components/KeyboardAvoidanceView';
export interface AlbumDetailsCarouselProps {
  initialPhotoId?: any;
  albumInfo: ProfileAlbumInfo;
  hideDescriptionBar?: boolean;
  enableKeyboardNavigation?: boolean;
  onSelectedAlbumPhotoChanged?(photoId: AlbumDetailPhotoFragment['id']): void;
}
const SvelteWrapper: React.FC<{
  albumPhotos: AlbumPhotoCarouselItemData[];
  showButtons: boolean;
  initialIndex: number;
  editing?: boolean;
  onIndexChange: (index: number) => void;
}> = React.memo(({
  editing,
  showButtons,
  albumPhotos,
  initialIndex,
  onIndexChange
}) => {
  const ref = React.useRef<HTMLElement>(null);
  React.useLayoutEffect(() => {
    const app = mount(SvelteAlbumCarousel, {
      target: ref.current,
      props: {
        albumPhotos,
        showButtons,
        initialIndex,
        onIndexChange
      }
    });
    return () => {
      unmount(app);
    };
  }, []);
  return <div style={{
    display: 'contents',
    pointerEvents: editing ? 'none' : 'all'
  }} ref={(ref as any)}></div>;
});
SvelteWrapper.displayName = 'SvelteWrapper';
export const AlbumDetailsCarousel: React.FC<AlbumDetailsCarouselProps> = props => {
  const {
    photos,
    selectedIndex,
    onSelectedIndexChanged
  } = useAlbumDetailsCarouselPhotos(props);
  const isStackedLayout = useIsStackedLayout();
  const isEditingDescription = React.useContext(IsEditingPhotoDescriptionContext);
  const carouselItems = React.useMemo(() => {
    return photos.length === 2 ? photos.concat(photos) : photos;
  }, [photos]);
  return <div style={{
    display: 'flex',
    width: '100%',
    height: '100%',
    // This is needed so the rounded corners of the mobile bottom drawer
    // overlay the carousel
    marginBottom: isStackedLayout ? '-16px' : 0,
    minHeight: 0
  }}>
			<div className={_c0}>
				<KeyboardAvoidanceView style={{
        width: '100%',
        height: '100%'
      }} forceOverlayHandling enabled={isEditingDescription}>
					<SvelteWrapper showButtons={!isStackedLayout} albumPhotos={carouselItems} initialIndex={selectedIndex % photos.length} onIndexChange={onSelectedIndexChanged} />
					{!isEditingDescription && <OpenAlbumDetailsContextMenu />}
					{!props.hideDescriptionBar && !isEditingDescription && carouselItems[selectedIndex] && <DescriptionBar item={carouselItems[selectedIndex]} />}
					{isEditingDescription && <EditDescriptionBar photoId={carouselItems[selectedIndex].id} initialRawText={carouselItems[selectedIndex].description?.rawText} />}
				</KeyboardAvoidanceView>
			</div>
		</div>;
};
const useAlbumDetailsCarouselPhotos = ({
  albumInfo,
  initialPhotoId,
  onSelectedAlbumPhotoChanged
}: AlbumDetailsCarouselProps) => {
  const closeAlbumDetails = useCloseAlbumDetails();
  const i18n = useService($I18n);
  const genericUserEventService = useService($GenericUserEventService);
  const photos = useMemo(() => {
    return albumInfo?.albums.reduce<AlbumPhotoCarouselItemData[]>((acc, currentAlbum) => [...acc, ...currentAlbum.albumPhotos.map((photo, index) => ({
      ...photo,
      albumTitle: currentAlbum.title,
      albumLength: currentAlbum.albumPhotos.length,
      photoIndex: index
    }))], albumInfo?.albumProfilePhoto ? [{
      ...albumInfo.albumProfilePhoto,
      albumTitle: i18n.format(PROFILE_PICTURE)
    }] : []);
  }, [albumInfo?.albums]);
  const [selectedIndex, setSelectedIndex] = useState(() => photos.findIndex(p => p.id === initialPhotoId));
  React.useEffect(() => {
    genericUserEventService.reportEvent({
      type: 'Viewed_AlbumPhoto',
      index: selectedIndex
    });
  }, []);
  const {
    notifyProfilePictureViewed,
    notifyAlbumPhotoViewed
  } = useNotifyPhotoViewed();
  useEffect(() => {
    if (photos.length === 0) {
      closeAlbumDetails();
      return;
    }
  }, [photos]);
  return {
    photos,
    selectedIndex,
    onSelectedIndexChanged: (newIndex: number) => {
      // update selected photo id to keep it in sync if photos are deleted.
      const selectedPhoto = photos[(newIndex + photos.length) % photos.length];
      if (selectedPhoto) {
        onSelectedAlbumPhotoChanged(selectedPhoto.id);
        const isProfilePicture = typeof selectedPhoto.albumLength === 'undefined';
        if (isProfilePicture) {
          notifyProfilePictureViewed();
        } else {
          notifyAlbumPhotoViewed();
        }
      }
      setSelectedIndex(newIndex);
    }
  };
};
const useNotifyPhotoViewed = () => {
  const authenticatedClientService = useService($AuthenticatedClientService);
  const viewedProfilePicture = useRef(false);
  const viewedAlbumPhoto = useRef(false);
  const {
    id
  } = useContext(AlbumDetailsUserContext);
  const userService = useService($UserService);
  return {
    notifyProfilePictureViewed: React.useCallback(() => {
      if (viewedProfilePicture.current || userService.isCurrentUser(id)) {
        return;
      }
      viewedProfilePicture.current = true;
      authenticatedClientService.currentK3Client.mutate(NotifyProfilePicturedViewed, {
        userId: id
      });
    }, [authenticatedClientService, viewedProfilePicture, id]),
    notifyAlbumPhotoViewed: React.useCallback(() => {
      if (viewedAlbumPhoto.current || userService.isCurrentUser(id)) {
        return;
      }
      viewedAlbumPhoto.current = true;
      authenticatedClientService.currentK3Client.mutate(NotifyAlbumPhotosViewed, {
        userId: id
      });
    }, [authenticatedClientService, viewedAlbumPhoto, id])
  };
};
const _c0 = " Knu-FlexCol flex-1 minWidth-0-px minHeight-0-px maxHeight-100-percent maxWidth-100-percent position-relative ";