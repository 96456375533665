import { jsxs as B, jsx as w } from "react/jsx-runtime";
import * as s from "react";
import R from "styled-components";
import { validateContrast as C } from "../validateContrast.js";
import { WhiteSolid as p } from "../../../themes/colors.js";
import "./Box.scss.js";
import { shouldForwardPropToNativeElement as D, compose as b, cursor as E, touchAction as M, pointerEvents as I, grid as L, gridSpan as $, gridGap as F, isAriaAttribute as A } from "./BoxMappings.js";
import { toPointerHandler as G } from "../CommonTypes/toPointerHandler.js";
import { CommonProps as k } from "./CommonProps.js";
import { useInGrid as K, DEFAULT_GRID_SIZE as N } from "../GridContext.js";
import T from "../../../node_modules/resize-observer-polyfill/dist/ResizeObserver.es.js";
import { AriaPropMap as U } from "../../../a11y/accessibilityProps.js";
import { PointerPropsMap as W } from "./PointerPropsMap.js";
import { clsx as j } from "../../../node_modules/clsx/dist/clsx.m.js";
import { resolveIsDarkColor as z } from "../isDarkColor.js";
import { useIsParentDark as H } from "./darkHandling.js";
import { useTheme as O } from "../../../themes/useTheme.js";
const P = (e) => {
  if (!e.gap)
    return;
  const o = e.flexDirection === "column" ? "margin-top" : "margin-left", r = e.theme.spacings[e.gap] ? e.theme.spacings[e.gap] + "px" : typeof e.gap == "number" ? e.gap + "px" : e.gap;
  return `
		& > *:not(:first-child) {
			${o}: ${r};
		}
	`;
};
P.handles = /* @__PURE__ */ new Map();
P.handles.set("gap", !0);
const d = (e) => {
  if (!e.shadow)
    return;
  if (!e.theme.colors.Shadow.onLight[e.shadow])
    return `
			box-shadow: ${e.shadow};
		`;
  const r = e.theme.colors.basic[e.parentBg] || e.parentBg || p.white;
  return `
		box-shadow: ${(C(
    e.theme.colors.Shadow.onLight[e.shadow].color,
    r
  ) ? e.theme.colors.Shadow.onLight : e.theme.colors.Shadow.onDark)[e.shadow].literal};
	`;
};
d.handles = /* @__PURE__ */ new Map();
d.handles.set("shadow", !0);
const u = (e) => e.focusBg && `
			&:focus-visible {
				outline: none;
				background: ${e.theme.colors.basic[e.focusBg] || e.focusBg};
			}
	
			&:focus {
				outline: none;
			}
		`;
u.handles = /* @__PURE__ */ new Map();
u.handles.set("focusBg", !0);
const l = (e) => e.animated ? `
			transition: ${typeof e.animated == "string" ? e.animated : "all 0.2s ease-in-out;"};
		` : void 0;
l.handles = /* @__PURE__ */ new Map();
l.handles.set("animated", !0);
const h = (e) => e.hideScrollIndicator && `
			/* Hide scrollbar for Chrome, Safari and Opera */
			&::-webkit-scrollbar {
				display: none;
				width: 0px;
			}
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
		`;
h.handles = /* @__PURE__ */ new Map();
h.handles.set("hideScrollIndicator", !0);
const X = b(
  ...[
    u,
    ...k,
    E,
    M,
    I,
    L,
    $,
    F,
    d,
    l,
    h
  ].filter(Boolean)
), Y = R.div.withConfig({
  shouldForwardProp: D
})`
	${X}
`, c = /* @__PURE__ */ new WeakMap(), g = new T((e) => {
  e.forEach((o) => {
    var n;
    const r = o.contentRect;
    (n = c.get(o.target)) == null || n({
      pageX: r.x,
      pageY: r.y,
      width: r.width,
      height: r.height
    });
  });
}), x = (e, o) => {
  const r = s.useRef(!1);
  s.useEffect(() => {
    c.set(o.current, e);
  }, [e]), s.useLayoutEffect(() => {
    var t;
    const n = (t = o.current) == null ? void 0 : t.getBoundingClientRect();
    n && (r.current = !0, e == null || e({
      pageX: n.x,
      pageY: n.y,
      width: n.width,
      height: n.height
    }));
  }, []), s.useEffect(() => {
    var n;
    if (g.observe(o.current), !r.current) {
      const t = (n = o.current) == null ? void 0 : n.getBoundingClientRect();
      e == null || e({
        pageX: t.x,
        pageY: t.y,
        width: t.width,
        height: t.height
      });
    }
    return () => {
      o.current && (g.unobserve(o.current), c.delete(o.current));
    };
  }, []);
}, _ = (e) => (x(e.onLayout, e.elementRef), null), Z = (e) => e.startsWith("on") && !e.includes("Key") && !e.includes("Scroll"), q = (e) => {
  const o = {}, r = {}, n = {};
  for (const t in e)
    if (A(t)) {
      const i = U[t];
      o[i] = e[t];
    } else if (Z(t)) {
      const i = W[t] ?? t;
      r[i] = G(
        e[t]
      );
    } else
      n[t] = e[t];
  return { pointerProps: r, ariaProps: o, unmappedProps: n };
}, J = (e) => {
  const o = s.useRef(null), r = K(), { innerRef: n, onLayout: t, className: i, ...S } = e, { pointerProps: v, ariaProps: y, unmappedProps: f } = q(S), a = e.handleDarkProps ? Q(f, o) : f;
  return /* @__PURE__ */ B(
    Y,
    {
      className: j(
        i,
        a != null && a.bg && (!i || !i.includes("content-is-")) ? z(a.bg, O()) ? "content-is-dark" : "content-is-light" : void 0
      ),
      ...y,
      ...v,
      ...a,
      ref: t || n || e.handleDarkProps ? (m) => {
        o.current = m, n && (n.current = m);
      } : null,
      colSpan: r ? e.colSpan ?? N : void 0,
      children: [
        t ? /* @__PURE__ */ w(_, { elementRef: o, onLayout: t }) : null,
        e.children
      ]
    }
  );
}, Q = (e, o) => !e.handleDarkProps || !H(o) ? e : Object.keys(e).reduce((n, t) => {
  if (t.startsWith("dark")) {
    const i = t.replace("dark", "");
    return {
      ...n,
      [i[0].toLowerCase() + i.substring(1)]: e[t]
    };
  } else if (!e["dark" + t[0].toUpperCase() + t.substring(1)])
    return {
      ...n,
      [t]: e[t]
    };
  return n;
}, {});
J.displayName = "Box";
const ge = s.forwardRef(
  ({ onLayout: e, children: o, ...r }, n) => {
    const t = s.useRef(null);
    return x(e, t), /* @__PURE__ */ w(
      "div",
      {
        ...r,
        ref: (i) => {
          t.current = i, typeof n == "function" ? n(i) : n && (n.current = i);
        },
        children: o
      }
    );
  }
);
export {
  J as Box,
  ge as FallbackBox,
  Z as isPointerEventHandler
};
