import { Z_INDEX } from '@shared/components';
import { BoxProps } from '@knuddels/component-library';
import { NAV_BAR_BOTTOM_HEIGHT } from '@shared/constants';

export const navBarWidthExpanded = 220;

export const styles = {
	/* NavBarLeft */
	NavBarExtensionLeft: {
		['zIndex' as any]: Z_INDEX.NAV,
		flex: 1,
		marginRight: 4,
	} as BoxProps,
	NavBarExtensionLeftCollapsed: {
		['zIndex' as any]: Z_INDEX.NAV,
		flex: 1,
		marginRight: 4,
	} as BoxProps,
	NavBarOverlay: {
		position: 'absolute',
		inset: 'none',
		flex: 1,
		alignSelf: 'stretch',
		bg: 'backgroundScrim',
		zIndex: Z_INDEX.NAV_OVERLAY,
	} as BoxProps,

	/* NavBarMinimalLeft */
	NavBarMinimalLeft: {
		flex: 1,
		flexDirection: 'column',
	} as BoxProps,

	/* NavBarMinimalBottom */
	NavBarMinimalBottom: {
		flexDirection: 'row',
		height: NAV_BAR_BOTTOM_HEIGHT,
	} as BoxProps,
};
