import * as React from 'react';
import { useMemo } from 'react';
import { NotificationBadge, Spacer } from '@shared/components';
import { calculateGradient, Flex, FlexCol, Image, parseRgbString, rgb, Ripple, Text, ThemeColors, ThemeOverride, useTheme, toPointerHandler, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { openInNewTab } from '@shared/helper/openUrl';
import { isNative } from '@knuddels-app/tools/isNative';
import { TextStates } from '@shared/components/atoms/Texts/TextProps';
import { NavBarConfiguration } from './configuration';
import { useBadge } from '@knuddelsModules/LoggedInArea/bundle/components/NavBar/useBadge';
import { NavBadge } from '@knuddelsModules/LoggedInArea/bundle/services';
export interface NavBarItemProps {
  active?: boolean;
  url?: string;
  collapsed?: boolean;
  onClick?: () => void;
  type: 'extendedLeftAligned' | 'minimalCentered';
  navLocation: NavBarConfiguration['navLocation'];
  icon: string | React.ComponentType<any>;
  title: string | JSX.Element;
  hideTitle?: boolean;
  testId?: string;
  badges: NavBadge[];
}
export const NavBarItem: React.FC<NavBarItemProps> = props => {
  const {
    active,
    collapsed,
    icon,
    onClick,
    navLocation,
    type: navType
  } = props;
  const extendedLeftAligned = navType === 'extendedLeftAligned';
  const alignment = extendedLeftAligned ? undefined : 'center';
  const flex = extendedLeftAligned ? undefined : 1;
  const [hovered, setHovered] = React.useState(false);
  const htmlRef = React.useRef<HTMLDivElement>(null);
  const activeBgColor = useActiveBgColor();
  const isDarkTheme = useTheme().id === 'dark';
  const smallButton = navLocation === 'bottom' && !extendedLeftAligned;
  return <div onClick={() => {
    onClick();
    if (props.url) {
      openInNewTab(props.url);
    }
  }} onMouseEnter={toPointerHandler(!isNative() ? () => setHovered(true) : null)} onMouseLeave={toPointerHandler(!isNative() ? () => setHovered(false) : null)} ref={htmlRef} style={{
    flex: resolveThemingValue(flex, "theme", useTheme()),
    alignItems: resolveThemingValue(alignment, "theme", useTheme()),
    justifyContent: resolveThemingValue(alignment, "theme", useTheme())
  }} className={_c0}>
			<div style={{
      background: resolveThemingValue(active && navLocation === 'left' ? activeBgColor : 'transparent', "colors", useTheme())
    }} className={_c1 + ((active && navLocation === 'left' ? activeBgColor : 'transparent') ? resolveIsDarkColor(active && navLocation === 'left' ? activeBgColor : 'transparent', useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				{extendedLeftAligned && <Spacer size={'tiny'} />}
				<div className={_c2 + ((isDarkTheme ? "transparentDark" : "transparentLight") ? resolveIsDarkColor(isDarkTheme ? "transparentDark" : "transparentLight", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (isDarkTheme ? _c3 : _c4)}>
					<div className={_c5}>
						<NavIcon icon={icon} active={active} hovered={hovered} />
						{collapsed && <Badge badges={props.badges} withWrapper />}
					</div>
					{!extendedLeftAligned && <Title {...props} hovered={hovered} />}
				</div>
				{extendedLeftAligned && <Spacer size={'minor'} />}
				{extendedLeftAligned && !collapsed && <div className={_c6 + ((isDarkTheme ? "transparentDark" : "transparentLight") ? resolveIsDarkColor(isDarkTheme ? "transparentDark" : "transparentLight", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (isDarkTheme ? _c7 : _c8)}>
						<Title {...props} hovered={hovered} />
						{props.badges.length > 0 && <Badge badges={props.badges} withWrapper={false} />}
					</div>}
				{extendedLeftAligned && !collapsed && <Spacer size={'base'} />}
			</div>
			<div style={{
      marginTop: resolveThemingValue(smallButton ? (-16 as ThemeOverride) : 0, "spacing", useTheme()),
      marginLeft: resolveThemingValue(navLocation === 'left' && !smallButton ? 0 : navLocation === 'bottom' ? 'none' : (-8 as ThemeOverride), "spacing", useTheme())
    }} className={_c9 + (smallButton ? _c10 : _c11) + (smallButton ? _c12 : _c13)}>
				<Ripple containerRef={htmlRef} pointerCallbackType={'onClick'} />
			</div>
		</div>;
};
export const NavIcon: React.FC<{
  active?: boolean;
  icon: string | React.ComponentType<any>;
  hovered?: boolean;
}> = ({
  icon,
  active,
  hovered
}) => {
  const isDarkTheme = useTheme().id !== 'light';
  if (typeof icon === 'string') {
    return <IconSvg iconUrl={icon} active={active} hovered={hovered} />;
  }
  let color: ThemeColors;
  if (isDarkTheme) {
    color = active ? 'white-solid-white' : hovered ? rgb(177, 177, 177) : rgb(101, 101, 101);
  } else {
    color = active ? 'black-solid-black' : hovered ? rgb(78, 78, 78) : rgb(153, 153, 153);
  }
  return React.createElement(icon, {
    size: 'large',
    active: active ?? false,
    color,
    hovered
  });
};
const Title: React.FC<{
  active?: boolean;
  type: 'extendedLeftAligned' | 'minimalCentered';
  title: string | JSX.Element;
  hideTitle?: boolean;
  hovered: boolean;
}> = ({
  active,
  title,
  type,
  hideTitle,
  hovered
}) => {
  const extended = type === 'extendedLeftAligned';
  if (hideTitle) {
    return null; // TODO do we need this? This method is never called if hideTitle is true.
  }

  return <Text type={extended ? 'body1' : 'tiny'} state={((active ? 'primary' : hovered ? 'secondary' : 'tertiary') as TextStates)} bold={true} numberOfLines={1} className={_c14}>
			{title}
		</Text>;
};
const Badge: React.FC<{
  badges: NavBadge[];
  withWrapper: boolean;
}> = ({
  badges,
  withWrapper
}) => {
  const badge = useBadge(badges);
  if (!badge.text) {
    return null;
  }
  return withWrapper ? <div style={{
    right: resolveThemingValue((-4 as ThemeOverride), "spacing", useTheme()),
    top: resolveThemingValue((-4 as ThemeOverride), "spacing", useTheme())
  }} className={_c15}>
			<NotificationBadge number={badge.text} rawColor={badge.color} />
		</div> : <NotificationBadge number={badge.text} rawColor={badge.color} />;
};
const ICON_SIZE = 32;
const IconSvg: React.FC<{
  iconUrl: string;
  active: boolean;
  hovered: boolean;
}> = ({
  iconUrl,
  active,
  hovered
}) => {
  return <Image src={iconUrl} alt={''} size={ICON_SIZE} {...{
    style: {
      mixBlendMode: active || hovered ? undefined : 'luminosity'
    }
  }} />;
};
const useActiveBgColor = () => {
  const theme = useTheme();
  return (useMemo(() => {
    const isDarkTheme = theme.id !== 'light';
    const rgb = parseRgbString(theme.colors.basic.accent);
    return isDarkTheme ? calculateGradient(rgb, 0, 0.22) : calculateGradient(rgb, 0, 0.11);
  }, [theme]) as ThemeColors);
};
const _c0 = " Knu-FlexCol minHeight-40px position-relative cursor-pointer ";
const _c1 = " Knu-Flex position-relative alignItems-center height-full borderRadius-minor ";
const _c2 = " Knu-FlexCol alignItems-center ";
const _c3 = " bg-transparentDark ";
const _c4 = " bg-transparentLight ";
const _c5 = " Knu-FlexCol overflow-visible position-relative ";
const _c6 = " Knu-Flex flex-1 justifyContent-space-between alignItems-center ";
const _c7 = " bg-transparentDark ";
const _c8 = " bg-transparentLight ";
const _c9 = " Knu-Flex position-absolute overflow-hidden borderRadius-100px ";
const _c10 = " height-42px ";
const _c11 = " height-full ";
const _c12 = " width-42px ";
const _c13 = " width-full ";
const _c14 = "  ";
const _c15 = " Knu-FlexCol position-absolute ";