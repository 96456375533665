import { UAParser as i } from "../../deps/ua-parser-js/src/main/ua-parser.js";
import { isTouchDevice as a } from "../Layout/helper/isTouchDevice.js";
const o = i(window.navigator.userAgent);
var e;
const w = ((e = o.os.name) == null ? void 0 : e.toLowerCase()) === "ios" && a();
var r, s;
const m = ((r = o.os.name) == null ? void 0 : r.toLowerCase()) === "ios" && ((s = o.browser.name) == null ? void 0 : s.toLowerCase()) === "webkit";
export {
  o as BrowserInfo,
  w as isMobileSafari,
  m as isSafariWebView
};
