import * as React from 'react';
import { SpacerSizes } from '../../atoms/Spacers/SpacerProps';
import { Spacer } from '../../atoms/Spacers';
import { FlexCol, Image, PaddingProps, Smiley, Text, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { Button } from '../../atoms/Buttons';
interface Props {
  image: any;
  imageWidth: number;
  imageHeight: number;
  backgroundImage?: any;
  setBgTop?: boolean;
  imageScale?: number;
  imageSpacing?: SpacerSizes;
  fullHeight?: boolean;
  title: string | JSX.Element;
  description?: string | JSX.Element;
  button?: {
    text: string;
    onClick(): void;
  };
  py?: PaddingProps['py'];
}
export function PlaceholderContent({
  title,
  description,
  button,
  image,
  backgroundImage,
  imageWidth,
  imageHeight,
  imageSpacing,
  fullHeight = true,
  py = 'xxlarge'
}: Props): React.ReactElement {
  return <div style={{
    flexGrow: resolveThemingValue(fullHeight ? 1 : undefined, "theme", useTheme()),
    paddingTop: resolveThemingValue(py, "spacing", useTheme()),
    paddingBottom: resolveThemingValue(py, "spacing", useTheme())
  }} className={_c0}>
			<div style={{
      flexGrow: resolveThemingValue(fullHeight ? 9 : undefined, "theme", useTheme())
    }} className={_c1}>
				<div className={_c2}>
					{!!backgroundImage && <div className={_c3}>
							<Image src={backgroundImage} resizeMode={'contain'} alt={''} style={{
            width: resolveThemingValue(imageWidth, "sizes", useTheme()),
            height: resolveThemingValue(imageHeight, "sizes", useTheme())
          }} className={_c4} />
						</div>}
					<Smiley src={image} width={imageWidth} height={imageHeight} />
				</div>
				<Spacer size={imageSpacing || 'base'} />
				<Text type={'subtitle'} bold={true} className={_c5}>
					{title}
				</Text>
				{!!description && <>
						<Spacer size={'small'} />
						<Text type={'body2'} state={'tertiary'} className={_c6}>
							{description}
						</Text>
					</>}
				{button && <>
						<Spacer size={'large'} />
						<Button text={button.text} onClick={button.onClick} />
					</>}
			</div>
			<div className={_c7} />
		</div>;
}
const _c0 = " Knu-FlexCol px-xxlarge alignSelf-center maxWidth-360px ";
const _c1 = " Knu-FlexCol placeItems-center position-relative ";
const _c2 = " Knu-FlexCol position-relative justifyContent-center ";
const _c3 = " Knu-FlexCol position-absolute inset-none ";
const _c4 = "  ";
const _c5 = " textAlign-center ";
const _c6 = " textAlign-center ";
const _c7 = " Knu-FlexCol flexGrow-1 ";