interface FlingOptions {
	onFling: (details: FlingDetails) => void;
	shouldTrigger?: (
		details: FlingDetails,
		targetElement: HTMLElement
	) => boolean;
	shouldStart?: (targetElement: HTMLElement) => boolean;
}

interface FlingDetails {
	direction: 'up' | 'down' | 'left' | 'right';
	distance: number;
	velocity: number;
	angle: number;
	deltaX: number;
	deltaY: number;
	duration: number;
}

const MIN_VELOCITY = 0.75;
const MIN_DISTANCE = 50;
const MAX_DURATION = 250;

export class FlingGestureHandler {
	private readonly element: HTMLElement;

	private startX: number = 0;
	private startY: number = 0;
	private startTime: number = 0;
	private isTracking: boolean = false;

	private currentTargetElement: HTMLElement | null = null;

	constructor(
		element: HTMLElement,
		private options: FlingOptions
	) {
		this.element = element;

		// Bind methods to maintain correct 'this' context
		this.handleTouchStart = this.handleTouchStart.bind(this);
		this.handleTouchEnd = this.handleTouchEnd.bind(this);

		// Add event listeners
		this.attach();
	}

	public attach(): void {
		this.element.addEventListener('touchstart', this.handleTouchStart, {
			passive: true,
		});
		this.element.addEventListener('touchend', this.handleTouchEnd, {
			passive: true,
		});
	}

	public detach(): void {
		this.element.removeEventListener('touchstart', this.handleTouchStart);
		this.element.removeEventListener('touchend', this.handleTouchEnd);
	}

	private handleTouchStart(event: TouchEvent): void {
		const shouldStart =
			this.options.shouldStart?.(event.target as HTMLElement) ?? true;
		if (event.touches.length === 1 && shouldStart) {
			this.isTracking = true;
			this.startX = event.touches[0].clientX;
			this.startY = event.touches[0].clientY;
			this.startTime = Date.now();
			this.currentTargetElement = event.target as HTMLElement;
		}
	}

	private handleTouchEnd(event: TouchEvent): void {
		if (!this.isTracking) return;
		this.isTracking = false;

		const endX = event.changedTouches[0].clientX;
		const endY = event.changedTouches[0].clientY;
		const endTime = Date.now();

		const deltaX = endX - this.startX;
		const deltaY = endY - this.startY;
		const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
		const duration = endTime - this.startTime;
		const velocity = distance / duration;

		// Check if the gesture qualifies as a fling
		if (
			duration <= MAX_DURATION &&
			distance >= MIN_DISTANCE &&
			velocity >= MIN_VELOCITY
		) {
			// Calculate angle in degrees
			const angle = (Math.atan2(deltaY, deltaX) * 180) / Math.PI;

			// Determine direction
			const direction = this.getDirection(angle);

			const details = {
				direction,
				distance,
				velocity,
				angle,
				deltaX,
				deltaY,
				duration,
			} satisfies FlingDetails;

			if (
				(this.currentTargetElement &&
					this.options.shouldTrigger?.(
						details,
						this.currentTargetElement
					)) ??
				true
			) {
				this.options.onFling(details);
			}
		}
	}

	private getDirection(angle: number): FlingDetails['direction'] {
		if (angle > -45 && angle <= 45) return 'right';
		if (angle > 45 && angle <= 135) return 'down';
		if (angle > 135 || angle <= -135) return 'left';
		return 'up';
	}
}
