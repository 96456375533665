var $i = "2.0.0", q = "", Si = "?", si = "function", H = "undefined", z = "object", vi = "string", ii = "major", r = "model", e = "name", i = "type", a = "vendor", t = "version", g = "architecture", N = "console", l = "mobile", h = "tablet", m = "smarttv", J = "wearable", ci = "xr", ki = "embedded", X = "inapp", Ci = "user-agent", xi = 500, Ai = "brands", P = "formFactors", _i = "fullVersionList", F = "platform", Oi = "platformVersion", bi = "bitness", M = "sec-ch-ua", Ki = M + "-full-version-list", Zi = M + "-arch", Ji = M + "-" + bi, Qi = M + "-form-factors", ie = M + "-" + l, ee = M + "-" + r, Gi = M + "-" + F, te = Gi + "-version", Xi = [Ai, _i, l, r, F, Oi, g, P, bi], k = "browser", y = "cpu", E = "device", A = "engine", x = "os", B = "result", ri = "Amazon", W = "Apple", Ti = "ASUS", Hi = "BlackBerry", U = "Google", Ii = "Huawei", Mi = "Lenovo", re = "Honor", fi = "LG", ni = "Microsoft", Ui = "Motorola", j = "Samsung", Pi = "Sharp", oi = "Sony", hi = "Xiaomi", pi = "Zebra", D = "Mobile ", Y = " Browser", Li = "Chrome", C = "Chromecast", oe = "Edge", $ = "Firefox", K = "Opera", Di = "Facebook", Ni = "Sogou", Ei = "Windows", ae = typeof window !== H, v = ae && window.navigator ? window.navigator : void 0, T = v && v.userAgentData ? v.userAgentData : void 0, ne = function(o, n) {
  var s = {}, u = n;
  if (!wi(n)) {
    u = {};
    for (var b in n)
      for (var f in n[b])
        u[f] = n[b][f].concat(u[f] ? u[f] : []);
  }
  for (var w in o)
    s[w] = u[w] && u[w].length % 2 === 0 ? u[w].concat(o[w]) : o[w];
  return s;
}, li = function(o) {
  for (var n = {}, s = 0; s < o.length; s++)
    n[o[s].toUpperCase()] = o[s];
  return n;
}, yi = function(o, n) {
  if (typeof o === z && o.length > 0) {
    for (var s in o)
      if (_(o[s]) == _(n))
        return !0;
    return !1;
  }
  return V(o) ? _(n).indexOf(_(o)) !== -1 : !1;
}, wi = function(o, n) {
  for (var s in o)
    return /^(browser|cpu|device|engine|os)$/.test(s) || (n ? wi(o[s]) : !1);
}, V = function(o) {
  return typeof o === vi;
}, mi = function(o) {
  if (o) {
    for (var n = [], s = L(/\\?\"/g, o).split(","), u = 0; u < s.length; u++)
      if (s[u].indexOf(";") > -1) {
        var b = ei(s[u]).split(";v=");
        n[u] = { brand: b[0], version: b[1] };
      } else
        n[u] = ei(s[u]);
    return n;
  }
}, _ = function(o) {
  return V(o) ? o.toLowerCase() : o;
}, gi = function(o) {
  return V(o) ? L(/[^\d\.]/g, o).split(".")[0] : void 0;
}, O = function(o) {
  for (var n in o) {
    var s = o[n];
    typeof s == z && s.length == 2 ? this[s[0]] = s[1] : this[s] = void 0;
  }
  return this;
}, L = function(o, n) {
  return V(n) ? n.replace(o, q) : n;
}, Z = function(o) {
  return L(/\\?\"/g, o);
}, ei = function(o, n) {
  if (V(o))
    return o = L(/^\s\s*/, o), typeof n === H ? o : o.substring(0, xi);
}, Fi = function(o, n) {
  if (!(!o || !n))
    for (var s = 0, u, b, f, w, c, d; s < n.length && !c; ) {
      var p = n[s], R = n[s + 1];
      for (u = b = 0; u < p.length && !c && p[u]; )
        if (c = p[u++].exec(o), c)
          for (f = 0; f < R.length; f++)
            d = c[++b], w = R[f], typeof w === z && w.length > 0 ? w.length === 2 ? typeof w[1] == si ? this[w[0]] = w[1].call(this, d) : this[w[0]] = w[1] : w.length === 3 ? typeof w[1] === si && !(w[1].exec && w[1].test) ? this[w[0]] = d ? w[1].call(this, d, w[2]) : void 0 : this[w[0]] = d ? d.replace(w[1], w[2]) : void 0 : w.length === 4 && (this[w[0]] = d ? w[3].call(this, d.replace(w[1], w[2])) : void 0) : this[w] = d || void 0;
      s += 2;
    }
}, Q = function(o, n) {
  for (var s in n)
    if (typeof n[s] === z && n[s].length > 0) {
      for (var u = 0; u < n[s].length; u++)
        if (yi(n[s][u], o))
          return s === Si ? void 0 : s;
    } else if (yi(n[s], o))
      return s === Si ? void 0 : s;
  return n.hasOwnProperty("*") ? n["*"] : o;
}, Bi = {
  ME: "4.90",
  "NT 3.11": "NT3.51",
  "NT 4.0": "NT4.0",
  2e3: "NT 5.0",
  XP: ["NT 5.1", "NT 5.2"],
  Vista: "NT 6.0",
  7: "NT 6.1",
  8: "NT 6.2",
  "8.1": "NT 6.3",
  10: ["NT 6.4", "NT 10.0"],
  RT: "ARM"
}, qi = {
  embedded: "Automotive",
  mobile: "Mobile",
  tablet: ["Tablet", "EInk"],
  smarttv: "TV",
  wearable: "Watch",
  xr: ["VR", "XR"],
  "?": ["Desktop", "Unknown"],
  "*": void 0
}, zi = {
  browser: [
    [
      // Most common regardless engine
      /\b(?:crmo|crios)\/([\w\.]+)/i
      // Chrome for Android/iOS
    ],
    [t, [e, D + "Chrome"]],
    [
      /edg(?:e|ios|a)?\/([\w\.]+)/i
      // Microsoft Edge
    ],
    [t, [e, "Edge"]],
    [
      // Presto based
      /(opera mini)\/([-\w\.]+)/i,
      // Opera Mini
      /(opera [mobiletab]{3,6})\b.+version\/([-\w\.]+)/i,
      // Opera Mobi/Tablet
      /(opera)(?:.+version\/|[\/ ]+)([\w\.]+)/i
      // Opera
    ],
    [e, t],
    [
      /opios[\/ ]+([\w\.]+)/i
      // Opera mini on iphone >= 8.0
    ],
    [t, [e, K + " Mini"]],
    [
      /\bop(?:rg)?x\/([\w\.]+)/i
      // Opera GX
    ],
    [t, [e, K + " GX"]],
    [
      /\bopr\/([\w\.]+)/i
      // Opera Webkit
    ],
    [t, [e, K]],
    [
      // Mixed
      /\bb[ai]*d(?:uhd|[ub]*[aekoprswx]{5,6})[\/ ]?([\w\.]+)/i
      // Baidu
    ],
    [t, [e, "Baidu"]],
    [
      /\b(?:mxbrowser|mxios|myie2)\/?([-\w\.]*)\b/i
      // Maxthon
    ],
    [t, [e, "Maxthon"]],
    [
      /(kindle)\/([\w\.]+)/i,
      // Kindle
      /(lunascape|maxthon|netfront|jasmine|blazer|sleipnir)[\/ ]?([\w\.]*)/i,
      // Lunascape/Maxthon/Netfront/Jasmine/Blazer/Sleipnir
      // Trident based
      /(avant|iemobile|slim(?:browser|boat|jet))[\/ ]?([\d\.]*)/i,
      // Avant/IEMobile/SlimBrowser/SlimBoat/Slimjet
      /(?:ms|\()(ie) ([\w\.]+)/i,
      // Internet Explorer
      // Blink/Webkit/KHTML based                                         // Flock/RockMelt/Midori/Epiphany/Silk/Skyfire/Bolt/Iron/Iridium/PhantomJS/Bowser/QupZilla/Falkon
      /(flock|rockmelt|midori|epiphany|silk|skyfire|ovibrowser|bolt|iron|vivaldi|iridium|phantomjs|bowser|qupzilla|falkon|rekonq|puffin|brave|whale(?!.+naver)|qqbrowserlite|duckduckgo|klar|helio|(?=comodo_)?dragon)\/([-\w\.]+)/i,
      // Rekonq/Puffin/Brave/Whale/QQBrowserLite/QQ//Vivaldi/DuckDuckGo/Klar/Helio/Dragon
      /(heytap|ovi|115)browser\/([\d\.]+)/i,
      // HeyTap/Ovi/115
      /(weibo)__([\d\.]+)/i
      // Weibo
    ],
    [e, t],
    [
      /quark(?:pc)?\/([-\w\.]+)/i
      // Quark
    ],
    [t, [e, "Quark"]],
    [
      /\bddg\/([\w\.]+)/i
      // DuckDuckGo
    ],
    [t, [e, "DuckDuckGo"]],
    [
      /(?:\buc? ?browser|(?:juc.+)ucweb)[\/ ]?([\w\.]+)/i
      // UCBrowser
    ],
    [t, [e, "UCBrowser"]],
    [
      /microm.+\bqbcore\/([\w\.]+)/i,
      // WeChat Desktop for Windows Built-in Browser
      /\bqbcore\/([\w\.]+).+microm/i,
      /micromessenger\/([\w\.]+)/i
      // WeChat
    ],
    [t, [e, "WeChat"]],
    [
      /konqueror\/([\w\.]+)/i
      // Konqueror
    ],
    [t, [e, "Konqueror"]],
    [
      /trident.+rv[: ]([\w\.]{1,9})\b.+like gecko/i
      // IE11
    ],
    [t, [e, "IE"]],
    [
      /ya(?:search)?browser\/([\w\.]+)/i
      // Yandex
    ],
    [t, [e, "Yandex"]],
    [
      /slbrowser\/([\w\.]+)/i
      // Smart Lenovo Browser
    ],
    [t, [e, "Smart " + Mi + Y]],
    [
      /(avast|avg)\/([\w\.]+)/i
      // Avast/AVG Secure Browser
    ],
    [[e, /(.+)/, "$1 Secure" + Y], t],
    [
      /\bfocus\/([\w\.]+)/i
      // Firefox Focus
    ],
    [t, [e, $ + " Focus"]],
    [
      /\bopt\/([\w\.]+)/i
      // Opera Touch
    ],
    [t, [e, K + " Touch"]],
    [
      /coc_coc\w+\/([\w\.]+)/i
      // Coc Coc Browser
    ],
    [t, [e, "Coc Coc"]],
    [
      /dolfin\/([\w\.]+)/i
      // Dolphin
    ],
    [t, [e, "Dolphin"]],
    [
      /coast\/([\w\.]+)/i
      // Opera Coast
    ],
    [t, [e, K + " Coast"]],
    [
      /miuibrowser\/([\w\.]+)/i
      // MIUI Browser
    ],
    [t, [e, "MIUI" + Y]],
    [
      /fxios\/([\w\.-]+)/i
      // Firefox for iOS
    ],
    [t, [e, D + $]],
    [
      /\bqihoobrowser\/?([\w\.]*)/i
      // 360
    ],
    [t, [e, "360"]],
    [
      /\b(qq)\/([\w\.]+)/i
      // QQ
    ],
    [[e, /(.+)/, "$1Browser"], t],
    [
      /(oculus|sailfish|huawei|vivo|pico)browser\/([\w\.]+)/i
    ],
    [[e, /(.+)/, "$1" + Y], t],
    [
      // Oculus/Sailfish/HuaweiBrowser/VivoBrowser/PicoBrowser
      /samsungbrowser\/([\w\.]+)/i
      // Samsung Internet
    ],
    [t, [e, j + " Internet"]],
    [
      /metasr[\/ ]?([\d\.]+)/i
      // Sogou Explorer
    ],
    [t, [e, Ni + " Explorer"]],
    [
      /(sogou)mo\w+\/([\d\.]+)/i
      // Sogou Mobile
    ],
    [[e, Ni + " Mobile"], t],
    [
      /(electron)\/([\w\.]+) safari/i,
      // Electron-based App
      /(tesla)(?: qtcarbrowser|\/(20\d\d\.[-\w\.]+))/i,
      // Tesla
      /m?(qqbrowser|2345(?=browser|chrome|explorer))\w*[\/ ]?v?([\w\.]+)/i
      // QQ/2345
    ],
    [e, t],
    [
      /(lbbrowser|rekonq)/i
      // LieBao Browser/Rekonq
    ],
    [e],
    [
      /ome\/([\w\.]+) \w* ?(iron) saf/i,
      // Iron
      /ome\/([\w\.]+).+qihu (360)[es]e/i
      // 360
    ],
    [t, e],
    [
      // WebView
      /((?:fban\/fbios|fb_iab\/fb4a)(?!.+fbav)|;fbav\/([\w\.]+);)/i
      // Facebook App for iOS & Android
    ],
    [[e, Di], t, [i, X]],
    [
      /(Klarna)\/([\w\.]+)/i,
      // Klarna Shopping Browser for iOS & Android
      /(kakao(?:talk|story))[\/ ]([\w\.]+)/i,
      // Kakao App
      /(naver)\(.*?(\d+\.[\w\.]+).*\)/i,
      // Naver InApp
      /safari (line)\/([\w\.]+)/i,
      // Line App for iOS
      /\b(line)\/([\w\.]+)\/iab/i,
      // Line App for Android
      /(alipay)client\/([\w\.]+)/i,
      // Alipay
      /(twitter)(?:and| f.+e\/([\w\.]+))/i,
      // Twitter
      /(instagram|snapchat)[\/ ]([-\w\.]+)/i
      // Instagram/Snapchat
    ],
    [e, t, [i, X]],
    [
      /\bgsa\/([\w\.]+) .*safari\//i
      // Google Search Appliance on iOS
    ],
    [t, [e, "GSA"], [i, X]],
    [
      /musical_ly(?:.+app_?version\/|_)([\w\.]+)/i
      // TikTok
    ],
    [t, [e, "TikTok"], [i, X]],
    [
      /\[(linkedin)app\]/i
      // LinkedIn App for iOS & Android
    ],
    [e, [i, X]],
    [
      /(chromium)[\/ ]([-\w\.]+)/i
      // Chromium
    ],
    [e, t],
    [
      /headlesschrome(?:\/([\w\.]+)| )/i
      // Chrome Headless
    ],
    [t, [e, Li + " Headless"]],
    [
      / wv\).+(chrome)\/([\w\.]+)/i
      // Chrome WebView
    ],
    [[e, Li + " WebView"], t],
    [
      /droid.+ version\/([\w\.]+)\b.+(?:mobile safari|safari)/i
      // Android Browser
    ],
    [t, [e, "Android" + Y]],
    [
      /chrome\/([\w\.]+) mobile/i
      // Chrome Mobile
    ],
    [t, [e, D + "Chrome"]],
    [
      /(chrome|omniweb|arora|[tizenoka]{5} ?browser)\/v?([\w\.]+)/i
      // Chrome/OmniWeb/Arora/Tizen/Nokia
    ],
    [e, t],
    [
      /version\/([\w\.\,]+) .*mobile(?:\/\w+ | ?)safari/i
      // Safari Mobile
    ],
    [t, [e, D + "Safari"]],
    [
      /iphone .*mobile(?:\/\w+ | ?)safari/i
    ],
    [[e, D + "Safari"]],
    [
      /version\/([\w\.\,]+) .*(safari)/i
      // Safari
    ],
    [t, e],
    [
      /webkit.+?(mobile ?safari|safari)(\/[\w\.]+)/i
      // Safari < 3.0
    ],
    [e, [t, "1"]],
    [
      /(webkit|khtml)\/([\w\.]+)/i
    ],
    [e, t],
    [
      // Gecko based
      /(?:mobile|tablet);.*(firefox)\/([\w\.-]+)/i
      // Firefox Mobile
    ],
    [[e, D + $], t],
    [
      /(navigator|netscape\d?)\/([-\w\.]+)/i
      // Netscape
    ],
    [[e, "Netscape"], t],
    [
      /(wolvic|librewolf)\/([\w\.]+)/i
      // Wolvic/LibreWolf
    ],
    [e, t],
    [
      /mobile vr; rv:([\w\.]+)\).+firefox/i
      // Firefox Reality
    ],
    [t, [e, $ + " Reality"]],
    [
      /ekiohf.+(flow)\/([\w\.]+)/i,
      // Flow
      /(swiftfox)/i,
      // Swiftfox
      /(icedragon|iceweasel|camino|chimera|fennec|maemo browser|minimo|conkeror)[\/ ]?([\w\.\+]+)/i,
      // IceDragon/Iceweasel/Camino/Chimera/Fennec/Maemo/Minimo/Conkeror
      /(seamonkey|k-meleon|icecat|iceape|firebird|phoenix|palemoon|basilisk|waterfox)\/([-\w\.]+)$/i,
      // Firefox/SeaMonkey/K-Meleon/IceCat/IceApe/Firebird/Phoenix
      /(firefox)\/([\w\.]+)/i,
      // Other Firefox-based
      /(mozilla)\/([\w\.]+) .+rv\:.+gecko\/\d+/i,
      // Mozilla
      // Other
      /(polaris|lynx|dillo|icab|doris|amaya|w3m|netsurf|obigo|mosaic|(?:go|ice|up)[\. ]?browser)[-\/ ]?v?([\w\.]+)/i,
      // Polaris/Lynx/Dillo/iCab/Doris/Amaya/w3m/NetSurf/Obigo/Mosaic/Go/ICE/UP.Browser
      /\b(links) \(([\w\.]+)/i
      // Links
    ],
    [e, [t, /_/g, "."]],
    [
      /(cobalt)\/([\w\.]+)/i
      // Cobalt
    ],
    [e, [t, /[^\d\.]+./, q]]
  ],
  cpu: [
    [
      /\b(?:(amd|x|x86[-_]?|wow|win)64)\b/i
      // AMD64 (x64)
    ],
    [[g, "amd64"]],
    [
      /(ia32(?=;))/i,
      // IA32 (quicktime)
      /((?:i[346]|x)86)[;\)]/i
      // IA32 (x86)
    ],
    [[g, "ia32"]],
    [
      /\b(aarch64|arm(v?8e?l?|_?64))\b/i
      // ARM64
    ],
    [[g, "arm64"]],
    [
      /\b(arm(?:v[67])?ht?n?[fl]p?)\b/i
      // ARMHF
    ],
    [[g, "armhf"]],
    [
      // PocketPC mistakenly identified as PowerPC
      /windows (ce|mobile); ppc;/i
    ],
    [[g, "arm"]],
    [
      /((?:ppc|powerpc)(?:64)?)(?: mac|;|\))/i
      // PowerPC
    ],
    [[g, /ower/, q, _]],
    [
      /(sun4\w)[;\)]/i
      // SPARC
    ],
    [[g, "sparc"]],
    [
      /((?:avr32|ia64(?=;))|68k(?=\))|\barm(?=v(?:[1-7]|[5-7]1)l?|;|eabi)|(?=atmel )avr|(?:irix|mips|sparc)(?:64)?\b|pa-risc)/i
      // IA64, 68K, ARM/64, AVR/32, IRIX/64, MIPS/64, SPARC/64, PA-RISC
    ],
    [[g, _]]
  ],
  device: [
    [
      //////////////////////////
      // MOBILES & TABLETS
      /////////////////////////
      // Samsung
      /\b(sch-i[89]0\d|shw-m380s|sm-[ptx]\w{2,4}|gt-[pn]\d{2,4}|sgh-t8[56]9|nexus 10)/i
    ],
    [r, [a, j], [i, h]],
    [
      /\b((?:s[cgp]h|gt|sm)-(?![lr])\w+|sc[g-]?[\d]+a?|galaxy nexus)/i,
      /samsung[- ]((?!sm-[lr])[-\w]+)/i,
      /sec-(sgh\w+)/i
    ],
    [r, [a, j], [i, l]],
    [
      // Apple
      /(?:\/|\()(ip(?:hone|od)[\w, ]*)(?:\/|;)/i
      // iPod/iPhone
    ],
    [r, [a, W], [i, l]],
    [
      /\((ipad);[-\w\),; ]+apple/i,
      // iPad
      /applecoremedia\/[\w\.]+ \((ipad)/i,
      /\b(ipad)\d\d?,\d\d?[;\]].+ios/i
    ],
    [r, [a, W], [i, h]],
    [
      /(macintosh);/i
    ],
    [r, [a, W]],
    [
      // Sharp
      /\b(sh-?[altvz]?\d\d[a-ekm]?)/i
    ],
    [r, [a, Pi], [i, l]],
    [
      // Honor
      /(?:honor)([-\w ]+)[;\)]/i
    ],
    [r, [a, re], [i, l]],
    [
      // Huawei
      /\b((?:ag[rs][23]?|bah2?|sht?|btv)-a?[lw]\d{2})\b(?!.+d\/s)/i
    ],
    [r, [a, Ii], [i, h]],
    [
      /(?:huawei)([-\w ]+)[;\)]/i,
      /\b(nexus 6p|\w{2,4}e?-[atu]?[ln][\dx][012359c][adn]?)\b(?!.+d\/s)/i
    ],
    [r, [a, Ii], [i, l]],
    [
      // Xiaomi
      /\b(poco[\w ]+|m2\d{3}j\d\d[a-z]{2})(?: bui|\))/i,
      // Xiaomi POCO
      /\b; (\w+) build\/hm\1/i,
      // Xiaomi Hongmi 'numeric' models
      /\b(hm[-_ ]?note?[_ ]?(?:\d\w)?) bui/i,
      // Xiaomi Hongmi
      /\b(redmi[\-_ ]?(?:note|k)?[\w_ ]+)(?: bui|\))/i,
      // Xiaomi Redmi
      /oid[^\)]+; (m?[12][0-389][01]\w{3,6}[c-y])( bui|; wv|\))/i,
      // Xiaomi Redmi 'numeric' models
      /\b(mi[-_ ]?(?:a\d|one|one[_ ]plus|note lte|max|cc)?[_ ]?(?:\d?\w?)[_ ]?(?:plus|se|lite|pro)?)(?: bui|\))/i
      // Xiaomi Mi
    ],
    [[r, /_/g, " "], [a, hi], [i, l]],
    [
      /oid[^\)]+; (2\d{4}(283|rpbf)[cgl])( bui|\))/i,
      // Redmi Pad
      /\b(mi[-_ ]?(?:pad)(?:[\w_ ]+))(?: bui|\))/i
      // Mi Pad tablets
    ],
    [[r, /_/g, " "], [a, hi], [i, h]],
    [
      // OPPO
      /; (\w+) bui.+ oppo/i,
      /\b(cph[12]\d{3}|p(?:af|c[al]|d\w|e[ar])[mt]\d0|x9007|a101op)\b/i
    ],
    [r, [a, "OPPO"], [i, l]],
    [
      /\b(opd2\d{3}a?) bui/i
    ],
    [r, [a, "OPPO"], [i, h]],
    [
      // Vivo
      /vivo (\w+)(?: bui|\))/i,
      /\b(v[12]\d{3}\w?[at])(?: bui|;)/i
    ],
    [r, [a, "Vivo"], [i, l]],
    [
      // Realme
      /\b(rmx[1-3]\d{3})(?: bui|;|\))/i
    ],
    [r, [a, "Realme"], [i, l]],
    [
      // Motorola
      /\b(milestone|droid(?:[2-4x]| (?:bionic|x2|pro|razr))?:?( 4g)?)\b[\w ]+build\//i,
      /\bmot(?:orola)?[- ](\w*)/i,
      /((?:moto[\w\(\) ]+|xt\d{3,4}|nexus 6)(?= bui|\)))/i
    ],
    [r, [a, Ui], [i, l]],
    [
      /\b(mz60\d|xoom[2 ]{0,2}) build\//i
    ],
    [r, [a, Ui], [i, h]],
    [
      // LG
      /((?=lg)?[vl]k\-?\d{3}) bui| 3\.[-\w; ]{10}lg?-([06cv9]{3,4})/i
    ],
    [r, [a, fi], [i, h]],
    [
      /(lm(?:-?f100[nv]?|-[\w\.]+)(?= bui|\))|nexus [45])/i,
      /\blg[-e;\/ ]+((?!browser|netcast|android tv)\w+)/i,
      /\blg-?([\d\w]+) bui/i
    ],
    [r, [a, fi], [i, l]],
    [
      // Lenovo
      /(ideatab[-\w ]+)/i,
      /lenovo ?(s[56]000[-\w]+|tab(?:[\w ]+)|yt[-\d\w]{6}|tb[-\d\w]{6})/i
    ],
    [r, [a, Mi], [i, h]],
    [
      // Nokia
      /(?:maemo|nokia).*(n900|lumia \d+)/i,
      /nokia[-_ ]?([-\w\.]*)/i
    ],
    [[r, /_/g, " "], [a, "Nokia"], [i, l]],
    [
      // Google
      /(pixel c)\b/i
      // Google Pixel C
    ],
    [r, [a, U], [i, h]],
    [
      /droid.+; (pixel[\daxl ]{0,6})(?: bui|\))/i
      // Google Pixel
    ],
    [r, [a, U], [i, l]],
    [
      // Sony
      /droid.+; (a?\d[0-2]{2}so|[c-g]\d{4}|so[-gl]\w+|xq-a\w[4-7][12])(?= bui|\).+chrome\/(?![1-6]{0,1}\d\.))/i
    ],
    [r, [a, oi], [i, l]],
    [
      /sony tablet [ps]/i,
      /\b(?:sony)?sgp\w+(?: bui|\))/i
    ],
    [[r, "Xperia Tablet"], [a, oi], [i, h]],
    [
      // OnePlus
      / (kb2005|in20[12]5|be20[12][59])\b/i,
      /(?:one)?(?:plus)? (a\d0\d\d)(?: b|\))/i
    ],
    [r, [a, "OnePlus"], [i, l]],
    [
      // Amazon
      /(alexa)webm/i,
      /(kf[a-z]{2}wi|aeo(?!bc)\w\w)( bui|\))/i,
      // Kindle Fire without Silk / Echo Show
      /(kf[a-z]+)( bui|\)).+silk\//i
      // Kindle Fire HD
    ],
    [r, [a, ri], [i, h]],
    [
      /((?:sd|kf)[0349hijorstuw]+)( bui|\)).+silk\//i
      // Fire Phone
    ],
    [[r, /(.+)/g, "Fire Phone $1"], [a, ri], [i, l]],
    [
      // BlackBerry
      /(playbook);[-\w\),; ]+(rim)/i
      // BlackBerry PlayBook
    ],
    [r, a, [i, h]],
    [
      /\b((?:bb[a-f]|st[hv])100-\d)/i,
      /\(bb10; (\w+)/i
      // BlackBerry 10
    ],
    [r, [a, Hi], [i, l]],
    [
      // Asus
      /(?:\b|asus_)(transfo[prime ]{4,10} \w+|eeepc|slider \w+|nexus 7|padfone|p00[cj])/i
    ],
    [r, [a, Ti], [i, h]],
    [
      / (z[bes]6[027][012][km][ls]|zenfone \d\w?)\b/i
    ],
    [r, [a, Ti], [i, l]],
    [
      // HTC
      /(nexus 9)/i
      // HTC Nexus 9
    ],
    [r, [a, "HTC"], [i, h]],
    [
      /(htc)[-;_ ]{1,2}([\w ]+(?=\)| bui)|\w+)/i,
      // HTC
      // ZTE
      /(zte)[- ]([\w ]+?)(?: bui|\/|\))/i,
      /(alcatel|geeksphone|nexian|panasonic(?!(?:;|\.))|sony(?!-bra))[-_ ]?([-\w]*)/i
      // Alcatel/GeeksPhone/Nexian/Panasonic/Sony
    ],
    [a, [r, /_/g, " "], [i, l]],
    [
      // TCL
      /tcl (xess p17aa)/i,
      /droid [\w\.]+; ((?:8[14]9[16]|9(?:0(?:48|60|8[01])|1(?:3[27]|66)|2(?:6[69]|9[56])|466))[gqswx])(_\w(\w|\w\w))?(\)| bui)/i
    ],
    [r, [a, "TCL"], [i, h]],
    [
      /droid [\w\.]+; (418(?:7d|8v)|5087z|5102l|61(?:02[dh]|25[adfh]|27[ai]|56[dh]|59k|65[ah])|a509dl|t(?:43(?:0w|1[adepqu])|50(?:6d|7[adju])|6(?:09dl|10k|12b|71[efho]|76[hjk])|7(?:66[ahju]|67[hw]|7[045][bh]|71[hk]|73o|76[ho]|79w|81[hks]?|82h|90[bhsy]|99b)|810[hs]))(_\w(\w|\w\w))?(\)| bui)/i
    ],
    [r, [a, "TCL"], [i, l]],
    [
      // itel
      /(itel) ((\w+))/i
    ],
    [[a, _], r, [i, Q, { tablet: ["p10001l", "w7001"], "*": "mobile" }]],
    [
      // Acer
      /droid.+; ([ab][1-7]-?[0178a]\d\d?)/i
    ],
    [r, [a, "Acer"], [i, h]],
    [
      // Meizu
      /droid.+; (m[1-5] note) bui/i,
      /\bmz-([-\w]{2,})/i
    ],
    [r, [a, "Meizu"], [i, l]],
    [
      // Ulefone
      /; ((?:power )?armor(?:[\w ]{0,8}))(?: bui|\))/i
    ],
    [r, [a, "Ulefone"], [i, l]],
    [
      // Energizer
      /; (energy ?\w+)(?: bui|\))/i,
      /; energizer ([\w ]+)(?: bui|\))/i
    ],
    [r, [a, "Energizer"], [i, l]],
    [
      // Cat
      /; cat (b35);/i,
      /; (b15q?|s22 flip|s48c|s62 pro)(?: bui|\))/i
    ],
    [r, [a, "Cat"], [i, l]],
    [
      // Smartfren
      /((?:new )?andromax[\w- ]+)(?: bui|\))/i
    ],
    [r, [a, "Smartfren"], [i, l]],
    [
      // Nothing
      /droid.+; (a(?:015|06[35]|142p?))/i
    ],
    [r, [a, "Nothing"], [i, l]],
    [
      // MIXED
      /(blackberry|benq|palm(?=\-)|sonyericsson|acer|asus|dell|meizu|motorola|polytron|infinix|tecno|micromax|advan)[-_ ]?([-\w]*)/i,
      // BlackBerry/BenQ/Palm/Sony-Ericsson/Acer/Asus/Dell/Meizu/Motorola/Polytron/Infinix/Tecno/Micromax/Advan
      /; (imo) ((?!tab)[\w ]+?)(?: bui|\))/i,
      // IMO
      /(hp) ([\w ]+\w)/i,
      // HP iPAQ
      /(asus)-?(\w+)/i,
      // Asus
      /(microsoft); (lumia[\w ]+)/i,
      // Microsoft Lumia
      /(lenovo)[-_ ]?([-\w]+)/i,
      // Lenovo
      /(jolla)/i,
      // Jolla
      /(oppo) ?([\w ]+) bui/i
      // OPPO
    ],
    [a, r, [i, l]],
    [
      /(imo) (tab \w+)/i,
      // IMO
      /(kobo)\s(ereader|touch)/i,
      // Kobo
      /(archos) (gamepad2?)/i,
      // Archos
      /(hp).+(touchpad(?!.+tablet)|tablet)/i,
      // HP TouchPad
      /(kindle)\/([\w\.]+)/i
      // Kindle
    ],
    [a, r, [i, h]],
    [
      /(surface duo)/i
      // Surface Duo
    ],
    [r, [a, ni], [i, h]],
    [
      /droid [\d\.]+; (fp\du?)(?: b|\))/i
      // Fairphone
    ],
    [r, [a, "Fairphone"], [i, l]],
    [
      /(shield[\w ]+) b/i
      // Nvidia Shield Tablets
    ],
    [r, [a, "Nvidia"], [i, h]],
    [
      /(sprint) (\w+)/i
      // Sprint Phones
    ],
    [a, r, [i, l]],
    [
      /(kin\.[onetw]{3})/i
      // Microsoft Kin
    ],
    [[r, /\./g, " "], [a, ni], [i, l]],
    [
      /droid.+; ([c6]+|et5[16]|mc[239][23]x?|vc8[03]x?)\)/i
      // Zebra
    ],
    [r, [a, pi], [i, h]],
    [
      /droid.+; (ec30|ps20|tc[2-8]\d[kx])\)/i
    ],
    [r, [a, pi], [i, l]],
    [
      ///////////////////
      // SMARTTVS
      ///////////////////
      /smart-tv.+(samsung)/i
      // Samsung
    ],
    [a, [i, m]],
    [
      /hbbtv.+maple;(\d+)/i
    ],
    [[r, /^/, "SmartTV"], [a, j], [i, m]],
    [
      /(nux; netcast.+smarttv|lg (netcast\.tv-201\d|android tv))/i
      // LG SmartTV
    ],
    [[a, fi], [i, m]],
    [
      /(apple) ?tv/i
      // Apple TV
    ],
    [a, [r, W + " TV"], [i, m]],
    [
      /crkey.*devicetype\/chromecast/i
      // Google Chromecast Third Generation
    ],
    [[r, C + " Third Generation"], [a, U], [i, m]],
    [
      /crkey.*devicetype\/([^/]*)/i
      // Google Chromecast with specific device type
    ],
    [[r, /^/, "Chromecast "], [a, U], [i, m]],
    [
      /fuchsia.*crkey/i
      // Google Chromecast Nest Hub
    ],
    [[r, C + " Nest Hub"], [a, U], [i, m]],
    [
      /crkey/i
      // Google Chromecast, Linux-based or unknown
    ],
    [[r, C], [a, U], [i, m]],
    [
      /droid.+aft(\w+)( bui|\))/i
      // Fire TV
    ],
    [r, [a, ri], [i, m]],
    [
      /\(dtv[\);].+(aquos)/i,
      /(aquos-tv[\w ]+)\)/i
      // Sharp
    ],
    [r, [a, Pi], [i, m]],
    [
      /(bravia[\w ]+)( bui|\))/i
      // Sony
    ],
    [r, [a, oi], [i, m]],
    [
      /(mitv-\w{5}) bui/i
      // Xiaomi
    ],
    [r, [a, hi], [i, m]],
    [
      /Hbbtv.*(technisat) (.*);/i
      // TechniSAT
    ],
    [a, r, [i, m]],
    [
      /\b(roku)[\dx]*[\)\/]((?:dvp-)?[\d\.]*)/i,
      // Roku
      /hbbtv\/\d+\.\d+\.\d+ +\([\w\+ ]*; *([\w\d][^;]*);([^;]*)/i
      // HbbTV devices
    ],
    [[a, ei], [r, ei], [i, m]],
    [
      /\b(android tv|smart[- ]?tv|opera tv|tv; rv:)\b/i
      // SmartTV from Unidentified Vendors
    ],
    [[i, m]],
    [
      ///////////////////
      // CONSOLES
      ///////////////////
      /(ouya)/i,
      // Ouya
      /(nintendo) (\w+)/i
      // Nintendo
    ],
    [a, r, [i, N]],
    [
      /droid.+; (shield) bui/i
      // Nvidia
    ],
    [r, [a, "Nvidia"], [i, N]],
    [
      /(playstation \w+)/i
      // Playstation
    ],
    [r, [a, oi], [i, N]],
    [
      /\b(xbox(?: one)?(?!; xbox))[\); ]/i
      // Microsoft Xbox
    ],
    [r, [a, ni], [i, N]],
    [
      ///////////////////
      // WEARABLES
      ///////////////////
      /\b(sm-[lr]\d\d[05][fnuw]?s?)\b/i
      // Samsung Galaxy Watch
    ],
    [r, [a, j], [i, J]],
    [
      /((pebble))app/i
      // Pebble
    ],
    [a, r, [i, J]],
    [
      /(watch)(?: ?os[,\/]|\d,\d\/)[\d\.]+/i
      // Apple Watch
    ],
    [r, [a, W], [i, J]],
    [
      /droid.+; (wt63?0{2,3})\)/i
    ],
    [r, [a, pi], [i, J]],
    [
      ///////////////////
      // XR
      ///////////////////
      /droid.+; (glass) \d/i
      // Google Glass
    ],
    [r, [a, U], [i, ci]],
    [
      /(pico) (4|neo3(?: link|pro)?)/i
      // Pico
    ],
    [a, r, [i, ci]],
    [
      /; (quest( \d| pro)?)/i
      // Oculus Quest
    ],
    [r, [a, Di], [i, ci]],
    [
      ///////////////////
      // EMBEDDED
      ///////////////////
      /(tesla)(?: qtcarbrowser|\/[-\w\.]+)/i
      // Tesla
    ],
    [a, [i, ki]],
    [
      /(aeobc)\b/i
      // Echo Dot
    ],
    [r, [a, ri], [i, ki]],
    [
      ////////////////////
      // MIXED (GENERIC)
      ///////////////////
      /droid .+?; ([^;]+?)(?: bui|; wv\)|\) applew).+? mobile safari/i
      // Android Phones from Unidentified Vendors
    ],
    [r, [i, l]],
    [
      /droid .+?; ([^;]+?)(?: bui|\) applew).+?(?! mobile) safari/i
      // Android Tablets from Unidentified Vendors
    ],
    [r, [i, h]],
    [
      /\b((tablet|tab)[;\/]|focus\/\d(?!.+mobile))/i
      // Unidentifiable Tablet
    ],
    [[i, h]],
    [
      /(phone|mobile(?:[;\/]| [ \w\/\.]*safari)|pda(?=.+windows ce))/i
      // Unidentifiable Mobile
    ],
    [[i, l]],
    [
      /(android[-\w\. ]{0,9});.+buil/i
      // Generic Android Device
    ],
    [r, [a, "Generic"]]
  ],
  engine: [
    [
      /windows.+ edge\/([\w\.]+)/i
      // EdgeHTML
    ],
    [t, [e, oe + "HTML"]],
    [
      /(arkweb)\/([\w\.]+)/i
      // ArkWeb
    ],
    [e, t],
    [
      /webkit\/537\.36.+chrome\/(?!27)([\w\.]+)/i
      // Blink
    ],
    [t, [e, "Blink"]],
    [
      /(presto)\/([\w\.]+)/i,
      // Presto
      /(webkit|trident|netfront|netsurf|amaya|lynx|w3m|goanna|servo)\/([\w\.]+)/i,
      // WebKit/Trident/NetFront/NetSurf/Amaya/Lynx/w3m/Goanna/Servo
      /ekioh(flow)\/([\w\.]+)/i,
      // Flow
      /(khtml|tasman|links)[\/ ]\(?([\w\.]+)/i,
      // KHTML/Tasman/Links
      /(icab)[\/ ]([23]\.[\d\.]+)/i,
      // iCab
      /\b(libweb)/i
    ],
    [e, t],
    [
      /rv\:([\w\.]{1,9})\b.+(gecko)/i
      // Gecko
    ],
    [t, e]
  ],
  os: [
    [
      // Windows
      /microsoft (windows) (vista|xp)/i
      // Windows (iTunes)
    ],
    [e, t],
    [
      /(windows (?:phone(?: os)?|mobile))[\/ ]?([\d\.\w ]*)/i
      // Windows Phone
    ],
    [e, [t, Q, Bi]],
    [
      /windows nt 6\.2; (arm)/i,
      // Windows RT
      /windows[\/ ]?([ntce\d\. ]+\w)(?!.+xbox)/i,
      /(?:win(?=3|9|n)|win 9x )([nt\d\.]+)/i
    ],
    [[t, Q, Bi], [e, Ei]],
    [
      // iOS/macOS
      /ip[honead]{2,4}\b(?:.*os ([\w]+) like mac|; opera)/i,
      // iOS
      /(?:ios;fbsv\/|iphone.+ios[\/ ])([\d\.]+)/i,
      /cfnetwork\/.+darwin/i
    ],
    [[t, /_/g, "."], [e, "iOS"]],
    [
      /(mac os x) ?([\w\. ]*)/i,
      /(macintosh|mac_powerpc\b)(?!.+haiku)/i
      // Mac OS
    ],
    [[e, "macOS"], [t, /_/g, "."]],
    [
      // Google Chromecast
      /android ([\d\.]+).*crkey/i
      // Google Chromecast, Android-based
    ],
    [t, [e, C + " Android"]],
    [
      /fuchsia.*crkey\/([\d\.]+)/i
      // Google Chromecast, Fuchsia-based
    ],
    [t, [e, C + " Fuchsia"]],
    [
      /crkey\/([\d\.]+).*devicetype\/smartspeaker/i
      // Google Chromecast, Linux-based Smart Speaker
    ],
    [t, [e, C + " SmartSpeaker"]],
    [
      /linux.*crkey\/([\d\.]+)/i
      // Google Chromecast, Legacy Linux-based
    ],
    [t, [e, C + " Linux"]],
    [
      /crkey\/([\d\.]+)/i
      // Google Chromecast, unknown
    ],
    [t, [e, C]],
    [
      // Mobile OSes
      /droid ([\w\.]+)\b.+(android[- ]x86|harmonyos)/i
      // Android-x86/HarmonyOS
    ],
    [t, e],
    [
      // Android/WebOS/QNX/Bada/RIM/Maemo/MeeGo/Sailfish OS/OpenHarmony
      /(android|webos|qnx|bada|rim tablet os|maemo|meego|sailfish|openharmony)[-\/ ]?([\w\.]*)/i,
      /(blackberry)\w*\/([\w\.]*)/i,
      // Blackberry
      /(tizen|kaios)[\/ ]([\w\.]+)/i,
      // Tizen/KaiOS
      /\((series40);/i
      // Series 40
    ],
    [e, t],
    [
      /\(bb(10);/i
      // BlackBerry 10
    ],
    [t, [e, Hi]],
    [
      /(?:symbian ?os|symbos|s60(?=;)|series60)[-\/ ]?([\w\.]*)/i
      // Symbian
    ],
    [t, [e, "Symbian"]],
    [
      /mozilla\/[\d\.]+ \((?:mobile|tablet|tv|mobile; [\w ]+); rv:.+ gecko\/([\w\.]+)/i
      // Firefox OS
    ],
    [t, [e, $ + " OS"]],
    [
      /web0s;.+rt(tv)/i,
      /\b(?:hp)?wos(?:browser)?\/([\w\.]+)/i
      // WebOS
    ],
    [t, [e, "webOS"]],
    [
      /watch(?: ?os[,\/]|\d,\d\/)([\d\.]+)/i
      // watchOS
    ],
    [t, [e, "watchOS"]],
    [
      // Google ChromeOS
      /(cros) [\w]+(?:\)| ([\w\.]+)\b)/i
      // Chromium OS
    ],
    [[e, "Chrome OS"], t],
    [
      // Smart TVs
      /panasonic;(viera)/i,
      // Panasonic Viera
      /(netrange)mmh/i,
      // Netrange
      /(nettv)\/(\d+\.[\w\.]+)/i,
      // NetTV
      // Console
      /(nintendo|playstation) (\w+)/i,
      // Nintendo/Playstation
      /(xbox); +xbox ([^\);]+)/i,
      // Microsoft Xbox (360, One, X, S, Series X, Series S)
      /(pico) .+os([\w\.]+)/i,
      // Pico
      // Other
      /\b(joli|palm)\b ?(?:os)?\/?([\w\.]*)/i,
      // Joli/Palm
      /(mint)[\/\(\) ]?(\w*)/i,
      // Mint
      /(mageia|vectorlinux)[; ]/i,
      // Mageia/VectorLinux
      /([kxln]?ubuntu|debian|suse|opensuse|gentoo|arch(?= linux)|slackware|fedora|mandriva|centos|pclinuxos|red ?hat|zenwalk|linpus|raspbian|plan 9|minix|risc os|contiki|deepin|manjaro|elementary os|sabayon|linspire)(?: gnu\/linux)?(?: enterprise)?(?:[- ]linux)?(?:-gnu)?[-\/ ]?(?!chrom|package)([-\w\.]*)/i,
      // Ubuntu/Debian/SUSE/Gentoo/Arch/Slackware/Fedora/Mandriva/CentOS/PCLinuxOS/RedHat/Zenwalk/Linpus/Raspbian/Plan9/Minix/RISCOS/Contiki/Deepin/Manjaro/elementary/Sabayon/Linspire
      /(hurd|linux) ?([\w\.]*)/i,
      // Hurd/Linux
      /(gnu) ?([\w\.]*)/i,
      // GNU
      /\b([-frentopcghs]{0,5}bsd|dragonfly)[\/ ]?(?!amd|[ix346]{1,2}86)([\w\.]*)/i,
      // FreeBSD/NetBSD/OpenBSD/PC-BSD/GhostBSD/DragonFly
      /(haiku) (\w+)/i
      // Haiku
    ],
    [e, t],
    [
      /(sunos) ?([\w\.\d]*)/i
      // Solaris
    ],
    [[e, "Solaris"], t],
    [
      /((?:open)?solaris)[-\/ ]?([\w\.]*)/i,
      // Solaris
      /(aix) ((\d)(?=\.|\)| )[\w\.])*/i,
      // AIX
      /\b(beos|os\/2|amigaos|morphos|openvms|fuchsia|hp-ux|serenityos)/i,
      // BeOS/OS2/AmigaOS/MorphOS/OpenVMS/Fuchsia/HP-UX/SerenityOS
      /(unix) ?([\w\.]*)/i
      // UNIX
    ],
    [e, t]
  ]
}, ai = function() {
  var o = { init: {}, isIgnore: {}, isIgnoreRgx: {}, toString: {} };
  return O.call(o.init, [
    [k, [e, t, ii, i]],
    [y, [g]],
    [E, [i, r, a]],
    [A, [e, t]],
    [x, [e, t]]
  ]), O.call(o.isIgnore, [
    [k, [t, ii]],
    [A, [t]],
    [x, [t]]
  ]), O.call(o.isIgnoreRgx, [
    [k, / ?browser$/i],
    [x, / ?os$/i]
  ]), O.call(o.toString, [
    [k, [e, t]],
    [y, [g]],
    [E, [a, r]],
    [A, [e, t]],
    [x, [e, t]]
  ]), o;
}(), se = function(o, n) {
  var s = ai.init[n], u = ai.isIgnore[n] || 0, b = ai.isIgnoreRgx[n] || 0, f = ai.toString[n] || 0;
  function w() {
    O.call(this, s);
  }
  return w.prototype.getItem = function() {
    return o;
  }, w.prototype.withClientHints = function() {
    return T ? T.getHighEntropyValues(Xi).then(function(c) {
      return o.setCH(new Wi(c, !1)).parseCH().get();
    }) : o.parseCH().get();
  }, w.prototype.withFeatureCheck = function() {
    return o.detectFeature().get();
  }, n != B && (w.prototype.is = function(c) {
    var d = !1;
    for (var p in this)
      if (this.hasOwnProperty(p) && !yi(u, p) && _(b ? L(b, this[p]) : this[p]) == _(b ? L(b, c) : c)) {
        if (d = !0, c != H)
          break;
      } else if (c == H && d) {
        d = !d;
        break;
      }
    return d;
  }, w.prototype.toString = function() {
    var c = q;
    for (var d in f)
      typeof this[f[d]] !== H && (c += (c ? " " : q) + this[f[d]]);
    return c || H;
  }), T || (w.prototype.then = function(c) {
    var d = this, p = function() {
      for (var S in d)
        d.hasOwnProperty(S) && (this[S] = d[S]);
    };
    p.prototype = {
      is: w.prototype.is,
      toString: w.prototype.toString
    };
    var R = new p();
    return c(R), R;
  }), new w();
};
function Wi(o, n) {
  if (o = o || {}, O.call(this, Xi), n)
    O.call(this, [
      [Ai, mi(o[M])],
      [_i, mi(o[Ki])],
      [l, /\?1/.test(o[ie])],
      [r, Z(o[ee])],
      [F, Z(o[Gi])],
      [Oi, Z(o[te])],
      [g, Z(o[Zi])],
      [P, mi(o[Qi])],
      [bi, Z(o[Ji])]
    ]);
  else
    for (var s in o)
      this.hasOwnProperty(s) && typeof o[s] !== H && (this[s] = o[s]);
}
function Vi(o, n, s, u) {
  return this.get = function(b) {
    return b ? this.data.hasOwnProperty(b) ? this.data[b] : void 0 : this.data;
  }, this.set = function(b, f) {
    return this.data[b] = f, this;
  }, this.setCH = function(b) {
    return this.uaCH = b, this;
  }, this.detectFeature = function() {
    if (v && v.userAgent == this.ua)
      switch (this.itemType) {
        case k:
          v.brave && typeof v.brave.isBrave == si && this.set(e, "Brave");
          break;
        case E:
          !this.get(i) && T && T[l] && this.set(i, l), this.get(r) == "Macintosh" && v && typeof v.standalone !== H && v.maxTouchPoints && v.maxTouchPoints > 2 && this.set(r, "iPad").set(i, h);
          break;
        case x:
          !this.get(e) && T && T[F] && this.set(e, T[F]);
          break;
        case B:
          var b = this.data, f = function(w) {
            return b[w].getItem().detectFeature().get();
          };
          this.set(k, f(k)).set(y, f(y)).set(E, f(E)).set(A, f(A)).set(x, f(x));
      }
    return this;
  }, this.parseUA = function() {
    return this.itemType != B && Fi.call(this.data, this.ua, this.rgxMap), this.itemType == k && this.set(ii, gi(this.get(t))), this;
  }, this.parseCH = function() {
    var b = this.uaCH, f = this.rgxMap;
    switch (this.itemType) {
      case k:
        var w = b[_i] || b[Ai], c;
        if (w)
          for (var d in w) {
            var p = L(/(Google|Microsoft) /, w[d].brand || w[d]), R = w[d].version;
            !/not.a.brand/i.test(p) && (!c || /chrom/i.test(c) && !/chromi/i.test(p)) && (this.set(e, p).set(t, R).set(ii, gi(R)), c = p);
          }
        break;
      case y:
        var S = b[g];
        S && (S && b[bi] == "64" && (S += "64"), Fi.call(this.data, S + ";", f));
        break;
      case E:
        if (b[l] && this.set(i, l), b[r] && this.set(r, b[r]), b[r] == "Xbox" && this.set(i, N).set(a, ni), b[P]) {
          var ti;
          if (typeof b[P] != "string")
            for (var Ri = 0; !ti && Ri < b[P].length; )
              ti = Q(b[P][Ri++], qi);
          else
            ti = Q(b[P], qi);
          this.set(i, ti);
        }
        break;
      case x:
        var di = b[F];
        if (di) {
          var ui = b[Oi];
          di == Ei && (ui = parseInt(gi(ui), 10) >= 13 ? "11" : "10"), this.set(e, di).set(t, ui);
        }
        this.get(e) == Ei && b[r] == "Xbox" && this.set(e, "Xbox").set(t, void 0);
        break;
      case B:
        var ji = this.data, G = function(Yi) {
          return ji[Yi].getItem().setCH(b).parseCH().get();
        };
        this.set(k, G(k)).set(y, G(y)).set(E, G(E)).set(A, G(A)).set(x, G(x));
    }
    return this;
  }, O.call(this, [
    ["itemType", o],
    ["ua", n],
    ["uaCH", u],
    ["rgxMap", s],
    ["data", se(this, o)]
  ]), this;
}
function I(o, n, s) {
  if (typeof o === z ? (wi(o, !0) ? (typeof n === z && (s = n), n = o) : (s = o, n = void 0), o = void 0) : typeof o === vi && !wi(n, !0) && (s = n, n = void 0), s && typeof s.append === si) {
    var u = {};
    s.forEach(function(d, p) {
      u[p] = d;
    }), s = u;
  }
  if (!(this instanceof I))
    return new I(o, n, s).getResult();
  var b = typeof o === vi ? o : (
    // Passed user-agent string
    s && s[Ci] ? s[Ci] : (
      // User-Agent from passed headers
      v && v.userAgent ? v.userAgent : (
        // navigator.userAgent
        q
      )
    )
  ), f = new Wi(s, !0), w = n ? ne(zi, n) : zi, c = function(d) {
    return d == B ? function() {
      return new Vi(d, b, w, f).set("ua", b).set(k, this.getBrowser()).set(y, this.getCPU()).set(E, this.getDevice()).set(A, this.getEngine()).set(x, this.getOS()).get();
    } : function() {
      return new Vi(d, b, w[d], f).parseUA().get();
    };
  };
  return O.call(this, [
    ["getBrowser", c(k)],
    ["getCPU", c(y)],
    ["getDevice", c(E)],
    ["getEngine", c(A)],
    ["getOS", c(x)],
    ["getResult", c(B)],
    ["getUA", function() {
      return b;
    }],
    ["setUA", function(d) {
      return V(d) && (b = d.length > xi ? ei(d, xi) : d), this;
    }]
  ]).setUA(b), this;
}
I.VERSION = $i;
I.BROWSER = li([e, t, ii, i]);
I.CPU = li([g]);
I.DEVICE = li([r, a, i, N, l, m, h, J, ki]);
I.ENGINE = I.OS = li([e, t]);
export {
  I as UAParser
};
