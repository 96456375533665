import { FullConversationWithoutMessagesFragment } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { observer } from '@knuddels-app/mobx';
import { colorToRgbaString } from '@knuddels-app/tools/colorToRgbaString';
import { FlexCol, Z_INDEX, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { $ActiveChannelService, $ChannelComponents, ChannelInfo } from '@knuddelsModules/Channel';
import * as React from 'react';
import { useCommandOrPMessageSubmit } from './useCommandOrPMessageSubmit';
import { RichInput } from '@shared/components/RichInput';
export const BotConversationBody: React.FC<{
  otherParticipant: FullConversationWithoutMessagesFragment['otherParticipants'][0];
}> = observer('BotConversationBody', ({
  otherParticipant
}) => {
  const activeChannelService = useService($ActiveChannelService);
  const ChannelComponents = useService($ChannelComponents);
  const {
    submit
  } = useCommandOrPMessageSubmit(otherParticipant);
  const scrollViewRef = React.useRef<any>(null);
  const {
    backgroundColor,
    backgroundImageInfo
  } = activeChannelService.activeChannel.groupInfo;
  const channelBackgroundColorString = colorToRgbaString(backgroundColor);
  const getBotMessages = (channel: ChannelInfo) => {
    const messages = channel.getBotMessages().filter(message => message.__typename === 'ChannelMsgPrivateGroup' && message.sender.nick === otherParticipant.nick || message.__typename === 'ChannelMsgSystem' && message.sender.nick === otherParticipant.nick || message.__typename === 'CLIENT_MESSENGER_MESSAGE' && message.conversation.otherParticipants[0].nick === otherParticipant.nick);
    return messages;
  };
  return <ChannelComponents.ChannelBackground backgroundColor={channelBackgroundColorString} backgroundImageInfo={backgroundImageInfo}>
			<div className={_c0}>
				<ChannelComponents.ChatHistory channel={activeChannelService.activeChannel} getMessages={getBotMessages} scrollViewRef={scrollViewRef} />
				<div style={{
        zIndex: resolveThemingValue(Z_INDEX.HIGHEST_CONTENT, "theme", useTheme())
      }} className={_c1}>
					<RichInput autoFocus onSubmit={submit} useModal context={'miniChat'} />
				</div>
			</div>
		</ChannelComponents.ChannelBackground>;
});
const _c0 = " Knu-FlexCol width-full ";
const _c1 = " Knu-FlexCol shadow-Shadow3 position-relative ";