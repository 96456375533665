import * as React from 'react';
import { useMemo } from 'react';
import { Box, calculateGradient, parseRgbString, useTheme } from '@knuddels/component-library';
export const BackgroundGradient: React.FC<{}> = () => {
  const theme = useTheme();
  const backgroundGradient = useMemo(() => {
    const rgb = parseRgbString(theme.colors.basic.accent);
    const bgColor1 = calculateGradient(rgb, 0, 0.11);
    const bgColor2 = calculateGradient(rgb, 0, 0.04);
    return `linear-gradient(180deg, ${bgColor1} 0%, ${bgColor2} 100%)`;
  }, [theme]);
  return <div style={{
    background: backgroundGradient
  }} className={_c0} />;
};
const _c0 = " Knu-Box position-absolute inset-none ";