import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { $MessageFormatProvider } from '@knuddels-app/i18n';
import { ChannelList } from '../components/ChannelList';
import { DashboardChannelListElement } from '../components/DashboardChannelListElement';
import de from '../i18n/formats.de.json';
import en from '../i18n/formats.en.json';
import { Overview } from '@knuddelsModules/ChannelList/bundle/components/Overview/Overview';
import { FullCategory } from '@knuddelsModules/ChannelList/bundle/components/FullCategory/FullCategory';

@injectable()
export class ChannelListService {
	public readonly dispose = Disposable.fn();

	public readonly MainView = ChannelList;
	public readonly Overview = Overview;
	public readonly FullCategory = FullCategory;
	public readonly DashboardChannelListElement = DashboardChannelListElement;

	constructor(
		@inject($MessageFormatProvider)
		messageFormatProvider: typeof $MessageFormatProvider.T
	) {
		messageFormatProvider.registerFormatProvider(
			locale =>
				// Workaround for metro bundler because it can't handle dynamic imports.
				// See https://github.com/facebook/metro/issues/52
				(
					({
						de,
						en,
					}) as any
				)[locale.language]
		);
	}
}
