import { createK3Router } from '@knuddels-app/layout/k3Router';
import { channelViewId } from '@knuddelsModules/Channel';

export type ChannelRouteConfig = {
	chat: { params: { id: string; isNickListOpen: boolean } };
	overview: { params: undefined };
	category: { params: { id: string; grouped?: boolean } };
	['channel-list-settings']: { params: undefined };
};

export const { Router, useRouter } =
	createK3Router<ChannelRouteConfig>(channelViewId);
