import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $CurrentEndpointStore } from '@knuddels-app/Connection';
import { $OverlayService } from '@knuddels-app/overlays/serviceIds';
import { $AppService } from '../../providedServices';
import { runInAction } from '@knuddels-app/mobx';
import { ModalBottomContextProvider, ModalWrapper } from '@knuddels/component-library';
import { $Environment } from '@knuddels-app/Environment';
import { $SystemAppService } from '../../../SystemApps';
import { useLazyService } from '@knuddels-app/ModuleSystem';
import { $LocationService } from '@knuddels-app/location';
import { getSafeArea } from '@knuddels-app/SafeArea';
import { $ScreenService } from '@knuddels-app/Screen';
const OVERLAY_SYSTEM_APP_TEST_URL = 'https://chatproxytest.knuddels.de/static/system-app-overlay-presenter.html?v=3';
const OVERLAY_SYSTEM_APP_LIVE_URL = 'https://chatproxy.knuddels.de/static/system-app-overlay-presenter.html?v=3';
export const SystemAppOverlayPresenter = () => {
  const [openerAppId, setOpenerAppId] = React.useState<string | null>(null);
  const endpoint = useService($CurrentEndpointStore);
  const overlayService = useService($OverlayService);
  const screenService = useService($ScreenService);
  const systemAppServiceGetter = useLazyService($SystemAppService);
  const appService = useService($AppService);
  const env = useService($Environment);
  const locationService = useService($LocationService);
  const modalRef = React.useRef<HTMLDivElement | null>(null);
  const isLive = endpoint.currentEndpoint.id === 'DE';
  const ref = React.useRef<HTMLIFrameElement | null>(null);
  const closeOverlay = React.useCallback(() => {
    if (!ref.current) {
      return;
    }
    ref.current.contentWindow.postMessage({
      type: 'HIDE_MODAL'
    }, '*');
    ref.current.style.pointerEvents = 'none';
    ref.current.style.opacity = '0';
    ref.current.style.transform = 'translateY(-100%)';
    setOpenerAppId(null);
    systemAppServiceGetter().then(s => {
      s.setOverlayOpened(false);
    });
  }, []);
  const sendCloseToApp = (appId: string) => {
    const app = appService.appViewers.map(v => v.getApp(appId)).filter(Boolean)[0];
    if (app) {
      app.sendEventToWebview('CLOSE_OVERLAY', {});
      return true;
    } else {
      return false;
    }
  };
  React.useEffect(() => {
    if (openerAppId) {
      const handler = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          sendCloseToApp(openerAppId);
          e.stopPropagation();
        }
      };
      window.addEventListener('keydown', handler, true);
      const currentIndex = locationService.pushOverlay('menu');
      const unsub = locationService.historyListen(() => {
        if (history.state.idx < currentIndex) {
          sendCloseToApp(openerAppId);
        }
      });
      return () => {
        unsub();
        window.removeEventListener('keydown', handler, true);
        if (history.state.idx === currentIndex) {
          locationService.pop();
        }
      };
    }
  }, [closeOverlay, env.nativeInterface, openerAppId]);
  React.useEffect(() => {
    runInAction('set isSystemAppOverlayVisible', () => {
      overlayService.isSystemAppOverlayVisible = !!openerAppId;
    });
    if (!openerAppId) {
      return;
    }
    return appService.registerAppCloseListener(openerAppId, () => {
      closeOverlay();
    });
  }, [openerAppId]);
  React.useEffect(() => {
    const listener = (ev: MessageEvent) => {
      if (!ref.current) {
        return;
      }
      if (ev.data?.type === 'PRESENTER_SHOW_OVERLAY') {
        ref.current.style.pointerEvents = 'all';
        ref.current.style.opacity = '1';
        ref.current.style.transform = 'translateY(0)';
        /**
         * overlays are stacked via index.
         * gets the largest index and uses it to ensure the overlay is rendered above
         */
        modalRef.current.style.zIndex = overlayService.getNextZIndex(true) + '';
        setOpenerAppId(ev.data.appId);
      } else if (ev.data?.type === 'PRESENTER_HIDE_OVERLAY') {
        closeOverlay();
      }
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);
  return <ModalBottomContextProvider value={{
    spacing: getSafeArea().navBarHeight,
    direction: screenService.detailedOrientation === 'landscape-primary' ? 'right' : screenService.detailedOrientation === 'landscape-secondary' ? 'left' : 'bottom'
  }}>
			<ModalWrapper innerRef={modalRef} pointerEvents={'none'}>
				<iframe name={'system-app-overlay-presenter-v3'} style={{
        display: 'block',
        position: 'absolute',
        pointerEvents: 'none',
        top: '0',
        left: 0,
        right: 0,
        height: '100%',
        opacity: 0,
        width: '100%',
        transform: 'translateY(-100%)'
      }} allow={'microphone;camera;clipboard-write'} frameBorder={0} ref={ref} src={isLive ? OVERLAY_SYSTEM_APP_LIVE_URL : OVERLAY_SYSTEM_APP_TEST_URL} />
			</ModalWrapper>
		</ModalBottomContextProvider>;
};