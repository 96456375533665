import { action, observable, runInAction } from '@knuddels-app/mobx';
import { injectable, inject } from '@knuddels-app/DependencyInjection';
import { LocationInfo } from './LocationInfo';
import { Disposable } from '@knuddels/std';
import { $Environment } from '@knuddels-app/Environment';
import { History } from 'history';

@injectable()
export class LocationService {
	public readonly dispose = Disposable.fn();

	@observable private _currentLocation: LocationInfo;
	public get currentLocation(): LocationInfo {
		return this._currentLocation;
	}

	private readonly history: History;
	private readonly historyCanGoBack: () => boolean;

	get historyListen() {
		return this.history.listen;
	}

	get currentHistoryIndex(): number {
		return this.currentVisibleIndex;
	}

	private currentVisibleIndex: number = 0;

	private indexToOverlayState: boolean[] = [];
	private indexToHash: string[] = [];

	constructor(@inject($Environment) environment: typeof $Environment.T) {
		if (environment.nativeInterface) {
			// This is what the old BackButton did
			// (https://github.com/ReactTraining/react-router/blob/f4c9672d7baacd004ee3206c10988f82e3541242/packages/react-router-native/BackButton.js#L17)
			this.dispose.track(
				environment.nativeInterface.addBackHandler(() => {
					return this.pop();
				})
			);
			this.historyCanGoBack = () => {
				return (
					!!environment.nativeInterface &&
					this.currentVisibleIndex > 0
				);
			};
		} else {
			// browser history does not have index
			this.historyCanGoBack = () => true;
		}

		this.history = environment.history;
		this.replace(LocationInfo.fromHistoryLocation(this.history.location));
		this.currentVisibleIndex = this.getIndexFromLocation();
		this.dispose.track(
			this.history.listen(e => {
				runInAction('Update current location', () => {
					if (e.action !== 'POP') {
						return;
					}

					const index = window.history.state.idx;
					const shouldSkipEntry =
						(this.indexToOverlayState[index] &&
							!this.indexToOverlayState[
								this.currentVisibleIndex
							]) ||
						(this.history.location.state as any)
							?.alwaysSkipInHistory;

					if (shouldSkipEntry) {
						const forward = index > this.currentVisibleIndex;
						const targetIndex = forward
							? Math.min(
									this.indexToOverlayState.indexOf(
										false,
										index + 1
									) + 1,
									history.length - 1
								)
							: Math.max(
									this.indexToOverlayState.lastIndexOf(
										false,
										index
									),
									0
								);

						const sameView =
							this.indexToHash[targetIndex] ===
							this.indexToHash[this.currentVisibleIndex];
						const delta =
							targetIndex -
							index +
							(!sameView ? 0 : forward ? 1 : -1);
						this.history.go(delta);

						return;
					}

					this.currentVisibleIndex = index;

					this._currentLocation = LocationInfo.fromUpdate(
						e.action,
						e.location
					);
				});
			})
		);
	}

	public replacePath(path: string): void {
		const loc = this.currentLocation.withPath(path);
		this.replace(loc);
	}

	public pushHash(hash: string, overlay: boolean): number {
		const url = new URL(window.location.href);
		url.hash = hash;
		this.history.push(
			{
				pathname: url.pathname,
				hash: url.hash,
				search: url.search,
			},
			{
				...(this.history.location.state as Record<string, string>),
				includesOverlay: overlay,
				alwaysSkipInHistory: overlay,
			}
		);
		this.currentVisibleIndex = this.getIndexFromLocation();
		this.indexToOverlayState[this.currentVisibleIndex] = overlay;
		return this.currentVisibleIndex;
	}

	@action
	public push(newLocation: LocationInfo): void {
		const historyLocation = newLocation.toHistoryLocation();
		this.history.push(historyLocation, historyLocation.state);
		this.currentVisibleIndex = this.getIndexFromLocation();
		this.indexToOverlayState[this.currentVisibleIndex] =
			historyLocation.state.includesOverlays;
		this.indexToHash[this.currentVisibleIndex] =
			historyLocation.state.hashedLocation;
		this.indexToOverlayState = this.indexToOverlayState.slice(
			0,
			this.currentVisibleIndex + 1
		);
		this.indexToHash = this.indexToHash.slice(
			0,
			this.currentVisibleIndex + 1
		);
		this._currentLocation = newLocation;
	}

	public pushOverlay(hash: string): number {
		return this.pushHash(hash, true);
	}

	@action
	public replace(newLocation: LocationInfo): void {
		const historyLocation = newLocation.toHistoryLocation();
		this.currentVisibleIndex = this.getIndexFromLocation();
		this.indexToOverlayState[this.currentVisibleIndex] =
			historyLocation.state.includesOverlays;
		this.history.replace(historyLocation, historyLocation.state);
		this._currentLocation = newLocation;
	}

	public pop(): boolean {
		if (this.historyCanGoBack()) {
			this.history.back();
			return true;
		} else {
			return false;
		}
	}

	private getIndexFromLocation(): number {
		return window.history.state?.idx ?? 0;
	}
}
