import { ScreenHeight, ScreenWidth } from '@knuddels-app/Screen';

/**
 * A view id is a handle for a view.
 * TState must be immutable!
 */
export class ViewId<TState extends ViewState = ViewState> {
	public readonly kind = 'ViewId';

	constructor(
		/**
		 * The (primary) id used to reference this view in the URL.
		 */
		public readonly id: string,
		/**
		 * Alternative ids that are used when parsing an URL.
		 * Can be used to rename the id without breaking existing bookmarks.
		 * Only the primary id is used when updating the URL.
		 */
		public readonly aliasIds: ReadonlyArray<string> = new Array<string>()
	) {}

	public with(
		updater: (state: TState) => TState
	): ViewIdWithStateEffect<TState> {
		return {
			viewId: this,
			effect: updater,
		};
	}

	equals(other: ViewId): boolean {
		return this.id === other.id;
	}
}

/**
 * ViewStates must be immutable.
 */
export interface ViewState {
	persist(): PersistedViewState;

	/**
	 * If this ViewState has multiple views, this should return the
	 * state with only the root view open. The root view is the one
	 * that is initially opened when clicking on a navigation icon.
	 * If the only view open is the root view, the current state should be
	 * returned unmodified.
	 */
	withOnlyRootViewOpened?(): ViewState;

	/**
	 * This is called when the view is opened through the nav and can be used to modify the view state before
	 * the view is opened. Useful, for example, if the view should not exactly open as it was closed last time.
	 */
	onOpenByNav?(): ViewState;

	/**
	 * This is called when the view is opened through the menu can be used to modify the view state before
	 * the view is opened. Useful, for example, if the view should not exactly open as it was closed last time.
	 */
	onOpenByMenu?(): ViewState;
}

export class EmptyViewState implements ViewState {
	static fromPersistedViewState(): EmptyViewState {
		return new EmptyViewState();
	}

	persist(): PersistedViewState {
		return {
			pathItems: [],
			state: undefined,
		};
	}
}

export interface PersistedViewState {
	/**
	 * Are used for building the url.
	 * Must not contain view id strings.
	 */
	pathItems: string[];

	/**
	 * Must be json or undefined.
	 */
	state: unknown | undefined;
}

/**
 * Refers to a view and specifies an effect on its state.
 */
export type ViewIdWithStateEffect<TState extends ViewState> = {
	viewId: ViewId<TState>;
	effect: (oldState: TState) => TState;
};

export enum Position {
	/**
	 * Side view (e.g. Messenger overview).
	 */
	Side,
	/**
	 * Main screen (channel, on smaller screens messenger detail).
	 */
	Main,
	/**
	 * Overlay over complete screen.
	 */
	Overlay,
}

export interface ViewConfig<TState extends ViewState = ViewState> {
	/**
	 * The view id that this view describes.
	 */
	readonly viewId: ViewId<TState>;
	readonly position: Position;

	/**
	 * If set to a number, the component will remain mounted when navigated away.
	 * An index is used to keep the view at the same place in the dom.
	 */
	readonly persistIndex?: number;
	/**
	 * Specifies whether the view is opened automatically if there is space.
	 */
	readonly isDefault?: () => boolean;
	/**
	 * If there's more than one default view, the one with the highest priority will be opened.
	 */
	readonly isDefaultPriority?: number;
	readonly requiredScreenWidths?: ScreenWidth[];
	/**
	 * Specifies the Fade In Time for that view
	 */
	readonly fadeInTime?: number;
	readonly hasTitleBar?: boolean;
	/*
	 * Specifies whether the view will be mounted on next tick and with a fade animation
	 * default is true
	 */
	readonly lazyMountEnabled?: boolean;
	readonly useSplitView?: boolean;
	/*
	 * Renders the component on startup even if the view is not opened.
	 */
	readonly autostartInBackground?: boolean | (() => Promise<boolean>);
	/**
	 * If this view is open any notification matching the key will not be shown.
	 */
	readonly notificationAssociationKey?: string;
	/**
	 * Allows to override the default styles for the given position
	 */
	readonly getOverrideStyles?: (args: {
		width: ScreenWidth;
		height: ScreenHeight;
		viewState: TState;
	}) => Record<string, any>;
	/**
	 * Specifies whether the router should reuse the view if the paths are equal
	 * this will prevent animations from happening
	 */
	readonly reuseEqualRoutes?: boolean;
	/**
	 * Specifies an element that is rendered when there is no active child view
	 */
	readonly splitViewPlaceholder?: React.ReactNode;
	/**
	 * Default: false. If set to `true`, it allows the ViewService to add multiple overlays
	 * with the corresponding viewId. They will still close overlays if the persisted
	 * ViewState is equal.
	 * If this is used, it will automatically not use the viewId in the url and pathItems.
	 */
	readonly allowMultipleOverlays?: boolean;

	readonly isSystemApp?: boolean;

	/**
	 * If this returns true, the view will be shown directly on the nav background and not
	 * inside a pane.
	 */
	readonly hideDesktopBackground?: (state: TState) => boolean;

	/**
	 * If this returns true, the bottom ads that are shown on fullscreen overlays on mobile
	 * will be disabled for this view.
	 */
	readonly disableMobileBottomAds?: (state: TState) => boolean;

	/**
	 * Calling `loadState` and `persist` repeatedly should converge.
	 */
	loadState(from: PersistedViewState): TState;

	render(state: TState): RenderedView;
}

export interface RenderedView {
	mainView: React.ReactElement;
}
