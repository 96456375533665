import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $UserService } from '../../providedServices';
import { Box, createAccessiblePointerEventHandler, Flex, IconChevronRight, Text, ThemeColors, ThemeOverride, createNativeAccessiblePointerEventHandler, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
import { KnuddelsPlusOfferFragment, User } from '@generated/graphql';
import { observer } from '@knuddels-app/mobx';
import { $PromotionService } from '@knuddelsModules/Promotions';
import { useTimer } from '@knuddels-app/tools/useTimer';
import { colorToRgb } from '@knuddels-app/tools/colorToRgb';
export const KnuddelsPlusBanner: React.FC<{}> = observer('KnuddelsPlusBanner', () => {
  const userService = useService($UserService);
  const user = userService.currentUser;
  const execute = useSlashCommandWithoutChannel({
    shouldUseStappIfAvailable: true
  });
  const promotionService = useService($PromotionService);
  if (!user) {
    return <React.Fragment />;
  }
  if (user.knuddelsPlusActiveUntil && +user.knuddelsPlusActiveUntil > Date.now()) {
    return <React.Fragment />;
  }
  const bonusTime = getBonusTime(user);
  if (!promotionService.activeKnuddelsPlusOffer && bonusTime.format === 'none') {
    return <React.Fragment />;
  }
  const bgColor = promotionService.activeKnuddelsPlusOffer ? colorToRgb(promotionService.activeKnuddelsPlusOffer.color) : 'accent';
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: () => {
      execute('/opensystemapp KnuddelsPlusShopApp');
    }
  })} style={{
    background: resolveThemingValue(bgColor, "colors", useTheme())
  }} className={_c0 + (bgColor ? resolveIsDarkColor(bgColor, useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				{!promotionService.activeKnuddelsPlusOffer && <Flex position={'absolute'} inset={'none'} bg={bgColor} {...{
      style: {
        filter: 'brightness(0.75) saturate(1.5)'
      }
    }} zIndex={-1} />}
				<Text type={'tiny'} bold={true} className={_c1}>
					{promotionService.activeKnuddelsPlusOffer ? <OfferMessage bgColor={bgColor} offer={promotionService.activeKnuddelsPlusOffer} /> : bonusTime.format === 'days' ? <FormattedMessage id={declareFormat({
        id: 'knuddelsplus.banner.bonus.days',
        defaultFormat: 'Your free {badge} subscription is still valid for {bonusDays, plural, one {# day} other {# days}}!'
      })} values={{
        badge: <Badge bgColor={bgColor} />,
        bonusDays: bonusTime.amount
      }} /> : bonusTime.format === 'hours' ? <FormattedMessage id={declareFormat({
        id: 'knuddelsplus.banner.bonus.hours',
        defaultFormat: 'Your free {badge} subscription is still valid for {bonusHours}h!'
      })} values={{
        badge: <Badge bgColor={bgColor} />,
        bonusHours: bonusTime.amount
      }} /> : <FormattedMessage id={declareFormat({
        id: 'knuddelsplus.banner',
        defaultFormat: 'Enjoy more benefits with {badge}'
      })} values={{
        badge: <Badge bgColor={bgColor} />
      }} />}
				</Text>
				<IconChevronRight size={'large'} active />
			</div>;
});
const OfferMessage: React.FC<{
  offer: KnuddelsPlusOfferFragment;
  bgColor: ThemeColors;
}> = ({
  offer,
  bgColor
}) => {
  const [timer] = useTimer(+offer.endTimestamp, 'hh:mm:ss');
  return <FormattedMessage id={declareFormat({
    id: 'knuddelsplus.banner.offer',
    defaultFormat: '{badge} - OFFER - {timer} REMAINING!'
  })} values={{
    timer,
    badge: <Badge bgColor={bgColor} />
  }} />;
};
function getBonusTime(user: Pick<User, 'knuddelsPlusBonusDaysActiveUntil'>): {
  format: 'days' | 'hours';
  amount: number;
} | {
  format: 'none';
} {
  if (!user.knuddelsPlusBonusDaysActiveUntil) {
    return {
      format: 'none'
    };
  }
  const now = Date.now();
  const delta = +user.knuddelsPlusBonusDaysActiveUntil - now;
  if (delta <= 0) {
    return {
      format: 'none'
    };
  }
  if (delta < 1000 * 60 * 60 * 24) {
    return {
      format: 'hours',
      amount: Math.ceil(delta / 1000 / 60 / 60)
    };
  }
  return {
    format: 'days',
    amount: Math.floor(delta / 1000 / 60 / 60 / 24)
  };
}
const Badge: React.FC<{
  bgColor: ThemeColors;
}> = ({
  bgColor
}) => {
  return <Box as={'div'} px={(3 as ThemeOverride)} borderRadius={(3 as ThemeOverride)} bg={bgColor} placeItems={'center'} height={16} {...{
    style: {
      display: 'inline-block'
    }
  }}>
			<Text type={'tiny'} bold={true} className={_c2}>
				{'KNUDDELS PLUS'}
			</Text>
		</Box>;
};
const _c0 = " Knu-Flex cursor-pointer position-relative height-24px placeItems-center zIndex-0 ";
const _c1 = "  ";
const _c2 = " textAlign-center ";