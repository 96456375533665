import { declareFormat, FormatId } from '@knuddels/i18n';

import HappyHourMobile from './assets/mobile/HappyHour.png';
import HappyHourDesktop from './assets/desktop/HappyHour.png';
import ProfilbildhochladenMobile from './assets/mobile/Profilbildhochladen.png';
import ProfilbildhochladenDesktop from './assets/desktop/Profilbildhochladen.png';
import QuestsMobile from './assets/mobile/Quests.png';
import QuestsDesktop from './assets/desktop/Quests.png';
import ProfilMobile from './assets/mobile/Profil.png';
import ProfilDesktop from './assets/desktop/Profil.png';
import EinstellungenMobile from './assets/mobile/Einstellungen.png';
import EinstellungenDesktop from './assets/desktop/Einstellungen.png';
import FotomeetMobile from './assets/mobile/Fotomeet.png';
import FotomeetDesktop from './assets/desktop/Fotomeet.png';
import CrashMobile from './assets/mobile/Crash.png';
import CrashDesktop from './assets/desktop/Crash.png';
import WeltreiseMobile from './assets/mobile/Weltreise.png';
import WeltreiseDesktop from './assets/desktop/Weltreise.png';
import DiceOneMobile from './assets/mobile/DiceOne.png';
import DiceOneDesktop from './assets/desktop/DiceOne.png';
import DiceSkyMobile from './assets/mobile/DiceSky.png';
import DiceSkyDesktop from './assets/desktop/DiceSky.jpg';
import NotificationsMobile from './assets/mobile/Notifications.png';
import NotificationsDesktop from './assets/desktop/Notifications.png';
import AppsuSpieleMobile from './assets/mobile/AppsuSpiele.png';
import AppsuSpieleDesktop from './assets/desktop/AppsuSpiele.png';
import KnuddelstandMobile from './assets/mobile/Knuddelstand.png';
import KnuddelstandDesktop from './assets/desktop/Knuddelstand.png';
import SmileyboerseMobile from './assets/mobile/Smileyboerse.png';
import SmileyboerseDesktop from './assets/desktop/Smileyboerse.png';
import Quests2Mobile from './assets/mobile/Quests2.png';
import Quests2Desktop from './assets/desktop/Quests2.png';
import KontaktfilterMobile from './assets/mobile/Kontaktfilter.png';
import KontaktfilterDesktop from './assets/desktop/Kontaktfilter.png';
import SprechblasenMobile from './assets/mobile/Sprechblasen.png';
import SprechblasenDesktop from './assets/desktop/Sprechblasen.png';
import AkzentfarbeMobile from './assets/mobile/Akzentfarbe.png';
import AkzentfarbeDesktop from './assets/desktop/Akzentfarbe.png';
import NavigationSlotMobile from './assets/mobile/NavigationSlot.png';
import NavigationSlotDesktop from './assets/desktop/NavigationSlot.png';
import LightthemeMobile from './assets/mobile/Lighttheme.png';
import LightthemeDesktop from './assets/desktop/Lighttheme.png';
import SchriftgroesseMobile from './assets/mobile/Schriftgroesse.png';
import SchriftgroesseDesktop from './assets/desktop/Schriftgroesse.png';
import LoyaltySystemMobile from './assets/mobile/LoyaltySystem.png';
import LoyaltySystemDesktop from './assets/desktop/LoyaltySystem.png';
import KnuddelsPlusMobile from './assets/mobile/KnuddelsPlus.png';
import KnuddelsPlusDesktop from './assets/desktop/KnuddelsPlus.png';

type DashboardTipSchema = {
	title: FormatId;
	subtitle: FormatId;
	imageSrcMobile: any;
	imageSrcDesktop: any;
	repeating?: boolean;
};

export type DashboardTip = DashboardTipSchema & { id: DashboardTipId };

export const dashboardTips = [
	{
		id: 'runningHappyHour',
		repeating: true,
		title: declareFormat({
			id: 'channel.dashboard.tip.runningHappyHour.title',
			defaultFormat: 'Ongoing Happy Hour',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.runningHappyHour.subtitle',
			defaultFormat: 'Grab Knuddel at a special price now!',
		}),
		imageSrcMobile: HappyHourMobile,
		imageSrcDesktop: HappyHourDesktop,
	},
	{
		id: 'loyaltySystemLevelLossWarning',
		repeating: true,
		title: declareFormat({
			id: 'channel.dashboard.tip.loyaltySystemLevelLossWarning.title',
			defaultFormat: '',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.loyaltySystemLevelLossWarning.subtitle',
			defaultFormat: '',
		}),
		imageSrcMobile: LoyaltySystemMobile,
		imageSrcDesktop: LoyaltySystemDesktop,
	},
	{
		id: 'loyaltySystemPointLossWarning',
		repeating: true,
		title: declareFormat({
			id: 'channel.dashboard.tip.loyaltySystemPointLossWarning.title',
			defaultFormat: '',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.loyaltySystemPointLossWarning.subtitle',
			defaultFormat: '',
		}),
		imageSrcMobile: LoyaltySystemMobile,
		imageSrcDesktop: LoyaltySystemDesktop,
	},
	{
		id: 'loyaltySystemLevelUpHint',
		repeating: true,
		title: declareFormat({
			id: 'channel.dashboard.tip.loyaltySystemLevelUpHint.title',
			defaultFormat: '',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.loyaltySystemLevelUpHint.subtitle',
			defaultFormat: '',
		}),
		imageSrcMobile: LoyaltySystemMobile,
		imageSrcDesktop: LoyaltySystemDesktop,
	},
	{
		id: 'uploadProfilePhoto',
		repeating: true,
		title: declareFormat({
			id: 'channel.dashboard.tip.uploadProfilePhoto.title',
			defaultFormat: 'Upload Profile Photo',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.uploadProfilePhoto.subtitle',
			defaultFormat: 'Show who you are and meet new people',
		}),
		imageSrcMobile: ProfilbildhochladenMobile,
		imageSrcDesktop: ProfilbildhochladenDesktop,
	},
	{
		id: 'openQuests',
		title: declareFormat({
			id: 'channel.dashboard.tip.openQuests.title',
			defaultFormat: 'Check out your Quests',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.openQuests.subtitle',
			defaultFormat: 'Complete tasks and secure rewards',
		}),
		imageSrcMobile: QuestsMobile,
		imageSrcDesktop: QuestsDesktop,
	},
	{
		id: 'completeProfile',
		title: declareFormat({
			id: 'channel.dashboard.tip.completeProfile.title',
			defaultFormat: 'Tell something about yourself',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.completeProfile.subtitle',
			defaultFormat: 'Find people with the same interests',
		}),
		imageSrcMobile: ProfilMobile,
		imageSrcDesktop: ProfilDesktop,
	},
	{
		id: 'openSettings',
		title: declareFormat({
			id: 'channel.dashboard.tip.openSettings.title',
			defaultFormat: 'Customize Knuddels to your liking',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.openSettings.subtitle',
			defaultFormat: 'Set up Knuddels the way you want it',
		}),
		imageSrcMobile: EinstellungenMobile,
		imageSrcDesktop: EinstellungenDesktop,
	},
	{
		id: 'photoMeet',
		title: declareFormat({
			id: 'channel.dashboard.tip.photoMeet.title',
			defaultFormat: 'Meet new people',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.photoMeet.subtitle',
			defaultFormat: 'Check out Fotomeet and meet new people',
		}),
		imageSrcMobile: FotomeetMobile,
		imageSrcDesktop: FotomeetDesktop,
	},
	{
		id: 'enterCrash',
		title: declareFormat({
			id: 'channel.dashboard.tip.enterCrash.title',
			defaultFormat: 'Visit the Crash Channel',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.enterCrash.subtitle',
			defaultFormat: 'Find your new favorite game and chat',
		}),
		imageSrcMobile: CrashMobile,
		imageSrcDesktop: CrashDesktop,
	},
	{
		id: 'tryLoyaltySystem',
		title: declareFormat({
			id: 'channel.dashboard.tip.tryLoyaltySystem.title',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.tryLoyaltySystem.subtitle',
			defaultFormat: '',
		}),
		imageSrcMobile: LoyaltySystemMobile,
		imageSrcDesktop: LoyaltySystemDesktop,
	},
	{
		id: 'playWeltreise',
		title: declareFormat({
			id: 'channel.dashboard.tip.playWeltreise.title',
			defaultFormat: 'Play Weltreise',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.playWeltreise.subtitle',
			defaultFormat: "It's time for your online vacation, come by",
		}),
		imageSrcMobile: WeltreiseMobile,
		imageSrcDesktop: WeltreiseDesktop,
	},
	{
		id: 'enterDiceOne',
		title: declareFormat({
			id: 'channel.dashboard.tip.enterDiceOne.title',
			defaultFormat: 'Discover DiceOne',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.enterDiceOne.subtitle',
			defaultFormat: 'Many exciting games are waiting for you here',
		}),
		imageSrcMobile: DiceOneMobile,
		imageSrcDesktop: DiceOneDesktop,
	},
	{
		id: 'enterDiceSky',
		title: declareFormat({
			id: 'channel.dashboard.tip.enterDiceSky.title',
			defaultFormat: 'Discover DiceSky',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.enterDiceSky.subtitle',
			defaultFormat: 'Many exciting games are waiting for you here',
		}),
		imageSrcMobile: DiceSkyMobile,
		imageSrcDesktop: DiceSkyDesktop,
	},
	{
		id: 'allowNotifications',
		title: declareFormat({
			id: 'channel.dashboard.tip.allowNotifications.title',
			defaultFormat: "Don't miss anything",
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.allowNotifications.subtitle',
			defaultFormat: 'Turn on notifications and never miss anything!',
		}),
		imageSrcMobile: NotificationsMobile,
		imageSrcDesktop: NotificationsDesktop,
	},
	{
		id: 'openAppsAndGames',
		title: declareFormat({
			id: 'channel.dashboard.tip.openAppsAndGames.title',
			defaultFormat: 'Discover Apps and Games',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.openAppsAndGames.subtitle',
			defaultFormat: 'Many exciting games are waiting for you here',
		}),
		imageSrcMobile: AppsuSpieleMobile,
		imageSrcDesktop: AppsuSpieleDesktop,
	},
	{
		id: 'lowKnuddel',
		repeating: true,
		title: declareFormat({
			id: 'channel.dashboard.tip.lowKnuddel.title',
			defaultFormat: 'Low Knuddel Balance',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.lowKnuddel.subtitle',
			defaultFormat: 'Refill your Knuddel account now',
		}),
		imageSrcMobile: KnuddelstandMobile,
		imageSrcDesktop: KnuddelstandDesktop,
	},
	{
		id: 'openSmileyExchange',
		title: declareFormat({
			id: 'channel.dashboard.tip.openSmileyExchange.title',
			defaultFormat: 'Discover the Smiley Exchange',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.openSmileyExchange.subtitle',
			defaultFormat: 'Find your favorite smiley now',
		}),
		imageSrcMobile: SmileyboerseMobile,
		imageSrcDesktop: SmileyboerseDesktop,
	},
	{
		id: 'pickUpCompletedQuest',
		repeating: true,
		title: declareFormat({
			id: 'channel.dashboard.tip.pickUpCompletedQuest.title',
			defaultFormat: 'Complete your Quests',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.pickUpCompletedQuest.subtitle',
			defaultFormat: 'Collect your points now and keep collecting',
		}),
		imageSrcMobile: Quests2Mobile,
		imageSrcDesktop: Quests2Desktop,
	},
	{
		id: 'openContactFilter',
		title: declareFormat({
			id: 'channel.dashboard.tip.openContactFilter.title',
			defaultFormat: 'Who should be able to message you?',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.openContactFilter.subtitle',
			defaultFormat:
				'Set your contact filter so that you feel comfortable',
		}),
		imageSrcMobile: KontaktfilterMobile,
		imageSrcDesktop: KontaktfilterDesktop,
	},
	{
		id: 'changeMessageDesign',
		title: declareFormat({
			id: 'channel.dashboard.tip.changeMessageDesign.title',
			defaultFormat: 'Customize chat design',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.changeMessageDesign.subtitle',
			defaultFormat: 'Set how the messages should look in the chat room',
		}),
		imageSrcMobile: SprechblasenMobile,
		imageSrcDesktop: SprechblasenDesktop,
	},
	{
		id: 'changeAccentColor',
		title: declareFormat({
			id: 'channel.dashboard.tip.changeAccentColor.title',
			defaultFormat: 'Choose your favorite color',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.changeAccentColor.subtitle',
			defaultFormat:
				'Set the color in which Knuddels should shine for you',
		}),
		imageSrcMobile: AkzentfarbeMobile,
		imageSrcDesktop: AkzentfarbeDesktop,
	},
	{
		id: 'changeDynamicNavSlot',
		title: declareFormat({
			id: 'channel.dashboard.tip.changeDynamicNavSlot.title',
			defaultFormat: 'Personalize the navigation',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.changeDynamicNavSlot.subtitle',
			defaultFormat: 'Set what should be displayed in the navigation',
		}),
		imageSrcMobile: NavigationSlotMobile,
		imageSrcDesktop: NavigationSlotDesktop,
	},
	{
		id: 'changeTheme',
		title: declareFormat({
			id: 'channel.dashboard.tip.changeTheme.title',
			defaultFormat: 'Light or dark?',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.changeTheme.subtitle',
			defaultFormat:
				'Set whether you want to use Knuddels in dark or light mode',
		}),
		imageSrcMobile: LightthemeMobile,
		imageSrcDesktop: LightthemeDesktop,
	},
	{
		id: 'changeTextSize',
		title: declareFormat({
			id: 'channel.dashboard.tip.changeTextSize.title',
			defaultFormat: 'Adjust font size',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.changeTextSize.subtitle',
			defaultFormat: 'Set how large you want the font in Knuddels to be',
		}),
		imageSrcMobile: SchriftgroesseMobile,
		imageSrcDesktop: SchriftgroesseDesktop,
	},
	{
		id: 'knuddelsPlus',
		title: declareFormat({
			id: 'channel.dashboard.tip.knuddelsPlus.title',
			defaultFormat: '',
		}),
		subtitle: declareFormat({
			id: 'channel.dashboard.tip.knuddelsPlus.subtitle',
			defaultFormat: '',
		}),
		imageSrcMobile: KnuddelsPlusMobile,
		imageSrcDesktop: KnuddelsPlusDesktop,
	},
] as const satisfies (DashboardTipSchema & { id: string })[];

export type DashboardTipId = (typeof dashboardTips)[number]['id'];
