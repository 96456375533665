import * as React from 'react';
import { Container } from './K3Container';
import { Omit } from '@knuddels-app/tools/types';
import { Disposable } from '@knuddels/std';
import { newServiceId } from './ServiceId';
import { ServiceIdMap, TranslateServiceIds } from './getServices';
import { observer } from 'mobx-react-lite';
import { observable, runInAction } from '@knuddels-app/mobx';
import { InternalContainerContext } from './ContainerContext';
export interface IModel {
  /**
   * Notifies the model that the component has mounted.
   * Should only be used when interacting with the DOM.
   */
  componentDidMount?(): void;

  /**
   * Releases all resources allocated by this model like event listeners or timers.
   */
  dispose?(): void;

  /**
   * Resumes state from a previous model when the model got hot-reloaded.
   * `previousModel` will not be used anymore after that.
   */
  resumeFrom?(previousModel: this): void;
}
export type ModelType<TModelProps, TModel> = new (props: TModelProps, ...rest: any[]) => TModel;
export interface DeclaredProps<T> {
  props: T;
}
/**
 * Used to declare props for an injected component in addition to the props from its model.
 */
export function declareProps<T>(): DeclaredProps<T> {
  return {
    props: null!
  };
}

/**
 * The service id used to inject props into the model.
 */
export const $props = newServiceId<unknown>('$props');
interface InjectedComponentOptions {
  name: string;
}
type TranslateModelToProps<TModel> = {
  model: TModel extends IModel ? Omit<TModel, keyof IModel> : TModel;
};

/**
 * Creates a new identical type out of `T` so that typescript cannot use it for type inference.
 */
type Simplify<T> = (T extends string ? {
  T: string;
} : {
  T: T;
})['T'];

/**
 * Creates an injected component with a model.
 */
export function injectedComponent<TModelProps extends object, TModel, TProps extends object = {}, TInjects extends ServiceIdMap = {}>(options: InjectedComponentOptions & {
  model?: ModelType<TModelProps, TModel>;
  props?: DeclaredProps<TProps>;
  inject?: TInjects;
}, Component: React.ComponentType<
// The component has access to the model,
TranslateModelToProps<TModel> &
// ...to injected services
TranslateServiceIds<TInjects> &
// and to declared props. But `props` should not be inferred from this, thus `Simplify`.
Simplify<TProps> & { [TKey in keyof TModelProps]: unknown } // ...but not to model props. They are `unknown` and should be declared with `declareProps`.
>): React.FunctionComponent<TProps & TModelProps> & {
  Wrapped: React.ComponentType<
  // The component has access to the model,
  TranslateModelToProps<TModel> &
  // ...to injected services
  TranslateServiceIds<TInjects> &
  // and to declared props. But `props` should not be inferred from this, thus `Simplify`.
  Simplify<TProps> & { [TKey in keyof TModelProps]: unknown } // ...but not to model props. They are `unknown` and should be declared with `declareProps`.
  >;
  // Is about { model: TModel }
  TPropsWithModel: TranslateModelToProps<TModel>;
  // Are all input props combined.
  TProps: TProps & TModelProps;
} {
  Component.displayName = options.name;
  const LatestComponent = observer((Component as any));
  const modelType = options.model;
  const injectingComponent = function (props: TProps): JSX.Element {
    const container = React.useContext(InternalContainerContext);
    if (!container) {
      throw new Error('Container must be provided');
    }
    const observableModelProps = useObservableProps(props, options.name);
    const model = useModel((observableModelProps as any), (modelType as any), container);
    const modelProps = model ? {
      model
    } : {};
    let injectProps = {};
    try {
      injectProps = options.inject ? container.getServices(options.inject) : {};
    } catch (e) {
      throw new Error('Failed to create injected component ' + options.name + ': ' + e);
    }
    return <LatestComponent {...props} {...injectProps} {...modelProps} />;
  };
  injectingComponent.displayName = options.name + 'Injected';
  injectingComponent.Wrapped = Component;
  return Object.assign(observer<TProps & TModelProps>(injectingComponent), {
    TPropsWithModel: ((null as any) as TranslateModelToProps<TModel>),
    TProps: ((null as any) as TProps & TModelProps),
    Wrapped: Component,
    isInjectedComponent: true
  });
}

// This is a react hook and called on every render.
function useObservableProps<TProps>(props: TProps, name: string): TProps {
  const observableModelProps = React.useMemo<TProps>(() => observable((props as any), {}, {
    name: `${name}.props`,
    deep: false
  }), []);

  // We need to update the observable props whenever props changes.
  // useLayoutEffect causes a temporary inconsistent state between props and model.props.
  // Only after useLayoutEffect is called, they will match again.
  // If the props are updated without using `useLayoutEffect`,
  // react might issue a warning, as it could cause components
  // to update while we currently render a component.
  React.useLayoutEffect(() => {
    runInAction(`Update props of "${name}"`, () => {
      Object.assign((observableModelProps as any), props);
    });
  }, [props]);
  return observableModelProps;
}

/**
 * A react hook that provides a model instance of a given model type.
 * Model is recreated if `modelType` changes.
 */
function useModel<TModelProps, TModel extends IModel>(props: TModelProps, modelType: ModelType<TModelProps, TModel> | undefined, container: Container): TModel | undefined {
  const modelRef = React.useRef<{
    model: TModel | undefined;
    modelType: ModelType<any, any> | undefined;
  }>({
    model: undefined,
    modelType
  });
  const cur = modelRef.current;
  if (modelType && (!cur.model || cur.modelType !== modelType)) {
    const innerContainer = container.createSubContainer().inversifyContainer;
    innerContainer.bind($props.id).toConstantValue(props);
    innerContainer.bind(modelType).toSelf();
    let newModel: TModel;
    try {
      newModel = (innerContainer.get(modelType) as TModel);
    } catch (e) {
      throw new Error('Failed to create injected component model ' + modelType.name + ': ' + e);
    }
    if (cur.model && newModel.resumeFrom) {
      newModel.resumeFrom(cur.model);
    }
    cur.model = newModel;
    cur.modelType = modelType;
  }
  React.useEffect(() => {
    if (cur.model && 'componentDidMount' in cur.model) {
      (cur.model as any).componentDidMount();
    }
    return () => {
      if (Disposable.is(cur.model)) {
        cur.model.dispose();
      }
    };
  }, [cur.model]);
  return cur.model;
}