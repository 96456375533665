import * as React from 'react';
import { CommunityStatus, UserForProfileFragment } from '@generated/graphql';
import { FormattedText, Row } from '@shared/components';
import { translateChildren, translateCommunityStatus, translateCountry, translateRelationshipStatus, translateSexualOrientation, translateSmoker } from '../../entryValueTranslations';
import { $I18n, declareFormat, FormatId, formatMessage, FormattedDateTime, FormattedMessage } from '@knuddels-app/i18n';
import { CurrentUserKnuddelDisplayLazy } from '@knuddelsModules/KnuddelDisplay';
import { getFormattedDate } from '@knuddels-app/tools/getDateDifference';
import { Text } from '@knuddels/component-library';
import entryHobbies from '../../../assets/profile-entry-hobbies.png';
import entryMusic from '../../../assets/profile-entry-music.png';
import entryMovies from '../../../assets/profile-entry-movies.png';
import entrySeries from '../../../assets/profile-entry-series.png';
import entryBooks from '../../../assets/profile-entry-books.png';
import entryReadme from '../../../assets/profile-entry-readme.png';
import entryName from '../../../assets/profile-entry-name.png';
import entryBirthday from '../../../assets/profile-entry-birthday.png';
import entryRelationship from '../../../assets/profile-entry-relationship.png';
import entryOrientation from '../../../assets/profile-entry-orientation.png';
import entryChildren from '../../../assets/profile-entry-children.png';
import entrySmoker from '../../../assets/profile-entry-smoker.png';
import entryCountry from '../../../assets/profile-entry-country.png';
import entryPLZ from '../../../assets/profile-entry-plz.png';
import entryLanguage from '../../../assets/profile-entry-language.png';
import { formatAmount } from '@knuddels-app/tools/formatAmount';
import { useService } from '@knuddels-app/DependencyInjection';
export type ProfileEntryData = ({
  type: 'formattedText';
  content: (user: UserForProfileFragment) => FormattedText | undefined;
} | {
  type: 'text';
  content: (user: UserForProfileFragment) => string | undefined;
} | {
  type: 'link';
  content: (user: UserForProfileFragment) => {
    text: string;
    slashCommand?: string;
  } | undefined;
} | {
  type: 'number' | 'date' | 'time';
  content: (user: UserForProfileFragment) => number | undefined;
} | {
  type: 'textList';
  content: (user: UserForProfileFragment) => readonly string[] | undefined;
} | {
  type: 'linkList';
  content: (user: UserForProfileFragment) => readonly {
    text: string;
    slashCommand: string;
  }[] | undefined;
} | {
  type: 'channel';
  content: (user: UserForProfileFragment) => string | undefined;
} | {
  type: 'channels';
  content: (user: UserForProfileFragment) => readonly string[] | undefined;
} | {
  type: 'nick';
  content: (user: UserForProfileFragment) => string | undefined;
} | {
  type: 'nicks';
  content: (user: UserForProfileFragment) => readonly string[] | undefined;
} | {
  type: 'custom';
  content: (user: UserForProfileFragment, isCurrentUser: boolean) => React.ReactNode;
}) & {
  titleFormatId: FormatId;
  icon?: ((user: UserForProfileFragment) => string) | string;
};
export type ProfileCategory = {
  headerFormatId?: FormatId;
  entries: ProfileEntryData[];
};
export const detailCategories: ProfileCategory[] = [{
  entries: [{
    titleFormatId: declareFormat({
      id: 'ENTRY_README',
      defaultFormat: 'Readme'
    }),
    icon: entryReadme,
    type: 'formattedText',
    content: user => {
      return user.readMe && FormattedText.fromJsonString(user.readMe);
    }
  }]
}, {
  headerFormatId: declareFormat({
    id: 'CATEGORY_GENERAL',
    defaultFormat: 'General'
  }),
  entries: [{
    titleFormatId: declareFormat({
      id: 'ENTRY_NAME',
      defaultFormat: 'Name'
    }),
    icon: entryName,
    type: 'formattedText',
    content: user => user.nameFormatted && FormattedText.fromJsonString(user.nameFormatted)
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_DATE_OF_BIRTH',
      defaultFormat: 'Date of birth'
    }),
    icon: entryBirthday,
    type: 'date',
    content: user => +user.dateOfBirth
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_RELATIONSHIP_STATUS',
      defaultFormat: 'Relationship status'
    }),
    icon: entryRelationship,
    type: 'text',
    content: user => translateRelationshipStatus(user.relationshipStatus)
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_SEXUAL_ORIENTATION',
      defaultFormat: 'Sexual orientation'
    }),
    icon: entryOrientation,
    type: 'text',
    content: user => translateSexualOrientation(user.sexualOrientation)
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_CHILDREN',
      defaultFormat: 'Children'
    }),
    icon: entryChildren,
    type: 'text',
    content: user => translateChildren(user.children)
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_SMOKER',
      defaultFormat: 'Smoker'
    }),
    icon: entrySmoker,
    type: 'text',
    content: user => translateSmoker(user.smoker)
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_COUNTRY',
      defaultFormat: 'Country'
    }),
    icon: entryCountry,
    type: 'text',
    content: user => translateCountry(user.countryEnum)
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_CITY',
      defaultFormat: 'City'
    }),
    icon: entryPLZ,
    type: 'text',
    content: user => user.city
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_LANGUAGES',
      defaultFormat: 'Languages'
    }),
    icon: entryLanguage,
    type: 'textList',
    content: user => user.languages
  }]
}, {
  headerFormatId: declareFormat({
    id: 'CATEGORY_LIFESTYLE',
    defaultFormat: 'Lifestyle'
  }),
  entries: [{
    titleFormatId: declareFormat({
      id: 'ENTRY_HOBBIES',
      defaultFormat: 'Hobbys'
    }),
    icon: entryHobbies,
    type: 'textList',
    content: user => user.hobbies
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_MUSIC',
      defaultFormat: 'Music'
    }),
    icon: entryMusic,
    type: 'textList',
    content: user => user.music
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_MOVIES',
      defaultFormat: 'Movies'
    }),
    icon: entryMovies,
    type: 'textList',
    content: user => user.movies
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_SERIES',
      defaultFormat: 'Series'
    }),
    icon: entrySeries,
    type: 'textList',
    content: user => user.series
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_BOOKS',
      defaultFormat: 'Books'
    }),
    icon: entryBooks,
    type: 'textList',
    content: user => user.books
  }]
}];
export const knuddelsCategories: ProfileCategory[] = [{
  headerFormatId: declareFormat({
    id: 'CATEGORY_ACTIVITY',
    defaultFormat: 'Activity'
  }),
  entries: [{
    titleFormatId: declareFormat({
      id: 'ENTRY_LAST_ONLINE_TIME',
      defaultFormat: 'Last online'
    }),
    icon: user => user.isOnline ? require('../../../assets/profile-entry-last-online.png') : require('../../../assets/profile-entry-last-online-offline.png'),
    type: 'custom',
    content: user => {
      const lastOnlineTime = +user.lastOnlineTime;
      if (user.isOnline) {
        return <Text type={'body1'} className={_c0}>
								<FormattedMessage id={declareFormat({
            id: 'profile.last-online.now',
            defaultFormat: 'Online right now'
          })} />
							</Text>;
      } else if (lastOnlineTime <= 0) {
        return <Text type={'body1'} className={_c1}>-</Text>;
      } else {
        const date = getFormattedDate(lastOnlineTime).toLowerCase();
        return <Text type={'body1'} className={_c2}>
								{date + ' ('}
								<FormattedDateTime value={lastOnlineTime} hour={'numeric'} minute={'numeric'} />
								{')'}
							</Text>;
      }
    }
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_LAST_CHANNEL',
      defaultFormat: 'Last Channel'
    }),
    icon: require('../../../assets/profile-entry-last-channel.png'),
    type: 'channel',
    content: user => {
      const lastOnlineTime = +user.lastOnlineTime;
      return lastOnlineTime > 0 ? user.latestOnlineChannelName : '-';
    }
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_KNUDDEL',
      defaultFormat: 'Knuddel'
    }),
    icon: require('@shared/images/knuddel.png'),
    type: 'custom',
    content: (user, isCurrentUser) => isCurrentUser ? <Row>
							<CurrentUserKnuddelDisplayLazy type={'amountOnly'} numberFormat={'none'} firebaseContentType={'Profile_OwnProfileView'} />
						</Row> : typeof user.knuddelAmount === 'number' ? <KnuddelAmount amount={user.knuddelAmount} /> : null
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_ONLINE_MINUTES',
      defaultFormat: 'Online minutes'
    }),
    icon: require('../../../assets/profile-entry-time.png'),
    type: 'number',
    content: user => user.onlineMinutes
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_DATE_OF_REGISTRATION',
      defaultFormat: 'Date of registration'
    }),
    icon: require('../../../assets/profile-entry-reg-date.png'),
    type: 'date',
    content: user => +user.dateOfRegistration
  }]
}, {
  headerFormatId: declareFormat({
    id: 'CATEGORY_COMMUNITY',
    defaultFormat: 'Community'
  }),
  entries: [{
    titleFormatId: declareFormat({
      id: 'ENTRY_TEAMS',
      defaultFormat: 'Teams'
    }),
    icon: require('../../../assets/profile-entry-teams.png'),
    type: 'linkList',
    content: user => user.teams.map(team => {
      return {
        text: team,
        slashCommand: `/hilfe ${getHelpcenterLinkForTeam(team)}`
      };
    })
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_COMMUNITY_STATUS',
      defaultFormat: 'Community-status'
    }),
    icon: require('@shared/images/member-status.png'),
    type: 'link',
    content: user => {
      let slashCommand: string = undefined;
      switch (user.status) {
        case CommunityStatus.Stammi:
          slashCommand = '/hilfe https://hilfe.knuddels.de/de/articles/3771877-was-ist-ein-status-und-wie-kriege-ich-den';
          break;
        case CommunityStatus.Family:
          slashCommand = '/hilfe https://hilfe.knuddels.de/de/articles/3771877-was-ist-ein-status-und-wie-kriege-ich-den';
          break;
        case CommunityStatus.Admin:
          slashCommand = '/hilfe https://hilfe.knuddels.de/de/articles/4255467-wahlen-zum-admin-und-cm-amt';
          break;
        default:
          break;
      }
      const text = translateCommunityStatus(user.status);
      if (!text) {
        return undefined;
      }
      return {
        text,
        slashCommand
      };
    }
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_CLT',
      defaultFormat: 'Channel lead'
    }),
    icon: require('../../../assets/icon_clt.png'),
    type: 'channels',
    content: user => user.channelLeadChannelNames
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_CM',
      defaultFormat: 'Channel moderator'
    }),
    icon: require('../../../assets/icon_cm.png'),
    type: 'channel',
    content: user => user.moderatedChannelName
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_MCM',
      defaultFormat: 'MyChannel moderator'
    }),
    icon: require('../../../assets/icon_mcm.png'),
    type: 'channels',
    content: user => user.moderatedMyChannelNames
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_ADMIN_PERIODS',
      defaultFormat: 'Admin periods'
    }),
    icon: require('../../../assets/profile-entry-stammi-months.png'),
    type: 'number',
    content: user => user.adminPeriods
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_CLT_ELECTION_COUNT',
      defaultFormat: 'Election for CLT'
    }),
    icon: require('../../../assets/profile-entry-stammi-months.png'),
    type: 'number',
    content: user => user.channelLeadElectionCount
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_CM_MONTHS',
      defaultFormat: 'CM months'
    }),
    icon: require('../../../assets/profile-entry-stammi-months.png'),
    type: 'number',
    content: user => user.channelModeratorMonths
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_MCM_MONTHS',
      defaultFormat: 'MCM months'
    }),
    icon: require('../../../assets/profile-entry-stammi-months.png'),
    type: 'number',
    content: user => user.myChannelModeratorMonths
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_STAMMI_MONTHS',
      defaultFormat: 'Stammi months'
    }),
    icon: require('../../../assets/profile-entry-stammi-months.png'),
    type: 'number',
    content: user => user.stammiMonths
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_FAVOURITE_CHANNEL',
      defaultFormat: 'Favourite channel'
    }),
    icon: 'https://cdnc.knuddelscom.de/pics/icons-profil/icon_mychannel.png',
    type: 'channel',
    content: user => user.favouriteChannelName
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_FAVOURITE_MY_CHANNEL',
      defaultFormat: 'Favourite MyChannel'
    }),
    icon: 'https://cdnc.knuddelscom.de/pics/icons-profil/icon_mychannel.png',
    type: 'channel',
    content: user => user.favouriteMyChannelName
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_MY_CHANNEL',
      defaultFormat: 'MyChannel'
    }),
    icon: require('../../../assets/profile-entry-mychannel.png'),
    type: 'channel',
    content: user => user.myChannelName
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_HARD_CORE_CHANNELS',
      defaultFormat: 'Inner Circle'
    }),
    icon: 'https://cdnc.knuddelscom.de/pics/icons-profil/icon_seed.png',
    type: 'channels',
    content: user => user.hardCoreChannelNames
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_SUPPORTS_KNUDDELS_PHILOSOPHY',
      defaultFormat: 'Supports the'
    }),
    icon: require('../../../assets/profile-entry-philosophy.png'),
    type: 'text',
    content: user => user.supportsKnuddelsPhilosophy ? formatMessage(declareFormat({
      id: 'ENTRY_SUPPORTS_KNUDDELS_PHILOSOPHY_VALUE',
      defaultFormat: 'Knuddels-Philosophy'
    })) : undefined
  }]
}, {
  headerFormatId: declareFormat({
    id: 'CATEGORY_ACHIEVEMENTS',
    defaultFormat: 'Achievements'
  }),
  entries: [{
    titleFormatId: declareFormat({
      id: 'ENTRY_HICKEYS',
      defaultFormat: 'Hickeys'
    }),
    icon: require('../../../assets/profile-entry-hickeys.png'),
    type: 'number',
    content: user => user.hickeys
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_ROSES',
      defaultFormat: 'Received roses'
    }),
    icon: require('../../../assets/profile-entry-roses.png'),
    type: 'number',
    content: user => user.roses
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_RECEIVED_HEARTS',
      defaultFormat: 'Received hearts'
    }),
    icon: require('../../../assets/profile-entry-hearts.png'),
    type: 'nicks',
    content: user => user.receivedHearts
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_GIVEN_HEART',
      defaultFormat: 'Gave the heart to'
    }),
    icon: require('../../../assets/profile-entry-heart.png'),
    type: 'nick',
    content: user => user.givenHeart
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_MENTOR_POINTS',
      defaultFormat: 'Mentor points'
    }),
    icon: require('../../../assets/profile-entry-mentor.png'),
    type: 'number',
    content: user => user.mentorPoints
  }, {
    titleFormatId: declareFormat({
      id: 'ENTRY_QUEST_TITLE',
      defaultFormat: 'Quests'
    }),
    icon: require('../../../assets/profile-entry-quests.png'),
    type: 'text',
    content: user => user.engagementSystemLevel ? formatMessage(declareFormat({
      id: 'ENTRY_QUEST_LEVEL',
      defaultFormat: 'Level {level}'
    }), {
      level: user.engagementSystemLevel
    }) : undefined
  }]
}];
export const KnuddelAmount: React.FC<{
  amount: number;
}> = ({
  amount
}) => {
  const i18n = useService($I18n);
  return <Text className={_c3}>{formatAmount(amount, 'none', i18n)}</Text>;
};
// tslint:disable-next-line: max-file-line-count

export const getHelpcenterLinkForTeam = (teamString: string): string => {
  const team = teamString.replace(/\s*\([^)]*\)\s*/g, '').trim();
  switch (team) {
    case 'Admin':
      return 'https://hilfe.knuddels.de/de/articles/9901498-das-admin-team';
    case 'Admin-FAQ':
      return 'https://hilfe.knuddels.de/de/articles/9901510-das-admin-faq-team';
    case 'Admin-Macro':
      return 'https://hilfe.knuddels.de/de/articles/9901515-das-admin-macro-team';
    case 'AntiExtremismus':
      return 'https://hilfe.knuddels.de/de/articles/9901520-das-anti-extremismus-team';
    case 'Bugs':
      return 'https://hilfe.knuddels.de/de/articles/9901526-das-bugs-team';
    case 'Channelleitung':
      return 'https://hilfe.knuddels.de/de/articles/7973293-channelleitung-clt';
    case 'CLT-Managment':
      return 'https://hilfe.knuddels.de/de/articles/9901642-das-clt-management';
    case 'Community Management':
      return 'https://hilfe.knuddels.de/de/articles/9901537-das-community-management';
    case 'Forum':
      return 'https://hilfe.knuddels.de/de/articles/9901545-das-forum-team';
    case 'Jugendschutz':
      return 'https://hilfe.knuddels.de/de/articles/9901552-das-jugendschutz-team';
    case 'Knuddels-Wiki':
      return 'https://hilfe.knuddels.de/de/articles/9901564-das-knuddels-wiki-team';
    case 'MyChannel':
      return 'https://hilfe.knuddels.de/de/articles/9901576-das-mychannel-team';
    case 'Profil':
      return 'https://hilfe.knuddels.de/de/articles/9901581-das-profil-team';
    case 'Spiele':
      return 'https://hilfe.knuddels.de/de/articles/9901593-das-spiele-team';
    case 'Smileys':
      return 'https://hilfe.knuddels.de/de/articles/9901588-das-smileys-team';
    case 'Veranstaltungen':
      return 'https://hilfe.knuddels.de/de/articles/9901600-das-veranstaltungen-team';
    case 'Verknuddelichung':
      return 'https://hilfe.knuddels.de/de/articles/9901607-das-verknuddelichung-team';
    case 'Vertrauensadmin':
      return 'https://hilfe.knuddels.de/de/articles/9901616-das-vertrauensadmin-team';
    case 'Knuddelsteam':
      return 'https://hilfe.knuddels.de/de/articles/9901570-das-knuddelsteam';
    case 'Chattertreffen':
    case 'Chattertreffen (Teamleitung)':
      return 'https://hilfe.knuddels.de/de/articles/10405251-team-chattertreffen-werde-teil-des-community-herzschlags';
    default:
      return teamString;
  }
};
const _c0 = "  ";
const _c1 = "  ";
const _c2 = "  ";
const _c3 = "  ";