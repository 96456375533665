import { K3ServiceBinder } from '@knuddels-app/DependencyInjection';
import * as ids from '../providedServices';
import { foreachProp } from '@knuddels/std';
import { ExploreComponents, DashboardService } from './services';

export function registerServices(ctx: K3ServiceBinder): void {
	foreachProp(ids, {
		$ExploreComponents:
			ctx.loggedInScope.toSingletonAutostart(ExploreComponents),

		$DashboardService: ctx.loggedInScope.toSingleton(DashboardService),
	});
}
