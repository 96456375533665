import { jsx as e } from "react/jsx-runtime";
import { Flex as s } from "./Flex.js";
import { ModalWrapper as u } from "./ModalWrapper.js";
import { ChildThemeProvider as a } from "../../themes/ThemeProvider.js";
import { useTheme as P } from "../../themes/useTheme.js";
const x = ({
  children: i,
  onPress: o,
  render: t = !0,
  pointerEvents: n = "all",
  backgroundStyle: m = "opaque",
  closeCallbackType: r = "onPress",
  zIndex: p,
  innerRef: d
}) => {
  const h = P();
  return /* @__PURE__ */ e(
    u,
    {
      render: t,
      innerRef: d,
      zIndex: p,
      pointerEvents: n,
      children: /* @__PURE__ */ e(a, { theme: h, children: /* @__PURE__ */ e(
        s,
        {
          bg: m === "opaque" ? "backgroundScrim" : "transparent",
          position: "absolute",
          width: "100%",
          height: "100%",
          onPress: r === "onPress" ? o : void 0,
          onPointerDown: r === "onPointerDown" ? o : void 0,
          onPointerUp: r === "onPointerUp" ? o : void 0,
          children: i
        }
      ) })
    }
  );
};
export {
  x as Modal
};
