import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { ChannelGroupInfoFragment } from '@generated/graphql';
type Data = Pick<ChannelGroupInfoFragment, 'shortDescription' | 'isMyChannel' | 'mainCategoryName'>;
export const useChannelListItemSubline = (info: Data, shouldUseShortDescription: boolean) => {
  const i18n = useService($I18n);
  if (shouldUseShortDescription) {
    return info.shortDescription;
  }
  const myChannelSuffix = info.isMyChannel ? ' · ' + declareFormat({
    id: 'channellist.shortDescription.mychannel',
    defaultFormat: 'created by member'
  }).format(i18n) : '';
  const mainCategoryName = info.mainCategoryName;
  if (mainCategoryName) {
    return mainCategoryName + '-Channel' + myChannelSuffix;
  }
  if (info.isMyChannel) {
    return 'MyChannel' + myChannelSuffix;
  }
  return declareFormat({
    id: 'channellist.shortDescription.temporarychannel',
    defaultFormat: 'Temporary Channel'
  }).format(i18n) + myChannelSuffix;
};