import { useService } from '@knuddels-app/DependencyInjection';
import { observer } from '@knuddels-app/mobx';
import { FlexCol, isDarkColor, ThemeOverride, useTheme, Z_INDEX } from '@knuddels/component-library';
import { $MessengerMiniChatService } from '@knuddelsModules/Messenger';
import React from 'react';
import { MiniChat } from './MiniChat';
import { MiniChatConversationList } from './MiniChatConversationList';
import { canOtherParticipantsReceiveMessages } from './helper';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { isMobileOrNative } from '@knuddels-app/tools/isMobileOrNative';
import { $Environment } from '@knuddels-app/Environment';
import { $OverlayService } from '@knuddels-app/overlays';
import { $LocationService } from '@knuddels-app/location';
import { $ActiveChannelService } from '../../../../../Channel';
export const MiniChatOverlay: React.FC<{
  onClose: () => void;
}> = observer('MiniChatOverlay', ({
  onClose
}) => {
  const messengerMiniChatService = useService($MessengerMiniChatService);
  const listedConversations = messengerMiniChatService.conversations;
  const unlistedConversation = messengerMiniChatService.unlistedConversation;
  const environment = useService($Environment);
  const overlayService = useService($OverlayService);
  const locationService = useService($LocationService);
  const handleChildClick = (e: any) => {
    e.stopPropagation();
  };
  React.useEffect(() => {
    const index = locationService.pushHash('mini-chat', false);
    return locationService.historyListen(() => {
      if (history.state.idx < index) {
        onClose();
      }
    });
  }, []);
  React.useEffect(() => {
    const activeConversation = messengerMiniChatService.activeConversation || unlistedConversation;
    if ('type' in activeConversation || activeConversation.id) {
      messengerMiniChatService.setAutoFocus(canOtherParticipantsReceiveMessages(activeConversation) && messengerMiniChatService.shouldAutoFocus);
    }
  }, []);
  React.useEffect(() => {
    if (!messengerMiniChatService.activeConversationId) {
      onClose();
    }
  }, [messengerMiniChatService.activeConversationId]);
  React.useEffect(() => {
    const positionAtOpening = overlayService.overlays.length;
    const disposable = environment.nativeInterface?.prependBackHandler(() => {
      // if overlay length is the same, this means the mini chat is on top
      // if not, this means other overlays have been opened on top, so we don't want to close the mini chat
      if (overlayService.overlays.length === positionAtOpening) {
        onClose();
        return true;
      }
      return false;
    });
    return () => {
      disposable?.dispose();
    };
  }, []);
  return <MiniChatWrapper onClose={onClose}>
			<MiniChatConversationList conversations={listedConversations} />

			<MiniChat conversationId={messengerMiniChatService.activeConversationId} onClose={onClose} onPress={handleChildClick} />
		</MiniChatWrapper>;
});
const MiniChatWrapper: React.FC<{
  children: React.ReactNode;
  onClose: () => void;
}> = observer('MiniChatWrapper', ({
  children,
  onClose
}) => {
  const handleParentClick = (e: any) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  const theme = useTheme();
  const keyboardService = useService($KeyboardService);
  const activeChannelService = useService($ActiveChannelService);
  const keyboardHeight = isMobileOrNative() ? keyboardService.keyboardHeight : 0;
  const bgColor = activeChannelService.backgroundColorAsRgb ?? theme.colors.basic.titleBarBg;
  return <FlexCol position={'absolute'} width={'full'} height={'full'} pb={'tiny'} bg={isDarkColor(bgColor) ? 'transparentDark' : 'transparentLight'} onPress={handleParentClick} zIndex={Z_INDEX.OVERLAY} {...{
    style: {
      background: ('linear-gradient(' + bgColor + ',rgba(0,0,0,0.3))' as ThemeOverride)
    }
  }}>
			<div style={{
      maxHeight: `calc(100% - ${keyboardHeight}px)`
    }} className={_c0}>
				{children}
			</div>
		</FlexCol>;
});
const _c0 = " Knu-FlexCol size-full ";