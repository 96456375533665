import { ScreenWidth } from '@knuddels-app/Screen';
import { VisibleView } from './ViewService';
import { Position, ViewConfig, ViewId } from './ViewId';
import { notNull } from '@knuddels-app/tools/notNull';
import { observable } from '@knuddels-app/mobx';

// Later views have higher priority
export function layout({
	views,
	visibleViews,
	persistedViews,
	screenWidth,
	createVisibleView,
}: {
	views: ReadonlyArray<ViewConfig>;
	visibleViews: ReadonlyArray<VisibleView>;
	persistedViews: ReadonlyArray<VisibleView>;
	screenWidth: ScreenWidth;
	createVisibleView: (view: ViewConfig) => VisibleView;
}): Layout {
	const slots: Layout['viewsByPosition'] = {};
	const persistedViewsByPosition: Record<
		keyof Layout['viewsByPosition'],
		LaidoutView[]
	> = {
		Main: [],
		Side: [],
	};
	const overlays: VisibleView[] = [];

	const availableSlots = getAvailableLayoutPositions(screenWidth);

	function getLayoutPosition(position: Position): LayoutPosition {
		if (
			position === Position.Side &&
			availableSlots.has(LayoutPosition.Side)
		) {
			return LayoutPosition.Side;
		}
		return LayoutPosition.Main;
	}

	for (const persistedView of persistedViews) {
		const pos = getLayoutPosition(persistedView.viewConfig.position);
		persistedViewsByPosition[pos].push(
			new LaidoutView(
				persistedView,
				pos,
				persistedView.renderedView.mainView
			)
		);
	}

	for (const visibleView of visibleViews) {
		if (
			visibleView.viewConfig.requiredScreenWidths &&
			visibleView.viewConfig.requiredScreenWidths.every(
				w => w !== screenWidth
			)
		) {
			continue;
		}
		if (visibleView.viewConfig.position === Position.Overlay) {
			overlays.push(visibleView);
		} else {
			const pos = getLayoutPosition(visibleView.viewConfig.position);
			slots[pos] = new LaidoutView(
				visibleView,
				pos,
				visibleView.renderedView.mainView
			);
		}
	}

	const sortedViews = [...views].sort((v1, v2) => {
		return (v2.isDefaultPriority ?? 0) - (v1.isDefaultPriority ?? 0);
	});

	// open default views if the layout position is not taken yet.
	for (const view of sortedViews) {
		if (!view.isDefault?.()) {
			continue;
		}
		const pos = getLayoutPosition(view.position);
		if (!slots[pos]) {
			const visibleView = createVisibleView(view);
			slots[pos] = new LaidoutView(
				visibleView,
				pos,
				visibleView.renderedView.mainView
			);
		}
	}

	return new Layout({
		overlays,
		views: Object.values(slots).filter(notNull),
		persistedViewsByPosition,
	});
}

function getAvailableLayoutPositions(width: ScreenWidth): Set<LayoutPosition> {
	const p = {
		[ScreenWidth.XS]: [LayoutPosition.Main],
		[ScreenWidth.S]: [LayoutPosition.Main],
		[ScreenWidth.M]: [LayoutPosition.Main, LayoutPosition.Side],
		[ScreenWidth.L]: [LayoutPosition.Main, LayoutPosition.Side],
		[ScreenWidth.XL]: [LayoutPosition.Main, LayoutPosition.Side],
	};
	return new Set(p[width]);
}

export enum LayoutPosition {
	Side = 'Side',
	Main = 'Main',
}

export class LaidoutView {
	constructor(
		public readonly visibleView: VisibleView,
		public readonly position: LayoutPosition,
		public readonly view: React.ReactElement
	) {}

	get key(): string {
		return this.visibleView.viewConfig.viewId.id;
	}
}

export class Layout {
	@observable
	public readonly persistedViewsByPosition: Record<
		keyof Layout['viewsByPosition'],
		LaidoutView[]
	>;
	public readonly viewsByPosition: {
		[TPos in LayoutPosition]?: LaidoutView;
	} = {};

	public readonly overlays: VisibleView[] = [];

	@observable
	public additionalOverlays: VisibleView<any>[] = [];

	constructor({
		views,
		overlays,
		persistedViewsByPosition,
	}: {
		views: LaidoutView[];
		overlays: VisibleView[];
		persistedViewsByPosition: Record<
			keyof Layout['viewsByPosition'],
			LaidoutView[]
		>;
	}) {
		for (const v of views) {
			this.viewsByPosition[v.position] = v;
		}
		this.overlays = overlays;
		this.persistedViewsByPosition = persistedViewsByPosition;
	}

	public addPersistedView(view: VisibleView): void {
		const pos: LayoutPosition =
			view.viewConfig.position === Position.Side
				? LayoutPosition.Side
				: LayoutPosition.Main;

		if (
			this.persistedViewsByPosition[pos].find(v =>
				v.visibleView.viewConfig.viewId.equals(view.viewConfig.viewId)
			)
		) {
			return;
		}

		this.persistedViewsByPosition[pos].push(
			new LaidoutView(view, pos, view.renderedView.mainView)
		);
	}

	public get visibleViews(): ReadonlyArray<VisibleView> {
		const result = new Array<VisibleView>();
		for (const v of [LayoutPosition.Main, LayoutPosition.Side]) {
			const view = this.viewsByPosition[v];
			if (view) {
				result.push(view.visibleView);
			}
		}
		this.overlays.forEach(o => result.push(o));
		return result;
	}

	public find(view: VisibleView<any>): LaidoutView[] {
		return Object.values(this.viewsByPosition)
			.filter(val => val && val.visibleView === view)
			.filter(notNull);
	}

	public isViewPersisted(
		viewId: ViewId<any>,
		position: LayoutPosition
	): boolean {
		return this.persistedViewsByPosition[position].some(
			v => v.visibleView.viewConfig.viewId.id === viewId.id
		);
	}

	public isViewInBackground(
		viewId: ViewId<any>,
		position: LayoutPosition
	): boolean {
		return (
			this.viewsByPosition[position]?.visibleView.viewConfig.viewId.id !==
				viewId.id && this.isViewPersisted(viewId, position)
		);
	}

	public hasOpenSideView(): boolean {
		return this.viewsByPosition[LayoutPosition.Side] !== undefined;
	}

	public isViewActive(
		viewId: ViewId<any>,
		position: LayoutPosition
	): boolean {
		return (
			this.viewsByPosition[position]?.visibleView.viewConfig.viewId.id ===
			viewId.id
		);
	}

	public equals(other: Layout | undefined): boolean {
		if (!other) {
			return false;
		}

		if (other.overlays.length !== this.overlays.length) {
			return false;
		}

		for (const index in this.overlays) {
			if (!this.overlays[index].equals(other.overlays[index])) {
				return false;
			}
		}

		for (const p of Object.values(LayoutPosition)) {
			const a = this.viewsByPosition[p];
			const b = other.viewsByPosition[p];
			if (typeof a !== typeof b) {
				return false;
			}
			if (
				a &&
				b &&
				(a.position !== b.position ||
					a.view !== b.view ||
					a.visibleView !== b.visibleView)
			) {
				return false;
			}
		}
		return true;
	}
}
