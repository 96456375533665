import { calculateGradient, createAccessiblePointerEventHandler, Flex, FlexCol, IconMoreHorizontal, parseRgbString, Text, ThemeColors, useTheme, FallbackBox, createNativeAccessiblePointerEventHandler } from '@knuddels/component-library';
import * as React from 'react';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { useService } from '@knuddels-app/DependencyInjection';
import { type NormalizedNavBarItem } from '@knuddelsModules/LoggedInArea/bundle/services';
import { $LoggedInAreaService, $NavBarRegistry } from '@knuddelsModules/LoggedInArea';
import { TextStates } from '@shared/components/atoms/Texts/TextProps';
import { useReactiveState } from '@knuddels-app/tools/useReactiveState';
import { distinct } from '@knuddels/std';
import { FormattedMessage } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels/i18n';
import { $UserService } from '@knuddelsModules/UserData';
import { CommunityStatus } from '@generated/graphql';
import { useMemo } from 'react';
import { useCachedWidth } from '@knuddels-app/tools/useCachedElementWidth';
import { $ViewService } from '@knuddels-app/layout';
import { $AppService } from '../../../Apps';
import { K3_SIDEBAR_SID } from '@shared/constants';
type ItemType = {
  type: 'view';
  navItem: NormalizedNavBarItem;
} | {
  type: 'overflow';
  onClick(): void;
};
export const DashboardQuickAccess: React.FC<{}> = () => {
  const {
    width,
    onLayout
  } = useCachedWidth('dashboardQuickAccess');
  const maxItemRenderCount = Math.max(3, Math.floor(width / 72));
  const shownItems = useShownItems(maxItemRenderCount);
  if (shownItems.length === 0) {
    return null;
  }
  return <div className={_c0}>
			<FallbackBox onLayout={onLayout} className={_c1}>
				{width > 0 && <List entries={shownItems} />}
			</FallbackBox>
		</div>;
};
const List: React.FC<{
  entries: ItemType[];
}> = ({
  entries
}) => {
  const loggedInAreaService = useService($LoggedInAreaService);
  const backgroundColor = useAppIconBackground();
  if (entries.length === 0) {
    return null;
  }
  return <div className={_c2}>
			{entries.map(entry => {
      if (entry.type === 'view') {
        return <loggedInAreaService.MenuAppEntry key={entry.navItem.id} onPress={() => {}} item={entry.navItem} backgroundColor={backgroundColor} />;
      } else {
        return <OverflowItem key={'overflow'} />;
      }
    })}
		</div>;
};
const useAppIconBackground = () => {
  const theme = useTheme();
  return useMemo(() => {
    const isDarkTheme = theme.id !== 'light';
    const rgb = parseRgbString(theme.colors.basic.accent);
    return isDarkTheme ? 'rgba(255,255,255,0.11)' : calculateGradient(rgb, 0, 0.22);
  }, [theme]);
};
const OverflowItem: React.FC<{}> = () => {
  const [hovered, setHovered] = React.useState(false);
  const navBarRegistry = useService($NavBarRegistry);
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: () => {
      navBarRegistry.toggleMenu();
    }
  })} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} className={_c3}>
			<div className={_c4 + (hovered ? _c5 : _c6)}>
				<IconMoreHorizontal size={'large'} color={((hovered ? 'iconHoverAtom' : 'iconDefaultAtom') as ThemeColors)} />
			</div>
			<Text type={'tiny'} state={hovered ? 'primary' : ('secondary' as TextStates)} numberOfLines={1} bold={true} className={_c7}>
				<FormattedMessage id={declareFormat({
        id: 'channel.dashboard.quickaccess.overflow',
        defaultFormat: 'More'
      })} />
			</Text>
		</div>;
};
const useShownItems = (maxItems: number): ItemType[] => {
  const clientSettingsService = useService($ClientSettingsService);
  const navBarRegistry = useService($NavBarRegistry);
  const userService = useService($UserService);
  const appService = useService($AppService);
  const viewService = useService($ViewService);
  const viewIds = useReactiveState(() => distinct([...clientSettingsService.quickAccessViews, ...navBarRegistry.getNormalizedMenuItems().map(it => it.id)], it => it), [], 300);
  return React.useMemo(() => {
    if (clientSettingsService.quickAccessViews.length === 0 && userService.currentUser.status === CommunityStatus.Normal) {
      return [];
    }
    const allItems = (viewIds.map(viewId => ({
      type: 'view',
      navItem: navBarRegistry.getNormalizedMenuItems().find(it => it.id === viewId)
    } satisfies ItemType)).filter(it => it.navItem).sort((a, b) => {
      const textA = a.navItem.badges?.[0]?.text || '';
      const textB = b.navItem.badges?.[0]?.text || '';
      const badgeA = textA ? parseInt(textA) || 1 : 0;
      const badgeB = textB ? parseInt(textB) || 1 : 0;
      return badgeB - badgeA;
    }).map(item => {
      if (item.type === 'view') {
        const data = item.navItem.data;
        if (data.kind === 'view') {
          item.navItem.onClick = () => {
            viewService.openViewAsOverlay(data.view);
          };
        } else if (data.kind === 'favorite') {
          item.navItem.onClick = () => {
            appService.nextGlobalAppAsView = false;
            appService.executeSlashCommand(data.command?.replace(/\$SID/g, K3_SIDEBAR_SID), 'nav-favorite-apps');
          };
        }
      }
      return item;
    }) satisfies ItemType[]);
    if (allItems.length > maxItems) {
      return [...allItems.slice(0, maxItems - 1), {
        type: 'overflow',
        onClick: () => {}
      }];
    } else {
      return allItems;
    }
  }, [maxItems, viewIds]);
};
const _c0 = " Knu-Flex overflow-visible mb-small mt-base mx-tiny ";
const _c1 = " Knu-Flex maxWidth-100-percent flex-1 overflow-visible height-72px ";
const _c2 = " Knu-Flex justifyContent-space-between width-full ";
const _c3 = " Knu-FlexCol cursor-pointer width-72px gap-tiny alignItems-center pb-tiny ";
const _c4 = " Knu-FlexCol size-48px placeItems-center borderRadius-xlarge position-relative borderWidth-large borderStyle-solid ";
const _c5 = " borderColor-inputFieldBorderHover ";
const _c6 = " borderColor-inputFieldBorder ";
const _c7 = "  ";