import * as React from 'react';
import { $ViewService, ViewId } from '@knuddels-app/layout';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $NavBarItemProvider } from '../../extensionPoints';
import { Disposable, expectUnreachable } from '@knuddels/std';
import { declareFormat } from '@knuddels/i18n';
import { DYNAMIC_SLOT_INDEX, DYNAMIC_SLOT_TYPE } from '../../constants';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { $AppService, $GlobalAppsAppViewer } from '@knuddelsModules/Apps';
import { NavBadge, NavBarItemData } from './NavBarItemData';
import { formatMessage } from '@knuddels-app/i18n';
import { getBadgeCount } from '@shared/helper/getBadgeCount';
import { action, autorun, computed, observable, runInAction } from '@knuddels-app/mobx';
import { $LocalStorage, LocalStorageEntry, LocalStorageKey } from '@knuddels-app/local-storage';
import { $ScreenService } from '@knuddels-app/Screen';
import { globalAppViewId, viewIdForSystemApp } from '@knuddelsModules/SystemApps';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { $EvergreenDataService } from '@knuddels-app/evergreenData';
import { associate } from '@knuddels-app/tools/associate';
import { K3_SIDEBAR_SID } from '@shared/constants';
import { $CommandService, $CommandWithoutChannelService } from '@knuddels-app/Commands';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
import { $OverlayService } from '@knuddels-app/overlays';
import { MenuBottomSheetOverlayFactory } from '../components/MenuBottomSheet/MenuBottomSheet';
import { $FeatureService } from '@knuddels-app/featureFlags';
import { appNotificationExploreFlag } from '@knuddelsModules/FeatureFlags';
import { IconMenu } from '../components/IconMenu';
export const lastVisitedSlotKey = LocalStorageKey.withJsonSerializer<string>({
  name: 'lastVisitedSlot',
  cookieExpires: {
    inDays: Number.MAX_SAFE_INTEGER
  }
});

/**
 * TODO Extract Slot behavior to strategies
 */

@injectable()
export class NavBarItemRegistry {
  public readonly dispose = Disposable.fn();
  private lastVisitedSlotEntry: LocalStorageEntry<string> = this.localStorage.getEntry(lastVisitedSlotKey);
  @observable
  private dynamicItemId: string | null = null;
  private globalAppsAppViewer: typeof $GlobalAppsAppViewer.T | null = null;
  constructor(@inject.many($NavBarItemProvider)
  private readonly providers: (typeof $NavBarItemProvider.T)[], @inject($ViewService)
  private readonly viewService: typeof $ViewService.T, @inject($FirebaseAnalyticsService)
  private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T, @inject.lazy($GlobalAppsAppViewer)
  private readonly getGlobalAppsAppViewer: typeof $GlobalAppsAppViewer.TLazy, @inject($LocalStorage)
  private readonly localStorage: typeof $LocalStorage.T, @inject($ScreenService)
  private readonly screenService: typeof $ScreenService.T, @inject($ClientSettingsService)
  private readonly clientSettingsService: typeof $ClientSettingsService.T, @inject($EvergreenDataService)
  private readonly evergreenDataService: typeof $EvergreenDataService.T, @inject.lazy($AppService)
  private readonly getAppService: typeof $AppService.TLazy, @inject($CommandService)
  private readonly commandService: typeof $CommandService.T, @inject($CommandWithoutChannelService)
  private readonly commandWithoutChannelService: typeof $CommandWithoutChannelService.T, @inject($GenericUserEventService)
  private readonly genericUserEventService: typeof $GenericUserEventService.T, @inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T, @inject($FeatureService)
  private readonly featureService: typeof $FeatureService.T) {
    this.initMenuItemsFromLocalStorage();
    this.getGlobalAppsAppViewer().then(globalAppsAppViewer => {
      this.globalAppsAppViewer = globalAppsAppViewer;
    });
    this.dispose.track(autorun({
      name: 'update slot type on menu change'
    }, () => {
      const menuEntries = this.evergreenDataService.data.menu.categories.flatMap(it => it.entries);
      if (menuEntries.length > 0 && this.getSelectedSlotType() !== DYNAMIC_SLOT_TYPE && !menuEntries.some(it => this.isSelectedSlotType(it.id, it.name))) {
        this.setSelectedSlotType('fotomeet');
      }
    }));
    this.dispose.track(this.viewService.onViewOpened.sub(viewId => {
      const menuItem = this.menuItems.find(it => it.kind === 'view' && it.view === viewId);
      if (menuItem) {
        this.clientSettingsService.addQuickAccessView(menuItem.id);
      }
    }).dispose);
    this.getAppService().then(appService => {
      this.dispose.track(appService.onAppOpened.sub(appInstance => {
        const menuItem = this.menuItems.find(it => it.kind === 'favorite' && it.id === 'globalAppsFav-' + appInstance.appIdWithoutMeta);
        if (menuItem) {
          this.clientSettingsService.addQuickAccessView(menuItem.id);
        }
      }).dispose);
    });
  }
  public viewInsideBottomNav(viewId: ViewId, includeFavorites = false): boolean {
    /**
     * there is no bottom nav on non-stacked layouts
     */
    if (!this.screenService.isStackedLayout) {
      return false;
    }
    return this.bottomItems.some(i => {
      return i.kind === 'view' && i.view === viewId || includeFavorites && i.kind === 'favorite';
    });
  }
  public getItems(variant: NavBarVariant): CombinedNavBarItemData[] {
    return this.getCategories(variant).flatMap(it => it.entries);
  }
  private trackItem(id: string, location: 'left' | 'bottom' | 'menu'): void {
    if (id === 'Logout') {
      this.genericUserEventService.reportEvent({
        type: 'Logout',
        source: 'Navigation_' + location
      });
    }
    if (location === 'left') {
      this.firebaseAnalyticsService.logEvent('Navigation_SideNavigation', `${id}_Clicked`);
    } else if (location === 'bottom') {
      this.firebaseAnalyticsService.logEvent('Navigation_BottomNavigation', `${id}_Clicked`);
    } else {
      this.firebaseAnalyticsService.logEvent('Navigation_Menu', `${id}_Clicked`);
    }
  }
  private isViewActive(viewId: ViewId, location: 'left' | 'bottom' | 'menu'): boolean {
    if (location === 'menu') {
      return false;
    }
    if (location === 'bottom' && !!this.overlayService.findOverlay(MenuBottomSheetOverlayFactory.getFilter())) {
      return false;
    }
    const activeViews = [...this.viewService.visibleViews];
    if (activeViews.some(view => view.viewConfig.viewId === viewId)) {
      if (viewId === this.globalAppsAppViewer?.globalAppViewId) {
        return !this.globalAppsAppViewer?.app || this.viewInsideBottomNav(viewId, false);
      }
      return true;
    }
    return false;
  }
  private isAppActive(title: string): boolean {
    const activeViews = [...this.viewService.visibleViews];
    if (!this.globalAppsAppViewer?.app?.title || !activeViews.some(view => view.viewConfig.viewId === this.globalAppsAppViewer?.globalAppViewId)) {
      return false;
    }
    return this.globalAppsAppViewer?.app.title.includes(title);
  }
  public openView(viewId: ViewId, location: 'menu' | 'bottom' | 'left', preventOverlay = false): void {
    const existing = this.viewService.findView(viewId);
    if (!existing) {
      const cb = location === 'menu' ? 'onOpenByMenu' : 'onOpenByNav';
      if (location === 'menu' && (this.getSelectedSlotType() !== DYNAMIC_SLOT_TYPE || !preventOverlay)) {
        this.viewService.openViewAsOverlayWithContext(viewId.with(s => s[cb] ? s[cb]() : s), 'Navigation_' + location);
      } else {
        this.viewService.openView(viewId.with(s => s[cb] ? s[cb]() : s), {
          locationUpdateMode: 'push',
          openContext: 'Navigation_' + location
        });
      }
    } else {
      this.viewService.openView(viewId.with(s => {
        let ns = s;
        if (ns.onOpenByNav) {
          ns = ns.onOpenByNav();
        }
        if (ns.withOnlyRootViewOpened) {
          ns = ns.withOnlyRootViewOpened();
        }
        return ns;
      }), {
        openContext: 'Navigation_' + location
      });
    }
  }
  @computed
  private get menuItems(): CombinedNavBarItemData[] {
    const bottomItems = this.bottomItems.slice(0, 3);
    return this.getItems('mobile').filter(it => !bottomItems.some(b => b.id === it.id));
  }

  /**
   * Gets the items displayed in the bottom navigation bar.
   */
  @computed
  private get bottomItems(): CombinedNavBarItemData[] {
    const items = this.getItems('mobile');
    const MAX_BOTTOM_ITEMS = 5;
    if (items.length < MAX_BOTTOM_ITEMS) {
      return items;
    }
    const bottomItems: CombinedNavBarItemData[] = items.slice(0, MAX_BOTTOM_ITEMS - 1);
    if (this.getSelectedSlotType() === DYNAMIC_SLOT_TYPE && this.dynamicItemId && !bottomItems.find(i => i.id === this.dynamicItemId) && items.find(i => i.id === this.dynamicItemId)) {
      const actualItem = items.find(i => i.id === this.dynamicItemId);
      bottomItems.pop();
      bottomItems.push(actualItem);
    }
    if (this.getSelectedSlotType() !== DYNAMIC_SLOT_TYPE) {
      bottomItems.pop();
      const selectedSlotItem = items.find(i => this.isSelectedSlotType(i.id, i.title));
      if (selectedSlotItem) {
        bottomItems.push(selectedSlotItem);
      } else {
        const fallback = this.getFallbackBottomItem(items);
        if (fallback) {
          bottomItems.push(fallback);
        }
      }
    }
    const bottomItemIds = bottomItems.map(i => i.id);
    const badgeItems = items.filter(i => !bottomItemIds.includes(i.id));
    bottomItems.push({
      id: 'menu',
      kind: 'custom',
      disableForDynamicSlot: true,
      title: formatMessage(declareFormat({
        id: 'NAVBAR_ITEM_MENU',
        defaultFormat: 'Menu'
      })),
      onClick: () => {
        this.toggleMenu();
      },
      isActive: () => {
        return !!this.overlayService.findOverlay(MenuBottomSheetOverlayFactory.getFilter());
      },
      icon: IconMenu,
      trackingId: 'Menu',
      badges: this.featureService.hasSomeFlagsEnabled([appNotificationExploreFlag]) ? [] : this.getMenuBadges(badgeItems)
    });
    return bottomItems;
  }
  public isSelectedSlotType(id: string, title: string): boolean {
    return this.getSelectedSlotType().toLowerCase() === id.toLowerCase() || this.getSelectedSlotType().toLowerCase() === title.toLowerCase();
  }
  public getFallbackBottomItem<T extends {
    id?: string;
  }>(items: T[]): T {
    const fotomeet = items.find(i => i.id === 'fotomeet');
    if (fotomeet) {
      return fotomeet;
    }
    return items.find(i => i.id === 'engagementSystem');
  }
  public getMenuBadges(menuItems: CombinedNavBarItemData[] = this.menuItems): NavBadge[] {
    return menuItems.flatMap(it => it.badges ?? []);
  }
  private async initMenuItemsFromLocalStorage(): Promise<void> {
    let lastItemCount = 0;
    const dispose = autorun({
      name: 'Check stored items'
    }, async () => {
      const items = this.getItems('mobile');
      if (items.length === lastItemCount) {
        return;
      }
      lastItemCount = items.length;
      if (this.getSelectedSlotType() !== DYNAMIC_SLOT_TYPE) {
        return;
      }
      const slot = this.lastVisitedSlotEntry.get();
      if (slot) {
        const item = items.find(i => i.id === slot || i.title === slot);
        if (item) {
          runInAction('set dynamicItem', () => {
            this.dynamicItemId = item.id;
            dispose();
          });
        }
      }
    });
  }
  @action
  private updateDynamicItem(item: CombinedNavBarItemData): void {
    if (this.getSelectedSlotType() !== DYNAMIC_SLOT_TYPE || item.disableForDynamicSlot) {
      return;
    }
    const fixedItems = this.getBottomItems().slice(0, 3);
    if (fixedItems.find(i => i.id === item.id)) {
      return;
    }
    this.dynamicItemId = item.id;
    this.lastVisitedSlotEntry.set(item.id);
  }
  public getBottomItems(): NormalizedNavBarItem[] {
    return this.bottomItems.map((i, index) => this.createNormalizedItem(i, 'bottom', index)).map(i => {
      const onClick = i.onClick;
      return {
        ...i,
        onClick: () => {
          this.overlayService.closeOverlay(MenuBottomSheetOverlayFactory);
          onClick();
        }
      };
    });
  }
  public getNormalizedMenuItems(): NormalizedNavBarItem[] {
    return this.menuItems.map((i, index) => this.createNormalizedItem(i, 'menu', index));
  }
  private getCategories(variant: NavBarVariant): NavBarCategory[] {
    const menu = this.evergreenDataService.data.menu;
    const itemData = this.providers.flatMap(it => it.getItems(variant)).filter(it => !!it.id);
    const byId = associate(itemData, it => it.id, it => it);
    return menu.categories.map(category => {
      return ({
        id: category.id,
        name: category.name,
        entries: category.entries.filter(entry => entry.type !== 'frontendProvided' || !!byId[entry.id]).map(entry => {
          if (entry.type === 'frontendProvided' && byId[entry.id]) {
            return {
              title: entry.name,
              ...byId[entry.id],
              icon: entry.icon
            };
          }
          const commonData = ({
            id: entry.id,
            title: entry.name,
            icon: entry.icon,
            badges: [{
              text: menu.badges[entry.id],
              color: menu.badgesColor[entry.id],
              validUntil: menu.badgesValidUntil[entry.id]
            }]
          } satisfies Partial<CombinedNavBarItemData>);
          if (entry.type === 'systemApp') {
            return ({
              ...commonData,
              kind: 'view',
              view: viewIdForSystemApp(entry.appId),
              trackingId: entry.appId
            } satisfies CombinedNavBarItemData);
          }
          if (entry.type === 'link') {
            return ({
              ...commonData,
              kind: 'link',
              trackingId: entry.name,
              url: entry.url
            } satisfies CombinedNavBarItemData);
          }
          if (entry.type === 'slashCommand') {
            if (entry.id.startsWith('globalAppsFav')) {
              const appId = entry.id.replace('globalAppsFav-', '');
              return ({
                ...commonData,
                appId,
                command: entry.command?.replace(/\$SID/g, K3_SIDEBAR_SID),
                kind: 'favorite',
                trackingId: entry.name
              } satisfies CombinedNavBarItemData);
            }
            return ({
              ...commonData,
              kind: 'custom',
              trackingId: entry.id,
              onClick: () => {
                const call = this.commandService.tryParseCommandCall(entry.command);
                if (!call) {
                  return;
                }
                this.commandWithoutChannelService.invokeCommand(call.commandName, call.parameter);
              }
            } satisfies CombinedNavBarItemData);
          }
        }).filter(it => !!it)
      } satisfies NavBarCategory);
    });
  }
  public getNormalizedCategories(variant: NavBarVariant): NormalizedNavBarCategory[] {
    const location = variant === 'desktop' ? 'left' : 'menu';
    return this.getCategories(variant).map(category => {
      return ({
        id: category.id,
        name: category.name,
        entries: category.entries.map(entry => {
          return this.createNormalizedItem(entry, location, -1);
        })
      } satisfies NormalizedNavBarCategory);
    });
  }
  private createNormalizedItem(item: CombinedNavBarItemData, location: 'left' | 'bottom' | 'menu', position: number): NormalizedNavBarItem | null {
    const badges = item.badges ? item.badges.map(badge => ({
      text: getBadgeCount(badge.text),
      color: badge.color,
      validUntil: badge.validUntil
    })) : [];
    switch (item.kind) {
      case 'custom':
        return {
          data: item,
          id: item.id,
          active: item.isActive && item.isActive(),
          badges,
          icon: item.icon,
          onClick: () => {
            this.trackItem(item.trackingId, location);
            item.onClick();
          },
          title: item.title
        };
      case 'view':
        return {
          data: item,
          id: item.id,
          active: this.isViewActive(item.view, location),
          badges,
          icon: item.icon,
          onClick: () => {
            if (location === 'menu') {
              this.updateDynamicItem(item);
            }
            if (item.traceId) {
              this.firebaseAnalyticsService.startTrace(item.traceId);
            }
            this.trackItem(item.trackingId, location);
            if (item.onClick) {
              item.onClick();
            }
            if (item.openView) {
              item.openView(location);
            } else {
              this.openView(item.view, location, !item.disableForDynamicSlot);
            }
          },
          title: item.title
        };
      case 'link':
        return {
          data: item,
          id: item.id,
          active: false,
          badges,
          icon: item.icon,
          onClick: () => {
            this.trackItem(item.trackingId, location);
            if (item.onClick) {
              item.onClick();
            }
          },
          title: item.title,
          url: item.url
        };
      case 'favorite':
        return {
          data: item,
          id: item.id,
          active: typeof item.title === 'string' && this.isAppActive(item.title),
          badges,
          icon: item.icon,
          onClick: async () => {
            if (location === 'menu') {
              this.updateDynamicItem(item);
            }

            /**
             * if global app is already open, just switch to it,
             * without triggering the click event (which would cause a reload)
             */
            if (this.screenService.isStackedLayout && this.globalAppsAppViewer?.app?.appIdWithoutMeta === item.appId) {
              this.viewService.openView(this.globalAppsAppViewer.globalAppViewId);
              return;
            }
            this.trackItem(item.trackingId, location);
            const appService = await this.getAppService();
            if (appService.globalAppsAppViewer.getApp(item.appId)) {
              if (!this.screenService.isStackedLayout) {
                this.viewService.closeView(globalAppViewId);
              }
              return;
            }
            appService.nextGlobalAppAsView = position === DYNAMIC_SLOT_INDEX || !this.screenService.isStackedLayout || this.getSelectedSlotType() === DYNAMIC_SLOT_TYPE;
            await appService.executeSlashCommand(item.command?.replace(/\$SID/g, K3_SIDEBAR_SID), 'nav-favorite-apps');
          },
          title: item.title
        };
      default:
        {
          expectUnreachable(item);
        }
    }
  }
  public getSelectedSlotType(): string {
    return this.clientSettingsService.navIconSlot ?? 'fotomeet';
  }
  @action
  public async setSelectedSlotType(slotType: string): Promise<void> {
    this.clientSettingsService.setNavIconSlot(slotType);
  }
  public toggleMenu() {
    this.overlayService.toggleOverlay(MenuBottomSheetOverlayFactory, {});
  }
}
export interface NavBarItemProvider {
  getItems(variant: NavBarVariant): NavBarItemData[];
}
export type NavBarVariant = 'desktop' | 'mobile';

/**
 * A normalized description of a nav bar item.
 */
export interface NormalizedNavBarItem {
  data: CombinedNavBarItemData;
  id: string;
  active: boolean;
  onClick: () => void;
  url?: string;
  title: string | JSX.Element;
  badges: NavBadge[];
  icon: string | React.ComponentType<any>;
}
export type CombinedNavBarItemData = NavBarItemData & {
  title: string;
  icon: string | React.ComponentType<any>;
};
export type NavBarCategory = {
  id: string;
  name: string;
  entries: CombinedNavBarItemData[];
};
export type NormalizedNavBarCategory = {
  id: string;
  name: string;
  entries: NormalizedNavBarItem[];
};
// tslint:disable-next-line: max-file-line-count