import { dangerouslyGetK3Container } from '@knuddels-app/ModuleSystem';
import { ContainerProvider, useService } from '@knuddels-app/DependencyInjection';
import * as React from 'react';
import { SyncedCacheProvider } from '@knuddels-app/Connection';
import { DarkTheme, LightTheme, Theme, ThemeProvider } from '@knuddels/component-library';
import { $LocalStorage } from '@knuddels-app/local-storage';
import { accentKey, themeKey } from '@shared/themeKeys';
import { ThemeSwitchProvider } from './ThemeSwitchProvider';
import { usePrefersDarkMode } from '@knuddels-app/tools/usePrefersDarkMode';
import { useNativeDarkModeListener } from './tools/useNativeDarkModeListener';
import { isMobileBrowser } from './tools/isMobileBrowser';
import { isNative } from './tools/isNative';
import { createThemeWithFontScale } from '@knuddels-app/tools/createThemeWithFontScale';
import { Keyboard, KeyboardStyle } from '@capacitor/keyboard';
import { useFontScale } from '@knuddels-app/tools/getFontScale';
import { StAppMessengerThemeProvider } from '@knuddels-app/StAppMessenger/StAppMessengerThemeProvider';
import { ThemeConfigProvider } from '@knuddels-app/ThemeConfigProvider';
import { getSafeArea } from './SafeArea';
import { observer } from './mobx';

/**
 * Used to inject all relevant providers into subtrees.
 * Was created for the {@link KeyboardReplacementContainer} component that
 * renders its children outside the providers' scope (only on native).
 */

const useThemeFromStorage = () => {
  const prefersDarkMode = usePrefersDarkMode();
  const [config, setConfig] = React.useState<{
    lightTheme: Theme;
    darkTheme: Theme;
    activeTheme: Theme;
    accent: string;
  } | null>();
  const localStorage = useService($LocalStorage);
  const fontScale = useFontScale();
  const updateTheme = async (themeId: string, inputAccent: string | undefined) => {
    const accent = inputAccent ?? config?.accent;
    if (!accent) {
      const lightTheme = await createThemeWithFontScale(LightTheme.colors.basic.accent, LightTheme);
      const darkTheme = await createThemeWithFontScale(DarkTheme.colors.basic.accent, DarkTheme);
      const activeTheme = themeId === 'dark' ? darkTheme : lightTheme;
      setConfig({
        lightTheme,
        darkTheme,
        activeTheme,
        accent: activeTheme.colors.basic.accent
      });
    } else {
      const lightTheme = await createThemeWithFontScale(accent, LightTheme);
      const darkTheme = await createThemeWithFontScale(accent, DarkTheme);
      const activeTheme = themeId === 'dark' ? darkTheme : lightTheme;
      setConfig({
        lightTheme,
        darkTheme,
        activeTheme,
        accent
      });
    }
  };
  const createTheme = async () => {
    const themeEntry = localStorage.getEntry(themeKey);
    const accentEntry = localStorage.getEntry(accentKey);
    const theme = themeEntry.get();
    const accent = accentEntry.get();
    const useTheme = theme === 'dark' ? DarkTheme : theme === 'light' ? LightTheme : prefersDarkMode ? DarkTheme : LightTheme;
    await updateTheme(useTheme.id, accent);
  };
  React.useLayoutEffect(() => {
    createTheme();
  }, [prefersDarkMode, fontScale]);
  return {
    config,
    updateTheme
  };
};
const CustomThemeProvider: React.FC = observer('CustomThemeProvider', ({
  children
}) => {
  const {
    config,
    updateTheme
  } = useThemeFromStorage();
  useNativeDarkModeListener(updateTheme);
  if (isNative()) {
    React.useEffect(() => {
      if (!config) {
        return;
      }
      Keyboard.setStyle({
        style: config.activeTheme.id === 'dark' ? KeyboardStyle.Dark : KeyboardStyle.Light
      });
    }, [config?.activeTheme]);
  }
  if (!config) {
    return null;
  }
  return <ThemeSwitchProvider value={updateTheme}>
				<ThemeProvider safeAreaTopHeight={getSafeArea().safeAreaTopHeight} theme={config.activeTheme} useCustomScrollBars={!isMobileBrowser() || isNative()}>
					<ThemeConfigProvider value={config}>
						{children}
					</ThemeConfigProvider>
				</ThemeProvider>
			</ThemeSwitchProvider>;
});
export function AllProviders(props: React.PropsWithChildren<{}>): React.ReactElement {
  return <ContainerProvider value={dangerouslyGetK3Container()}>
			<CustomThemeProvider>
				<StAppMessengerThemeProvider>
					<SyncedCacheProvider>{props.children}</SyncedCacheProvider>
				</StAppMessengerThemeProvider>
			</CustomThemeProvider>
		</ContainerProvider>;
}