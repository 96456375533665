import * as providedServices from './providedServices';
import { Module } from '@knuddels-app/ModuleSystem/Module';
import { userDataModule } from '@knuddelsModules/UserData/module';
import { notificationsModule } from '@knuddelsModules/Notifications/module';
import { deepLinkingModule } from '@knuddelsModules/DeepLinking/module';

export const paymentModule = new Module({
	name: 'Payment module',
	dependencies: [userDataModule, notificationsModule, deepLinkingModule],
	providedServices,
	loadEvent: globalEnv.product === 'stapp-messenger' ? 'onDemand' : 'onLogin',
	load: {
		from: () =>
			import(
				/* webpackChunkName: "payment" */ './bundle/registerServices'
			),
	},
});
