import { useService } from '@knuddels-app/DependencyInjection';
import { LoadService } from '@knuddels-app/ModuleSystem';
import { useIsStackedLayout } from '@knuddels-app/Screen';
import { observer } from '@knuddels-app/mobx';
import { calculateGradient, ChildThemeProvider, FlexCol, parseRgbString, ScrollView, Text, useChildTheme, useTheme, FallbackBox, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { $AdsService } from '@knuddelsModules/Ads';
import { $ChannelListService } from '@knuddelsModules/ChannelList';
import { $MessengerComponents } from '@knuddelsModules/Messenger';
import { $ProfileVisitorsPanelService, ProfileVisitorsOverview } from '@knuddelsModules/ProfileVisitors';
import { Spacer } from '@shared/components';
import * as React from 'react';
import { DashboardMobileHeader } from './DashboardMobileHeader';
import { DashboardTipsElement } from './DashboardTipsElement';
import { DashboardQuickAccess } from './DashboardQuickAccess';
import { $FeatureService } from '@knuddels-app/featureFlags';
import { dashboardQuickAccessControlFlag } from '@knuddelsModules/FeatureFlags';
import { GreetingText } from '@knuddelsModules/Explore/bundle/components/GreetingText';
import { useCachedWidth } from '@knuddels-app/tools/useCachedElementWidth';
const TOP_ADZONE_WIDTH = 728;
const SIDE_ADZONE_SPACING = 24;
const SIDE_ADZONE_WIDTH = 300 + SIDE_ADZONE_SPACING;
const SCROLL_THUMB_WIDTH = 16;
export const NoChannelDashboard: React.FC = observer('NoCannelDashboard', () => {
  const visitorPanelService = useService($ProfileVisitorsPanelService);
  const featureService = useService($FeatureService);
  const isStackedLayout = useIsStackedLayout();
  const {
    width,
    onLayout
  } = useCachedWidth('noChannelDashboard');
  const variant = isStackedLayout ? 'mobile' : 'desktop';
  const layout = isStackedLayout || width < TOP_ADZONE_WIDTH + SCROLL_THUMB_WIDTH ? 'short' : 'wide';
  const [isScrolled, setIsScrolled] = React.useState(false);
  const theme = useTheme();
  const background = React.useMemo(() => {
    if (!isStackedLayout) {
      return undefined;
    }
    const rgb = parseRgbString(theme.colors.basic.accent);
    const bgColor1 = 'transparent';
    const bgColor2 = calculateGradient(rgb, 0, 0.04);
    return `linear-gradient(180deg, ${bgColor1} 0%, ${bgColor2} 100%)`;
  }, [theme, isStackedLayout]);
  return <FallbackBox onLayout={onLayout} className={_c0 + ((variant === 'desktop' ? "transparent" : "contentBg") ? resolveIsDarkColor(variant === 'desktop' ? "transparent" : "contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (variant === 'desktop' ? _c1 : _c2)}>
				{width > 0 && <>
						{variant === 'mobile' && <DashboardMobileHeader hasShadow={isScrolled} />}
						<div onScroll={e => {
        setIsScrolled(e.currentTarget.scrollTop > 0);
      }} className={_c3}>
							{width - TOP_ADZONE_WIDTH - SCROLL_THUMB_WIDTH >= SIDE_ADZONE_WIDTH * 2 && <SideAdzone />}
							<div style={{
          background,
          alignSelf: resolveThemingValue(layout === 'wide' ? 'center' : undefined, "theme", useTheme())
        }} className={_c4}>
								{layout === 'wide' && <TopAdzone />}
								<div style={{
            alignSelf: resolveThemingValue(layout === 'wide' ? 'center' : undefined, "theme", useTheme()),
            maxWidth: resolveThemingValue(layout === 'wide' ? 728 + 32 : undefined, "sizes", useTheme()),
            minWidth: resolveThemingValue(layout === 'wide' ? 728 + 32 : undefined, "sizes", useTheme())
          }} className={_c5}>
									{variant === 'desktop' && <DesktopGreetingText layout={layout} />}
									{variant === 'mobile' && !featureService.hasSomeFlagsEnabled([dashboardQuickAccessControlFlag]) && <DashboardQuickAccess />}
									<DashboardTipsElement layout={layout} variant={variant} />
									<div className={_c6}>
										<LoadService service={$ChannelListService}>
											{components => <components.DashboardChannelListElement layout={layout} variant={variant} />}
										</LoadService>
									</div>
									<Spacer size={'base'} />
									<LoadService service={$MessengerComponents}>
										{components => <components.DashboardMessengerListElement layout={layout} variant={variant} />}
									</LoadService>
									{visitorPanelService.shouldShow() && <>
											<Spacer size={'base'} />
											<ProfileVisitorsOverview layout={layout} type={'dashboard'} showTopSpacer={false} />
										</>}
									<Spacer size={'xxlarge'} />
								</div>
							</div>
						</div>
					</>}
			</FallbackBox>;
});
const DesktopGreetingText: React.FC<{
  layout: 'short' | 'wide';
}> = ({
  layout
}) => {
  const theme = useChildTheme({
    sizes: {
      texts: {
        title: {
          fontSize: 27,
          fontWeight: '700',
          lineHeight: 30,
          letterSpacing: 0.081
        }
      }
    }
  }, []);
  return <ChildThemeProvider theme={theme}>
			<Text type={'title'} state={'highlighted'} className={_c7 + (layout === 'short' ? _c8 : _c9)}>
				<GreetingText />
			</Text>
		</ChildThemeProvider>;
};
const TopAdzone: React.FC = observer('TopAdzone', () => {
  const adsService = useService($AdsService);
  const Adzone = adsService.Adzone;
  if (!adsService.areWebAdsVisible || !Adzone) {
    return null;
  }
  return <div style={{
    width: resolveThemingValue(TOP_ADZONE_WIDTH, "sizes", useTheme())
  }} className={_c10}>
			<Adzone adzone={'channelNoConnectionLeaderboard'} fixedSize={90 + 24} adsBadgeLocation={'bottom'} />
		</div>;
});
const SideAdzone: React.FC = observer('SideAdzone', () => {
  const adsService = useService($AdsService);
  const Adzone = adsService.Adzone;
  if (!adsService.areWebAdsVisible || !Adzone) {
    return null;
  }
  return <div className={_c11}>
			<Adzone adzone={'channelNoConnectionOffContent'} reloadAd verticalAd />
		</div>;
});
const _c0 = " Knu-FlexCol width-full height-100-percent flex-1 ";
const _c1 = " bg-transparent ";
const _c2 = " bg-contentBg ";
const _c3 = " Knu-ScrollView position-relative flex-1 flexBasis-0-px ";
const _c4 = " Knu-FlexCol flex-1 ";
const _c5 = " Knu-FlexCol ";
const _c6 = " Knu-FlexCol minHeight-288px ";
const _c7 = " mt-xlarge ";
const _c8 = " mx-base ";
const _c9 = " mx-none ";
const _c10 = " Knu-FlexCol py-base ";
const _c11 = " Knu-FlexCol position-absolute right-large insetY-none alignItems-center ";