import { jsx as c, jsxs as L } from "react/jsx-runtime";
import l from "react";
import { ThemeProvider as P } from "styled-components";
import { LightTheme as w } from "./Light.js";
import { GlobalStyle as M, CustomScrollBarStyles as E } from "./GlobalStyle.js";
const G = (s, t, n, r = 1) => `rgba(${s}, ${t}, ${n}, ${r})`, B = l.createContext(w), A = l.createContext(0), I = l.createContext(0), D = l.createContext(null), N = (s, t) => {
  const n = l.useMemo(() => {
    var f, b, u, i, v, m, $, x, y, h, k, p, j, g, O, C, S;
    const r = {};
    for (const [e, o] of Object.entries(
      ((f = s.colors) == null ? void 0 : f.basic) ?? {}
    ))
      r[`--colors-${e}`] = o;
    for (const [e, o] of Object.entries(
      ((b = s.sizes) == null ? void 0 : b.box) ?? {}
    ))
      r[`--sizes-${e}`] = o;
    for (const [e, o] of Object.entries(
      ((u = s.sizes) == null ? void 0 : u.icons) ?? {}
    ))
      r[`--sizes-icons-${e}`] = o + "px";
    for (const [e, o] of Object.entries(s.spacings ?? {}))
      r[`--spacing-${e}`] = o + "px";
    for (const [e, o] of Object.entries(
      ((v = (i = s.colors) == null ? void 0 : i.texts) == null ? void 0 : v.light) ?? {}
    ))
      r[`--text-colors-${e}-light`] = o, r[`--text-colors-${e}`] = o;
    for (const [e, o] of Object.entries(
      (($ = (m = s.colors) == null ? void 0 : m.texts) == null ? void 0 : $.dark) ?? {}
    ))
      r[`--text-colors-${e}-dark`] = o;
    for (const [e, o] of Object.entries(
      ((y = (x = s.colors) == null ? void 0 : x.icons) == null ? void 0 : y.dark) ?? {}
    ))
      r[`--icons-colors-${e}-dark`] = o;
    for (const [e, o] of Object.entries(
      ((h = s.colors) == null ? void 0 : h.icons.light) ?? {}
    ))
      r[`--icons-colors-${e}-light`] = o, r[`--icons-colors-${e}`] = o;
    for (const [e, o] of Object.entries(
      ((k = s.sizes) == null ? void 0 : k.borderRadius) ?? {}
    ))
      r[`--borderRadius-${e}`] = o + (e === "circle" ? "" : "px");
    for (const [e, o] of Object.entries(
      ((p = s.sizes) == null ? void 0 : p.border) ?? {}
    ))
      r[`--borderWidth-${e}`] = o + "px";
    for (const [e, o] of Object.entries(
      ((g = (j = s.colors) == null ? void 0 : j.Shadow) == null ? void 0 : g.onLight) ?? {}
    ))
      r[`--shadows-onLight-${e}`] = o.literal;
    for (const [e, o] of Object.entries(
      ((C = (O = s.colors) == null ? void 0 : O.Shadow) == null ? void 0 : C.onDark) ?? {}
    ))
      r[`--shadows-onDark-${e}`] = o.literal;
    for (const [e, o] of Object.entries(
      ((S = s.sizes) == null ? void 0 : S.texts) ?? {}
    ))
      for (const [R, z] of Object.entries(o)) {
        const K = typeof z == "number" ? "px" : "";
        r[`--${R}-${e}`] = z + K;
      }
    for (const [e, o] of Object.entries(
      s.aspectRatio ?? {}
    ))
      r[`--aspectRatio-${e}`] = o;
    const a = "Theme-" + (Math.random() + 1).toString(36).substring(7), d = t.document.createElement("style");
    return d.innerHTML = `.${a} { ${Object.entries(r).map(([e, o]) => `${e}: ${o};`).join(`
`)} }`, t.document.head.append(d), d.id = `styles-${a}`, a;
  }, [s]);
  return l.useEffect(() => () => {
    var r;
    (r = t.document.getElementById(`styles-${n}`)) == null || r.remove();
  }, [n]), n;
}, q = ({
  children: s,
  defaultTheme: t = w,
  parentWindow: n,
  useCustomScrollBars: r = !0,
  safeAreaTopHeight: a = 0,
  ...d
}) => {
  const [f, b] = l.useState(t), u = l.useMemo(() => ({ setTheme: b, currentTheme: f }), [b, f]), i = d.theme ?? f, v = N(i, n ?? window);
  return /* @__PURE__ */ c(D.Provider, { value: u, children: /* @__PURE__ */ c(B.Provider, { value: i, children: /* @__PURE__ */ c(P, { theme: i, children: /* @__PURE__ */ L(A.Provider, { value: a, children: [
    /* @__PURE__ */ c(M, {}),
    r && /* @__PURE__ */ c(
      E,
      {
        scrollBarThumbColor: i.colors.basic.scrollbarThumbColor,
        scrollbarBackgroundColor: i.colors.basic.scrollbarBackgroundColor
      }
    ),
    /* @__PURE__ */ c(
      "div",
      {
        className: v + " Knu-Flex size-full minHeight-0",
        children: s
      }
    )
  ] }) }) }) });
}, J = ({ children: s, styles: t, overrideVariables: n, theme: r, parentWindow: a }) => {
  const d = N(
    n ?? r,
    a ?? window
  );
  return /* @__PURE__ */ c(B.Provider, { value: r, children: /* @__PURE__ */ c(P, { theme: r, children: /* @__PURE__ */ c(
    "div",
    {
      className: d + " Knu-Flex",
      style: t || { display: "contents" },
      children: s
    }
  ) }) });
};
export {
  J as ChildThemeProvider,
  I as SafeAreaBottomContext,
  A as SafeAreaTopContext,
  B as ThemeContext,
  q as ThemeProvider,
  D as ThemeSwitcherContext,
  G as rgb
};
