import { injectable } from '@knuddels-app/DependencyInjection';
import { action, observable } from '@knuddels-app/mobx';
import { OS, os } from '@shared/components/tools/os';
import { NativeWebview } from '@knuddels/native-webview-capacitor-plugin';
import { NativeInAppNotifications } from '@knuddels/native-in-app-notifications';
import { NativeInAppNotificationsWeb } from '@knuddels/native-in-app-notifications/dist/esm/web';
import {
	isDarkColor,
	LightTheme,
	rgb,
	Theme,
} from '@knuddels/component-library';
import { Color } from '@generated/graphql';

import { Disposable } from '@knuddels/std';
import { PluginListenerHandle } from '@capacitor/core';

const KEY = 'experimental_native_webview';

type Handlers = {
	onPress?: () => void;
	onHidden?: () => void;
	onError?: (error: { message: string }) => void;
};

type OverlayState = 'hidden' | 'visible';
type BackgroundState = 'background' | 'active';
type AppDrawerState = 'minimized' | 'hidden' | 'visible';

@injectable()
export class NativeWebViewService {
	dispose = Disposable.fn();

	public appToWebViewId = new Map<string, string>();

	public currentTheme: Theme = LightTheme;

	private pressHandlers = new Map<string, () => void>();
	private hiddenHandlers = new Map<string, () => void>();

	private overlayState: OverlayState = 'hidden';
	private backgroundState: BackgroundState = 'background';
	private appDrawerState: AppDrawerState = 'hidden';

	get isEnabled(): boolean {
		return this._isEnabled && os === OS.ios;
	}

	@observable
	private _isEnabled = localStorage.getItem(KEY) !== 'false';

	constructor() {
		if (!this.isEnabled) {
			NativeWebview.hideContainer();
		}

		const handlerPromises: Promise<PluginListenerHandle>[] = [];

		handlerPromises.push(
			NativeInAppNotifications.addListener(
				'notificationClicked',
				({ id }) => {
					const handler = this.pressHandlers.get(id);
					if (handler) {
						handler();
						this.handleNotificationHidden(id);
					}
				}
			),
			NativeInAppNotifications.addListener(
				'notificationClosed',
				({ id }) => {
					this.handleNotificationHidden(id);
				}
			)
		);

		Promise.all(handlerPromises).then(handlers => {
			this.dispose.track(() => {
				handlers.forEach(handler => handler.remove());
			});
		});
	}

	updateBackgroundState(state: BackgroundState): void {
		this.backgroundState = state;
		this.updateContainerVisibility();
	}

	updateOverlayState(state: OverlayState): void {
		this.overlayState = state;
		this.updateContainerVisibility();
	}

	updateAppDrawerState(state: AppDrawerState): void {
		this.appDrawerState = state;
		this.updateContainerVisibility();
	}

	updateSize(
		appData: { appId: string; displayWidth: number; displayHeight: number },
		scale: number
	): void {
		if (!this.isEnabled) {
			return;
		}

		const id = this.appIdToWebViewId(appData.appId);
		if (id) {
			NativeWebview.updateLayout({
				id,
				data: {
					scale,
					width: appData.displayWidth,
					height: appData.displayHeight,
				},
			});
		}
	}

	updateState(app: { appId: string }, state: 'active' | 'inactive'): void {
		if (!this.isEnabled) {
			return;
		}

		const id = this.appIdToWebViewId(app.appId);
		if (id) {
			NativeWebview.setWebviewState({
				id,
				state,
			});
		}
	}

	async render(
		loaderUrl: string,
		appData: {
			appId: string;
			getSize: () => { width: number; height: number };
		},
		scale: number,
		initialState: 'active' | 'inactive'
	): Promise<{ id: string }> {
		const size = appData.getSize();
		const r = await NativeWebview.renderWebview({
			url: loaderUrl,
			data: {
				scale,
				width: size.width,
				height: size.height,
			},
			initialState,
		});
		if (this.shouldShowContainer()) {
			NativeWebview.showContainer();
		}
		this.appToWebViewId.set(appData.appId, r.id);
		this.updateSize(
			{
				...appData,
				displayHeight: appData.getSize().height,
				displayWidth: appData.getSize().width,
			},
			scale
		);
		/**
		 *  on slow devices the webview sometimes was not positioned correctly
		 *  this is mostly likely due to the container size being unknown as a result of a race condition
		 */
		setTimeout(() => {
			this.updateSize(
				{
					...appData,
					displayHeight: appData.getSize().height,
					displayWidth: appData.getSize().width,
				},
				scale
			);
		}, 500);
		return { id: r.id };
	}

	destroy(app: { appId: string }): void {
		const id = this.appIdToWebViewId(app.appId);
		if (id) {
			NativeWebview.destroyWebview({ id });
			this.appToWebViewId.delete(app.appId);
		}
	}

	private appIdToWebViewId(appId: string): string | undefined {
		return this.appToWebViewId.get(appId);
	}

	private shouldShowContainer(): boolean {
		return !(
			this.overlayState === 'visible' ||
			this.backgroundState === 'background' ||
			this.appDrawerState === 'hidden' ||
			this.appDrawerState === 'minimized'
		);
	}

	private updateContainerVisibility(): void {
		if (!this.isEnabled) {
			NativeWebview.hideContainer();
			return;
		}

		if (!this.shouldShowContainer()) {
			NativeWebview.hideContainer();
			return;
		}

		NativeWebview.showContainer();
	}

	private handleNotificationHidden(id: string): void {
		this.pressHandlers.delete(id);
		const handler = this.hiddenHandlers.get(id);
		if (handler) {
			handler();
			this.hiddenHandlers.delete(id);
		}
	}

	@action.bound
	setEnabled(isEnabled: boolean): void {
		this._isEnabled = isEnabled;
		localStorage.setItem(KEY, isEnabled ? 'true' : 'false');
		this.updateContainerVisibility();
	}

	async renderNotification(
		options: Pick<
			Parameters<NativeInAppNotificationsWeb['renderNotification']>[0],
			'body' | 'iconUrl' | 'title'
		> &
			Handlers & {
				ctaText?: string;
				iconText?: string;
				iconColor?: { darkThemeColor: Color; lightThemeColor: Color };
			}
	): Promise<() => void> {
		return this.renderNotificationInternal(options, async (args, config) =>
			NativeInAppNotifications.renderNotification({
				...args,
				...config,
			})
		);
	}

	async renderMenteeNotification(
		options: Pick<
			Parameters<
				NativeInAppNotificationsWeb['renderMenteeNotification']
			>[0],
			'title' | 'age' | 'gender' | 'onlineChannel' | 'nick' | 'ctaText'
		> &
			Handlers
	): Promise<() => void> {
		return this.renderNotificationInternal(options, (args, config) =>
			NativeInAppNotifications.renderMenteeNotification({
				...args,
				...config,
				iconUrl:
					'https://cdnc.knuddelscom.de/pics/sm_abo_15-05_schulabschluss.gif',
			})
		);
	}

	private renderNotificationInternal = async <
		T extends Handlers & {
			iconColor?: { darkThemeColor: Color; lightThemeColor: Color };
		},
	>(
		args: T,
		createNotification: (
			options: Omit<T, 'onPress' | 'onHidden' | 'onError'>,
			config: {
				bgColor: string;
				titleColor: string;
				bodyColor: string;
				ctaTextColor: string;
				iconTextColor: string | undefined;
			}
		) => Promise<{ id: string }>
	) => {
		const { onPress, onHidden, onError, ...rest } = args;
		const textColors = isDarkColor(
			this.currentTheme.colors.basic.contentLightBg
		)
			? this.currentTheme.colors.texts.dark
			: this.currentTheme.colors.texts.light;

		const iconColor = isDarkColor(
			this.currentTheme.colors.basic.contentLightBg
		)
			? args.iconColor?.darkThemeColor
			: args.iconColor?.lightThemeColor;

		try {
			const value = await createNotification(rest, {
				bgColor: this.currentTheme.colors.basic.contentLightBg,
				titleColor: textColors.primary,
				bodyColor: textColors.secondary,
				ctaTextColor: this.currentTheme.colors.basic.accent,
				iconTextColor: iconColor
					? rgb(
							iconColor.red,
							iconColor.green,
							iconColor.blue,
							iconColor.alpha
						)
					: undefined,
			});

			if (onPress) {
				this.pressHandlers.set(value.id, onPress);
			}

			if (onHidden) {
				this.hiddenHandlers.set(value.id, onHidden);
			}

			const close = () => {
				NativeInAppNotifications.removeNotification({ id: value.id });
				this.handleNotificationHidden(value.id);
			};

			setTimeout(close, 10000);

			return close;
		} catch (error) {
			if (onError) {
				onError(error as { message: string });
			}
			return () => {};
		}
	};
}
