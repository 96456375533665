import * as React from 'react';
import { XOR } from '../../tools';
import { Flex, ThemeOverride } from '@knuddels/component-library';
import './TextBadge.css';
export type TextBadgeColors = 'onDark' | 'onLight' | 'accent';
interface TextBadgeProps {
  text: string;
}
interface PresetProps extends TextBadgeProps {
  colorPreset?: TextBadgeColors;
}
interface RawProps extends TextBadgeProps {
  rawColor?: string;
}
export const TextBadge = React.memo((props: XOR<PresetProps, RawProps>) => {
  return <Flex className={'TextBadge'} {...({
    style: props.colorPreset === 'accent' ? {
      '--text-badge-bg-color': 'var(--colors-accent)',
      '--text-badge-text-color': 'var(--text-colors-primary-light)'
    } : {}
  } as any)} borderRadius={(5 as ThemeOverride)} overflow={'hidden'} placeItems={'center'}>
			<span className={'Knu-Text TextBadgeText text-tiny'} style={{
      ...textStyle
    }}>
				{props.text}
			</span>
		</Flex>;
});
TextBadge.displayName = 'TextBadge';
const textStyle = {
  letterSpacing: 1,
  paddingLeft: 4,
  paddingRight: 4
};