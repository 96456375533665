import * as React from 'react';
import { ChannelPreviewMembersFragment, ColorFragment } from '@generated/graphql';
import { ChannelThumbnail } from './ChannelThumbnail';
import { Flex, FlexCol, IconSortUp, Ripple, Rotate, Text, ThemeOverride, PointerEvent, Image, Z_INDEX, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { os, OS } from '@shared/components/tools/os';
import { ChannelListContacts } from './ChannelListContacts';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ActiveChannelService } from '@knuddelsModules/Channel';
import { observer } from '@knuddels-app/mobx';
import { ActiveChannelIndicator } from '../../../shared/ActiveChannelIndicator';
import { colorToRgbaString } from '@knuddels-app/tools/colorToRgbaString';
import { $AnimationSettingsService } from '@knuddelsModules/Settings';
type ChannelListItemProps = {
  id: string;
  name: string;
  userCount: number;
  backgroundColor?: ColorFragment;
  channelImage?: string;
  blurredChannelImage?: string;
  previewMembers?: ChannelPreviewMembersFragment;
  onEntryClick: (id: string) => void;
  onExpanderClick?: (id: string) => void;
  hasSubChannels?: boolean;
  expanded?: boolean;
  indented?: boolean;
  isAdultChannel: boolean;
  subline?: string;
  type: 'default' | 'condensed';
  isLast: boolean;
  hideImageBorder?: boolean;
};
const animate = (anchor: HTMLElement, bgColor: string) => {
  const element = document.createElement('div');
  const target = (document.querySelector('.channel-root') as HTMLElement);
  const targetRect = target?.getBoundingClientRect();
  const rect = anchor.getBoundingClientRect();

  // Calculate the scale factors
  const scaleX = targetRect.width / rect.width;
  const scaleY = targetRect.height / rect.height;

  // Calculate the translation needed to center the element
  const translateX = targetRect.left - rect.left + (targetRect.width - rect.width) / 2;
  const translateY = targetRect.top - rect.top + (targetRect.height - rect.height) / 2;

  // Set initial position and size
  element.style.position = 'absolute';
  element.style.top = `${rect.top}px`;
  element.style.left = `${rect.left}px`;
  element.style.width = `${rect.width}px`;
  element.style.height = `${rect.height}px`;
  element.style.zIndex = '9999';
  element.style.backgroundColor = bgColor;
  element.style.transformOrigin = 'center';
  document.body.appendChild(element);
  const duration = 250;
  const easing = 'cubic-bezier(0,0,0, 1.0)';
  element.style.transition = `all ${duration}ms ${easing}`;
  element.addEventListener('transitionend', () => {
    document.body.removeChild(element);
    target!.style.opacity = '1';
  }, {
    once: true
  });
  setTimeout(() => {
    document.body.removeChild(element);
    target!.style.opacity = '1';
  }, duration);
  requestAnimationFrame(() => {
    target!.style.opacity = '0';
    element.style.transform = `translate3d(${translateX}px, ${translateY}px, 0) scale(${scaleX}, ${scaleY})`;
  });
};
export const ChannelListItem: React.FC<ChannelListItemProps> = observer('ChannelListItem', props => {
  const [isActive, setIsActive] = React.useState(false);
  const activeChannelService = useService($ActiveChannelService);
  const animationSettingsService = useService($AnimationSettingsService);
  const bgColor = colorToRgbaString(props.backgroundColor) || 'transparent';
  React.useEffect(() => {
    const activeChannelId = activeChannelService.activeChannel?.id.split(':')[0];
    setIsActive(activeChannelId === props.id);
  }, [activeChannelService.activeChannel?.id, props.id]);
  const friends = props.previewMembers?.friends ?? [];
  const watchlist = props.previewMembers?.watchlist ?? [];
  const others = props.previewMembers?.others ?? [];
  const ref = React.useRef<HTMLDivElement>(null);
  return <div onClick={() => {
    if (animationSettingsService.isAnimationEnabled) {
      const willShowWarning = props.isAdultChannel && !activeChannelService.hasChannelBeenVisited(props.name);
      if (willShowWarning) {
        props.onEntryClick(props.id);
      } else {
        animate(ref.current!, bgColor);
        setTimeout(() => {
          props.onEntryClick(props.id);
        }, 0);
      }
    } else {
      props.onEntryClick(props.id);
    }
  }} ref={ref} style={{
    clipPath: 'content-box',
    background: resolveThemingValue((bgColor as ThemeOverride), "colors", useTheme())
  }} className={_c0 + ((bgColor as ThemeOverride) ? resolveIsDarkColor((bgColor as ThemeOverride), useTheme()) ? " content-is-dark" : " content-is-light" : "") + (true ? " animated-all " : "") + " " + ('channel-list-item-' + props.id) + " " + (props.indented ? _c1 : _c2) + (props.isLast ? _c3 : _c4)}>
				{!!props.blurredChannelImage && <div className={_c5}>
						<Image resizeMode={'cover'} src={props.blurredChannelImage} alt={''} className={_c6} />
					</div>}
				<div style={{
      background: resolveThemingValue(!props.channelImage ? (bgColor as ThemeOverride) : undefined, "colors", useTheme()),
      zIndex: resolveThemingValue(Z_INDEX.OVERCONTENT, "theme", useTheme())
    }} className={_c7 + ((!props.channelImage ? (bgColor as ThemeOverride) : undefined) ? resolveIsDarkColor(!props.channelImage ? (bgColor as ThemeOverride) : undefined, useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
					<ChannelThumbnail src={props.channelImage} alt={props.name} userCount={props.userCount} backgroundColor={bgColor} type={props.type === 'condensed' ? 'default' : 'large'} hideBorder={props.hideImageBorder} />
					<div className={_c8}>
						<EntryTitle name={props.name} isActive={isActive} type={props.type} subline={props.subline} />
						{(friends.length > 0 || watchlist.length > 0 || others.length > 0) && <ChannelListContacts friends={friends} watchlist={watchlist} others={others} align={'left'} />}
					</div>
				</div>
				<Ripple mode={'full'} zIndex={5} />
				<Expander {...props} />
			</div>;
});
export const EntryTitle: React.FC<{
  name: string;
  isActive: boolean;
  type: 'default' | 'condensed';
  textColor?: string;
  subline?: string;
}> = ({
  name,
  isActive,
  type,
  subline
}) => {
  const style = os === OS.web ? {
    overflowWrap: 'anywhere'
  } : {};
  return <div className={_c9}>
			<div className={_c10}>
				<Text type="subtitle" numberOfLines={1}
      // @ts-expect-error Not exposed
      style={{
        ...style
      }} className={_c11}>
					{name}
				</Text>
				{isActive && <ActiveChannelIndicator />}
			</div>
			{type === 'default' && !!subline && <Text type={'tiny'} numberOfLines={1} className={_c12}>
					{subline}
				</Text>}
		</div>;
};
const Expander: React.FC<ChannelListItemProps> = props => {
  const handleExpanderClick = (e: PointerEvent): void => {
    e.stopPropagation();
    props.onExpanderClick(props.id);
  };
  if (!props.hasSubChannels || !props.onExpanderClick) {
    return null;
  }
  return <div style={{
    zIndex: resolveThemingValue(Z_INDEX.OVERCONTENT, "theme", useTheme())
  }} className={_c13}>
			<Rotate from={180} to={0} rotated={props.expanded} className={_c14}>
				<IconSortUp ripple={false} onPress={handleExpanderClick} />
			</Rotate>
		</div>;
};
const _c0 = " Knu-FlexCol position-relative mr-none borderRadius-xlarge overflow-hidden cursor-pointer ";
const _c1 = " ml-small ";
const _c2 = " ml-none ";
const _c3 = " mb-none ";
const _c4 = " mb-small ";
const _c5 = " Knu-FlexCol position-absolute inset-none ";
const _c6 = " size-full ";
const _c7 = " Knu-Flex p-small ";
const _c8 = " Knu-FlexCol flex-1 mx-minor alignItems-flex-start justifyContent-space-between pb-tiny minWidth-0-px ";
const _c9 = " Knu-FlexCol ";
const _c10 = " Knu-Flex alignItems-center ";
const _c11 = "  ";
const _c12 = "  ";
const _c13 = " Knu-FlexCol position-absolute right-tiny top-none bottom-none width-34px height-32px justifyContent-center ";
const _c14 = " height-full alignItems-center ";