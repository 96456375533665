import { useService } from '@knuddels-app/DependencyInjection';
import { observer, reaction } from '@knuddels-app/mobx';
import { Box, Flex } from '@knuddels/component-library';
import { $NavHidingService } from '@knuddelsModules/LoggedInArea';
import { Z_INDEX } from '@shared/components';
import { navBarWidthCollapsed } from '@shared/constants';
import { motion } from 'framer-motion';
import * as React from 'react';
import { NavBarConfiguration } from './configuration';
export const NavBarHidingWrapper: React.FC<{
  config: NavBarConfiguration;
}> = ({
  config,
  children
}) => {
  if (config.navLocation === 'bottom') {
    return <NavbarAnimationWrapperBottom>
				{children}
			</NavbarAnimationWrapperBottom>;
  }
  if (config.navLocation === 'left' && config.shownItems === 'minimal') {
    return <NavbarAnimationWrapperLeft>{children}</NavbarAnimationWrapperLeft>;
  }
  return <>{children}</>;
};
const NavbarAnimationWrapperBottom: React.FC<{
  children: React.ReactNode;
}> = observer('NavbarAnimationWrapperBottom', ({
  children
}) => {
  const hidingService = useService($NavHidingService);

  /**
   * shadow={'Shadow2'}
   */
  return <div className={_c0}>
			<motion.div animate={{
      height: hidingService.isHidden ? 0 : undefined
    }} style={{
      flexDirection: 'column',
      display: 'flex',
      overflow: 'hidden',
      zIndex: Z_INDEX.NAV
    }}>
				{children}
			</motion.div>
		</div>;
});
const NavbarAnimationWrapperLeft: React.FC = ({
  children
}) => {
  const progress = useProgress();
  return <motion.div animate={{
    width: (1 - progress) * navBarWidthCollapsed
  }} transition={{
    duration: 0.25
  }} style={{
    flexDirection: 'column',
    display: 'flex',
    overflow: 'hidden',
    zIndex: Z_INDEX.NAV,
    boxShadow: 'var(--shadows-Shadow2)'
  }}>
			<div className={_c1}>
				{children}
			</div>
		</motion.div>;
};
const useProgress = () => {
  const hidingService = useService($NavHidingService);
  const [progress, setProgress] = React.useState(hidingService.isHidden ? 1 : 0);
  React.useEffect(() => {
    return reaction({
      name: 'Update nav hiding state'
    }, () => hidingService.isHidden, isHidden => {
      setProgress(isHidden ? 1 : 0);
    });
  }, []);
  return progress;
};
const _c0 = " Knu-Box shadow-Shadow2 zIndex-1 ";
const _c1 = " Knu-Flex size-full overflow-hidden ";