import * as React from 'react';
import { DarkTheme, LightTheme, Theme } from '@knuddels/component-library';
const ThemeConfigContext = React.createContext<{
  lightTheme: Theme;
  darkTheme: Theme;
}>({
  lightTheme: LightTheme,
  darkTheme: DarkTheme
});
export const ThemeConfigProvider = ThemeConfigContext.Provider;
export const useThemeConfig = () => React.useContext(ThemeConfigContext)!;