import { fromPromise } from '@knuddels-app/mobx';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import {
	NavBarItemData,
	NavBarItemProvider,
	NavBarVariant,
} from '@knuddelsModules/LoggedInArea';
import { declareFormat, formatMessage } from '@knuddels-app/i18n';
import { channelViewId } from './ChannelViewProvider';
import { $ActiveChannelService } from './providedServiceIds';
import { $ViewService } from '@knuddels-app/layout';

@injectable()
export class ChannelNavBarItemProvider implements NavBarItemProvider {
	private readonly service = fromPromise(this.getActiveChannelService());

	private fallbackText = formatMessage(
		declareFormat({
			id: 'NAVBAR_ITEM_CHAT',
			defaultFormat: 'Channels',
		})
	);

	constructor(
		@inject.lazy($ActiveChannelService)
		private readonly getActiveChannelService: typeof $ActiveChannelService.TLazy,
		@inject($ViewService)
		private readonly viewService: typeof $ViewService.T
	) {}

	private get title(): string {
		return this.service.case(
			{
				fulfilled: s =>
					this.viewService
						.findPersistedView(channelViewId)
						?.state?.topOfStack()?.path === 'chat' &&
					s.activeChannel
						? s.activeChannel.name
						: this.fallbackText,
				pending: () => this.fallbackText,
			},
			false
		);
	}

	getItems(variant: NavBarVariant): NavBarItemData[] {
		if (variant === 'desktop') {
			// Only the channel list is shown in the left nav bar, not the current channel.
			return [];
		}

		return [
			{
				id: 'chat',
				kind: 'view',
				title: this.title,
				view: channelViewId,
				trackingId: 'Chat',
			},
		];
	}
}
