import * as snackbars from './SnackbarManager';
import * as screen from './Screen';
import * as connection from './Connection';
import * as loadingScreen from './LoadingScreen';
import * as environment from '@knuddels-app/Environment';
import * as commands from './Commands/registerServices';
import * as location from './location';
import * as layout from './layout';
import * as firebase from './analytics/firebase';
import * as i18n from './i18n';
import * as keyboard from './Keyboard/registerService';
import * as overlays from './overlays/registerServices';
import * as localStorage from './local-storage';
import * as featureFlags from './featureFlags';
import * as appLoading from './AppLoadingService';
import * as nativeWebView from './NativeWebView';
import { Module } from './ModuleSystem';
import * as evergreenData from './evergreenData';
import { registerServices as genericAnalyticsRegisterServices } from './analytics/generic/registerServices';

export const coreModule = new Module({
	name: 'Core',
	dependencies: [],
	load: () => {},
	providedServices: {},
	loadStatic: ctx => {
		environment.registerServices(ctx);
		snackbars.registerServices(ctx);
		connection.registerServices(ctx);
		commands.registerServices(ctx);
		i18n.registerServices(ctx);
		screen.registerServices(ctx);
		loadingScreen.registerServices(ctx);
		keyboard.registerServices(ctx);
		location.registerServices(ctx);
		layout.registerServices(ctx);
		firebase.registerServices(ctx);
		overlays.registerServices(ctx);
		localStorage.registerServices(ctx);
		featureFlags.registerServices(ctx);
		appLoading.registerServices(ctx);
		nativeWebView.registerServices(ctx);
		genericAnalyticsRegisterServices(ctx);
		evergreenData.registerServices(ctx);
	},
});
