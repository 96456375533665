import { AppViewer } from './AppViewer';
import { AppInstance, AppInstanceData } from './AppInstance';
import { action } from '@knuddels-app/mobx';
import { $ViewService, VisibleView } from '@knuddels-app/layout';
import { appModalViewId, AppModalViewState } from '../../AppModalViewProvider';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { HIDE_TITLEBAR_APPS } from '@shared/constants';
@injectable()
export class ModalAppViewer implements AppViewer {
  private appOverlays: Map<string, {
    app: AppInstance;
    view: VisibleView;
  }> = new Map();
  constructor(@inject($ViewService)
  private viewService: typeof $ViewService.T, @inject($FirebaseAnalyticsService)
  private firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T) {}
  @action.bound
  addApp(app: AppInstance): void {
    // We don't want to track the meta data
    const appIdWithoutMetaData = stripMetaDataFromAppId(app.appId);
    this.firebaseAnalyticsService.logEvent('SystemApps_ModalView', `${appIdWithoutMetaData}_Opened`);
    this.appOverlays.set(app.appId, {
      app,
      view: this.renderViewForApp(app)
    });
  }
  @action.bound
  closeAllApps(): void {
    this.appOverlays.forEach(({
      app
    }) => app.close());
  }
  @action.bound
  closeAllAppsExceptForChannel(channelName: string | undefined): void {
    if (!channelName) {
      this.closeAllApps();
      return;
    }
    this.appOverlays.forEach(({
      app
    }) => {
      if (app.data.channelName === channelName) {
        app.close();
      }
    });
  }
  @action.bound
  closeApp(appId: string): void {
    // We don't want to track the meta data
    const appIdWithoutMetaData = stripMetaDataFromAppId(appId);
    this.firebaseAnalyticsService.logEvent('SystemApps_ModalView', `${appIdWithoutMetaData}_Closed`);
    const appOverlay = this.appOverlays.get(appId);
    if (appOverlay) {
      appOverlay.app.close();
    } else if (this.appOverlays.size === 0) {
      // Close all. This is needed because on refresh it tries
      // to open an app from state that doesn't exist here.
      this.viewService.closeView(appModalViewId);
    }
  }
  @action.bound
  removeApp(appId: string): void {
    const appOverlay = this.appOverlays.get(appId);
    if (!appOverlay) {
      return;
    }
    appOverlay.view.close();
    this.appOverlays.delete(appId);
  }
  canHandleAppEvent(app: AppInstanceData & {
    global: boolean;
    globalAppViewOpen: boolean;
  }): boolean {
    if (!app.globalAppViewOpen && (app.global || app.display.__typename === 'AppDisplaySidebar')) {
      return true;
    }
    return app.display.__typename === 'AppDisplayPopup';
  }
  getApp(appId: string): AppInstance | undefined {
    return this.appOverlays.get(appId) && this.appOverlays.get(appId)!.app;
  }
  private renderViewForApp(app: AppInstance): VisibleView {
    return this.viewService.openView(appModalViewId.with(() => new AppModalViewState(app.appId, app.uniqueId,
    /**
     * TODO: Replace with a generic solution for apps with own title bar
     */
    app.isGlobalApp && !HIDE_TITLEBAR_APPS.includes(app.appId))));
  }
  getAllApps(): AppInstance[] {
    return Array.from(this.appOverlays.values()).map(({
      app
    }) => app);
  }
}
function stripMetaDataFromAppId(appId: string): string {
  // see in chatplatform: SystemApp.META_SEPARATOR
  const separator = ':';
  const appIdWithoutMetaData = appId.split(separator)[0];
  return appIdWithoutMetaData;
}