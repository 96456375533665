import {
	ChannelListCategories,
	ChannelListCategoriesEntry,
	ChannelListCategory,
	ClientSettings,
	ClientSettingsSubscription,
	ClientUpdateSettings,
	ContactListTab,
	ContactListTabs,
	ContactListTabsEntry,
	ConversationListFilterType,
	GetClientSettings,
	InitialJoinBehavior,
	MacroBoxSettingsEntryFragment,
	PrivateMessageReplyBehavior,
	SoundEvent,
	UpdateClientSettings,
} from '@generated/graphql';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
import { $CommandService } from '@knuddels-app/Commands';
import {
	$AuthenticatedClientService,
	$AuthService,
} from '@knuddels-app/Connection';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $FeatureService } from '@knuddels-app/featureFlags';
import { $ViewService } from '@knuddels-app/layout';
import { action, observable, runInAction } from '@knuddels-app/mobx';
import { debounce } from '@knuddels-app/tools/debounce';
import { objectKeys } from '@knuddels-app/tools/objectKeys';
import { Deferred, Disposable, distinct } from '@knuddels/std';
import {
	$MiniChatSettingsService,
	settingsViewId,
} from '@knuddelsModules/Settings';
import { $UserService } from '@knuddelsModules/UserData';
import { App } from '@capacitor/app';

const MINI_CHAT_REPLY_BEHAVIOR = 'MINI_CHAT';

type MiniChatReplyBehavior = typeof MINI_CHAT_REPLY_BEHAVIOR;

type LocalClientSettings = {
	privateMessageReplyBehavior: PrivateMessageReplyBehavior;
	privateMessageReplyBehaviorMobile:
		| PrivateMessageReplyBehavior
		| MiniChatReplyBehavior;
	enableInappMessengerNotifications: boolean;
	miniChatTriggerEnabled: boolean;
};

type ClientSettingsWithLocalSettings = Omit<
	ClientSettings,
	'privateMessageReplyBehavior'
> &
	LocalClientSettings;

@injectable()
export class ClientSettingsService {
	public readonly dispose = Disposable.fn();
	public readonly deferredReady = new Deferred();
	public readonly MiniChatReplyBehavior = MINI_CHAT_REPLY_BEHAVIOR;

	@observable
	private _clientSettingsFetched = false;

	private readonly contactListTabsUpdater =
		new ClientSetting<'contactListTabs'>(
			this.userService,
			this.authenticatedClientService,
			{ contactListTabs: { tabs: [] } },
			{
				optimisticUpdate: true,
				debounced: true,
				resetValueOnError: true,
			}
		);
	private readonly channelListCategoriesUpdater =
		new ClientSetting<'channelListCategories'>(
			this.userService,
			this.authenticatedClientService,
			{ channelListCategories: { categories: [] } },
			{
				optimisticUpdate: true,
				debounced: true,
				resetValueOnError: true,
				debounceDuration: 1000,
			}
		);
	private readonly initialJoinBehaviorUpdater =
		new ClientSetting<'initialJoinBehavior'>(
			this.userService,
			this.authenticatedClientService,
			{ initialJoinBehavior: InitialJoinBehavior.DisabledUntilJoin },
			{ optimisticUpdate: true, debounced: true, resetValueOnError: true }
		);
	private readonly conversationListFilterTypeUpdater =
		new ClientSetting<'conversationListFilterType'>(
			this.userService,
			this.authenticatedClientService,
			{
				conversationListFilterType:
					ConversationListFilterType.AllMessages,
			},
			{
				optimisticUpdate: true,
			}
		);
	private readonly enabledSoundEventsUpdater =
		new ClientSetting<'enabledSoundEvents'>(
			this.userService,
			this.authenticatedClientService,
			{
				enabledSoundEvents: [
					SoundEvent.FriendRequestAccepted,
					SoundEvent.NewMessageReceived,
				],
			},
			{ optimisticUpdate: true, debounced: true, resetValueOnError: true }
		);

	private readonly mentorBarExtendedUpdater =
		new ClientSetting<'mentorBarExtended'>(
			this.userService,
			this.authenticatedClientService,
			{
				mentorBarExtended: true,
			},
			{ optimisticUpdate: true, debounced: true, resetValueOnError: true }
		);

	private readonly privateMessageReplyBehaviorUpdater =
		new MixedClientSetting<'privateMessageReplyBehavior'>(
			this.userService,
			this.authenticatedClientService,
			'privateMessageReplyBehavior',
			{
				privateMessageReplyBehavior:
					PrivateMessageReplyBehavior.Messenger,
			},
			{ optimisticUpdate: true, debounced: true, resetValueOnError: true }
		);

	private readonly privateMessageReplyBehaviorMobileUpdater =
		new LocalClientSetting<
			PrivateMessageReplyBehavior | MiniChatReplyBehavior
		>(MINI_CHAT_REPLY_BEHAVIOR, 'privateMessageReplyBehaviorMobile');

	private readonly macroBoxQuickAccessEntriesUpdater =
		new ClientSetting<'macroBoxQuickAccessEntries'>(
			this.userService,
			this.authenticatedClientService,
			{ macroBoxQuickAccessEntries: [] },
			{ optimisticUpdate: true, debounced: true, resetValueOnError: true }
		);

	private readonly macroBoxInteractionEntriesUpdater =
		new ClientSetting<'macroBoxInteractionEntries'>(
			this.userService,
			this.authenticatedClientService,
			{ macroBoxInteractionEntries: [] },
			{ optimisticUpdate: true, debounced: true, resetValueOnError: true }
		);

	private readonly enableInappMessengerNotificationsUpdater =
		new LocalClientSetting<boolean>(
			true,
			'enableInappMessengerNotifications'
		);

	private readonly macroBoxEnabledUpdater =
		new ClientSetting<'macroBoxEnabled'>(
			this.userService,
			this.authenticatedClientService,
			{ macroBoxEnabled: false },
			{ optimisticUpdate: true, debounced: true, resetValueOnError: true }
		);

	private readonly miniChatTriggerEnabledUpdater =
		new LocalClientSetting<boolean>(true, 'miniChatTriggerEnabled');

	private readonly navIconSlotUpdater = new ClientSetting<'navIconSlot'>(
		this.userService,
		this.authenticatedClientService,
		{ navIconSlot: null },
		{
			optimisticUpdate: true,
			debounced: true,
			resetValueOnError: true,
			localStorageCacheKey: 'slotKey',
			migrateCachedValue: (value: unknown) => {
				if (typeof value !== 'object') {
					return {};
				}

				const userIds = objectKeys(value);
				if (
					userIds.some(
						id =>
							typeof value[id] === 'object' &&
							'navIconSlot' in value[id]
					)
				) {
					return value as { [key: string]: { navIconSlot: string } };
				}

				const newValue: { [key: string]: { navIconSlot: string } } = {};
				userIds.forEach(id => {
					newValue[id] = { navIconSlot: value[id] };
				});
				return newValue;
			},
		}
	);

	private readonly quickAccessViewsUpdater =
		new ClientSetting<'quickAccessViews'>(
			this.userService,
			this.authenticatedClientService,
			{ quickAccessViews: [] },
			{
				optimisticUpdate: true,
				debounced: true,
				resetValueOnError: false,
				debounceDuration: 30 * 1000,
			}
		);

	constructor(
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($MiniChatSettingsService)
		private readonly miniChatSettingsService: typeof $MiniChatSettingsService.T,
		@inject($UserService)
		private readonly userService: typeof $UserService.T,
		@inject($CommandService)
		private readonly commandService: typeof $CommandService.T,
		@inject($ViewService)
		private readonly viewService: typeof $ViewService.T,
		@inject($FeatureService)
		private readonly featureService: typeof $FeatureService.T,
		@inject($GenericUserEventService)
		private readonly genericUserEventService: typeof $GenericUserEventService.T,
		@inject($AuthService)
		private readonly authService: typeof $AuthService.T
	) {
		this.dispose.track(
			this.authenticatedClientService.currentK3Client.subscribeToPrimaryData(
				ClientSettingsSubscription,
				{},
				{
					next: this.handleSubscriptionEvent,
				}
			)
		);

		this.dispose.track(
			commandService.registerCommand({
				commandName: 'knuddelzugriff',
				invoke: async (parameter: string): Promise<void> => {
					this.viewService.openViewAsOverlayWithContext(
						settingsViewId.with(s =>
							s.withConfig({
								path: 'KnuddelAccessSettings',
								params: {
									channelName: parameter,
								},
							})
						),
						'SlashCommand'
					);
				},
			})
		);

		this.dispose.track(
			commandService.registerCommand({
				commandName: 'knuddelaccount',
				invoke: async (): Promise<void> => {
					this.viewService.openViewAsOverlayWithContext(
						settingsViewId.with(s =>
							s.withPath('KnuddelAccessSettings')
						),
						'SlashCommand'
					);
				},
			})
		);

		this.dispose.track(
			this.authService.onBeforeVoluntaryLogout.sub(
				({ waitOnPromise }) => {
					waitOnPromise(this.flushQuickAccessViewChange());
				}
			)
		);

		App.addListener('appStateChange', state => {
			if (!state.isActive) {
				this.flushQuickAccessViewChange();
			}
		}).then(({ remove }) => this.dispose.track(remove));

		// On timeout and failure use default values, so there will be no infinite loading screen
		setTimeout(() => {
			if (!this._clientSettingsFetched) {
				runInAction('Client settings timeout', () => {
					this._clientSettingsFetched = true;
					if (this.deferredReady.state === 'none') {
						this.deferredReady.resolve();
					}
				});
			}
		}, 1000);

		this.initClientSettings();
	}

	private readonly handleSubscriptionEvent = (
		eventData: typeof ClientSettingsSubscription.TPrimaryResult
	): void => {
		this.updateSettings(eventData.settings);
	};

	private initClientSettings = async (): Promise<void> => {
		return this.authenticatedClientService.currentK3Client
			.queryWithResultPromise(GetClientSettings, {}, 'cache-first')
			.match({
				ok: data => this.updateSettings(data),
				error: error => {
					console.error('Failed to fetch client settings', error);
				},
			})
			.finally(() => {
				runInAction('set settings to fetched', () => {
					this._clientSettingsFetched = true;
					if (this.deferredReady.state === 'none') {
						this.deferredReady.resolve();
					}
				});
			});
	};

	public get clientSettingsFetched(): boolean {
		return this._clientSettingsFetched;
	}

	public get conversationListFilterType(): ConversationListFilterType {
		return this.conversationListFilterTypeUpdater.value
			.conversationListFilterType;
	}

	public get initialJoinBehavior(): InitialJoinBehavior {
		return this.initialJoinBehaviorUpdater.value.initialJoinBehavior;
	}

	public get contactListTabEntries(): readonly ContactListTabsEntry[] {
		return this.contactListTabsUpdater.value.contactListTabs.tabs;
	}

	public get activeContactListTabs(): ContactListTab[] {
		return this.contactListTabEntries
			.filter(it => it.active)
			.map(it => it.tab);
	}

	public get channelListCategories(): readonly ChannelListCategoriesEntry[] {
		return this.channelListCategoriesUpdater.value.channelListCategories
			.categories;
	}

	public get activeChannelListCategories(): ChannelListCategory[] {
		return this.channelListCategories
			.filter(it => it.active)
			.map(it => it.category);
	}

	public get enabledSoundEvents(): readonly SoundEvent[] {
		return this.enabledSoundEventsUpdater.value.enabledSoundEvents;
	}

	public isSoundEventEnabled(event: SoundEvent): boolean {
		return (
			this.enabledSoundEvents.includes(SoundEvent.Global) &&
			this.enabledSoundEvents.includes(event)
		);
	}

	public isSoundEnabledForApp(appId: string): boolean {
		if (!this.enabledSoundEvents.includes(SoundEvent.Global)) {
			return false;
		}

		switch (appId) {
			case 'vipMenu':
				return this.enabledSoundEvents.includes(SoundEvent.VipApp);
			case 'EngagementSystemApp':
				return this.enabledSoundEvents.includes(
					SoundEvent.EngagementSystemApp
				);
			case 'LoyaltyApp':
				return this.enabledSoundEvents.includes(SoundEvent.LoyaltyApp);
			default:
				return true;
		}
	}

	public get mentorBarExtended(): boolean {
		return this.mentorBarExtendedUpdater.value.mentorBarExtended;
	}

	public get privateMessageReplyBehavior():
		| PrivateMessageReplyBehavior
		| MiniChatReplyBehavior {
		if (this.miniChatSettingsService.isMiniChatAvailable) {
			return this.privateMessageReplyBehaviorMobileUpdater.get();
		} else {
			return this.privateMessageReplyBehaviorUpdater.value
				.privateMessageReplyBehavior;
		}
	}

	public get miniChatTriggerEnabled(): boolean {
		return this.miniChatTriggerEnabledUpdater.get();
	}

	public get macroBoxQuickAccessEntries(): readonly MacroBoxSettingsEntryFragment[] {
		return this.macroBoxQuickAccessEntriesUpdater.value
			.macroBoxQuickAccessEntries;
	}

	public get macroBoxInteractionEntries(): readonly MacroBoxSettingsEntryFragment[] {
		return this.macroBoxInteractionEntriesUpdater.value
			.macroBoxInteractionEntries;
	}

	public get macroBoxEnabled(): boolean {
		return this.macroBoxEnabledUpdater.value.macroBoxEnabled;
	}

	public get inappMessengerNotificationEnabled(): boolean {
		return this.enableInappMessengerNotificationsUpdater.get();
	}

	public get navIconSlot(): string | null {
		return this.navIconSlotUpdater.value.navIconSlot;
	}

	public get quickAccessViews(): readonly string[] {
		return this.quickAccessViewsUpdater.value.quickAccessViews;
	}

	public setConversationListFilterType(
		filterType: ConversationListFilterType
	): void {
		// We're okay with not handling errors here
		const newValue = {
			conversationListFilterType: filterType,
		};
		this.conversationListFilterTypeUpdater.updateValue(newValue, newValue);
	}

	public setInitialJoinBehavior(
		behavior: InitialJoinBehavior
	): Promise<void> {
		this.genericUserEventService.reportEvent({
			type: 'Settings_Changed',
			field: 'InitialJoinBehavior',
		});
		const newValue = {
			initialJoinBehavior: behavior,
		};
		return this.initialJoinBehaviorUpdater.updateValue(newValue, newValue);
	}

	public setContactListTabs(tabs: ContactListTabs): Promise<void> {
		this.genericUserEventService.reportEvent({
			type: 'Settings_Changed',
			field: 'ContactListTabs',
		});
		return this.contactListTabsUpdater.updateValue(
			{
				contactListTabs: {
					tabs: tabs.tabs.map(it => ({
						tab: it.tab,
						active: it.active,
						toggleable: it.toggleable,
					})),
				},
			},
			{
				contactListTabs: {
					tabs: tabs.tabs.map(it => ({
						tab: it.tab,
						active: it.active,
					})),
				},
			}
		);
	}

	public setChannelListCategories(
		categories: ChannelListCategories,
		options?: {
			skipDebounce?: boolean;
		}
	): Promise<void> {
		this.genericUserEventService.reportEvent({
			type: 'Settings_Changed',
			field: 'ChannelListCategories',
		});
		return this.channelListCategoriesUpdater.updateValue(
			{
				channelListCategories: {
					categories: categories.categories.map(it => ({
						category: it.category,
						active: it.active,
					})),
				},
			},
			{
				channelListCategories: {
					categories: categories.categories.map(it => ({
						category: it.category,
						active: it.active,
					})),
				},
			},
			options
		);
	}

	public addChannelListCategoriesMutationListener = (
		listener: () => void
	): (() => void) => {
		return this.channelListCategoriesUpdater.addMutationListener(listener);
	};

	public enableSoundEvent(event: SoundEvent): Promise<void> {
		this.genericUserEventService.reportEvent({
			type: 'Settings_Changed',
			field: 'EnabledSoundEvents',
		});
		if (this.enabledSoundEvents.includes(event)) {
			return;
		}

		const newValue = {
			enabledSoundEvents: [...this.enabledSoundEvents, event],
		};
		return this.enabledSoundEventsUpdater.updateValue(newValue, newValue);
	}

	public disableSoundEvent(event: SoundEvent): Promise<void> {
		this.genericUserEventService.reportEvent({
			type: 'Settings_Changed',
			field: 'EnabledSoundEvents',
		});
		if (!this.enabledSoundEvents.includes(event)) {
			return;
		}

		const newValue = {
			enabledSoundEvents: this.enabledSoundEvents.filter(
				e => e !== event
			),
		};
		return this.enabledSoundEventsUpdater.updateValue(newValue, newValue);
	}

	public updateMentorBarExtended(mentorBarExtended: boolean): Promise<void> {
		this.genericUserEventService.reportEvent({
			type: 'Settings_Changed',
			field: 'MentorBarExtended',
		});
		const newValue = {
			mentorBarExtended,
		};
		return this.mentorBarExtendedUpdater.updateValue(newValue, newValue);
	}

	public setPrivateMessageReplyBehavior(
		privateMessageReplyBehavior:
			| PrivateMessageReplyBehavior
			| MiniChatReplyBehavior
	): Promise<void> {
		this.genericUserEventService.reportEvent({
			type: 'Settings_Changed',
			field: 'PrivateMessageReplyBehavior',
		});
		if (this.miniChatSettingsService.isMiniChatAvailable) {
			this.privateMessageReplyBehaviorMobileUpdater.set(
				privateMessageReplyBehavior
			);
			return;
		}

		if (privateMessageReplyBehavior !== MINI_CHAT_REPLY_BEHAVIOR) {
			return this.privateMessageReplyBehaviorUpdater.updateValue(
				{ privateMessageReplyBehavior },
				{ privateMessageReplyBehavior }
			);
		}

		return;
	}

	public setMiniChatTriggerEnabled(miniChatTriggerEnabled: boolean): void {
		this.genericUserEventService.reportEvent({
			type: 'Settings_Changed',
			field: 'MiniChatTriggerEnabled',
		});
		this.miniChatTriggerEnabledUpdater.set(miniChatTriggerEnabled);
	}

	public setMacroBoxQuickAccessEntries(
		macroBoxQuickAccessEntries: readonly MacroBoxSettingsEntryFragment[]
	): Promise<void> {
		this.genericUserEventService.reportEvent({
			type: 'Settings_Changed',
			field: 'MacroBoxQuickAccessEntries',
		});
		const newValue = {
			macroBoxQuickAccessEntries,
		};
		return this.macroBoxQuickAccessEntriesUpdater.updateValue(newValue, {
			macroBoxQuickAccessEntries: {
				entries: newValue.macroBoxQuickAccessEntries.map(it => ({
					id: it.id,
					active: it.active,
				})),
			},
		});
	}

	public setMacroBoxInteractionEntries(
		macroBoxInteractionEntries: readonly MacroBoxSettingsEntryFragment[]
	): Promise<void> {
		this.genericUserEventService.reportEvent({
			type: 'Settings_Changed',
			field: 'MacroBoxInteractionEntries',
		});
		const newValue = {
			macroBoxInteractionEntries,
		};
		return this.macroBoxInteractionEntriesUpdater.updateValue(newValue, {
			macroBoxInteractionEntries: {
				entries: newValue.macroBoxInteractionEntries.map(it => ({
					id: it.id,
					active: it.active,
				})),
			},
		});
	}

	public setMacroBoxEnabled(macroBoxEnabled: boolean): Promise<void> {
		this.genericUserEventService.reportEvent({
			type: 'Settings_Changed',
			field: 'MacroBoxEnabled',
		});
		const newValue = {
			macroBoxEnabled,
		};
		return this.macroBoxEnabledUpdater.updateValue(newValue, newValue);
	}

	public setEnableInappMessengerNotifications(
		enableInappMessengerNotifications: boolean | null
	): void {
		this.genericUserEventService.reportEvent({
			type: 'Settings_Changed',
			field: 'EnableInappMessengerNotifications',
		});
		this.enableInappMessengerNotificationsUpdater.set(
			enableInappMessengerNotifications
		);
	}

	public setNavIconSlot(navIconSlot: string): void {
		const newValue = {
			navIconSlot,
		};
		this.navIconSlotUpdater.updateValue(newValue, newValue);
	}

	public addQuickAccessView(id: string): void {
		this.setQuickAccessViews(
			distinct([id, ...this.quickAccessViews], it => it)
		);
	}

	public setQuickAccessViews(quickAccessViews: readonly string[]): void {
		const newValue = {
			quickAccessViews,
		};
		this.quickAccessViewsUpdater.updateValue(newValue, newValue);
	}

	public async flushQuickAccessViewChange(): Promise<void> {
		if (this.quickAccessViewsUpdater.isUpdatePending) {
			await this.quickAccessViewsUpdater.updateValue(
				this.quickAccessViewsUpdater.value,
				this.quickAccessViewsUpdater.value,
				{ skipDebounce: true }
			);
		}
	}

	@action.bound
	private updateSettings(settings: Partial<ClientSettings>): void {
		if (settings.conversationListFilterType) {
			this.conversationListFilterTypeUpdater.setValue({
				conversationListFilterType: settings.conversationListFilterType,
			});
		}
		if (settings.initialJoinBehavior) {
			this.initialJoinBehaviorUpdater.setValue({
				initialJoinBehavior: settings.initialJoinBehavior,
			});
		}
		if (settings.contactListTabs) {
			this.contactListTabsUpdater.setValue({
				contactListTabs: settings.contactListTabs,
			});
		}
		if (settings.channelListCategories) {
			this.channelListCategoriesUpdater.setValue({
				channelListCategories: settings.channelListCategories,
			});
		}
		if (settings.enabledSoundEvents) {
			this.enabledSoundEventsUpdater.setValue({
				enabledSoundEvents: settings.enabledSoundEvents,
			});
		}
		if (typeof settings.mentorBarExtended === 'boolean') {
			this.mentorBarExtendedUpdater.setValue({
				mentorBarExtended: settings.mentorBarExtended,
			});
		}
		if (settings.privateMessageReplyBehavior) {
			this.privateMessageReplyBehaviorUpdater.setValue(
				{
					privateMessageReplyBehavior:
						settings.privateMessageReplyBehavior,
				},
				false
			);
		}
		if (settings.macroBoxQuickAccessEntries) {
			this.macroBoxQuickAccessEntriesUpdater.setValue({
				macroBoxQuickAccessEntries: settings.macroBoxQuickAccessEntries,
			});
		}
		if (settings.macroBoxInteractionEntries) {
			this.macroBoxInteractionEntriesUpdater.setValue({
				macroBoxInteractionEntries: settings.macroBoxInteractionEntries,
			});
		}
		if (typeof settings.macroBoxEnabled === 'boolean') {
			this.macroBoxEnabledUpdater.setValue({
				macroBoxEnabled: settings.macroBoxEnabled,
			});
		}
		if (typeof settings.navIconSlot === 'string') {
			this.navIconSlotUpdater.setValue({
				navIconSlot: settings.navIconSlot,
			});
		}

		if (settings.quickAccessViews) {
			this.quickAccessViewsUpdater.setValue({
				quickAccessViews: settings.quickAccessViews,
			});
		}
	}
}

class ClientSetting<
	TKey extends keyof ClientSettingsWithLocalSettings &
		keyof ClientUpdateSettings,
	TValue extends Partial<ClientSettingsWithLocalSettings> = Pick<
		ClientSettingsWithLocalSettings,
		TKey
	>,
	TMutationValue extends ClientUpdateSettings = Pick<
		ClientUpdateSettings,
		TKey
	>,
> {
	@observable
	private _value: TValue;
	private prevValue: TValue | undefined = undefined;

	private mutationListeners: (() => void)[] = [];

	private readonly cachedValues: { [key: string]: TValue } = {};

	constructor(
		private readonly userService: typeof $UserService.T,
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		initialValue: TValue,
		private options: {
			optimisticUpdate?: boolean;
			debounced?: boolean;
			resetValueOnError?: boolean;
			debounceDuration?: number;
			localStorageCacheKey?: string;
			migrateCachedValue?: (value: unknown) => { [key: string]: TValue };
		} = {}
	) {
		this._value = initialValue;

		if (options.localStorageCacheKey) {
			const cachedValue = localStorage.getItem(
				options.localStorageCacheKey
			);
			if (cachedValue !== null) {
				const parsedValue = JSON.parse(cachedValue);
				this.cachedValues = options.migrateCachedValue
					? options.migrateCachedValue(parsedValue)
					: parsedValue;
				this.userService.getCurrentUser().then(user => {
					if (this.cachedValues[user.id]) {
						runInAction('set cached value', () => {
							this._value = this.cachedValues[user.id];
						});
					}
				});
			}
		}
	}

	get value(): TValue {
		return this._value;
	}

	get isUpdatePending(): boolean {
		return !!this.prevValue;
	}

	addMutationListener(listener: () => void): () => void {
		this.mutationListeners.push(listener);
		return () => {
			const index = this.mutationListeners.indexOf(listener);
			if (index !== -1) {
				this.mutationListeners.splice(index, 1);
			}
		};
	}

	updateValue = (
		value: TValue,
		mutationValue: TMutationValue,
		options?: {
			skipDebounce?: boolean;
		}
	): Promise<void> => {
		if (!this.prevValue) {
			this.prevValue = this._value;
		}

		if (this.options.optimisticUpdate) {
			this.setValue(value);
		}

		return new Promise<void>((resolve, reject) => {
			const onSuccess = () => {
				resolve();
				this.mutationListeners.forEach(it => it());
			};

			if (this.options.debounced && !options?.skipDebounce) {
				this.debouncedMutateValue(mutationValue, onSuccess, reject);
			} else {
				this.mutateValue(mutationValue, onSuccess, reject);
			}
		})
			.catch(e => {
				if (this.options.resetValueOnError) {
					this.setValue(this.prevValue);
				}
				throw e;
			})
			.finally(() => (this.prevValue = undefined));
	};

	@action.bound
	setValue(value: TValue): void {
		this._value = value;

		if (!this.userService.currentUser) {
			return;
		}

		if (this.options.localStorageCacheKey) {
			this.cachedValues[this.userService.currentUser.id] = value;
			localStorage.setItem(
				this.options.localStorageCacheKey,
				JSON.stringify(this.cachedValues)
			);
		}
	}

	private mutateValue = (
		value: Partial<TMutationValue>,
		onSuccess: () => void,
		onError: (e: any) => void
	): void => {
		this.authenticatedClientService.currentK3Client
			.mutateWithResultPromise(UpdateClientSettings, { settings: value })
			.then(r => {
				r.match({
					ok: () => {
						onSuccess();
					},
					error: e => onError(e),
				});
				// Success is handled through an subscription event
			})
			.catch(e => {
				onError(e);
			});
	};

	// tslint:disable-next-line: member-ordering
	private debouncedMutateValue = debounce(
		this.mutateValue,
		this.options.debounceDuration || 500
	);
}

class LocalClientSetting<T> {
	@observable
	private _value: T | null = null;
	private readonly localStorageKey: keyof LocalClientSettings;

	constructor(
		initalValue: T | null,
		localStorageKey: keyof LocalClientSettings
	) {
		this.localStorageKey = localStorageKey;

		const storedValue = localStorage.getItem(this.localStorageKey);
		if (storedValue !== null) {
			this._value = JSON.parse(storedValue);
		} else {
			this._value = initalValue;
		}
	}

	public get(): T | null {
		return this._value;
	}

	@action
	public set(value: T | null): void {
		if (value === null) {
			localStorage.removeItem(this.localStorageKey);
			this._value = null;
		} else {
			localStorage.setItem(this.localStorageKey, JSON.stringify(value));
			this._value = value;
		}
	}
}

class MixedClientSetting<
	TKey extends keyof LocalClientSettings & keyof ClientUpdateSettings,
	TValue extends Partial<LocalClientSettings> = Pick<
		LocalClientSettings,
		TKey
	>,
	TMutationValue extends ClientUpdateSettings = Pick<
		ClientUpdateSettings,
		TKey
	>,
> {
	private readonly clientSetting: ClientSetting<TKey, TValue, TMutationValue>;
	private readonly localStorageSetting: LocalClientSetting<TValue[TKey]>;

	constructor(
		private readonly userService: typeof $UserService.T,
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		private readonly localStorageKey: TKey,
		initialValue: TValue,
		private readonly options: {
			optimisticUpdate?: boolean;
			debounced?: boolean;
			resetValueOnError?: boolean;
			debounceDuration?: number;
		} = {}
	) {
		this.clientSetting = new ClientSetting(
			this.userService,
			this.authenticatedClientService,
			initialValue,
			options
		);
		this.localStorageSetting = new LocalClientSetting(
			null,
			localStorageKey
		);
	}

	public get value(): TValue {
		if (this.localStorageSetting.get() === null) {
			return this.clientSetting.value;
		}

		const settingObj: Partial<ClientSettingsWithLocalSettings> = {};
		settingObj[this.localStorageKey as TKey] =
			this.localStorageSetting.get();
		return settingObj as TValue;
	}

	public setValue(value: TValue, setLocalSetting: boolean): void {
		if (setLocalSetting) {
			this.localStorageSetting.set(value[this.localStorageKey]);
		}
		this.clientSetting.setValue(value);
	}

	public updateValue = async (
		value: TValue,
		mutationValue: TMutationValue
	): Promise<void> => {
		const prevValue = this.value;
		this.localStorageSetting.set(value[this.localStorageKey]);
		this.clientSetting.updateValue(value, mutationValue).catch(() => {
			this.localStorageSetting.set(prevValue[this.localStorageKey]);
		});
	};
}

// tslint:disable-next-line: max-file-line-count
