import * as React from 'react';
import { isUsingTouch } from '@shared/components';
import { Box, Checkbox, createAccessiblePointerEventHandler, Flex, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { useState } from 'react';
import { AlbumsEditor } from '../ProfileOverlay/EditProfile/EditProfileContent/logic/AlbumsEditor';
import { observer, runInAction } from '@knuddels-app/mobx';
import { useTrackOwnProfileView } from '../shared/useTrackOwnProfileView';
import { useReleaseImage } from '@knuddels-app/tools/useReleaseImage';
type ThumbnailPhotoProps = {
  photoId: string;
  url: string;
  size: number;
  onPress: (photoId: string) => void;
  editor?: AlbumsEditor;
} & Omit<React.ComponentProps<typeof Box>, 'onPress'>;
export const ThumbnailPhoto: React.FC<ThumbnailPhotoProps> = observer('ThumbnailPhoto', props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    photoId,
    editor,
    size,
    url,
    onPress,
    ...boxProps
  } = props;
  const [hovered, setHovered] = useState(false);
  const releaseRef = useReleaseImage();
  return <Flex {...boxProps} position={'relative'} borderRadius={'base'} overflow={'hidden'} {...useBoxInteractionProps(props, setHovered)}>
				<div ref={releaseRef} style={{
      backgroundImage: `url(${url})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center top',
      height: resolveThemingValue(size, "sizes", useTheme()),
      width: resolveThemingValue(size, "sizes", useTheme())
    }} className={_c0} />

				{editor && <SelectionCheckbox photoId={photoId} editor={editor} hovered={hovered} />}
			</Flex>;
});
const SelectionCheckbox: React.FC<{
  photoId: string;
  editor: AlbumsEditor;
  hovered: boolean;
}> = observer('SelectionCheckbox', ({
  photoId,
  editor,
  hovered
}) => {
  const track = useTrackOwnProfileView();
  return (hovered || editor.selectedPhotoIds.size > 0) && <div className={_c1}>
				<Checkbox size={'large'} circular value={editor.selectedPhotoIds.has(photoId)} onChange={({
      value
    }) => {
      runInAction(`update photo selection: ${photoId}`, () => {
        if (value) {
          track('AlbumEdit_SelectImage');
          editor.selectedPhotoIds.add(photoId);
        } else {
          editor.selectedPhotoIds.delete(photoId);
        }
      });
    }} />
			</div>;
});
const useTogglePhotoSelected = (photoId: string, editor?: AlbumsEditor) => {
  const track = useTrackOwnProfileView();
  return () => {
    runInAction(`update photo selection: ${photoId}`, () => {
      if (!editor.selectedPhotoIds.has(photoId)) {
        track('AlbumEdit_SelectImage');
        editor.selectedPhotoIds.add(photoId);
      } else {
        editor.selectedPhotoIds.delete(photoId);
      }
    });
  };
};
const useBoxInteractionProps = ({
  photoId,
  editor,
  onPress
}: ThumbnailPhotoProps, setHovered: (newValue: boolean) => void): React.ComponentProps<typeof Box> => {
  const toggle = useTogglePhotoSelected(photoId, editor);
  const onBoxPress = React.useCallback(() => {
    onPress(photoId);
  }, [photoId]);
  if (!editor) {
    return createAccessiblePointerEventHandler({
      pointerCallback: onBoxPress
    });
  }
  if (!isUsingTouch()) {
    // desktop works with hover. Not using it on mobile to prevent weird hover/onLongPress interactions.
    return {
      ...createAccessiblePointerEventHandler({
        pointerCallback: onBoxPress
      }),
      onHover: () => setHovered(true),
      onHoverEnd: () => setHovered(false)
    };
  } else {
    return {
      ...createAccessiblePointerEventHandler({
        pointerCallback: () => {
          const photoSelectionActive = editor.selectedPhotoIds.size > 0;
          if (photoSelectionActive) {
            toggle();
          } else {
            onBoxPress();
          }
        }
      }),
      onSecondaryPress: e => {
        // prevent context menu to open
        e.preventDefault();
        e.stopPropagation();
        toggle();
      }
    };
  }
};
const _c0 = " Knu-Flex ";
const _c1 = " Knu-Box position-absolute top-none right-none ";