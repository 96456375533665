import { injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import * as React from 'react';
import { $OverlayService } from './serviceIds';
import { FlexCol, Modal, ModalBottomContextProvider, SafeAreaTopContext } from '@knuddels/component-library';
import { InsideModalContext } from '@knuddels-app/overlays/InsideModalContext';
import { $NativeWebViewService } from '@knuddels-app/NativeWebView';
import { getSafeArea } from '../SafeArea';
import { $ScreenService } from '@knuddels-app/Screen';
@injectable()
class OverlayPresenterModel {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(@injectProps()
  props: unknown) {}
}
export const OverlayPresenter = injectedComponent({
  model: OverlayPresenterModel,
  name: 'OverlayPresenter',
  inject: {
    service: $OverlayService,
    nativeWebView: $NativeWebViewService,
    screenService: $ScreenService
  }
}, ({
  service,
  screenService
}) => <ModalBottomContextProvider value={{
  spacing: getSafeArea().navBarHeight,
  direction: screenService.detailedOrientation === 'landscape-primary' ? 'right' : screenService.detailedOrientation === 'landscape-secondary' ? 'left' : 'bottom'
}}>
			{service.overlays.map((o, i) => <Modal zIndex={() => service.getNextZIndex()} backgroundStyle={o.backgroundType ?? 'transparent'} pointerEvents={'none'} render={i >= service.overlays.length - 2} key={o.id}>
					<InsideModalContext.Provider value>
						<SafeAreaTopContext.Provider value={0}>
							<div className={_c0}>
								{(o.addSafeAreaPadding ?? true) && <div style={{
            height: getSafeArea().safeAreaTopHeight
          }} />}
								<div className={_c1}>
									<div style={{
              display: 'contents',
              pointerEvents: 'all'
            }}>
										{o.view}
									</div>
								</div>
							</div>
						</SafeAreaTopContext.Provider>
					</InsideModalContext.Provider>
				</Modal>)}
		</ModalBottomContextProvider>);
const _c0 = " Knu-FlexCol size-full ";
const _c1 = " Knu-FlexCol flex-1 position-relative ";