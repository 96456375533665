import {
	FullConversationWithoutMessagesFragment,
	MessengerConversationState,
	MessengerOverview,
} from '@generated/graphql';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { computed, fromPromise } from '@knuddels-app/mobx';
import { Disposable } from '@knuddels/std';
import {
	NavBarItemData,
	NavBarItemProvider,
} from '@knuddelsModules/LoggedInArea';
import {
	getReadStateFromConversations,
	messengerReadStateToString,
} from './bundle/utils/useMessengerReadStateString';
import { messengerViewId } from './MessengerViewProvider';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { $FriendRequestsService } from '@knuddelsModules/Contacts';
import { getBadgeCount } from '@shared/helper/getBadgeCount';

@injectable()
export class MessengerNavBarItemProvider implements NavBarItemProvider {
	public readonly dispose = Disposable.fn();

	private readonly friendRequestService = fromPromise(
		this.getFriendRequestsService()
	);

	private readonly messengerOverviewResult = this.dispose.track(
		this.authenticatedClientService.currentK3Client.watchQuery(
			MessengerOverview,
			{
				pixelDensity: getPixelRatio(),
				filterByState: MessengerConversationState.Unread,
			}
		)
	);

	@computed get badgeState(): string | undefined {
		const val = this.messengerOverviewResult.value;
		if (val === 'loading' || val === 'error') {
			return undefined;
		}
		if (this.friendRequestService.state !== 'fulfilled') {
			return undefined;
		}

		const conversations = val.messenger
			? val.messenger.conversations.conversations
			: ([] as ReadonlyArray<FullConversationWithoutMessagesFragment>);
		const friendRequests =
			this.friendRequestService.value.friendRequestsForConversation;

		const str = messengerReadStateToString(
			getReadStateFromConversations([...conversations, ...friendRequests])
		);

		return getBadgeCount(str);
	}

	constructor(
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject.lazy($FriendRequestsService)
		private readonly getFriendRequestsService: typeof $FriendRequestsService.TLazy
	) {}

	getItems(): NavBarItemData[] {
		return [
			{
				id: 'messenger',
				kind: 'view',
				view: messengerViewId,
				badges: [{ text: getBadgeCount(this.badgeState) }],
				trackingId: 'Messenger',
			},
		];
	}
}
