import * as React from 'react';
import { Spacer } from '@shared/components';
import { ListGroup, ScrollView } from '@knuddels/component-library';
import { useNavigate } from '../SettingsRouter';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { SettingsAppInfo } from './SettingsAppInfo';
import { AdsSettings } from './AdsSettings';
import { GeneralSettings } from './GeneralSettings';
import { SectionsSettings } from './SectionsSettings';
import { LegalSettings } from './LegalSettings';
import { SettingsNavigateFunction } from '../../../SettingsRouterConfig';
import { SupportSettings } from './SupportSettings';
import { SettingsListItem } from '@knuddelsModules/Settings/bundle/components/SettingsListItem';
import { useIsStackedLayout } from '@knuddels-app/Screen';
import { IconAppLogout } from '@shared/icons/IconAppLogout';
import { $AuthService } from '@knuddels-app/Connection';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels/i18n';
export const SettingsOverview: React.FC<{
  shouldShowChannelSetting: boolean;
  shouldShowAccountSettings: boolean;
  navigate?: SettingsNavigateFunction;
}> = ({
  // eslint-disable-next-line react-hooks/rules-of-hooks
  navigate = useNavigate(),
  shouldShowChannelSetting,
  shouldShowAccountSettings
}) => {
  const track = useLogEvent();
  const isStackedLayout = useIsStackedLayout();
  React.useEffect(() => {
    track('Settings_Overview', 'Opened');
  }, []);
  return <div className={_c0}>
			<GeneralSettings shouldShowAccountSettings={shouldShowAccountSettings} navigate={navigate} />
			<SectionsSettings shouldShowChannelSetting={shouldShowChannelSetting} navigate={navigate} />
			{globalEnv.product !== 'stapp-messenger' && <SupportSettings />}
			<AdsSettings />
			<LegalSettings navigate={navigate} />
			{globalEnv.product !== 'stapp-messenger' && isStackedLayout && <Logout />}
			<Spacer size={'xxlarge'} />
			<SettingsAppInfo />
			<Spacer size={'xxxlarge'} />
		</div>;
};
const Logout: React.FC = () => {
  const authService = useService($AuthService);
  const i18n = useService($I18n);
  return <ListGroup>
			<SettingsListItem caption={declareFormat({
      id: 'settings.logout',
      defaultFormat: 'Logout'
    }).format(i18n)} onPress={() => {
      authService.logout();
    }} iconRight={<IconAppLogout size={'large'} />} />
		</ListGroup>;
};
const _c0 = " Knu-ScrollView ";