import { ContextMenuProps } from './ContextMenu';
import { getSafeArea } from '@knuddels-app/SafeArea';

export type Position = { x: number; y: number };

const minimumDistanceToScreen = 8;
const distanceToChevron = 8;

export function positionContextMenu(
	props: ContextMenuProps,
	menuWidth: number,
	menuHeight: number,
	windowWidth: number,
	windowHeight: number
): Position {
	let x = props.chevronX + props.chevronWidth - menuWidth;
	let y = props.chevronY + props.chevronHeight + 4;

	x = Math.max(minimumDistanceToScreen, x);

	if (x + menuWidth > windowWidth - minimumDistanceToScreen) {
		x = windowWidth - menuWidth - minimumDistanceToScreen;
	}

	const saveAreaBottom = getSafeArea().safeAreaBottomHeight;
	const saveAreaTop = getSafeArea().safeAreaTopHeight;

	if (
		y + menuHeight >
		windowHeight - minimumDistanceToScreen - saveAreaBottom - saveAreaTop
	) {
		y = props.chevronY - distanceToChevron - menuHeight;
	}

	return { x, y: Math.max(0, y) };
}
