import { K3ServiceBinder } from '@knuddels-app/DependencyInjection';
import { EvergreenDataService } from '@knuddels-app/evergreenData/EvergreenDataService';
import {
	$ClientActionsService,
	ClientActionsService,
} from '@knuddels-app/evergreenData/ClientActionsService';
import {
	$AppLoadingProvider,
	simpleAppLoadingProvider,
} from '@knuddels-app/AppLoadingService';
import { $EvergreenDataService } from '@knuddels-app/evergreenData/index';

export const registerServices = (ctx: K3ServiceBinder) => {
	ctx.loggedInScope
		.bind($EvergreenDataService)
		.toSingleton(EvergreenDataService);

	ctx.loggedInScope
		.bind($ClientActionsService)
		.toSingletonAutostart(ClientActionsService);

	ctx.globalScope
		.bind($AppLoadingProvider)
		.toConstant(
			simpleAppLoadingProvider($EvergreenDataService, service =>
				service.init()
			)
		);
};
