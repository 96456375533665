import { App } from '@capacitor/app';
import { Device, DeviceInfo } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import { $AuthService } from '@knuddels-app/Connection';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $EvergreenDataService } from '@knuddels-app/evergreenData';
import { reaction } from '@knuddels-app/mobx';
import { getPlatform } from '@knuddels-app/tools/clientInformation/platform';
import { getVersion } from '@knuddels-app/tools/clientInformation/version';
import { isNative } from '@knuddels-app/tools/isNative';
import { Disposable } from '@knuddels/std';
import { $UserService } from '@knuddelsModules/UserData/providedServices';
import { hashString } from '@shared/helper/hashString';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
import {
	CrashDetector,
	CrashDetectorPlugin,
} from '@knuddels/crash-detector-capacitor-plugin';
import { OS, os } from '@shared/components/tools/os';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';

const APP_STATE_KEY = 'appCrashState';
const CRASH_DETECTION_KEY = 'crashDetection';

interface AppStateRecord {
	timestamp: number;
	memoryInfo: any;
	isActive: boolean;
}

interface CrashDetectionEntry {
	startTime: number;
}

let deviceInfo: DeviceInfo;
Device.getInfo().then(di => {
	deviceInfo = di;
});

type CrashReport = Omit<AppStateRecord, 'isActive'> & {
	crashType: 'foregroundCrash' | 'backgroundCrash' | 'iOSWebviewCrash';
	appState?: string;
	deviceInfo?: DeviceInfo;
	k3ClientVersion: string;
	timeSinceLastClose: number;
	nick: string;
	userId?: string;
	usedFallbackStartTimestamp: boolean;
};

@injectable()
export class CrashDetectionService {
	public readonly dispose = Disposable.fn();
	private intervalId: NodeJS.Timeout | null = null;
	private isAppActive = true;
	private reportConfig: {
		ep: string;
		logIntervall: number;
		reportFastRestartMaxMillis: number;
	} | null = null;
	private webStartTimestamp: number | null = null;

	constructor(
		@inject($AuthService)
		private authService: typeof $AuthService.T,
		@inject($EvergreenDataService)
		private evergreenDataService: typeof $EvergreenDataService.T,
		@inject($UserService)
		private userService: typeof $UserService.T,
		@inject($GenericUserEventService)
		private genericUserEventService: typeof $GenericUserEventService.T,
		@inject($FirebaseAnalyticsService)
		private firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T
	) {
		this.setWebStartTimestamp();

		if (this.evergreenDataService.data.settings.crashes.ep !== '') {
			this.reportConfig = this.evergreenDataService.data.settings.crashes;

			this.initialize();
			this.startStateTracking();
		}

		App.addListener('appStateChange', async state => {
			this.isAppActive = state.isActive;
			await this.writeStateInfo();
		});

		this.dispose.track(
			reaction(
				{
					name: 'native only crashes',
				},
				() => {
					return (
						os === OS.ios &&
						isNative() &&
						this.evergreenDataService.data.settings.crashesV2
							.trackingMode !== 'off' &&
						this.evergreenDataService.data.settings.crashesV2.ep
							.length > 0
					);
				},
				() => {
					CrashDetector.getCrashes().then(async data => {
						try {
							for (const crash of data.crashes ?? []) {
								this.sendNativeCrashReport(crash);
							}
						} catch {
							/* empty */
						}
					});
				}
			)
		);

		this.dispose.track(
			reaction(
				{
					name: 'activate tracking when details are available',
				},
				() =>
					this.evergreenDataService.data.settings.crashes.ep !== '' &&
					this.evergreenDataService.data.settings.crashes
						.logIntervall > 0 &&
					this.evergreenDataService.data.settings.crashes
						.reportFastRestartMaxMillis > 0,
				() => {
					if (this.reportConfig !== null) {
						return;
					}

					this.reportConfig =
						this.evergreenDataService.data.settings.crashes;

					this.initialize();
					this.startStateTracking();
				}
			)
		);

		this.dispose.track(() => {
			if (this.intervalId) {
				clearInterval(this.intervalId);
			}
		});

		this.dispose.track(
			this.authService.onBeforeVoluntaryLogout.sub(async () => {
				await Preferences.set({
					key: APP_STATE_KEY,
					value: JSON.stringify({
						timestamp: null,
						isActive: false,
					}),
				});
			})
		);
	}

	private async writeStateInfo() {
		try {
			const newState: AppStateRecord = {
				timestamp: Date.now(),
				memoryInfo: await this.getMemoryInfo(),
				isActive: this.isAppActive,
			};

			await Preferences.set({
				key: APP_STATE_KEY,
				value: JSON.stringify(newState),
			});
		} catch (error) {
			console.error(
				'CrashDetectionService: Error writing state info:',
				error
			);
		}
	}

	private startStateTracking() {
		if (this.reportConfig === null) {
			console.log('CrashDetectionService: Save interval is null');
			return;
		}
		this.intervalId = setInterval(async () => {
			await this.writeStateInfo();
		}, this.reportConfig.logIntervall);
	}

	private setWebStartTimestamp() {
		if (isNative() && getPlatform() === 'ios') {
			this.webStartTimestamp = Date.now();
		}
	}

	private async initialize() {
		if (this.reportConfig === null) {
			console.log('CrashDetectionService: Threshold is null');
			return;
		}

		let usedFallbackStartTimestamp = false;

		try {
			const currentStartState = isNative()
				? await this.getNativeStartTime()
				: { startTime: Date.now() };

			const lastSessionState = await this.getStateHistory();

			if (currentStartState && lastSessionState) {
				const { startTime: currentStartTimestamp } = currentStartState;
				const { timestamp: lastSessionTimestamp } = lastSessionState;
				let timeSinceLastClose =
					currentStartTimestamp - lastSessionTimestamp;

				if (timeSinceLastClose < 0) {
					timeSinceLastClose = Date.now() - lastSessionTimestamp;
					usedFallbackStartTimestamp = true;
					if (timeSinceLastClose < 0) {
						return;
					}
				}

				if (
					timeSinceLastClose <
					this.reportConfig.reportFastRestartMaxMillis
				) {
					await this.sendCrashReport({
						state: lastSessionState,
						timeSinceLastClose,
						usedFallbackStartTimestamp,
					});
				} else if (isNative() && getPlatform() === 'ios') {
					if (this.webStartTimestamp === null) {
						console.log(
							'CrashDetectionService: Webview start time is null'
						);
						return;
					}

					const webTimeSinceLastClose =
						this.webStartTimestamp - lastSessionTimestamp;

					if (
						webTimeSinceLastClose <
						this.reportConfig.reportFastRestartMaxMillis
					) {
						await this.sendCrashReport({
							state: lastSessionState,
							isWebviewReload: true,
							timeSinceLastClose: webTimeSinceLastClose,
							usedFallbackStartTimestamp,
						});
					}
				}
			}

			await this.writeStateInfo();
		} catch (error) {
			console.error(
				'CrashDetectionService: Error initializing Service:',
				error
			);
		}
	}

	private async getMemoryInfo() {
		if (isNative()) {
			return (await Device.getInfo()).memUsed;
		} else if ('memory' in performance && performance.memory) {
			const memoryInfo = {
				totalJSHeapSize:
					(performance.memory as any).totalJSHeapSize || null,
				usedJSHeapSize:
					(performance.memory as any).usedJSHeapSize || null,
				jsHeapSizeLimit:
					(performance.memory as any).jsHeapSizeLimit || null,
			};

			return memoryInfo;
		} else {
			return null;
		}
	}

	public async getStateHistory(): Promise<AppStateRecord | null> {
		try {
			const { value } = await Preferences.get({
				key: APP_STATE_KEY,
			});
			return value ? JSON.parse(value) : null;
		} catch (error) {
			console.error(
				'CrashDetectionService: Error getting state history:',
				error
			);
			return null;
		}
	}

	private async getNativeStartTime(): Promise<CrashDetectionEntry | null> {
		try {
			const { value } = await Preferences.get({
				key: CRASH_DETECTION_KEY,
			});
			return value ? JSON.parse(value) : null;
		} catch (error) {
			console.error(
				'CrashDetectionService: Error getting crash detection entry:',
				error
			);
			return null;
		}
	}

	private sendNativeCrashReport(
		crash: Awaited<
			ReturnType<CrashDetectorPlugin['getCrashes']>
		>['crashes'][0]
	) {
		const report: CrashReport = {
			k3ClientVersion: getVersion().toSimpleString(),
			timestamp: crash.timestamp,
			timeSinceLastClose: crash.timestamp,
			usedFallbackStartTimestamp: false,
			deviceInfo,
			crashType: 'iOSWebviewCrash',
			appState: crash.state,
			memoryInfo: null,
			nick: this.userService.currentUser?.nick || '-',
			userId: this.userService.currentUser?.id,
		};

		fetch(this.evergreenDataService.data.settings.crashesV2.ep, {
			method: 'POST',
			body: JSON.stringify(report),
		});

		this.genericUserEventService.reportEvent({
			type: 'Client_Crash',
			crashType: 'iOSWebviewCrash',
			timeSinceLastClose: crash.timestamp,
			state: crash.state,
		});

		this.firebaseAnalyticsService.logCrash(crash.state);
	}

	private async sendCrashReport({
		state,
		isWebviewReload,
		timeSinceLastClose,
		usedFallbackStartTimestamp,
	}: {
		state: AppStateRecord;
		timeSinceLastClose: number;
		isWebviewReload?: boolean;
		usedFallbackStartTimestamp: boolean;
	}) {
		if (!state || !this.reportConfig?.ep) {
			return;
		}

		try {
			if ('name' in deviceInfo) {
				deviceInfo.name = deviceInfo.name
					? await hashString(deviceInfo.name)
					: 'unknown';
			}

			const crashReport: CrashReport = {
				...state,
				crashType: isWebviewReload
					? 'iOSWebviewCrash'
					: !state.isActive
						? 'backgroundCrash'
						: 'foregroundCrash',
				k3ClientVersion: getVersion().toSimpleString(),
				timeSinceLastClose: timeSinceLastClose,
				usedFallbackStartTimestamp: usedFallbackStartTimestamp,
				nick: this.userService.currentUser?.nick || '-',
				userId: this.userService.currentUser?.id,
			};

			this.genericUserEventService.reportEvent({
				type: 'Client_Crash',
				crashType: crashReport.crashType,
				timeSinceLastClose: crashReport.timeSinceLastClose,
			});

			const response = await fetch(this.reportConfig.ep, {
				method: 'POST',
				body: JSON.stringify(crashReport),
			});

			if (!response.ok) {
				console.error(
					'CrashDetectionService: Failed to send crash report',
					`Status: ${response.status}`,
					await response.text()
				);
				return;
			}
		} catch (error) {
			console.error(
				'CrashDetectionService: Error sending crash report:',
				error
			);
		}
	}
}
