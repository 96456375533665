import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $UserService } from '@knuddelsModules/UserData';
import { FormattedMessage } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels/i18n';
export const GreetingText: React.FC<{}> = () => {
  const userService = useService($UserService);
  const hourOfDay = new Date().getHours();
  let time: string;
  if (hourOfDay >= 4 && hourOfDay < 10) {
    time = 'morning';
  } else if (hourOfDay >= 10 && hourOfDay < 18) {
    time = 'day';
  } else if (hourOfDay >= 18 && hourOfDay < 23) {
    time = 'evening';
  } else {
    time = 'night';
  }
  return <FormattedMessage id={declareFormat({
    id: 'channel.dashboard.greeting',
    defaultFormat: '{time, select, morning {Good morning} day {Good day} evening {Good evening} night {Good night} other {Hello}}, {nick}!'
  })} values={{
    time,
    nick: userService.currentUser?.nick
  }} />;
};