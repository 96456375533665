export const ADZONE_CONTENT_HEIGHT = 280;
export const ADZONE_LABEL_HEIGHT = 16; // adjust this if lineHeight of text label changes
export const ADZONE_WIDTH = 300;
export const ADZONE_HEIGHT = ADZONE_CONTENT_HEIGHT + ADZONE_LABEL_HEIGHT;

export interface AdzoneProps {
	adzone:
		| 'channel'
		| 'channelNickList'
		| 'channelList'
		| 'channelNoConnectionOffContent'
		| 'channelNoConnectionMobileBanner'
		| 'channelNoConnectionLeaderboard'
		| 'profile'
		| 'messengerOverview'
		| 'macrosheet';
	reloadAd?: boolean;
	verticalAd?: boolean;
	fixedSize?: number;
	hideLoadingIndicator?: boolean;
	hideRemoveAdsBadge?: true;
	adsBadgeLocation?: 'top' | 'bottom';
}
