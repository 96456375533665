import { injectable } from '@knuddels-app/DependencyInjection';
import { Position, ViewProvider, ViewRegistry } from '@knuddels-app/layout';
import { LoadService } from '@knuddels-app/ModuleSystem';
import * as React from 'react';
import { downloadViewId } from './constants';
import * as providedServices from './providedServiceIds';
import { getRouterStateFromPersistedViewState, RouterViewState } from '@knuddels-app/layout/k3Router';
import { DownloadRouterConfig } from './DownloadRouterConfig';
@injectable()
export class DownloadViewProvider implements ViewProvider {
  registerViews(registry: ViewRegistry): void {
    registry.registerView({
      viewId: downloadViewId,
      position: Position.Side,
      loadState: DownloadViewState.fromPersistedViewState,
      render: () => ({
        mainView: <LoadService service={providedServices.$LoggedInAreaService}>
						{s => <s.DownloadView />}
					</LoadService>
      })
    });
  }
}
export class DownloadViewState extends RouterViewState<DownloadRouterConfig> {
  public static fromPersistedViewState = getRouterStateFromPersistedViewState(DownloadViewState);
}