import * as React from 'react';
import { DO_KNUDDEL } from '../../../../i18n/shared-formats';
import { IconKnuddel } from '../../../shared/IconKnuddel';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { useDoKnuddel } from '@knuddels-app/shared-hooks/useDoKnuddel';
import { SpeedDial } from '@knuddels/component-library';
import { useTrackProfileView } from '../../../shared/useTrackProfileView';
export const KnuddelFabEntry: React.FC<{
  nick: string;
}> = ({
  nick
}) => {
  const i18n = useService($I18n);
  const doKnuddel = useDoKnuddel();
  const track = useTrackProfileView();
  return <SpeedDial.Option label={i18n.format(DO_KNUDDEL)} icon={<IconKnuddel size={'large'} />} onPress={async () => {
    track('FAB_Knuddel');
    await doKnuddel(nick);
  }} />;
};