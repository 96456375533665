import * as providedServices from './providedServices';
import { Module } from '@knuddels-app/ModuleSystem/Module';
import {
	$PushTokenService,
	PushTokenService,
} from '@knuddelsModules/Notifications/PushTokenService';
import { deepLinkingModule } from '@knuddelsModules/DeepLinking/module';

export const notificationsModule = new Module({
	name: 'Notifications module',
	dependencies: [deepLinkingModule],
	providedServices,
	/*
	 * setup notifications if permission
	 * for that to work, the module needs to be registered (e.g. as knownModule or as dependency in a loading module)
	 */
	loadEvent: 'onLogin',
	load: {
		from: () =>
			import(/* webpackChunkName: "app" */ './bundle/registerServices'),
	},
	loadStatic: ctx => {
		ctx.globalScope
			.bind($PushTokenService)
			.toSingletonAutostart(PushTokenService);
	},
});
