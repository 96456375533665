import * as React from 'react';
import { SpeedDial } from '@knuddels/component-library';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { ProfileUser, useUpdateProfileUser } from '../../../profileQueryHelper';
import { $WatchlistService } from '@knuddelsModules/Contacts';
import { IconAddToWatchlist } from '@knuddels-app/shared-components/IconAddToWatchlist';
import { IconRemoveFromWatchlist } from '@knuddels-app/shared-components/IconRemoveFromWatchlist';
import { useLazyService } from '@knuddels-app/ModuleSystem';
import { ADD_TO_WATCHLIST, REMOVE_FROM_WATCHLIST } from '../../../../i18n/shared-formats';
import { useTrackProfileView, useTrackProfileViewSnackbar } from '../../../shared/useTrackProfileView';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
export const WatchlistFabEntry: React.FC<{
  user: ProfileUser;
}> = ({
  user
}) => {
  if (user.isOnWatchlist) {
    return <RemoveFromWatchlistEntry userId={user.id} />;
  } else {
    return <AddToWatchlistEntry userId={user.id} />;
  }
};
const AddToWatchlistEntry: React.FC<{
  userId: string;
}> = props => {
  const i18n = useService($I18n);
  const getWatchlistService = useLazyService($WatchlistService);
  const updateIsOnWatchlist = useUpdateIsOnWatchlist();
  const track = useTrackProfileView();
  const trackSnackbar = useTrackProfileViewSnackbar();
  const genericUserEventService = useService($GenericUserEventService);
  return <SpeedDial.Option label={i18n.format(ADD_TO_WATCHLIST)} icon={<IconAddToWatchlist size={'large'} />} onPress={async () => {
    track('FAB_AddToWatchlist');
    const service = await getWatchlistService();
    const success = await service.addToWatchlist(props.userId, trackSnackbar);
    if (success) {
      // update state manually because profile is not using the apollo cache
      updateIsOnWatchlist(true);
      genericUserEventService.reportEvent({
        type: 'Added_To_Watchlist',
        target: props.userId,
        source: 'WatchlistFabEntry'
      });
    }
  }} />;
};
const RemoveFromWatchlistEntry: React.FC<{
  userId: string;
}> = props => {
  const i18n = useService($I18n);
  const getWatchlistService = useLazyService($WatchlistService);
  const updateIsOnWatchlist = useUpdateIsOnWatchlist();
  const track = useTrackProfileView();
  const trackSnackbar = useTrackProfileViewSnackbar();
  return <SpeedDial.Option label={i18n.format(REMOVE_FROM_WATCHLIST)} icon={<IconRemoveFromWatchlist size={'large'} />} onPress={async () => {
    track('FAB_RemoveFromWatchlist');
    const service = await getWatchlistService();
    const success = service.removeFromWatchlist(props.userId, trackSnackbar);
    if (success) {
      // update state manually because profile is not using the apollo cache
      updateIsOnWatchlist(false);
    }
  }} />;
};
const useUpdateIsOnWatchlist = () => {
  const updateProfileUser = useUpdateProfileUser();
  return (newIsOnWatchList: boolean) => {
    updateProfileUser(oldData => ({
      ...oldData,
      user: {
        ...oldData.user,
        user: {
          ...oldData.user.user,
          isOnWatchlist: newIsOnWatchList
        }
      }
    }));
  };
};