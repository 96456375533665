import * as React from 'react';
import { createAccessiblePointerEventHandler, Dropdown, Flex, FlexCol, LineDivider, ListGroup, Option, Panel, PanelsList, ScrollView, SwitchListItem, Tab, Tabs, TabsList, TabText, Text, Theme, ThemeColors, useTheme, createNativeAccessiblePointerEventHandler, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { SettingDarkModeSwitch } from './Overview/SettingDarkModeSwitch';
import { HuePicker } from 'react-color';
import { useService } from '@knuddels-app/DependencyInjection';
import { observer } from '@knuddels-app/mobx';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { onMainThreadIdle } from '@knuddels-app/tools/onMainThreadIdle';
import { useThemeSwitch } from '@knuddels-app/ThemeSwitchProvider';
import { ChannelLayoutSettings } from '@knuddelsModules/Settings/bundle/components/ChannelSettings/ChannelLayoutSettings';
import { $I18n } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels/i18n';
import { $AnimationSettingsService, $ThemeSettingsService } from '@knuddelsModules/Settings/providedServiceIds';
import { $NavBarRegistry, DYNAMIC_SLOT_TYPE } from '@knuddelsModules/LoggedInArea';
import { $SmileyService } from '@knuddelsModules/AutocompleteInputBar';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
const ColorPicker: React.FC<{
  color: ThemeColors;
  selected: boolean;
  onPress(to: ThemeColors): void;
}> = ({
  color,
  onPress,
  selected
}) => {
  return <FlexCol size={40} borderRadius={'circle'} borderStyle={'solid'} borderColor={selected ? 'black-transparent-220' : 'transparent'} darkBorderColor={selected ? 'white-transparent-160' : 'transparent'} borderWidth={'large'} placeItems={'center'} handleDarkProps>
			<div {...createNativeAccessiblePointerEventHandler({
      pointerCallback: () => onPress(color)
    })} style={{
      background: resolveThemingValue(color, "colors", useTheme())
    }} className={_c0 + (color ? resolveIsDarkColor(color, useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
		</FlexCol>;
};
const AccentChooseListItem: React.FC = () => {
  const themeRef = React.useRef<Theme>();
  const theme = useTheme();
  const [selectedColor, setSelectedColor] = React.useState(theme.colors.basic.accent);
  const themeSettingsService = useService($ThemeSettingsService);
  const track = useLogEvent();
  const genericUserEventService = useService($GenericUserEventService);
  React.useEffect(() => {
    setSelectedColor(theme.colors.basic.accent);
    themeRef.current = theme;
  }, [theme]);
  const updateTheme = useThemeSwitch();
  const switchTheme = async (to: ThemeColors) => {
    const newAccent = theme.colors.basic[to] ?? to;
    updateTheme(theme.id, newAccent);
    onMainThreadIdle(() => {
      track('Settings_Theming', 'AccentColor_Changed');
      genericUserEventService.reportEvent({
        type: 'Settings_Changed',
        field: 'AccentColor'
      });
      themeSettingsService.setAccent(newAccent);
    });
  };
  const setCustomHue = async (rgb: string) => {
    switchTheme((rgb as ThemeColors));
  };
  const getColorProps = (color: ThemeColors) => {
    return {
      color,
      selected: (theme.colors.basic[color] ?? color) === theme.colors.basic.accent
    };
  };
  return <div className={_c1 + ("listGroupBg" ? resolveIsDarkColor("listGroupBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<div className={_c2 + ("transparent" ? resolveIsDarkColor("transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<Text type={'subtitle'} className={_c3}>Wähle deine Akzentfarbe</Text>
			</div>
			<div className={_c4}>
				<ColorPicker {...getColorProps('purple-400')} onPress={switchTheme} />
				<ColorPicker {...getColorProps('blue-500')} onPress={switchTheme} />
				<ColorPicker {...getColorProps('teal-500')} onPress={switchTheme} />
				<ColorPicker {...getColorProps('orange-500')} onPress={switchTheme} />
				<ColorPicker {...getColorProps('red-500')} onPress={switchTheme} />
				<ColorPicker {...getColorProps(('rgba(92, 169, 4,1)' as ThemeColors))} onPress={switchTheme} />
			</div>
			<div className={_c5}>
				<HuePicker width={'100%'} color={selectedColor} styles={{
        default: {
          picker: {
            borderRadius: theme.sizes.borderRadius.large
          }
        }
      }} onChange={e => {
        setSelectedColor(`rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, 1)`);
      }} onChangeComplete={e => {
        setCustomHue(`rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, 1)`);
      }} />
			</div>
		</div>;
};
const MenuSettings: React.FC = observer('MenuSettings', props => {
  const navBarRegistry = useService($NavBarRegistry);
  const allItems = navBarRegistry.getItems('mobile');
  const coreItems = (allItems.slice(3).filter(i => !i.disableForDynamicSlot && i.kind !== 'favorite').map(i => ({
    title: i.title,
    id: i.id
  })).filter(i => typeof i.id === 'string') as {
    title: string;
    id: string;
  }[]);
  const favorites = (allItems.filter(i => i.kind === 'favorite').map(i => ({
    title: i.title,
    id: i.id
  })).filter(i => typeof i.id === 'string') as {
    title: string;
    id: string;
  }[]);
  const getSelectedSlotType = () => {
    if (navBarRegistry.getSelectedSlotType() === DYNAMIC_SLOT_TYPE) {
      return DYNAMIC_SLOT_TYPE;
    }
    const favouriteItem = favorites.find(item => navBarRegistry.isSelectedSlotType(item.id, item.title));
    if (favouriteItem) {
      return favouriteItem.title;
    }
    const coreItem = coreItems.find(item => navBarRegistry.isSelectedSlotType(item.id, item.title));
    if (coreItem) {
      return coreItem.id;
    }
    const fallback = navBarRegistry.getFallbackBottomItem(coreItems);
    if (fallback) {
      return fallback.id;
    }
    return DYNAMIC_SLOT_TYPE;
  };
  const selectedSlotType = getSelectedSlotType();
  return <FlexCol p={'base'} {...props}>
			<div className={_c6 + ("transparent" ? resolveIsDarkColor("transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<Text type={'subtitle'} bold={true} className={_c7}>
					Welche App soll an der 4. Position der Menüleiste angezeigt
					werden?
				</Text>
			</div>
			<div className={_c8}>
				<Dropdown value={selectedSlotType.toLowerCase()} onChange={e => {
        navBarRegistry.setSelectedSlotType(e.value);
      }}>
					<Option value={DYNAMIC_SLOT_TYPE}>
						Dynamisch (zuletzt verwendete App)
					</Option>
					<LineDivider className={_c9} />
					{coreItems.map(item => <Option key={item.id} value={item.id.toLowerCase()}>
							{item.title}
						</Option>)}
					{favorites.length > 0 && <LineDivider className={_c10} />}
					{favorites.map(favorite => <Option key={favorite.id} value={favorite.title.toLowerCase()}>
							{favorite.title}
						</Option>)}
				</Dropdown>
			</div>
		</FlexCol>;
});
const skinColors = ['👋', '👋🏻', '👋🏼', '👋🏽', '👋🏾', '👋🏿'];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EmojiSettings: React.FC = observer('EmojiSettings', props => {
  const smileyService = useService($SmileyService);
  const genericUserEventService = useService($GenericUserEventService);
  return <FlexCol p={'base'} {...props} gap={'base'}>
			<div className={_c11 + ("transparent" ? resolveIsDarkColor("transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<Text type={'subtitle'} bold={true} className={_c12}>
					Standard Farbton für Emojis
				</Text>
			</div>
			<div className={_c13 + ({
      horizontal: " scroll-direction-horizontal "
    }['horizontal'] ?? "") + (true ? " hide-scroll-indicator " : "")}>
				{skinColors.map((skinColor, index) => <div {...createNativeAccessiblePointerEventHandler({
        pointerCallback: () => {
          smileyService.setEmojiSkinColorIndex(index);
          genericUserEventService.reportEvent({
            type: 'Settings_Changed',
            field: 'EmojiSkinColor'
          });
        }
      })} key={skinColor} className={_c14 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (index === smileyService.emojiSkinColorIndex ? _c15 : _c16)}>
						{skinColor}
					</div>)}
			</div>
		</FlexCol>;
});
const AnimationSettings: React.FC = observer('AnimationSettings', props => {
  const animationSettingsService = useService($AnimationSettingsService);
  return <SwitchListItem caption="Bewegung reduzieren" additionalInfo="Bewegung der Benutzeroberfläche reduzieren, z.B. beim Öffnen und Schließen eines Channels." checked={animationSettingsService.reduceMotion} onChange={() => {
    animationSettingsService.setReduceMotion(!animationSettingsService.reduceMotion);
  }} {...props} />;
});
export const DisplaySettings: React.FC = observer('DisplaySettings', () => {
  const i18n = useService($I18n);
  return <Tabs variant={'scrollable'}>
			<TabsList>
				<Tab className={_c17}>
					<TabText>
						{i18n.format(declareFormat({
            id: 'displaySettings.tabTitle.global'
          }))}
					</TabText>
				</Tab>
				<Tab className={_c18}>
					<TabText>
						{i18n.format(declareFormat({
            id: 'displaySettings.tabTitle.channel'
          }))}
					</TabText>
				</Tab>
			</TabsList>
			<PanelsList>
				<Panel>
					<div className={_c19}>
						<ListGroup title={'Modus'}>
							<SettingDarkModeSwitch />
						</ListGroup>
						<ListGroup title={'Akzent'}>
							<AccentChooseListItem />
						</ListGroup>
						<ListGroup title={'Menüleiste anpassen'}>
							<MenuSettings />
						</ListGroup>
						<ListGroup title={'Smilies und Emojis'}>
							<EmojiSettings />
						</ListGroup>
						<ListGroup title={'Animation'}>
							<AnimationSettings />
						</ListGroup>
					</div>
				</Panel>
				<Panel>
					<ChannelLayoutSettings />
				</Panel>
			</PanelsList>
		</Tabs>;
});
const _c0 = " Knu-FlexCol cursor-pointer size-30px borderRadius-circle ";
const _c1 = " Knu-FlexCol p-base bg-listGroupBg ";
const _c2 = " Knu-Flex bg-transparent alignItems-center ";
const _c3 = "  ";
const _c4 = " Knu-Flex mt-large gap-base ";
const _c5 = " Knu-Flex mt-base ";
const _c6 = " Knu-Flex bg-transparent ";
const _c7 = "  ";
const _c8 = " Knu-Flex mt-large ";
const _c9 = "  ";
const _c10 = "  ";
const _c11 = " Knu-Flex bg-transparent ";
const _c12 = "  ";
const _c13 = " Knu-ScrollView gap-small ";
const _c14 = " Knu-Flex cursor-pointer borderWidth-large borderStyle-solid bg-contentBg p-base borderRadius-large placeItems-center ";
const _c15 = " borderColor-accent ";
const _c16 = " borderColor-transparent ";
const _c17 = "  ";
const _c18 = "  ";
const _c19 = " Knu-ScrollView size-full pb-xxlarge ";