import { GetCachedUserData, GetUserForMacroBox, MacroBoxSettingsEntryFragment } from '@generated/graphql';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
import { useService } from '@knuddels-app/DependencyInjection';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { Flex } from '@knuddels/component-library';
import { $IgnoreService } from '@knuddelsModules/UserData';
import { IconMute } from '@shared/icons/IconMute';
import { IconReport } from '@shared/icons/IconReport';
import * as React from 'react';
import { MacroBoxCategory } from './MacroBoxCategory';
import { MacroBoxEntry } from './MacroBoxEntry';
import { OperationResult } from '@knuddels-app/Connection';
import { useDoKnuddel } from '@knuddels-app/shared-hooks/useDoKnuddel';
import { Knuddel } from '@shared/components';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
export const QuickAccessCategory: React.FC<{
  user: OperationResult<typeof GetCachedUserData.TPrimaryResult>['data'] & Partial<OperationResult<typeof GetUserForMacroBox.TPrimaryResult>['data']>;
  close: (goToChannel?: boolean) => void;
  entries: readonly MacroBoxSettingsEntryFragment[];
}> = ({
  user,
  close,
  entries
}) => {
  const ignoreService = useService($IgnoreService);
  const snackbarService = useService($SnackbarService);
  const i18n = useService($I18n);
  const track = useLogEvent();
  const executeSlashCommand = useSlashCommandWithoutChannel();
  const genericUserEventService = useService($GenericUserEventService);
  const doKnuddel = useDoKnuddel();
  const knuddel = async () => {
    close(true);
    await doKnuddel(user.nick);
    track('Chat_MacroQuickAccess', 'Knuddeln_Clicked');
  };
  const openReport = () => {
    close();
    executeSlashCommand(`/opensystemapp UserReport:{selectedUser:'${user.nick}'}`);
    track('Chat_MacroQuickAccess', 'Report_Clicked');
  };
  const ignore = () => {
    close();
    ignoreService.ignore(user.id, () => {
      genericUserEventService.reportEvent({
        type: 'Ignored_User',
        nick: user.nick,
        source: 'Macrobox'
      });
      snackbarService.showSnackbar({
        type: 'macrobox',
        text: i18n.format(declareFormat({
          id: 'macrobox.ignore.success.title'
        }), {
          nick: user.nick
        }),
        subtext: i18n.format(declareFormat({
          id: 'macrobox.ignore.success.subtitle'
        }))
      });
    });
    track('Chat_MacroQuickAccess', 'Ignore_Clicked');
  };
  if (entries.length === 0) {
    return null;
  }
  return <MacroBoxCategory title={declareFormat({
    id: 'channel.macrobox.category.quickaccess',
    defaultFormat: 'Quick access'
  }).format(i18n)}>
			<div className={_c0}>
				{entries.map((entry, index) => {
        switch (entry.id) {
          case 'conversation':
          case 'openProfile':
          case 'knuddel':
            return <MacroBoxEntry key={entry.id} ml={index > 0 ? 'minor' : 'none'} title={declareFormat({
              id: 'channel.macrobox.knuddel',
              defaultFormat: 'Knuddeln'
            }).format(i18n)} onPress={knuddel}>
									<Knuddel />
								</MacroBoxEntry>;
          case 'ignore':
            return <MacroBoxEntry key={entry.id} ml={index > 0 ? 'minor' : 'none'} title={declareFormat({
              id: 'channel.macrobox.ignore',
              defaultFormat: 'Ignore'
            }).format(i18n)} onPress={ignore}>
									<IconMute size={'large'} />
								</MacroBoxEntry>;
          case 'report':
            if (user?.isReportable) {
              return <MacroBoxEntry key={entry.id} ml={index > 0 ? 'minor' : 'none'} title={declareFormat({
                id: 'channel.macrobox.report',
                defaultFormat: 'Report'
              }).format(i18n)} onPress={openReport}>
										<IconReport size={'large'} />
									</MacroBoxEntry>;
            }
            break;
          default:
            return null;
        }
      })}
			</div>
		</MacroBoxCategory>;
};
const _c0 = " Knu-Flex ";