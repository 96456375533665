import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { DisconnectedBanner } from '@knuddels-app/Connection';
import { declareProps, IModel, inject, injectable, injectedComponent, injectProps, useService } from '@knuddels-app/DependencyInjection';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { observer } from '@knuddels-app/mobx';
import { useLazyService } from '@knuddels-app/ModuleSystem';
import { $NativeWebViewService } from '@knuddels-app/NativeWebView';
import { $OverlayService } from '@knuddels-app/overlays';
import { useReactiveState } from '@knuddels-app/tools/useReactiveState';
import { createAccessiblePointerEventHandler, Flex, FlexCol, IconCloseCircular, rgb, Text, Tooltip, ThemeOverride, TITLE_BAR_HEIGHT, useSafeAreaTopHeight, TitleBar, isDarkColor, useTheme, FallbackBox, createNativeAccessiblePointerEventHandler, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { Disposable } from '@knuddels/std';
import { $AdsService } from '@knuddelsModules/Ads';
import { $ChannelAppViewer, $ChannelAppViewerAnimationService, ChannelAppViewerBackgroundSpacerLoadable, ChannelAppViewerLoadable, ChannelBackgroundAppViewLoadable } from '@knuddelsModules/Apps';
import { $MessengerComponents, $MessengerMiniChatService } from '@knuddelsModules/Messenger';
import { $AnimationSettingsService, $ClientSettingsService, $MessageLayoutSettingsService } from '@knuddelsModules/Settings';
import { OverflowMenuTrigger, Z_INDEX } from '@shared/components';
import { KeyboardAvoidanceView } from '@shared/components/KeyboardAvoidanceView';
import { OS, os } from '@shared/components/tools/os';
import { dismissKeyboard } from '@shared/helper/dismissKeyboard';
import { IconInfoOutline } from '@shared/icons/IconInfoOutline';
import { IconNicklist } from '@shared/icons/IconNicklist';
import { IconReport } from '@shared/icons/IconReport';
import { motion, useAnimate } from 'framer-motion';
import * as React from 'react';
import { useRegisterBottomInputContext } from '../../../../../app/RegisterBottomInputContext';
import { $ActiveChannelService } from '../../../providedServiceIds';
import { ChannelInfo } from '../../services';
import { ChannelColorMode } from '../../services/channel/calculateChannelColorMode';
import { ChatHistory } from './ChatContent/ChatHistory';
import { ChatItem } from './ChatContent/ChatItem';
import { ChatVirtualList } from './ChatContent/ChatVirtualList';
import { ChatInput } from './ChatInput/ChatInput';
import { ChatOverflowContextMenu } from './ChatOverflowContextMenu';
import { defaultGroupInfo } from './defaultActiveChannel';
import { useDelayedsActiveChannelUserCount } from './useDelayedActiveChannelUserCount';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
import { useIsStackedLayout } from '@knuddels-app/Screen';
import { $ViewService } from '@knuddels-app/layout';
import { channelViewId } from '@knuddelsModules/Channel';
import { IconChannels } from '@shared/icons/IconChannels';
import { StatusBar, Style } from '@capacitor/status-bar';
interface Props {
  isStackedLayout: boolean;
  showNicklistIcon: boolean;
  channel: ChannelInfo | undefined;
  channelColor: string | undefined;
  colorMode: ChannelColorMode;
  mayShowApps: boolean;
  visibleToUser: boolean;
  isInBackground: boolean;
  clickNicklistIcon(): void;
}
@injectable()
class ChatViewModel implements IModel {
  public readonly dispose = Disposable.fn();
  public readonly scrollViewRef = React.createRef<ChatVirtualList<ChatItem>>();
  constructor(@injectProps()
  private readonly props: Props & {
    channel: ChannelInfo | undefined;
  }, @inject($OverlayService)
  private readonly overlayService: typeof $OverlayService.T, @inject($FirebaseAnalyticsService)
  private firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T) {}
  public scrollToBottom = (): void => {
    if (this.scrollViewRef.current) {
      this.scrollViewRef.current.scrollToBottom();
    }
  };
  public showOverflowContextMenu = (x: number, y: number, width: number, height: number) => {
    this.firebaseAnalyticsService.logEvent('Chat_Chatraum', 'Titlebar_MoreMenuOpened');
    this.overlayService.showOverlay({
      view: <ChatOverflowContextMenu chevronX={x} chevronY={y} chevronWidth={width} chevronHeight={height} />,
      backgroundType: os === OS.ios ? 'opaque' : undefined
    });
  };
}
export const ChatView = injectedComponent({
  name: 'ChatView',
  model: ChatViewModel,
  props: declareProps<Props>(),
  inject: {
    i18n: $I18n,
    activeChannelService: $ActiveChannelService
  }
}, ({
  channel,
  model,
  showNicklistIcon,
  clickNicklistIcon,
  activeChannelService,
  channelColor,
  visibleToUser,
  mayShowApps,
  isInBackground,
  i18n
}) => {
  const {
    name = '',
    groupInfo = defaultGroupInfo
  } = channel || {};
  const inputBlurRef = React.useRef<{
    blur(): void;
  }>();
  const registerBottomInput = useRegisterBottomInputContext();
  const overlayService = useService($OverlayService);
  const nativeWebViewService = useService($NativeWebViewService);
  const theme = useTheme();
  React.useEffect(() => {
    if (!visibleToUser) {
      StatusBar.setStyle({
        style: theme.id === 'dark' ? Style.Dark : Style.Light
      });
    } else {
      StatusBar.setStyle({
        style: isDarkColor(channelColor) ? Style.Dark : Style.Light
      });
    }
  }, [channelColor, visibleToUser]);
  const [{
    width,
    height
  }, setSize] = React.useState({
    width: 0,
    height: 0
  });
  const MessengerComponents = useService($MessengerComponents);
  const keyboardService = useService($KeyboardService);
  const clientSettingsService = useService($ClientSettingsService);
  const maxSplitViewPercentage = keyboardService.showSmileyOrFeatureBox && !keyboardService.supportsVirtualKeyboard ? 0.4 : 0.55;
  const openReportContent = useOpenReportContent();
  const openChannelInfo = useOpenChannelInfo();
  const leaveChannel = useLeaveChannel();
  const openChannelList = useOpenChannelList();
  const miniChatOverlay = useService($MessengerMiniChatService);
  const channelAppViewer = useService($ChannelAppViewer);
  const isStackedLayout = useIsStackedLayout();
  const messageLayoutSettingsService = useService($MessageLayoutSettingsService);
  useReactiveState(() => {
    if (isInBackground || !nativeWebViewService.isEnabled) {
      return;
    }
    if (overlayService.overlays.length > 0 || overlayService.isSystemAppOverlayVisible) {
      nativeWebViewService.updateOverlayState('visible');
    } else {
      nativeWebViewService.updateOverlayState('hidden');
    }
  }, [isInBackground]);
  React.useLayoutEffect(() => {
    if (!nativeWebViewService.isEnabled) {
      return;
    }
    if (isInBackground) {
      dismissKeyboard();
      nativeWebViewService.updateBackgroundState('background');
    } else {
      nativeWebViewService.updateBackgroundState('active');
      return registerBottomInput();
    }
  }, [isInBackground]);
  return <div className={_c0}>
				<KeyboardAvoidanceView enabled={!isInBackground && !miniChatOverlay.isOverlayVisible} flip style={{
      flex: 0,
      position: 'relative',
      zIndex: Z_INDEX.TITLE_BAR,
      flexDirection: 'column',
      background: activeChannelService.backgroundColorAsRgb
    }}>
					<TitlebarAnimationWrapper>
						<TitleBar
        // @ts-expect-error not exposed
        bg={channelColor} title={name} navIcon={isStackedLayout ? <IconChannels label={'Zur Channelauswahl'} size={'large'} onPress={openChannelList} /> : <IconCloseCircular label={'Channel verlassen'} size={'large'} onPress={leaveChannel} />} iconList={[<IconInfoOutline key={'info'} label={'Channel-Info anzeigen'} size={'large'} onPress={openChannelInfo} />, <IconReport key={'report'} label={'Channel melden'} size={'large'} onPress={openReportContent} />, <OverflowMenuTrigger key={'overflow'} showOverflowMenu={model.showOverflowContextMenu} />, showNicklistIcon && <NickListIcon key={'nicklist'} onPress={() => {
          inputBlurRef.current?.blur();
          clickNicklistIcon();
        }} />]} subtitle={groupInfo.isMyChannel ? declareFormat({
          id: 'channel.subtitle.mychannel',
          defaultFormat: 'Channel created by member'
        }).format(i18n) : undefined} />
					</TitlebarAnimationWrapper>
				</KeyboardAvoidanceView>
				<div className={_c1}>
					<FallbackBox onLayout={e => setSize(e)} className={_c2}>
						{mayShowApps && <ChannelAppViewerBackgroundSpacerLoadable maxWidth={width} maxHeight={height} maxSplitViewPercentage={maxSplitViewPercentage} />}
						<DisconnectedBanner />

						<div className={_c3}>
							{clientSettingsService.privateMessageReplyBehavior === 'MINI_CHAT' && clientSettingsService.miniChatTriggerEnabled && <div className={_c4}>
										<KeyboardAvoidanceView flip style={{
              position: 'relative',
              zIndex: Z_INDEX.HIGHEST_CONTENT
            }} enabled={!isInBackground && !miniChatOverlay.isOverlayVisible && channelAppViewer.apps.length === 0}>
											<MessengerComponents.MiniChatTrigger channelColor={channelColor} />
										</KeyboardAvoidanceView>
									</div>}

							<ChannelBackgroundAppViewLoadable />

							<div className={_c5}>
								<ChatHistory key={channel.id + messageLayoutSettingsService.renderMode} scrollViewRef={model.scrollViewRef} channel={channel} getMessages={currentChannel => currentChannel.getMessages()} />
							</div>
						</div>

						{mayShowApps && width > 0 && <ChannelAppViewerLoadable maxWidth={width} maxHeight={height} backgroundColor={groupInfo.backgroundColor} highlightColor={groupInfo.highlightColor} maxSplitViewPercentage={maxSplitViewPercentage} />}

						<ChatInput backgroundColor={groupInfo.backgroundColor} mode={keyboardService.modeInChannel} blurRef={inputBlurRef} channel={channel} messageWasSent={model.scrollToBottom} onSmileyboxOpened={() => {}} onSmileyboxClosed={() => {}} />
					</FallbackBox>
				</div>
			</div>;
});
const useOpenReportContent = () => {
  const activeChannel = useService($ActiveChannelService).activeChannel;
  const track = useLogEvent();
  return () => {
    if (activeChannel) {
      activeChannel.send('/admincall new');
    }
    track('Chat_Chatraum', 'Titlebar_ReportContentClicked');
  };
};
const useOpenChannelInfo = () => {
  const activeChannel = useService($ActiveChannelService).activeChannel;
  const track = useLogEvent();
  return () => {
    if (activeChannel) {
      activeChannel.send('/info ' + activeChannel.mainChannelName);
    }
    track('Chat_Chatraum', 'Titlebar_ChannelInfoClicked');
  };
};
const animate = (targetSelector: () => HTMLElement, bgColor: string) => {
  const anchor = (document.querySelector('.channel-root') as HTMLElement);
  const element = document.createElement('div');
  const rect = anchor.getBoundingClientRect();
  element.style.position = 'absolute';
  element.style.top = `${rect?.top}px`;
  element.style.left = `${rect?.left}px`;
  element.style.width = `${rect?.width}px`;
  element.style.height = `${rect?.height}px`;
  element.style.zIndex = '9999';
  element.style.backgroundColor = bgColor;
  element.style.borderRadius = '18px';
  document.body.appendChild(element);
  element.style.transition = 'all 200ms cubic-bezier(0, 0, 0, 1)';
  element.addEventListener('transitionend', () => {
    document.body.removeChild(element);
  }, {
    once: true
  });
  setTimeout(() => {
    const target = targetSelector();
    if (!target) {
      element.remove();
      return;
    }
    const targetRect = target?.getBoundingClientRect();

    // Calculate the scale factors
    const scaleX = (targetRect.width - 32) / rect.width;
    const scaleY = targetRect.height / rect.height;

    // Calculate the translation needed to center the element
    const translateX = targetRect.left - rect.left + (targetRect.width - rect.width) / 2;
    const translateY = targetRect.top - rect.top + (targetRect.height - rect.height) / 2;
    requestAnimationFrame(() => {
      element.style.transform = `translate3d(${translateX}px, ${translateY}px, 0) scale(${scaleX}, ${scaleY})`;
    });
  }, 150);
};
const useLeaveChannel = () => {
  const track = useLogEvent();
  const activeChannelService = useService($ActiveChannelService);
  const genericUserEventService = useService($GenericUserEventService);
  const animationSettingsService = useService($AnimationSettingsService);
  const getAdsService = useLazyService($AdsService);
  return () => {
    const id = activeChannelService.activeChannel?.id.split(':')[0];
    const bgColor = rgb(activeChannelService.activeChannel?.groupInfo.backgroundColor.red, activeChannelService.activeChannel?.groupInfo.backgroundColor.green, activeChannelService.activeChannel?.groupInfo.backgroundColor.blue, activeChannelService.activeChannel?.groupInfo.backgroundColor.alpha ?? 1);
    activeChannelService.leaveChannel();
    if (animationSettingsService.isAnimationEnabled) {
      animate(() => document.querySelector(`.channel-list-item-${id}`), bgColor);
    }
    genericUserEventService.reportEvent({
      type: 'Left_Channel',
      source: 'CloseButton_ChatView'
    });
    track('Chat_Chatraum', 'Titlebar_LeaveChannelClicked');
    getAdsService().then(service => service.showInterstitial());
  };
};
const useOpenChannelList = () => {
  const track = useLogEvent();
  const viewService = useService($ViewService);
  return () => {
    viewService.openView(channelViewId.with(s => s.openChannelList()));
    track('Chat_Chatraum', 'Titlebar_BackClicked');
  };
};
export const NickListIcon: React.FC<{
  onPress: () => void;
}> = observer('NickListIcon', ({
  onPress
}) => {
  const activeChannelService = useService($ActiveChannelService);
  const highlightColor = activeChannelService.activeChannel?.groupInfo?.highlightColor;
  const highlightRgb = highlightColor ? rgb(highlightColor.red, highlightColor.green, highlightColor.blue) : undefined;
  const userCount = useDelayedsActiveChannelUserCount();
  return <Tooltip content={'Nickliste anzeigen'} spacing={8}>
				<div {...createNativeAccessiblePointerEventHandler({
      pointerCallback: onPress
    })} style={{
      background: resolveThemingValue(highlightRgb, "colors", useTheme())
    }} className={_c6 + (highlightRgb ? resolveIsDarkColor(highlightRgb, useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
					<IconNicklist active size={'large'} />
					<Text type={'body1'} bold={true} className={_c7}>
						{userCount}
					</Text>
				</div>
			</Tooltip>;
});
const TitlebarAnimationWrapper: React.FC = ({
  children
}) => {
  const channelAppViewerAnimationService = useService($ChannelAppViewerAnimationService);
  const [scope, titleAnimate] = useAnimate();
  const safeAreaHeight = useSafeAreaTopHeight();
  React.useEffect(() => {
    return channelAppViewerAnimationService.registerToggleListener(newState => {
      titleAnimate(scope.current, {
        height: newState === 'full' ? 0 : TITLE_BAR_HEIGHT + safeAreaHeight
      });
    });
  }, []);
  return <motion.div ref={scope} transition={{
    duration: 0.25
  }} style={{
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    zIndex: Z_INDEX.TITLE_BAR,
    backgroundColor: 'transparent',
    boxShadow: 'var(--shadows-Shadow2)'
  }}>
			<div className={_c8}>
				{children}
			</div>
		</motion.div>;
};
const _c0 = " Knu-FlexCol flex-1 ";
const _c1 = " Knu-FlexCol flex-1 overflow-hidden ";
const _c2 = " Knu-FlexCol flex-1 position-relative overflow-hidden height-full ";
const _c3 = " Knu-FlexCol position-relative flex-1 overflow-hidden ";
const _c4 = " Knu-Flex position-absolute top-small right-minor ";
const _c5 = " Knu-FlexCol position-relative flex-1 ";
const _c6 = " Knu-Flex cursor-pointer position-relative borderRadius-minor borderWidth-large borderStyle-solid borderColor-white-transparent-440 alignItems-center px-small py-2px mx-small ";
const _c7 = " ml-tiny ";
const _c8 = " Knu-FlexCol size-full overflow-hidden ";