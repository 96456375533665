import * as React from 'react';
import { TopChannelCategoryFragment } from '@generated/graphql';
import { Flex, FlexCol, IconChevronRight, Image, rgb, Text, ThemeOverride, useTheme, resolveThemingValue, toPointerHandler, FallbackBox, resolveIsDarkColor } from '@knuddels/component-library';
import { useLazyService } from '@knuddels-app/ModuleSystem';
import { $AdsService } from '@knuddelsModules/Ads';
import { useService } from '@knuddels-app/DependencyInjection';
import { $JoinChannelService } from '@knuddelsModules/Channel';
import { $I18n } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels/i18n';
import { useUpdateRecommendedChannels } from '../RecommendedChannelsContext';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import styles from './HighlightChannelListItem.module.scss';
const HIGHLIGHT_GAME_SIZE = 48;
const OVERFLOW_ICON_SIZE = 24;
export const HighlightChannelListItemRow: React.FC<{
  itemHeight: number;
  itemWidth: number;
  footerHeight: number;
  maxChannelsPerRow: number;
  channels: TopChannelCategoryFragment[];
  isLast: boolean;
  trackingContentType: string;
}> = ({
  channels,
  itemWidth,
  itemHeight,
  footerHeight,
  maxChannelsPerRow,
  isLast,
  trackingContentType
}) => {
  const theme = useTheme();
  return <div className={_c0}>
			{channels.map((channel, index) => {
      return <HighlightChannelListItem key={index} isFirst={index === 0} channel={channel} itemHeight={itemHeight - (isLast ? 0 : theme.spacings.minor)} itemWidth={itemWidth} footerHeight={footerHeight} trackingContentType={trackingContentType} />;
    })}
			{new Array(maxChannelsPerRow - channels.length).fill(null).map((_, index) => {
      return <div key={index} style={{
        width: resolveThemingValue(itemWidth, "sizes", useTheme())
      }} className={_c1} />;
    })}
		</div>;
};
export const HighlightChannelListItem: React.FC<{
  isFirst: boolean;
  itemHeight: number;
  itemWidth: number;
  footerHeight: number;
  channel: TopChannelCategoryFragment;
  trackingContentType: string;
}> = ({
  channel,
  isFirst,
  footerHeight,
  itemWidth,
  itemHeight,
  trackingContentType
}) => {
  const channelService = useService($JoinChannelService);
  const getAdsService = useLazyService($AdsService);
  const updateLastVisitedChannels = useUpdateRecommendedChannels();
  const track = useLogEvent();
  const joinChannelGroup = React.useCallback(() => {
    track(trackingContentType, 'Channellist_ChannelEntered');
    channelService.joinChannelByGroupId(channel.group.id, channel.group.name, 'ChannelList_HighlightedChannel');
    updateLastVisitedChannels();
    getAdsService().then(it => it.showInterstitial());
  }, [channel]);
  const headerImage = useHeaderImage(channel);
  const hasGames = channel.games.length > 0;
  const imageHeight = itemHeight - footerHeight;
  return <div onClick={toPointerHandler(joinChannelGroup)} style={{
    width: resolveThemingValue(itemWidth, "sizes", useTheme()),
    height: resolveThemingValue(itemHeight, "sizes", useTheme()),
    background: resolveThemingValue(rgb(40, 40, 40, 0.5), "colors", useTheme())
  }} className={_c2 + (rgb(40, 40, 40, 0.5) ? resolveIsDarkColor(rgb(40, 40, 40, 0.5), useTheme()) ? " content-is-dark" : " content-is-light" : "") + (isFirst ? _c3 : _c4)}>
			<Image src={headerImage} resizeMode={'cover'} alt={''} style={{
      height: resolveThemingValue(imageHeight, "sizes", useTheme())
    }} className={_c5} />
			{channel.glowOverlay && <GlowOverlay source={channel.glowOverlay} height={imageHeight} />}
			<div style={{
      background: resolveThemingValue(rgb(40, 40, 40, 0.9), "colors", useTheme())
    }} className={_c6 + (rgb(40, 40, 40, 0.9) ? resolveIsDarkColor(rgb(40, 40, 40, 0.9), useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<Text type={'body2'} state={'secondary'} numberOfLines={2} className={_c7}>
					{channel.longDescription}
				</Text>
				{hasGames && <GamesList channel={channel} />}
			</div>
		</div>;
};
const GamesList: React.FC<{
  channel: TopChannelCategoryFragment;
}> = ({
  channel
}) => {
  const [width, setWidth] = React.useState(0);
  const maxGames = Math.floor((width - OVERFLOW_ICON_SIZE) / (HIGHLIGHT_GAME_SIZE + 4));
  const maxShownGamesCount = maxGames < channel.gameCount ? maxGames - 1 : maxGames;
  const shownGamesCount = Math.min(maxShownGamesCount, channel.games.length);
  const overflowGamesCount = channel.gameCount - shownGamesCount;
  const showOverflow = overflowGamesCount > 0;
  const shownGames = channel.games.slice(0, shownGamesCount);
  return <FallbackBox onLayout={e => setWidth(e.width)} className={_c8}>
			{shownGames.map((game, index) => <Image src={game.pictureUrl} alt={''} key={index} style={{
      height: resolveThemingValue(HIGHLIGHT_GAME_SIZE, "sizes", useTheme()),
      width: resolveThemingValue(HIGHLIGHT_GAME_SIZE, "sizes", useTheme())
    }} className={_c9 + (index === 0 ? _c10 : _c11)} />)}
			{showOverflow && <OverflowItem overflowCount={overflowGamesCount} />}
			<div className={_c12}>
				<IconChevronRight size={'large'} />
			</div>
		</FallbackBox>;
};
const OverflowItem: React.FC<{
  overflowCount: number;
}> = ({
  overflowCount
}) => {
  const i18n = useService($I18n);
  return <div style={{
    height: resolveThemingValue(HIGHLIGHT_GAME_SIZE, "sizes", useTheme()),
    width: resolveThemingValue(HIGHLIGHT_GAME_SIZE, "sizes", useTheme()),
    minWidth: resolveThemingValue(HIGHLIGHT_GAME_SIZE, "sizes", useTheme())
  }} className={_c13 + ("white-transparent-120" ? resolveIsDarkColor("white-transparent-120", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<Text bold={true} type={'subtitle'} color={'white-transparent-660'} className={_c14}>
				{`+${overflowCount}`}
			</Text>
			<Text bold={true} type={'body2'} color={'white-transparent-660'} style={{
      fontSize: 10,
      marginTop: resolveThemingValue((-3 as ThemeOverride), "spacing", useTheme())
    }} className={_c15}>
				{declareFormat({
        id: 'channellist.games',
        defaultFormat: 'Games'
      }).format(i18n).toUpperCase()}
			</Text>
		</div>;
};
const useHeaderImage = (channel: TopChannelCategoryFragment) => {
  if (globalEnv.platform !== 'capacitor') {
    return channel.headerImage;
  }

  // To improve loading time of gifs on native, we include them in the bundle
  return React.useMemo(() => {
    if (channel.headerImage.includes('channelauswahl_beastfight.gif')) {
      return require('@knuddelsModules/ChannelList/bundle/assets/channelauswahl_beastfight.gif');
    }
    if (channel.headerImage.includes('channelauswahl_crash.gif')) {
      return require('@knuddelsModules/ChannelList/bundle/assets/channelauswahl_crash.gif');
    }
    if (channel.headerImage.includes('channelauswahl_weltreise.gif')) {
      return require('@knuddelsModules/ChannelList/bundle/assets/channelauswahl_weltreise.gif');
    }
    return channel.headerImage;
  }, [channel.headerImage]);
};
const GlowOverlay: React.FC<{
  source: string;
  height: number;
}> = ({
  source,
  height
}) => {
  return <Flex position={'absolute'} insetX={'none'} top={'none'} height={height} overflow={'hidden'} {...{
    className: styles.glowoverlay
  }}>
			<Image src={source} alt={''} className={_c16} />
		</Flex>;
};
const _c0 = " Knu-Flex mb-minor px-base ";
const _c1 = " Knu-FlexCol ml-base ";
const _c2 = " Knu-FlexCol position-relative borderRadius-xlarge overflow-hidden cursor-pointer ";
const _c3 = " ml-none ";
const _c4 = " ml-base ";
const _c5 = " width-full ";
const _c6 = " Knu-FlexCol pl-base pr-base pt-base zIndex-0 flex-1 ";
const _c7 = "  ";
const _c8 = " Knu-Flex mt-minor width-full ";
const _c9 = "  ";
const _c10 = " ml-none ";
const _c11 = " ml-tiny ";
const _c12 = " Knu-Flex ml-minor alignItems-center ";
const _c13 = " Knu-FlexCol ml-tiny alignSelf-center bg-white-transparent-120 borderRadius-tiny ";
const _c14 = " textAlign-center mt-small ";
const _c15 = " textAlign-center ";
const _c16 = " size-full ";