import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ViewService, ViewId } from '@knuddels-app/layout';
import { GenericSystemApp } from '@knuddelsModules/SystemApps';
import { RouterViewState } from '@knuddels-app/layout/k3Router';
export const GenericSystemAppView: React.FC<{
  appId: string;
  viewId: ViewId<RouterViewState<any>>;
}> = ({
  viewId,
  appId
}) => {
  const viewService = useService($ViewService);
  return <GenericSystemApp onAppClose={() => viewService.closeView(viewId)} appId={appId} viewId={viewId} />;
};