import e from "react";
import { ThemeContext as o, SafeAreaTopContext as r, SafeAreaBottomContext as n } from "./ThemeProvider.js";
const i = () => {
  const t = e.use(o);
  if (!t)
    throw new Error(
      "Could not find a theme. Did you include a <ThemeProvider> at the root of your application?"
    );
  return t;
}, m = () => e.useContext(r), f = () => e.useContext(n);
export {
  f as useSafeAreaBottomHeight,
  m as useSafeAreaTopHeight,
  i as useTheme
};
