import { Flex, SvgRenderer, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import * as React from 'react';
import { $UserService, UserImage, UserImageType } from '@knuddelsModules/UserData';
import { useService } from '@knuddels-app/DependencyInjection';
export const IconMenu: React.FC<{
  active: boolean;
  hovered?: boolean;
}> = ({
  active,
  hovered
}) => {
  const userService = useService($UserService);
  return <div style={{
    mixBlendMode: active || hovered ? undefined : 'luminosity'
  }} className={_c0}>
			<UserImage type={UserImageType.NAV_MENU} userId={userService.currentUser?.id} borderRadius={'minor'} />
			<div className={_c1 + ("contentLightBg" ? resolveIsDarkColor("contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<div className={_c2 + ("accent" ? resolveIsDarkColor("accent", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
			</div>
			<div className={_c3}>
				<SvgRenderer width={32} height={32} svg={svg} color={'white'} />
			</div>
		</div>;
};
const svg = `
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 23.5H28" stroke="white" stroke-linecap="round"/>
<path d="M22 25.5H28" stroke="white" stroke-linecap="round"/>
<path d="M22 27.5H28" stroke="white" stroke-linecap="round"/>
</svg>
`;
const _c0 = " Knu-Flex size-32px placeItems-center ";
const _c1 = " Knu-Flex position-absolute right-none bottom-none size-14px borderRadius-circle bg-contentLightBg placeItems-center ";
const _c2 = " Knu-Flex size-12px borderRadius-circle bg-accent ";
const _c3 = " Knu-Flex position-absolute inset-none ";