import * as React from 'react';
import { observer } from '@knuddels-app/mobx';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ActiveChannelService } from '@knuddelsModules/Channel';
import { $UserService } from '@knuddelsModules/UserData';
import { $MessageLayoutSettingsService } from '@knuddelsModules/Settings';
import { DropdownSettings } from '../DropdownSettings';
import { FontSize, FontSizes, RenderMode, RenderModes } from '../../services/MessageLayoutSettingsService';
import { ChildThemeProvider, Flex, FlexCol, Image, ListGroup, rgb, ScrollView, SwitchListItem, Theme, useChildTheme, useTheme, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { $I18n } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels/i18n';
// tslint:disable-next-line: no-module-bleeding
import { MessengerMessageGroup } from '@knuddelsModules/Channel/bundle/components/Chat/ChatContent/ChatItems/MessengerMessageGroup';
// tslint:disable-next-line: no-module-bleeding
import { ChannelActionMessageGroup } from '@knuddelsModules/Channel/bundle/components/Chat/ChatContent/ChatItems/ChannelActionMessageGroup';
// tslint:disable-next-line: no-module-bleeding
import { ChannelInfo } from '@knuddelsModules/Channel/bundle/services';
// tslint:disable-next-line: no-module-bleeding
import { ChannelMessageGroup } from '@knuddelsModules/Channel/bundle/components/Chat/ChatContent/ChatItems/ChannelMessageGroup';
import { getMockMessages, MockMessage } from '@knuddelsModules/Settings/bundle/components/ChannelSettings/LayoutMockData';
import { ChannelBackgroundImageMode } from '@generated/graphql';
// tslint:disable-next-line: no-module-bleeding
import { ChannelBackgroundColorContext } from '@knuddelsModules/Channel/bundle/components/Chat/ChatContent/ChannelBackground';
// tslint:disable-next-line: no-module-bleeding
import { PrivateGroupMessageGroup } from '@knuddelsModules/Channel/bundle/components/Chat/ChatContent/ChatItems/PrivateGroupMessageGroup';
import { LoadService } from '@knuddels-app/ModuleSystem';
import { ChatRenderConfig } from '@knuddelsModules/Channel/bundle/components/Chat/ChatRenderConfig';
const splitRgbString = (rgbString: string) => {
  const rgbValues = rgbString.replace('rgba(', '').replace(')', '').split(',');
  return rgbValues.map(value => parseInt(value, 10));
};
const getDefaultChannelGroup = (theme: Theme): ChannelInfo['groupInfo'] => {
  const accentColor = splitRgbString(theme.colors.basic.accent);
  return {
    highlightColor: {
      red: accentColor[0],
      green: accentColor[1],
      blue: accentColor[2],
      alpha: 1
    },
    backgroundColor: {
      red: 13,
      green: 24,
      blue: 33,
      alpha: 1
    },
    backgroundImageInfo: {
      url: require('./settingsDefaultBg.jpg'),
      mode: ChannelBackgroundImageMode.Cover
    },
    highlightColorCondensedPrivateMessage: {
      red: 71,
      green: 209,
      blue: 179,
      alpha: 1
    },
    isAdultChannel: false,
    isMyChannel: false,
    isSpecialPreviewImage: false,
    previewImageInfo: {
      url: '',
      urlBlurred: ''
    }
  };
};
const renderMessage = (message: MockMessage, channelGroupInfo: ChannelInfo['groupInfo'], layoutService: typeof $MessageLayoutSettingsService.T) => {
  const renderConfig: ChatRenderConfig = {
    channelGroupInfo,
    renderMode: layoutService.renderMode,
    messageSpacingPx: layoutService.messageSpacingPx
  };
  switch (message.__typename) {
    case 'ChannelMsgPublic':
      return <ChannelMessageGroup renderConfig={renderConfig} message={(message as any)} firstOfSet animated={false} isStackedLayout={false} />;
    case 'ChannelMsgAction':
      return <ChannelActionMessageGroup renderConfig={renderConfig} message={(message as any)} animated={false} isStackedLayout={false} />;
    case 'CLIENT_MESSENGER_MESSAGE':
      return <MessengerMessageGroup renderConfig={renderConfig} event={(message as any)} animated={false} firstOfSet lastOfSet={false} isStackedLayout={false} channelGroupInfo={channelGroupInfo} />;
    case 'ChannelMsgPrivateGroup':
      return <PrivateGroupMessageGroup renderConfig={renderConfig} message={(message as any)} firstOfSet lastOfSet={false} animated={false} isStackedLayout={false} channelGroupInfo={channelGroupInfo} />;
    default:
      return <></>;
  }
};
export const ChannelLayoutSettings: React.FC = () => {
  return <LoadService service={$ActiveChannelService}>
			{() => <ChannelLayoutSettingsInternal />}
		</LoadService>;
};
const ChannelLayoutSettingsInternal: React.FC = observer('ChannelLayoutSettingsInternal', () => {
  const activeChannelService = useService($ActiveChannelService);
  const theme = useTheme();
  const channelInfo = activeChannelService?.activeChannel?.groupInfo ?? getDefaultChannelGroup(theme);
  const bgImage = channelInfo.backgroundImageInfo?.url;
  const bg = rgb(channelInfo?.backgroundColor?.red, channelInfo?.backgroundColor?.green, channelInfo?.backgroundColor?.blue);
  const userService = useService($UserService);
  const layoutService = useService($MessageLayoutSettingsService);
  const i18n = useService($I18n);
  const childTheme = useChildTheme({
    sizes: {
      texts: {
        body1: {
          fontSize: layoutService.fontSizePx,
          lineHeight: layoutService.lineHeightPx
        }
      }
    }
  }, [layoutService.fontSizePx]);
  return <div className={_c0}>
				<ListGroup title={i18n.format(declareFormat({
      id: 'channelSettings.layoutSettings.listGroupTitle'
    }))}>
					<DropdownSettings
      // @ts-expect-error Prop not exposed
      separator={'none'} title={i18n.format(declareFormat({
        id: 'channelSettings.displaySettings.title'
      }))} value={layoutService.renderMode} onChange={value => layoutService.setRenderMode((value as RenderMode))} items={RenderModes.map(mode => ({
        value: mode,
        label: i18n.format(declareFormat({
          id: 'channelSettings.renderMode'
        }), {
          mode
        })
      }))} />

					<ChildThemeProvider theme={childTheme}>
						<div style={{
          background: resolveThemingValue(bg, "colors", useTheme())
        }} className={_c1 + (bg ? resolveIsDarkColor(bg, useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
							<ChannelBackgroundColorContext.Provider value={bg}>
								{layoutService.showChannelBackground && channelInfo?.backgroundImageInfo && <div className={_c2}>
											<Image resizeMode={'cover'} alt={''} src={bgImage} className={_c3} />
										</div>}

								<div className={_c4}>
									{getMockMessages(userService, layoutService).map((message, index) => <div key={index} style={{
                marginTop: resolveThemingValue(index > 0 ? (layoutService.messageSpacingPx as any) : 0, "spacing", useTheme())
              }} className={_c5}>
											{renderMessage(message, channelInfo, layoutService)}
										</div>)}
								</div>
							</ChannelBackgroundColorContext.Provider>
						</div>
					</ChildThemeProvider>
				</ListGroup>
				<ListGroup title={i18n.format(declareFormat({
      id: 'channelSettings.readabilitySettings.listGroupTitle'
    }))}>
					<DropdownSettings title={i18n.format(declareFormat({
        id: 'channelSettings.fontSize.title'
      }))} value={layoutService.fontSize} onChange={value => layoutService.setFontSize((value as FontSize))} items={Object.keys(FontSizes).map(fontSize => ({
        value: fontSize,
        label: i18n.format(declareFormat({
          id: 'channelSettings.fontSize'
        }), {
          fontSize
        })
      }))} />
					<SwitchListItem checked={layoutService.showChannelBackground} onChange={e => layoutService.setShowChannelBackground(e)} caption={i18n.format(declareFormat({
        id: 'channelSettings.backgroundImage.title'
      }))} />
				</ListGroup>
				<div style={{
      minHeight: resolveThemingValue(childTheme.spacings.xxlarge, "sizes", useTheme())
    }} className={_c6} />
			</div>;
});
const _c0 = " Knu-ScrollView size-full pb-xxlarge ";
const _c1 = " Knu-FlexCol pointerEvents-none width-full borderBottomRadius-base overflow-hidden position-relative py-base pl-small pr-base ";
const _c2 = " Knu-FlexCol position-absolute inset-none ";
const _c3 = " size-full ";
const _c4 = " Knu-FlexCol ";
const _c5 = " Knu-FlexCol ";
const _c6 = " Knu-Flex height-full ";