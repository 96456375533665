import { $ModuleService, Module } from '@knuddels-app/ModuleSystem';
import * as providedServices from './providedServices';
import { $ViewProvider, $ViewService } from '@knuddels-app/layout';
import { GlobalAppViewProvider } from './GlobalAppViewProvider';
import {
	$NavBarItemProvider,
	$NavBarRegistry,
} from '@knuddelsModules/LoggedInArea';
import { $I18n } from '@knuddels-app/i18n';
import { notificationsModule } from '@knuddelsModules/Notifications/module';
import { featureFlagsModule } from '../FeatureFlags/module';
import {
	$SmileyTradeAppView,
	SmileyTradeAppView,
} from '@knuddelsModules/SystemApps/bundle/services';
import { appsModule } from '@knuddelsModules/Apps/module';
import { smileyTradeAppViewId } from './bundle/components/SmileyTrade/viewId';
import { SmileyTradeAppViewProvider } from './SmileyTradeAppViewProvider';
import { GenericSystemAppViewProvider } from '@knuddelsModules/SystemApps/GenericSystemAppViewProvider';
import { globalAppViewId } from './bundle/components/GlobalApp/viewId';
import { $ScreenService } from '@knuddels-app/Screen';
import { $GlobalAppsAppViewer } from '@knuddelsModules/Apps';

export const systemAppsModule = new Module({
	name: 'System app module',
	dependencies: [appsModule, notificationsModule, featureFlagsModule],
	providedServices,
	load: {
		from: () =>
			import(
				/* webpackChunkName: "systemApps" */ './bundle/registerServices'
			),
	},
	loadEvent: 'onLogin',
	loadStatic: ctx => {
		ctx.globalScope.bind($ViewProvider).toSingleton(GlobalAppViewProvider);
		ctx.globalScope
			.bind($ViewProvider)
			.toSingleton(SmileyTradeAppViewProvider);
		ctx.globalScope
			.bind($ViewProvider)
			.toSingleton(GenericSystemAppViewProvider);

		ctx.loggedInScope
			.bind($SmileyTradeAppView)
			.toSingletonAutostart(SmileyTradeAppView);

		ctx.loggedInScope.bind($NavBarItemProvider).toDynamicSingleton(
			{
				i18n: $I18n,
				screenService: $ScreenService,
				viewService: $ViewService,
				moduleService: $ModuleService,
			},
			({ viewService, moduleService }) => ({
				getItems: () => [
					{
						id: 'smileyAuction',
						kind: 'view',
						view: smileyTradeAppViewId,
						trackingId: 'SmileyTrade',
					},
					{
						id: 'globalApps',
						kind: 'view',
						trackingId: 'GlobalApps_Overview',
						view: globalAppViewId,
						openView: async location => {
							const globalAppsAppViewer =
								await moduleService.loadServiceAndModule(
									$GlobalAppsAppViewer
								);
							if (
								viewService.isViewVisible(globalAppViewId) &&
								globalAppsAppViewer.app
							) {
								viewService.openView(
									globalAppViewId.with(s =>
										s.withOnlyRootViewOpened()
									)
								);
							} else {
								moduleService
									.loadServiceAndModule($NavBarRegistry)
									.then(service =>
										service.openView(
											globalAppViewId,
											location,
											true
										)
									);
							}
						},
					},
				],
			})
		);
	},
});
