import {
	NavBarItemData,
	NavBarItemProvider,
} from '@knuddelsModules/LoggedInArea';
import { contactsViewId } from './ContactsViewProvider';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $FriendRequestsService } from './providedServices';
import { fromPromise } from '@knuddels-app/mobx';
import { $MentorService } from '@knuddelsModules/MentorSystem';
import { getBadgeCount } from '@shared/helper/getBadgeCount';

@injectable()
export class ContactsNavBarItemProvider implements NavBarItemProvider {
	private readonly friendRequestsService = fromPromise(
		this.getFriendRequestsService()
	);
	private readonly mentorService = fromPromise(this.getMentorService());

	constructor(
		@inject.lazy($FriendRequestsService)
		private readonly getFriendRequestsService: typeof $FriendRequestsService.TLazy,
		@inject.lazy($MentorService)
		private readonly getMentorService: typeof $MentorService.TLazy
	) {}

	getItems(): NavBarItemData[] {
		const requestCount = this.friendRequestsService.case({
			fulfilled: s => s.receivedFriendRequests.length,
			pending: () => 0,
			rejected: () => 0,
		});

		const mentorBadgeCount = this.mentorService.case({
			fulfilled: s =>
				s.mentorStatus?.__typename === 'MentorStatusAvailable' ? 1 : 0,
			pending: () => 0,
			rejected: () => 0,
		});

		const totalCount = requestCount + mentorBadgeCount;

		return [
			{
				id: 'contacts',
				kind: 'view',
				view: contactsViewId,
				trackingId: 'Contacts',
				badges: [{ text: getBadgeCount(totalCount) }],
			},
		];
	}
}
