import { useMutation, useQueryWithSyncedCache } from '@knuddels-app/Connection';
import { EditAlbumPhotoDescription, GetAlbumInfoForProfile } from '@generated/graphql';
import { useService } from '@knuddels-app/DependencyInjection';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import * as React from 'react';
import { AlbumDetailsUserContext } from '../AlbumDetailsUserContext';
import { SetEditingPhotoDescriptionContext, SetPhotoDescriptionHasChangesContext } from '../EditPhotoDescriptionContext';
import { Spacer } from '@shared/components';
import { Flex, FlexCol, IconSave, TextArea, useTheme, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { $I18n, declareFormat } from '@knuddels-app/i18n';
import { useTrackAlbumDetails } from '../useTrackAlbumDetails';
import { getSafeArea } from '@knuddels-app/SafeArea';
export const EditDescriptionBar: React.FC<{
  photoId: string;
  initialRawText: string | undefined;
}> = ({
  photoId,
  initialRawText
}) => {
  const i18n = useService($I18n);
  const setHasChanges = React.useContext(SetPhotoDescriptionHasChangesContext);
  const [rawText, setRawText] = React.useState(initialRawText || '');
  const saveAlbumDescription = useSaveAlbumPhotoDescription();
  const safeAreaBottom = getSafeArea().safeAreaBottomHeight;
  const theme = useTheme();
  React.useEffect(() => {
    setHasChanges(rawText !== initialRawText);
  }, [setHasChanges, rawText, initialRawText]);
  return <div style={{
    paddingBottom: resolveThemingValue((safeAreaBottom + theme.spacings.small as any), "spacing", useTheme())
  }} className={_c0 + ("black-transparent-black" ? resolveIsDarkColor("black-transparent-black", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<div className={_c1}>
				<div className={_c2}>
					<TextArea ariaLabelledBy={''} value={rawText} onChange={({
          value
        }) => setRawText(value)} maxLength={100} autoResize placeholder={declareFormat({
          id: 'album.details.edit-description.placeholder',
          defaultFormat: 'Add a description'
        }).format(i18n)} />
				</div>
				<Spacer size={'base'} />
				<IconSave size={'large'} disabled={rawText === initialRawText} onPress={() => {
        saveAlbumDescription(rawText, photoId);
      }} />
			</div>
		</div>;
};
const useSaveAlbumPhotoDescription = () => {
  const [changeDescription] = useMutation(EditAlbumPhotoDescription);
  const snackbarService = useService($SnackbarService);
  const track = useTrackAlbumDetails();
  const userId = React.useContext(AlbumDetailsUserContext).id;
  const {
    stopEditing
  } = React.useContext(SetEditingPhotoDescriptionContext);
  const profileAlbumInfoQuery = useQueryWithSyncedCache(GetAlbumInfoForProfile, {
    userId
  });
  return async (newDescription: string, photoId: string) => {
    track('ChangeDescription_Saved');
    const result = await changeDescription({
      photoId,
      newDescription
    });
    if (result.data?.__typename === 'ChangeAlbumPhotoDescriptionSuccess') {
      const description = result.data.description;
      profileAlbumInfoQuery.updateCache(oldData => ({
        ...oldData,
        albumProfilePhoto: oldData.albumProfilePhoto && oldData.albumProfilePhoto.id === photoId ? {
          ...oldData.albumProfilePhoto,
          description
        } : oldData.albumProfilePhoto,
        albums: oldData.albums.map(album => ({
          ...album,
          albumPhotos: album.albumPhotos.map(photo => {
            if (photo.id === photoId) {
              return {
                ...photo,
                description
              };
            } else {
              return photo;
            }
          })
        }))
      }));
      stopEditing();
    } else {
      snackbarService.showGenericError();
    }
  };
};
const _c0 = " Knu-FlexCol width-full position-absolute bottom-none bg-black-transparent-black left-none right-none p-small ";
const _c1 = " Knu-Flex alignItems-center ";
const _c2 = " Knu-FlexCol flex-1 ";