import { Position, ViewId, ViewProvider, ViewRegistry } from '@knuddels-app/layout';
import * as React from 'react';
import { injectable } from '@knuddels-app/DependencyInjection';
import { LoadService } from '@knuddels-app/ModuleSystem';
import * as providedServices from '@knuddelsModules/Channel/providedServiceIds';
import { Channel } from '@generated/graphql';
import { chatroomShownTimingTracker } from '@knuddelsModules/Channel/analytics';
import { getRouterStateFromPersistedViewState, RouterViewState, StackRouteConfig } from '@knuddels-app/layout/k3Router';
import { ChannelRouteConfig } from './bundle/components/ChannelRouter';
@injectable()
export class ChannelViewProvider implements ViewProvider {
  registerViews(registry: ViewRegistry): void {
    registry.registerView({
      viewId: channelViewId,
      position: Position.Main,
      persistIndex: 0,
      autostartInBackground: globalEnv.product === 'app',
      isDefault: () => globalEnv.product === 'app',
      loadState: ChannelViewState.fromPersistedViewState,
      hideDesktopBackground: state => !state.channelId,
      render: state => {
        chatroomShownTimingTracker.start();
        return {
          mainView: <LoadService service={providedServices.$ChannelComponents}>
							{s => <s.MainView routeChatId={state.channelId} isNicklistOpen={state.isNicklistOpen} />}
						</LoadService>
        };
      }
    });
  }
}
export class ChannelViewState extends RouterViewState<ChannelRouteConfig> {
  public static fromPersistedViewState = getRouterStateFromPersistedViewState(ChannelViewState);
  public get channelId(): string {
    const chatRoute = this.stack.find(r => r.path === 'chat');
    return chatRoute?.params.id ?? '';
  }
  public get isNicklistOpen(): boolean {
    const chatRoute = this.stack.find(r => r.path === 'chat');
    if (!chatRoute) {
      return false;
    }
    return ('isNickListOpen' in chatRoute.params && chatRoute.params.isNickListOpen) ?? false;
  }
  getPathItems(): string[] {
    const topOfStack = this.topOfStack();
    const channelId = topOfStack?.path === 'chat' ? topOfStack.params.id : undefined;
    if (channelId) {
      return [channelId];
    }
    return super.getPathItems();
  }
  getStackFromPathItems(): StackRouteConfig<ChannelRouteConfig>[] {
    if (this.pathItems?.[0]?.match(/[0-9]+/)) {
      return [{
        path: 'chat',
        params: {
          id: this.pathItems?.[0],
          isNickListOpen: false
        },
        key: RouterViewState.generateId()
      }];
    }
    return super.getStackFromPathItems();
  }
  public toggleNickList(): ChannelViewState {
    return this.withStack([...this.stack.map(it => {
      if (it.path === 'chat' && 'isNickListOpen' in it.params) {
        return {
          ...it,
          params: {
            ...it.params,
            isNickListOpen: !it.params.isNickListOpen
          }
        };
      }
      return it;
    })]);
  }
  public closeNickList(): ChannelViewState {
    return this.withStack([...this.stack.map(it => {
      if (it.path !== 'chat') {
        return it;
      }
      return {
        ...it,
        params: {
          ...it.params,
          isNickListOpen: false
        }
      };
    })]);
  }
  public openChannelList(): ChannelViewState {
    const reuseRoute = this.stack.find(r => r.path === 'overview');
    return this.withStack([{
      path: 'overview',
      params: undefined,
      key: reuseRoute ? reuseRoute.key : RouterViewState.generateId()
    }]);
  }
  public setChannel(channelId: Channel['id']): ChannelViewState {
    const reuseRoute = this.stack.find(r => r.path === 'chat');
    return this.withStack([...this.stack.filter(r => r.path !== 'chat'), {
      path: 'chat',
      params: {
        id: channelId,
        isNickListOpen: false
      },
      key: reuseRoute ? reuseRoute.key : RouterViewState.generateId()
    }]);
  }
  public clearChannel(): ChannelViewState {
    return this.withStack([...this.stack.filter(r => r.path !== 'chat')]);
  }
}
export const channelViewId = new ViewId<ChannelViewState>('channel');