import { jsx as u } from "react/jsx-runtime";
import { Z_INDEX as y } from "../configs/zindex.js";
import * as n from "react";
import * as m from "react-dom";
const c = n.createContext(null), d = n.createContext(
  null
), C = d.Provider, b = (r) => /* @__PURE__ */ u(c.Provider, { value: r.element, children: r.children }), h = ({ render: r = !0, ...t }) => {
  const [o, f] = n.useState(null), i = n.useContext(c), a = n.useContext(d);
  n.useLayoutEffect(() => {
    o && (r ? o.style.display = "" : o.style.display = "none");
  }, [r]);
  const s = (e, l) => {
    if (l)
      switch (l.direction) {
        case "bottom":
          e.style.bottom = l.spacing + "px";
          break;
        case "left":
          e.style.left = l.spacing + "px";
          break;
        case "right":
          e.style.right = l.spacing + "px";
          break;
      }
  };
  return n.useLayoutEffect(() => {
    const e = document.createElement("div");
    return t.innerRef && (t.innerRef.current = e), i ? e.parent = i : e.parent = null, e.style.position = "fixed", e.style.top = "0px", e.style.left = "0px", e.style.right = "0px", e.style.bottom = "0px", e.style.transform = "translateZ(0)", e.style.backfaceVisibility = "hidden", a && s(e, a), e.style.pointerEvents = t.pointerEvents ?? "all", e.style.zIndex = `${t.zIndex ? typeof t.zIndex == "function" ? t.zIndex() : t.zIndex : y.ABOVE_MODAL}`, document.body.appendChild(e), f(e), () => {
      document.body.removeChild(e);
    };
  }, []), n.useEffect(() => {
    a && o && s(o, a);
  }, [a]), o ? m.createPortal(t.children, o) : null;
};
export {
  C as ModalBottomContextProvider,
  b as ModalParentProvider,
  h as ModalWrapper
};
