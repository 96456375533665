import { Flex, FlexCol, Image, Text, ThemeColors, toPointerHandler, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import * as React from 'react';
import { LabelContainer } from './PromotionLabel';
import { LoyaltySystem } from '@knuddels/global-configs';
import TextAnimationStyles from './TextAnimation.module.scss';
import { declareFormat, FormatId } from '@knuddels/i18n';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { $EvergreenDataService, EvergreenData } from '@knuddels-app/evergreenData';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
import { observer } from '@knuddels-app/mobx';
export type LoyaltySystemLabelType = EvergreenData['loyalty']['state'] | 'NONE';
const Container: React.FC<{
  bg: ThemeColors;
  format: FormatId;
  minWidth?: boolean;
  location: 'bottom' | 'top';
}> = ({
  format,
  bg,
  location,
  minWidth = false
}) => {
  const i18n = useService($I18n);
  const execute = useSlashCommandWithoutChannel();
  const text = i18n.format(format);
  return <LabelContainer minWidth={minWidth} location={location} bg={bg}>
			<div onClick={toPointerHandler(e => {
      e.stopPropagation();
      execute('/buyknuddel sid~LoyaltySystemLabel');
    })} className={_c0 + ("transparentDark" ? resolveIsDarkColor("transparentDark", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<div className={_c1 + " " + TextAnimationStyles.TextAnimation + " "}>
					<Text type={'tiny'} bold={true} style={{
          whiteSpace: 'nowrap'
        }} className={_c2}>
						{text}
					</Text>

					<Text type={'tiny'} bold={true} style={{
          whiteSpace: 'nowrap'
        }} className={_c3}>
						{text}
					</Text>
				</div>
			</div>
		</LabelContainer>;
};
export const renderLoyaltySystemLabels = (state: LoyaltySystemLabelType, location: 'top' | 'bottom', useMinWidth: boolean = false) => {
  switch (state) {
    case 'LEVEL_LOSS':
      return <Container minWidth={useMinWidth} location={location} bg={mapColor(state)} format={declareFormat({
        id: 'knuddelsdisplay.loyalty.levelLossWarning'
      })} />;
    case 'POINT_LOSS':
      return <Container minWidth={useMinWidth} location={location} bg={mapColor(state)} format={declareFormat({
        id: 'knuddelsdisplay.loyalty.pointLossWarning'
      })} />;
    default:
      return null;
  }
};
const mapColor = (state: LoyaltySystemLabelType) => {
  switch (state) {
    case 'LEVEL_LOSS':
      return LoyaltySystem.getGlobalColors().levelLossWarning;
    case 'POINT_LOSS':
      return LoyaltySystem.getGlobalColors().pointLossWarning;
    default:
      return 'transparent';
  }
};
export const LoyaltyIcon: React.FC<{
  loyaltyLabelStyle: LoyaltySystemLabelType;
}> = observer('LoyaltyIcon', ({
  loyaltyLabelStyle
}) => {
  const evergreenDataService = useService($EvergreenDataService);
  const execute = useSlashCommandWithoutChannel();
  if (loyaltyLabelStyle === 'NONE' || loyaltyLabelStyle === 'OK') {
    return null;
  }
  return <div onClick={toPointerHandler(e => {
    execute('/loyaltySystem open');
    e.stopPropagation();
  })} style={{
    borderColor: resolveThemingValue(mapColor(loyaltyLabelStyle), "colors", useTheme())
  }} className={_c4}>
			<Image src={LoyaltySystem.getConfigForLevel(evergreenDataService.data.loyalty.currentLevel).iconUrl} alt={''} className={_c5} />
		</div>;
});
const _c0 = " Knu-FlexCol position-relative size-full bg-transparentDark overflow-hidden ";
const _c1 = " Knu-Flex gap-base position-absolute ";
const _c2 = "  ";
const _c3 = "  ";
const _c4 = " Knu-Flex size-24px shadow-Shadow3 borderRadius-circle placeItems-center borderStyle-solid borderWidth-large ";
const _c5 = " size-full ";