import * as React from 'react';
import { NoCommentsPlaceholder } from './NoCommentsPlaceholder';
import { InfiniteScrollView, SwipeContextProvider } from '@shared/components';
import { FlexCol, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { AlbumPhotoCommentFragment, AlbumPhotoComments, AlbumPhotoCommentsLength } from '@generated/graphql';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { PhotoComment } from './PhotoComment';
import { useQueryWithLocalCache } from '@knuddels-app/Connection';
export type PhotoCommentsUpdater = ReturnType<typeof usePhotoComments>['updateCache'];
export const usePhotoComments = (albumPhotoId: string, commentsOpen: boolean) => {
  return useQueryWithLocalCache(
  // @ts-expect-error typings wrong
  commentsOpen ? AlbumPhotoComments : AlbumPhotoCommentsLength, commentsOpen ? {
    albumPhotoId,
    pixelDensity: getPixelRatio()
  } : {
    albumPhotoId
  });
};
export const PhotoCommentsContext = React.createContext<{
  data: ReturnType<typeof usePhotoComments>['data'];
  loading: boolean;
  updateCache: PhotoCommentsUpdater;
}>({
  data: null,
  loading: true,
  updateCache: () => {}
});
export const PhotoComments: React.FC = () => {
  const {
    data: comments,
    loading,
    updateCache: updateComments
  } = React.useContext(PhotoCommentsContext);
  const prevLoadingItems = React.useRef<ListItem[] | null>(null);
  const items = React.useMemo(() => {
    return comments ? comments.map(it => ({
      uniqueKey: it.id,
      type: 'comment',
      comment: it
    })) : [];
  }, [comments]);
  React.useEffect(() => {
    if (!loading) {
      prevLoadingItems.current = items;
    }
  }, [loading, items]);
  const renderItem = useRenderItem(items.length, updateComments);

  // prevent flickering the placeholder when clicking through photos without comments
  const showPlaceholder = loading ? prevLoadingItems.current != null && prevLoadingItems.current.length === 0 : items.length === 0;
  return <SwipeContextProvider>
			<div className={_c0 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				{showPlaceholder ? <NoCommentsPlaceholder /> : loading ?
      // <PhotoCommentsSkeleton />
      <></> : <InfiniteScrollView estimatedItemSize={90} items={items} dock={'bottom'} renderItem={renderItem} />}
			</div>
		</SwipeContextProvider>;
};
interface ListItem {
  uniqueKey: string;
  type: string;
  comment: AlbumPhotoCommentFragment;
}
const useRenderItem = (itemCount: number, updateComment: PhotoCommentsUpdater) => {
  return React.useCallback((item: ListItem, index: number) => {
    const removeCommentFromCache = () => updateComment(oldData => oldData.filter(comment => comment.id !== item.comment.id));
    return <PhotoComment comment={item.comment} showDivider={index > 0} removeCommentFromCache={removeCommentFromCache} />;
  }, [itemCount]);
};
const _c0 = " Knu-FlexCol position-relative bg-contentBg width-100-percent height-100-percent flex-1 ";