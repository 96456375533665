import * as React from 'react';
import { declareProps, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import { ContextMenu, ContextMenuEntry, ContextMenuProps } from '@shared/components';
import { $ActiveChannelService, channelViewId } from '@knuddelsModules/Channel';
import { $ThisVisibleOverlay } from '@knuddels-app/overlays';
import { declareFormat } from '@knuddels/i18n';
import { $I18n } from '@knuddels-app/i18n';
import { action } from '@knuddels-app/mobx';
import { $ViewService } from '@knuddels-app/layout';
import { settingsViewId } from '@knuddelsModules/Settings';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { IconInfo, IconSettings } from '@knuddels/component-library';
import { IconReport } from '@shared/icons/IconReport';
import { IconChannelRating } from './IconChannelRating';
import { ContextMenuDivider } from '@shared/components/molecules/Modal/ContextMenuDivider';
import { useIsStackedLayout } from '@knuddels-app/Screen';
import { IconLogout } from '@shared/icons/IconLogout';
@injectable()
class ChatOverflowContextMenuModel {
  constructor(@injectProps()
  props: unknown, @inject($ActiveChannelService)
  private readonly activeChannelService: typeof $ActiveChannelService.T, @inject($ThisVisibleOverlay)
  private readonly thisVisibleOverlay: typeof $ThisVisibleOverlay.T, @inject($ViewService)
  private readonly viewService: typeof $ViewService.T, @inject($FirebaseAnalyticsService)
  private firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T) {}
  @action.bound
  public closeContextMenu(): void {
    this.thisVisibleOverlay.dispose();
  }
  @action.bound
  public openChannelSettings(): void {
    this.firebaseAnalyticsService.logEvent('Chat_Chatraum', 'Titlebar_SettingsClicked');
    this.thisVisibleOverlay.dispose();
    this.viewService.openViewAsOverlayWithContext(settingsViewId.with(s => s.withPath('ChannelSettings')), 'ChatContextMenu');
  }
  @action.bound
  public openChannelInfo(): void {
    this.firebaseAnalyticsService.logEvent('Chat_Chatraum', 'Titlebar_ChannelInfoClicked');
    this.thisVisibleOverlay.dispose();
    if (this.activeChannelService.activeChannel) {
      this.activeChannelService.activeChannel.send('/info');
    }
  }
  @action.bound
  public openChannelRating(): void {
    this.firebaseAnalyticsService.logEvent('Chat_Chatraum', 'Titlebar_ChannelRatingClicked');
    this.thisVisibleOverlay.dispose();
    if (this.activeChannelService.activeChannel) {
      this.activeChannelService.activeChannel.send('/usersatisfaction');
    }
  }
  @action.bound
  public openReportChannel(): void {
    this.firebaseAnalyticsService.logEvent('Chat_Chatraum', 'Titlebar_ReportChannelClicked');
    this.thisVisibleOverlay.dispose();
    const activeChannel = this.activeChannelService.activeChannel;
    if (activeChannel) {
      activeChannel.send('/reportuserchannel ' + activeChannel.name);
    }
  }
  @action.bound
  public leaveChannel(): void {
    this.thisVisibleOverlay.dispose();
    this.viewService.openView(channelViewId.with(s => s.openChannelList()));
    this.activeChannelService.leaveChannel();
  }
}
export const ChatOverflowContextMenu = injectedComponent({
  name: 'ChatOverflowContextMenu',
  model: ChatOverflowContextMenuModel,
  props: declareProps<ContextMenuProps>(),
  inject: {
    i18n: $I18n,
    activeChannelService: $ActiveChannelService
  }
}, ({
  model,
  i18n,
  activeChannelService,
  chevronX,
  chevronY,
  chevronWidth,
  chevronHeight
}) => {
  const canReportChannel = activeChannelService.activeChannel && activeChannelService.activeChannel.groupInfo.isMyChannel;
  const isStackedLayout = useIsStackedLayout();
  return <ContextMenu closeContextMenu={model.closeContextMenu} chevronX={chevronX} chevronY={chevronY} chevronWidth={chevronWidth} chevronHeight={chevronHeight}>
				<ContextMenuEntry icon={IconInfo} text={declareFormat({
      id: 'channelOverflowMenu.info',
      defaultFormat: 'Show channel info'
    }).format(i18n)} onClick={model.openChannelInfo} />
				<ContextMenuEntry icon={IconChannelRating} text={declareFormat({
      id: 'channelOverflowMenu.rating',
      defaultFormat: 'Show channel rating'
    }).format(i18n)} onClick={model.openChannelRating} />
				{canReportChannel && <ContextMenuEntry icon={IconReport} text={declareFormat({
      id: 'channelOverflowMenu.report',
      defaultFormat: 'Report channel'
    }).format(i18n)} onClick={model.openReportChannel} />}
				<ContextMenuDivider />
				<ContextMenuEntry icon={IconSettings} text={declareFormat({
      id: 'channelOverflowMenu.settings',
      defaultFormat: 'Settings'
    }).format(i18n)} onClick={model.openChannelSettings} />
				{isStackedLayout && <>
						<ContextMenuDivider />
						<ContextMenuEntry icon={IconLogout} text={declareFormat({
        id: 'channelOverflowMenu.leaveChannel',
        defaultFormat: 'Leave channel'
      }).format(i18n)} onClick={model.leaveChannel} />
					</>}
			</ContextMenu>;
});