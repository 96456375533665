import * as React from 'react';
import { Flex, FlexCol, IconHide, IconShow, Image, ThemeOverride, Z_INDEX, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ActiveChannelService, $JoinChannelService, ChannelInfo } from '@knuddelsModules/Channel';
import { colorToRgbaString } from '@knuddels-app/tools/colorToRgbaString';
import { isNative } from '@knuddels-app/tools/isNative';
import { ChannelThumbnail } from './ChannelThumbnail';
import { ChannelListItem, EntryTitle } from './ChannelListItem';
import { useChannelListItemSubline } from '../../../shared/ChannelList/useChannelListItemSubline';
import { ChatItemFactory } from '@knuddelsModules/Channel/bundle/components/Chat/ChatContent/ChatItemFactory';
import { ChannelCategoryFragment } from '@generated/graphql';
import { ChannelListContacts } from './ChannelListContacts';
import { observer } from '@knuddels-app/mobx';
import { ContextMenu, ContextMenuEntry, ContextMenuProps, OverflowMenuTrigger } from '@shared/components';
import { $OverlayService, $ThisVisibleOverlay } from '@knuddels-app/overlays';
import { IconLogout } from '@shared/icons/IconLogout';
import { declareFormat } from '@knuddels/i18n';
import { $I18n } from '@knuddels-app/i18n';
export const ActiveChannelListItem: React.FC<{
  activeChannel: ChannelInfo;
  group?: ChannelCategoryFragment['channelGroups'][0];
  previewMembers?: ChannelCategoryFragment['channelGroups'][0]['previewMembers'];
}> = observer('ActiveChannelListItem', ({
  previewMembers,
  activeChannel,
  group
}) => {
  const overlayService = useService($OverlayService);
  const channelService = useService($JoinChannelService);
  const [expandedChannels, setExpandedChannels] = React.useState(false);
  const info = activeChannel.groupInfo;
  const bgColor = colorToRgbaString(info.backgroundColor) || 'transparent';
  const channelImage = info.previewImageInfo.url;
  const blurredChannelImage = info.previewImageInfo.urlBlurred;
  const subline = useChannelListItemSubline(info, false);
  const friends = previewMembers?.friends ?? [];
  const watchlist = previewMembers?.watchlist ?? [];
  const others = previewMembers?.others ?? [];
  const lastMessages = activeChannel.getMessages().slice(-3).map(it => ChatItemFactory.create({
    message: it,
    animated: false,
    channelGroupInfo: activeChannel.groupInfo,
    lastOfSet: true,
    firstOfSet: true,
    isStackedLayout: true,
    uniqueKey: it.id
  }, {
    renderMode: 'condensed',
    contentMode: 'textOnly',
    messageSpacingPx: 4,
    channelGroupInfo: activeChannel.groupInfo,
    overrideTextProps: {
      type: 'tiny'
    }
  }));
  return <div className={_c0}>
				<div onClick={() => {
      channelService.joinChannelById(activeChannel.id, 'ChannelList', {
        redirectToChannelOnSuccess: true,
        redirectToChannelListOnFail: true,
        mayJoinSubChannel: false
      });
    }} style={{
      clipPath: 'border-box',
      background: resolveThemingValue((bgColor as ThemeOverride), "colors", useTheme()),
      transform: resolveThemingValue(!isNative() ? 'scale(1.01)' : undefined, "theme", useTheme())
    }} className={_c1 + ((bgColor as ThemeOverride) ? resolveIsDarkColor((bgColor as ThemeOverride), useTheme()) ? " content-is-dark" : " content-is-light" : "") + (true ? " animated-all " : "")}>
					{!!blurredChannelImage && <div className={_c2}>
							<Image resizeMode={'cover'} src={blurredChannelImage} alt={''} className={_c3} />
						</div>}
					<div style={{
        background: resolveThemingValue(!channelImage ? (bgColor as ThemeOverride) : undefined, "colors", useTheme()),
        zIndex: resolveThemingValue(Z_INDEX.OVERCONTENT, "theme", useTheme())
      }} className={_c4 + ((!channelImage ? (bgColor as ThemeOverride) : undefined) ? resolveIsDarkColor(!channelImage ? (bgColor as ThemeOverride) : undefined, useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
						<ChannelThumbnail src={channelImage} alt={activeChannel.name} userCount={activeChannel.participants.size} backgroundColor={bgColor} type={'large'} hideBorder={info.isSpecialPreviewImage} />
						<div className={_c5}>
							<EntryTitle name={activeChannel.name} isActive type={'default'} subline={subline} />
							{(friends.length > 0 || watchlist.length > 0 || others.length > 0) && <ChannelListContacts friends={friends} watchlist={watchlist} others={others} align={'left'} />}
						</div>
					</div>
					<div className={_c6}>
						<div className={_c7}>
							{lastMessages.map(it => it.node)}
						</div>
					</div>
					<div style={{
        zIndex: resolveThemingValue(Z_INDEX.OVERCONTENT + 1, "theme", useTheme())
      }} className={_c8}>
						<OverflowMenuTrigger showOverflowMenu={(x, y, width, height) => {
          overlayService.showOverlay({
            view: <OverflowMenu chevronX={x} chevronY={y} chevronWidth={width} chevronHeight={height} expanded={expandedChannels} toggleExpanded={group?.channels?.length > 1 ? () => {
              setExpandedChannels(!expandedChannels);
            } : null} />
          });
        }} />
					</div>
				</div>

				<div className={_c9}>
					{expandedChannels && group.channels.filter(channel => activeChannel.id !== channel.id).map(channel => {
        return <ChannelListItem key={channel.id} id={channel.id} name={channel.name} isAdultChannel={group.info.isAdultChannel} userCount={channel.onlineUserCount} previewMembers={channel.previewMembers} backgroundColor={group.info.backgroundColor} channelImage={group.info.previewImageInfo.url} blurredChannelImage={group.info.previewImageInfo.urlBlurred} onEntryClick={id => {
          channelService.joinChannelById(id, 'ChannelList', {
            redirectToChannelOnSuccess: true,
            redirectToChannelListOnFail: true,
            mayJoinSubChannel: false
          });
          setExpandedChannels(false);
        }} indented type={'condensed'} isLast={false} hideImageBorder={group.info.isSpecialPreviewImage} />;
      })}
				</div>
			</div>;
});
const OverflowMenu: React.FC<ContextMenuProps & {
  expanded: boolean;
  toggleExpanded?: () => void;
  onHide?: () => void;
}> = ({
  chevronX,
  chevronY,
  chevronWidth,
  chevronHeight,
  toggleExpanded,
  expanded
}) => {
  const thisVisibleOverlay = useService($ThisVisibleOverlay);
  const activeChannelService = useService($ActiveChannelService);
  const i18n = useService($I18n);
  return <ContextMenu closeContextMenu={thisVisibleOverlay.dispose} chevronX={chevronX} chevronY={chevronY} chevronWidth={chevronWidth} chevronHeight={chevronHeight}>
			<ContextMenuEntry icon={IconLogout} text={declareFormat({
      id: 'channelOverflowMenu.leaveChannel',
      defaultFormat: 'Leave channel'
    }).format(i18n)} onClick={() => {
      thisVisibleOverlay.dispose();
      activeChannelService.leaveChannel();
    }} />
			{toggleExpanded && <ContextMenuEntry icon={!expanded ? IconShow : IconHide} text={!expanded ? declareFormat({
      id: 'channelOverflowMenu.expand',
      defaultFormat: 'Show subchannels'
    }).format(i18n) : declareFormat({
      id: 'channelOverflowMenu.hide',
      defaultFormat: 'Hide subchannels'
    }).format(i18n)} onClick={() => {
      thisVisibleOverlay.dispose();
      toggleExpanded();
    }} />}
		</ContextMenu>;
};
const _c0 = " Knu-FlexCol gap-small ";
const _c1 = " Knu-FlexCol position-relative borderRadius-xlarge borderStyle-solid borderWidth-4px borderColor-rgba-106-226-200-1-0- overflow-hidden cursor-pointer height-168px maxHeight-168px ";
const _c2 = " Knu-FlexCol position-absolute inset-none ";
const _c3 = " size-full ";
const _c4 = " Knu-Flex p-minor pb-small ";
const _c5 = " Knu-FlexCol flex-1 mx-minor alignItems-flex-start justifyContent-space-between pb-tiny minWidth-0-px ";
const _c6 = " Knu-FlexCol flex-1 overflow-hidden position-relative mb-minor mx-small pointerEvents-none ";
const _c7 = " Knu-FlexCol position-absolute insetX-none bottom-none ";
const _c8 = " Knu-Flex position-absolute top-none right-none pointerEvents-all ";
const _c9 = " Knu-FlexCol ";