import { K3ServiceBinder } from '@knuddels-app/DependencyInjection';
import { foreachProp } from '@knuddels/std';
import * as ids from '../providedServiceIds';
import {
	ActiveChannelService,
	ChannelCommandsService,
	ChannelMessageFilterService,
	ChannelSubscriptionService,
	Commands,
	JoinChannelService,
	MacroBoxService,
	ReconnectedService,
	SystemEventsService,
} from './services';
import { ChannelComponents } from './services/ChannelComponents';

export function registerServices(ctx: K3ServiceBinder): void {
	foreachProp(ids, {
		$ActiveChannelService:
			ctx.loggedInScope.toSingletonAutostart(ActiveChannelService),
		$JoinChannelService:
			ctx.loggedInScope.toSingletonAutostart(JoinChannelService),
		$ReconnectedService:
			ctx.loggedInScope.toSingletonAutostart(ReconnectedService),
		$SystemEventsService:
			ctx.loggedInScope.toSingletonAutostart(SystemEventsService),
		$Commands: ctx.loggedInScope.toSingletonAutostart(Commands),
		$ChannelComponents:
			ctx.loggedInScope.toSingletonAutostart(ChannelComponents),
		$MacroBoxService: ctx.loggedInScope.toSingleton(MacroBoxService),
		$ChannelSubscriptionService: ctx.loggedInScope.toSingletonAutostart(
			ChannelSubscriptionService
		),
		$ChannelMessageFilterService: ctx.loggedInScope.toSingletonAutostart(
			ChannelMessageFilterService
		),
		$ChannelCommandsService: ctx.loggedInScope.toSingletonAutostart(
			ChannelCommandsService
		),
	});
}
