import * as React from 'react';
import { FlexCol, toPointerHandler } from '@knuddels/component-library';
import { MacroBoxInteractionEntries } from './MacroBoxInteractionEntries';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels/i18n';
import { $ClientSettingsService } from '@knuddelsModules/Settings';
import { MacroBoxCategory } from './MacroBoxCategory';
import { QuickAccessCategory } from './QuickAccessCategory';
import { MacroBoxDataTypes } from './MacroBoxDataTypes';
export const MacroBoxContent: React.FC<{
  user: MacroBoxDataTypes;
  close: (goToChannel?: boolean) => void;
}> = ({
  user,
  close
}) => {
  const i18n = useService($I18n);
  const quickAccessEntries = useActiveQuickAccessEntries(user?.isReportable ?? false, user?.isAllowedByContactFilter ?? false);

  // Without the noop onPress, scrolling does not work on native
  return <div onClick={toPointerHandler(noop)} className={_c0}>
			<QuickAccessCategory entries={quickAccessEntries} user={user} close={close} />

			<MacroBoxCategory mt={quickAccessEntries.length > 0 ? 'large' : 'none'} title={declareFormat({
      id: 'channel.macrobox.category.interactions',
      defaultFormat: 'Do something with {nick}'
    }).format(i18n, {
      nick: user.nick
    })}>
				<MacroBoxInteractionEntries nick={user.nick} close={() => close(true)} />
			</MacroBoxCategory>
		</div>;
};
const useActiveQuickAccessEntries = (isReportable: boolean, isAllowedByContactFilter: boolean) => {
  const clientSettingService = useService($ClientSettingsService);

  // knuddel, conversation and openProfile are all mapped to the knuddel entry
  return clientSettingService.macroBoxQuickAccessEntries.filter(it => it.active && (it.id !== 'report' || isReportable) && (it.id !== 'knuddel' || isAllowedByContactFilter) && (it.id !== 'conversation' || isAllowedByContactFilter) && (it.id !== 'openProfile' || isAllowedByContactFilter));
};
function noop(): void {}
const _c0 = " Knu-FlexCol m-base ";