import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import { GetFeatureFlags } from '@generated/graphql';
import { $FeatureService, FeatureFlag } from '@knuddels-app/featureFlags';
import { Deferred, Disposable } from '@knuddels/std';
import {
	appNotificationExploreFlag,
	authenticityFlag,
	dashboardQuickAccessControlFlag,
	disablePeriodicChannelSelectionUpdatesFlag,
	engagementSystemChatGroupFlag,
	newAppKnuddelTransferFlag,
	nickSwitchFlag,
	smileyTradeFlag,
	tanSystemEnabledFlag,
} from '../../featureFlags';

@injectable()
export class FeatureFlagsService {
	public readonly dispose = Disposable.fn();

	public readonly deferredReady = new Deferred();
	private loadedFeatureFlags: FeatureFlag[] = [];

	constructor(
		@inject($AuthenticatedClientService)
		authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($FeatureService)
		private readonly featureService: typeof $FeatureService.T
	) {
		// On timeout and failure use default values, so there will be no infinite loading screen
		setTimeout(() => {
			if (this.deferredReady.state === 'none') {
				console.log('resolve deferredReady');
				this.deferredReady.resolve();
			}
		}, 1000);

		authenticatedClientService.currentK3Client
			.queryWithResultPromise(GetFeatureFlags, {})
			.then(result => {
				if (result.isOk()) {
					const activeFlagIds = result.unwrap().map(it => it.id);
					activeFlagIds.forEach(id => {
						const flag = mapToFeatureFlag(id);
						if (flag) {
							this.loadedFeatureFlags.push(flag);
							this.featureService.setFeatureFlagEnabled(
								flag,
								true
							);
						}
					});
				}
			})
			.finally(() => {
				if (this.deferredReady.state === 'none') {
					console.log('resolve deferredReady because ready');
					this.deferredReady.resolve();
				}
			});

		this.dispose.track(() => {
			// reset set flags on logout
			this.loadedFeatureFlags.forEach(flag => {
				this.featureService.resetFeatureFlag(flag);
			});
			this.loadedFeatureFlags = [];
		});
	}
}

function mapToFeatureFlag(id: number): FeatureFlag | undefined {
	switch (id) {
		case 580:
			return engagementSystemChatGroupFlag;
		case 585:
			return tanSystemEnabledFlag;
		case 594:
			return nickSwitchFlag;
		case 599:
			return authenticityFlag;
		case 610:
			return smileyTradeFlag;
		case 649:
			return disablePeriodicChannelSelectionUpdatesFlag;
		case 656:
			return newAppKnuddelTransferFlag;
		case 673:
			return dashboardQuickAccessControlFlag;
		case 674:
			return appNotificationExploreFlag;
		default:
			return undefined;
	}
}
