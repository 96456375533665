import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $AuthenticatedClientService } from '@knuddels-app/Connection';
import {
	ActivePromotions,
	ColorFragment,
	GenericKnuddelOfferFragment,
	HappyHourFragment,
	KnuddelsPlusOfferFragment,
	PromotionEvents,
} from '@generated/graphql';
import { action, observable, runInAction } from '@knuddels-app/mobx';
import { Disposable } from '@knuddels/std';
import { $MessageFormatProvider } from '@knuddels-app/i18n';
import de from '../i18n/formats.de.json';
import en from '../i18n/formats.en.json';

export type KnuddelPromotion = {
	type: 'happyHour' | 'generic';
	color: ColorFragment;
	endTimestamp: string;
};

@injectable()
export class PromotionService implements Disposable {
	public dispose = Disposable.fn();

	private happyHour = new PromotionHandler<HappyHourFragment>();
	private knuddelPlusOffer =
		new PromotionHandler<KnuddelsPlusOfferFragment>();
	private genericKnuddelOffer =
		new PromotionHandler<GenericKnuddelOfferFragment>();

	constructor(
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($MessageFormatProvider)
		private readonly messageFormatProvider: typeof $MessageFormatProvider.T
	) {
		messageFormatProvider.registerFormatProvider(
			locale =>
				// Workaround for metro bundler because it can't handle dynamic imports.
				// See https://github.com/facebook/metro/issues/52
				(
					({
						de,
						en,
					}) as any
				)[locale.language]
		);

		this.dispose.track(
			this.authenticatedClientService.currentK3Client.subscribeToPrimaryData(
				PromotionEvents,
				{},
				{
					next: this.handleEvent,
				}
			)
		);
		this.dispose.track(() => {
			this.happyHour.clearTimeout();
			this.knuddelPlusOffer.clearTimeout();
			this.genericKnuddelOffer.clearTimeout();
		});

		this.authenticatedClientService.currentK3Client
			.queryWithResultPromise(ActivePromotions, {})
			.onOk(result => {
				result.forEach(promotion => {
					switch (promotion.__typename) {
						case 'HappyHourPromotion':
							this.happyHour.start(promotion);
							break;
						case 'KnuddelsPlusOffer':
							this.knuddelPlusOffer.start(promotion);
							break;
						case 'GenericKnuddelOffer':
							this.genericKnuddelOffer.start(promotion);
							break;
						default:
					}
				});
			});
	}

	public get activeKnuddelPromotion(): KnuddelPromotion | undefined {
		const happyHour = this.happyHour.activeData;
		if (happyHour) {
			return {
				type: 'happyHour',
				color: happyHour.color,
				endTimestamp: happyHour.endTimestamp,
			};
		}

		const knuddelOffer = this.genericKnuddelOffer.activeData;
		if (knuddelOffer) {
			return {
				type: 'generic',
				color: knuddelOffer.color,
				endTimestamp: knuddelOffer.endTimestamp,
			};
		}
		return undefined;
	}

	public get activeHappyHour(): HappyHourFragment | undefined {
		return this.happyHour.activeData;
	}

	public get activeKnuddelsPlusOffer():
		| KnuddelsPlusOfferFragment
		| undefined {
		return this.knuddelPlusOffer.activeData;
	}

	public get activeGenericKnuddelOffer():
		| GenericKnuddelOfferFragment
		| undefined {
		return this.genericKnuddelOffer.activeData;
	}

	@action.bound
	private handleEvent(data: typeof PromotionEvents.TPrimaryResult): void {
		switch (data.__typename) {
			case 'PromotionStartedEvent':
				if (data.newPromotion.__typename === 'HappyHourPromotion') {
					this.happyHour.start(data.newPromotion);
				}
				if (data.newPromotion.__typename === 'KnuddelsPlusOffer') {
					this.knuddelPlusOffer.start(data.newPromotion);
				}
				if (data.newPromotion.__typename === 'GenericKnuddelOffer') {
					this.genericKnuddelOffer.start(data.newPromotion);
				}
				break;
			case 'PromotionEndedEvent':
				if (data.promotion.__typename === 'HappyHourPromotion') {
					this.happyHour.end();
				}
				if (data.promotion.__typename === 'KnuddelsPlusOffer') {
					this.knuddelPlusOffer.end();
				}
				if (data.promotion.__typename === 'GenericKnuddelOffer') {
					this.genericKnuddelOffer.end();
				}
				break;
			default:
				break;
		}
	}
}

class PromotionHandler<TData extends { endTimestamp: string }> {
	@observable
	private data: TData | undefined;
	private timeout: any | undefined;

	get activeData(): TData | undefined {
		return this.data;
	}

	@action.bound
	start(data: TData): void {
		this.clearTimeout();

		this.data = data;

		const millisUntilEnd = parseInt(data.endTimestamp, 10) - Date.now();

		this.timeout = setTimeout(
			() => {
				runInAction('reset happyhour after it ended', () => {
					this.data = undefined;
				});
				this.timeout = undefined;
			},
			Math.min(millisUntilEnd, 0x7fffffff)
		); // Prevent integer overflow for large endTimestamps
	}

	@action.bound
	end(): void {
		this.clearTimeout();
		this.data = undefined;
	}

	clearTimeout(): void {
		if (this.timeout) {
			clearTimeout(this.timeout);
			this.timeout = undefined;
		}
	}
}
