/* System Apps Ids */
export const GLOBAL_APP_ID = 'globalAppOverview';
export const WORLDTOUR_APP_ID = 'worldtour';
export const CHANGE_PASSWORD_APP_ID = 'ChangePasswordApp';
export const NICK_SWITCH_APP_ID = 'NickSwitchApp';
export const NICK_HISTORY_APP_ID = 'NickHistoryApp';
export const CANCEL_ABOS_APP_ID = 'CancelAbosApp';
export const ADMIN_INFO_APP_ID = 'AdminInfoApp';
export const SMILEY_TRADE_APP_ID = 'SmileyAuctionApp';
export const PROFILE_CUSTOMIZATION_APP_ID = 'ProfileCustomizationApp';
export const CHANNEL_LIMIT_SETTINGS_APP_ID = 'ChannelLimitSettingsApp';
export const TRANSFER_HISTORY_APP_ID = 'TransferHistoryApp';
export const CONTACT_FILTER_SETTINGS_APP_ID = 'FilterSettingsApp';
export const KNUDDEL_BUY_APP_ID = 'BuyDirectKnuddelApp';
export const KNUDDELS_PLUS_SHOP_APP_ID = 'KnuddelsPlusShopApp';
export const FOTOMEET_APP_ID = 'FotomeetApp';
export const CT_TICKET_APP_ID = 'CtTicketApp';

export const DISABLE_ADS_APP_IDS = [
	KNUDDEL_BUY_APP_ID,
	KNUDDELS_PLUS_SHOP_APP_ID,
	PROFILE_CUSTOMIZATION_APP_ID,
	FOTOMEET_APP_ID,
	CT_TICKET_APP_ID,
	SMILEY_TRADE_APP_ID,
];

/* Custom Slash Commands */
export const GLOBAL_APP_SLASH_COMMAND = 'applist';
export const EMAIL_APP_SLASH_COMMAND = '/emailverification';

/* SIDs */
export const GLOBAL_APPS_OVERVIEW_SID = 'globalAppsOverview';
export const K3_SIDEBAR_SID = 'k3Sidebar';
export const APP_AND_GAMES_SID = 'appsAndGames';

/* MISC */
export const WORLDTOUR_COMMANDS = ['weltreise', 'worldtour', 'wr'];
export const HAUS_KNUDDEL_COMMANDS = ['pet', 'pet overview'];
export const APPS_TO_FORCE_CLOSE = [NICK_SWITCH_APP_ID, NICK_HISTORY_APP_ID];
export const HIDE_TITLEBAR_APPS = [CT_TICKET_APP_ID, 'CtLootApp', 'LoyaltyApp'];

export const navBarWidthCollapsed = 72;

export const NAV_BAR_BOTTOM_HEIGHT = 64;

export const APP_DRAWER_BAR_HEIGHT = 32;

export const CHANNEL_MESSAGE_COMMAND_CONTEXT = 'channel_message';
export const CHANNEL_JOIN_ERROR_COMMAND_CONTEXT = 'channel_join_error';
