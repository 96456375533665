import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { LoggedInView } from '../components/LoggedInView';
import { $MessageFormatProvider } from '@knuddels-app/i18n';
import { DownloadView } from '../components/DownloadView';
import de from '../i18n/formats.de.json';
import en from '../i18n/formats.en.json';
import { MenuAppEntry } from '../components/MenuBottomSheet/MenuAppEntry';

@injectable()
export class LoggedInAreaService {
	LoggedInView: typeof LoggedInView = LoggedInView;
	DownloadView: typeof DownloadView = DownloadView;
	MenuAppEntry: typeof MenuAppEntry = MenuAppEntry;

	constructor(
		@inject($MessageFormatProvider)
		messageFormatProvider: typeof $MessageFormatProvider.T
	) {
		messageFormatProvider.registerFormatProvider(
			locale =>
				// Workaround for metro bundler because it can't handle dynamic imports.
				// See https://github.com/facebook/metro/issues/52
				(
					({
						de,
						en,
					}) as any
				)[locale.language]
		);
	}
}
