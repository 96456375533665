import * as React from 'react';
import { NormalizedNavBarItem } from '@knuddelsModules/LoggedInArea/bundle/services';
import { Box, createAccessiblePointerEventHandler, FlexCol, Text, ThemeOverride, createNativeAccessiblePointerEventHandler, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { openInNewTab } from '@shared/helper/openUrl';
import { AppIconBg } from './AppIconBg';
import { NavIcon } from '../NavBar/NavBarItem';
import { TextStates } from '@shared/components/atoms/Texts/TextProps';
import { NotificationBadge } from '@shared/components';
import { useBadge } from '@knuddelsModules/LoggedInArea/bundle/components/NavBar/useBadge';
export const MenuAppEntry: React.FC<{
  onPress: () => void;
  item: NormalizedNavBarItem;
  backgroundColor: string;
}> = ({
  onPress,
  item,
  backgroundColor
}) => {
  const [hovered, setHovered] = React.useState(false);
  const badge = useBadge(item.badges);
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: () => {
      item.onClick();
      if (item.url) {
        openInNewTab(item.url);
      }
      onPress();
    }
  })} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} className={_c0}>
			<div style={{
      background: backgroundColor
    }} className={_c1}>
				<div className={_c2}>
					<AppIconBg />
				</div>
				<NavIcon icon={item.icon} active />
			</div>
			<Text type={'tiny'} state={hovered ? 'primary' : ('secondary' as TextStates)} numberOfLines={1} bold={true} className={_c3}>
				{item.title}
			</Text>
			{badge.text && <div style={{
      top: resolveThemingValue((-2 as ThemeOverride), "spacing", useTheme())
    }} className={_c4}>
					<NotificationBadge number={badge.text} rawColor={badge.color} />
				</div>}
		</div>;
};
const _c0 = " Knu-FlexCol cursor-pointer position-relative minWidth-72px gap-tiny alignItems-center ";
const _c1 = " Knu-FlexCol size-48px placeItems-center borderRadius-xlarge position-relative overflow-hidden ";
const _c2 = " Knu-Box position-absolute inset-none ";
const _c3 = "  ";
const _c4 = " Knu-Box position-absolute right-small ";