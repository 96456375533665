import { Module } from '@knuddels-app/ModuleSystem';
import * as providedServices from './providedServices';
import { $ViewProvider } from '@knuddels-app/layout';
import { ExploreViewProvider } from './ExploreViewProvider';
import { $NavBarItemProvider } from '@knuddelsModules/LoggedInArea';
import { ExploreNavBarItemProvider } from './ExploreNavBarItemProvider';
import { profileModule } from '@knuddelsModules/Profile/module';
import { channelModule } from '@knuddelsModules/Channel/module';
import { userDataModule } from '@knuddelsModules/UserData/module';
import { promotionModule } from '@knuddelsModules/Promotions/module';
import { notificationsModule } from '@knuddelsModules/Notifications/module';

export const exploreModule = new Module({
	name: 'Explore module',
	dependencies: [
		userDataModule,
		profileModule,
		channelModule,
		promotionModule,
		notificationsModule,
	],
	providedServices,
	load: {
		from: () =>
			import(
				/* webpackChunkName: "unnamed" */ './bundle/registerServices'
			),
	},
	loadStatic: ctx => {
		ctx.globalScope.bind($ViewProvider).toSingleton(ExploreViewProvider);
		ctx.loggedInScope
			.bind($NavBarItemProvider)
			.toSingleton(ExploreNavBarItemProvider);
	},
});
