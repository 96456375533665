import * as React from 'react';
import { useRef } from 'react';
import { Backdrop, FabTransition, Image, SpeedDial, useSpeedDial, Z_INDEX } from '@knuddels/component-library';
import { useService } from '@knuddels-app/DependencyInjection';
import { $I18n } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels/i18n';
import { SelectedProfileTabContext } from '../../SelectedProfileTabContext';
import { ConversationFabEntry } from './ConversationEntry';
import { FriendFabEntry } from './FriendStateEntry';
import { ProfileUser } from '../../../profileQueryHelper';
import { WatchlistFabEntry } from './WatchlistFabEntry';
import { KnuddelFabEntry } from './KnuddelFabEntry';
import { KissFabEntry } from './KissFabEntry';
import { useTrackProfileView } from '../../../shared/useTrackProfileView';
import { $ViewService } from '@knuddels-app/layout';
import { profileViewId } from '@knuddelsModules/Profile/ProfileViewProvider';
import { userIsJames } from '@shared/helper/user';
import { FriendState } from '@generated/graphql';
export const InteractionFabMenu: React.FC<{
  user: ProfileUser;
  conversationId: string;
  onClose(): void;
}> = props => {
  const {
    speedDialProps,
    backdropProps
  } = useSpeedDial();
  const i18n = useService($I18n);
  const viewService = useService($ViewService);
  const {
    activeTabIndex
  } = React.useContext(SelectedProfileTabContext);
  const speedDialPropsWithTracking = useSpeedDialPropsWithTracking(speedDialProps);
  const renderSpeedDial = () => {
    const entries: React.ReactElement[] = [];
    if (!userIsJames(props.user) && !props.user.isAppBot) {
      entries.push(<ConversationFabEntry key={'conversation'} conversationId={props.conversationId} onClose={() => {
        viewService.closeView(profileViewId);
        speedDialProps.onChange(false);
      }} />);
    }
    if (props.user.isAllowedByContactFilter) {
      entries.push(<KnuddelFabEntry key={'knuddel'} nick={props.user.nick} />);
    }
    entries.push(<KissFabEntry key={'kiss'} user={props.user} />);
    if (props.user.friendState !== FriendState.Friends) {
      entries.push(<WatchlistFabEntry key={'watchlist'} user={props.user} />);
      entries.push(<FriendFabEntry key={'friend'} user={props.user} />);
    }
    return <SpeedDial {...speedDialPropsWithTracking} openIcon={<Image src={require('./assets/fab-action.png')} alt={i18n.format(declareFormat({
      id: 'profile.fab-menu.icon-alt',
      defaultFormat: 'Icon that displays possible actions'
    }))} className={_c0} />}>
				{entries}
			</SpeedDial>;
  };
  return <>
			<Backdrop {...backdropProps} zIndex={Z_INDEX.OVER_TITLE_BAR} />
			<FabTransition activeIndex={activeTabIndex} zIndex={Z_INDEX.OVER_TITLE_BAR} anchor={'bottom-right'}>
				{/* rendering 3 times (for each tab once) so that speed dial fades out and in when switching tabs  */}
				{renderSpeedDial()}
				{renderSpeedDial()}
				{renderSpeedDial()}
			</FabTransition>
		</>;
};
type SpeedDialHookProps = ReturnType<typeof useSpeedDial>['speedDialProps'];
const useSpeedDialPropsWithTracking = (speedDialProps: SpeedDialHookProps): SpeedDialHookProps => {
  const track = useTrackProfileView();
  const lastTrackedOpenState = useRef(false);
  return {
    ...speedDialProps,
    onChange: open => {
      speedDialProps.onChange(open);

      // onChange is triggered even when just hovering over the speed
      // dial options but we want to track them only once.
      if (lastTrackedOpenState.current === open) {
        return;
      }
      lastTrackedOpenState.current = open;
      track(open ? 'FAB_Opened' : 'FAB_Closed');
    }
  };
};
const _c0 = " width-48px height-48px ";