import { Spacer, Z_INDEX } from '@shared/components';
import * as React from 'react';
import { NavBarHeader } from './NavBarHeader';
import { NavBarItem } from './NavBarItem';
import { navBarWidthExpanded, styles } from './styles';
import { NavBarProfile } from './NavBarProfile';
import { NavBarConfiguration, navBarConfigurations } from './configuration';
import { createAccessiblePointerEventHandler, Flex, FlexCol, IconSortDown, IconSortUp, LinearGradient, LineDivider, ScrollView, ThemeOverride, useBgClassName, useEvent, Tooltip, useMeasureElement, useTheme, resolveThemingValue, createNativeAccessiblePointerEventHandler, resolveIsDarkColor } from '@knuddels/component-library';
import { NavComponentModel } from './NavComponentModel';
import { observer } from '@knuddels-app/mobx';
import { navBarWidthCollapsed } from '@shared/constants';
import { setScrollTop } from '@shared/components/helper/setScrollTop';
import { motion } from 'framer-motion';
import { NavBadge, NormalizedNavBarItem } from '@knuddelsModules/LoggedInArea/bundle/services';
import { BackgroundGradient } from './BackgroundGradient';
import { useIsStackedLayout } from '@knuddels-app/Screen';
import { DYNAMIC_SLOT_INDEX } from '@knuddelsModules/LoggedInArea/constants';
import { getSafeArea } from '@knuddels-app/SafeArea';
interface Props {
  model: NavComponentModel;
}
const DEFAULT_SCROLL_BUTTON_SIZE = 36;
const TOP_GRADIENT_HEIGHT = 5;
export const NavBar: React.FC<Props> = observer('NavBar', props => {
  const shadow = typeof props.model.config.shadow === 'string' ? props.model.config.shadow : props.model.config.shadow(props.model.isCollapsed);
  const style = props.model.config.navLocation === 'bottom' ? styles.NavBarMinimalBottom : props.model.config.extendOptions.canBeExtended ? props.model.isCollapsed ? styles.NavBarExtensionLeftCollapsed : styles.NavBarExtensionLeft : styles.NavBarMinimalLeft;
  const theme = useTheme();
  const isDarkTheme = theme.id !== 'light';
  const isStackedLayout = useIsStackedLayout();
  const safeArea = React.useMemo(() => getSafeArea(), []);
  let height = style.height;
  if (height && typeof height === 'number') {
    height += getSafeArea().safeAreaBottomHeight;
  }
  return <div style={{
    ...props.model.config.shadowStyle,
    height,
    boxShadow: resolveThemingValue(shadow, "shadows", useTheme()),
    zIndex: resolveThemingValue(Z_INDEX.NAV, "theme", useTheme())
  }} className={_c0}>
			<motion.div className={useBgClassName('contentBg')} transition={{
      type: 'tween',
      duration: 0.1
    }} animate={{
      width: props.model.config.navLocation === 'bottom' ? 'auto' : props.model.isCollapsed ? navBarWidthCollapsed : navBarWidthExpanded
    }} style={{
      display: 'flex',
      position: 'relative',
      overflow: 'visible',
      flexDirection: 'column',
      paddingBottom: safeArea.safeAreaBottomHeight,
      background: isDarkTheme ? isStackedLayout ? 'var(--colors-contentBg)' : 'var(--colors-black-solid-880)' : 'var(--colors-contentLightBg)',
      ...(style as any),
      height
    }}>
				{!isStackedLayout && <BackgroundGradient />}
				{props.model.config.shownItems === 'full' ? <>
						<div className={_c1}>
							<NavBarHeader backIcon={props.model.config.extendOptions.overlayContentIfExtended ? 'close' : 'back'} collapsed={props.model.isCollapsed} onToggleCollapsed={props.model.setNavCollapsed} />
							<NavBarProfile onProfilePicClick={props.model.handleProfilePicClick} onNicknameClick={props.model.handleNickNameClick} collapsed={props.model.isCollapsed} />
						</div>

						<NavBarLeft {...props} />
					</> : <BottomItems {...props} />}
			</motion.div>
		</div>;
});
const CategoryGroup: React.FC<{
  items: NormalizedNavBarItem[];
  model: NavComponentModel;
}> = observer('CategoryGroup', ({
  items,
  model
}) => {
  const cb = useEvent((index: number) => {
    const item = items[index];
    model.handleItemClick(item);
  });
  return <>
			{items.map((item, index) => <React.Fragment key={`navbaritem-${index}`}>
					<Tooltip spacing={8} content={(item.title as any)} enabled={model.isCollapsed && model.config.navLocation === 'left'}>
						<Item title={item.title} index={index} url={item.url} icon={item.icon} badges={item.badges} active={item.active} shownItems={model.config.shownItems} hideItemTitle={model.config.hideItemTitle} navLocation={model.config.navLocation} collapsed={model.isCollapsed} onItemClick={cb} />
						{index + 1 === items.length && <Spacer size={'tiny'} />}
					</Tooltip>
				</React.Fragment>)}
		</>;
});
const LeftItems: React.FC<Props> = observer('LeftItems', ({
  model
}) => {
  return <>
			{model.categories.map((category, index) => <div key={`category-${category.id}`} className={_c2}>
					{index > 0 && <CategoryDivider collapsed={model.isCollapsed} />}
					<div className={_c3}>
						<CategoryGroup items={category.entries} model={model} />
					</div>
				</div>)}
		</>;
});
const CategoryDivider: React.FC<{
  collapsed: boolean;
}> = ({
  collapsed
}) => {
  return <div className={_c4 + (collapsed ? _c5 : _c6)}>
			<LineDivider color={'hairlineAtom'} className={_c7} />
		</div>;
};
const NavBarAnimation: React.FC<{
  title: string;
  active: boolean;
  shouldPreventAnimation: boolean;
}> = ({
  children,
  ...props
}) => {
  return <motion.div initial={false} transition={{
    type: 'tween',
    duration: 0.3,
    ease: [0, 0, 0, 1]
  }} animate={{
    scale: props.active && !props.shouldPreventAnimation ? [1, 1.2, 1] : 1
  }} className={'Knu-Flex flex-1'}>
			{children}
		</motion.div>;
};
const BottomItems: React.FC<Props> = observer('BottomItems', ({
  model
}) => {
  const shouldPreventAnimationRef = React.useRef(false);
  const cb = useEvent((index: number) => {
    shouldPreventAnimationRef.current = true;
    const item = model.bottomItems[index];
    model.handleItemClick(item);
    setTimeout(() => {
      shouldPreventAnimationRef.current = false;
    }, 500);
  });
  return <>
			{model.bottomItems.map((item, index) => {
      return <React.Fragment key={`navbaritem-${index}`}>
						<NavBarAnimation shouldPreventAnimation={index !== DYNAMIC_SLOT_INDEX || shouldPreventAnimationRef.current} active={item.active} title={item.title.toString()}>
							<Item title={item.title} index={index} url={item.url} icon={item.icon} badges={item.badges} active={item.active} hideItemTitle={navBarConfigurations.minimalBottom.hideItemTitle} shownItems={navBarConfigurations.minimalBottom.shownItems} navLocation={navBarConfigurations.minimalBottom.navLocation} collapsed={model.isCollapsed} onItemClick={cb} />
						</NavBarAnimation>
						{index + 1 === model.bottomItems.length && <Spacer size={'tiny'} />}
					</React.Fragment>;
    })}
		</>;
});
const Item = React.memo(({
  navLocation,
  shownItems,
  hideItemTitle,
  collapsed,
  onItemClick,
  ...props
}: {
  active?: boolean;
  index: number;
  badges: NavBadge[];
  title: string | JSX.Element;
  url: string;
  icon: string | React.ComponentType<any>;
  navLocation: NavBarConfiguration['navLocation'];
  shownItems: NavBarConfiguration['shownItems'];
  hideItemTitle: NavBarConfiguration['hideItemTitle'];
  collapsed: boolean;
  onItemClick: (index: number) => void;
}) => {
  return <div className={_c8 + (navLocation === 'bottom' ? _c9 : _c10)}>
				<NavBarItem active={props.active} badges={props.badges} collapsed={collapsed} navLocation={navLocation} onClick={() => onItemClick(props.index)} title={props.title} url={props.url} type={shownItems === 'minimal' ? 'minimalCentered' : 'extendedLeftAligned'} hideTitle={hideItemTitle} icon={props.icon} />
			</div>;
});
Item.displayName = 'Item';
const NavBarLeft: React.FC<Props> = observer('NavBarLeft', props => {
  const {
    height: scrollHeight,
    bind: bindNavContent
  } = useMeasureElement();
  const {
    height: offsetHeight,
    bind: bindScrollView
  } = useMeasureElement();
  const isDarkTheme = useTheme().id !== 'light';
  const [scrollY, setScrollY] = React.useState<number>(0);
  const [hideScrollButton, setHideScrollButton] = React.useState<boolean>(true);
  const [hideGradientTop, setHideGradientTop] = React.useState<boolean>(true);
  const [isScrolledMoreThenHalf, setIsScrolledMoreThenHalf] = React.useState<boolean>(false);
  const [scrollOverflow, setScrollOverflow] = React.useState<number>(0);
  React.useLayoutEffect(() => {
    setScrollOverflow(scrollHeight + navBarWidthCollapsed - offsetHeight);
  }, [scrollHeight, offsetHeight]);
  React.useLayoutEffect(() => {
    setIsScrolledMoreThenHalf(scrollY > scrollOverflow / 2);
    setHideGradientTop(scrollY < TOP_GRADIENT_HEIGHT + 1 || scrollOverflow <= 0);
  }, [scrollY, scrollOverflow]);
  React.useLayoutEffect(() => {
    setHideScrollButton(!props.model.isCollapsed || scrollOverflow <= 0);
  }, [props.model.isCollapsed, scrollOverflow]);
  const onScrollButtonPress = () => {
    const scrollView = bindScrollView.innerRef.current;
    if (scrollView) {
      setScrollTop(scrollView, isScrolledMoreThenHalf ? 0 : scrollOverflow);
    }
  };
  return <>
			<ScrollView flex={1} flexBasis={0} pb={hideScrollButton ? undefined : (navBarWidthCollapsed as ThemeOverride)} onScroll={e => setScrollY(e.currentTarget.scrollTop)} touchAction="pan-y" hideScrollIndicator {...bindScrollView}>
				<GradientContainer align={'top'} height={TOP_GRADIENT_HEIGHT} hide={hideGradientTop || isDarkTheme} />
				<FlexCol px={'base'} {...bindNavContent}>
					<Spacer size={'minor'} />
					<LeftItems {...props} />
					<Spacer size={'minor'} />
				</FlexCol>
			</ScrollView>
			<GradientContainer align={'bottom'} hide={hideScrollButton}>
				<ScrollButton onPress={onScrollButtonPress} icon={isScrolledMoreThenHalf ? <IconSortUp size={'large'} /> : <IconSortDown size={'large'} />} />
			</GradientContainer>
		</>;
});
const ScrollButton: React.FC<{
  icon: JSX.Element;
  onPress: () => void;
  size?: number;
}> = ({
  icon,
  onPress,
  size = DEFAULT_SCROLL_BUTTON_SIZE
}) => {
  return <div {...createNativeAccessiblePointerEventHandler({
    pointerCallback: onPress
  })} style={{
    height: resolveThemingValue(size, "sizes", useTheme()),
    width: resolveThemingValue(size, "sizes", useTheme())
  }} className={_c11 + ("white-solid-120" ? resolveIsDarkColor("white-solid-120", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			{icon}
		</div>;
};
const GradientContainer: React.FC<{
  align: 'top' | 'bottom';
  height?: number;
  children?: React.ReactNode;
  hide?: boolean;
}> = ({
  align,
  height = navBarWidthCollapsed,
  children,
  hide = false
}) => {
  const isDarkTheme = useTheme().id !== 'light';
  if (hide) {
    return null;
  }
  const bottom = align === 'bottom' ? 'none' : undefined;
  const colors: React.ComponentProps<typeof LinearGradient>['colors'] = align === 'bottom' ? [{
    color: isDarkTheme ? 'black-solid-880' : 'contentBg',
    stop: 0.73
  }, {
    color: 'transparent',
    stop: 1
  }] : ['transparent', isDarkTheme ? 'black-solid-880' : 'black-transparent-110'];
  return <LinearGradient width={'full'} height={height} placeItems={'center'} position={'absolute'} bottom={bottom} colors={colors} zIndex={Z_INDEX.OVERCONTENT}>
			{children}
		</LinearGradient>;
  // tslint:disable-next-line: max-file-line-count
};
const _c0 = " Knu-FlexCol position-relative ";
const _c1 = " Knu-FlexCol position-relative overflow-hidden ";
const _c2 = " Knu-FlexCol ";
const _c3 = " Knu-FlexCol gap-small ";
const _c4 = " Knu-FlexCol height-16px width-full alignSelf-stretch ";
const _c5 = " pr-none ";
const _c6 = " pr-base ";
const _c7 = "  ";
const _c8 = " Knu-Flex flex-1 justifyContent-center position-relative overflow-hidden ";
const _c9 = " flexDirection-row ";
const _c10 = " flexDirection-column ";
const _c11 = " Knu-Flex cursor-pointer shadow-Shadow2 placeItems-center position-relative bg-white-solid-120 borderRadius-circle ";