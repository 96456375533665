import { GetCurrentUserNickComponent, User, UserKnuddelComponent, UserKnuddelUpdatedComponent } from '@generated/graphql';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { useService } from '@knuddels-app/DependencyInjection';
import { Spacer } from '@shared/components';
import { BoxProps, CrossFade, Flex, FlexCol, rgb, Text, ThemeOverride, toPointerHandler, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { expectUnreachable } from '@knuddels/std';
import * as React from 'react';
import { Amount } from './KnuddelDisplayAmount';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { $PromotionService } from '@knuddelsModules/Promotions';
import { colorToRgbaString } from '@knuddels-app/tools/colorToRgbaString';
import { PromotionLabel } from './PromotionLabel';
import { observer } from '@knuddels-app/mobx';
import { VerticalDisplayAnimatedAmount } from './VerticalDisplayAnimatedAmount';
import { KnuddelIcon } from './KnuddelIcon';
import { AmountFormat } from '@knuddels-app/tools/formatAmount';
import { LoyaltyIcon, LoyaltySystemLabelType, renderLoyaltySystemLabels } from './LoyltySystemLabels';
import { $EvergreenDataService } from '@knuddels-app/evergreenData';
type FirebaseProps = {
  firebaseContentType?: string;
  firebaseItemId?: string;
};
type Props = {
  type: 'amountOnly' | 'vertical' | 'normal' | 'big';
  numberFormat: AmountFormat;
  background?: 'none' | 'light' | 'default';
  staticIcon?: boolean;
  promotionBannerLocation?: 'top' | 'bottom' | 'none';
  showLoyaltySystemLabels?: boolean;
  showLoyaltySystemIcon?: boolean;
};
const useTrackKnuddelBuy = ({
  firebaseContentType,
  firebaseItemId
}: FirebaseProps) => {
  const firebaseAnalyticsService = useService($FirebaseAnalyticsService);
  return () => {
    if (firebaseContentType) {
      firebaseAnalyticsService.logEvent(firebaseContentType, firebaseItemId || 'KnuddelCount_Clicked');
    }
  };
};
const BUY_KNUDDEL_SLASH_COMMAND = '/buyknuddel sid~KnuddelDisplay';
export const CurrentUserKnuddelDisplay: React.FC<Props & FirebaseProps> = ({
  firebaseContentType,
  firebaseItemId,
  showLoyaltySystemLabels = false,
  showLoyaltySystemIcon = true,
  ...props
}) => {
  const snackBarService = useService($SnackbarService);
  const track = useTrackKnuddelBuy({
    firebaseContentType,
    firebaseItemId
  });
  const execute = useSlashCommandWithoutChannel({
    shouldUseStappIfAvailable: true
  });
  const evergreenDataService = useService($EvergreenDataService);
  const buyKnuddel = (): void => {
    track();
    execute(BUY_KNUDDEL_SLASH_COMMAND).catch(() => {
      snackBarService.showGenericError();
    });
  };
  return <div onClick={toPointerHandler(buyKnuddel)} className={_c0}>
			<GetCurrentUserNickComponent>
				{({
        data
      }) => data && data.user && <KnuddelDisplay showLoyaltySystemIcon={showLoyaltySystemIcon} loyaltyLabelStyle={showLoyaltySystemLabels === false ? 'NONE' : evergreenDataService.data.loyalty.state} showBuyKnuddel id={data.user.currentUser.id} {...props} /> || null}
			</GetCurrentUserNickComponent>
		</div>;
};
export const KnuddelSubscription = (props: {
  id: User['id'];
}) => <UserKnuddelUpdatedComponent variables={{
  id: props.id
}} />;
export function KnuddelDisplay({
  id,
  type,
  staticIcon,
  numberFormat,
  background,
  showBuyKnuddel = false,
  loyaltyLabelStyle,
  showLoyaltySystemIcon = true,
  promotionBannerLocation = 'none'
}: {
  id: string;
  showBuyKnuddel?: boolean;
  loyaltyLabelStyle?: LoyaltySystemLabelType;
} & Props): React.ReactElement {
  return <UserKnuddelComponent variables={{
    id
  }}>
			{({
      data
    }) => {
      const amount = data && data.user && data.user.user && data.user.user.knuddelAmount;
      switch (type) {
        case 'vertical':
          return <VerticalDisplay amount={amount} staticIcon={staticIcon} numberFormat={numberFormat} loyaltyLabelStyle={loyaltyLabelStyle} />;
        case 'normal':
        case 'big':
          return <HorizontalDisplay amount={amount} type={type} background={background || 'default'} staticIcon={staticIcon} numberFormat={numberFormat} showBuyButton={showBuyKnuddel} showLoyaltyIcon={showLoyaltySystemIcon} promotionBannerLocation={promotionBannerLocation} loyaltyLabelStyle={loyaltyLabelStyle} />;
        case 'amountOnly':
          return <AmountWithBuyButton amount={amount} textType={'body1'} numberFormat={numberFormat} buyButtonType={showBuyKnuddel ? 'normal' : undefined} />;
        default:
          return expectUnreachable(type);
      }
    }}
		</UserKnuddelComponent>;
}
const VerticalDisplay = observer('VerticalDisplay', (props: {
  amount: number | undefined | null;
  staticIcon?: boolean;
  numberFormat: 'none' | 'millionOnly' | 'full';
  loyaltyLabelStyle?: LoyaltySystemLabelType;
}) => {
  const promotionService = useService($PromotionService);
  const execute = useSlashCommandWithoutChannel();
  const promotion = promotionService.activeKnuddelPromotion;
  return <div className={_c1}>
				<div className={_c2}>
					<KnuddelIcon staticIcon={props.staticIcon} />
					{promotion ? <VerticalDisplayAnimatedAmount amount={props.amount} promotion={promotion} numberFormat={props.numberFormat} /> : <Amount amount={props.amount} textType={'body2'} numberFormat={props.numberFormat} />}
				</div>

				{!promotion && !!props.loyaltyLabelStyle && props.loyaltyLabelStyle !== 'NONE' && props.loyaltyLabelStyle !== 'OK' && <div className={_c3}>
							<div onClick={toPointerHandler(e => {
        e.stopPropagation();
        execute('/loyaltySystem open');
      })} style={{
        width: 'calc(100% + 18px)'
      }} className={_c4}>
								<LoyaltyIcon loyaltyLabelStyle={props.loyaltyLabelStyle} />
							</div>
							<div className={_c5}>
								{renderLoyaltySystemLabels(props.loyaltyLabelStyle, 'top')}
							</div>
						</div>}
			</div>;
});
const HorizontalDisplay = observer('HorizontalDisplay', (props: {
  amount: number | undefined | null;
  type: 'normal' | 'big';
  numberFormat: 'none' | 'millionOnly' | 'full';
  background: 'none' | 'default' | 'light';
  staticIcon?: boolean;
  showBuyButton: boolean;
  promotionBannerLocation?: 'top' | 'bottom' | 'none';
  loyaltyLabelStyle?: LoyaltySystemLabelType;
  showLoyaltyIcon?: boolean;
}) => {
  const promotionService = useService($PromotionService);
  const backgroundStyle = props.background !== 'none' ? backgroundStyles[props.type] : {};
  const buyStyle = props.showBuyButton ? styles.withBuyButton : {};
  const promotion = promotionService.activeKnuddelPromotion;
  const showTopPromotionBadge = !!promotion && props.promotionBannerLocation === 'top';
  const showBottomPromotionBadge = !!promotion && props.promotionBannerLocation === 'bottom';
  const topBorderRadius = showTopPromotionBadge ? 'none' : 'tiny';
  const bottomBorderRadius = showBottomPromotionBadge ? 'none' : 'tiny';
  const canRenderLoyaltySystemLabels = !promotion || props.promotionBannerLocation === 'none';
  const loyaltyLabelStyle = canRenderLoyaltySystemLabels ? props.loyaltyLabelStyle : 'NONE';
  const showLoyaltySystemLabelsAtTop = props.promotionBannerLocation === 'top';
  const showLoyaltySystemLabelsAtBottom = props.promotionBannerLocation === 'bottom' || props.promotionBannerLocation === 'none';
  return <div className={_c6}>
				{props.showLoyaltyIcon !== false && <LoyaltyIcon loyaltyLabelStyle={props.loyaltyLabelStyle} />}

				<div className={_c7}>
					{showTopPromotionBadge && <PromotionLabel promotion={promotion} location={'top'} />}
					{canRenderLoyaltySystemLabels && showLoyaltySystemLabelsAtTop && renderLoyaltySystemLabels(loyaltyLabelStyle, 'top', !props.showLoyaltyIcon)}
					<FlexCol {...styles.common} {...backgroundStyle} bg={props.background === 'light' ? rgb(0, 0, 0, 0.07) : props.background === 'default' ? 'black-solid-110' : undefined} darkBg={props.background === 'light' ? rgb(0, 0, 0, 0.07) : props.background === 'default' ? 'black-solid-660' : undefined} {...buyStyle} borderTopLeftRadius={topBorderRadius} borderTopRightRadius={topBorderRadius} borderBottomLeftRadius={bottomBorderRadius} borderBottomRightRadius={bottomBorderRadius} handleDarkProps>
						<KnuddelIcon staticIcon={props.staticIcon} />
						<Spacer size={'tiny'} />
						<div style={{
          background: resolveThemingValue(props.background === 'light' ? 'transparent' : undefined, "colors", useTheme())
        }} className={_c8 + ((props.background === 'light' ? 'transparent' : undefined) ? resolveIsDarkColor(props.background === 'light' ? 'transparent' : undefined, useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
							<Amount amount={props.amount} textType={'body2'} numberFormat={props.numberFormat} />
						</div>
						<Spacer size={'auto'} />
						{props.showBuyButton && <>
								<Spacer size={props.type === 'normal' ? 'tiny' : 'minor'} />
								<KnuddelBuyButton type={props.type} />
							</>}
					</FlexCol>
					{showBottomPromotionBadge && <PromotionLabel promotion={promotion} location={'bottom'} />}
					{canRenderLoyaltySystemLabels && showLoyaltySystemLabelsAtBottom && renderLoyaltySystemLabels(loyaltyLabelStyle, 'bottom', !props.showLoyaltyIcon)}
				</div>
			</div>;
});
function AmountWithBuyButton({
  buyButtonType,
  ...amountProps
}: typeof Amount.TProps & {
  buyButtonType?: 'normal' | 'big';
}): React.ReactElement {
  return <FlexCol {...styles.common}>
			<Amount {...amountProps} />
			{!!buyButtonType && <>
					<Spacer size={buyButtonType === 'normal' ? 'tiny' : 'minor'} />
					<KnuddelBuyButton type={buyButtonType} />
				</>}
		</FlexCol>;
}
const KnuddelBuyButton = observer('KnuddelBuyButton', ({
  type
}: {
  type: 'normal' | 'big';
}) => {
  const promotionService = useService($PromotionService);
  const promotion = promotionService.activeKnuddelPromotion;
  const size = type === 'normal' ? '16px' : '26px';
  return <div style={{
    background: resolveThemingValue(promotion ? (colorToRgbaString(promotion.color) as any) : 'accent', "colors", useTheme()),
    width: resolveThemingValue(size, "sizes", useTheme()),
    height: resolveThemingValue(size, "sizes", useTheme())
  }} className={_c9 + ((promotion ? (colorToRgbaString(promotion.color) as any) : 'accent') ? resolveIsDarkColor(promotion ? (colorToRgbaString(promotion.color) as any) : 'accent', useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				{promotion ? <PromotionButtonContent /> : <Text type={'tiny'} bold={true} className={_c10}>
						+
					</Text>}
			</div>;
});
const PromotionButtonContent: React.FC = () => {
  const [plusVisible, setPlusVisible] = React.useState(true);
  React.useEffect(() => {
    const interval = setInterval(() => {
      setPlusVisible(visible => !visible);
    }, 2500);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return <CrossFade duration={750} initialVisible={plusVisible} initial={<Text type={'tiny'} bold={true} className={_c11}>
					+
				</Text>} end={<Text type={'tiny'} bold={true} className={_c12}>
					%
				</Text>} />;
};
const styles = {
  common: ({
    flexDirection: 'row',
    overflow: 'visible',
    alignItems: 'center'
  } as BoxProps),
  withBuyButton: ({
    pr: 'tiny',
    py: 'tiny'
  } as BoxProps)
};
const backgroundStyles = {
  normal: ({
    pl: 'tiny',
    pr: 'small',
    py: (3 as ThemeOverride),
    minWidth: 40,
    bg: 'black-transparent-440'
  } as BoxProps),
  big: ({
    pl: (6 as ThemeOverride),
    pr: 'minor',
    py: (7 as ThemeOverride),
    minWidth: 48,
    bg: 'black-transparent-440'
  } as BoxProps)
};
const _c0 = " Knu-Flex position-relative overflow-visible cursor-pointer ";
const _c1 = " Knu-FlexCol alignItems-center overflow-visible width-56px gap-14px ";
const _c2 = " Knu-FlexCol alignItems-center width-full ";
const _c3 = " Knu-FlexCol alignItems-center gap-7px pb-small ";
const _c4 = " Knu-Flex justifyContent-center position-relative ";
const _c5 = " Knu-FlexCol width-34px ";
const _c6 = " Knu-Flex gap-base ";
const _c7 = " Knu-FlexCol ";
const _c8 = " Knu-Flex ";
const _c9 = " Knu-FlexCol borderRadius-tiny placeItems-center ";
const _c10 = "  ";
const _c11 = "  ";
const _c12 = "  ";