import * as React from 'react';
import { injectable } from '@knuddels-app/DependencyInjection';
import { Position, ViewId, ViewProvider, ViewRegistry } from '@knuddels-app/layout';
import { LoadService } from '@knuddels-app/ModuleSystem';
import { $SettingsComponents } from './providedServiceIds';
import { getRouterStateFromPersistedViewState, RouterViewState } from '@knuddels-app/layout/k3Router';
import type { SettingsRouterConfig } from '@knuddelsModules/Settings/SettingsRouterConfig';
@injectable()
export class SettingsViewProviderImpl implements ViewProvider {
  registerViews(registry: ViewRegistry): void {
    registry.registerView({
      viewId: settingsViewId,
      position: Position.Side,
      loadState: SettingsViewState.fromPersistedViewState,
      useSplitView: true,
      render: () => ({
        mainView: <LoadService service={$SettingsComponents}>
						{s => <s.MainView />}
					</LoadService>
      })
    });
  }
}
export const settingsViewId = new ViewId<SettingsViewState>('settings');
export class SettingsViewState extends RouterViewState<SettingsRouterConfig> {
  public static fromPersistedViewState = getRouterStateFromPersistedViewState(SettingsViewState);
  onOpenByNav?(): SettingsViewState {
    return this.withStack(this.stack);
  }
}