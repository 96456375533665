import { jsxs as s, jsx as r } from "react/jsx-runtime";
import "./TitleBar.scss.js";
import { clsx as g } from "../node_modules/clsx/dist/clsx.m.js";
import { useIsDarkColor as h } from "./Layout/Box/useBg.js";
import { useSafeAreaTopHeight as x, useTheme as u } from "../themes/useTheme.js";
import { resolveThemeColor as T } from "./Layout/isDarkColor.js";
const I = 56, C = ({
  hideShadow: p = !1,
  navIcon: i,
  iconList: a = [],
  title: e,
  subtitle: o,
  subtitleAdornment: m,
  editMode: c = !1,
  fillBackground: d = !1,
  ...t
}) => {
  const f = h(t.bg ?? "titleBarBg"), n = x(), l = {
    paddingTop: n,
    height: 56 + n
  };
  return /* @__PURE__ */ s(
    "div",
    {
      style: t.bg ? {
        ...l,
        "--colors-titleBarBg": T(
          t.bg,
          u()
        )
      } : l,
      className: g(
        "Knu-Titlebar Knu-Flex",
        f ? "content-is-dark" : "content-is-light",
        c && "editing",
        i && "hasNavIcon",
        d && "bg-titleBarBg"
      ),
      children: [
        i,
        /* @__PURE__ */ s("div", { className: "Knu-FlexCol flex-1", children: [
          typeof e == "string" ? /* @__PURE__ */ r(
            "span",
            {
              className: "Knu-Text numberOfLines-1 text-title text-primary",
              children: e
            }
          ) : e,
          /* @__PURE__ */ s("div", { className: "Knu-Flex alignItems-center", children: [
            m,
            /* @__PURE__ */ r(
              "span",
              {
                className: "Knu-Text numberOfLines-1 text-tiny text-tertiary",
                children: o
              }
            )
          ] })
        ] }),
        /* @__PURE__ */ r(
          "div",
          {
            className: "Knu-Flex alignItems-center justifyContent-flexEnd mr-small",
            children: a
          }
        )
      ]
    }
  );
};
export {
  I as TITLE_BAR_HEIGHT,
  C as TitleBar
};
