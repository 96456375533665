import * as React from 'react';
import { useMemo } from 'react';
import { observer } from '@knuddels-app/mobx';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ThisVisibleOverlay, OverlayFactory } from '@knuddels-app/overlays';
import { Box, calculateGradient, createAccessiblePointerEventHandler, Flex, FlexCol, FlexProps, IconCloseCircular, parseRgbString, rgb, ScrollView, Text, ThemeOverride, useTheme, Z_INDEX, resolveThemingValue, toPointerHandler, createNativeAccessiblePointerEventHandler, resolveIsDarkColor } from '@knuddels/component-library';
import { dismissKeyboard } from '@shared/helper/dismissKeyboard';
import { NAV_BAR_BOTTOM_HEIGHT } from '@shared/constants';
import { $UserService, KnuddelsPlusStatusBadge, UserImage } from '@knuddelsModules/UserData';
import { Spacer } from '@shared/components';
import { CurrentUserKnuddelDisplayLazy } from '@knuddelsModules/KnuddelDisplay';
import { $NavBarRegistry, $NavBarService } from '@knuddelsModules/LoggedInArea';
import { useBottomSheet } from '@shared/components/BottomSheet/useBottomSheet';
import { KnuddelsLogoSvg } from './KnuddelsLogoSvg';
import { useLazyService } from '@knuddels-app/ModuleSystem';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { MenuAppEntry } from './MenuAppEntry';
import { $AuthService, $CurrentEndpointStore } from '@knuddels-app/Connection/serviceIds';
import { IconAppLogout } from '@shared/icons/IconAppLogout';
import { $LocationService } from '@knuddels-app/location';
import { getSafeArea } from '@knuddels-app/SafeArea';
export const MenuBottomSheet: React.FC = observer('MenuBottomSheet', () => {
  const overlay = useService($ThisVisibleOverlay);
  const navBarService = useService($NavBarService);
  const locationService = useService($LocationService);
  const {
    domRef,
    bgRef,
    bind,
    className
  } = useBottomSheet(overlay);
  const isLandscape = navBarService.config === 'minimalLeft';
  React.useEffect(() => {
    const currentIndex = locationService.pushOverlay('menu');
    const unsub = locationService.historyListen(() => {
      if (history.state.idx < currentIndex) {
        overlay.dispose();
      }
    });
    return () => {
      unsub();
      if (history.state.idx === currentIndex) {
        locationService.pop();
      }
    };
  }, []);
  const theme = useTheme();
  const colors = useAccentuatedColors();
  const isDarkTheme = theme.id !== 'light';
  const currentEndpointStore = useService($CurrentEndpointStore);
  const authService = useService($AuthService);
  const showLogoutIcon = useMemo(() => {
    const logins = (JSON.parse(localStorage.getItem('logins_' + currentEndpointStore.currentEndpoint.id) || '[]') as Array<{
      lastLoggedInNick: string;
    }>);
    return logins.length > 1;
  }, [currentEndpointStore.currentEndpoint.id]);
  React.useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        overlay.dispose();
        e.stopPropagation();
      }
    };
    window.addEventListener('keydown', handler, true);
    return () => {
      window.removeEventListener('keydown', handler, true);
    };
  }, []);
  return <div style={{
    bottom: resolveThemingValue(isLandscape ? 0 : (NAV_BAR_BOTTOM_HEIGHT + getSafeArea().safeAreaBottomHeight as ThemeOverride), "spacing", useTheme()),
    zIndex: resolveThemingValue(Z_INDEX.BELOW_NAV, "theme", useTheme())
  }} className={_c0}>
			<div ref={bgRef} className={_c1 + ("backgroundScrim" ? resolveIsDarkColor("backgroundScrim", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
			<div onClick={toPointerHandler(overlay.dispose)} className={_c2 + ((isDarkTheme ? "transparentDark" : "transparentLight") ? resolveIsDarkColor(isDarkTheme ? "transparentDark" : "transparentLight", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (isDarkTheme ? _c3 : _c4)} />
			<div ref={domRef} className={className} style={{
      minHeight: 0,
      maxHeight: '100%',
      width: '100%',
      flexDirection: 'column',
      paddingTop: getSafeArea().safeAreaTopHeight
    }}>
				<div className={_c5}>
					<div className={'Knu-FlexCol'} style={{
          touchAction: 'none',
          overflow: 'hidden'
        }} onClick={dismissKeyboard} {...bind()}>
						<div className={_c6 + ((isDarkTheme ? "contentBg" : "contentLightBg") ? resolveIsDarkColor(isDarkTheme ? "contentBg" : "contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (isDarkTheme ? _c7 : _c8)}>
							<div style={{
              background: colors.mainBackgroundGradient
            }} className={_c9}>
								<div className={_c10 + ("iconDefaultAtom" ? resolveIsDarkColor("iconDefaultAtom", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
								<div className={_c11}>
									<IconCloseCircular size={'large'} onPress={overlay.dispose} />
									<div className={_c12}>
										<div className={_c13}>
											<KnuddelsLogoSvg />
										</div>
									</div>
									{showLogoutIcon && <IconAppLogout size={'large'} onPress={() => {
                  authService.logout();
                }} />}
								</div>

								<div className={_c14}>
									<UserSection />
									<AppSection colors={colors} onPress={overlay.dispose} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>;
});
const UserSection: React.FC<{}> = () => {
  const userService = useService($UserService);
  const userId = userService.currentUser.id;
  const getProfileNavigationService = useLazyService($ProfileNavigationService);
  const showProfile = () => {
    getProfileNavigationService().then(s => s.showProfile(userId, 'Nav'));
  };
  return <Section mt={'tiny'}>
			<div {...createNativeAccessiblePointerEventHandler({
      pointerCallback: showProfile
    })} className={_c15}>
				<UserImage type={48} uid={userId} />
			</div>
			<Spacer size={'small'} />
			<div className={_c16}>
				<div {...createNativeAccessiblePointerEventHandler({
        pointerCallback: showProfile
      })} className={_c17}>
					<Text type={'subtitle'} bold={true} numberOfLines={1} className={_c18}>
						{userService.currentUser.nick}
					</Text>
				</div>
				<KnuddelsPlusStatusBadge />
			</div>
			<div className={_c19} />
			<Spacer size={'large'} />
			<CurrentUserKnuddelDisplayLazy type={'normal'} numberFormat={'full'} firebaseContentType={'Navigation_BottomNavigation'} firebaseItemId={'MoreView_KnuddelCountClicked'} promotionBannerLocation={'top'} showLoyaltySystemLabels />
		</Section>;
};
const AppSection: React.FC<{
  colors: AccentuatedColors;
  onPress: () => void;
}> = ({
  colors,
  onPress
}) => {
  const navBarRegistry = useService($NavBarRegistry);
  const items = navBarRegistry.getNormalizedMenuItems();
  return <Section mt={'minor'}>
			<div style={{
      display: 'grid',
      rowGap: '16px',
      columnGap: '4px',
      gridTemplateColumns: 'repeat(auto-fill, minmax(72px, 1fr))',
      width: '100%'
    }}>
				{items.map(item => <MenuAppEntry key={item.id} item={item} backgroundColor={colors.appIconBackground} onPress={onPress} />)}
			</div>
		</Section>;
};
const Section: React.FC<FlexProps> = ({
  children,
  ...flexProps
}) => {
  const bgDark = useTheme().id !== 'light';
  return <Flex position={'relative'} bg={'contentLightBg'} darkBg={rgb(255, 255, 255, 0.02)} p={'base'} borderRadius={'xlarge'} borderStyle={'solid'} borderWidth={'base'} borderColor={'hairlineAtom'} {...flexProps} handleDarkProps>
			<div className={_c20 + ((bgDark ? "transparentDark" : "transparentLight") ? resolveIsDarkColor(bgDark ? "transparentDark" : "transparentLight", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (bgDark ? _c21 : _c22)}>
				{children}
			</div>
		</Flex>;
};
export const MenuBottomSheetOverlayFactory = new OverlayFactory(MenuBottomSheet, () => {}, {
  addSafeAreaPadding: false,
  keepOnLocationChange: true
});
const useAccentuatedColors = () => {
  const theme = useTheme();
  return useMemo(() => {
    const isDarkTheme = theme.id !== 'light';
    const rgb = parseRgbString(theme.colors.basic.accent);
    const appIconBackground = isDarkTheme ? 'rgba(255,255,255,0.11)' : calculateGradient(rgb, 0, 0.22);
    const bgColor1 = 'transparent';
    const bgColor2 = calculateGradient(rgb, 0, 0.11);
    const mainBackgroundGradient = isDarkTheme ? undefined : `linear-gradient(0deg, ${bgColor1} 0%, ${bgColor2} 100%)`;
    return {
      appIconBackground,
      mainBackgroundGradient
    };
  }, [theme]);
};
type AccentuatedColors = ReturnType<typeof useAccentuatedColors>;
const _c0 = " Knu-FlexCol inset-none position-absolute overflow-hidden ";
const _c1 = " Knu-Box position-absolute inset-none size-full bg-backgroundScrim pointerEvents-none ";
const _c2 = " Knu-FlexCol flex-1 ";
const _c3 = " bg-transparentDark ";
const _c4 = " bg-transparentLight ";
const _c5 = " Knu-FlexCol position-relative size-full justifyContent-flex-end shadow-Shadow2 ";
const _c6 = " Knu-FlexCol flex-1 width-full borderTopRadius-24px overflow-hidden ";
const _c7 = " bg-contentBg ";
const _c8 = " bg-contentLightBg ";
const _c9 = " Knu-FlexCol overflow-hidden px-base pt-small pb-minor ";
const _c10 = " Knu-Box mx-auto width-54px height-5px bg-iconDefaultAtom borderRadius-tiny ";
const _c11 = " Knu-Flex alignItems-center minHeight-48px position-relative justifyContent-space-between ";
const _c12 = " Knu-Flex width-full pointerEvents-none position-absolute ";
const _c13 = " Knu-Flex mx-auto ";
const _c14 = " Knu-ScrollView ";
const _c15 = " Knu-FlexCol cursor-pointer ";
const _c16 = " Knu-FlexCol gap-tiny ";
const _c17 = " Knu-Flex cursor-pointer height-full alignItems-center overflow-visible ";
const _c18 = "  ";
const _c19 = " Knu-Flex flex-1 ";
const _c20 = " Knu-Flex width-full alignItems-center ";
const _c21 = " bg-transparentDark ";
const _c22 = " bg-transparentLight ";