import { inject, injectable, injectProps } from '@knuddels-app/DependencyInjection';
import { Disposable, expectUnreachable } from '@knuddels/std';
import { $ScreenService } from '@knuddels-app/Screen';
import { $ProfileNavigationService } from '@knuddelsModules/Profile';
import { $NavBarRegistry, $NavBarService, $NavBarStateStore } from '@knuddelsModules/LoggedInArea';
import { action, autorun, observable, runInAction } from '@knuddels-app/mobx';
import { NavBarConfiguration, navBarConfigurations } from '@knuddelsModules/LoggedInArea/bundle/components/NavBar/configuration';
import { NormalizedNavBarCategory, NormalizedNavBarItem } from '@knuddelsModules/LoggedInArea/bundle/services';
import { sideNavMaximaizeNavigationEvent, sideNavMinimizeNavigationEvent, sideNavNicknameEvent, sideNavProfilImageEvent } from '@knuddelsModules/LoggedInArea/bundle/analytics';
export class NavBarCollapsedState {
  @observable
  private collapsed: boolean;
  constructor(private readonly navBarStateStore: typeof $NavBarStateStore.T, private navConfig: NavBarConfiguration) {
    if (this.navConfig.extendOptions.useStoredState) {
      this.collapsed = this.navBarStateStore.state === 'collapsed';
    } else {
      this.collapsed = true;
    }
  }
  public get isCollapsed(): boolean {
    return this.collapsed;
  }
  @action.bound
  navConfigChanged(navConfig: NavBarConfiguration): void {
    if (this.navConfig === navConfig) {
      return;
    }
    this.navConfig = navConfig;
    const typeChangeAction = this.navConfig.extendOptions.typeChangeAction;
    switch (typeChangeAction) {
      case 'collapse':
        this.collapsed = true;
        break;
      case 'extendUnlessPreviouslyCollapsed':
        // Extend nav unless 'collapsed' state was stored previously.
        this.collapsed = this.collapsed && this.navBarStateStore.state === 'collapsed';
        break;
      case 'none':
        break;
      default:
        expectUnreachable(typeChangeAction);
    }
  }
  setState = (collapsed: boolean) => {
    this.collapsed = collapsed;
    if (this.navConfig.extendOptions.useStoredState) {
      this.navBarStateStore.setState(collapsed ? 'collapsed' : 'extended');
    }
  };
}
@injectable()
export class NavComponentModel {
  readonly dispose = Disposable.fn();
  private readonly collapsedState: NavBarCollapsedState;
  constructor(@injectProps()
  private readonly props: unknown, @inject($ScreenService)
  private readonly screenService: typeof $ScreenService.T, @inject.lazy($ProfileNavigationService)
  private readonly getProfileNavigationService: typeof $ProfileNavigationService.TLazy, @inject($NavBarRegistry)
  private readonly navBarRegistry: typeof $NavBarRegistry.T, @inject($NavBarStateStore)
  private readonly navBarStateStore: typeof $NavBarStateStore.T, @inject($NavBarService)
  private readonly navBarService: typeof $NavBarService.T) {
    this.collapsedState = new NavBarCollapsedState(this.navBarStateStore, this.config);
    this.dispose.track(autorun({
      name: 'navConfig changed'
    }, () => {
      this.collapsedState.navConfigChanged(this.config);
    }));
  }
  public get config(): NavBarConfiguration {
    return navBarConfigurations[this.navBarService.config];
  }
  public get isCollapsed(): boolean {
    return this.collapsedState.isCollapsed;
  }
  @action.bound
  public handleItemClick(item: NormalizedNavBarItem): void {
    item.onClick();
    runInAction('Collpase nav after click', () => {
      if (this.screenService.isMediumSize) {
        this.collapsedState.setState(true);
      }
    });
  }
  @action.bound
  public setNavCollapsed(collapsed: boolean): void {
    this.collapsedState.setState(collapsed);
    if (collapsed) {
      sideNavMinimizeNavigationEvent.track('MinimizeNavigation_Clicked');
    } else {
      sideNavMaximaizeNavigationEvent.track('MaximizeNavigation_Clicked');
    }
  }
  @action.bound
  public collapseNav(): void {
    this.setNavCollapsed(true);
  }
  public handleNickNameClick = (): void => {
    this.openCurrentUserProfile();
    sideNavNicknameEvent.track('Nickname_Clicked');
  };
  public handleProfilePicClick = (): void => {
    this.openCurrentUserProfile();
    sideNavProfilImageEvent.track('ProfileImage_Clicked');
  };
  private openCurrentUserProfile = (): void => {
    this.getProfileNavigationService().then(service => service.showCurrentUserProfile({
      openedFromTrackingLabel: 'Nav'
    }));
  };
  public get categories(): NormalizedNavBarCategory[] {
    return this.navBarRegistry.getNormalizedCategories('desktop');
  }
  public get bottomItems(): NormalizedNavBarItem[] {
    return this.navBarRegistry.getBottomItems();
  }
}