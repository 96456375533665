import { ColorFragment, RegistrationInterest } from '@generated/graphql';
import { $FirstLoginStorage } from '@knuddels-app/Connection/serviceIds';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ViewService } from '@knuddels-app/layout';
import { autorun, observer } from '@knuddels-app/mobx';
import { FlexCol, TITLE_BAR_HEIGHT, resolveThemingValue, useTheme, FallbackBox } from '@knuddels/component-library';
import { $ActiveChannelService, channelViewId } from '@knuddelsModules/Channel';
import { $NavBarService } from '@knuddelsModules/LoggedInArea';
import { $UserService } from '@knuddelsModules/UserData';
import { Z_INDEX } from '@shared/components';
import { APP_DRAWER_BAR_HEIGHT, NAV_BAR_BOTTOM_HEIGHT } from '@shared/constants';
import { animate as animateManual, motion, useAnimate, useMotionValue, useMotionValueEvent } from 'framer-motion';
import * as React from 'react';
import { $ChannelAppViewer, $ChannelAppViewerAnimationService } from '../../../providedServices';
import { AppInstance } from '../../services/AppInstance';
import { ViewerState } from '../../services/ChannelAppViewerAnimationService';
import { AppsWebview } from '../AppsWebview';
import { AppBackgroundGestureView } from './AppBackgroundGestureView';
import { AppDrawerBar } from './AppDrawerBar';
import { ViewerSharedValues } from './ViewerSharedValues';
import { getMinimizedHeightPercentage } from './getMinimizedHeightPercentage';
import { getSplitViewHeightPercentage } from './getSplitViewHeightPercentage';
import { $ScreenService } from '@knuddels-app/Screen';
import { useReactiveState } from '@knuddels-app/tools/useReactiveState';
import { usePrevious } from '@knuddels-app/tools/usePrevious';
import { KeyboardAvoidanceView } from '@shared/components/KeyboardAvoidanceView';
import { NativeAppsWebview } from '@knuddelsModules/Apps/bundle/components/NativeAppsWebview';
import { $NativeWebViewService } from '@knuddels-app/NativeWebView';
import { getSafeArea } from '@knuddels-app/SafeArea';
const useStartAppMinimized = () => {
  const firstLoginStorage = useService($FirstLoginStorage);
  const userService = useService($UserService);
  const [startMinimized, setStartMinimized] = React.useState<boolean | null>(null);
  React.useEffect(() => {
    Promise.all([firstLoginStorage.isFirstLoginAfterRegistration(), userService.getCurrentUser()]).then(([firstLogin, user]) => {
      setStartMinimized(firstLogin && user.registrationInterest !== RegistrationInterest.Games);
      firstLoginStorage.resetFirstLoginAfterRegistration();
    });
  }, []);
  return startMinimized;
};
export const ChannelAppViewer: React.FC<{
  backgroundColor: ColorFragment;
  highlightColor?: ColorFragment;
  maxWidth: number;
  maxHeight: number;
  maxSplitViewPercentage: number;
}> = props => {
  const startMinimized = useStartAppMinimized();
  if (startMinimized === null) {
    return null;
  }
  return <ChannelAppViewerInner {...props} startMinimized={startMinimized} />;
};
const useAppState = (channelAppViewer: (typeof $ChannelAppViewer)['T'], state: ViewerState) => {
  const viewService = useService($ViewService);
  const nativeWebviewService = useService($NativeWebViewService);
  const setAllAppsToBackground = () => {
    channelAppViewer.apps.forEach(app => {
      app.updateVisibleStatus('background');
    });
  };
  const syncActiveState = () => {
    channelAppViewer.apps.forEach(app => {
      if (app === channelAppViewer.activeApp) {
        app.updateVisibleStatus('active');
      } else {
        app.updateVisibleStatus('background');
      }
    });
  };
  React.useEffect(() => {
    return autorun({
      name: 'Watch visibility of apps'
    }, () => {
      const isChatInBackground = viewService.isViewInBackground(channelViewId);
      if (isChatInBackground) {
        setAllAppsToBackground();
      } else {
        syncActiveState();
      }
    });
  }, []);
  React.useEffect(() => {
    syncActiveState();
  }, [channelAppViewer.activeApp]);
  React.useEffect(() => {
    if (state === 'hidden' || state === 'minimized') {
      setAllAppsToBackground();
      nativeWebviewService.updateAppDrawerState(state);
    } else {
      syncActiveState();
      nativeWebviewService.updateAppDrawerState('visible');
    }
  }, [state]);
};
const ChannelAppViewerInner: React.FC<{
  backgroundColor: ColorFragment;
  highlightColor?: ColorFragment;
  maxWidth: number;
  maxHeight: number;
  startMinimized: boolean;
  maxSplitViewPercentage: number;
}> = observer('ChannelAppViewerInner', ({
  backgroundColor,
  highlightColor,
  maxWidth,
  maxHeight,
  startMinimized,
  maxSplitViewPercentage
}) => {
  const channelAppViewer = useService($ChannelAppViewer);
  const nativeWebviewService = useService($NativeWebViewService);
  const {
    appBgColor,
    drawerBgColor
  } = getBackgroundColors(backgroundColor);
  const [scope, animate] = useAnimate();
  const {
    state,
    previousState,
    toggle,
    modifyHeight,
    viewerSharedValues
  } = useViewerSize(maxSplitViewPercentage, maxWidth, maxHeight, channelAppViewer.activeApp ? startMinimized ? 'minimized' : 'split' : 'hidden', channelAppViewer.activeApp);
  useAppState(channelAppViewer, state);
  useMotionValueEvent(viewerSharedValues.heightPercentage, 'change', val => {
    if (scope.current) {
      animate(scope.current, {
        height: val * 100 + '%'
      }, {
        bounce: 0
      });
    }
  });
  const variants = {
    minimized: {
      height: APP_DRAWER_BAR_HEIGHT
    },
    split: {
      height: viewerSharedValues.heightPercentage.get() * 100 + '%'
    },
    full: {
      height: '100%'
    }
  };
  useReactiveState(() => {
    channelAppViewer.apps.forEach(app => {
      nativeWebviewService.updateState(app, app === channelAppViewer.activeApp ? 'active' : 'inactive');
    });
  }, []);
  return <motion.div ref={scope} initial={'split'} variants={variants} style={{
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: '0',
    right: '0',
    justifyContent: 'flex-end',
    zIndex: Z_INDEX.BELOW_TITLE_BAR,
    boxShadow: 'var(--shadow-Shadow1)',
    backdropFilter: 'blur(5px)',
    backgroundColor: appBgColor
  }}>
				<div style={{
      minHeight: resolveThemingValue(APP_DRAWER_BAR_HEIGHT * 2, "sizes", useTheme())
    }} className={_c0}>
					<AppBackgroundGestureView viewerSharedValues={viewerSharedValues}>
						{channelAppViewer.apps.map((app, index) => {
          return <AppItem isFullScreen={state === 'full'} key={app.appId} viewerSharedValues={viewerSharedValues} appIndex={index} app={app} />;
        })}
					</AppBackgroundGestureView>
					<AppDrawerBar backgroundColor={drawerBgColor} highlightColor={highlightColor} state={state === 'hidden' ? 'minimized' : state} prevState={previousState === 'hidden' ? 'minimized' : previousState} toggleSize={toggle} modifyHeight={modifyHeight} />
				</div>
			</motion.div>;
});
const AppItem: React.FC<{
  viewerSharedValues: ViewerSharedValues;
  appIndex: number;
  isFullScreen: boolean;
  app: AppInstance;
}> = observer('AppItem', ({
  app,
  appIndex,
  isFullScreen,
  viewerSharedValues
}) => {
  const appViewer = useService($ChannelAppViewer);
  const activeIndex = appViewer.activeAppIndex;
  const [scope, animate] = useAnimate();
  React.useEffect(() => {
    if (appIndex === activeIndex) {
      animate(scope.current, {
        x: 0
      }, {
        bounce: 0,
        duration: 0.5
      });
    } else if (appIndex > activeIndex) {
      animate(scope.current, {
        x: 1 * viewerSharedValues.maxWidth.get()
      }, {
        bounce: 0,
        duration: 0.5
      });
    } else if (appIndex < activeIndex) {
      animate(scope.current, {
        x: -1 * viewerSharedValues.maxWidth.get()
      }, {
        bounce: 0,
        duration: 0.5
      });
    }
  }, [activeIndex, appIndex]);
  return <motion.div ref={scope} initial={{
    x: appIndex === activeIndex ? 0 : 1 * viewerSharedValues.maxWidth.get()
  }} style={{
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    flexDirection: 'column'
  }}>
				<KeyboardAvoidanceView enabled={isFullScreen} type={'shrink'} style={{
      width: '100%',
      height: '100%'
    }}>
					{app.displayType === 'fill' ? <FillContentView isFullScreen={isFullScreen} key={app.appId} app={app} /> : <ScaleContentView isFullScreen={isFullScreen} key={app.appId} contentWidth={app.displayWidth} contentHeight={app.displayHeight} viewerSharedValues={viewerSharedValues} app={app} />}
				</KeyboardAvoidanceView>
			</motion.div>;
});
export const FillContentView: React.FC<{
  app: AppInstance;
  isFullScreen: boolean;
}> = observer('FillContentView', ({
  app,
  isFullScreen
}) => {
  const nativeWebviewService = useService($NativeWebViewService);
  const rerender = React.useState({})[1];
  const coordsRef = React.useRef({
    height: 0,
    width: 0
  });
  const safeArea = getSafeArea();
  return <FallbackBox onLayout={e => {
    coordsRef.current = e;
    nativeWebviewService.updateSize({
      appId: app.appId,
      displayHeight: e.height,
      displayWidth: e.width
    }, 1);
    rerender({});
  }} style={{
    paddingTop: resolveThemingValue(isFullScreen ? (safeArea.safeAreaTopHeight as any) : 'none', "spacing", useTheme()),
    paddingBottom: resolveThemingValue(isFullScreen ? (safeArea.safeAreaBottomHeight as any) : 'none', "spacing", useTheme())
  }} className={_c1}>
			{nativeWebviewService.isEnabled ? coordsRef.current.height > 0 ? <NativeAppsWebview scale={1} getSize={() => ({
      height: coordsRef.current.height,
      width: coordsRef.current.width
    })} appInstance={app} key={app.appId} /> : null : <AppsWebview appInstance={app} key={app.appId} />}
		</FallbackBox>;
});
export const ScaleContentView: React.FC<{
  viewerSharedValues: ViewerSharedValues;
  contentWidth: number;
  contentHeight: number;
  app: AppInstance;
  isFullScreen: boolean;
}> = ({
  app,
  contentWidth,
  contentHeight,
  viewerSharedValues: {
    maxWidth,
    maxHeight,
    heightPercentage
  }
}) => {
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef<any>();
  const nativeWebviewService = useService($NativeWebViewService);
  const [scope, animate] = useAnimate();
  const actualScale = () => {
    const percentageHeight = heightPercentage.get();
    const actualHeight = Math.max(maxHeight.get() * percentageHeight - APP_DRAWER_BAR_HEIGHT, 0);
    const scaleX = maxWidth.get() < contentWidth ? maxWidth.get() / contentWidth : 1;
    const scaleY = actualHeight < contentHeight ? actualHeight / contentHeight : 1;
    return Math.min(scaleX, scaleY);
  };
  React.useEffect(() => {
    if (!scope.current) {
      return;
    }
    animate(scope.current, {
      scale: actualScale()
    }, {
      bounce: 0,
      duration: 0.2
    });
    nativeWebviewService.updateSize(app, actualScale());
  }, [actualScale, scope.current]);
  return <FallbackBox onLayout={e => {
    setHeight(e.height);
  }} className={_c2}>
			<div ref={ref} className={_c3}>
				{height > 0 && <motion.div ref={scope} style={{
        position: 'absolute'
      }}>
						<div style={{
          width: resolveThemingValue(contentWidth, "sizes", useTheme()),
          height: resolveThemingValue(contentHeight, "sizes", useTheme())
        }} className={_c4}>
							{nativeWebviewService.isEnabled ? <NativeAppsWebview scale={actualScale()} getSize={() => {
            return {
              height: app.displayHeight,
              width: app.displayWidth
            };
          }} appInstance={app} key={app.appId} /> : <AppsWebview appInstance={app} key={app.appId} />}
						</div>
					</motion.div>}
			</div>
		</FallbackBox>;
};
function getBackgroundColors(color: ColorFragment): {
  appBgColor: string;
  drawerBgColor: string;
} {
  const {
    red,
    green,
    blue
  } = color;
  return {
    appBgColor: `rgba(${red},${green},${blue},0.95)`,
    drawerBgColor: `rgba(${red},${green},${blue},1)`
  };
}
const useViewerSize = (maxSplitViewPercentage: number, maxWidth: number, maxHeight: number, initialState: ViewerState, app?: AppInstance) => {
  const [state, setState] = React.useState<ViewerState>(initialState);
  const [previousState, setPreviousState] = React.useState<ViewerState>('minimized');
  const isAnimating = React.useRef(false);
  const activeChannelService = useService($ActiveChannelService);
  const maxWidthAnimated = useMotionValue(maxWidth);
  const maxHeightAnimated = useMotionValue(maxHeight);
  const heightPercentage = useMotionValue(heightPercentageForState(state, maxWidth, maxHeight, maxSplitViewPercentage, app));
  const channelAppViewerAnimationService = useService($ChannelAppViewerAnimationService);
  const heightOffset = useGetHeightOffset()();
  React.useEffect(() => {
    if (app) {
      setNewState(initialState, maxHeight, 'minimized');
    }
    return () => {
      channelAppViewerAnimationService.setState('hidden');
    };
  }, [activeChannelService.activeChannel]);
  React.useEffect(() => {
    if (!isAnimating.current) {
      maxHeightAnimated.set(maxHeight);
      heightPercentage.set(heightPercentageForState(state, maxWidth, maxHeight, maxSplitViewPercentage, app));
    }
  }, [maxSplitViewPercentage, maxHeight, isAnimating]);
  React.useEffect(() => {
    maxWidthAnimated.set(maxWidth);
  }, [maxWidth]);
  const updateHeightPercentageAnimated = (toState: ViewerState, toMaxHeight: number) => {
    const setAnimatingToFalse = () => {
      isAnimating.current = false;
    };
    isAnimating.current = true;
    animateManual(heightPercentage, heightPercentageForState(toState, maxWidth, toMaxHeight, maxSplitViewPercentage, app), {
      duration: 0.25,
      onUpdate: () => {
        // heightPercentage.set(latest);
      },
      onComplete: () => {
        setAnimatingToFalse();
      }
    });
  };
  React.useEffect(() => {
    updateHeightPercentageAnimated(state, maxHeight);
  }, [app?.displayHeight]);
  const setNewState = (newState: ViewerState, newMaxHeight: number, prevState?: ViewerState) => {
    setState(newState);
    setPreviousState(prevState ?? state);
    channelAppViewerAnimationService.setState(newState);
    updateHeightPercentageAnimated(newState, newMaxHeight);
    animateManual(maxHeightAnimated, newMaxHeight, {
      duration: 0.25,
      onUpdate: latest => {
        maxHeightAnimated.set(latest);
      }
    });
  };
  useChangeStateOnLandscape(maxHeight, state, setNewState);
  const toggle = () => {
    let newState: ViewerState;
    let newMaxHeight: number;
    if (!app) {
      newState = 'hidden';
      newMaxHeight = maxHeight;
    } else if (state === 'hidden') {
      newState = 'split';
      newMaxHeight = maxHeight;
    } else if (state === 'split') {
      if (previousState === 'minimized' || previousState === 'hidden') {
        newState = 'full';
        newMaxHeight = maxHeight + heightOffset;
      } else {
        newState = 'minimized';
        newMaxHeight = maxHeight;
      }
    } else if (state === 'full') {
      newState = 'split';
      newMaxHeight = maxHeight;
    } else {
      newState = 'split';
      newMaxHeight = maxHeight;
    }
    setNewState(newState, newMaxHeight);
  };
  const modifyHeight = (action: 'increase' | 'decrease') => {
    let newState: ViewerState;
    let newMaxHeight: number;
    if (action === 'increase') {
      if (state === 'minimized') {
        newState = 'split';
        newMaxHeight = maxHeight;
      } else if (state === 'split') {
        newState = 'full';
        newMaxHeight = maxHeight + heightOffset;
      } else {
        return;
      }
    } else if (action === 'decrease') {
      if (state === 'split') {
        newState = 'minimized';
        newMaxHeight = maxHeight - heightOffset;
      } else if (state === 'full') {
        newState = 'split';
        newMaxHeight = maxHeight - heightOffset;
      } else {
        return;
      }
    } else {
      return;
    }
    setNewState(newState, newMaxHeight);
  };
  React.useEffect(() => {
    if (!app && state !== 'hidden') {
      toggle();
    } else if (app && state === 'hidden') {
      toggle();
    }
  }, [state, app]);
  return {
    state,
    previousState,
    toggle,
    modifyHeight,
    viewerSharedValues: {
      heightPercentage,
      maxWidth: maxWidthAnimated,
      maxHeight: maxHeightAnimated
    }
  };
};
function heightPercentageForState(state: ViewerState, maxWidth: number, maxHeight: number, maxSplitViewPercentage: number, app?: AppInstance): number {
  if (state === 'hidden') {
    return 0;
  }
  if (state === 'split') {
    return getSplitViewHeightPercentage(maxSplitViewPercentage, maxWidth, maxHeight, app);
  }
  if (state === 'minimized') {
    return getMinimizedHeightPercentage(maxHeight);
  }
  return 1;
}
const useGetHeightOffset = () => {
  const navBarService = useService($NavBarService);
  return () => {
    return navBarService.isBottom ? TITLE_BAR_HEIGHT + NAV_BAR_BOTTOM_HEIGHT : TITLE_BAR_HEIGHT;
  };
};
const useChangeStateOnLandscape = (maxHeight: number, state: ViewerState, setNewState: (newState: ViewerState, newMaxHeight: number) => void) => {
  const stateBeforeLandscape = React.useRef<ViewerState | null>(null);
  const maxHeightRef = React.useRef(maxHeight);
  React.useEffect(() => {
    maxHeightRef.current = maxHeight;
  }, [maxHeight]);
  const screenService = useService($ScreenService);
  const getHeightOffset = useGetHeightOffset();
  const isLandscape = useReactiveState(() => screenService.isLandscape, [screenService]);
  const previousLandscape = usePrevious(isLandscape);
  React.useEffect(() => {
    if (previousLandscape === undefined) {
      return;
    }
    if (isLandscape && screenService.isStackedLayout) {
      stateBeforeLandscape.current = state;
      if (state === 'split') {
        setTimeout(() => {
          setNewState('full', maxHeightRef.current + getHeightOffset());
        }, 100);
      }
    } else if (stateBeforeLandscape.current === 'split' && state === 'full') {
      setTimeout(() => {
        setNewState('split', maxHeightRef.current - getHeightOffset());
      }, 100);
    }
  }, [isLandscape]);
};

// tslint:disable-next-line: max-file-line-count
const _c0 = " Knu-FlexCol size-full overflow-hidden ";
const _c1 = " Knu-FlexCol position-relative size-full ";
const _c2 = " Knu-FlexCol position-relative height-full ";
const _c3 = " Knu-FlexCol position-absolute inset-none placeItems-center ";
const _c4 = " Knu-FlexCol ";