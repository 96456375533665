import * as React from 'react';
import { observer } from '@knuddels-app/mobx';
import { ColorFragment } from '@generated/graphql';
import { $ChannelAppViewer } from '@knuddelsModules/Apps';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { Flex, FlexCol, IconChevronLeft, IconChevronRight, ThemeColors, ThemeOverride, useIsDarkColor, resolveThemingValue, useTheme, toPointerHandler, resolveIsDarkColor } from '@knuddels/component-library';
import { APP_DRAWER_BAR_HEIGHT } from '@shared/constants';
import { CurrentUserKnuddelDisplayLazy } from '@knuddelsModules/KnuddelDisplay';
import { CarouselIndicators } from '@shared/components';
import { useService } from '@knuddels-app/DependencyInjection';
import { $ScreenService, useIsStackedLayout } from '@knuddels-app/Screen';
import { IconResize } from './IconResize';
import { IconExpand } from './IconExpand';
import { IconShrink } from './IconShrink';
import { useDrag } from '@use-gesture/react';
import { getSafeArea } from '@knuddels-app/SafeArea';
interface Props {
  backgroundColor: string;
  highlightColor?: ColorFragment;
  state: 'minimized' | 'split' | 'full';
  prevState: 'minimized' | 'split' | 'full';
  toggleSize: () => void;
  modifyHeight: (action: 'increase' | 'decrease') => void;
}
export const AppDrawerBar: React.FC<Props> = observer('AppDrawerBar', ({
  backgroundColor,
  highlightColor,
  toggleSize,
  modifyHeight,
  state,
  prevState
}): JSX.Element => {
  const appViewer = useService($ChannelAppViewer);
  const firebaseAnalyticsService = useService($FirebaseAnalyticsService);
  const showKnuddelDisplay = useIsStackedLayout();
  const screenService = useService($ScreenService);
  const activeAppIndex = appViewer.activeAppIndex;
  const handleIndicatorClick = (item: unknown, index: number) => {
    if (activeAppIndex !== index) {
      firebaseAnalyticsService.logEvent('Chat_AppView', 'AppSwitcher_ChangeByClick');
      appViewer.setActiveAppIndex(index);
    }
  };
  const prevApp = () => {
    firebaseAnalyticsService.logEvent('Chat_AppView', 'AppSwitcher_ChangeByClick');
    appViewer.prevApp();
  };
  const nextApp = () => {
    firebaseAnalyticsService.logEvent('Chat_AppView', 'AppSwitcher_ChangeByClick');
    appViewer.nextApp();
  };
  const isFirstAppActive = activeAppIndex === 0;
  const isLastAppActive = activeAppIndex === appViewer.openAppInstances.length - 1;
  const hasMultipleApps = appViewer.openAppInstances.length > 1;
  const ToggleIcon = state === 'minimized' ? IconResize : state === 'split' && prevState === 'full' ? IconShrink : state === 'split' && prevState !== 'full' ? IconExpand : IconShrink;
  const bind = useDrag(dragState => {
    if (dragState.swipe[1] === 1) {
      modifyHeight('increase');
    } else if (dragState.swipe[1] === -1) {
      modifyHeight('decrease');
    }
  }, {
    axis: 'y',
    swipe: {
      distance: 10,
      velocity: 0,
      duration: 300
    }
  });
  const pb = state === 'full' && !screenService.isLandscape ? getSafeArea().safeAreaBottomHeight : 0;
  return <div className={_c0}>
				<div {...bind()} style={{
      touchAction: 'none'
    }}>
					<div className={_c1}>
						<div style={{
          height: resolveThemingValue(APP_DRAWER_BAR_HEIGHT + pb, "sizes", useTheme()),
          paddingBottom: resolveThemingValue((pb as any), "spacing", useTheme()),
          background: resolveThemingValue((backgroundColor as ThemeOverride), "colors", useTheme())
        }} className={_c2 + ((backgroundColor as ThemeOverride) ? resolveIsDarkColor((backgroundColor as ThemeOverride), useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
							{showKnuddelDisplay && <div className={_c3}>
									<CurrentUserKnuddelDisplayLazy type={'normal'} background={'none'} staticIcon numberFormat={'none'} firebaseContentType={'Chat_AppView'} />
								</div>}
							{hasMultipleApps && <IconChevronLeft size={'large'} disabled={isFirstAppActive} onPress={prevApp} />}
							{hasMultipleApps && <CarouselIndicators items={appViewer.openAppInstances} activeIndex={activeAppIndex} activeColor={highlightColor} onClick={handleIndicatorClick} animateNewItems />}
							{hasMultipleApps && <IconChevronRight disabled={isLastAppActive} onPress={nextApp} size={'large'} />}
						</div>
						<div onClick={toPointerHandler(toggleSize)} className={_c4}>
							<ToggleIcon size={'base'} color={((useIsDarkColor((backgroundColor as any)) ? 'white-transparent-880' : 'black-transparent-880') as ThemeColors)} />
						</div>
					</div>
				</div>
			</div>;
});
const _c0 = " Knu-FlexCol position-relative shadow-Shadow1 ";
const _c1 = " Knu-FlexCol justifyContent-center ";
const _c2 = " Knu-Flex position-relative placeItems-center ";
const _c3 = " Knu-Flex position-absolute left-none pl-base ";
const _c4 = " Knu-FlexCol position-absolute top-none right-none width-56px height-32px pl-base placeItems-center cursor-pointer ";