import * as React from 'react';
import { declareProps, IModel, injectable, injectedComponent, injectProps, useService } from '@knuddels-app/DependencyInjection';
import { AppsWebviewProps } from './AppsWebviewProps';
import { useAppContext } from './useAppContext';
import { NativeWebview } from '@knuddels/native-webview-capacitor-plugin';
import { PluginListenerHandle } from '@capacitor/core';
import { $OverlayService } from '@knuddels-app/overlays';
import { useReactiveState } from '@knuddels-app/tools/useReactiveState';
import { $NativeWebViewService } from '@knuddels-app/NativeWebView';
import { $ChannelAppViewer } from '@knuddelsModules/Apps';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { $CommandService } from '@knuddels-app/Commands';
@injectable()
class AppsWebviewModel implements IModel {
  public id = '';
  public readonly iframeRef = React.createRef<HTMLIFrameElement>();
  constructor(@injectProps()
  private readonly props: AppsWebviewProps) {}
  dispose(): void {}
  init = (context: any) => {
    this.props.appInstance.initWebview({
      postMessage: (message: string) => {
        NativeWebview.sendToWebview({
          message,
          id: this.id
        });
      }
    }, {
      ...context,
      ...this.props.context
    });
  };
  handleMessage = (ev: MessageEvent) => {
    if (this.iframeRef.current && ev.source === this.iframeRef.current.contentWindow) {
      this.props.appInstance.handleMessageEvent(ev.data);
    }
  };
}
const lastRestartTimes = new Map<string, number>();
export const NativeAppsWebview = injectedComponent({
  name: 'NativeAppsWebview',
  model: AppsWebviewModel,
  props: declareProps<AppsWebviewProps & {
    scale: number;
    getSize: () => {
      width: number;
      height: number;
    };
  }>()
}, ({
  model,
  appInstance,
  scale,
  getSize
}) => {
  const genericContext = useAppContext(appInstance);
  const contentBg = React.useRef(genericContext.contentBg);
  const snapshotRef = React.useRef<string | null>(null);
  const tookSnapshot = React.useRef(false);
  const rerender = React.useState({})[1];
  const nativeWebViewService = useService($NativeWebViewService);
  const firebaseAnalytics = useService($FirebaseAnalyticsService);
  const commandService = useService($CommandService);
  const appViewer = useService($ChannelAppViewer);
  const overlayService = useService($OverlayService);
  useReactiveState(() => {
    if ((overlayService.overlays.length > 0 || overlayService.isSystemAppOverlayVisible) && !tookSnapshot.current) {
      NativeWebview.getSnapshot({
        id: model.id
      }).then(r => {
        snapshotRef.current = r.snapshot;
        tookSnapshot.current = true;
        rerender({});
      });
    } else if (overlayService.overlays.length === 0) {
      tookSnapshot.current = false;
    }
  }, []);
  React.useLayoutEffect(() => {
    const handlers: PluginListenerHandle[] = [];
    (async () => {
      handlers.push(await NativeWebview.addListener('onLoad', e => {
        if (e.id !== model.id) {
          return;
        }
        model.init(genericContext);
      }), await NativeWebview.addListener('onMessage', e => {
        if (e.id !== model.id) {
          return;
        }
        appInstance.handleMessageEvent(e.payload);
      }), await NativeWebview.addListener('onCrash', ({
        id
      }) => {
        if (id !== model.id) {
          return;
        }
        firebaseAnalytics.logEvent('app_crash', `channel_name%${appInstance.channelName}%app_id%${appInstance.appId}%`);
        console.error('crash detected', appInstance.channelName, appInstance.appId);
        const lastRestartTime = lastRestartTimes.get(appInstance.appId) ?? 0;
        if (Date.now() - lastRestartTime < 1000 * 60) {
          return;
        }

        // try to restart crash
        if (appInstance.channelName.toLowerCase().includes('crash')) {
          lastRestartTimes.set(appInstance.appId, Date.now());
          commandService.invokeCommand({
            commandName: 'refreshgame',
            parameter: ''
          });
        }
        if (appInstance.channelName === 'DiceOne') {
          lastRestartTimes.set(appInstance.appId, Date.now());
          // bonus farmer
          if (appInstance.appId.includes('544115')) {
            commandService.invokeCommand({
              commandName: 'bg3',
              parameter: ''
            });
          } else {
            commandService.invokeCommand({
              commandName: 'menü',
              parameter: ''
            });
          }
        }
      }));
    })();
    return () => {
      handlers.forEach(h => h.remove());
    };
  }, [appInstance]);
  const loaderUrl = appInstance.getLoaderUrl(contentBg.current, genericContext);
  React.useLayoutEffect(() => {
    nativeWebViewService.render(loaderUrl, {
      appId: appInstance.appId,
      getSize
    }, scale, appViewer.activeApp === appInstance ? 'active' : 'inactive').then(({
      id
    }) => {
      model.id = id;
      nativeWebViewService.updateState(appInstance, appViewer.activeApp === appInstance ? 'active' : 'inactive');
    });
    return () => {
      nativeWebViewService.destroy(appInstance);
    };
  }, [loaderUrl]);
  if (snapshotRef.current) {
    return <img src={`data:image/jpeg;base64,${snapshotRef.current}`} height={'100%'} width={'100%'} />;
  }
  return null;
});