import * as React from 'react';
import { useService } from '@knuddels-app/DependencyInjection';
import { OverflowMenuTrigger, useOverflowMenuTrigger } from '@shared/components';
import { Flex, IconMoreVertical } from '@knuddels/component-library';
import { $OverlayService, OverlayFactory } from '@knuddels-app/overlays';
import { AlbumDetailsOverflowContextMenu } from './AlbumDetailsContextMenu';
import { observer } from '@knuddels-app/mobx';
import { AlbumDetailsUserContext, SelectedAlbumPhotoContext } from '../AlbumDetailsUserContext';
import { useUpdateProfileAlbums } from '../../profileQueryHelper';
import { SetEditingPhotoDescriptionContext } from '../EditPhotoDescriptionContext';
import { useSlashCommandWithoutChannel } from '@knuddels-app/Commands/useSlashCommandWithoutChannel';
import { IconReport } from '@shared/icons/IconReport';
import { IconWithShadow } from '../../shared/IconWithShadow';
export const useOverflowMenu = () => {
  const selectedPhotoContext = React.useContext(SelectedAlbumPhotoContext);
  const updateProfileAlbumInfo = useUpdateProfileAlbums();
  const overlayService = useService($OverlayService);
  const startEditingDescription = React.useContext(SetEditingPhotoDescriptionContext).startEditing;
  const open: React.ComponentProps<typeof OverflowMenuTrigger>['showOverflowMenu'] = (x, y, width, height) => {
    overlayService.showOverlayIfNotVisible(AlbumDetailsOverflowContextMenuOverlay, {
      chevronX: x,
      chevronY: y,
      chevronWidth: width,
      chevronHeight: height,
      ...selectedPhotoContext,
      startEditingDescription,
      updateProfileAlbumInfo
    });
  };
  return {
    open,
    isOpen: !!overlayService.findOverlay(AlbumDetailsOverflowContextMenuOverlay.getFilter())
  };
};
export const OpenAlbumDetailsContextMenu: React.FC = observer('OpenAlbumDetailsContextMenu', () => {
  const {
    administrationUrl,
    isOwner
  } = React.useContext(SelectedAlbumPhotoContext);
  return <div className={_c0}>
				<ReportIcon />
				{(administrationUrl || isOwner) && <MoreIcon />}
			</div>;
});
const ReportIcon: React.FC = () => {
  const executeSlashCommand = useSlashCommandWithoutChannel();
  const {
    photoId,
    isOwner
  } = React.useContext(SelectedAlbumPhotoContext);
  const {
    nick
  } = React.useContext(AlbumDetailsUserContext);
  if (isOwner) {
    return null;
  }
  return <IconWithShadow onPress={() => {
    executeSlashCommand(`/opensystemapp UserReport:{selectedPhotoId:'${photoId}', selectedUser:'${nick}'}`);
  }} InnerIcon={IconReport} />;
};
const MoreIcon: React.FC = () => {
  const {
    open,
    isOpen
  } = useOverflowMenu();
  const {
    ref,
    onPress
  } = useOverflowMenuTrigger(open);
  return <IconWithShadow innerRef={ref} active={isOpen} onPress={e => onPress(e)} InnerIcon={IconMoreVertical} />;
};
const AlbumDetailsOverflowContextMenuOverlay = new OverlayFactory(AlbumDetailsOverflowContextMenu);
const _c0 = " Knu-Flex position-absolute top-tiny right-tiny ";