import { declareProps, injectedComponent } from '@knuddels-app/DependencyInjection';
import * as React from 'react';
import { NavBar } from './NavBar';
import { styles } from './styles';
import { Flex, FlexCol, ThemeOverride, useTheme, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { NavComponentModel } from './NavComponentModel';
import { NavBarHidingWrapper } from './NavBarHidingWrapper';
import { BackgroundGradient } from './BackgroundGradient';
import { useIsStackedLayout } from '@knuddels-app/Screen';
export const Nav = injectedComponent({
  name: 'Nav',
  model: NavComponentModel,
  props: declareProps<{
    children?: React.ReactNode;
  }>()
}, ({
  model,
  children
}) => {
  const config = model.config;
  const renderNavBar = () => {
    return <NavBarHidingWrapper config={config}>
					<NavBar model={(model as NavComponentModel)} />
				</NavBarHidingWrapper>;
  };
  const mayRender = true;
  const isDarkTheme = useTheme().id !== 'light';
  const isStackedLayout = useIsStackedLayout();
  return <div className={_c0 + ((isDarkTheme ? "black-solid-880" : "contentLightBg") ? resolveIsDarkColor(isDarkTheme ? "black-solid-880" : "contentLightBg", useTheme()) ? " content-is-dark" : " content-is-light" : "") + (config.navLocation === 'bottom' ? _c1 : _c2) + (isDarkTheme ? _c3 : _c4)}>
				{!isStackedLayout && <BackgroundGradient />}
				{config.zIndex === 'belowContent' && mayRender && renderNavBar()}

				{!!config.extendOptions.overlayContentIfExtended && !model.isCollapsed && <FlexCol {...styles.NavBarOverlay} onPress={model.collapseNav} />}

				{/* content */}
				<div style={{
      marginLeft: resolveThemingValue(config.contentMarginLeft || ('none' as ThemeOverride), "spacing", useTheme())
    }} className={_c5}>
					{children}
				</div>

				{/* absolute/overlay nav */}
				{config.zIndex === 'aboveContent' && mayRender && renderNavBar()}
			</div>;
});
const _c0 = " Knu-Flex flex-1 position-relative zIndex-0 minHeight-0-px ";
const _c1 = " flexDirection-column ";
const _c2 = " flexDirection-row ";
const _c3 = " bg-black-solid-880 ";
const _c4 = " bg-contentLightBg ";
const _c5 = " Knu-FlexCol minHeight-0-px flex-1 ";