import * as React from 'react';
import { inject, injectProps, injectable, injectedComponent, useService } from '@knuddels-app/DependencyInjection';
import { contactsViewId } from '@knuddelsModules/Contacts/ContactsViewProvider';
import { Contacts } from '@knuddelsModules/Contacts/bundle/components/Contacts';
import { $SettingsComponents, contactListSettingsFormat } from '@knuddelsModules/Settings';
import { Router, useParams } from './ContactsRouter';
import { $ViewService } from '@knuddels-app/layout';
import { $FirebaseAnalyticsService } from '@knuddels-app/analytics/firebase';
import { declareFormat } from '@knuddels/i18n';
import { $I18n } from '@knuddels-app/i18n';
import type { ContactsRouterConfig } from '@knuddelsModules/Contacts/ContactsRouterConfig';
import { $MentorService } from '../../../MentorSystem';
@injectable()
class ContactsMainViewModel {
  constructor(@injectProps()
  private readonly props: unknown, @inject($ViewService)
  private readonly viewService: typeof $ViewService.T, @inject($FirebaseAnalyticsService)
  private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T) {}
  public closeView = (): void => {
    this.firebaseAnalyticsService.logEvent('Contacts_Titlebar', 'CloseButton_Clicked');
    this.viewService.closeView(contactsViewId);
  };
  public handleRouteChange = ({
    newPath
  }: {
    newPath: keyof ContactsRouterConfig;
  }) => {
    if (newPath === 'contacts') {
      this.firebaseAnalyticsService.logEvent('Contacts_Settings', 'Titlebar_BackButton');
    } else if (newPath === 'contacts-settings') {
      this.firebaseAnalyticsService.logEvent('Contacts_Titlebar', 'Settings_Clicked');
    }
  };
}
export const ContactsMainView = injectedComponent({
  name: 'ContactsMainView',
  model: ContactsMainViewModel,
  inject: {
    settingsComponents: $SettingsComponents,
    i18n: $I18n
  }
}, ({
  model,
  settingsComponents,
  i18n
}) => {
  return <Router rootPath={'contacts'} onClose={model.closeView} onNavigate={model.handleRouteChange}>
				<Router.Route path={'contacts'} title={i18n.format(declareFormat({
      id: 'contacts.title',
      defaultFormat: 'Contacts'
    }))} hideShadow>
					<Contacts />
				</Router.Route>
				<Router.Route path={'contacts-settings'} title={contactListSettingsFormat.format(i18n)}>
					<settingsComponents.ContactListSettings />
				</Router.Route>
				<Router.Route path={'mentorsystem-about'} title={i18n.format(declareFormat({
      id: 'mentorsystem.about.title',
      defaultFormat: 'About the mentor system'
    }))}>
					<MentorSystemAbout />
				</Router.Route>
				<Router.Route path={'mentorsystem-rewards'} title={i18n.format(declareFormat({
      id: 'mentorsystem.rewards.title',
      defaultFormat: 'Mentor system rewards'
    }))}>
					<MentorSystemRewards />
				</Router.Route>
			</Router>;
});
const MentorSystemAbout: React.FC = () => {
  const mentorService = useService($MentorService);
  const params = useParams('mentorsystem-about');
  return <mentorService.MentorSystemAbout initialTab={params?.tab} />;
};
const MentorSystemRewards: React.FC = () => {
  const mentorService = useService($MentorService);
  const params = useParams('mentorsystem-rewards');
  return <mentorService.MentorSystemRewards initialTab={params?.tab} />;
};