import * as React from 'react';
import { Spacer } from '@shared/components';
import { declareFormat, FormattedMessage } from '@knuddels-app/i18n';
import { RemoveAdsBadge } from './RemoveAdsBadge';
import { Flex, Text } from '@knuddels/component-library';
export function AdsLabel(props: {
  hideRemoveAdsBadge?: true;
  isBottomAd?: boolean;
  bannerId?: string;
}): React.ReactElement {
  return <div className={_c0}>
			<Text type={'tiny'} state={'tertiary'} className={_c1}>
				<FormattedMessage id={declareFormat({
        id: 'AD_ZONE_LABEL',
        defaultFormat: 'Ad'
      })} />
			</Text>
			<Spacer size={'auto'} />
			{props.hideRemoveAdsBadge ? <Spacer size={'minor'} /> : <RemoveAdsBadge bannerId={props.bannerId} />}
		</div>;
}
const _c0 = " Knu-Flex position-relative alignItems-center ";
const _c1 = "  ";