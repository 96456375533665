import { EmptyViewState, Position, ViewId, ViewProvider, ViewRegistry } from '@knuddels-app/layout';
import * as React from 'react';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { LoadService } from '@knuddels-app/ModuleSystem';
import { $ExploreComponents } from '@knuddelsModules/Explore/providedServices';
import { $ScreenService } from '@knuddels-app/Screen';
@injectable()
export class ExploreViewProvider implements ViewProvider {
  constructor(@inject($ScreenService)
  private readonly screenService: typeof $ScreenService.T) {}
  registerViews(registry: ViewRegistry): void {
    registry.registerView({
      viewId: exploreViewId,
      position: Position.Main,
      loadState: EmptyViewState.fromPersistedViewState,
      isDefault: () => globalEnv.product === 'app' && this.screenService.isStackedLayout,
      isDefaultPriority: 1,
      render: () => {
        return {
          mainView: <LoadService service={$ExploreComponents}>
							{s => <s.MainView />}
						</LoadService>
        };
      },
      hideDesktopBackground: () => true
    });
  }
}
export const exploreViewId = new ViewId<EmptyViewState>('explore');