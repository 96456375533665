import { injectable } from '@knuddels-app/DependencyInjection';
import { Position, ViewProvider, ViewRegistry } from '@knuddels-app/layout';
import * as React from 'react';
import { getRouterStateFromPersistedViewState, RouterViewState } from '@knuddels-app/layout/k3Router';
import { RouterConfig } from './bundle/components/GlobalApp/GlobalAppsRouter';
import { globalAppViewId } from './bundle/components/GlobalApp/viewId';
import { MainView } from '@knuddelsModules/SystemApps/bundle/components/GlobalApp/MainView';
import * as providesServices from './providedServices';
import { dangerouslyGetK3Container, LoadService } from '@knuddels-app/ModuleSystem';
import { $GlobalAppsAppViewer } from '@knuddelsModules/Apps';
@injectable()
export class GlobalAppViewProvider implements ViewProvider {
  registerViews(registry: ViewRegistry): void {
    registry.registerView({
      viewId: globalAppViewId,
      position: Position.Side,
      isSystemApp: true,
      getOverrideStyles: () => ({
        flex: 1,
        maxWidth: 1100
      }),
      loadState: GlobalAppViewState.fromPersistedViewState,
      render: () => {
        return {
          mainView: <LoadService service={providesServices.$SystemAppService}>
							{() => <MainView />}
						</LoadService>
        };
      }
    });
  }
}
export class GlobalAppViewState extends RouterViewState<RouterConfig> {
  public static fromPersistedViewState = getRouterStateFromPersistedViewState(GlobalAppViewState);
  onOpenByMenu(): this {
    const s = dangerouslyGetK3Container().getService($GlobalAppsAppViewer);
    s.closeAllApps();
    return super.onOpenByMenu();
  }
  onOpenByNav(): this {
    return this.onOpenByMenu();
  }
}