import { BaseColors as s, getLightThemeDecisions as h, toThemeColorMapping as l, Texts as t, Icons as d, WhiteSolid as r, BlackTransparent as e, BlackSolid as n } from "./colors.js";
import { FONT_FAMILY as o } from "../components/configs/fonts.js";
const b = (a = 1, i = n, g) => ({
  id: "light",
  colors: {
    basic: {
      ...s,
      ...h(i, g),
      ...l("black-solid", i)
    },
    gradients: {
      backgroundScrim: [
        { color: "backgroundScrimGradientTop", stop: -82e-4 },
        { color: "backgroundScrimGradientBottom", stop: 1 }
      ]
    },
    texts: {
      dark: t.onDark,
      light: t.onLight
    },
    icons: {
      dark: d.onDark,
      light: d.onLight
    },
    fotomeet: {
      dislikeBorder: "rgba(239, 117, 146, 0.22)",
      likeBorder: "rgba(138, 222, 52, 0.22)"
    },
    Shadow: {
      onDark: {
        none: {
          native: {},
          literal: "none",
          color: "rgba(0, 0, 0, 0)"
        },
        Shadow1: {
          native: {
            elevation: 1,
            shadowColor: "rgba(0,0,0,0.38)",
            shadowOffset: { width: 0, height: 1 },
            shadowRadius: 2,
            shadowOpacity: 1
          },
          literal: `0 1px 3px 0 rgba(0, 0, 0, 0.2),
          0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14)`,
          color: "rgba(0,0,0,0.38)"
        },
        Shadow2: {
          native: {
            elevation: 2,
            shadowColor: "rgba(0,0,0,0.38)",
            shadowOffset: { width: 0, height: 1 },
            shadowRadius: 2,
            shadowOpacity: 1
          },
          literal: `0 1px 5px 0 rgba(0, 0, 0, 0.2),
          0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14)`,
          color: "rgba(0,0,0,0.38)"
        },
        Shadow3: {
          native: {
            elevation: 3,
            shadowColor: "rgba(0,0,0,0.37)",
            shadowOffset: { width: 0, height: 2 },
            shadowRadius: 5,
            shadowOpacity: 1
          },
          literal: `0 1px 8px 0 rgba(0, 0, 0, 0.2),
          0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 3px 3px 0 rgba(0, 0, 0, 0.14)`,
          color: "rgba(0,0,0,0.37)"
        },
        Shadow4: {
          native: {
            elevation: 4,
            shadowColor: "rgba(0,0,0,0.37)",
            shadowOffset: { width: 0, height: 2 },
            shadowRadius: 7,
            shadowOpacity: 1
          },
          literal: `0 1px 10px 0 rgba(0, 0, 0, 0.2),
          0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14)`,
          color: "rgba(0,0,0,0.37)"
        },
        ShadowNegative1: {
          native: {
            elevation: 4,
            shadowColor: "rgba(0, 0, 0, 0,07)",
            shadowOffset: { width: 0, height: -3 },
            shadowRadius: 7,
            shadowOpacity: 1
          },
          literal: "0px -1px 5px 0px rgba(0, 0, 0, 0,07), 0px -2px 4px 0px rgba(0, 0, 0, 0,05), 0px -3px 4px 0px rgba(0, 0, 0, 0,04)",
          color: "rgba(0, 0, 0, 0,07)"
        }
      },
      onLight: {
        none: {
          native: {},
          literal: "none",
          color: "rgba(0, 0, 0, 0)"
        },
        Shadow1: {
          native: {
            elevation: 1,
            shadowColor: "rgba(0,0,0,0.13)",
            shadowOffset: { width: 0, height: 2 },
            shadowRadius: 3,
            shadowOpacity: 1
          },
          literal: `0 1px 3px 0 rgba(0, 0, 0, 0.066),
          0 2px 2px 0 rgba(0, 0, 0, 0.0396), 0 0 2px 0 rgba(0, 0, 0, 0.0462)`,
          color: "rgba(0,0,0,0.13)"
        },
        Shadow2: {
          native: {
            elevation: 2,
            shadowColor: "rgba(0,0,0,0.12)",
            shadowOffset: { width: 0, height: 3 },
            shadowRadius: 5,
            shadowOpacity: 1
          },
          literal: `0 1px 5px 0 rgba(0, 0, 0, 0.066),
          0 3px 4px 0 rgba(0, 0, 0, 0.0396), 0 2px 4px 0 rgba(0, 0, 0, 0.0462)`,
          color: "rgba(0,0,0,0.12)"
        },
        Shadow3: {
          native: {
            elevation: 3,
            shadowColor: "rgba(0,0,0,0.12)",
            shadowOffset: { width: 0, height: 3 },
            shadowRadius: 5,
            shadowOpacity: 1
          },
          literal: `0 1px 8px 0 rgba(0, 0, 0, 0.066),
          0 3px 4px 0 rgba(0, 0, 0, 0.0396), 0 3px 3px 0 rgba(0, 0, 0, 0.0462)`,
          color: "rgba(0,0,0,0.12)"
        },
        Shadow4: {
          native: {
            elevation: 4,
            shadowColor: "rgba(0,0,0,0.12)",
            shadowOffset: { width: 0, height: 3 },
            shadowRadius: 7,
            shadowOpacity: 1
          },
          literal: `0 1px 10px 0 rgba(0, 0, 0, 0.066),
          0 4px 5px 0 rgba(0, 0, 0, 0.0396), 0 2px 4px 0 rgba(0, 0, 0, 0.0462)`,
          color: "rgba(0,0,0,0.12)"
        },
        ShadowNegative1: {
          native: {
            elevation: 4,
            shadowColor: "rgba(0, 0, 0, 0,07)",
            shadowOffset: { width: 0, height: -3 },
            shadowRadius: 7,
            shadowOpacity: 1
          },
          literal: `0 -1px 5px 0 rgba(0, 0, 0, 0.07),
          0 -2px 4px 0 rgba(0, 0, 0, 0.05), 0 -3px 4px 0 rgba(0, 0, 0, 0.04)`,
          color: "rgba(0, 0, 0, 0,07)"
        }
      }
    },
    Icons: {
      white: {
        hover: 0.66,
        disabled: 0.22,
        tertiary: 0.44,
        secondary: 0.66,
        primary: 1,
        error: 1,
        highlighted: 1
      },
      black: {
        hover: 0.66,
        disabled: 0.22,
        tertiary: 0.44,
        secondary: 0.66,
        primary: 0.88,
        error: 1,
        highlighted: 1
      },
      accent: {
        hover: 0.66,
        disabled: 0.22,
        tertiary: 0.44,
        secondary: 0.66,
        primary: 1,
        error: 1,
        highlighted: 1
      }
    },
    Switch: {
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)",
      activeBoxShadow: "0px 0px 0px 10px rgba(175, 141, 233, 0.3)"
    },
    BannerBox: {
      shadowColor: "#fff"
    },
    Snackbar: {
      background: "rgb(36, 36, 36)"
    },
    NavBar: {
      background: "#191919",
      shadowColor: "rgba(0,0,0,0.37)",
      overlayBackground: "rgba(0, 0, 0, .22)",
      extensionBottomBackground: "#222222",
      headerAndFooterBackground: "rgba(0, 0, 0, 0.2)"
    },
    ProductChooser: {
      border: "#e5e5e5",
      activeShadowBackground: "#fff"
    },
    ConversationBackground: {
      background: "rgba(96, 84, 109, 0.08)"
    },
    StappMessenger: {
      unreadMarker: "#ee0000"
    },
    Messenger: {
      outgoingMessage: "rgba(96, 84, 109, 1.0)",
      incomingMessage: r.white
    }
  },
  spacings: {
    none: 0,
    tiny: 4,
    small: 8,
    minor: 12,
    base: 16,
    large: 24,
    xlarge: 32,
    xxlarge: 48,
    xxxlarge: 56
  },
  aspectRatio: {
    square: 1,
    video: 16 / 9,
    "4/3": 4 / 3
  },
  sizes: {
    texts: {
      headline: {
        fontFamily: o.TITLE,
        fontSize: 24 * a,
        lineHeight: 31 * a,
        fontWeight: "bold"
      },
      title: {
        fontFamily: o.TITLE,
        fontSize: 20 * a,
        lineHeight: 24 * a,
        letterSpacing: 0.3 * a,
        fontWeight: "bold"
      },
      subtitle: {
        fontFamily: o.TITLE,
        fontSize: 16 * a,
        lineHeight: 20 * a,
        letterSpacing: 0.3 * a,
        fontWeight: "bold"
      },
      body1: {
        fontFamily: o.BASE,
        fontSize: 16 * a,
        lineHeight: 20 * a
      },
      body2: {
        fontFamily: o.BASE,
        fontSize: 14 * a,
        lineHeight: 18 * a
      },
      tiny: {
        fontFamily: o.BASE,
        fontSize: 12 * a,
        lineHeight: 16 * a
      }
    },
    tabs: {
      tabsListHeight: 48,
      tabsMinWidth: 90,
      activeIndicatorHeight: 5
    },
    input: {
      default: 48,
      condensed: 32,
      textarea: 84
    },
    checkbox: {
      default: 20,
      large: 28
    },
    radio: {
      default: 20,
      selectionMarker: 8
    },
    fab: {
      default: 54,
      condensed: 40
    },
    hitarea: 44,
    borderRadius: {
      none: 0,
      tiny: 4,
      small: 6,
      minor: 8,
      base: 10,
      large: 12,
      xlarge: 16,
      circle: "50%"
      // a large value equals a circular border in native
    },
    border: {
      none: 0,
      base: 1,
      large: 2,
      xlarge: 3
    },
    pic: {
      icon: 16,
      "very-small": 24,
      small: 32,
      normal: 48,
      large: 64
    },
    icons: {
      minor: 12,
      base: 16,
      large: 24
    },
    dialogs: {
      maxWidth: 320
    },
    buttons: {
      default: 40
    },
    box: {
      full: "100%",
      "three-quarter": "75%",
      half: "50%",
      quarter: "25%"
    }
  },
  fontFamily: {
    base: "Source Sans Pro, Comic Sans MS, sans-serif",
    title: "Dosis, Comic Sans MS, sans-serif"
  },
  buttons: {
    primary: {
      colors: {
        background: "linear-gradient(to bottom,#af8de9,#996ce6)",
        backgroundNative: ["#af8de9", "#996ce6"],
        hover: "linear-gradient(to bottom,#8d74cf,#6a48c2)",
        disabled: "linear-gradient(to bottom,rgba(175, 141, 233, 0.6),rgba(153, 108, 230, 0.6))",
        disabledNative: [
          "rgba(175, 141, 233, 0.6)",
          "rgba(153, 108, 230, 0.6)"
        ]
      },
      border: {
        width: 0,
        colors: {
          normal: "rgba(0,0,0,0)",
          hover: "rgba(0,0,0,0)",
          disabled: "rgba(0,0,0,0)"
        }
      }
    },
    secondary: {
      colors: {
        background: r.white,
        hover: r.white,
        disabled: r.white
      },
      border: {
        width: 2,
        colors: {
          normal: e[220],
          hover: e[440],
          disabled: e[110]
        }
      }
    }
  },
  breakpoints: {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1536
  },
  $$Cache: /* @__PURE__ */ new Map()
}), c = b(1);
export {
  c as LightTheme,
  b as createLightTheme
};
