import * as React from 'react';
import { ChannelGroupFragment, GetChannelCategories } from '@generated/graphql';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { LoadingEntry } from '../../shared/LoadingEntry';
import { CHANNEL_GROUPS_PER_CATEGORY } from '../constants';
import { ChannelList } from './ChannelList/ChannelList';
import { useCacheAndNetworkQuery } from './useCacheAndNetworkQuery';
import { equalUpToDepth } from '@shared/components';
import { useChannelGroupUpdater } from './useChannelGroupUpdater';
import { useIsTabVisible } from './useIsTabVisible';
import { mergePreviewMembers } from '@knuddelsModules/ChannelList/bundle/components/Overview/ChannelSelection/mergePreviewMembers';
import { useIsStackedLayout } from '@knuddels-app/Screen';
export const ChannelCategoriesTab: React.FC<{
  refetchOverview: boolean;
}> = ({
  refetchOverview
}) => {
  const {
    data,
    error,
    loading,
    refetch,
    setData
  } = useCacheAndNetworkQuery(GetChannelCategories, {
    groupAmount: CHANNEL_GROUPS_PER_CATEGORY + 1,
    pixelDensity: getPixelRatio()
  });
  const isStackedLayout = useIsStackedLayout();
  const setVisibleChannels = useUpdater(setData);
  React.useEffect(() => {
    if (refetchOverview) {
      refetch();
    }
  }, [refetchOverview]);
  if (error) {
    return null;
  }
  if (loading && !data) {
    return <LoadingEntry />;
  }
  return <ChannelList categories={[...data]} condensedEntries mayShowActiveChannel={isStackedLayout} trackingContentType={'ChannelSelection_ChannelCategories'} onVisibleChannelsChanged={visibleChannels => {
    setVisibleChannels(visibleChannels);
  }} />;
};
type QueryResult = typeof GetChannelCategories.TPrimaryResult;
type DataUpdater = (prevData: QueryResult) => QueryResult;
const useUpdater = (setData: (updater: DataUpdater) => void) => {
  const updateChannelData = React.useCallback((newChannel: ChannelGroupFragment) => {
    const groupId = newChannel.id;
    setData(prevData => {
      const oldChannel = prevData.map(it => it.channelGroups).reduce((acc, val) => acc.concat(val), []).find(it => it.id === groupId);
      if (!oldChannel) {
        return prevData;
      }
      if (equalUpToDepth(oldChannel, newChannel, undefined, 10)) {
        return prevData;
      }
      const channelToInsert = mergePreviewMembers(oldChannel, newChannel);
      return prevData.map(category => {
        return {
          ...category,
          channelGroups: category.channelGroups.map(group => {
            if (group.id === newChannel.id) {
              return channelToInsert;
            }
            return group;
          })
        };
      });
    });
  }, [setData]);
  const isTabVisible = useIsTabVisible(2);
  return useChannelGroupUpdater(isTabVisible, updateChannelData);
};