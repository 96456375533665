import { declareProps, inject, injectable, injectedComponent, injectProps } from '@knuddels-app/DependencyInjection';
import * as React from 'react';
import { $PaymentModule } from '@knuddelsModules/Payment';
import { declareFormat } from '@knuddels/i18n';
import { FormattedMessage } from '@knuddels-app/i18n';
import { $Environment } from '@knuddels-app/Environment';
import { Flex, IconCloseCircular, PointerEvent, Text, toPointerHandler } from '@knuddels/component-library';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
@injectable()
class Model {
  constructor(@injectProps()
  private readonly props: unknown, @inject($Environment)
  private readonly environment: typeof $Environment.T, @inject.lazy($PaymentModule)
  private readonly getPaymentModule: typeof $PaymentModule.TLazy, @inject($GenericUserEventService)
  private readonly genericUserEventService: typeof $GenericUserEventService.T) {}
  removeAds = (e: PointerEvent, bannerId?: string): void => {
    e.stopPropagation();
    this.genericUserEventService.reportEvent({
      type: 'RemoveAdsClicked',
      bannerId
    });
    if (this.environment.messengerSystemAppInterface) {
      this.environment.messengerSystemAppInterface.client.executeSlashCommand('/vip abo');
    } else {
      this.getPaymentModule().then(service => {
        service.openOverviewModal();
      });
    }
  };
}
export const RemoveAdsBadge = injectedComponent({
  model: Model,
  name: 'RemoveAdsBadge',
  props: declareProps<{
    bannerId?: string;
  }>()
}, ({
  model,
  bannerId
}) => {
  return <div onClick={toPointerHandler(e => model.removeAds(e, bannerId))} className={_c0}>
				<Text type={'tiny'} state={'tertiary'} bold={true} className={_c1}>
					<FormattedMessage id={declareFormat({
        id: 'payment.remove_ad',
        defaultFormat: 'Remove ad'
      })} />
				</Text>
				<IconCloseCircular />
			</div>;
});
const _c0 = " Knu-Flex height-24px placeItems-center cursor-pointer alignSelf-flex-end pl-small ";
const _c1 = " mr-tiny ";