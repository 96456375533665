import * as React from 'react';

/**
 * iOS on mobile seems to have a bug where it doesn't release the image from memory when it's removed from the DOM.
 * This hook is a workaround to force the image to be released from memory when the component is unmounted.
 * It sets the background image to a 1x1 transparent gif when the component is unmounted.
 *
 * ref: https://archive.fngtps.com/2010/mobile-safari-image-resource-limit-workaround/
 */

const emptyGif = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
export const useReleaseImage = () => {
	const ref = React.useRef<HTMLElement>(null);

	React.useLayoutEffect(() => {
		return () => {
			if (ref.current) {
				ref.current.style.backgroundImage = `url(${emptyGif})`;
			}
		};
	}, []);

	return ref;
};
