import {
	inject,
	injectable,
	newServiceId,
} from '@knuddels-app/DependencyInjection';
import { Disposable } from '@knuddels/std';
import { $EvergreenDataService } from '@knuddels-app/evergreenData/index';
import {
	ActionTrigger,
	ClientActionEntryPoint,
} from '@knuddels-app/evergreenData/ActionsEvergreenData';
import { objectKeys } from '@knuddels-app/tools/objectKeys';
import {
	$CommandService,
	$CommandWithoutChannelService,
} from '@knuddels-app/Commands';
import { $LocalStorage, LocalStorageKey } from '@knuddels-app/local-storage';
import { isNative } from '@knuddels-app/tools/isNative';
import { $PaymentHandler } from '@knuddelsModules/Payment';
import { $UserService } from '@knuddelsModules/UserData';
import {
	$GenericUserEventService,
	UserEvent,
	UserEventSubscriber,
} from '@knuddels-app/analytics/generic';
import { OS, os } from '@shared/components/tools/os';

export const $ClientActionsService = newServiceId<ClientActionsService>(
	'$ClientActionsService'
);

const executedClientActions = LocalStorageKey.withJsonSerializer<
	Record<string, Record<string, number>>
>({
	name: 'executedClientActions',
	cookieExpires: { inDays: Number.MAX_SAFE_INTEGER },
});

@injectable()
export class ClientActionsService implements UserEventSubscriber {
	public readonly dispose = Disposable.fn();

	private readonly sessionExecutedTriggers = new Map<string, number>();
	private readonly allTimeExecutedTriggers: Record<
		string,
		Record<string, number>
	> = {};
	private storageEntry = this.localStorage.getEntry(executedClientActions);

	constructor(
		@inject($EvergreenDataService)
		private readonly evergreenDataService: typeof $EvergreenDataService.T,
		@inject($CommandWithoutChannelService)
		private readonly commandWithoutChannelService: typeof $CommandWithoutChannelService.T,
		@inject($CommandService)
		private readonly commandService: typeof $CommandService.T,
		@inject($LocalStorage)
		private readonly localStorage: typeof $LocalStorage.T,
		@inject.lazy($PaymentHandler)
		private readonly getPaymentHandler: typeof $PaymentHandler.TLazy,
		@inject.lazy($UserService)
		private readonly getUserService: typeof $UserService.TLazy,
		@inject($GenericUserEventService)
		private readonly genericUserEventService: typeof $GenericUserEventService.T
	) {
		this.dispose.track(() => {
			this.sessionExecutedTriggers.clear();
		});
		this.dispose.track(this.genericUserEventService.subscribe(this));

		this.allTimeExecutedTriggers = this.storageEntry.get() ?? {};
	}

	handleEvent(event: UserEvent): void {
		const { type, ...params } = event;

		this.onEntryPoint({
			id: type,
			params,
		});
	}

	private async onEntryPoint(
		entryPoint: ClientActionEntryPoint
	): Promise<void> {
		const paymentHandler = await this.getPaymentHandler();
		if (isNative()) {
			await paymentHandler.deferredProductDataPreloaded.promise;
		}
		await this.evergreenDataService.deferredInitialized.promise;

		const userService = await this.getUserService();
		const userId = userService.currentUser!.id;

		const triggers = this.evergreenDataService.data.actions.triggers;
		const firstValidTrigger = triggers.find(it =>
			this.isValidTrigger(userId, it, entryPoint, paymentHandler)
		);

		if (!firstValidTrigger) {
			return;
		}

		const now = Date.now();
		this.sessionExecutedTriggers.set(firstValidTrigger.id, now);
		if (
			firstValidTrigger.frequency === 'once' ||
			typeof firstValidTrigger.timeout === 'number'
		) {
			if (!this.allTimeExecutedTriggers[userId]) {
				this.allTimeExecutedTriggers[userId] = {};
			}
			this.allTimeExecutedTriggers[userId][firstValidTrigger.id] = now;
			this.storageEntry.set(this.allTimeExecutedTriggers);
		}
		this.executeTrigger(firstValidTrigger);
	}

	private isValidTrigger(
		userId: string,
		trigger: ActionTrigger,
		entryPoint: ClientActionEntryPoint,
		paymentHandler: typeof $PaymentHandler.T
	): boolean {
		const matchesEntryPoint = trigger.entryPoints.some(it => {
			if (it.id !== entryPoint.id) {
				return false;
			}

			const paramKeys = objectKeys(it.params);
			if (paramKeys.length === 0) {
				return true;
			}

			return !paramKeys.some(paramKey => {
				const param = it.params[paramKey];
				return param !== entryPoint.params[paramKey];
			});
		});

		if (!matchesEntryPoint) {
			return false;
		}

		const now = Date.now();

		if (
			typeof trigger.activeAfter === 'number' &&
			now < trigger.activeAfter
		) {
			return false;
		}

		if (
			typeof trigger.activeUntil === 'number' &&
			now > trigger.activeUntil
		) {
			return false;
		}

		if (
			trigger.frequency === 'once' &&
			this.allTimeExecutedTriggers[trigger.id]
		) {
			return false;
		}

		const lastExecutionTime =
			this.allTimeExecutedTriggers[userId]?.[trigger.id] ??
			this.sessionExecutedTriggers.get(trigger.id);
		if (lastExecutionTime) {
			if (trigger.frequency !== 'always') {
				return false;
			}

			if (
				typeof trigger.timeout === 'number' &&
				Date.now() - lastExecutionTime < trigger.timeout
			) {
				return false;
			}
		}

		if (
			isNative() &&
			(trigger.requiredStoreOffer || trigger.requiredIntroductionOffer)
		) {
			if (!paymentHandler.preloadedProductData) {
				return false;
			}

			const preloadedDetails = paymentHandler.preloadedProductData;
			const productDetails = objectKeys(preloadedDetails.details).flatMap(
				it => preloadedDetails!.details[it]
			);

			if (trigger.requiredStoreOffer) {
				if (os === OS.ios && !preloadedDetails.eligibleForPromoOffers) {
					return false;
				}

				if (
					!productDetails.some(
						details =>
							details.skuId ===
								trigger.requiredStoreOffer?.skuId &&
							details.availableOffers?.some(
								offer =>
									offer.id ===
									trigger.requiredStoreOffer?.offerId
							)
					)
				) {
					return false;
				}
			}

			if (os === OS.ios && trigger.requiredIntroductionOffer) {
				const introductionOffers =
					preloadedDetails.usedIntroductionOffers;
				if (
					!introductionOffers ||
					introductionOffers.includes(
						trigger.requiredIntroductionOffer.subscriptionGroupId
					)
				) {
					return false;
				}

				const details = productDetails.find(
					details =>
						details.skuId ===
						trigger.requiredIntroductionOffer?.skuId
				);
				if (!details) {
					return false;
				}

				// '$' is a special id for the base offer on ios
				const baseOffer = details.availableOffers?.find(
					it => it.id === '$'
				);

				// Assume that multiple pricingPhases indicate an introduction offer
				if (!baseOffer || baseOffer.pricingPhases.length < 2) {
					return false;
				}
			}
		}

		return true;
	}

	private executeTrigger(trigger: ActionTrigger): void {
		switch (trigger.action.type) {
			case 'slashCommand':
				this.executeSlashCommand(trigger.action.command);
				break;
		}
	}

	private executeSlashCommand(command: string): void {
		const call = this.commandService.tryParseCommandCall(command);
		if (call) {
			this.commandWithoutChannelService.invokeCommand(
				call.commandName,
				call.parameter
			);
		}
	}
}
