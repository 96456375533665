import { FormattedText, FormattedTextDisplay, MessageProps } from '@shared/components';
import * as React from 'react';
import { MessageActionIcon } from '../MessageActionIcon';
import { MessageClickableSender } from '../MessageClickableSender';
import { ActionMessage, ActionMessageFragment } from '../Messages/ActionMessage';
import { Flex, rgb } from '@knuddels/component-library';
import { FormattedTextDisplayWithCommandHandling } from '@knuddels-app/Commands';
import { CondensedMessage } from '@knuddelsModules/Channel/bundle/components/Chat/ChatContent/Messages/CondensedMessage';
import { ChatRenderConfig } from '@knuddelsModules/Channel/bundle/components/Chat/ChatRenderConfig';
const CondensedActionMessage: React.FC<{
  message: ActionMessageFragment;
  renderConfig: ChatRenderConfig;
}> = ({
  message,
  renderConfig
}) => {
  const highlightColor = rgb(renderConfig.channelGroupInfo.highlightColor.red, renderConfig.channelGroupInfo.highlightColor.green, renderConfig.channelGroupInfo.highlightColor.blue);
  return <CondensedMessage>
			<div className={_c0}>
				{message.iconFormattedText && renderConfig.contentMode !== 'textOnly' && <div className={_c1}>
							<FormattedTextDisplay text={FormattedText.fromJsonString(message.iconFormattedText)} />
						</div>}
				<div className={_c2}>
					<FormattedTextDisplayWithCommandHandling textProps={{
          ...((renderConfig.overrideTextProps as any) || {}),
          color: renderConfig.contentMode === 'textOnly' ? undefined : highlightColor,
          italic: true
        }} text={FormattedText.fromJsonString(message.formattedText)} disableLinks={renderConfig.contentMode === 'textOnly'} disableColor={renderConfig.contentMode === 'textOnly'} disableBold={renderConfig.contentMode === 'textOnly'} disableImages={renderConfig.contentMode === 'textOnly'} />
				</div>
			</div>
		</CondensedMessage>;
};
export class ChannelActionMessageGroup extends React.Component<{
  message: ActionMessageFragment;
  renderConfig: ChatRenderConfig;
  animated: boolean;
  isStackedLayout: boolean;
}> {
  public static getKey(message: ActionMessageFragment): string {
    return `action_${message.id}`;
  }
  public render(): React.ReactElement {
    const {
      isStackedLayout,
      message,
      renderConfig
    } = this.props;
    const sharedMessageProps: MessageProps = {
      align: 'left',
      isStackedLayout,
      showImage: true,
      hasArrow: true
    };
    if (renderConfig.renderMode === 'condensed') {
      return <CondensedActionMessage message={message} renderConfig={renderConfig} />;
    }
    return <>
				{message.iconFormattedText ? <MessageActionIcon iconFormattedText={FormattedText.fromJsonString(message.iconFormattedText)} {...sharedMessageProps}>
						<ActionMessage message={message} />
					</MessageActionIcon> : <MessageClickableSender uid={message.sender.id} {...sharedMessageProps}>
						<ActionMessage message={message} />
					</MessageClickableSender>}
			</>;
  }
}
const _c0 = " Knu-Flex flex-1 alignItems-center ";
const _c1 = " Knu-Flex mr-small ";
const _c2 = " Knu-Flex flex-1 ";