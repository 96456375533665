import {
	AppControlPlaneEventInput,
	AppDataEventInput,
	AppEvents,
	SendAppControlPlaneEvent,
	SendAppDataEvent,
} from '@generated/graphql';
import { $K3Firebase } from '@knuddels-app/analytics/firebase';
import { $CommandService } from '@knuddels-app/Commands';
import {
	$AuthenticatedClientService,
	$AuthService,
	$ClientInfoStore,
} from '@knuddels-app/Connection/serviceIds';
import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { action, observable, runInAction, when } from '@knuddels-app/mobx';
import { Disposable, EventEmitter, EventSource } from '@knuddels/std';
import {
	$ChannelAppViewer,
	$ChannelBackgroundAppViewer,
	$CustomPositionAppViewer,
	$GlobalAppsAppViewer,
	$ModalAppViewer,
	$StartNativeClientEventHandler,
	$SystemAppPerformanceService,
	$SystemAppStorageService,
} from '../../providedServices';
import { AppViewer } from './AppViewer';
import { $UserService } from '@knuddelsModules/UserData';
import { AppCallbacks, AppInstance, AppInstanceData } from './AppInstance';
import { shouldLogAppEvents } from './shouldLogAppEvents';
import { ChannelBackgroundAppView } from '../components/ChannelBackgroundAppView';
import {
	ClientEventHandler,
	ClientEvents,
	ShowSnackbarEvent,
} from './ClientEventHandler';
import { $MessageFormatProvider } from '@knuddels-app/i18n';
import { $ViewService } from '@knuddels-app/layout';
import { $NavBarRegistry } from '@knuddelsModules/LoggedInArea';
import {
	CONTACT_FILTER_SETTINGS_APP_ID,
	GLOBAL_APP_ID,
	SMILEY_TRADE_APP_ID,
	WORLDTOUR_APP_ID,
} from '@shared/constants';
import { globalAppViewId } from '@knuddelsModules/SystemApps';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { vibrate } from '@shared/helper/vibrate';
import { copyToClipboard } from '@shared/helper/copyToClipboard';
import { ChannelAppViewer } from '../components/ChannelAppViewer/ChannelAppViewer';
import { ChannelAppViewerBackgroundSpacer } from '../components/ChannelAppViewer/ChannelAppViewerBackgroundSpacer';
import { $ScreenService } from '@knuddels-app/Screen';
import {
	$ClientSettingsService,
	settingsViewId,
} from '@knuddelsModules/Settings';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { handleActivityOccurred } from '@knuddels-app/tools/getClientState';
import de from '../i18n/formats.de.json';
import en from '../i18n/formats.en.json';
import bigHello from '@shared/icons/icon-bighello.gif';
import {
	getNativeFilePicker,
	requestPicturePermissions,
} from '@knuddels-app/tools/filePicking/getNativeFilePicker';
import { $OverlayService } from '@knuddels-app/overlays';
import { $PaymentHandler } from '@knuddelsModules/Payment';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';

const excludesSlashCommands = ['/buyknuddel'];

@injectable()
export class AppService implements AppCallbacks {
	ChannelBackgroundAppComponent = ChannelBackgroundAppView;
	ChannelAppViewer = ChannelAppViewer;
	ChannelAppViewerBackgroundSpacer = ChannelAppViewerBackgroundSpacer;

	get initialized(): boolean {
		return this._initialized;
	}

	readonly dispose = Disposable.fn();

	public get appViewers(): AppViewer[] {
		return [
			this.customPositionAppViewer,
			this.globalAppsAppViewer,
			this.modalAppViewer,
			this.channelBackgroundAppViewer,
			this.channelAppViewer,
		];
	}

	onDirectConnectionLost: (
		appType: 'global' | 'channel',
		globalAppKey?: string,
		channelName?: string
	) => void = undefined;

	public readonly onAppOpened: EventSource<AppInstance>;
	private readonly appOpenedEmitter = new EventEmitter<AppInstance>();

	private readonly activeFirebaseUerProperties: string[] = [];

	private readonly appCloseListeners: Map<string, (() => void)[]> = new Map();

	@observable
	private _initialized = false;

	private nextAppIsGlobal = false;
	public nextGlobalAppAsView = false;

	private genericSystemAppEventHandler: Map<
		string,
		((event: ClientEvents) => void)[]
	> = new Map();

	private openAppListener: Map<
		string,
		((appInstance: AppInstance) => void)[]
	> = new Map();

	constructor(
		@inject($ClientInfoStore)
		private readonly clientInfoStore: typeof $ClientInfoStore.T,
		@inject($UserService)
		private readonly userService: typeof $UserService.T,
		@inject($AuthenticatedClientService)
		private readonly authenticatedClientService: typeof $AuthenticatedClientService.T,
		@inject($CommandService)
		private readonly commandService: typeof $CommandService.T,
		@inject($AuthService)
		private readonly authService: typeof $AuthService.T,
		@inject($ModalAppViewer)
		private readonly modalAppViewer: typeof $ModalAppViewer.T,
		@inject($CustomPositionAppViewer)
		private readonly customPositionAppViewer: typeof $CustomPositionAppViewer.T,
		@inject($GlobalAppsAppViewer)
		public readonly globalAppsAppViewer: typeof $GlobalAppsAppViewer.T,
		@inject($ChannelAppViewer)
		private readonly channelAppViewer: typeof $ChannelAppViewer.T,
		@inject($StartNativeClientEventHandler)
		public readonly startNativeClientEventHandler: typeof $StartNativeClientEventHandler.T,
		@inject($PaymentHandler)
		private readonly paymentHandler: typeof $PaymentHandler.T,
		@inject($ChannelBackgroundAppViewer)
		private readonly channelBackgroundAppViewer: typeof $ChannelBackgroundAppViewer.T,
		@inject($K3Firebase)
		private readonly k3Firebase: typeof $K3Firebase.T,
		@inject($MessageFormatProvider)
		private readonly messageFormatProvider: typeof $MessageFormatProvider.T,
		@inject($ViewService)
		private readonly viewService: typeof $ViewService.T,
		@inject($NavBarRegistry)
		private readonly navBarRegistry: typeof $NavBarRegistry.T,
		@inject($SnackbarService)
		private readonly snackbarService: typeof $SnackbarService.T,
		@inject($ScreenService) private screenService: typeof $ScreenService.T,
		@inject($SystemAppStorageService)
		private systemAppStorageService: typeof $SystemAppStorageService.T,
		@inject.lazy($ClientSettingsService)
		private readonly getClientSettingsService: typeof $ClientSettingsService.TLazy,
		@inject($OverlayService)
		private readonly overlayService: typeof $OverlayService.T,
		@inject($SystemAppPerformanceService)
		private readonly systemAppPerformanceService: typeof $SystemAppPerformanceService.T,
		@inject($GenericUserEventService)
		private readonly genericUserEventService: typeof $GenericUserEventService.T
	) {
		messageFormatProvider.registerFormatProvider(
			locale =>
				// Workaround for metro bundler because it can't handle dynamic imports.
				// See https://github.com/facebook/metro/issues/52
				(
					({
						de,
						en,
					}) as any
				)[locale.language]
		);

		this.onAppOpened = this.appOpenedEmitter.asEvent();

		this.init();

		this.dispose.track(this.channelAppViewer);

		this.dispose.track(() => {
			this.activeFirebaseUerProperties.forEach(key =>
				k3Firebase.analytics.setUserProperties({ [key]: null })
			);
		});

		ScreenOrientation.addListener('screenOrientationChange', e => {
			this.getAllApps().forEach(app => {
				app.updateOrientation(
					e.type.includes('landscape') ? 'landscape' : 'portrait'
				);
			});
		}).then(sub => {
			this.dispose.track(() => sub.remove());
		});
	}

	private getAllApps(): AppInstance[] {
		return this.appViewers
			.map(viewer => viewer.getAllApps())
			.reduce((a, b) => a.concat(b), [])
			.filter(Boolean);
	}

	private init(): void {
		this.dispose.track(
			this.authService.onBeforeVoluntaryLogout.sub(() => {
				this.closeAllApps();
			})
		);

		this.dispose.track(
			this.authenticatedClientService.currentK3Client.subscribeToPrimaryData(
				AppEvents,
				{},
				{
					next: this.handleEvent,
				}
			)
		);

		when(
			{
				name: 'Waiting for subscription connected',
			},
			() =>
				this.authenticatedClientService.currentK3Client
					.subscriptionClientState,
			{
				resolveTest: s => s.kind === 'connected',
			}
		).then(() => {
			runInAction('AppService.init', () => {
				this._initialized = true;
			});
		});
	}

	private getAppViewer(
		event: AppInstanceData & { global: boolean; globalAppViewOpen: boolean }
	): AppViewer {
		return this.appViewers.find(viewer =>
			viewer.canHandleAppEvent({ ...event })
		);
	}

	private getClientEventHandler(
		event: ClientEvents
	): ClientEventHandler<ClientEvents> | null {
		switch (event.name) {
			case 'StartNativePaymentEvent':
				return this.startNativeClientEventHandler;
			default:
				return null;
		}
	}

	@action.bound
	private handleEvent(data: typeof AppEvents.TPrimaryResult): void {
		if (shouldLogAppEvents) {
			console.log('Event from server:', data.__typename, data);
		}
		switch (data.__typename) {
			case 'AppOpenEvent':
				this.systemAppPerformanceService.recordFirstServerResponse(
					data.appId,
					data.pageData
				);
				this.openApp(data);
				break;
			case 'AppClosedEvent':
				this.appViewers.forEach(viewer => viewer.closeApp(data.appId));
				break;
			case 'AppDataEvent': {
				const app = this.appViewers
					.map(viewer => viewer.getApp(data.appId))
					.filter(Boolean)[0];
				if (app) {
					app.sendEventToWebview(data.eventKey, data.eventValue);
				}
				break;
			}
			case 'AppControlPlaneEvent':
				// what events come here?
				break;
			default:
				break;
		}
	}

	@action.bound
	closeAllAppsExceptForChannel(channelName: string | undefined): void {
		this.appViewers.forEach(viewer =>
			viewer.closeAllAppsExceptForChannel(channelName)
		);
	}

	@action.bound
	private closeAllApps(): void {
		this.appViewers.forEach(viewer => viewer.closeAllApps());
	}

	@action.bound
	closeChannelBoundApps(channelName: string): void {
		this.appViewers.forEach(viewer =>
			viewer.closeChannelBoundApps?.(channelName)
		);
	}

	@action.bound
	openApp(data: AppInstanceData): void {
		this.genericUserEventService.reportEvent({
			type: 'SystemApp_OpenApp',
			appId: data.appId?.split(':')[0],
		});

		// do not handle anything for overlays, just open them
		if (data.appId.endsWith(':overlay')) {
			this.doOpenApp(data, false, false);
			return;
		}

		if (data.appId === WORLDTOUR_APP_ID) {
			this.nextAppIsGlobal = true;
		}

		if (
			data.display.__typename === 'AppDisplaySidebar' &&
			data.appId !== GLOBAL_APP_ID &&
			data.appId !== SMILEY_TRADE_APP_ID
		) {
			this.nextAppIsGlobal = true;
		}

		const isGlobalViewOpen =
			this.viewService.isViewVisibleOrPending(globalAppViewId);
		const openedInsideGlobalAppContext =
			isGlobalViewOpen ||
			!this.screenService.isStackedLayout ||
			this.nextGlobalAppAsView;

		this.closeOverlaysIfNeeded(
			data,
			this.nextAppIsGlobal,
			openedInsideGlobalAppContext
		);

		if (this.nextAppIsGlobal) {
			if (
				this.nextGlobalAppAsView ||
				(!isGlobalViewOpen && !this.screenService.isStackedLayout)
			) {
				this.viewService.openView(
					globalAppViewId.with(s => s.withPath('apps'))
				);
			} else if (!this.screenService.isStackedLayout) {
				this.viewService.openView(globalAppViewId);
			}
		}

		this.doOpenApp(
			data,
			this.nextAppIsGlobal,
			openedInsideGlobalAppContext
		);
	}

	private closeOverlaysIfNeeded(
		data: AppInstanceData,
		isGlobal: boolean,
		openedInsideGlobalAppContext: boolean
	): void {
		const appViewer = this.getAppViewer({
			...data,
			global: isGlobal,
			globalAppViewOpen: openedInsideGlobalAppContext,
		});
		if (
			appViewer === this.globalAppsAppViewer &&
			(!isGlobal || !openedInsideGlobalAppContext)
		) {
			this.overlayService.closeAllOverlays();
		}
	}

	@action.bound
	private doOpenApp(
		data: AppInstanceData,
		isGlobalApp: boolean,
		openedInsideGlobalAppContext: boolean
	): void {
		this.nextAppIsGlobal = false;
		this.nextGlobalAppAsView = false;
		const appViewer = this.getAppViewer({
			...data,
			global: isGlobalApp,
			globalAppViewOpen: openedInsideGlobalAppContext,
		});
		appViewer.closeApp(data.appId);

		runInAction('Open app', () => {
			if (this.userService.currentUser) {
				const appInstance = new AppInstance(
					{
						initAppData: {
							...data,
							isGlobalApp,
							openedInsideGlobalAppContext,
						},
						currentUserNick: this.userService.currentUser.nick,
						clientVersion: this.clientInfoStore.clientVersion,
						callbacks: this,
					},
					this.systemAppPerformanceService
				);

				const openAppListeners = this.openAppListener.get(data.appId);
				if (openAppListeners) {
					appInstance
						.onOpen()
						.then(() =>
							openAppListeners.forEach(l => l(appInstance))
						);
				}

				this.appOpenedEmitter.emit(appInstance);

				appViewer.addApp(appInstance);
			}
		});
	}

	registerAppCloseListener(appId: string, listener: () => void): () => void {
		const listeners = this.appCloseListeners.get(appId);
		if (!listeners) {
			this.appCloseListeners.set(appId, [listener]);
		} else {
			listeners.push(listener);
		}

		return () => {
			const appCloseListeners = this.appCloseListeners.get(appId);
			if (appCloseListeners) {
				this.appCloseListeners.set(
					appId,
					appCloseListeners.filter(l => l !== listener)
				);
			}
		};
	}

	closeApp(appId: string): void {
		this.appViewers.forEach(viewer => viewer.closeApp(appId));
	}

	@action.bound
	onAppClose(appId: string): void {
		if (this.appCloseListeners.has(appId)) {
			this.appCloseListeners.get(appId).forEach(l => l());
		}

		this.appViewers.forEach(viewer => viewer.removeApp(appId));
	}

	registerEventHandler(
		appId: string,
		handler: (event: any) => void
	): () => void {
		if (!this.genericSystemAppEventHandler.has(appId)) {
			this.genericSystemAppEventHandler.set(appId, []);
		}
		const handlers = this.genericSystemAppEventHandler.get(appId);
		handlers.push(handler);
		this.genericSystemAppEventHandler.set(appId, handlers);
		return () => {
			const h = this.genericSystemAppEventHandler.get(appId);
			this.genericSystemAppEventHandler.set(
				appId,
				h.filter(han => han !== handler)
			);
		};
	}

	registerAppOpenListener(
		appId: string,
		listener: (appInstance: AppInstance) => void
	): () => void {
		if (!this.openAppListener.has(appId)) {
			this.openAppListener.set(appId, []);
		}

		const alreadyOpenedInstance = this.getAllApps().find(
			a => a.appId === appId
		);
		if (alreadyOpenedInstance) {
			listener(alreadyOpenedInstance);
		}

		const listeners = this.openAppListener.get(appId);
		listeners.push(listener);
		this.openAppListener.set(appId, listeners);
		return () => {
			const ls = this.openAppListener.get(appId);
			this.openAppListener.set(
				appId,
				ls.filter(l => l !== listener)
			);
		};
	}

	sendAppEvent(event: AppDataEventInput): void {
		this.authenticatedClientService.currentK3Client
			.mutateWithResultPromise(SendAppDataEvent, {
				event,
			})
			.onErr(err => console.error('Error sending app data event', err));
	}

	sendControlPlaneEvent(event: AppControlPlaneEventInput): void {
		this.authenticatedClientService.currentK3Client
			.mutateWithResultPromise(SendAppControlPlaneEvent, {
				event,
			})
			.onErr(err =>
				console.error('Error sending app control plane event', err)
			);
	}

	async executeSlashCommand(cmd: string, appId: string): Promise<void> {
		/*
			TODO: logic should move to the server.
		 */
		this.nextAppIsGlobal =
			(cmd.includes(`sid~k3Sidebar`) ||
				cmd.includes(`sid~appsAndGames`) ||
				cmd.includes(`sid~globalAppsOverview`)) &&
			!excludesSlashCommands.some(slashCmd => cmd.startsWith(slashCmd));

		const result = await this.commandService.parseAndInvokeCommand(
			cmd,
			'SystemApp ' + appId
		);
		if (!result.isOk()) {
			console.log('Error executing slash command', result);
			this.nextAppIsGlobal = false;
			this.nextGlobalAppAsView = false;
		}
	}

	logFirebaseEvent(name: string, jsonParams: string): void {
		this.k3Firebase.analytics.logEvent(name, JSON.parse(jsonParams));
	}

	setFirebaseUserProperty(name: string, value: string): void {
		this.k3Firebase.analytics.setUserProperties({ [name]: value });

		if (this.activeFirebaseUerProperties.indexOf(name) === -1) {
			this.activeFirebaseUerProperties.push(name);
		}
	}

	handleClientEvent(appInstance: AppInstance, event: ClientEvents): void {
		if (event.name === 'reportUserEvent') {
			this.genericUserEventService.reportEvent(event.data as any);
			return;
		}

		if (
			this.systemAppStorageService.handleStorageEvent(appInstance, event)
		) {
			return;
		}

		if (
			event.name === 'directConnectionLost' &&
			this.onDirectConnectionLost
		) {
			if (appInstance.isGlobalApp) {
				this.onDirectConnectionLost('global', appInstance.globalAppKey);
			} else if (!appInstance.nonChannelApp) {
				this.onDirectConnectionLost(
					'channel',
					undefined,
					appInstance.channelName
				);
			}

			return;
		}

		if (event.name === 'activityOccurred') {
			handleActivityOccurred();
			return;
		}

		if (event.name === 'SHOW_SNACKBAR') {
			this.showSnackbar(event);
			return;
		}

		if (event.name === 'VIBRATE') {
			vibrate(event.pattern);
			return;
		}

		if (event.name === 'COPY_TO_CLIPBOARD') {
			copyToClipboard(event.text);
			return;
		}

		if (event.name === 'OPEN_CONTACT_FILTER_SETTINGS') {
			this.openContactFilterSettings(appInstance);
			return;
		}

		if (event.name === 'SELECT_IMAGE') {
			getNativeFilePicker()(async files => {
				const selectedFile = files[0];
				if (!selectedFile) {
					return;
				}

				const base64Image = selectedFile.image.startsWith('data:image')
					? selectedFile.image
					: await this.readAsBase64(
							selectedFile.uploadObject as File
						);

				appInstance.sendEventToWebview('onImageSelected', {
					image: base64Image,
					name: selectedFile.uploadObject.name,
				});
			});
			return;
		}

		if (event.name === 'REQUEST_PICTURE_PERMISSION') {
			requestPicturePermissions().then(result => {
				appInstance.sendEventToWebview('onPicturePermissionResult', {
					result,
				});
			});
			return;
		}

		if (event.name === 'REQUEST_PRODUCT_DETAILS') {
			this.paymentHandler
				.getDetailsForProducts([
					{
						productId: event.productId,
						skuId: event.skuId,
						offerId: event.offerId,
						isSubscription: event.isSubscription,
					},
				])
				.then(result => {
					appInstance.sendEventToWebview(
						'onProductDetails',
						result[0]
					);
				});
			return;
		}

		if (event.name === 'REQUEST_DETAILS_FOR_PRODUCTS') {
			this.paymentHandler
				.getDetailsForProducts(event.productInfos)
				.then(result => {
					appInstance.sendEventToWebview('onDetailsForProducts', {
						productInfos: result,
					});
				});
			return;
		}

		if (event.name === 'INITIAL_PROPS_LOAD_METRIC') {
			this.systemAppPerformanceService.recordInitialPropsLoad(
				event.appId,
				event.measurements
			);
			return;
		}
		const genericSystemAppEventHandler =
			this.genericSystemAppEventHandler.get(event.appId);

		if (genericSystemAppEventHandler) {
			genericSystemAppEventHandler.forEach(h => h(event));
			return;
		}

		const handler = this.getClientEventHandler(event);
		if (handler) {
			handler.handleEvent(event);
		}
	}

	private async readAsBase64(file: File): Promise<string> {
		const reader = new FileReader();
		return new Promise((resolve, reject) => {
			reader.onload = () => resolve(reader.result as string);
			reader.onerror = error => reject(error);
			reader.readAsDataURL(file);
		});
	}

	private showSnackbar(event: ShowSnackbarEvent): void {
		/**
		 * ensure that multiple snackbars with different contents are shown correctly
		 */
		const type =
			event.appId +
			event.snackbar.props.text +
			event.snackbar.props.subtext;
		const text = event.snackbar.props.text;

		// Prevent app from crashing if the system app sends invalid data
		if (typeof type === 'undefined' || typeof text === 'undefined') {
			return;
		}

		this.snackbarService.showSnackbar({
			adornment: event.snackbar.props.adornmentUrl ?? bigHello,
			type,
			text,
			subtext: event.snackbar.props.subtext,
		});
	}

	private openContactFilterSettings(appInstance: AppInstance): void {
		if (globalEnv.product === 'stapp-messenger') {
			this.commandService.parseAndInvokeCommand(
				'/opensystemapp ' + CONTACT_FILTER_SETTINGS_APP_ID
			);
		} else {
			this.viewService.openView(
				settingsViewId.with(s => s.withPath('ContactFilterSettings')),
				{ openContext: 'SystemApp ' + appInstance.appId }
			);
		}
	}

	playSound(appId: string, url: string): void {
		this.getClientSettingsService().then(service => {
			if (service.isSoundEnabledForApp(appId)) {
				new Audio(url).play();
			}
		});
	}
}

// tslint:disable-next-line: max-file-line-count
