import { measureLayoutRelativeToWindow } from '@knuddels-app/tools/measureLayoutRelativeToWindow';
import * as React from 'react';
import { useHovered } from '@knuddels-app/tools/useHovered';
import { Flex, FlexCol, FlexProps, IconSortDown, PointerEvent, Rotate, ThemeOverride, toPointerHandler, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { isMobileSafari } from '@knuddels-app/tools/isMobileSafari';
import { getSafeArea } from '@knuddels-app/SafeArea';
interface OwnProps {
  isMenuActive?: boolean;
  chevronBackgroundColor: string;
  position: 'top' | 'bottom';
  onDark?: boolean;
  testId?: string;
  overrideTop?: number;
  onContentWrapperClick?(): void;
  showContextMenu(x: number, y: number, width: number, height: number): void;
}
type Props = OwnProps;
const wrapperWidthTop = 48;
const wrapperHeightTop = 24;
const wrapperWidthBottom = 24;
const wrapperHeightBottom = 24;
const chevronWidth = 24;
const chevronHeight = 24;
const chevronBottomOffsetBottom = 9;
const chevronBottomOffsetRight = 16;
const chevronTopOffsetRight = -4;
const chevronTopOffsetTop = -6;
export const ContextMenuTrigger: React.FC<Props> = props => {
  const {
    hovered,
    bind: {
      onHover,
      onHoverEnd
    }
  } = useHovered();
  const {
    selfRef,
    openContextMenu
  } = useOpenContextMenu(props);
  const touchTimerRef = React.useRef<number | null>(null);
  const pointerDownPositionRef = React.useRef<{
    x: number;
    y: number;
  }>({
    x: 0,
    y: 0
  });
  const isChevronVisible = hovered || props.isMenuActive;
  const backgroundColor = isChevronVisible ? props.chevronBackgroundColor : 'transparent';
  const positionStyle = props.position === 'top' ? styles.chevronTop : styles.chevronBottom;
  const onPointerDown = (event: PointerEvent) => {
    pointerDownPositionRef.current = {
      x: event.pageX,
      y: event.pageY
    };

    // Clear any existing timer
    if (touchTimerRef.current) {
      clearTimeout(touchTimerRef.current);
      touchTimerRef.current = null;
    }

    // For touch events (not mouse clicks), set up a long-press timer
    if (event.originalEvent.pointerType === 'touch') {
      touchTimerRef.current = window.setTimeout(() => {
        openContextMenu();
      }, 500); // Long press threshold (e.g., 500 ms)
    }
  };

  const onPointerUp = () => {
    // Clear the long-press timer on release
    if (touchTimerRef.current) {
      clearTimeout(touchTimerRef.current);
      touchTimerRef.current = null;
    }
  };
  const onPointerMove = (event: PointerEvent) => {
    const dx = event.pageX - pointerDownPositionRef.current.x;
    const dy = event.pageY - pointerDownPositionRef.current.y;

    // If the user has moved the pointer too far, cancel the long-press timer
    if (Math.abs(dx) > 10 || Math.abs(dy) > 10) {
      if (touchTimerRef.current) {
        clearTimeout(touchTimerRef.current);
        touchTimerRef.current = null;
      }
    }
  };
  return <div onClick={toPointerHandler(props.onContentWrapperClick)} onContextMenu={toPointerHandler(openContextMenu)} onPointerDown={toPointerHandler(isMobileSafari() ? onPointerDown : undefined)} onPointerUp={isMobileSafari() ? onPointerUp : undefined} onPointerMove={isMobileSafari() ? onPointerMove : undefined} onMouseEnter={toPointerHandler(onHover)} onMouseLeave={toPointerHandler(onHoverEnd)} ref={(selfRef as any)} className={_c0 + (props.onContentWrapperClick ? _c1 : _c2)}>
			{props.children}
			{isChevronVisible && <div style={{
      ...positionStyle,
      ...(props.overrideTop && {
        top: props.overrideTop
      }),
      background: resolveThemingValue((backgroundColor as ThemeOverride), "colors", useTheme())
    }} className={_c3 + ((backgroundColor as ThemeOverride) ? resolveIsDarkColor((backgroundColor as ThemeOverride), useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
					<Rotate to={180} rotated={!!props.isMenuActive} delay={100} className={_c4}>
						<IconSortDown onPress={(e: PointerEvent) => {
          e.stopPropagation();
          openContextMenu();
        }} size={'large'} />
					</Rotate>
				</div>}
		</div>;
};
const useOpenContextMenu = (props: Props) => {
  const selfRef = React.useRef<React.Component>();
  const doOpen = async () => {
    try {
      if (!selfRef.current) {
        return;
      }
      const {
        x,
        y,
        width,
        height
      } = await measureLayoutRelativeToWindow(selfRef.current);
      const safeArea = getSafeArea();

      // Ensure that the menu is not displayed outside the window
      const boundedY = Math.max(safeArea.safeAreaTopHeight, y - safeArea.safeAreaTopHeight);

      // Need to do this manually because the chevron is only there if context menu is already open
      // so we can't have a ref on it
      const menuTopX = x + width - chevronWidth - chevronTopOffsetRight;
      const menuTopY = boundedY + (props.overrideTop ?? chevronTopOffsetTop);
      const menuBottomX = x + width - chevronWidth - chevronBottomOffsetRight;
      const menuBottomY = boundedY + height - chevronHeight - chevronBottomOffsetBottom;
      const menuX = props.position === 'bottom' ? menuBottomX : menuTopX;
      const menuY = props.position === 'bottom' ? menuBottomY : menuTopY;
      props.showContextMenu(menuX, menuY, chevronWidth, chevronHeight);
    } catch (err) {
      console.warn(err);
    }
  };
  return {
    selfRef,
    openContextMenu: doOpen
  };
};
const styles = {
  chevronTop: ({
    top: (chevronTopOffsetTop as ThemeOverride),
    right: (chevronTopOffsetRight as ThemeOverride),
    width: wrapperWidthTop,
    height: wrapperHeightTop
  } as FlexProps),
  chevronBottom: ({
    bottom: chevronBottomOffsetBottom,
    right: chevronBottomOffsetRight,
    width: wrapperWidthBottom,
    height: wrapperHeightBottom
  } as ThemeOverride)
};
const _c0 = " Knu-Flex position-relative overflow-visible flexDirection-column ";
const _c1 = " cursor-pointer ";
const _c2 = " cursor-default ";
const _c3 = " Knu-FlexCol position-absolute alignSelf-flex-end placeItems-center cursor-pointer p-tiny zIndex-100000 ";
const _c4 = "  ";