import { jsxs as R, Fragment as w, jsx as l } from "react/jsx-runtime";
import s from "react";
import { createPortal as D } from "react-dom";
import { useTheme as C } from "../../themes/useTheme.js";
import { ChildThemeProvider as E } from "../../themes/ThemeProvider.js";
import { Flex as y } from "../Layout/Flex.js";
import { Text as M } from "../Text/Text.js";
import { useEvent as A } from "../helper/useEvent.js";
import { isMobileSafari as S } from "../helper/browserInfo.js";
import { motion as k } from "../../deps/framer-motion/dist/es/render/components/motion/proxy.js";
import { isTouchDevice as L } from "../Layout/helper/isTouchDevice.js";
const P = (e) => {
  if (!e.clientWidth) {
    const r = e.children[0];
    return r ? P(r) : e;
  }
  return e;
};
let T = !1, x;
const j = (e) => {
  x = setTimeout(() => {
    T = !1;
  }, e);
}, v = 100, B = 500, F = 3e3;
let h;
const O = (e, r) => {
  const t = s.useRef(null), o = s.useRef({
    x: 0,
    y: 0
  }), i = A(e), u = (n) => {
    n.preventDefault(), o.current = {
      x: n.pageX,
      y: n.pageY
    }, t.current && (clearTimeout(t.current), t.current = null), t.current = window.setTimeout(() => {
      i(), r();
    }, 500);
  }, c = (n) => {
    n.preventDefault(), t.current && (clearTimeout(t.current), t.current = null);
  }, a = (n) => {
    const f = n.pageX - o.current.x, p = n.pageY - o.current.y;
    (Math.abs(f) > 10 || Math.abs(p) > 10) && t.current && (clearTimeout(t.current), t.current = null);
  };
  return L() ? S ? {
    onPointerDown: u,
    onPointerUp: c,
    onPointerMove: a
  } : {
    onContextMenu(n) {
      n.preventDefault(), i();
    }
  } : {};
}, Q = ({ content: e, children: r, spacing: t, enabled: o = !0 }) => {
  const i = s.useRef(), u = s.useRef(), c = s.useRef(null), [a, n] = s.useState(null), f = s.useRef(!1), p = () => {
    clearTimeout(u.current), clearTimeout(i.current), clearTimeout(x), T = !0;
  }, d = () => {
    clearTimeout(u.current), clearTimeout(i.current), j(v), u.current = setTimeout(() => {
      n(null);
    }, v);
  }, g = (m) => {
    !o || h === m || (i.current = setTimeout(
      () => {
        n(P(c.current)), p();
      },
      T || m === "longpress" ? 0 : B
    ));
  }, b = O(
    () => {
      g("longpress"), setTimeout(() => {
        d();
      }, F);
    },
    () => {
      f.current = !0;
    }
  );
  return /* @__PURE__ */ R(w, { children: [
    /* @__PURE__ */ l(
      "div",
      {
        ref: c,
        style: { display: "contents" },
        onClickCapture: (m) => {
          if (f.current) {
            f.current = !1, m.stopPropagation(), m.preventDefault();
            return;
          }
          clearTimeout(i.current), d();
        },
        ...b,
        onMouseEnter: () => {
          g("mouseenter"), h = "mouseenter";
        },
        onMouseLeave: () => {
          h = "mouseleave", d();
        },
        children: r
      }
    ),
    a && /* @__PURE__ */ l(W, { spacing: t, anchor: a, children: e })
  ] });
}, W = ({
  anchor: e,
  children: r,
  spacing: t
}) => {
  const [o, i] = s.useState(null), u = s.useRef(null);
  s.useEffect(() => {
    const a = X(
      e.getBoundingClientRect(),
      u.current.getBoundingClientRect(),
      t
    );
    i(a);
  }, [e]);
  const c = C();
  return D(
    /* @__PURE__ */ l(
      k.div,
      {
        ref: u,
        initial: { opacity: 0 },
        animate: { opacity: o ? 1 : 0 },
        style: {
          zIndex: 9999,
          position: "fixed",
          pointerEvents: "none",
          left: o ? o.left : 0,
          top: o ? o.top : 0
        },
        children: /* @__PURE__ */ l(E, { theme: c, children: /* @__PURE__ */ l(
          y,
          {
            bg: "rgba(40,40,40,0.88)",
            borderRadius: "base",
            p: "small",
            children: /* @__PURE__ */ l(y, { bg: "transparentDark", children: /* @__PURE__ */ l(
              M,
              {
                bold: !0,
                type: "body2",
                style: { whiteSpace: "nowrap" },
                children: r
              }
            ) })
          }
        ) })
      }
    ),
    document.body
  );
}, X = (e, r, t = 0) => {
  const o = e.left + e.width + t, i = o + 12 + r.width < window.innerWidth;
  return {
    top: e.top + e.height / 2 - r.height / 2,
    left: i ? o : e.left - r.width - t
  };
};
export {
  Q as Tooltip
};
