import {
	SafeArea as CapacitorSafeArea,
	SafeAreaInsets,
} from 'capacitor-plugin-safe-area';
import { action, computed, observable } from '@knuddels-app/mobx';
import { OS, os } from '@shared/components/tools/os';
import { ScreenOrientation } from '@capacitor/screen-orientation';

(window as any).getSafeArea = () => {
	return getSafeArea();
};

class SafeArea {
	@observable.ref
	private _insets: SafeAreaInsets['insets'] = {
		bottom: 0,
		top: 0,
		left: 0,
		right: 0,
	};

	@observable
	private _orientation: OrientationType = 'portrait-primary';

	get insets() {
		return this._insets;
	}

	get hasBottomNavBar() {
		return os === OS.android && this._insets.bottom > 20;
	}

	@computed
	get safeAreaTopHeight() {
		if (os === OS.android && this._orientation.startsWith('landscape')) {
			return 0;
		}
		return this._insets.top;
	}

	get safeAreaBottomHeight() {
		if (this.hasBottomNavBar) {
			return 0;
		}
		return this._insets.bottom;
	}

	get navBarHeight() {
		if (this.hasBottomNavBar) {
			return this._insets.bottom;
		}
		return 0;
	}

	constructor() {
		CapacitorSafeArea.getSafeAreaInsets().then(info => {
			this.setInsets(info.insets);
		});
		CapacitorSafeArea.addListener('safeAreaChanged', info => {
			this.setInsets(info.insets);
		});
		ScreenOrientation.orientation().then(orientation => {
			this.updateOrientation(orientation.type);
		});
		ScreenOrientation.addListener(
			'screenOrientationChange',
			orientation => {
				this.updateOrientation(orientation.type);
			}
		);
	}

	@action
	private updateOrientation(orientation: OrientationType) {
		this._orientation = orientation;
	}
	@action
	private setInsets(insets: SafeAreaInsets['insets']) {
		this._insets = {
			...insets,
			bottom: Math.max(insets.bottom, insets.right, insets.left),
		};
	}
}

let instance: SafeArea;
export const getSafeArea = () => {
	if (!instance) {
		instance = new SafeArea();
	}
	return instance;
};
