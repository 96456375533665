import { useService } from '@knuddels-app/DependencyInjection';
import { observer } from '@knuddels-app/mobx';
import { createAccessiblePointerEventHandler, createSvgIcon, Flex, rgb, ThemeOverride, useIsDarkColor, useTheme, Z_INDEX, createNativeAccessiblePointerEventHandler, resolveThemingValue, resolveIsDarkColor } from '@knuddels/component-library';
import { $ActiveChannelService } from '@knuddelsModules/Channel';
import { $MessengerMiniChatService } from '@knuddelsModules/Messenger/providedServices';
import { $MiniChatSettingsService } from '@knuddelsModules/Settings';
import clsx from 'clsx';
import * as React from 'react';
import { blurBackground } from './MiniChatOverlay/helper';
import styles from './MiniChatTrigger.module.scss';
const BACKGROUD_BLUR_RADIUS = 5;
const IconTriggerInactive = createSvgIcon(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.8 20">
		<g>
			<path fill="var(--icons-colors-default)" strokeWidth="0px" d="m8.8,0C3.94,0,0,3.58,0,8c0,2.27,1.05,4.31,2.72,5.76-.24.78-.74,1.57-1.66,2.26,0,0,0,0,0,0-.08.03-.14.08-.19.15-.05.07-.07.15-.07.23,0,.11.04.21.12.28.08.08.18.12.28.12.03,0,.05,0,.08,0,1.55,0,2.88-.67,3.93-1.5.5.2,1.02.37,1.57.49-.24-.69-.38-1.42-.38-2.18,0-3.97,3.59-7.2,8-7.2,1.11,0,2.16.2,3.12.57-.56-3.93-4.24-6.97-8.72-6.97Zm5.6,8c-1.7,0-3.33.59-4.53,1.64-1.2,1.05-1.87,2.47-1.87,3.96s.67,2.91,1.87,3.96,2.83,1.64,4.53,1.64c.82,0,1.63-.14,2.39-.41.98.69,2.17,1.19,3.53,1.2.03,0,.06,0,.08,0,.11,0,.21-.04.28-.12.08-.07.12-.18.12-.28,0-.08-.03-.16-.07-.23-.05-.07-.11-.12-.19-.15-.73-.56-1.2-1.17-1.48-1.79,1.12-1.04,1.74-2.41,1.75-3.83,0-1.49-.67-2.91-1.87-3.96-1.2-1.05-2.83-1.64-4.53-1.64Z" />
		</g>
	</svg>);
export const MiniChatTrigger: React.FC<{
  channelColor: string;
}> = observer('MiniChatTrigger', ({
  channelColor
}) => {
  const theme = useTheme();
  const miniChatService = useService($MessengerMiniChatService);
  const miniChatSettingsService = useService($MiniChatSettingsService);
  const activeChannelService = useService($ActiveChannelService);
  const isParentDark = useIsDarkColor((channelColor as ThemeOverride));
  const [highlightColor, setHighlightColor] = React.useState<string | null>(null);
  const IconTriggerActive = React.useMemo(() => createSvgIcon(<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.4 21.4">
						<g>
							<path strokeWidth="0px" fill="var(--icons-colors-default)" fillRule="evenodd" d="m11.22,1.71c-.77-.2-1.58-.31-2.42-.31C3.94,1.4,0,4.98,0,9.4c0,2.27,1.05,4.31,2.72,5.76-.24.78-.74,1.57-1.66,2.26h0c-.08.03-.14.08-.19.15-.05.07-.07.15-.07.23,0,.11.04.21.12.28.08.08.18.12.28.12.03,0,.05,0,.08,0,1.55,0,2.88-.67,3.93-1.5.5.2,1.02.37,1.57.49-.24-.69-.38-1.42-.38-2.18,0-2.97,2.01-5.53,4.87-6.62-.55-1-.87-2.15-.87-3.38s.3-2.31.82-3.29Zm6.18,10.29c.75,0,1.47-.12,2.14-.33.81.96,1.26,2.13,1.26,3.33,0,1.43-.63,2.8-1.75,3.83.28.62.75,1.23,1.48,1.79.08.03.14.08.19.15.05.07.07.15.07.23,0,.11-.04.21-.12.28-.08.08-.18.12-.28.12-.03,0-.06,0-.08,0-1.36,0-2.55-.51-3.53-1.2-.76.27-1.57.41-2.39.41-1.7,0-3.33-.59-4.53-1.64s-1.87-2.47-1.87-3.96.67-2.91,1.87-3.96c.67-.59,1.48-1.03,2.36-1.31,1.28,1.4,3.12,2.27,5.16,2.27Z" />
							<circle fill={highlightColor} cx="17.4" cy="5" r="5" />
						</g>
					</svg>), [highlightColor]);
  React.useEffect(() => {
    const highlightColorObj = activeChannelService.activeChannel?.groupInfo?.highlightColor;
    const highlightRgb = highlightColorObj ? rgb(highlightColorObj.red, highlightColorObj.green, highlightColorObj.blue) : undefined;
    setHighlightColor(highlightRgb);
  }, [activeChannelService.activeChannel]);
  if (!miniChatSettingsService.isMiniChatAvailable || miniChatService.conversations.length === 0) {
    return null;
  }
  return <Flex {...createAccessiblePointerEventHandler({
    pointerCallback: () => miniChatService.openConversation(undefined, 'MiniChatTrigger')
  })} size={48} placeItems={'center'} zIndex={Z_INDEX.HIGHEST_CONTENT} style={({
    '--mini-chat-highlight-color': highlightColor,
    '--mini-chat-trigger-background-color': isParentDark ? theme.colors.basic['white-solid-110'] : theme.colors.basic['white-solid-white']
  } as any)}>
				<Flex className={clsx(miniChatService.hasUnreadMessages && styles.MiniChatTrigger)} bg={miniChatService.hasUnreadMessages ? isParentDark ? 'white-solid-110' : 'white-solid-white' : isParentDark ? 'white-transparent-160' : 'black-transparent-110'} size={40} borderRadius={'circle'} placeItems={'center'}
    // @ts-expect-error Not exposed
    style={miniChatService.hasUnreadMessages ? undefined : blurBackground(BACKGROUD_BLUR_RADIUS)}>
					{miniChatService.hasUnreadMessages ? <IconTriggerActive size="large" active={false} /> : <IconTriggerInactive size="large" color={'actionIconDefault'} active={false} />}
				</Flex>
			</Flex>;
});
const _c0 = " Knu-Flex cursor-pointer size-48px placeItems-center ";
const _c1 = " Knu-Flex size-40px borderRadius-circle placeItems-center ";