import { Flex, TITLE_BAR_HEIGHT, ThemeColors, Z_INDEX, isTouchDevice, useEvent, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import * as React from 'react';
import { useDrag } from '@use-gesture/react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import styles from './TransitionView.module.scss';
import { getSafeArea } from '../SafeArea';
import { OS, os } from '@shared/components/tools/os';
import { FlingGestureHandler } from '@shared/components/helper/FlingGestureHandler';
export const FlingDownContext = React.createContext<(velocity: number) => void>(() => {});
export const useFlingDownListener = () => {
  return React.useContext(FlingDownContext);
};
export interface TransitionViewProps {
  zIndex?: number;
  bg?: ThemeColors;
  transition: 'slideUp' | 'fade';
  hide?: boolean;
  leave?: {
    onFinished?(): void;
  };
  onSwipeDown: () => void;
  allowSwipe: boolean;
  isSystemApp?: boolean;
}
export const TransitionView: React.FC<TransitionViewProps> = props => {
  // outer view is needed to prevent scrollbar from appearing when moving the animated view out
  const AnimatedView = props.transition === 'fade' ? OpacityView : SlidingView;
  return <div style={{
    background: resolveThemingValue(props.bg, "colors", useTheme()),
    zIndex: resolveThemingValue(props.zIndex, "theme", useTheme())
  }} className={_c0 + (props.bg ? resolveIsDarkColor(props.bg, useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<AnimatedView {...props} />
		</div>;
};
const noElementIsScrolled = (domRef: HTMLElement, t: HTMLElement) => {
  if (t === domRef) {
    return true;
  }
  if (t.scrollTop > 0) {
    return false;
  }
  if (t.parentElement) {
    return noElementIsScrolled(domRef, t.parentElement);
  }
  return true;
};
const borderRadiusInPercentage = `${24 / window.innerWidth * 100}% ${24 / (window.innerHeight - 5 - getSafeArea().navBarHeight) * 100}%`;
const SlidingView: React.FC<TransitionViewProps> = props => {
  const domRef = React.useRef<HTMLDivElement | null>(null);
  const dragActiveRef = React.useRef(false);
  const hide = props.hide;
  const isFirstRender = React.useRef(true);
  const onSwipeDown = useEvent(() => {
    props.onSwipeDown();
  });
  React.useEffect(() => {
    requestAnimationFrame(() => {
      if (domRef.current) {
        domRef.current.style.transform = 'translateZ(0)';
      }
    });
  }, []);
  const animateOut = (domElement: HTMLElement, currentTranslateY: number, velocity: number) => {
    const animation = domElement.animate({
      transform: `translate3d(0,100%,0)`
    }, {
      duration: Math.min((domElement.offsetHeight - currentTranslateY) / Math.abs(velocity), 300),
      fill: 'forwards',
      easing: 'ease-out'
    });
    animation.addEventListener('finish', () => {
      onSwipeDown();
      props.leave?.onFinished?.();
    }, {
      once: true
    });
  };
  React.useEffect(() => {
    const handler = new FlingGestureHandler(domRef.current!, {
      onFling(details) {
        animateOut(domRef.current!, 0, details.velocity);
      },
      shouldStart(target) {
        return noElementIsScrolled(domRef.current!, target);
      },
      shouldTrigger(details) {
        return details.direction === 'down';
      }
    });
    handler.attach();
    return () => {
      handler.detach();
    };
  }, []);
  const flingDownCallback = React.useCallback(velocity => {
    animateOut(domRef.current!, 0, velocity);
  }, []);
  React.useEffect(() => {
    if (hide && domRef.current) {
      const animation = domRef.current.animate({
        transform: `translateY(100%)`
      }, {
        duration: Math.min(domRef.current.offsetHeight, 300),
        fill: 'forwards',
        easing: 'cubic-bezier(0.3, 0, 0.8, 0.15)'
      });
      animation.addEventListener('finish', () => {
        props.leave?.onFinished?.();
      }, {
        once: true
      });
    } else if (!hide && domRef.current && !isFirstRender.current) {
      domRef.current.animate({
        transform: `translateY(0%)`
      }, {
        duration: Math.min(domRef.current.offsetHeight, 300),
        fill: 'forwards',
        easing: 'cubic-bezier(0.3, 0, 0.8, 0.15)'
      });
    }
    isFirstRender.current = false;
  }, [hide]);
  const bind = useDrag(({
    xy,
    velocity,
    movement: [, my],
    direction,
    dragging,
    first,
    target
  }) => {
    const domElement = domRef.current;
    if (!domElement) {
      return;
    }
    const translateY = Math.max(0, my);
    if (first) {
      dragActiveRef.current = xy[1] - getSafeArea().safeAreaTopHeight < TITLE_BAR_HEIGHT || (target as HTMLDivElement)?.className?.includes?.('transition-view-target');
      domElement.style.transition = 'none';
    }
    if (!dragActiveRef.current) {
      return;
    }
    domElement.style.transform = `translate3d(0,${translateY}px,0)`;
    if (!dragging) {
      if (velocity[1] > 0.5 && direction[1] === 1) {
        animateOut(domElement, translateY, velocity[1]);
      } else {
        domElement.style.transition = '';
        domElement.style.transform = '';
      }
    }
  }, {
    axis: 'y',
    enabled: props.allowSwipe && isTouchDevice()
  });
  return <div ref={domRef} {...props.isSystemApp ? {} : bind()} className={clsx(styles['Knu-SlideUpView'], os === OS.android ? styles['android'] : '')} style={{
    transform: 'translate3d(0,100%,0)',
    touchAction: props.isSystemApp ? undefined : 'none',
    borderTopLeftRadius: os === OS.android ? borderRadiusInPercentage : undefined,
    borderTopRightRadius: os === OS.android ? borderRadiusInPercentage : undefined
  }}>
			<FlingDownContext.Provider value={flingDownCallback}>
				{props.children}
			</FlingDownContext.Provider>
			{props.isSystemApp && <div {...bind()} style={{
      position: 'absolute',
      top: 0,
      left: 48,
      width: 150,
      height: TITLE_BAR_HEIGHT,
      touchAction: 'none',
      zIndex: Z_INDEX.ABOVE_MODAL
    }} />}
		</div>;
};
const OpacityView: React.FC<TransitionViewProps> = props => {
  const animationInitializedRef = React.useRef(false);
  const opacityVariant = {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1,
      transition: {
        ease: [1, 0, 0.8, 1]
      }
    }
  };
  React.useLayoutEffect(() => {
    if (!animationInitializedRef.current) {
      animationInitializedRef.current = true;
    }
  }, []);
  const initialVariant = animationInitializedRef.current ? 'visible' : 'hidden';
  const animateVariant = props.hide ? 'hidden' : 'visible';
  return <motion.div style={{
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }} variants={opacityVariant} initial={initialVariant} animate={animateVariant} onAnimationComplete={() => {
    if (props.hide && props.leave?.onFinished) {
      props.leave.onFinished();
    }
  }}>
			{props.children}
		</motion.div>;
};
const _c0 = " Knu-Flex position-absolute top-none left-none right-none bottom-none ";