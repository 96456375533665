import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $NotificationBarService } from '../../../Notifications';
import { $ViewService } from '@knuddels-app/layout';
import { engagementAppViewId } from '../components/EngagementSystem/viewId';
import { $CommandService } from '@knuddels-app/Commands';
import { channelListViewId } from '../../../ChannelList';
import { messengerViewId } from '../../../Messenger';
import { settingsViewId } from '../../../Settings';
import { Disposable } from '@knuddels/std';

const REGISTER_COMMAND = '/engagementsystem';
const STREAKS_COMMAND = '/engagement';

@injectable()
export class EngagementAppView {
	public readonly dispose = Disposable.fn();

	constructor(
		@inject($NotificationBarService)
		private readonly notificationBarService: typeof $NotificationBarService.T,
		@inject($ViewService)
		private readonly viewService: typeof $ViewService.T,
		@inject($CommandService)
		private readonly commandService: typeof $CommandService.T
	) {
		['engagementsystem', 'quest'].forEach(commandName =>
			this.dispose.track(
				commandService.registerCommand({
					commandName,
					invoke: async (): Promise<void> => {
						this.viewService.openViewAsOverlay(
							engagementAppViewId()
						);
					},
					shouldInvoke(parameter: string): boolean {
						return parameter.length === 0;
					},
				})
			)
		);

		this.dispose.track(
			commandService.registerCommand({
				commandName: 'channelselect',
				invoke: async (): Promise<void> => {
					this.viewService.openViewAsOverlayWithContext(
						channelListViewId,
						'engagement-app'
					);
				},
				shouldInvoke(parameter: string): boolean {
					return parameter.length === 0;
				},
			})
		);

		this.dispose.track(
			commandService.registerCommand({
				commandName: 'bonus',
				invoke: async (): Promise<void> => {
					viewService.openViewAsOverlay(
						engagementAppViewId().with(state =>
							state.withConfig(
								{
									path: 'home',
									params: {
										activeTab: 'bonus',
									},
								},
								{
									mayReplaceTopOfStack: true,
									isDeepLinkRoot: true,
								}
							)
						)
					);
				},
			})
		);

		this.dispose.track(
			commandService.registerCommand({
				commandName: 'messengeroverview',
				invoke: async (): Promise<void> => {
					this.viewService.openViewAsOverlayWithContext(
						messengerViewId,
						'engagement-app'
					);
				},
				shouldInvoke(parameter: string): boolean {
					return parameter.length === 0;
				},
			})
		);

		this.dispose.track(
			commandService.registerCommand({
				commandName: 'settingsview',
				invoke: async (): Promise<void> => {
					this.viewService.openViewAsOverlayWithContext(
						settingsViewId,
						'EngagementAppView'
					);
				},
				shouldInvoke(parameter: string): boolean {
					return parameter.length === 0;
				},
			})
		);

		this.dispose.track(
			commandService.registerCommand({
				commandName: 'privacysettingsview',
				invoke: async (): Promise<void> => {
					this.viewService.openViewAsOverlayWithContext(
						settingsViewId.with(s =>
							s.withPath('ContactFilterSettings')
						),
						'engagement-app'
					);
				},
				shouldInvoke(parameter: string): boolean {
					return parameter.length === 0;
				},
			})
		);

		this.dispose.track(
			notificationBarService.registerSlashCommandHandler(
				REGISTER_COMMAND,
				command => {
					viewService.openViewAsOverlay(
						engagementAppViewId().with(state =>
							state.withStack([
								{
									key: 'home',
									path: 'home',
									params: {
										activeTab: 'tasks',
										taskId: command,
									},
								},
								{
									key: 'taskDetail',
									path: 'taskDetail',
									params: {
										taskId: command,
									},
								},
							])
						)
					);
				}
			)
		);

		this.dispose.track(
			notificationBarService.registerSlashCommandHandler(
				STREAKS_COMMAND,
				() => {
					viewService.openViewAsOverlay(
						engagementAppViewId().with(state =>
							state.withConfig(
								{
									path: 'home',
									params: {
										activeTab: 'streaks',
									},
								},
								{
									mayReplaceTopOfStack: true,
									isDeepLinkRoot: true,
								}
							)
						)
					);
				}
			)
		);
	}
}
