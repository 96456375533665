import * as React from 'react';
import { ReactNode } from 'react';
import { Snackbar, SnackbarProps } from './Snackbar';
import { equalUpToDepth } from '../../../tools';
import { FlexCol, Modal, useTheme, FallbackBox, resolveThemingValue } from '@knuddels/component-library';
export type SnackbarPropsWithType = SnackbarProps & {
  type: string;
};
export interface SnackbarContainerProps extends SnackbarDisplayProps {
  snackbar?: SnackbarPropsWithType;
}
export interface SnackbarDisplayProps {
  isStackedLayout?: boolean;
  children?: ReactNode;
  bottomSpacing?: number;
}
export class SnackbarContainer extends React.Component<SnackbarContainerProps> {
  shouldComponentUpdate(nextProps: Readonly<{
    children?: ReactNode;
  }> & Readonly<SnackbarContainerProps>): boolean {
    if (this.props.isStackedLayout !== nextProps.isStackedLayout || this.props.children !== nextProps.children) {
      return true;
    }
    const snackbar = this.props.snackbar;
    const nextSnackbar = nextProps.snackbar;
    if (!snackbar) {
      return !!nextSnackbar;
    }
    if (!nextSnackbar) {
      return !!snackbar;
    }
    return !equalUpToDepth(snackbar, nextSnackbar, undefined, 2);
  }
  render(): React.ReactNode {
    const snackbar = this.props.snackbar;
    const bottomSpacing = this.props.bottomSpacing || 0;
    const isStackedLayout = this.props.isStackedLayout || false;
    return <SnackbarDisplayer snackbar={snackbar} bottomSpacing={bottomSpacing} isStackedLayout={isStackedLayout}>
				{this.props.children}
			</SnackbarDisplayer>;
  }
}
const SnackbarDisplayer: React.FC<{
  snackbar: SnackbarPropsWithType | undefined;
  bottomSpacing: number;
  isStackedLayout: boolean;
}> = ({
  snackbar,
  bottomSpacing,
  isStackedLayout,
  children
}) => {
  const [containerWidth, setContainerWidth] = React.useState(0);
  const [barWidth, setBarWidth] = React.useState(0);
  const theme = useTheme();
  const mx = containerWidth > barWidth && barWidth > 0 && !isStackedLayout ? (containerWidth - barWidth) / 2 : theme.spacings.base;
  return <FallbackBox onLayout={e => setContainerWidth(e.width)} className={_c0}>
			{children}
			{snackbar && <Modal zIndex={300} pointerEvents={'none'} backgroundStyle={'transparent'}>
					<div style={{
        marginLeft: resolveThemingValue((`${mx}px` as any), "spacing", useTheme()),
        marginRight: resolveThemingValue((`${mx}px` as any), "spacing", useTheme()),
        marginBottom: resolveThemingValue((`${bottomSpacing}px` as any), "spacing", useTheme())
      }} className={_c1 + (!isStackedLayout ? _c2 : _c3)}>
						<FallbackBox onLayout={e => setBarWidth(e.width)} style={{
          minWidth: resolveThemingValue(!isStackedLayout ? '340px' : undefined, "sizes", useTheme()),
          maxWidth: resolveThemingValue(!isStackedLayout ? '580px' : undefined, "sizes", useTheme())
        }} className={_c4}>
							<Snackbar key={snackbar.type} {...snackbar} />
						</FallbackBox>
					</div>
				</Modal>}
		</FallbackBox>;
};
const _c0 = " Knu-FlexCol position-absolute left-none top-none right-none bottom-none ";
const _c1 = " Knu-FlexCol position-absolute left-none right-none bottom-none ";
const _c2 = " alignItems-center ";
const _c3 = " alignItems-stretch ";
const _c4 = " Knu-FlexCol pointerEvents-all ";