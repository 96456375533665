import { jsxs as x, jsx as l } from "react/jsx-runtime";
import h from "react";
import "./createSvgIcon.scss.js";
import { clsx as g } from "../../node_modules/clsx/dist/clsx.m.js";
import { useColor as k } from "../Layout/hooks/useColor.js";
import { Tooltip as y } from "../tooltip/Tooltip.js";
import { toPointerHandler as n } from "../Layout/CommonTypes/toPointerHandler.js";
import { Ripple as S } from "../Layout/Box/Ripple.js";
import { resolveThemeColor as T } from "../Layout/isDarkColor.js";
import { useTheme as j } from "../../themes/useTheme.js";
const z = (r) => h.memo(
  ({
    size: t = "base",
    type: o = "default",
    disabled: e,
    active: R,
    onPress: s,
    onPointerDown: m,
    label: i,
    onPointerUp: a,
    ripple: C = !0,
    ...f
  }) => {
    const c = h.useRef(null), b = k(f, c), { innerRef: d } = f, u = !!s || !!m || !!a, p = /* @__PURE__ */ x(
      "div",
      {
        ref: (v) => {
          c.current = v, d && (d.current = v);
        },
        "aria-label": i,
        style: I(b, j()),
        onClick: e ? void 0 : n(s),
        onPointerDown: n(m),
        onPointerUp: n(a),
        className: g(
          "Knu-Svg",
          t,
          o,
          u && "clickable",
          e && "disabled",
          R && "active"
        ),
        children: [
          u && C && /* @__PURE__ */ l(
            "div",
            {
              className: "Knu-FlexCol RippleWrapper overflow-hidden position-absolute alignItems-center borderRadius-circle",
              children: /* @__PURE__ */ l(S, { containerRef: c })
            }
          ),
          r
        ]
      }
    );
    return i ? /* @__PURE__ */ l(y, { content: i, children: p }) : p;
  }
);
function I(r, t) {
  let o = {};
  if (r) {
    const e = T(r, t);
    o["--icons-colors-default"] = e, o["--icons-colors-hover"] = e, o["--icons-colors-active"] = e, o["--icons-colors-static"] = e, o["--icons-colors-disabled"] = e;
  }
  return o;
}
export {
  z as createSvgIcon
};
