import { Position, ViewId, ViewProvider, ViewRegistry } from '@knuddels-app/layout';
import * as React from 'react';
import { injectable } from '@knuddels-app/DependencyInjection';
import { LoadService } from '@knuddels-app/ModuleSystem';
import * as providedServices from './providedServices';
import { allChannelsShownTimingTracker } from './analytics';
import { getRouterStateFromPersistedViewState, RouterViewState } from '@knuddels-app/layout/k3Router';
import { ChannelListRouteConfig } from './ChannelListRouteConfig';
@injectable()
export class ChannelListViewProvider implements ViewProvider {
  registerViews(registry: ViewRegistry): void {
    registry.registerView({
      viewId: channelListViewId,
      position: Position.Side,
      loadState: ChannelListViewState.fromPersistedViewState,
      render: () => {
        allChannelsShownTimingTracker.start();
        return {
          mainView: <LoadService service={providedServices.$ChannelListService}>
							{s => <s.MainView />}
						</LoadService>
        };
      }
    });
  }
}
export class ChannelListViewState extends RouterViewState<ChannelListRouteConfig> {
  public static fromPersistedViewState = getRouterStateFromPersistedViewState(ChannelListViewState);
  public asOverview(): ChannelListViewState {
    return this.root();
  }
}
export const channelListViewId = new ViewId<ChannelListViewState>('channelList');