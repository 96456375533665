import * as providedServices from './providedServiceIds';
import { $ClientSettingsService } from './providedServiceIds';
import { Module } from '@knuddels-app/ModuleSystem/Module';
import { $ViewProvider } from '@knuddels-app/layout/extensionPoints';
import {
	settingsViewId,
	SettingsViewProviderImpl,
} from './SettingsViewProviderImpl';
import { $NavBarItemProvider } from '@knuddelsModules/LoggedInArea';
import {
	$AppLoadingProvider,
	simpleAppLoadingProvider,
} from '@knuddels-app/AppLoadingService';
import { mentorSystemModule } from '../MentorSystem/module';

export const settingsModule = new Module({
	name: 'Settings',
	dependencies: [mentorSystemModule],
	providedServices,
	load: {
		from: () =>
			import(/* webpackChunkName: "app" */ './bundle/registerServices'),
	},
	loadStatic: ctx => {
		ctx.globalScope
			.bind($ViewProvider)
			.toSingleton(SettingsViewProviderImpl);

		ctx.loggedInScope
			.bind($NavBarItemProvider)
			.toDynamicSingleton({}, () => ({
				getItems: () => [
					{
						id: 'settings',
						kind: 'view',
						view: settingsViewId,
						trackingId: 'Settings',
					},
				],
			}));

		ctx.globalScope
			.bind($AppLoadingProvider)
			.toConstant(
				simpleAppLoadingProvider(
					$ClientSettingsService,
					service => service.deferredReady.promise
				)
			);
	},
});
