const r = (a) => `https://cdnc.knuddelscom.de/static/images/loyalty-system/${a}-icon.svg`, n = () => ({
  levelLossWarning: "red-600",
  pointLossWarning: "orange-500"
}), o = (a = "de") => ({
  0: {
    name: "Kieselstein",
    primaryColor: "rgba(104, 104, 104, 1)",
    secondaryColor: "rgba(162, 162, 162, 1)",
    tertiaryColor: "rgba(214, 214, 214, 1)",
    gradient: "linear-gradient(180deg, #9F9E9E 43.14%, #878585 100%)",
    iconUrl: r("kieselstein")
  },
  1: {
    name: "Bernstein",
    primaryColor: "rgba(240, 164, 0, 1)",
    secondaryColor: "rgba(255, 182, 35, 1)",
    tertiaryColor: "rgba(255, 233, 189, 1)",
    gradient: "linear-gradient(180deg, #FFC823 45.5%, #D19600 100%, #D18400 100%)",
    iconUrl: r("bernstein")
  },
  2: {
    name: "Rosenquarz",
    primaryColor: "rgba(244, 103, 154, 1)",
    secondaryColor: "rgba(255, 184, 219, 1)",
    tertiaryColor: "rgba(255, 235, 248, 1)",
    gradient: "linear-gradient(180deg, #FFB8DB 43%, #FF7598 100%)",
    iconUrl: r("rosenquarz")
  },
  3: {
    name: "Amethyst",
    primaryColor: "rgba(146, 40, 172, 1)",
    secondaryColor: "rgba(215, 108, 238, 1)",
    tertiaryColor: "rgba(232, 200, 249, 1)",
    gradient: "linear-gradient(180deg, #EE6CEA 51.5%, #CB2EEB 100%)",
    iconUrl: r("amethyst")
  },
  4: {
    name: "Topas",
    primaryColor: "rgba(235, 203, 0, 1)",
    secondaryColor: "rgba(251, 230, 113, 1)",
    tertiaryColor: "rgba(252, 238, 161, 1)",
    gradient: "linear-gradient(180deg, #FFF1A5 45.5%, #F8BE11 100%)",
    iconUrl: r("topas")
  },
  5: {
    name: "Smaragd",
    primaryColor: "rgba(38, 166, 140, 1)",
    secondaryColor: "rgba(109, 210, 191, 1)",
    tertiaryColor: "rgba(189, 235, 230, 1)",
    gradient: "linear-gradient(180deg, #6DD2BF 45%, #36B5B5 100%)",
    iconUrl: r("smaragd")
  },
  6: {
    name: "Saphir",
    primaryColor: "rgba(29, 122, 230, 1)",
    secondaryColor: "rgba(162, 205, 255, 1)",
    tertiaryColor: "rgba(214, 233, 255, 1)",
    gradient: "linear-gradient(180deg, #A2CDFF 44.5%, #4D91FF 100%)",
    iconUrl: r("saphir")
  },
  7: {
    name: "Rubin",
    primaryColor: "rgba(213, 0, 0, 1)",
    secondaryColor: "rgba(240, 63, 58, 1)",
    tertiaryColor: "rgba(247, 153, 151, 1)",
    gradient: "linear-gradient(180deg, #F3625E 43.5%, #E40C0C 100%)",
    iconUrl: r("rubin")
  },
  8: {
    name: "Diamant",
    primaryColor: "rgba(63, 188, 195, 1)",
    secondaryColor: "rgba(156, 238, 238, 1)",
    tertiaryColor: "rgba(198, 245, 245, 1)",
    gradient: "linear-gradient(180deg, #9CEEEE 41.36%, #22C0C9 100%)",
    iconUrl: r("diamant")
  },
  9: {
    name: "Taaffeit",
    primaryColor: "rgba(191, 123, 215, 1)",
    secondaryColor: "rgba(217, 156, 238, 1)",
    tertiaryColor: "rgba(237, 198, 245, 1)",
    gradient: "linear-gradient(180deg, #EDC6F5 41.36%, #BF7BD7 100%)",
    iconUrl: r("taaffeit")
  }
}), i = (a, e = "de") => o(e)[a];
export {
  i as getConfigForLevel,
  n as getGlobalColors,
  o as getLevelConfig,
  r as getPath
};
