import { inject, injectable } from '@knuddels-app/DependencyInjection';
import { $CommandService } from '@knuddels-app/Commands';
import { $ViewService } from '@knuddels-app/layout';
import { Disposable } from '@knuddels/std';
import { contactsViewId } from '../../ContactsViewProvider';

@injectable()
export class ContactsCommandService {
	public readonly dispose = Disposable.fn();

	constructor(
		@inject($CommandService)
		commandService: typeof $CommandService.T,
		@inject($ViewService)
		viewService: typeof $ViewService.T
	) {
		for (const cmd of ['f', 'friends']) {
			this.dispose.track(
				commandService.registerCommand({
					commandName: cmd,
					async invoke(): Promise<void> {
						viewService.openViewAsOverlay(
							// open default view (to close other subviews)
							contactsViewId.with(state => state.default())
						);
					},
					shouldInvoke(parameter: string): boolean {
						return !parameter.trim();
					},
				})
			);
		}

		this.dispose.track(
			commandService.registerCommand({
				commandName: 'mentor',
				invoke: async (): Promise<void> => {
					viewService.openViewAsOverlay(
						contactsViewId.with(oldState =>
							oldState.withContactsTab('mentees')
						)
					);
				},
				shouldInvoke(parameter: string): boolean {
					return !parameter.trim();
				},
			})
		);
	}
}
