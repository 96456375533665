import { measureLayoutRelativeToWindow } from '@knuddels-app/tools/measureLayoutRelativeToWindow';
import * as React from 'react';
import { Box, IconMoreVertical, PointerEvent, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { getSafeArea } from '@knuddels-app/SafeArea';
interface OwnProps {
  isMenuActive?: boolean;
  testId?: string;
  bg?: React.ComponentProps<typeof Box>['bg'];
  showOverflowMenu(x: number, y: number, width: number, height: number): void;
}
type Props = OwnProps;
export const OverflowMenuTrigger: React.FC<Props> = props => {
  const {
    ref,
    onPress
  } = useOverflowMenuTrigger(props.showOverflowMenu);
  return <div ref={ref} style={{
    background: resolveThemingValue(props.bg, "colors", useTheme())
  }} className={_c0 + (props.bg ? resolveIsDarkColor(props.bg, useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
			<IconMoreVertical size={'large'} label={'Weitere Optionen'} active={props.isMenuActive} onPress={onPress} />
		</div>;
};
export const useOverflowMenuTrigger = (showOverflowMenu: Props['showOverflowMenu']) => {
  const ref = React.useRef<React.Component>(null);
  const onPress = async (e: PointerEvent): Promise<void> => {
    e.stopPropagation();
    if (!ref.current) {
      return;
    }
    try {
      const {
        x,
        y,
        width,
        height
      } = await measureLayoutRelativeToWindow(ref.current);
      showOverflowMenu(x, y - getSafeArea().safeAreaTopHeight, width, height);
    } catch (err) {
      console.warn(err);
    }
  };
  return {
    onPress,
    ref
  };
};
const _c0 = " Knu-Box ";