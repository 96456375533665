import * as React from 'react';
import { declareFormat } from '@knuddels/i18n';
import { $I18n } from '@knuddels-app/i18n';
import { useService } from '@knuddels-app/DependencyInjection';
import { FlexCol, LineDivider, ListGroup, RadioButton, RadioGroup, SwitchListItem, Text, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { useMutation } from '@knuddels-app/Connection';
import { ChangeAdultChannelsVisibleInHardcoreChannels, ChangeDateOfBirthPrivacySettings, ChangeFriendsPrivacySettings, ChangeKnuddelPrivacySettings, ChangeNicknameHistoryChecked, NicknameHistorySettings, PrivacySettings, PrivacyType } from '@generated/graphql';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { PrivacySettingsProps } from './privacySettings.props';
import { useFailedSaveSnackbar } from './usePrivacySettingState';
import { getDaysFromNow } from '@shared/components';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
export const ProfilePrivacySettings: React.FC<PrivacySettingsProps> = ({
  data,
  updateData,
  nickSwitchEnabled
}) => {
  const track = useLogEvent();
  const i18n = useService($I18n);
  const showErrorSnackbar = useFailedSaveSnackbar();
  const genericUserEventService = useService($GenericUserEventService);
  const [changeFriendsPrivacySettings] = useMutation(ChangeFriendsPrivacySettings);
  const [changeKnuddelPrivacySettings] = useMutation(ChangeKnuddelPrivacySettings);
  const [changeDateOfBirthPrivacySettings] = useMutation(ChangeDateOfBirthPrivacySettings);
  const [changeAdultChannelsVisibleInHardcoreChannels] = useMutation(ChangeAdultChannelsVisibleInHardcoreChannels);
  const {
    friendsPrivacyType,
    knuddelPrivacyType,
    dateOfBirthPrivacyType,
    nicknameHistory: history,
    adultChannelsVisibleInHardcoreChannels
  } = data;
  const onNicknameHistoryChange = useOnNicknameHistoryChange(history, updateData);
  return <ListGroup title={i18n.format(declareFormat({
    id: 'settings.privacy.profile.title',
    defaultFormat: 'Privacy in profile'
  }))}>
			<div className={_c0 + ("transparent" ? resolveIsDarkColor("transparent", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
				<Text type={'subtitle'} bold={true} className={_c1}>
					{i18n.format(declareFormat({
          id: 'settings.privacy.friends.sub-title',
          defaultFormat: 'Who may see your friends?'
        }))}
				</Text>
				<div className={_c2}>
					<RadioGroup value={friendsPrivacyType} onChange={({
          value
        }) => {
          if (value === friendsPrivacyType) {
            // if value didn't change, do nothing.
            return;
          }
          genericUserEventService.reportEvent({
            type: 'Settings_Changed',
            field: 'PrivacyProfileSeeFriends'
          });
          track('Settings_Privacy', privacyTypeTrackingLabelMap[(value as PrivacyType)]);
          const {
            revertUpdate
          } = updateData({
            friendsPrivacyType: value
          });
          changeFriendsPrivacySettings({
            privacyType: value
          }).then(result => {
            if (result.data.__typename !== 'Success') {
              revertUpdate();
              showErrorSnackbar();
            }
          });
        }}>
						<RadioButtons />
					</RadioGroup>
				</div>
				<LineDivider className={_c3} />
				<Text type={'subtitle'} bold={true} className={_c4}>
					{i18n.format(declareFormat({
          id: 'settings.privacy.knuddel.sub-title',
          defaultFormat: 'Who may see your Knuddel?'
        }))}
				</Text>
				<div className={_c5}>
					<RadioGroup value={knuddelPrivacyType} onChange={({
          value
        }) => {
          if (value === knuddelPrivacyType) {
            // if value didn't change, do nothing.
            return;
          }
          genericUserEventService.reportEvent({
            type: 'Settings_Changed',
            field: 'PrivacyProfileSeeKnuddel'
          });
          track('Settings_Privacy', privacyTypeTrackingLabelMap[(value as PrivacyType)]);
          const {
            revertUpdate
          } = updateData({
            knuddelPrivacyType: value
          });
          changeKnuddelPrivacySettings({
            privacyType: value
          }).then(result => {
            if (result.data.__typename !== 'Success') {
              revertUpdate();
              showErrorSnackbar();
            }
          });
        }}>
						<RadioButtons />
					</RadioGroup>
				</div>
				<LineDivider className={_c6} />
				<Text type={'subtitle'} bold={true} className={_c7}>
					{i18n.format(declareFormat({
          id: 'settings.privacy.dateOfBirth.sub-title',
          defaultFormat: 'Who may see your date of birth?'
        }))}
				</Text>
				<div style={{
        paddingBottom: resolveThemingValue(nickSwitchEnabled ? 'small' : undefined, "spacing", useTheme())
      }} className={_c8}>
					<RadioGroup value={dateOfBirthPrivacyType} onChange={({
          value
        }) => {
          if (value === dateOfBirthPrivacyType) {
            // if value didn't change, do nothing.
            return;
          }
          genericUserEventService.reportEvent({
            type: 'Settings_Changed',
            field: 'PrivacyProfileSeeDateOfBirth'
          });
          track('Settings_Privacy', privacyTypeTrackingLabelMap[(value as PrivacyType)]);
          const {
            revertUpdate
          } = updateData({
            dateOfBirthPrivacyType: value
          });
          changeDateOfBirthPrivacySettings({
            privacyType: value
          }).then(result => {
            if (result.data.__typename !== 'Success') {
              revertUpdate();
              showErrorSnackbar();
            }
          });
        }}>
						<RadioButtons />
					</RadioGroup>
				</div>

				{nickSwitchEnabled && <SwitchListItem caption={i18n.format(declareFormat({
        id: 'settings.privacy.changeNickname.showNicknameHistory.caption',
        defaultFormat: 'Show nickname history in profile'
      }))} additionalInfo={!history.toggleable ? i18n.format(declareFormat({
        id: 'settings.privacy.changeNickname.showNicknameHistory.additionalInfo',
        defaultFormat: 'You can deactivate the option in {days, plural, one {# day} other {# days}}'
      }), {
        days: getDaysFromNow(history.disabledUntil)
      }) : undefined} checked={history.checked} onChange={onNicknameHistoryChange} disabled={!history.toggleable} separator={'none'} />}

				<SwitchListItem caption={i18n.format(declareFormat({
        id: 'settings.privacy.adultChannelsInHardcoreChannels.caption',
        defaultFormat: 'Show erotic channels in core channels'
      }))} checked={adultChannelsVisibleInHardcoreChannels} onChange={value => {
        if (value === adultChannelsVisibleInHardcoreChannels) {
          // if value didn't change, do nothing.
          return;
        }
        genericUserEventService.reportEvent({
          type: 'Settings_Changed',
          field: 'PrivacyProfileShowAdultChannelsInHardcoreChannels'
        });
        track('Settings_Privacy', 'AdultChannelsVisibleInHardcoreChannels');
        const {
          revertUpdate
        } = updateData({
          adultChannelsVisibleInHardcoreChannels: value
        });
        changeAdultChannelsVisibleInHardcoreChannels({
          visible: value
        }).then(result => {
          if (result.data.__typename !== 'Success') {
            revertUpdate();
            showErrorSnackbar();
          }
        });
      }} separator={'none'} />
			</div>
		</ListGroup>;
};
const privacyTypeTrackingLabelMap: { [key in PrivacyType]: string | undefined } = {
  [PrivacyType.Public]: 'FriendsVisibility_AllClicked',
  [PrivacyType.FriendsOnly]: 'FriendsVisibility_JustFriendsClicked',
  [PrivacyType.Hidden]: 'FriendsVisibility_NoOneClicked',
  // is not an available option so should not be tracked, but just added it to reduce code complexity
  [PrivacyType.BestFriendsOnly]: 'FriendsVisibility_JustBestFriendsClicked'
};
const useOnNicknameHistoryChange = (history: NicknameHistorySettings, updateData: (newData: Partial<PrivacySettings>) => {
  revertUpdate: () => void;
}) => {
  const track = useLogEvent();
  const showErrorSnackbar = useFailedSaveSnackbar();
  const [changeNicknameHistoryChecked] = useMutation(ChangeNicknameHistoryChecked);
  const onNicknameHistoryChange = (value: boolean) => {
    if (value === history.checked) {
      // if value didn't change, do nothing.
      return;
    }
    track('Settings_Privacy', `NicknameHistoryChange_${value ? 'Activate' : 'Deactivate'}`);
    const {
      revertUpdate
    } = updateData({
      nicknameHistory: {
        ...history,
        checked: value
      }
    });
    changeNicknameHistoryChecked({
      checked: value
    }).then(result => {
      if (result.data.__typename !== 'Success') {
        revertUpdate();
        showErrorSnackbar();
      }
    });
  };
  return onNicknameHistoryChange;
};
export const RadioButtons: React.FC<{}> = () => {
  const i18n = useService($I18n);
  return <>
			<RadioButton value={PrivacyType.Public} label={i18n.format(declareFormat({
      id: 'settings.privacy.option.public',
      defaultFormat: 'all (public)'
    }))} />
			<RadioButton value={PrivacyType.FriendsOnly} label={i18n.format(declareFormat({
      id: 'settings.privacy.option.only-friends',
      defaultFormat: 'only friends'
    }))} />
			<RadioButton value={PrivacyType.Hidden} label={i18n.format(declareFormat({
      id: 'settings.privacy.option.hidden',
      defaultFormat: 'nobody'
    }))} />
		</>;
};
const _c0 = " Knu-FlexCol bg-transparent ";
const _c1 = " mx-base my-minor ";
const _c2 = " Knu-FlexCol pl-minor pb-small ";
const _c3 = " mx-base ";
const _c4 = " mx-base my-minor ";
const _c5 = " Knu-FlexCol pl-minor pb-small ";
const _c6 = " mx-base ";
const _c7 = " mx-base my-minor ";
const _c8 = " Knu-FlexCol pl-minor ";