import { injectedComponent, useService } from '@knuddels-app/DependencyInjection';
import * as React from 'react';
import { SplashScreen } from '@capacitor/splash-screen';
import { $AuthService, $CurrentEndpointStore, KnownEndpoints } from '@knuddels-app/Connection';
import { Flex, FlexCol, Text, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { Device } from '@capacitor/device';
import { $FirebaseAnalyticsService, $K3Firebase } from '@knuddels-app/analytics/firebase';
import { $OverlayService } from '@knuddels-app/overlays';
import { ServerSwitchModal } from './ServerSwitch/ServerSwitchModal';
import { OverlayPresenter } from '@knuddels-app/overlays/OverlayPresenter';
import { isNative } from '@knuddels-app/tools/isNative';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { $Environment } from '@knuddels-app/Environment';
import { WebviewResize } from '@knuddels/webview-resize-capacitor-plugin';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { $AllowedServerAccessStore, $LastLoginStorage } from '@knuddels-app/Connection/serviceIds';
import { dismissKeyboard } from '@shared/helper/dismissKeyboard';
import { $LocationService } from '@knuddels-app/location';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { Capacitor } from '@capacitor/core';
import { $SnackbarService } from '@knuddels-app/SnackbarManager';
import { getNamespaceIfWeb } from '@knuddels-app/local-storage/sessionCookieHelper';
import { $MixPanelService } from '@knuddels-app/analytics/generic/MixPanelService';
import { setupAppleAdsAttribution } from './appleAdsAttribution';
import { dangerouslyGetK3Container } from '@knuddels-app/ModuleSystem';
import { OS, os } from '@shared/components/tools/os';
import { getSafeArea } from '@knuddels-app/SafeArea';
import { setupPlayInstallReferrer } from './playInstallReferrer';
const LOGIN = '/landing-page/login/index.html';
const REG = '/landing-page/index.html';
const hasOnceLoggedInKey = 'hasOnceLoggedIn';
const getInitialPage = (lastLoginStorage: typeof $LastLoginStorage.T) => {
  if (import.meta.env.MODE === 'development') {
    return LOGIN;
  }
  if (localStorage.getItem(hasOnceLoggedInKey) === 'true' || lastLoginStorage.getLastedUsedNick()) {
    return LOGIN;
  }
  return REG;
};
const setViewNotResizing = () => {
  if (!isNative()) {
    return;
  }
  if (window.innerHeight < 700) {
    return;
  }
  Keyboard.setResizeMode({
    mode: KeyboardResize.None
  });
  WebviewResize.disableWebviewResize();
};
const setViewResizing = () => {
  if (!isNative()) {
    return;
  }
  Keyboard.setResizeMode({
    mode: KeyboardResize.Native
  }).catch(() => {});
  WebviewResize.enableWebviewResize();
};
export const LoggedOutArea = injectedComponent({
  name: 'LoggedOutArea'
}, () => {
  const ref = React.useRef<HTMLIFrameElement>(null);
  const lastUrl = React.useRef<string | null>(null);
  const [isLoginView, setIsLoginView] = React.useState(false);
  const authService = useService($AuthService);
  const currentEndpointService = useService($CurrentEndpointStore);
  const analyticsService = useService($FirebaseAnalyticsService);
  const overlayService = useService($OverlayService);
  const snackbarService = useService($SnackbarService);
  const environment = useService($Environment);
  const lastLoginStorage = useService($LastLoginStorage);
  const allowedServers = useService($AllowedServerAccessStore);
  const locationService = useService($LocationService);
  const mixpanelService = useService($MixPanelService);
  const initialRender = React.useRef(true);
  const initialPageRef = React.useRef(getInitialPage(lastLoginStorage));
  React.useLayoutEffect(() => {
    setupAppleAdsAttribution();
    setupPlayInstallReferrer();
  }, []);
  React.useEffect(() => {
    locationService.replacePath('/');
  }, []);
  React.useEffect(() => {
    mixpanelService.reinitialize();
  }, []);
  const showServerIndicator = !globalEnv.isReleaseExperiment && !isNative() || import.meta.env.MODE === 'development' || allowedServers.speciallyAllowedServers.length >= 1;
  React.useLayoutEffect(() => {
    setViewResizing();
    ScreenOrientation.lock({
      orientation: 'portrait'
    }).catch(() => {});
  }, []);
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (!ref.current) {
        return;
      }
      const url = ref.current.contentWindow.location.href;
      if (url === lastUrl.current) {
        return;
      }
      lastUrl.current = url;
      if (url.includes('login')) {
        setViewNotResizing();
        setIsLoginView(true);
      } else {
        setViewResizing();
        setIsLoginView(false);
      }
    }, 10);
    return () => {
      clearInterval(interval);
    };
  }, []);
  React.useEffect(() => {
    setViewNotResizing();
    if (isNative()) {
      Keyboard.setAccessoryBarVisible({
        isVisible: false
      });
      const nick = lastLoginStorage.getLastedUsedNick();
      if (nick) {
        localStorage.setItem(hasOnceLoggedInKey, 'true');
      }
    }
    const disposable = environment.nativeInterface?.addBackHandler(() => {
      history.back();
      return true;
    });
    const handler = (e: PopStateEvent) => {
      if (e.state.stepId) {
        e.stopImmediatePropagation();
        ref.current.contentWindow.postMessage({
          type: 'popstate',
          state: e.state
        }, '*');
      }
    };
    window.addEventListener('popstate', handler);
    return () => {
      window.removeEventListener('popstate', handler);
      disposable?.dispose();
    };
  }, []);
  React.useEffect(() => {
    Device.getInfo().then(info => {
      localStorage.setItem('platform', info.platform === 'ios' ? 'iphone' : info.platform);
    });
    Device.getId().then(id => {
      localStorage.setItem('uid', id.identifier);
    });
    const handler = async (event: MessageEvent<{
      type: 'history-push';
      stepId: string;
    } | {
      type: 'login';
      token: string;
      username: string | null;
      password: string | null;
    } | {
      type: 'login-local';
      nickname: string;
      pwd: string;
    } | {
      type: 'request-permission';
    } | {
      type: 'get-permission';
    } | {
      type: 'open-keyboard';
    } | {
      type: 'analytics';
      params: {
        event: string;
      } | {
        content_type: string;
        item_id: string;
      };
    } | {
      type: 'mixpanel';
      params: {
        event: string;
        properties: Record<string, any>;
      };
    }>) => {
      if (event.data.type === 'get-permission') {
        dangerouslyGetK3Container().getService($K3Firebase).messaging.then(messaging => messaging.getPermission()).then(result => {
          ref.current.contentWindow.postMessage({
            type: 'get-permission-result',
            permission: result
          }, '*');
        });
      }
      if (event.data.type === 'request-permission') {
        dangerouslyGetK3Container().getService($K3Firebase).messaging.then(messaging => messaging.requestPermission()).then(result => {
          ref.current.contentWindow.postMessage({
            type: 'request-permission-result',
            permission: result
          }, '*');
        }).catch(() => {
          ref.current.contentWindow.postMessage({
            type: 'request-permission-result',
            permission: 'declined'
          }, '*');
        });
      }
      if (event.data.type === 'open-keyboard') {
        Keyboard.show().catch(() => {});
      }
      if (event.data.type === 'history-push') {
        window.history.pushState({
          stepId: event.data.stepId
        }, '');
      }
      if (event.data.type === 'analytics') {
        if ('event' in event.data.params) {
          if (event.data.params.event === 'sign_up') {
            analyticsService.logSignUp();
          }
        } else {
          analyticsService.logEvent(event.data.params.content_type, event.data.params.item_id);
        }
        return;
      }
      if (event.data.type === 'mixpanel') {
        mixpanelService.handleEventWithoutEvergreenData(({
          type: event.data.params.event,
          ...event.data.params.properties
        } as any));
        return;
      }
      if (event.data.type === 'login-local') {
        ScreenOrientation.unlock().catch(() => {});
        Keyboard.hide().catch(() => {});
        dismissKeyboard();
        authService.login(currentEndpointService.currentEndpoint, event.data.nickname, event.data.pwd).then(r => {
          if (r.kind === 'loggedOut') {
            alert(JSON.stringify(r.error));
          } else {
            setViewNotResizing();
            localStorage.setItem(hasOnceLoggedInKey, 'true');
          }
        });
      }
      if (event.data.type === 'login') {
        const {
          username,
          password
        } = event.data;
        ScreenOrientation.unlock().catch(() => {});
        Keyboard.hide().catch(() => {});
        dismissKeyboard();
        setViewNotResizing();
        authService.loginWithDeviceToken(currentEndpointService.currentEndpoint, event.data.token).then(r => {
          if (r.kind === 'loggedIn') {
            localStorage.setItem(hasOnceLoggedInKey, 'true');
          } else if (r.kind === 'loggedOut') {
            switch (r.error.kind) {
              case 'AuthError':
                snackbarService.showGenericError('Der gespeicherte Login ist abgelaufen. Bitte logge dich erneut ein.');
                break;
              case 'CouldNotReachServer':
                snackbarService.showGenericError('Der Server konnte nicht erreicht werden. Bitte versuche es später erneut.');
                break;
              default:
                snackbarService.showGenericError('Bitte versuche es später erneut.');
                break;
            }
          }
          if (r.kind === 'loggedIn' && Capacitor.getPlatform() === 'ios' && password && username) {
            SavePassword.promptDialog({
              password,
              username
            });
          }
        });
      }
    };
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);
  React.useEffect(() => {
    if (!initialRender.current) {
      try {
        ref.current.contentWindow.location.reload();
      } catch (e) {
        /* empty */
      }
    }
    initialRender.current = false;
    localStorage.setItem('currentEndpoint', currentEndpointService.currentEndpoint.id);
    localStorage.setItem('prefix', getNamespaceIfWeb() ?? '');
    if ([KnownEndpoints.Dev.id, KnownEndpoints.Local.id].includes(currentEndpointService.currentEndpoint.id)) {
      localStorage.setItem('currentEnvironment', 'development');
    } else {
      localStorage.setItem('currentEnvironment', import.meta.env.MODE);
    }
  }, [currentEndpointService.currentEndpoint.id]);
  return <div className={_c0}>
				{os === OS.android && <div style={{
      height: getSafeArea().safeAreaTopHeight,
      background: '#000000'
    }}></div>}
				<iframe ref={ref} onLoad={() => {
      SplashScreen.hide();
    }} allow={'otp-credentials'} style={{
      width: '100%',
      flex: 1,
      border: 'none',
      background: '#000'
    }} src={initialPageRef.current} />
				{os === OS.android && <div style={{
      height: getSafeArea().insets.bottom,
      background: '#000000'
    }}></div>}

				{showServerIndicator && isLoginView && <div onClick={() => {
      overlayService.showOverlay({
        view: <ServerSwitchModal />
      });
    }} style={{
      bottom: resolveThemingValue((getSafeArea().navBarHeight + 'px' as any), "spacing", useTheme())
    }} className={_c1 + ("accent" ? resolveIsDarkColor("accent", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
						<Text className={_c2}>
							Verbunden mit{' '}
							{currentEndpointService.currentEndpoint.name}
						</Text>
					</div>}

				<OverlayPresenter />
			</div>;
});
const _c0 = " Knu-FlexCol size-100-percent ";
const _c1 = " Knu-Flex bg-accent position-absolute width-full p-large cursor-pointer justifyContent-center ";
const _c2 = "  ";