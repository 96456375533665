import { useService } from '@knuddels-app/DependencyInjection';
import { $KeyboardService } from '@knuddels-app/Keyboard';
import { useIsStackedLayout } from '@knuddels-app/Screen';
import * as React from 'react';
import { observer } from '@knuddels-app/mobx';
import { TransitionView } from './TransitionView';
import { FlexCol, isTouchDevice, resolveThemingValue, resolveIsDarkColor, useTheme } from '@knuddels/component-library';
import { $AdsService } from '@knuddelsModules/Ads';
export const OverlayView = observer('OverlayView', ({
  hide,
  overlay,
  isTopOverlay,
  onHidden,
  onSwipeDown,
  zIndex,
  isSystemApp,
  disableAds
}: {
  hide: boolean;
  overlay: React.ReactElement;
  isTopOverlay: boolean;
  zIndex: number;
  isSystemApp: boolean;
  disableAds: boolean;
  onHidden(): void;
  onSwipeDown(): void;
}) => {
  const adsService = useService($AdsService);
  const isStackedLayout = useIsStackedLayout();
  const keyboardService = useService($KeyboardService);
  const currentInputRef = React.useRef<(typeof $KeyboardService.T)['focusable'] | null>(null);
  const initialHide = React.useRef(hide);
  React.useEffect(() => {
    // The transition view doesn't call onHidden when it's initially hidden
    // causing the overlay to be stuck in the hidden state.
    if (initialHide.current) {
      onHidden();
    }
  }, []);
  React.useEffect(() => {
    // We want to hide keyboard when the overlay opens and closes
    // to prevent weird keyboard when there is no input left.
    keyboardService.dismissKeyboard();
    setTimeout(() => {
      keyboardService.dismissKeyboard();
    });
  }, [hide]);
  React.useEffect(() => {
    currentInputRef.current = keyboardService.focusable;
    const handler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onSwipeDown();
      }
    };
    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
      if (!isTouchDevice() && currentInputRef.current) {
        currentInputRef.current.focus();
      }
    };
  }, []);
  return <TransitionView zIndex={zIndex} bg={isStackedLayout || !isTopOverlay ? 'transparent' : 'backgroundScrim'} transition={isStackedLayout ? 'slideUp' : 'fade'} hide={hide} leave={{
    onFinished: onHidden
  }} onSwipeDown={onSwipeDown} isSystemApp={isSystemApp} allowSwipe={isStackedLayout}>
				{overlay}
				{!disableAds && isStackedLayout && adsService.areCapacitorAdsVisible && <div className={_c0 + ("contentBg" ? resolveIsDarkColor("contentBg", useTheme()) ? " content-is-dark" : " content-is-light" : "")}>
							<adsService.CapacitorAdzone adVisible={isTopOverlay} location={'bottom'} bannerId={'K3FullScreenOverlayBanner'} />
						</div>}
			</TransitionView>;
});
const _c0 = " Knu-FlexCol shadow-Shadow1 width-full zIndex-1 bg-contentBg ";