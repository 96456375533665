import * as React from 'react';
import { injectedComponent, useService } from '@knuddels-app/DependencyInjection';
import { $ViewService } from '@knuddels-app/layout';
import { SettingsOverview } from './Overview/SettingsOverview';
import { ChannelSettings } from './ChannelSettings/ChannelSettings';
import { ContactListSettings } from './ContactListSettings';
import { $Environment } from '@knuddels-app/Environment';
import { PrivacySettings } from './PrivacySettings/PrivacySettings';
import { settingsViewId } from '@knuddelsModules/Settings';
import { Router } from './SettingsRouter';
import { FlexCol } from '@knuddels/component-library';
import { $I18n } from '@knuddels-app/i18n';
import { declareFormat } from '@knuddels/i18n';
import { accountSettingsFormat, channelListSettingsFormat, contactFilterSettingsFormat, contactListSettingsFormat, creditsFormat, displaySettingsFormat, macroBoxSettingsFormat, mentorSystemSettingsFormat, messengerSettingsFormat, privacySettingsFormat, settingsFormat, soundSettingsFormat, subscriptionsSettingsFormat } from './formats';
import { useLogEvent } from '@knuddels-app/analytics/firebase/useLogEvent';
import { ChangePasswordApp } from './ChangePasswordApp';
import { SoundSettings } from './SoundSettings';
import { MentorSystemSettings } from './MentorSystemSettings';
import { AccountSettings } from './AccountSettings/AccountSettings';
import { MessengerSettingsOverview } from './MessengerSettings/MessengerSettingsOverview';
import { Subscriptions } from './Subscriptions';
import { SettingsChangeNicknameApp } from './AccountSettings/SettingsChangeNicknameApp';
import { DisplaySettings } from '@knuddelsModules/Settings/bundle/components/DisplaySettings';
import { MacroBoxSettings } from '@knuddelsModules/Settings/bundle/components/ChannelSettings/MacroBoxSettings';
import { ChannelListSettings } from '@knuddelsModules/Settings/bundle/components/ChannelListSettings/ChannelListSettings';
import { FotomeetSettings } from '@knuddelsModules/Settings/bundle/components/FotomeetSettings/FotomeetSettings';
import { KnuddelAccessApp } from './KnuddelAccessApp';
import { KnuddelTransferHistoryApp } from './KnuddelTransferHistoryApp';
import { ContactFilterSettingsApp } from '@knuddelsModules/Settings/bundle/components/ContactFilterSettingsApp';
import { $GenericUserEventService } from '@knuddels-app/analytics/generic';
const LazyCredits = React.lazy(() => import('./Credits/Credits'));
export const SettingsMainView = injectedComponent({
  name: 'SettingsMainView',
  inject: {
    viewService: $ViewService,
    environment: $Environment,
    i18n: $I18n
  }
}, ({
  viewService,
  i18n
}) => {
  const closeSettings = (): void => {
    viewService.closeView(settingsViewId);
  };
  const channelSettingsTitleFormat = declareFormat({
    id: 'settings.channelSettings.title',
    defaultFormat: 'Channel'
  });
  const track = useLogEvent();
  const genericUserEventService = useService($GenericUserEventService);
  return <div className={_c0}>
				<Router rootPath="Overview" onClose={closeSettings} onNavigate={({
      newPath
    }) => {
      genericUserEventService.reportEvent({
        type: 'Settings_Navigated',
        newPath
      });
    }}>
					<Router.Route path="Overview" title={settingsFormat.format(i18n)} onNavIconPress={() => {
        track('Settings_Overview', 'Titlebar_Close');
      }}>
						<SettingsOverview shouldShowChannelSetting shouldShowAccountSettings />
					</Router.Route>
					<Router.Route path="AccountSettings" title={accountSettingsFormat.format(i18n)} onNavIconPress={() => track('Settings_AccountAndSecurity', 'Titlebar_Close')}>
						<AccountSettings />
					</Router.Route>
					<Router.Route path="Credits" title={creditsFormat.format(i18n)} onNavIconPress={() => track('Settings_Credits', 'Titlebar_Close')}>
						<React.Suspense fallback={null}>
							<LazyCredits />
						</React.Suspense>
					</Router.Route>
					<Router.Route hideShadow path="ChannelSettings" title={channelSettingsTitleFormat.format(i18n)} onNavIconPress={() => track('Settings_Channelsettings', 'Titlebar_Close')}>
						<ChannelSettings />
					</Router.Route>
					<Router.Route path="MacroBoxSettings" title={macroBoxSettingsFormat.format(i18n)} onNavIconPress={() => track('Settings_MacroBxoSettings', 'Titlebar_BackButton')}>
						<MacroBoxSettings />
					</Router.Route>
					<Router.Route path="ContactListSettings" title={contactListSettingsFormat.format(i18n)} onNavIconPress={() => track('Contacts_Settings', 'Titlebar_BackButton')}>
						<ContactListSettings />
					</Router.Route>
					<Router.Route path="MessengerSettings" title={messengerSettingsFormat.format(i18n)} onNavIconPress={() => track('Messenger_Settings', 'Titlebar_BackButton')}>
						<MessengerSettingsOverview />
					</Router.Route>
					<Router.Route path="SoundSettings" title={soundSettingsFormat.format(i18n)} onNavIconPress={() => track('Settings_Sounds', 'Titlebar_Close')}>
						<SoundSettings />
					</Router.Route>
					<Router.Route path="MentorSystemSettings" title={mentorSystemSettingsFormat.format(i18n)} onNavIconPress={() => track('Settings_MentorSystem', 'Titlebar_Close')}>
						<MentorSystemSettings />
					</Router.Route>
					<Router.Route path="ContactFilterSettings" title={contactFilterSettingsFormat.format(i18n)} hideTitleBar>
						<ContactFilterSettingsApp />
					</Router.Route>
					<Router.Route path="PrivacySettings" title={privacySettingsFormat.format(i18n)} onNavIconPress={() => track('Settings_Privacy', 'Titlebar_Close')}>
						<PrivacySettings />
					</Router.Route>
					<Router.Route path="ChangePassword" hideTitleBar>
						<ChangePasswordApp />
					</Router.Route>
					<Router.Route path="Subscriptions" hideTitleBar title={subscriptionsSettingsFormat.format(i18n)}>
						<Subscriptions />
					</Router.Route>
					<Router.Route path="ChangeNickname" hideTitleBar>
						<SettingsChangeNicknameApp />
					</Router.Route>
					<Router.Route hideShadow path="DisplaySettings" title={displaySettingsFormat.format(i18n)}>
						<DisplaySettings />
					</Router.Route>
					<Router.Route path="ChannelListSettings" title={channelListSettingsFormat.format(i18n)} onNavIconPress={() => track('ChannelList_Settings', 'Titlebar_BackButton')}>
						<ChannelListSettings />
					</Router.Route>
					<Router.Route path="KnuddelAccessSettings" hideTitleBar>
						<KnuddelAccessApp />
					</Router.Route>
					<Router.Route path="KnuddelTransferHistory" hideTitleBar>
						<KnuddelTransferHistoryApp />
					</Router.Route>
					<Router.Route path={'FotomeetSettings'} title={'Fotomeet'}>
						<FotomeetSettings />
					</Router.Route>
				</Router>
			</div>;
});
const _c0 = " Knu-FlexCol flex-1 ";