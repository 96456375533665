import { GetProfilePictureCustomSize, ProfilePictureOverlays, Scalars, User } from '@generated/graphql';
import { useQuery } from '@knuddels-app/Connection';
import { observer } from '@knuddels-app/mobx';
import { getPixelRatio } from '@knuddels-app/tools/getPixelRatio';
import { BoxProps, Flex, FlexCol, Image, ThemeOverride, resolveThemingValue, useTheme, resolveIsDarkColor } from '@knuddels/component-library';
import { SkeletonAnimation, StatusBadgeProps } from '@shared/components';
import * as React from 'react';
import { getCustomUserImageSize } from '../customUserImageSizes';
export interface UserImageProps {
  // TODO remove after everything has been moved to GraphQL
  uid?: Scalars['ID'];
  userId?: User['id'];
  type: UserImageType;
  onlineBadge?: React.ReactElement<StatusBadgeProps>;
  shadow?: boolean;
  blurred?: boolean;
  /**
   * If set to true, a skeleton animation will be shown while the image is loading.
   * If set to an url string, the url will be displayed while the image is loading.
   */
  showSkeletonWhileLoading?: boolean | string;
  /**
   * Sets the kind of highlighting for a friend's user image.
   *
   * This component will automatically query the `friendState` from apollo cache
   * and set the specified highlighting if they are friends.
   *
   * - simple (default). Show a colored border.
   * - fancy. Show a colored border with additional stars.
   * - disabled. disable highlighting
   */
  highlight?: 'simple' | 'fancy' | 'disabled';
  url?: string;
  overlayUrls?: ProfilePictureOverlays | undefined | null;
  borderRadius?: BoxProps['borderRadius'];
}
interface InternalProps {
  url?: string | undefined | null;
  overlayUrls?: ProfilePictureOverlays | undefined | null;
  type: UserImageType;
  shadow?: boolean;
  blurred?: boolean;
  showSkeletonWhileLoading?: boolean | string;
  onlineBadge?: React.ReactElement<StatusBadgeProps>;
  borderRadius?: BoxProps['borderRadius'];
}
import defaultPic from '@shared/images/nopic.png';
import { useReleaseImage } from '@knuddels-app/tools/useReleaseImage';
const noPicState = {
  source: defaultPic
};
export enum UserImageType {
  NICKLIST = 32,
  CHAT = 40,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  STAPP_SIDEBAR_SMALL = 32,
  STAPP_SIDEBAR_BIG = 44,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  NAV_SMALL = 40,
  NAV_BIG = 56,
  NAV_MENU = 30,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  MESSENGER = 56,
  COMMUNICATION_REQUEST_BOX = 60,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  FOTOMEET = 56,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  SETTINGS = 56,
  RECEIVED_FRIEND_REQUEST = 72,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  MESSENGER_NOTIFICATION = 32,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  MINI_CHAT_HEAD = 48,
}
type Props = InternalProps;
const LoadingImage: React.FC<{
  loaded: boolean;
  size: any;
  borderRadius: any;
} & Pick<Props, 'showSkeletonWhileLoading'>> = ({
  loaded,
  showSkeletonWhileLoading,
  size,
  borderRadius
}) => {
  if (loaded || !showSkeletonWhileLoading) {
    return null;
  }
  if (typeof showSkeletonWhileLoading === 'boolean') {
    return <SkeletonAnimation>
				<div style={{
        height: resolveThemingValue(size, "sizes", useTheme()),
        width: resolveThemingValue(size, "sizes", useTheme()),
        borderRadius: resolveThemingValue(borderRadius, "borderRadius", useTheme())
      }} className={_c0 + ("skeleton" ? resolveIsDarkColor("skeleton", useTheme()) ? " content-is-dark" : " content-is-light" : "")} />
			</SkeletonAnimation>;
  }
  return <Image src={showSkeletonWhileLoading} alt={''}
  // @ts-expect-error Not exposed
  style={{
    filter: `blur(5px)`,
    /* Fix for Safari performance issue: */
    /* forces the browser use GPU acceleration for that particular element instead of the CPU */
    webkitBackfaceVisibility: 'hidden',
    mozBackfaceVisibility: 'hidden',
    height: resolveThemingValue(size, "sizes", useTheme()),
    width: resolveThemingValue(size, "sizes", useTheme()),
    borderRadius: resolveThemingValue(borderRadius, "borderRadius", useTheme())
  }} className={_c1} />;
};
const UserImageContent: React.FC<Props & {
  source: string;
}> = props => {
  const showSkeletonWhileLoading = props.showSkeletonWhileLoading ?? false;
  /* if showSkeletonWhileLoading is false, we set isLoaded to true
   * so that we don't unnecessarily rerender the component when the image is loaded
   */
  const [isLoaded, setIsLoaded] = React.useState(!showSkeletonWhileLoading);
  const size = props.type;
  const releaseRef = useReleaseImage();
  React.useEffect(() => {
    if (isLoaded) {
      return;
    }
    setTimeout(() => {
      setIsLoaded(true);
    }, 100);
  }, []);
  const onlineBadge = props.onlineBadge && <div style={{
    bottom: resolveThemingValue((-1 as ThemeOverride), "spacing", useTheme()),
    right: resolveThemingValue((-1 as ThemeOverride), "spacing", useTheme())
  }} className={_c2}>
			{props.onlineBadge}
		</div>;
  const borderRadius = props.borderRadius ?? (size < 32 ? 'tiny' : 'minor');
  const overlays = (size <= 64 ? props.overlayUrls?.urlsSmall : props.overlayUrls?.urlsLarge) ?? [];
  const content = <div className={_c3}>
			<div style={{
      borderRadius: resolveThemingValue(borderRadius, "borderRadius", useTheme()),
      height: resolveThemingValue(size, "sizes", useTheme()),
      width: resolveThemingValue(size, "sizes", useTheme())
    }} className={_c4}>
				<LoadingImage loaded={isLoaded} showSkeletonWhileLoading={showSkeletonWhileLoading} size={size} borderRadius={borderRadius} />

				{props.blurred ? <div style={{
        filter: `blur(5px)`,
        /* Fix for Safari performance issue: */
        /* forces the browser use GPU acceleration for that particular element instead of the CPU */
        // @ts-expect-error Not exposed
        webkitBackfaceVisibility: 'hidden',
        mozBackfaceVisibility: 'hidden'
      }} className={_c5}>
						<Image resizeMode={'cover'} src={props.source} fallbackSrc={defaultPic} size={'full'} alt={''} onLoad={() => {
          setIsLoaded(true);
        }} {...{
          style: {
            objectPosition: 'center top'
          }
        }} />
					</div> : <div ref={releaseRef} style={{
        backgroundImage: `url(${props.source ?? defaultPic})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center top',
        height: resolveThemingValue(size, "sizes", useTheme()),
        width: resolveThemingValue(size, "sizes", useTheme()),
        borderRadius: resolveThemingValue(borderRadius, "borderRadius", useTheme())
      }} className={_c6} />}
			</div>
			{overlays.length > 0 && overlays.map((overlayUrl, index) => <div key={index} style={{
      borderRadius: resolveThemingValue(borderRadius, "borderRadius", useTheme())
    }} className={_c7}>
						<div style={{
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundImage: resolveThemingValue('url(' + overlayUrl + ')', "theme", useTheme())
      }} className={_c8} />
					</div>)}
			{onlineBadge}
		</div>;
  if (props.shadow) {
    return <div style={{
      height: resolveThemingValue(size, "sizes", useTheme()),
      width: resolveThemingValue(size, "sizes", useTheme()),
      borderRadius: resolveThemingValue(borderRadius, "borderRadius", useTheme())
    }} className={_c9}>
				{content}
			</div>;
  } else {
    return <div style={{
      height: resolveThemingValue(size, "sizes", useTheme()),
      width: resolveThemingValue(size, "sizes", useTheme())
    }} className={_c10}>
				{content}
			</div>;
  }
};
class UserImageBase extends React.PureComponent<Props, {
  source: string | null;
}> {
  state = noPicState;
  UNSAFE_componentWillMount(): void {
    this.setSource(this.props);
  }
  setSource(props: Props): void {
    const url = props.url;
    if ((!url || url.length === 0) && !this.props.showSkeletonWhileLoading) {
      this.setState(noPicState);
    } else if (url) {
      this.setState({
        source: url
      });
    } else {
      this.setState({
        source: null
      });
    }
  }
  UNSAFE_componentWillReceiveProps(newProps: Props): void {
    // if image is unavailable and fails to load, it is replaced with nopic
    // if we update here without a check, it will retry loading the image (that failed to load earlier)
    // => image will flicker
    // adapt this check if other props should update the state
    //  - or maybe save old url and only retry if url changed
    //  - and/or only reset after some time
    const prevUrl = this.props.url;
    const newUrl = newProps.url;
    if (prevUrl !== newUrl) {
      this.setSource(newProps);
    }
  }
  render(): JSX.Element {
    return <UserImageContent {...this.props} source={this.state.source} />;
  }
}
const UserImageWithGraphql: React.FC<Omit<UserImageProps, 'uid'>> = observer('UserImageWithGraphql', ({
  userId,
  ...props
}) => {
  const profilePictureQuery = useQuery(GetProfilePictureCustomSize, {
    userId,
    size: getCustomUserImageSize(props.type),
    pixelDensity: getPixelRatio()
  }, 'cache-first');
  const url = profilePictureQuery.data?.profilePicture.urlCustomSizeSquare;
  const overlayUrls = profilePictureQuery.data?.profilePictureOverlayUrls;
  return <UserImageBase {...props} url={url} overlayUrls={overlayUrls} />;
});
const UserImageWithoutGraphql: React.FC<Omit<UserImageProps, 'uid'>> = observer('UserImageWithoutGraphql', ({
  url,
  ...props
}) => {
  return <UserImageBase {...props} url={url} />;
});
export const UserImage = (props: UserImageProps): JSX.Element => {
  const userId = props.userId || props.uid !== undefined && props.uid.toString() ||
  // set default to undefined
  undefined;
  // Need to do this switch because we need the uid to do the graphql query
  if (userId === undefined) {
    return <UserImageBase {...props} />;
  } else if (props.url) {
    return <UserImageWithoutGraphql {...props} userId={userId} />;
  } else {
    return <UserImageWithGraphql {...props} userId={userId} />;
  }
};
const _c0 = " Knu-Flex bg-skeleton ";
const _c1 = "  ";
const _c2 = " Knu-FlexCol position-absolute ";
const _c3 = " Knu-FlexCol position-relative overflow-visible ";
const _c4 = " Knu-FlexCol position-relative overflow-hidden ";
const _c5 = " Knu-FlexCol position-absolute inset-none ";
const _c6 = " Knu-Flex ";
const _c7 = " Knu-Flex position-absolute inset-none overflow-hidden ";
const _c8 = " Knu-Flex size-full ";
const _c9 = " Knu-Flex shadow-Shadow1 ";
const _c10 = " Knu-FlexCol borderRadius-circle overflow-visible position-relative ";