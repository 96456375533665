import { jsx as v } from "react/jsx-runtime";
import * as i from "react";
import { toPressHandler as N } from "./PressHandler.js";
import "./Text.scss.js";
import { clsx as R } from "../../node_modules/clsx/dist/clsx.m.js";
import { marginMapping as n } from "../Layout/Box/BoxMappings.js";
import { useColor as k } from "../Layout/hooks/useColor.js";
import { resolveThemeColor as E, resolveThemingValue as H } from "../Layout/isDarkColor.js";
import { useTheme as K } from "../../themes/useTheme.js";
const L = i.memo(
  ({
    children: c,
    type: a = "body1",
    state: p = "primary",
    id: f,
    bold: y,
    extraBold: u,
    italic: x,
    onPress: T,
    selectable: g,
    numberOfLines: e = 0,
    className: h,
    style: d,
    ...o
  }) => {
    const { clickable: b, onClick: C } = N(T), m = i.useRef(null), s = k(o, m), l = K(), S = s ? {
      "--text-colors-primary": E(s, l)
    } : {}, j = e > 1 ? {
      "--numberOfLines": e
    } : {};
    let r = o.style || {};
    return Object.keys(o).forEach((t) => {
      t in n && (r = {
        ...r,
        [n[t]]: H(
          o[t],
          "spacing",
          l
        )
      });
    }), /* @__PURE__ */ v(
      "span",
      {
        id: f,
        ref: m,
        onClick: C,
        style: {
          ...S,
          ...j,
          ...r,
          ...d
        },
        className: R("Knu-Text", a, p, h, {
          clickable: b,
          bold: y,
          extraBold: u,
          italic: x,
          selectable: g,
          numberOfLines: e !== 0,
          singleLine: e === 1
        }),
        children: c
      }
    );
  }
);
L.displayName = "Text";
export {
  L as Text
};
