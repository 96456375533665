import * as React from 'react';
import { inject, injectable, injectedComponent, injectProps, useService } from '@knuddels-app/DependencyInjection';
import { BoxProps, Flex, FlexCol, SplitView, resolveThemingValue, useTheme } from '@knuddels/component-library';
import { Z_INDEX } from '@shared/components';
import { $ViewService } from './serviceIds';
import { LaidoutView, Layout, LayoutPosition } from './layout';
import { $ScreenService, ScreenHeight, ScreenWidth } from '@knuddels-app/Screen';
import { Disposable } from '@knuddels/std';
import { action, computed, observable, observer, reaction } from '@knuddels-app/mobx';
import { $LocationService } from '../location';
import { capitalizeFirstLetter } from '../tools/capitalizeFirstLetter';
import { $FirebaseAnalyticsService } from '../analytics/firebase';
import styles from './MountingAnimation.module.scss';
import throttle from 'lodash/throttle';
import { ChannelViewState } from '@knuddelsModules/Channel/ChannelViewProvider';
import { $AnimationSettingsService } from '@knuddelsModules/Settings';
const OPEN_ANIMATION_DURATION = 500;
const OPEN_EASING_FUNCTION = 'cubic-bezier(0.05, 1.15, 0.1, 1.0)';
const SWITCH_ANIMATION_DURATION = 250;
const SWITCH_EASING_FUNCTION = 'cubic-bezier(0, 0, 0, 1)';
@injectable()
class LayoutViewModel {
  public readonly dispose = Disposable.fn();
  @observable
  private splitViewStatus: Map<string, boolean> = new Map();
  constructor(@injectProps()
  props: unknown, @inject($ViewService)
  private readonly viewService: typeof $ViewService.T, @inject($ScreenService)
  private readonly screenService: typeof $ScreenService.T, @inject($LocationService)
  private readonly locationService: typeof $LocationService.T, @inject($FirebaseAnalyticsService)
  private readonly firebaseAnalyticsService: typeof $FirebaseAnalyticsService.T) {
    this.trackScreenOrientation();
  }
  trackScreenOrientation(): void {
    this.firebaseAnalyticsService.logEvent('Orientation_Load', `${this.screenOrientation}_${this.currentLocation}`);
    this.dispose.track(reaction({
      name: 'screen orientation changed'
    }, () => this.screenOrientation, () => {
      this.firebaseAnalyticsService.logEvent('Orientation_Change', `${this.screenOrientation}_${this.currentLocation}`);
    }));
  }
  isViewPersisted(view: LaidoutView | undefined, position: LayoutPosition): boolean {
    return !!view && this.layout.isViewPersisted(view.visibleView.viewConfig.viewId, position);
  }
  isViewActive(view: LaidoutView | undefined, position: LayoutPosition): boolean {
    return !!view && this.layout.isViewActive(view.visibleView.viewConfig.viewId, position);
  }
  @action
  updateSplitViewStatus(view: LaidoutView, status: boolean): void {
    this.splitViewStatus.set(view.visibleView.viewConfig.viewId.id, status);
  }
  getPersistedViews(position: LayoutPosition): LaidoutView[] {
    return this.layout.persistedViewsByPosition[position];
  }
  get layout(): Layout {
    return this.viewService.layout;
  }
  get isMediumSize(): boolean {
    return this.screenService.isMediumSize;
  }
  get screenSize(): {
    width: ScreenWidth;
    height: ScreenHeight;
  } {
    return {
      width: this.screenService.screenWidth,
      height: this.screenService.screenHeight
    };
  }
  get isStackedLayout(): boolean {
    return this.screenService.isStackedLayout;
  }
  @computed
  get isExploreViewOpen(): boolean {
    const mainView = this.layout.viewsByPosition[LayoutPosition.Main];
    return mainView?.visibleView.state instanceof ChannelViewState && (mainView.visibleView.state.pathItems.length === 0 || mainView.visibleView.state.pathItems[0] === 'overview');
  }
  @computed
  get isSplitView(): boolean {
    if (globalEnv.product === 'stapp-messenger') {
      return true;
    }
    const sideView = this.layout.viewsByPosition[LayoutPosition.Side];
    return (sideView?.visibleView?.viewConfig.useSplitView && this.splitViewStatus.get(sideView?.visibleView.viewConfig.viewId.id)) ?? false;
  }
  get splitViewPlaceholder(): React.ReactNode | undefined {
    const sideView = this.layout.viewsByPosition[LayoutPosition.Side];
    return sideView?.visibleView?.viewConfig.splitViewPlaceholder;
  }
  get shouldHideMainView(): boolean {
    return this.isSplitView && this.isMediumSize;
  }
  @computed
  get screenOrientation(): string {
    return this.screenService.isLandscape ? 'Landscape' : 'Portrait';
  }

  // Get the current location excluding the id's
  get currentLocation(): string {
    return this.locationService.currentLocation.path.filter(path => !/^\d/.test(path[0])).reduce((result, path) => result + '_' + capitalizeFirstLetter(path), '');
  }
}
const mergeStyles = (styles: Partial<BoxProps>, overrideStyles: Partial<BoxProps> = {}) => {
  return {
    ...styles,
    ...overrideStyles
  };
};
const getStyles = (active: boolean, position: LayoutPosition, overrideStyles: Partial<BoxProps> = {}, model: Pick<LayoutViewModel, 'shouldHideMainView' | 'isViewActive' | 'screenSize'>) => {
  const displayStyles = active && (position !== LayoutPosition.Main || !model.shouldHideMainView) ? {
    zIndex: 1
  } : {
    position: ('absolute' as BoxProps['position']),
    top: (0 as any),
    left: (0 as any),
    right: (0 as any),
    bottom: (0 as any),
    opacity: 0,
    zIndex: 0,
    pointerEvents: ('none' as any)
  };
  return mergeStyles(mergeStyles(positionStyles[position], overrideStyles), displayStyles);
};
const positionWithoutPersisted = (model: Pick<LayoutViewModel, 'layout' | 'isViewPersisted'>, position: LayoutPosition) => {
  return [model.layout.viewsByPosition[position]].filter(view => !model.isViewPersisted(view, position));
};
let initialRender = true;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useAnimateLayoutWithCss = (active: boolean, id: string) => {
  const animationSettingsService = useService($AnimationSettingsService);
  const ref = React.useRef<HTMLDivElement>(null);
  const positionX = React.useRef<number | null>(null);
  React.useEffect(() => {
    if (!ref.current) return;
    if (!animationSettingsService.isAnimationEnabled) return;
    setTimeout(() => {
      positionX.current = ref.current?.getBoundingClientRect().x ?? 0;
    }, OPEN_ANIMATION_DURATION);
    setTimeout(() => {
      initialRender = false;
    }, 0);
    const handleTransitionEnd = () => {
      positionX.current = ref.current?.getBoundingClientRect().x ?? 0;
    };
    ref.current.addEventListener('transitionend', handleTransitionEnd);
    return () => {
      ref.current?.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, []);
  const throttledAnimation = React.useCallback(throttle((newPositionX: number) => {
    if (!ref.current) return;
    const deltaX = positionX.current! - newPositionX;
    ref.current.style.transform = `translate3d(${deltaX}px, 0, 0)`;
    ref.current.style.transition = 'none';
    requestAnimationFrame(() => {
      ref.current!.style.transform = 'translate3d(0px, 0, 0)';
      ref.current!.style.transition = `transform ${OPEN_ANIMATION_DURATION}ms ${OPEN_EASING_FUNCTION}`;
    });
  }, 200, {
    trailing: false
  }), []);
  React.useLayoutEffect(() => {
    if (!active) return;
    if (!ref.current) return;
    if (initialRender) return;
    if (positionX.current === null) return;
    const newPositionX = ref.current.getBoundingClientRect().x;
    if (newPositionX !== positionX.current && animationSettingsService.isAnimationEnabled) {
      throttledAnimation(newPositionX);
    }
  });
  return ref;
};
export const LayoutView = injectedComponent({
  name: 'LayoutView',
  model: LayoutViewModel
}, ({
  model
}) => {
  const views = ([...[...model.getPersistedViews(LayoutPosition.Main)].sort((a, b) => a.visibleView.viewConfig.persistIndex! - b.visibleView.viewConfig.persistIndex!), ...positionWithoutPersisted(model, LayoutPosition.Main), ...[...model.getPersistedViews(LayoutPosition.Side)].sort((a, b) => a.visibleView.viewConfig.persistIndex! - b.visibleView.viewConfig.persistIndex!), ...positionWithoutPersisted(model, LayoutPosition.Side)].filter(Boolean) as LaidoutView[]);
  const usePaneLayout = !model.isStackedLayout && globalEnv.product !== 'stapp-messenger';
  return <div style={{
    gap: resolveThemingValue(usePaneLayout ? 'base' : undefined, "spacing", useTheme()),
    paddingRight: resolveThemingValue(!usePaneLayout || model.isExploreViewOpen ? undefined : 'base', "spacing", useTheme())
  }} className={_c0}>
				{views.map(view => {
      const useSingleView = !view.visibleView.viewConfig.useSplitView || model.isStackedLayout;
      const hideBackground = globalEnv.product === 'stapp-messenger' || !model.isStackedLayout && view.visibleView.viewConfig.hideDesktopBackground && view.visibleView.viewConfig.hideDesktopBackground(view.visibleView.state);
      const active = model.isViewActive(view, view.position);
      const overrideStyles = active ? view.visibleView.viewConfig.getOverrideStyles?.({
        viewState: view.visibleView.state,
        ...model.screenSize
      }) : undefined;
      if (useSingleView) {
        return <LayoutViewShadow key={view.key} id={view.key} useSingleView={useSingleView} active={active} position={view.position} overrideStyles={overrideStyles} model={(model as LayoutViewModel)} animateIn={view.visibleView.animateIn} hideBackground={hideBackground}>
								{view.view}
							</LayoutViewShadow>;
      } else {
        return <LayoutViewShadow key={view.key} id={view.key} useSingleView={useSingleView} active={active} position={view.position} overrideStyles={overrideStyles} model={(model as LayoutViewModel)} hideBackground={hideBackground}>
								<SplitView onSplitViewStateChange={status => model.updateSplitViewStatus(view, status)} rootViewWidth={SIDE_ROOT_WIDTH} sideViewMinWidth={SIDE_CONTENT_MIN_WIDTH} totalMaxWidth={globalEnv.product === 'stapp-messenger' || model.isMediumSize ? undefined : SIDE_TOTAL_MAX_WIDTH} shadow={'Shadow4'} zIndex={Z_INDEX.OVER_TITLE_BAR} placeholder={model.splitViewPlaceholder}>
									{view.view}
								</SplitView>
							</LayoutViewShadow>;
      }
    })}
			</div>;
});
const LayoutViewShadow: React.FC<{
  active: boolean;
  position: LayoutPosition;
  overrideStyles?: Record<string, any>;
  useSingleView: boolean;
  model: LayoutViewModel;
  children: React.ReactNode;
  id: any;
  hideBackground?: boolean;
  animateIn?: () => void;
}> = observer('LayoutViewShadow', ({
  active,
  position,
  overrideStyles,
  model,
  useSingleView,
  children,
  animateIn,
  hideBackground,
  id
}) => {
  const ref = useAnimateLayoutWithCss(active, id);
  const [mounted, setMounted] = React.useState(initialRender && active);
  const initialRenderRef = React.useRef(initialRender);
  const animationSettingsService = useService($AnimationSettingsService);
  const viewService = useService($ViewService);
  const [mountingType] = React.useState(() => viewService.previousLayout?.hasOpenSideView() ? 'pulse' : 'slide');
  React.useLayoutEffect(() => {
    setMounted(active);
    if (active) {
      animateIn?.();
    }
  }, [active]);
  const style = useSingleView ? getStyles(active, position, overrideStyles, model) : {
    ...getStyles(active, position, overrideStyles, model),
    flex: model.isSplitView ? 1 : undefined,
    width: model.isSplitView ? '100%' : undefined,
    minWidth: model.isSplitView ? SIDE_ROOT_WIDTH + SIDE_CONTENT_MIN_WIDTH : undefined,
    maxWidth: globalEnv.product === 'stapp-messenger' || model.shouldHideMainView ? undefined : SIDE_ROOT_MAX_WIDTH
  };
  return <Flex style={({
    ...style,
    '--animation-duration': `${mountingType === 'slide' ? OPEN_ANIMATION_DURATION : SWITCH_ANIMATION_DURATION}ms`,
    '--animation-easing-function': mountingType === 'slide' ? OPEN_EASING_FUNCTION : SWITCH_EASING_FUNCTION
  } as any)} minWidth={0} className={initialRenderRef.current || !animationSettingsService.isAnimationEnabled ? '' : styles['mount-animation-' + mountingType] + ' ' + (mounted ? styles['mount-animation-' + mountingType + '-enabled'] : '')}>
				<div ref={ref} style={{
      zIndex: Z_INDEX.OVER_TITLE_BAR,
      ...style,
      borderRadius: resolveThemingValue(model.isStackedLayout || hideBackground ? undefined : 'xlarge', "borderRadius", useTheme()),
      marginTop: resolveThemingValue(model.isStackedLayout || globalEnv.product === 'stapp-messenger' || model.isExploreViewOpen && id === 'channel' ? undefined : 'base', "spacing", useTheme()),
      marginBottom: resolveThemingValue(model.isStackedLayout || globalEnv.product === 'stapp-messenger' || model.isExploreViewOpen && id === 'channel' ? undefined : 'base', "spacing", useTheme()),
      overflow: resolveThemingValue(model.isStackedLayout ? undefined : 'hidden', "theme", useTheme())
    }} className={_c2 + " " + (position === LayoutPosition.Main ? 'channel-root' : '') + " " + (hideBackground ? _c3 : _c4)}>
					{children}
				</div>
			</Flex>;
});
LayoutViewShadow.displayName = 'LayoutViewShadow';
const SIDE_ROOT_WIDTH = 360;
const SIDE_ROOT_MAX_WIDTH = 1100;
const SIDE_CONTENT_MIN_WIDTH = 440;
// if not full width
const SIDE_CONTENT_MAX_WIDTH = 740;
const SIDE_TOTAL_MAX_WIDTH = SIDE_ROOT_WIDTH + SIDE_CONTENT_MAX_WIDTH;
const positionStyles: { [TPosition in LayoutPosition]: BoxProps } = {
  [LayoutPosition.Main]: {
    flex: 1
  },
  [LayoutPosition.Side]: {
    width: SIDE_ROOT_WIDTH
  }
};
const _c0 = " Knu-Flex position-relative flexDirection-row-reverse flex-1 minHeight-0-px ";
const _c1 = " Knu-Flex minWidth-0-px ";
const _c2 = " Knu-FlexCol position-relative minHeight-0-px maxWidth-full ";
const _c3 = " shadow-none ";
const _c4 = " shadow-Shadow4 ";